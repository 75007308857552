import router from 'next/router';
import {AtomEffect} from 'recoil';
import {
  onClient,
  clearValue,
  getValue,
  setValue,
  getSessionValue,
  clearSessionValue,
  setSessionValue,
} from '../utils';
import {debug} from '../utils/logging';

export const localStorageEffect =
  <T>(key: string, setDefault?: boolean): AtomEffect<T> =>
    ({setSelf, onSet}) => {
      const savedValue = getValue(key);
      if (savedValue && setDefault) {
        try {
          setSelf(JSON.parse(savedValue));
        } catch {
          clearValue(key);
        }
      }
      onSet((newValue, _, isReset) => {
      isReset ? clearValue(key) : setValue(key, JSON.stringify(newValue));
      });
    };

export const sessionStorageEffect =
  <T>(key: string, setDefault?: boolean): AtomEffect<T> =>
    ({setSelf, onSet}) => {
      const savedValue = getSessionValue(key);
      if (savedValue && setDefault) {
        try {
          setSelf(JSON.parse(savedValue));
        } catch {
          clearSessionValue(key);
        }
      }
      onSet((newValue, _, isReset) => {
      isReset ?
        clearSessionValue(key) :
        setSessionValue(key, JSON.stringify(newValue));
      });
    };

export const debugEffect =
  <T>(message: string): AtomEffect<T> =>
    ({onSet}) => {
      onSet((newValue) => {
        if (process.env.INFRA_ENV === 'dev') {
          console.debug(message, newValue);
        }
      });
    };

export const routeEffect =
  <T>(path: (id: T) => string): AtomEffect<T> =>
    ({onSet}) => {
      onClient(() => {
        onSet((newPage) => {
          if (router.isReady) {
          // eslint-disable-next-line no-unused-vars
            const {id, ...query} = router.query;
            router.push({
              pathname: path(newPage),
              query,
            });
          }
        });
      });
    };

export const cleanCartEffect =
  (): AtomEffect<Array<string>> =>
    ({onSet}) => {
      onClient(() => {
        onSet((items) => {
          if (items.length === 0) {
            debug('cleaning cart');
            clearValue('atomstate:orderId');
            clearValue('atomstate:orderItems');
          }
        });
      });
    };
