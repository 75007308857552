
import {percentOf} from '@mindfulness/utils/math';
import {Maybe} from '@mindfulness/utils/maybe';
import React, {useCallback, useEffect, useRef} from 'react';
import ReactPlayer from 'react-player';
import {useRecoilState} from 'recoil';

import {playerPlayState} from '../state/atoms';
import {useAsMemo} from './useAsMemo';
import {Context} from '../components/global/Provider';
import {PlayableTrack, SingleDetailFragment, SingleVariantFragment} from '../types/types';
import {
  assertNumber,
  hasNextChapter,
  hasPreviousChapter,
  nextChapter,
  onClient,
  previousChapter,
  toPlayable,
} from '../utils';

export const useSinglePlayer = ({
  single,
  variant,
}: Props) => {
  const cueRef = useRef<HTMLDivElement>(null);
  const [status, setStatus] = useRecoilState(playerPlayState);
  const {session} = React.useContext(Context);
  // const [locked, setLocked] = React.useState<boolean>(true);

  const [playPercent, setPlayPercent] = React.useState<number>(0);
  const [playSeconds, setPlaySeconds] = React.useState<number>(0);
  const [chapter, setChapter] = React.useState<PlayableTrack>();
  const player = React.useRef<ReactPlayer>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const fullScreenRef = React.useRef<HTMLDivElement>(null);
  const previousState = React.useRef<'playing' | 'paused'>();
  const tracks = React.useMemo(
      () => toPlayable(single, variant?.id),
      [single.id, single.userData, variant?.id],
  );
  const poster = useAsMemo(chapter?.poster);
  const image: Maybe<string> = useAsMemo(single.coverImage?.url);
  const duration = useAsMemo(chapter?.duration) || 0;


  const setTime = React.useCallback(
      (time: number, duration: number, onlyUpdateState?: boolean) => {
        if (!player.current) return;
        if (time < 0) {
          setPlaySeconds(0);
          setPlayPercent(0);
          if (!onlyUpdateState) player.current.seekTo(0, 'seconds');
        } else {
          setPlaySeconds(time);
          setPlayPercent(percentOf(time, duration));
          if (!onlyUpdateState) player.current.seekTo(time, 'seconds');
        }
      },
      [player.current],
  );

  const scrubBackwards = React.useCallback(() => {
    if (!player.current) return;
    const time = playSeconds - 15;
    if (time < 0) {
      const _hasPreviousChapter = hasPreviousChapter(chapter, tracks);
      // If there is a next chapter change to it
      if (_hasPreviousChapter) {
        const _previousChapter = previousChapter(chapter, tracks);
        const duration = assertNumber(_previousChapter?.duration);
        setChapter(_previousChapter);
        setTime(duration + time, duration);
        return;
      }
      setTime(0, duration);
    } else {
      setTime(time, duration);
    }
  }, [playSeconds, chapter, tracks]);

  const handleFullScreen = useCallback(() => {
    if (!document.fullscreenElement) {
      try {
        fullScreenRef.current?.requestFullscreen();
      } catch (err) {
        alert(
            `Error attempting to enable fullscreen mode`,
        );
      }
    } else {
      document.exitFullscreen();
    }
  }, [fullScreenRef.current]);

  const scrubForwards = React.useCallback(() => {
    if (!player.current) return;
    const time = playSeconds + 15;
    const duration = assertNumber(chapter?.duration);
    if (duration < time) {
      // If there is a next chapter change to it
      if (hasNextChapter(chapter, tracks)) {
        const _nextChapter = nextChapter(chapter, tracks);
        const duration = assertNumber(_nextChapter?.duration);
        setChapter(_nextChapter);
        setTime(time - duration, duration);
        return;
      }
      setTime(duration, duration);
    } else {
      setTime(time, duration);
    }
  }, [playSeconds, chapter, tracks]);

  useEffect(() => {
    // Focus the player to prevent scrolling down on space press
    wrapperRef.current?.focus();
    // Add key board events on client side
    onClient(() =>
      document.addEventListener('keydown', (event) => {
        if (event.code === 'ArrowRight') {
          scrubForwards();
        }
        if (event.code === 'ArrowLeft') {
          scrubBackwards();
        }

        // if (event.code === 'Space') {
        //   handleSingleLocked();
        // }
      }),
    );
  }, [scrubBackwards, scrubForwards, wrapperRef.current]);

  // If the single changes reset the state and check whether it is locked
  useEffect(() => {
    (async () => {
      const secondsLoaded = player.current?.getSecondsLoaded?.();
      if (secondsLoaded && secondsLoaded < 1) {
        return;
      }
    })();
  }, [
    single.id,
    chapter,
    session,
    variant,
    player.current,
    previousState.current,
  ]);

  useEffect(() => {
    if (!tracks) return;
    // / Skip past any intro content and explainers
    if (tracks[1] && tracks[0].skip) {
      setChapter(tracks[1]);
      return;
    }

    setChapter(tracks[0]);
  }, [tracks]);

  useEffect(() => {
    if (['playing', 'paused'].includes(status)) {
      previousState.current = status as 'playing' | 'paused';
    }
  }, [status, player.current]);

  // Stop the player when the user is not logged in
  useEffect(() => {
    if (session === undefined) {
      setStatus('paused');
    }
  }, [session]);


  return {
    image,
    poster,
    wrapperRef,
    playPercent,
    playSeconds,
    scrubBackwards,
    scrubForwards,
    duration,
    status,
    setTime,
    chapter,
    setChapter,
    player,
    tracks,
    previousState,
    cueRef,
    handleFullScreen,
    fullScreenRef,
  };
};

type Props = {
  single: SingleDetailFragment;
  variant: Maybe<SingleVariantFragment>;

}
