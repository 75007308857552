import {jsonLdScriptProps} from 'react-schemaorg';
import {VideoObject} from 'schema-dts';
import {SingleDetailFragment} from '../types/types';

export const audioSingleSchema = ({single}: { single: SingleDetailFragment & {
  transcripts: Array<string>;
}}) =>
  jsonLdScriptProps<VideoObject>({
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    'name': single.title,
    'description': single.description,
    'image': single.cellImage?.url,
    'thumbnailUrl': single.coverImage?.url,
    'uploadDate': single.createdAt,
    ...(single.transcripts ? ({
      'transcript': single.transcripts[0],
    }) : ({})),
    'url': `https://mindfulness.com${single.webSlug}`,
    'embedUrl': `https://mindfulness.com${single.webSlug}`,
    ...(single.ratings?.average && single.ratings?.total ?
      {
        aggregateRating: {
          '@type': 'AggregateRating',
          'ratingValue': single.ratings?.average,
          'ratingCount': single.ratings?.total,
        },
      } :
      {}),
  });
