import {useState, useEffect} from 'react';
import {useScroll} from './useScroll';

/**
 *
 * @param {number} threshold - The threshold in pixels to scroll past
 * @return {boolean} - whether or not the page has scrolled past the provided threshold
 */
export function useScrolled(threshold: number): boolean {
  const [scrolled, setScrolled] = useState(false);
  const {scrollY} = useScroll();
  useEffect(() => {
    if (scrollY < threshold) {
      setScrolled(false);
    } else {
      setScrolled(true);
    }
  }, [scrollY, threshold]);

  return scrolled;
}
