import styled from '@emotion/styled';

export const ModalWrapper = styled.div<{
  open: boolean;
}>(
    ({open}) => `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  background-color: rgba(55, 55, 75, 0.6);
  transition: opacity ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  ${
    open ?
      `
    opacity: 1;
    pointer-events: all;
  ` :
      `
    opacity: 0;
    pointer-events: none;
  `
}
`,
);

export const ModalOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

export const ModalInner = styled.div(
    ({theme}) => `

    background-color: ${theme.colors.white};
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    @media (min-width: ${theme.breakpoints.md.min}) {
      max-width: 520px;
      width: 100%;
      position: relative;
      border-radius: ${theme.radius.lg};
      overflow-y: auto;

    }
`,
);

export const CloseButtonWrapper = styled.div(
    ({theme}) => `
position: absolute;
right: 8px;
top: 8px;
`,
);
