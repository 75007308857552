import {gql} from '@apollo/client';
import {
  OrderFragment,
  SessionFragment,
  SubscriptionFragment,
  UnauthenticatedSessionFragment,
} from './fragments';

// Sends and email to the user even if they are unauthed.
export const MUTATE_GENERATE_INSTANT_ACCESS = gql`
  mutation generateInstantAccess($type: InstantAccess!, $email: String, $onto: String) {
    generateInstantAccess(type: $type, email: $email, onto: $onto) {
      link
    }
  }
`;

export const MUTATE_SIGNUP = gql`
  mutation signup(
    $anonymous: AnonymousSignupInput
    $facebook: FacebookSignupLoginInput
    $passwordless: PasswordlessSignupInput
    $apple: AppleSignupLoginInput
    $google: GoogleSignupLoginInput
    $userDetails: UserInput
    $preferences: UserPreferencesInput
    $device: DeviceInput
  ) {
    signup(
      anonymous: $anonymous
      facebook: $facebook
      passwordless: $passwordless
      apple: $apple
      userDetails: $userDetails
      preferences: $preferences
      device: $device
      google: $google
    ) {
      ... on Session {
        ...SessionFragment
      }
    }
  }
  ${SessionFragment}
`;

export const MUTATE_EMAIL_CAPTURE = gql`
  mutation emailSubscribe($email: String!, $cohorts: [String!]) {
    register(email: $email, cohorts: $cohorts) {
      ... on Session {
        user {
          email
        }
      }
      ... on UnauthenticatedSession {
        email
      }
    }
  }
`;

export const MUTATE_LOGIN = gql`
  mutation login($passwordless: PasswordlessLoginInput) {
    login(passwordless: $passwordless) {
      ... on Session {
        ...SessionFragment
      }
    }
  }
  ${SessionFragment}
`;

export const MUTATE_UPDATE_USER = gql`
  mutation updateUser($details: UserUpdateInput!, $id: ID!) {
    updateUser(id: $id, details: $details) {
      id
    }
  }
`;

export const MUTATE_PURCHASE_PLAN_REGISTER = gql`
  mutation subscribeAndRegister(
    $email: String!
    $paymentMethod: String!
    $name: String
    $plan: String!
    $deviceId: String!
    $coupon: String
    $trial: Int!
  ) {
    register(
      email: $email
      userDetails: { fullName: $name }
      device: { deviceId: $deviceId, type: WEB }
    ) {
      ... on Session {
        ...SessionFragment
      }

      ... on UnauthenticatedSession {
        ...UnauthenticatedSessionFragment
      }
    }

    purchasePlanAnonymously(
      email: $email
      name: $name
      stripe: { paymentMethodId: $paymentMethod }
      plan: { alias: $plan, coupon: $coupon, trial: $trial }
    ) {
      ...SubscriptionFragment
    }

    generateInstantAccess(type: PURCHASE, email: $email) {
      link
    }
  }

  ${SessionFragment}
  ${UnauthenticatedSessionFragment}
  ${SubscriptionFragment}
`;

export const MUTATE_PURCHASE_PLAN = gql`
  mutation subscribe(
    $email: String!
    $name: String!
    $paymentMethod: String!
    $plan: String!
    $coupon: String
    $trial: Int!
  ) {
    purchasePlanAnonymously(
      email: $email
      name: $name
      stripe: { paymentMethodId: $paymentMethod }
      plan: { alias: $plan, coupon: $coupon, trial: $trial }
    ) {
      ...SubscriptionFragment
    }

    generateInstantAccess(type: PURCHASE, email: $email) {
      link
    }
  }
  ${SubscriptionFragment}
`;

export const MUTATE_PURCHASE_GIFTCARD = gql`
  mutation giftcard(
    $email: String!
    $paymentMethod: String!
    $plan: String!
    $coupon: String
  ) {
    purchaseGiftCardAnonymously(
      email: $email
      stripe: { paymentMethodId: $paymentMethod }
      plan: { alias: $plan, coupon: $coupon }
    ) {
      id
      code
      url
    }
  }
`;

export const MUTATE_PURCHASE_GIFTCARD_ANONYMOUSLY = gql`
  mutation giftcardRegister(
    $email: String!
    $paymentMethod: String!
    $name: String
    $plan: String!
    $deviceId: String!
    $coupon: String
  ) {
    register(
      email: $email
      userDetails: { fullName: $name }
      device: { deviceId: $deviceId, type: WEB }
    ) {
      ... on Session {
        ...SessionFragment
      }

      ... on UnauthenticatedSession {
        ...UnauthenticatedSessionFragment
      }
    }

    purchaseGiftCardAnonymously(
      email: $email
      stripe: { paymentMethodId: $paymentMethod }
      plan: { alias: $plan, coupon: $coupon }
    ) {
      id
      code
      url
    }
  }
  ${SessionFragment}
  ${UnauthenticatedSessionFragment}
`;

/**
 * TODO: Switch for onymous redeem share
 */
export const MUTATE_REDEEM_SHARE_ANONYMOUSLY = gql`
  mutation redeemShareAnonymously($code: ID!, $email: String!) {
    redeemShareAnonymously(code: $code, email: $email) {
      id
      code
      url
    }
  }
`;

export const MUTATE_EMAIL_LINK_TO_PHONE = gql`
  mutation emailLinkToPhone($link: String!, $email: String!) {
    emailLinkToPhone(link: $link, email: $email)
  }
`;

export const MUTATE_SMS_LINK_TO_PHONE = gql`
  mutation smsLinkToPhone($link: String!, $number: String!) {
    smsLinkToPhone(link: $link, number: $number)
  }
`;

export const MUTATE_JOIN_LIST = gql`
  mutation join($email: String!, $name: String, $lists: [MailList!]) {
    joinLists(email: $email, name: $name, lists: $lists)
  }
`;

export const MUTATE_LEAVE_LIST = gql`
  mutation leave($email: String!, $name: String, $lists: [MailList!]) {
    leaveLists(email: $email, name: $name, lists: $lists)
  }
`;

export const MUTATE_JOIN_COHORT = gql`
  mutation joinCohort($cohort: String!) {
    joinCohort(cohort: $cohort)
  }
`;

export const MUTATE_REDEEM_GIFT_CARD_ANONYMOUSLY = gql`
  mutation redeemGiftCardAnonymously(
    $email: String!
    $deviceId: String!
    $cohorts: [String!]
    $code: ID!
  ) {
    register(
      email: $email
      cohorts: $cohorts
      device: { deviceId: $deviceId, type: WEB }
    ) {
      ... on Session {
        ...SessionFragment
      }

      ... on UnauthenticatedSession {
        ...UnauthenticatedSessionFragment
      }
    }

    redeemAccessPassAnonymously(code: $code, email: $email) {
      id
      code
      url
    }

    generateInstantAccess(type: ACCESS_PASS, email: $email) {
      link
    }
  }
  ${SessionFragment}
  ${UnauthenticatedSessionFragment}
`;

export const MUTATE_PURCHASE_EVENT_PASS = gql`
  mutation purchaseEventPass(
    $event: EventPassInput!
    $plan: PlanPurchaseInput!
    $stripe: StripeSubscriptionInput!
  ) {
    purchaseEventPass(event: $event, plan: $plan, stripe: $stripe) {
      storeTransactionId
    }
  }
`;

export const MUTATE_PURCHASE_EVENT_PASS_ANONYMOUSLY = gql`
  mutation purchaseEventPassAnonymously(
    $email: String!
    $name: String
    $event: EventPassInput!
    $plan: PlanPurchaseInput!
    $stripe: StripeSubscriptionInput!
  ) {
    purchaseEventPassAnonymously(
      email: $email
      name: $name
      event: $event
      plan: $plan
      stripe: $stripe
    ) {
      storeTransactionId
    }
  }
`;

export const MUTATE_UPDATE_PREFERENCES = gql`
  mutation updatePreferences($changes: UserPreferencesInput!) {
    updatePreferences(changes: $changes) {
      user {
        id
      }
    }
  }
`;

export const MUTATE_JOIN_BUNDLE = gql`
  mutation joinBundle($id: String!) {
    joinBundle(id: $id) {
      joined
    }
  }
`;

export const MUTATE_REDEEM_SINGLE_ACCESS = gql`
  mutation redeemSingleAccess($code: ID!) {
    redeemShare(code: $code) {
      code
      url
      type
      contentId
    }
  }
`;

export const MUTATE_TRACK_EVENTS = gql`
  mutation trackEvents($events: [EventInput!]!) {
    trackEvents(events: $events) {
      name
      userId
    }
  }
`;

export const MUTATE_ADD_TO_ORDER = gql`
  mutation addToOrder($order: ID, $purchasable: ID!, $changes: OrderInput) {
    addToOrder(order: $order, purchasable: $purchasable, changes: $changes) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const MUTATE_REMOVE_FROM_ORDER = gql`
  mutation removeFromOrder($order: ID!, $purchasable: ID!) {
    removeFromOrder(order: $order, purchasable: $purchasable) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

