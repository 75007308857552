import * as React from 'react';
import {SocialBanner as SocialBannerProps} from '@mindfulness/cms';

import {Column, Container, Grid, Row, Section} from '../../layout';
import {H3, H4, Small} from '../../typography';
import {BasicImage} from '../../global';
import {SectionIndex} from '../../../types/types';

export const SocialBanner: React.FC<SectionIndex<SocialBannerProps>> = ({
  title,
  background,
  items,
  paddingTop,
  paddingBottom,
}) =>
  items?.length ? (
    <Section
      background={background}
      padding={{
        xs: 30,
        md: 104,
      }}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container maxWidth="lg">
        {title ? (
          <Row
            spacing={{
              xs: 10,
              md: 40,
            }}
          >
            <Column textAlign="center">
              <H3 weight="bold" size="2xl">
                {title}
              </H3>
            </Column>
          </Row>
        ) : null}
        <Grid
          cols={{
            xs: 1,
            md: 3,
          }}
        >
          {items?.map((props) => (
            <a
              href={props.href}
              target="_blank"
              key={props.title}
              rel="noreferrer"
            >
              <Row gap={12} items="center" wrap="nowrap">
                <Column span={6}>
                  {props.image ? (
                    <BasicImage
                      image={props.image}
                      ratio="1:1"
                      decoration="circle"
                    />
                  ) : null}
                </Column>
                <Column span={18}>
                  <H4 weight="bold">{props.handle}</H4>
                  <Small colour="grey7">{props.title}</Small>
                </Column>
              </Row>
            </a>
          ))}
        </Grid>
      </Container>
    </Section>
  ) : null;
