import * as React from 'react';
import {CtaButton} from '@mindfulness/cms';
import {captureException} from '@sentry/nextjs';

import {useForm, useStrategy} from '../../../hooks';
import {Alert, Button, LinkButton} from '../../forms';
import {Context} from '../../global';
import {Column, Row} from '../../layout';
import {Small, Text} from '../../typography';

import {InlineForm, Input} from './InlineActionForm.styles';
import {InlineLoginButtons} from './InlineLoginButtons';

export const InlineActionForm: React.FC<
  React.PropsWithChildren<
    Props & {
      id: string;
      className?: string;
      color?: 'default' | 'white';
      hideButtons?: boolean;
    }
  >
> = ({children, id, color, hideButtons}) => {
  const {data: pageData} = React.useContext(Context);
  const {handlePasswordlessSignup, handleLogout, handleAction, session} =
    React.useContext(Context);
  const titleCaseStrategy = useStrategy();

  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    email: string;
  }>({
    initialValues: {
      email: '',
    },
    onSubmit: async (e, {data, setErrors}) => {
      try {
        await handlePasswordlessSignup?.({
          ...data,
          name: '',
          cohort: pageData?.action?.cohort,
        });
      } catch (err) {
        captureException(err);
        setErrors({
          form: 'An unexpected error occured.',
        });
      }
    },
    validations: {
      email: {
        required: {
          value: true,
          message: 'Please enter your email address',
        },
      },
    },
  });

  return (
    <>
      {session ? (
        <Row spacing={40}>
          <Column>
            <InlineForm>
              <Input
                placeholder="Enter your email address"
                value={
                  session.strategy === 'PASSWORDLESS' ?
                    session.email :
                    `${
                      session.fullName || session.email || session.firstName
                    } (${titleCaseStrategy})`
                }
                readOnly
                type="email"
              />
              <Button
                id="openApp"
                size="md"
                wide
                onClick={() => handleAction?.(session?.email)}
              >
                {children}
              </Button>
            </InlineForm>
          </Column>
          <Column>
            <Text>
              {'Not you? '}
              <LinkButton
                onClick={async () => {
                  await handleLogout?.();
                }}
              >
                Switch Account
              </LinkButton>
            </Text>
          </Column>
        </Row>
      ) : (
        <Row as="form" onSubmit={handleSubmit}>
          <Alert error={errors?.form} />
          <Column>
            <InlineForm>
              <Input
                type="email"
                placeholder="Enter your email address"
                value={data?.email}
                onChange={handleChange('email')}
              />
              <Button id={id} type="submit" size="md" loading={loading}>
                {loading ? 'Loading' : children}
              </Button>
            </InlineForm>
          </Column>
          {!hideButtons && data?.email.length ? (
            <>
              <Column spacing={10}>
                <Small fontSize="xs">Or sign up with</Small>
              </Column>
              <Column spacing={10}>
                <InlineLoginButtons color={color} />
              </Column>
            </>
          ) : null}
        </Row>
      )}
    </>
  );
};

type Props = {
  actionType: CtaButton['actionType'];
};
