import styled from '@emotion/styled';
import {H4} from '../../typography';

export const ReadmoreContainer = styled.div<{
  open: boolean;
  minHeight: number;
  height: number;
}>(
    ({theme, open, height, minHeight}) => `

  position: relative;
  overflow: hidden;
  ${open ? `max-height: ${height}px;` : `max-height: ${minHeight}px;`}

  margin-bottom: ${theme.spacings[16]};
  transition: max-height ease ${height / 1000}s;


`,
);

export const ReadmoreLink = styled(H4)(
    ({theme}) => `
  outline: none;
  &:hover {
    color: ${theme.colors.sleepAccent};
    text-decoration: underline;
  }
`,
);

export const Gradient = styled.div<{
  open: boolean;
}>(
    ({theme, open}) => `
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: height ease 0.2s;
    ${open ? `height: 0;` : `height: ${theme.spacings[16]};`}
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      ${theme.colors.white} 100%
    );
`,
);
