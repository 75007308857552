import {useContext, useEffect, useState} from 'react';
import {isUndefined} from '@mindfulness/utils/maybe';
import {CollectionFeedFragment, Maybe} from '../types/api';
import {Context} from '../components/global';
import {getTagCollection} from '../api/functions/server';
import {useIntersection} from './useIntersection';

const hydrate = async (id: Maybe<string>) => {
  try {
    if (!id) return;
    const collection = await getTagCollection(
        {
          id,
          withUserData: true,
          resultsTake: 100,
        },
        {
          fetchPolicy: 'network-only',
        },
    );
    return collection;
  } catch (e) {
    console.error(`Failed to useHydrateCollection`, e, id);
  }
};

export const useHydratedCollection = (
    staticCollection: Maybe<CollectionFeedFragment>,
    preventHydration?: boolean,
) => {
  const {isIntersecting, ref} = useIntersection(true);
  const [loading, setLoading] = useState(false);
  const {session} = useContext(Context);
  const [collection, setCollection] = useState(staticCollection);

  useEffect(() => {
    if (preventHydration) return;
    (async () => {
      if (!staticCollection?.id || session === null || !isIntersecting) return;
      if (isUndefined(session)) {
        setLoading(true);
        // If the user logs out, remove all the userData
        setCollection(staticCollection);
        setLoading(false);
        return;
      }
      setLoading(true);

      const hydratedCollection = await hydrate(staticCollection.id);

      if (hydratedCollection) {
        setCollection(hydratedCollection);
      }
      setLoading(false);
    })();
  }, [staticCollection?.id, session, isIntersecting, preventHydration]);

  return {
    loading,
    collection,
    ref,
    isIntersecting,
  };
};
