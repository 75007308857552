import React, {useState} from 'react';

import {useCreateModal} from '../../../hooks';
import {Icon} from '..';

import {
  BottomSheetBar,
  BottomSheetBox,
  BottomSheetOverFlow,
  BottomSheetWrapper,
  CloseButtonWrapper,
  DragBox,
  ModalInner,
  ModalOuter,
  ModalWrapper,
} from './BottomSheet.styles';
import {NormalizedButton} from '../../ui/NormalizedButton';

export const BottomSheet: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  const {open, name, closeModal} = useCreateModal(props);
  const [dragging, setDragging] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const inner = ref.current;
    if (!inner) return;
    setTimeout(() => {
      inner.style.setProperty('top', '100px');
    }, 300);
  }, [open]);

  const handleClose = () => {
    setTimeout(() => {
      closeModal?.();
    }, 200);
  };

  const handleDrag = (event: React.TouchEvent) => {
    if (event.changedTouches.length > 0) {
      const touch = event.changedTouches[0];
      const distance = touch.clientY;
      const nextDistance = Math.max(100, distance);
      if (nextDistance < window.innerHeight - 100) {
        ref.current?.style.setProperty('top', `${nextDistance}px`);
      } else {
        ref.current?.style.setProperty('top', `${window.innerHeight}px`);
        ref.current?.style.setProperty('transition', 'top .2s ease');
      }
    }
  };

  const handleDragEnd = () => {
    const inner = ref.current;
    if (!inner) return;
    setDragging(false);
    const top = inner.getBoundingClientRect().top;
    if (top < window.innerHeight - 100) {
      inner.style.setProperty('transition', 'top .2s ease');
      inner.style.setProperty('top', '100px');
    } else {
      inner.style.setProperty('transition', 'top .2s ease');
      inner.style.setProperty('top', `${window.innerHeight}px`);
      handleClose();
    }
  };

  const handleDragStart = () => {
    const inner = ref.current;
    if (!inner) return;
    setDragging(true);
    inner.style.setProperty('transition', null);
  };

  return (
    <ModalWrapper open={open} id={`BottomSheet-${name}`}>
      <ModalOuter onClick={() => closeModal?.()} />
      <ModalInner ref={ref}>
        <BottomSheetWrapper>
          <BottomSheetBox onTouchStart={handleDragStart} onTouchMove={handleDrag} onTouchEnd={handleDragEnd} onTouchCancel={handleDragEnd}>
            <BottomSheetBar>
              <DragBox dragging={dragging} />
            </BottomSheetBar>
          </BottomSheetBox>
          <BottomSheetOverFlow>
            {children}
          </BottomSheetOverFlow>
        </BottomSheetWrapper>
        <CloseButtonWrapper>
          <NormalizedButton
            onClick={() => closeModal?.()}
            id={`BottomSheet-${name}Close`}
            role="button"
            aria-label="Close modal"
          >
            <Icon name="close-modal" size={30} />
          </NormalizedButton>
        </CloseButtonWrapper>
      </ModalInner>
    </ModalWrapper>
  );
};

type Props = {
  name: string;
  open?: boolean;
};
