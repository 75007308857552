import {useEffect, useState} from 'react';
import {Purchase, SanityKeyed} from '@mindfulness/cms';
import {Maybe} from '@mindfulness/utils/maybe';

import {getPurchasables} from '../api/functions/getPurchasble';
import {omitFalsey} from '../utils/array';
import {PurchaseFragment} from '../types/api';
import {useUntil} from './useUntil';
import {useSearchParams} from 'next/navigation';
import {unNull} from '../utils/maybe';

export const usePurchases = (
    purchases: Array<SanityKeyed<Purchase>>,
    couponProps: Maybe<string>,
) => {
  const [loading, setLoading] = useState(false);
  const [hydrated, setHydrated] = useState<Array<HydratedPurchase>>([]);
  const searchParams = useSearchParams();
  const couponParam = unNull(searchParams.get('coupon'));
  const coupon = useUntil([couponParam, couponProps]);

  useEffect(() => {
    (async () => {
      if (!purchases?.length) return;
      setLoading(true);
      try {
        const data = await getPurchasables(
            {
              ids: omitFalsey(purchases.map((p) => p.purchase?.id)),
            },
            coupon,
        );
        if (!data) return;

        setHydrated(
          purchases.map((d) => ({
            ...(data.find((p) => p.id === d.purchase?.id) || {}),
            purchase: d,
          })) as HydratedPurchase[],
        );
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [coupon]);

  return {
    hydratedPurchases: hydrated,
    loading,
  };
};

export type HydratedPurchase = PurchaseFragment & {
  purchase: SanityKeyed<Purchase>;
};
