import * as React from 'react';
import Marquee from 'react-fast-marquee';

import {GradientLink, Seperator} from './HubMarquee.styles';

import {Box, Section} from '../../layout';
import {ReferencedHubMarquee, SectionIndex} from '../../../types/types';
import {Link} from '../../global';

export const HubMarquee: React.FC<SectionIndex<ReferencedHubMarquee>> = ({
  hubs = [],
  background,
}) => {
  const reversedHubs = React.useMemo(() => [...hubs].reverse(), [hubs]);
  return (
    <Section
      sectionPadding={{
        paddingBottom: 40,
        paddingTop: 40,
      }}
      background={background}
    >
      <Marquee direction="right" className="marquee">
        {hubs.map((hub) => (
          <Item {...hub} key={`${hub._id}-1`} />
        ))}
      </Marquee>
      <Marquee className="marquee">
        {[...reversedHubs.slice(6), ...reversedHubs.slice(0, 6)].map((hub) => (
          <Item {...hub} key={`${hub._id}-2`} />
        ))}
      </Marquee>
      <Marquee direction="right" className="marquee">
        {[...hubs.slice(3), ...hubs.slice(0, 3)].map((hub) => (
          <Item {...hub} key={`${hub._id}-3`} />
        ))}
      </Marquee>
    </Section>
  );
};

const Item: React.FC<ReferencedHubMarquee['hubs'][0]> = ({
  slug,
  noHub,
  title,
}) => (
  <Box
    display="flex"
    items="center"
    height={{xs: '42px', md: '110px'}}
    className={title}
  >
    <Link href={`${slug?.current}${noHub ? '/browse' : ''}`} passHref>
      <GradientLink>
        <h2>{title}</h2>
      </GradientLink>
    </Link>
    <Seperator>•</Seperator>
  </Box>
);
