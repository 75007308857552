import * as React from 'react';

import {Context, Icon} from '../../global';

import {AppleButton} from './AppleLogin.styles';

export const AppleLogin: React.FC<Props> = ({
  size = 'md',
  color = 'default',
  redirect,
  setLoading,
}) => {
  const {handleAppleLogin} = React.useContext(Context);
  return (
    <AppleButton
      size={size}
      color={color}
      type="button"
      id="AppleLogin"
      role="button"
      aria-label="Login with Apple"
      onClick={async () => {
        try {
          setLoading(true);
          const res = await handleAppleLogin?.(typeof redirect !== 'function');
          // Redirect will be called when we want to login but not call the page action
          if (typeof redirect === 'function') {
            redirect({email: res?.email});
            return;
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }}
    >
      <Icon name="apple" size={20} />
    </AppleButton>
  );
};

type Props = {
  size?: 'md' | 'sm';
  color?: 'white' | 'default';
  redirect?: (params: { email?: string; name?: string }) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
