import * as React from 'react';
import {DevicesWithBullets as DevicesWithBulletsProps} from '@mindfulness/cms';

import {BackgroundContainer, IconBox} from './DevicesWithBullets.styles';

import {useOnMobile} from '../../../hooks';
import {BackgroundImage} from '../../global';
import {Icon} from '../../global/Icon';
import {H3, Text} from '../../typography';
import {Container, Row, Column, Stack, Section} from '../../layout';
import {SectionIndex} from '../../../types/types';
import {assertString} from '../../../utils';

export const DevicesWithBullets: React.FC<
  SectionIndex<DevicesWithBulletsProps>
> = ({image: responsiveImage, blocks, paddingTop, paddingBottom}) => {
  const image = useOnMobile() ?
    responsiveImage?.mobileImage || responsiveImage?.desktopImage :
    responsiveImage?.desktopImage;
  return (
    <Section
      position="relative"
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <BackgroundContainer>
        {image ? <BackgroundImage image={image} /> : null}
      </BackgroundContainer>
      <Container>
        <Row>
          <Column span={15} />
          <Column span={6}>
            <Stack direction="vertical" space={104}>
              {blocks?.map(({title, icon, paragraph, _key}) => (
                <Stack space={16} key={_key}>
                  <IconBox>
                    <Icon size={30} name={assertString(icon)} />
                  </IconBox>
                  <div>
                    <H3 weight="bold">{title}</H3>
                    <Text colour="grey7">{paragraph}</Text>
                  </div>
                </Stack>
              ))}
            </Stack>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};
