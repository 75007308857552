import * as React from 'react';
import {useSetRecoilState} from 'recoil';

import {BackgroundAPIImage, ModalContext, OverlayModal} from '../../global';
import {Box, Stack} from '../../layout';
import {Title1, Text} from '../../typography';

import {redirectState} from '../../../state/atoms';
import {Button} from '../../forms/Button';
import {useNavigation} from '../../../hooks';
import {CancelButton} from '../../forms/Button/CancelButton';

export const UnlockModal: React.FC = () => {
  const {closeModal} = React.useContext(ModalContext);
  const setRedirect = useSetRecoilState(redirectState);
  const {goTo, router} = useNavigation();
  return (
    <OverlayModal name="unlock" padding={0}>
      <Box maxWidth="640px">
        <Box height="273px" position="relative">
          <BackgroundAPIImage src="/images/unlock-bg.jpg" doNotResize />
        </Box>
        <Box
          padding={{
            xs: 20,
            md: 40,
          }}
          textAlign="center"
        >
          <Stack
            justify="center"
            items="center"
            space={16}
            wide
            direction="vertical"
          >
            <Box marginB={44}>
              <Title1>Start a free trial to play this session</Title1>
              <Text colour="grey8">7-Days free trial, cancel anytime.</Text>
            </Box>

            <Button
              id="unlockButton"
              type="button"
              onClick={() => {
                setRedirect(router.asPath);
                goTo(`/checkout?products=34754bbc-b0c5-4e76-b254-94d9abce8d9f`);
              }}
            >
              Start 7-Day Free Trial
            </Button>

            <CancelButton
              type="button"
              onClick={() =>
                closeModal?.({
                  name: 'unlock',
                })
              }
            >
              Not now
            </CancelButton>
          </Stack>
        </Box>
      </Box>
    </OverlayModal>
  );
};
