import {useContext, useMemo} from 'react';
import {useRouter} from 'next/router';
import {isDefined} from '@mindfulness/utils/maybe';
import {inPast} from '@mindfulness/utils/time';

import {Context} from '../components/global';
import {useModal} from './useModal';
import {useUntil} from './useUntil';
import {BundleDetailFragment} from '../types/api';


export const useLockedBundle = (b?: BundleDetailFragment) => {
  const {session, bundle: contextBundle, data} = useContext(Context);
  const bundle = useUntil([contextBundle, b]);
  const actionModal = useModal('action');
  const router = useRouter();

  const scrollToHero = () => {
    const heroBundle = document.querySelector(
        `[id*='HeroBundle']`,
    );

    if (heroBundle) {
      heroBundle.classList.add('highlight');
      heroBundle.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });

      setTimeout(() => {
        heroBundle?.classList.remove('highlight');
      }, 400);
    }
  };

  const loginAction = () => {
    actionModal?.open({
      props: {
        redirect: () => {
          scrollToHero();
          actionModal?.close();
        },
      },
    });
  };

  const accessAction = () => {
    if (session) {
      scrollToHero();
      return;
    }
    loginAction();
  };


  const isBundle = useMemo(
      () => router.isReady && bundle,
      [bundle, router.isReady],
  );
  const isLocked = useMemo(
      () => !session || (isBundle && bundle?.userData?.bundleLocked !== 'UNLOCKED'),
      [isBundle, bundle, session],
  );

  const isReleased = useMemo(() => {
    const item = (bundle || contextBundle)?.collections.find((collection) => {
      return collection.results.items.find((i) => {
        if (i.__typename === 'Single') {
          return !isDefined(i.releaseDate) || inPast(new Date(i.releaseDate));
        }
      });
    });
    return !!item;
  }, [bundle, contextBundle]);


  const loading = useMemo(() => {
    return !router.isReady || (!data && !bundle) || (session && !bundle?.userData);
  }, [router.isReady, session, bundle?.userData]);

  return {
    isLockedBundle: isBundle && isLocked,
    isReleased,
    bundle: bundle as BundleDetailFragment,
    loginAction,
    loading,
    accessAction,
  };
};
