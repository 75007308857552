import React, {useEffect} from 'react';
import {throttle, round} from 'lodash';
import dynamic from 'next/dynamic';
import {toMilliSeconds} from '@mindfulness/utils/time';
import {isDefined} from '@mindfulness/utils/maybe';

import {VideoTracking} from '../../../types/types';
import {Maybe, MediaType} from '../../../types/api';
import {AspectRatio} from '../../layouts/AspectRatio';
import {Context} from '../../global';
import {useModal} from '../../../hooks';
import {Box} from '../../layout';
import {useTrack} from '../../global/SegmentProvider';
import style from './VideoPlayer.module.css';

const ReactPlayer = dynamic(() => import('react-player/lazy'), {ssr: false});

export const VideoPlayer: React.FC<Props> = ({
  videoUrl,
  autoplay,
  ...props
}) => {
  const player = React.useRef(null);
  const [playPercent, setPlayPercent] = React.useState<number>(0);
  const [playSeconds, setPlaySeconds] = React.useState<number>(0);
  const track = useTrack();
  const {session, data} = React.useContext(Context);
  const modal = useModal('action');
  const [playing, setPlaying] = React.useState(!!autoplay);
  const action = React.useMemo(() => {
    return data?.action;
  }, [data]);

  const delayedTrack = throttle((e: string, props: VideoTracking) => {
    track(e, props);
  }, toMilliSeconds('2 seconds'));

  const defaultEventProps = (): VideoTracking => ({
    video_url: videoUrl,
    play_percent: playPercent,
    play_seconds: playSeconds,
    media_type: MediaType.Video,
  });

  useEffect(() => {
    if (!isDefined(props.playing)) {
      return;
    }
    setPlaying(props.playing);
  }, [props.playing]);

  return (
    <div className={style.videoWrapper}>
      <AspectRatio ratio="16:9">
        <Box position="relative">
          <button
            type="button"
            className={[
              style.videoOverlay,
              ...(playing ? [style.playing] : []),
            ].join(' ')}
            onClick={() => {
              if (
                action?.lockedToCohort &&
                !session?.user?.cohorts.includes(action?.lockedToCohort)
              ) {
                modal.open({
                  props: {
                    unlockUrl: action?.unlockUrl,
                  },
                });
                return;
              }
              setPlaying((isPlaying) => !isPlaying);
            }}
          >
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.6"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                fill="#3D81F1"
              />
              <path
                d="M50.324 37.8393C52.4531 39.0605 52.4531 42.1058 50.324 43.327L37.0419 50.9454C34.9084 52.1691 32.2383 50.6439 32.2383 48.2015V32.9648C32.2383 30.5224 34.9084 28.9972 37.0419 30.2209L50.324 37.8393Z"
                fill="white"
              />
            </svg>
          </button>
          <ReactPlayer
            ref={player}
            url={videoUrl}
            height="100%"
            width="100%"
            autoplay={autoplay}
            muted={autoplay}
            playing={playing}
            controls={true}
            pip={true}
            onProgress={(state) => {
              const seconds = round(state.playedSeconds);
              const percent = round(state.played * 100);

              setPlayPercent(percent);
              setPlaySeconds(seconds);

              if (state.played > 0) {
                delayedTrack('Video playback progressed', {
                  ...defaultEventProps(),
                  play_percent: percent,
                  play_seconds: seconds,
                });
              }
            }}
            onPlay={() => {
              setPlaying(true);
              track('Video playback started', {});
            }}
            onPause={() => {
              setPlaying(false);
              track('Video playback stopped', {
                ...defaultEventProps(),
              });
            }}
            onEnded={() => {
              track('Video playback stopped', {
                ...defaultEventProps(),
              });
            }}
          />
        </Box>
      </AspectRatio>
    </div>
  );
};

type Props = {
  autoplay?: boolean;
  videoUrl: Maybe<string>;
  playing?: boolean;
};
