import styled from '@emotion/styled';

import {PlayerStatus} from '../../../types/types';


export const VideoOverlay = styled.div<{
  status: PlayerStatus;
  hover: boolean;
}>(
    ({status, hover, theme}) => `
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    transition: opacity 0.3s ease;
    padding: 20px;

    @media (min-width: ${theme.breakpoints.md.min}) {
      padding: 60px;
    }

    ${
      ['playing', 'paused'].includes(status) ?
        `
        .timer {
          opacity: 0;
        }
        ${
          hover ?
            `
          &:before {
            opacity: 1;
          }
        ` :
            `
            .timer {
              opacity: 1;
            }
            &:before {
              opacity: 0.2;
            }
            `
        }


    ` :
        status === 'finished' ?
        `
        .timer {
          opacity: 0;
        }
        ` :
        status === 'loading' ?
        `
        .timer {
          opacity: 0;
        }
        ` :
        ''
}

    &:before {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-image: linear-gradient(0deg, rgba(55, 55, 75, 0.6), rgba(55, 55, 75, 0.6));
      transition: opacity 0.3s ease;
      ${
        ['playing', 'paused'].includes(status) ?
          `
        opacity: 0.2;
      ` :
          `

      `
}
    }

;
`,
);
