import {useContext} from 'react';
import * as React from 'react';
import Head from 'next/head';

import {
  GeneratedPageData,
  Maybe,
  SingleDetailFragment,
} from '../../types/types';
import {Context} from '../global';
import {getSizedImage, splitLabel} from '../../utils';
import {useNextSanityImage} from 'next-sanity-image';
import {SanityClientLike} from '@sanity/image-url/lib/types/types';

export const Meta: React.FC<Props> = ({generatedData, single}) => {
  const {client} = useContext(Context);
  const {data} = React.useContext(Context);

  const metaData = React.useMemo(
      () => data || generatedData,
      [data, generatedData],
  );
  const imageProps = useNextSanityImage(
    client as unknown as SanityClientLike,
    metaData?.seoMeta?.image || null,
    {
      imageBuilder: (builder) => {
        return builder.width(1200).height(630);
      },
    },
  );
  const twitterImageProps = useNextSanityImage(
    client as unknown as SanityClientLike,
    metaData?.seoMeta?.image || null,
    {
      imageBuilder: (builder) => {
        return builder.width(500);
      },
    },
  );
  const description = React.useMemo(() => {
    if (single) return single.description;
    if (metaData?._type == 'articles') {
      metaData?.seoMeta?.description || metaData.excerpt;
    }
    return metaData?.seoMeta?.description;
  }, [metaData, single]);

  const metaTitle = React.useMemo(() => {
    if (single) {
      const firstContributor = single.contributors?.[0]?.producer;
      return `${single.title}${
        single.label ? ` ${splitLabel(single.label)}` : ''
      }${firstContributor ? ` by ${firstContributor.fullName}` : ''}`;
    }
    return metaData?.seoMeta?.title || metaData?.title;
  }, [metaData, single]);

  const singleImage = React.useMemo(() => {
    if (!single) return;
    return getSizedImage({
      size: {
        width: 1200,
        height: 630,
      },
      src: single.coverImage?.url,
    });
  }, [single]);

  return (
    <Head>
      <meta property="og:locale" content="en_US" />
      {description ? (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta property="twitter:description" content={description} />
          </>
        ) : null}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@mindfulness_com" />
      <meta name="twitter:site" content="@mindfulness_com" />

      {metaData?.seoMeta?.noIndex ? (
          <meta name="robots" content="noindex" />
        ) : (
          <meta name="robots" content="index, follow" />
        )}
      {metaData?.slug?.current ? (
          <>
            <link
              rel="canonical"
              href={`https://mindfulness.com${metaData.slug.current}`}
            />
            <meta
              property="og:url"
              content={`https://mindfulness.com${metaData.slug.current}`}
            />
          </>
        ) : null}
      {single?.webSlug ? (
          <>
            <link
              rel="canonical"
              href={`https://mindfulness.com${single.webSlug}`}
            />
            <meta
              property="og:url"
              content={`https://mindfulness.com${single.webSlug}`}
            />
          </>
        ) : null}
      {metaTitle ? (
          <>
            <title>{`${metaTitle} | Mindfulness.com`}</title>
            <meta property="og:title" content={metaTitle} />
            <meta property="twitter:title" content={metaTitle} />
          </>
        ) : (
          <>
            <title>Mindfulness.com</title>
            <meta property="og:title" content="Mindfulness.com" />
            <meta property="twitter:title" content="Mindfulness.com" />
          </>
        )}
      {singleImage ? (
          <>
            <meta property="og:image" content={singleImage} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="twitter:image" content={singleImage} />
          </>
        ) : metaData ? (
          <>
            {metaData.seoMeta?.image && (
              <>
                <meta property="og:image" content={imageProps?.src} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta
                  property="twitter:image"
                  content={twitterImageProps?.src}
                />
              </>
            )}
            {metaData.seoMeta?.imageAlt && (
              <meta
                property="og:image:alt"
                content={metaData.seoMeta.imageAlt}
              />
            )}
          </>
        ) : null}
    </Head>
  );
};

type Props = {
  generatedData: Maybe<GeneratedPageData>;
  single: Maybe<SingleDetailFragment>;
};
