import * as React from 'react';
import {Quote as QuoteProps} from '@mindfulness/cms';

import {Blockquote, H4} from '../../typography';
import {Icon, BackgroundImage, BasicImage} from '../../global';
import {Container, Row, Column} from '../../layout';
import {useOnMobile} from '../../../hooks';

import {
  ImageBackground,
  ImageWrapper,
  OffsetWrapper,
  QuoteBackground,
  QuoteImage,
} from './Quote.styles';
import {SectionIndex} from '../../../types/types';
import {Section} from '../../layouts/Section';
import {AspectRatio} from '../../layouts/AspectRatio';

export const Quote: React.FC<SectionIndex<QuoteProps>> = ({
  title,
  image,
  backgroundImage: responsiveImage,
  author,
  background = 'white',
  index,
  ...sectionPadding
}) => {
  const backgroundImage = useOnMobile() ?
    responsiveImage?.mobileImage || responsiveImage?.desktopImage :
    responsiveImage?.desktopImage;
  const decoration = useOnMobile() ? 'none' : 'rounded';
  return (
    <Section padding={0} position="relative" sectionPadding={sectionPadding}>
      <BackgroundImage
        priority={index < 3}
        image={backgroundImage}
        zIndex={-3}
      />
      <QuoteBackground
        hasImage={Boolean(image?.image)}
        hasBackgroundImage={Boolean(backgroundImage)}
        background={background}
      >
        <Container>
          <Row justify="center">
            <Column
              span={24}
              justify="center"
              spacing={{
                xs: 10,
                md: 30,
              }}
            >
              <QuoteImage>
                <Icon name="quote" size={72} />
              </QuoteImage>
            </Column>
            <Column
              span={{
                xs: 24,
                md: 20,
              }}
              spacing={
                author ?
                  {
                    xs: 20,
                    md: 60,
                  } :
                  undefined
              }
            >
              <Blockquote>{title}</Blockquote>
            </Column>
            <Column span={24} spacing={16} justify="center" textAlign="center">
              {author ? <H4 weight="bold">{`—${author}`}</H4> : null}
            </Column>
          </Row>
          {image?.image ? <AspectRatio ratio="32:9" /> : null}
        </Container>
      </QuoteBackground>
      {image?.image ? (
        <Container
          position="relative"
          gutter={0}
          spacing={{
            xs: 0,
            md: 80,
          }}
        >
          <OffsetWrapper>
            <ImageWrapper>
              <BasicImage
                decoration={decoration}
                image={image}
                ratio="16:9"
                priority={index < 2}
              />
            </ImageWrapper>
          </OffsetWrapper>
          <AspectRatio ratio="32:9" zIndex={-1}>
            <ImageBackground />
          </AspectRatio>
        </Container>
      ) : null}
    </Section>
  );
};
