import styled from '@emotion/styled';

export const BackButton = styled.button(({theme}) => `
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0;
  margin: 0 0 16px 0;
  color: ${theme.colors.primary};

  &:hover {
    svg {
      animation: nudge 0.4s linear;
    }
  }

`);
