import styled from '@emotion/styled';

export const ReversedRow = styled.div(
    ({theme}) => `

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: ${theme.breakpoints.md.max}) {
    flex-direction: column-reverse;
  }
 `,
);
