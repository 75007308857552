import React, {useEffect, useState} from 'react';
import {switchEnum} from '@mindfulness/utils/logic';
import Cookies from 'js-cookie';
import {usePathname, useSearchParams} from 'next/navigation';

import {useAsyncEffect} from '../../../hooks/useAsyncEffect';
import {getMobileConfig} from '../../../api/functions/launchDeeplink';
import {Context} from '../Provider';
import {useModal} from '../../../hooks/useModal';
import {getQuestionSheets} from '../../../api/functions/getQuestionSheets';
import {Animation, Maybe, QuestionAnswerInput, QuestionOptionFragment, QuestionSheetFragment} from '../../../types/api';
import {Paragraph, Title2, Title3} from '../../typography';
import {Box, Column, Container, Flex, Row, Stack} from '../../layout';
import {Divider} from '../../ui';
import {Button, CheckButtonItem, CheckButtons} from '../../forms';
import {APIImage} from '../Image';
import {Scale} from '../../ui/Scale';
import {togglePushArrayItem} from '../../../utils/array';
import {FreeText} from '../../forms/FreeText';
import {Lottie} from '../Lottie';
import {submitQuestionSheet} from '../../../api/functions/submitQuestionSheet';
import {mapDeeplink} from '../../../utils/mapDeeplink';
import {isOnClient, onClient} from '../../../utils/next';
import {BackButton} from './Questionnaire.styles';
import {Icon} from '../Icon';
import {BottomSheet} from '../BottomSheet';
import {useTrack} from '../SegmentProvider';
import {atLeast} from '../../../utils';
import {useNewNavigation} from '../../../hooks/useNewNavigation';
import {AspectRatio} from '../../layouts/AspectRatio';

const aspectToRatio = (aspect: number | null | undefined): string => {
  if (!aspect) return '16:9';
  return `${aspect}:1`;
};

export const QuestionnaireFragment: React.FC<React.PropsWithChildren> = () => {
  const {session} = React.useContext(Context);

  const {asPath, removeQueryParam} = useNewNavigation();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const {open} = useModal('questionnaire');
  const {open: openActionModal} = useModal('action');
  const [id, setId] = useState<string>();
  const track = useTrack();

  useAsyncEffect(async () => {
    if (!isOnClient()) return;
    if ((asPath === '/' || asPath === '/feed/web-home') && !Cookies.get('launchDeeplink')) {
      if (!session) return;
      const mobileConfig = await getMobileConfig();


      const launchDeeplink = mobileConfig?.launchDeepLink;
      if (launchDeeplink?.includes('/questionsheet/')) {
        Cookies.set('launchDeeplink', launchDeeplink, {expires: 1, sameSite: 'strict', secure: true});
        const id = launchDeeplink.split('/').pop();
        setId(id);
        return;
      }
    }
    const questionsheetParam = searchParams.get('questionsheet');
    if (questionsheetParam) {
      if (session === null) return;
      if (!session) {
        openActionModal({
          props: {
            redirect: () => {
              if (pathname === '/') {
                window.location.reload();
              }
            },
          },
          onClose: () => {
            removeQueryParam('questionsheet');
            setId(undefined);
          },
        });
        return;
      }
      setId(questionsheetParam);
    }
  }, [session, asPath, searchParams, pathname]);

  useEffect(() => {
    if (id) {
      const onClose = async () => {
        setId(undefined);
        await track('Question sheet closed', {
        });
        await track('Question sheet cancelled', {
        });
      };
      open({
        props: {
          id,
        },
        onClose,
      });
      removeQueryParam('questionsheet');
    }
  }, [id]);


  return (
    <QuestionnaireModal />
  );
};

const QuestionnaireModal: React.FC = () => {
  const {goTo, reload} = useNewNavigation();
  const track = useTrack();
  const {props, close} = useModal('questionnaire');
  const [questionSheets, setQuestionSheets] = React.useState<QuestionSheetFragment[]>([]);
  const [questionSheet, setQuestionSheet] = React.useState<QuestionSheetFragment>();
  const [answers, setAnswers] = React.useState<AnswerState>([]);
  const [error, setError] = React.useState<string>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [animation, setAnimation] = React.useState<{
    lottie: Animation,
    message: string,
  }>();
  const id = props?.id;


  useAsyncEffect(async () => {
    if (!id) return;
    try {
      setLoading(true);
      const questionSheets = await getQuestionSheets({id});
      await track('Questionnaire started', {
        questionnaire: questionSheets[0].id,
      });


      if (questionSheets) {
        setQuestionSheets(questionSheets);
        setQuestionSheet(questionSheets.find(({initial}) => initial) ?? questionSheets[0]);
        // Clear defaults
        setAnswers([]);
        setError(undefined);
        setAnimation(undefined);
      }
      // return;
    } catch (err) {
      console.error(err);
      setError('We were unable to load the questionnaire. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [id]);


  const handleOptionClick = async (option: QuestionOptionFragment, questionSheet: Maybe<QuestionSheetFragment>, value: AnswerValue) => {
    const {nextAction, nextValue} = option;
    const questionId = questionSheet?.id ?? '';
    setAnswers((prev) => {
      const existingIndex = prev.findIndex(({questionId: id}) => id === questionId);
      const updated = {
        questionId,
        answerValue: option.value ?? '',
        value,

      };
      if (existingIndex > -1) {
        prev[existingIndex] = updated;
        return prev;
      }
      return [...prev, updated];
    });

    switchEnum(nextAction as string, {
      CLOSE: () => {
        if (id === 'qns-daily-checkin') {
          reload();
        }
        close();
      },
      SUBMIT: async () => {
        const minTime = questionSheet?.submittingAnimation?.includes('LOADING') ? 150 : 300;
        if (questionSheet?.submittingAnimation) {
          setAnimation({
            lottie: questionSheet.submittingAnimation,
            message: questionSheet.submittingText ?? '',
          });
        }
        try {
          const nextSheet = await atLeast(async () => {
            if (!questionSheet) return;
            const mappedAnswers = answers.map(({answerValue, questionId}) => ({
              answerValue,
              questionId,
            }));

            await track('Questionnaire submitted', {
              answers: mappedAnswers,
              questionnaire: questionSheet.questionnaireId,
            });
            const response = await submitQuestionSheet({
              id: questionSheet.questionnaireId as string,
              answers: mappedAnswers,
            });
            const link = mapDeeplink(response?.deeplink);
            if (link) {
              onClient(() => {
                const url = new URL(`${window.location.protocol}${window.location.host}${link}`);
                goTo(url);
              });
              return;
            }
            if (response?.question) {
              const nextSheet = questionSheets.find((q) => q.slug === response.question);
              return nextSheet;
            }
          }, minTime);
          if (nextSheet) {
            setQuestionSheet(nextSheet);
          } else {
            if (id === 'qns-daily-checkin') {
              reload();
            }
            close();
          }
          setAnimation(undefined);
        } catch (error) {
          setError('We were unable to submit your answers. Please try again later.');
          setAnimation(undefined);
        }
      },
      NEXT_QUESTION: () => {
        const nextQuestion = questionSheets?.find((q) => q.slug === nextValue);
        setQuestionSheet(nextQuestion);
      },
      else: () => console.error('Invalid next action'),
    });
    if (questionSheet) {
      await track('Question sheet answer selected', {
        question_sheet_id: questionSheet.id,
        question_sheet_title: questionSheet.title,
        question_option_id: option.id,
        question_option_title: option.title,
        questionnaire_id: questionSheet.questionnaireId,
      });
    }
  };

  const handleBack = (sheet: QuestionSheetFragment) => {
    const currentIndex = answers.findIndex((a) => a.questionId === sheet.id);
    const nextIndex = currentIndex === -1 ? answers.length - 1 : currentIndex - 1;
    const previous = answers[nextIndex];
    const previousSheet = questionSheets.find((q) => q.id === previous?.questionId);
    setQuestionSheet(previousSheet);
  };

  return (
    <BottomSheet name="questionnaire">
      <Container maxWidth='lg' spacing={34}>
        {loading ? (
          <Row>
            <Column justify='center'>
              <Box maxWidth="140px">
                <Lottie path={`/lottie/LOADING_CIRCLE.json`} />
              </Box>
            </Column>
          </Row>
        ) : error ? (
            <Row>
              <Column>
                <Title3 weight='bold'>An error occurred.</Title3>
                <Paragraph fontWeight={'bold'} spacing={24}>{error}</Paragraph>
                <Button id="ErrorClose" onClick={() => close()} variant="primary">Close</Button>
              </Column>
            </Row>
          ) : animation?.lottie ? (
            <Row>
              <Column justify='center'>
                <Box maxWidth="200px">
                  <Lottie path={`/lottie/${animation.lottie}.json`} />
                </Box>
              </Column>
              <Column justify='center'>
                {animation.message ? (
                  <Paragraph fontWeight={'bold'}>{animation.message}</Paragraph>
                ) : null}
              </Column>
            </Row>
          ) : questionSheet ? (
            <Row>
              {answers.length && !questionSheet.initial ? (
                <Column>
                  <BackButton onClick={() => handleBack(questionSheet)}>
                    <Icon name="arrow-left" size={10} />
                    <span>{'Back'}</span>
                  </BackButton>
                </Column>
              ) : null}
              <Column>
                <Title2 spacing={4}>{questionSheet.title}</Title2>
                <Paragraph >{questionSheet.description}</Paragraph>
                {questionSheet.image ? (
                  <Flex justify="center">
                    <Box width="100%" marginY={32}>
                      <AspectRatio ratio={aspectToRatio(questionSheet.image.aspect)}>
                        <APIImage url={questionSheet.image.url} />
                      </AspectRatio>
                    </Box>
                  </Flex>
                ) : (
                  <Divider spacing={34} />
                )}
                <QuestionSheetOptions
                  {...questionSheet}
                  groupedOptions={questionSheet.groupedOptions}
                  handleOptionClick={handleOptionClick}
                  answers={answers}
                />
              </Column>
            </Row>
          ) : null}

      </Container>
    </BottomSheet>
  );
};

const QuestionSheetOptions: React.FC<QuestionSheetProps> = ({handleOptionClick, answers, ...questionSheet}) => {
  const {groupedOptions, type} = questionSheet;
  if (!type || !groupedOptions?.length) return null;
  const inputProps = {
    answers,
    handleOptionClick,
    questionSheet,
  };
  const item = switchEnum(type, {
    CTA: <QuestionSheetCTA {...inputProps}/>,
    SCALE: <QuestionSheetScale {...inputProps} />,
    SELECT: <QuestionSheetSelect {...inputProps} />,
    MULTI_SELECT: <QuestionSheetMultiSelect {...inputProps} />,
    TEXT: <QuestionSheetText {...inputProps} />,
    else: null,
  });

  return item;
};

const QuestionSheetCTA: React.FC<QuestionSheetInputProps> = ({
  handleOptionClick,
  questionSheet,
}) => {
  const {groupedOptions} = questionSheet;
  if (!groupedOptions?.length) return null;

  return (
    <Stack direction="vertical" space={10}>
      {groupedOptions.map(({__typename, ...o}, index) => {
        if (__typename === 'QuestionOption') {
          const {title, id, value} = o as QuestionOptionFragment;

          return (
            <Button
              key={id}
              id={id ?? ''}
              type="button"
              wide
              variant={index === 0 ? 'primary' : 'alt'}
              onClick={() => {
                handleOptionClick(o, questionSheet, value ?? '');
              }}
            >
              {title}
            </Button>
          );
        }
      })}
    </Stack>

  );
};

const QuestionSheetText: React.FC<QuestionSheetInputProps> = ({
  answers,
  handleOptionClick,
  questionSheet,
}: QuestionSheetInputProps) => {
  const {groupedOptions, slug} = questionSheet;
  if (!groupedOptions?.length) return null;
  const option = groupedOptions[0] as QuestionOptionFragment;
  const answer = answers.find(({questionId}) => questionId === questionSheet.id);
  const defaultVal = (answer?.value ?? '') as string;
  const [value, setValue] = React.useState<string>(defaultVal);
  return (
    <Stack direction='vertical' space={20}>
      <FreeText value={value} onChange={(v) => setValue(v)} />
      <Button id={slug ?? ''} type="button" wide variant="primary" onClick={() => {
        handleOptionClick(option, questionSheet, value);
      }}>
        {option.title ?? 'Next'}
      </Button>
    </Stack>
  );
};

const QuestionSheetMultiSelect: React.FC<QuestionSheetInputProps> = ({
  answers,
  questionSheet,
  handleOptionClick,
}) => {
  const {groupedOptions, slug} = questionSheet;
  if (!groupedOptions?.length) return null;
  const answer = answers.find(({questionId}) => questionId === questionSheet.id);
  const defaultChecked = (answer?.value ?? []) as Array<string>;
  const [selected, setSelected] = React.useState<string[]>(defaultChecked);
  return (
    <Stack direction='vertical' space={20}>
      <CheckButtons
        colour="greyWarm"
        options={groupedOptions.map(({__typename, ...o}) => {
          if (__typename === 'QuestionOption') {
            const {title, id, label, cellImage, description} = o as QuestionOptionFragment;
            return {
              label: title,
              hint: label,
              image: cellImage?.url,
              value: id,
              checked: id && selected.includes(id),
              subtitle: description,
            };
          }
        }) as CheckButtonItem[]}
        onChoice={(value) => {
          setSelected((prev) =>
            togglePushArrayItem(prev, value, groupedOptions.length),
          );
        }}
      />
      <Button disabled={!selected.length} id={slug ?? ''} type="button" wide variant="primary" onClick={() => {
        const selectedOption = groupedOptions.find((o) => 'id' in o && o.id && selected.includes(o.id)) as QuestionOptionFragment;
        handleOptionClick(selectedOption, questionSheet, selected);
      }}>
          Next
      </Button>
    </Stack>
  );
};

const QuestionSheetSelect: React.FC<QuestionSheetInputProps> = ({
  answers,
  questionSheet,
  handleOptionClick,
}) => {
  const {groupedOptions} = questionSheet;
  if (!groupedOptions?.length) return null;
  const answer = answers.find(({questionId}) => questionId === questionSheet.id);

  const defaultChecked = ([...(answer?.answerValue ? [answer.answerValue] : [])]) as Array<string>;
  const [checkedItems, setChecked] = useState<Array<string>>(defaultChecked);
  return (
    <CheckButtons
      colour="greyWarm"
      options={groupedOptions.map(({__typename, ...o}) => {
        if (__typename === 'QuestionOption') {
          const {title, label, id, cellImage, description} = o as QuestionOptionFragment;
          return {
            label: title,
            hint: label,
            value: id,
            checked: checkedItems.includes(id as string),
            image: cellImage?.url,
            subtitle: description,
          };
        }
      }) as CheckButtonItem[]}
      onChoice={(v) => {
        const selected = groupedOptions.find((o) => 'id' in o && o.id === v) as QuestionOptionFragment;
        setChecked((prev) => togglePushArrayItem(prev, v, groupedOptions.length));
        if (selected) {
          handleOptionClick(selected, questionSheet, checkedItems);
        }
      }}
      maxAllowed={1}
    />
  );
};

const QuestionSheetScale: React.FC<QuestionSheetInputProps> = ({
  answers,
  handleOptionClick,
  questionSheet,
}) => {
  const {groupedOptions, slug} = questionSheet;
  if (!groupedOptions?.length) return null;
  const existingAnswer = answers.find(({questionId}) => questionId === questionSheet.id);
  const defaultPercent = existingAnswer?.value ? Number(existingAnswer.value) : 50;
  const [selected, setSelected] = useState<number>(Math.floor((defaultPercent / 100) * (groupedOptions.length - 1)));
  const first = groupedOptions[0] as QuestionOptionFragment;
  const last = groupedOptions[groupedOptions.length - 1] as QuestionOptionFragment;
  const [value, setValue] = useState<number>(defaultPercent);
  const handleChange = (percent: number) => {
    setValue(percent);
    const index = Math.floor((percent / 100) * (groupedOptions.length - 1));
    setSelected(index);
  };
  const selectedOption = groupedOptions[selected] as QuestionOptionFragment;
  return (
    <Stack direction='vertical' space={20}>
      <Scale
        high={first.title ?? ''}
        low={last.title ?? ''}
        value={value}
        onChange={handleChange}
      />
      <Button
        id={slug ?? ''}
        type="button"
        wide
        variant="primary"
        onClick={() => {
          handleOptionClick(selectedOption, questionSheet, value);
        }}
      >
        Next
      </Button>
    </Stack>
  );
};

type QuestionSheetInputProps = {
  answers: AnswerState;
  handleOptionClick: HandleOptionClick;
  questionSheet: QuestionSheetFragment;
}

type QuestionSheetProps = QuestionSheetFragment & {
  handleOptionClick: HandleOptionClick;
  answers: AnswerState;
};

type AnswerState = Array<QuestionAnswerInput & {
  value: AnswerValue;
}>;

type AnswerValue = string | number | string[];

type HandleOptionClick = (option: QuestionOptionFragment, sheet: QuestionSheetFragment, value: AnswerValue) => void;
