import * as React from 'react';
import {RichText, SignupCover} from '@mindfulness/cms';

import {LoadingSpinner, LoginForm, SignUpForm, VerifyForm} from '../../ui';
import {SectionIndex} from '../../../types/types';
import {LoadingBoxOverlay} from './AuthForm.styles';

export const AuthForm: React.FC<
  SectionIndex<Omit<SignupCover, '_type'>> & Props
> = ({view, email, loading, ...props}) => {
  return (
    <>
      <LoadingBox loading={loading} />
      {view === 'verify' ? (
        <VerifyForm email={email} {...props} />
      ) : view === 'login' ? (
        <LoginForm email={email} {...props} />
      ) : (
        <SignUpForm suggestedEmail={email} {...props} />
      )}
    </>
  );
};

const LoadingBox: React.FC<{loading: boolean}> = ({loading}) => {
  return (
    <LoadingBoxOverlay open={loading}>
      {loading ? null : (
        <LoadingSpinner />
      )}
    </LoadingBoxOverlay>
  );
};

type Props = {
  email?: string;
  view?: string;
  setView?: React.Dispatch<React.SetStateAction<string>>;
  token?: string;
  name?: string;
  paragraph?: RichText;
  subtitle?: string;
  notice?: string;
  title?: string;
  requireVerify?: boolean;
  onVerify?: (params: { email?: string; name?: string }) => void;
  redirect?: (params: { email?: string; name?: string }) => void;
  verifyLink?: string;
  faqLink?: string;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
