import styled from '@emotion/styled';
import {Alignment} from '@mindfulness/cms';
import {getAlign} from '../../../utils';

export const TextUnderImage = styled.div<{
  alignment?: Alignment;
}>(
    ({theme, alignment}) => `
  z-index: 0;
  padding-bottom: 1rem;
  ${alignment ? getAlign(theme, alignment) : ''}

  @media (min-width: ${theme.breakpoints.md.min}) {
    padding-bottom: 2rem;
  }
`,
);
