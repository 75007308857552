import styled from '@emotion/styled';
import {Box} from '../../layout';
import {stopGlobalPropForwarding} from '../../../utils';
import {Theme} from '@emotion/react';
const headerHeight = {
  xs: '77px',
  md: '97px',
};

export const OffsetWrapper = styled.div<{
  minimal?: boolean;
  announcement?: boolean;
  header: boolean;
}>(
    ({theme, minimal, announcement, header}) => `
  ${
    header ?
      `
    min-height: ${minimal ? 0 : announcement ? 115 : 78}px;

    @media (min-width: ${theme.breakpoints.lg.min}) {
      min-height: ${minimal ? 0 : announcement ? 136 : 92}px;
    }
  ` :
      ``
}

`,
);

export const LogoAnchor = styled.a(
    ({theme}) => `
  display: flex;
  align-items: center;
  height: ${headerHeight.xs};
  @media (min-width: ${theme.breakpoints.md.min}) {
    height: ${headerHeight.md};
  }
`,
);

export const DiscountWrapper = styled.div(
    () => `
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: 9;
`,
);
export const StyledHeader = styled.header<{
  minimal?: boolean;
  scrolled?: boolean;
}>(
    ({theme, minimal, scrolled}) => `
  position: fixed;
  top: 0;
  width: 100%;

  z-index: 10;
  transition: background-color 0.2s ease, border-bottom-color 0.2s ease;
  background-color: ${
    minimal && !scrolled ? 'transparent' : theme.colors.white
};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${
    minimal && !scrolled ? 'transparent' : theme.colors.grey4
};

  .logo-header-light {
    ${scrolled ? 'opacity: 0' : 'opacity: 1'};
    transition: opacity 0.2s ease;
    position: absolute;
    z-index: 1;
    top: 0;
  }

  .cta-wrapper {
    display: ${minimal && !scrolled ? 'none' : 'block'};
  }

`,
);

export const Nav = styled.nav(
    ({theme}) => `
  margin-left: 24px;
  display: none;
  @media (min-width: ${theme.breakpoints.xl.min}) {
    display: flex;
  }
`,
);

export const NavItem = styled.div<{
  active?: boolean;
}>(
    ({theme, active}) => `
  border-bottom: 3px solid transparent;
  transition: ease all 0.2s;
  font-family: var(--font-heading);
  ${
    active ?
      `
    border-bottom-color: ${theme.colors.primary};
  ` :
      ''
}
  &:hover {
    border-bottom-color: ${theme.colors.primary};
    color: ${theme.colors.primary};
  }

`,
);

export const NavLabel = styled.span(
    ({theme}) => `
  font-weight: ${theme.fontWeights.bold};
  height: 97px;
  display: flex;
  align-items: center;
  padding-left: 13px;
  padding-right: 13px;
`,
);

export const MegaMenuContainer = styled.div(
    ({theme}) => `
  border-top: 1px ${theme.colors.grey4} solid;
  position: absolute;
  background: ${theme.colors.white};
  left: 0;
  right: 0;
  top: 100%;
  opacity: 0;
  pointer-events: none;
  padding-top: 50px;
  padding-bottom: 133px;
  color: ${theme.colors.grey9};
`,
);

export const ArrowLabel = styled.span(
    ({theme}) => `
  padding-right: ${theme.spacings[30]};
  display: inline-block;

`,
);

export const ArrowLink = styled.a(
    ({theme}) => `
  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.bold};
  border-bottom: 3px solid ${theme.colors.primary};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${theme.spacings[10]};
  padding-bottom: ${theme.spacings[10]};

  &:hover {
    svg {
      animation: nudge 0.4s linear;
    }
  }
`,
);

export const ArticleLink = styled.a(
    ({theme}) => `
  margin-bottom: ${theme.spacings[12]};
  padding-bottom: ${theme.spacings[12]};
  border-bottom: 1px solid ${theme.colors.grey4};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.colors.primary};
  &:hover {
    svg {
      animation: nudge 0.4s linear;
    }
  }

`,
);

export const DrawerWrapper = styled.div<{
  open: boolean;
  announcement?: boolean;
}>(
    ({theme, open, announcement}) => `
  padding-top: ${announcement ? '115px' : headerHeight.xs};
  height: 100vh;
  height: 100svh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.white};
  z-index: 9;
  transition: transform ease 0.3s;
  transform: ${open ? 'translateX(0)' : 'translateX(-100%)'};
  display: flex;
  flex-direction: column;
`,
);

export const DrawerFooter = styled.footer(
    ({theme}) => `
  padding-top: ${theme.spacings[30]};
  padding-bottom: ${theme.spacings[60]};
  border-top: ${theme.colors.grey4} 1px solid;
`,
);

export const DrawerButton = styled.button<{
  color: keyof Theme['colors'];
}>(
    ({theme, color}) => `
  color: ${theme.colors[color]};
`,
);

export const DrawerNavList = styled.div(
    ({theme}) => `
  padding-top: ${theme.spacings[30]};
  padding-bottom: ${theme.spacings[30]};
  > * + * {
    border-top: ${theme.colors.grey4} 1px solid;
  }
`,
);

export const DrawerNavItem = styled.a(
    ({theme}) => `
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${theme.spacings[30]};
  padding-bottom: ${theme.spacings[30]};
  color: ${theme.colors.primary};
`,
);

export const DrawerNavLabel = styled.span(
    ({theme}) => `
  font-size: ${theme.fontSizes['2xl']};
  font-weight: ${theme.fontWeights.bold};
  text-align: left;
  color: ${theme.colors.grey9};
`,
);

export const DrawerInner = styled.div(
    ({theme}) => `
  padding-left: ${theme.spacings[16]};
  padding-right: ${theme.spacings[16]};
  box-shadow: inset 0px 20px 20px -20px rgb(148 148 148 / 25%);
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
`,
);

export const DrawerSubMenu = styled.div<{
  open: boolean;
}>(
    ({theme, open}) => `
  position: absolute;
  transition: transform ease 0.3s;
  transform: ${open ? 'translateX(0)' : 'translateX(-100%)'};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 21;
  background: ${theme.colors.white};
`,
);

export const SubMenuHeader = styled.header(
    ({theme}) => `
  border-bottom: ${theme.colors.grey4} 1px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacings[10]} ${theme.spacings[16]};
`,
);

export const SubMenuMain = styled.main(
    ({theme}) => `
  padding: ${theme.spacings[30]} ${theme.spacings[16]};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 62px;
  overflow: auto;
`,
);

export const BackButton = styled.button(
    ({theme}) => `
  color: ${theme.colors.primary};
  display: flex;
  align-items: center;
`,
);

export const BackButtonLabel = styled.span(
    ({theme}) => `
  font-size: ${theme.fontSizes.lg};
  font-weight: ${theme.fontWeights.bold};
  text-align: left;
  color: ${theme.colors.grey9};
  margin-left: ${theme.spacings[10]};
`,
);

export const SeeAllLabel = styled.span(
    ({theme}) => `
  padding-right: ${theme.spacings[10]};
  display: inline-block;
`,
);

export const SeeAllLink = styled.a(
    ({theme}) => `
  color: ${theme.colors.primary};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.sm};
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${theme.spacings[10]};
  padding-bottom: ${theme.spacings[10]};
`,
);

export const NoHoverLink = styled(Box, {
  shouldForwardProp: stopGlobalPropForwarding,
})(
    ({theme}) => `
  a:hover {
    color: ${theme.colors.white}
  }
`,
);


export const TooltipHeader = styled.header(
    ({theme}) => `
display: flex;
gap: ${theme.spacings[8]};
flex-wrap: nowrap;
align-items: center;
border-bottom: 1px solid ${theme.colors.grey4};
padding: ${theme.spacings[20]};
`,
);

