import * as React from 'react';
import Image from 'next/legacy/image';

import {ReversedRow} from './DeviceTestimonials.styles';

import {
  ReferencedDeviceTestimonials,
  SectionIndex,
} from '../../../types/types';
import {
  Container,
  Row,
  Column,
  Section,
  Reel,
  Box,
  Flex,
  Stack,
} from '../../layout';
import {
  BasicImage,
  BlockContent,
  CallToActionButton,
  ResponsiveBackgroundImage,
  ResponsiveImage,
} from '../../global';
import {H2, H3, H4, Text, TitleLarge} from '../../typography';
import {Logo} from '../../brand';
import {DownloadLinks} from '../../ui';
import {
  EndReelUncontainedSpacer,
  ReelUncontainedSpacer,
} from '../../ui/Reel/Reel.styles';
import {assertString} from '../../../utils';
import {VerticalDivider} from '../../ui/VerticalDivider';

export const DeviceTestimonials: React.FC<Props> = ({
  additionalLogo,
  content,
  backgroundImage,
  image,
  showLogos,
  testimonialTitle,
  testimonialParagraph,
  testimonials,
  _type,
  index,
}) => (
  <Section position="relative" sectionPadding={{
    paddingTop: 160,
    paddingBottom: 80,
  }}>
    <ResponsiveBackgroundImage
      image={backgroundImage}
      position="top"
      size="cover"
      alt=""
      priority={index === 0}
    />

    <Container spacing={34}>
      <ReversedRow>
        <Column
          width={{
            xs: '100%',
            md: '50%',
          }}
          position="relative"
          span={{
            xs: 24,
            md: 12,
          }}
          spacing={{
            xs: 30,
            sm: 60,
            md: 0,
          }}
        >
          <Box width="100%">
            <ResponsiveImage
              image={image}
              layout="responsive"
              alt={assertString(image?.desktopImage?.alt)}
              priority={index === 0}
            />
          </Box>
          <Box
            display="flex"
            justify="center"
            position="absolute"
            bottom="30px"
            width="100%"
          >
            <DownloadLinks hideTitle />
          </Box>
        </Column>
        <Column
          spacing={10}
          color="white"
          span={{
            xs: 24,
            md: 12,
            lg: 9,
          }}
          textAlign={{
            xs: 'center',
            md: 'start',
          }}
        >
          <Box
            spacing={16}
            items="center"
            display={
              showLogos ?
                'block' :
                {
                  xs: 'none',
                  md: 'block',
                }
            }
          >
            <Stack direction="horizontal" space={10} items="center">
              {additionalLogo && (
                <>
                  <Box width="40%">
                    <BasicImage
                      layout="responsive"
                      image={additionalLogo}
                      alt="Mindfulness.com"
                      priority={index === 0}
                    />
                  </Box>
                  <VerticalDivider background="grey9" height="3rem" />
                </>
              )}
              <Box width="40%">
                <Logo view="light" />
              </Box>
            </Stack>
          </Box>
          <TitleLarge as={index === 0 ? 'h1' : undefined} spacing={20}>
            {content?.title}
          </TitleLarge>
          <BlockContent
            content={content?.paragraph}
            background={'grey9'}
            spacing={16}
            fontWeight="bold"
          />
          {content?.cta?.text ? (
            <CallToActionButton
              id={`${_type}-${index}`}
              {...content?.cta}
              inlineColor="white"
            >
              {content?.cta?.text}
            </CallToActionButton>
          ) : null}
        </Column>
      </ReversedRow>
    </Container>
    <Container
      spacing={{
        xs: 50,
        md: 72,
      }}
    >
      <Row justify="space-evenly" items="center">
        <Column textAlign="center">
          <H2 spacing={10}>{testimonialTitle}</H2>
          <H4>{testimonialParagraph}</H4>
        </Column>
      </Row>
    </Container>
    <Reel
      show={{
        xs: 1.2,
        md: 2.4,
        lg: 3,
      }}
      hideArrows
      scroll={1}
      center
      Spacer={() => <ReelUncontainedSpacer />}
      EndSpacer={() => <EndReelUncontainedSpacer />}
    >
      {testimonials?.map(({_key, testimonial}) => (
        <Box
          key={_key}
          width="100%"
          height="100%"
          paddingT={16}
          paddingB={34}
          paddingR={20}
        >
          <Box
            radius="lg"
            shadow="medium"
            paddingT={30}
            paddingB={20}
            paddingX={16}
            background="white"
            height="100%"
            display="flex"
            flexDirection="column"
            justify="space-between"
          >
            {testimonial ? (
              <>
                <H3 spacing={20}>{testimonial.review}</H3>
                <Flex justify="space-between" items="center">
                  <Text fontSize="sm" fontWeight="bold">
                    {testimonial.user}
                  </Text>
                  <Box>
                    <Image
                      src="/images/review-stars.png"
                      width={170}
                      height={30}
                      alt="5 stars"
                    />
                  </Box>
                </Flex>
              </>
            ) : null}
          </Box>
        </Box>
      ))}
    </Reel>
  </Section>
);

type Props = SectionIndex<ReferencedDeviceTestimonials>;
