import {Person} from 'schema-dts';
import {jsonLdScriptProps} from 'react-schemaorg';
import {Teachers} from '@mindfulness/cms';
import {ProducerFragment} from '../types/types';

import {getOrganisation, mindfulnessOrganization} from './Organization';

export const producerSchema = (
    producer: ProducerFragment,
    additionalInfo?: Teachers,
) =>
  jsonLdScriptProps<Person>({
    '@context': 'https://schema.org',
    '@type': 'Person',
    'name': producer.fullName,
    'familyName': producer.fullName.split(' ')[1],
    'givenName': producer.fullName.split(' ')[0],
    'knowsAbout': [
      'https://en.wikipedia.org/wiki/Mindfulness',
      ...(additionalInfo?.knowsAbout || []),
    ],
    'url': `https://mindfulness.com${producer.webSlug}`,
    'image': producer.cellImage?.url,
    'affiliation': mindfulnessOrganization,
    'description': additionalInfo?.description || producer.blurb || undefined,
    ...additionalInfo,
    ...(additionalInfo?.worksFor ?
      {worksFor: getOrganisation(additionalInfo?.worksFor)} :
      {}),
  });
