import * as React from 'react';
import {TextImageOverlay as TextImageOverlayProps} from '@mindfulness/cms';

import {StyledContainer, TextWrapper} from './TextImageOverlay.styles';

import {
  BackgroundImage,
  BlockContent,
  CallToActionButton,
} from '../../global';
import {useOnMobile} from '../../../hooks';
import {H1} from '../../typography';
import {Container, Section} from '../../layout';
import {SectionIndex} from '../../../types/types';

export const TextImageOverlay: React.FC<
  SectionIndex<TextImageOverlayProps>
> = ({
  title,
  image: responsiveImage,
  paragraph,
  cta,
  _type,
  paddingTop,
  paddingBottom,
  index,
}) => {
  const image = useOnMobile() ?
    responsiveImage?.mobileImage || responsiveImage?.desktopImage :
    responsiveImage?.desktopImage;
  return (
    <Section
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
      padding={{
        xs: 0,
        md: 80,
      }}
    >
      <Container gutter={{xs: 0, md: 16}}>
        <StyledContainer>
          {image ? <BackgroundImage image={image} /> : null}
          <TextWrapper direction="vertical" space={16}>
            {title ? (
              <H1 as={index === 0 ? undefined : 'h2'} size="2xl">
                {title}
              </H1>
            ) : null}
            <BlockContent
              background="white"
              fontSize="md"
              content={paragraph}
            />
            {cta?.text ? (
              <CallToActionButton id={`${_type}-${index}`} {...cta}>
                {cta.text}
              </CallToActionButton>
            ) : null}
          </TextWrapper>
        </StyledContainer>
      </Container>
    </Section>
  );
};
