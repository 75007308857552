import {FAVOURITE_BUNDLE} from '../mutations/favouriteBundle';
import {FavouriteBundleMutation, FavouriteBundleMutationVariables} from '../../types/api';
import {getGraphClient} from '../../utils/graph';


export const favouriteBundle = async (variables: FavouriteBundleMutationVariables) => {
  const {data} = await getGraphClient().mutate<FavouriteBundleMutation>({
    mutation: FAVOURITE_BUNDLE,
    variables,
  });
  return data?.favouriteBundle;
};
