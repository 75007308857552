import styled from '@emotion/styled';

export const FacebookButton = styled.button<{
  size: 'sm' | 'md';
  color: 'white' | 'default';
}>(
    ({theme, size, color}) => `
    cursor: pointer;
    outline: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    border-radius: 100px;
    transition: ease 0.2s all;
    height: 2.8125rem;

    ${color === 'white' ?
      `
        background-color: ${theme.colors.white};
        border: 1px solid ${theme.colors.grey7};
        ` :
      `
      background-color: ${theme.colors.facebook};
      border: 1px solid ${theme.colors.facebook};
    `
}
    ${size === 'md' ?
      `
      width: 100%;
    ` :
      `
      width: 84px;
    `
}

`,
);
