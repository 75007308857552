import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {ResponsiveValue} from '../../types/types';
import {
  buildResponsiveCss,
  getColumnGap,
  getGridColumns,
  getRowGap,
} from '../../utils';

export const Grid = styled.div<Props>(
    ({theme, rowGap = 16, columnGap = 16, cols = 1, spacing, countCols}) => {
      return `
  display: grid;
  ${getRowGap(theme, rowGap)}
  ${getColumnGap(theme, columnGap)}
  width: 100%;
  ${spacing ? `margin-bottom: ${theme.spacings[spacing]};` : ``}
  ${getGridColumns(theme, cols)}
  ${buildResponsiveCss({
    theme,
    val: countCols,
    withMax: true,
    format: (val) => `
    > *:nth-child(1n+${val + 1}) {
      display: none;
    }
  `,
  })}
`;
    },
);

type Props = {
  rowGap?: ResponsiveValue<keyof Theme['spacings']>;
  columnGap?: ResponsiveValue<keyof Theme['spacings']>;
  spacing?: keyof Theme['spacings'];
  cols?: ResponsiveValue<number>;
  countCols?: ResponsiveValue<number>;
};
