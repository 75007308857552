import {useContext} from 'react';

import {ToastContext} from '../components/global';
import {Text} from '../components/typography';

export const useToast = () => {
  const {handleAdd} = useContext(ToastContext);
  const alreadyAuthenticated = () =>
    handleAdd?.(<Text>{`Already logged in`}</Text>);
  const verify = () =>
    handleAdd?.(<Text>{`✅  Verification code sent successfully`}</Text>);

  const facebookLoginRejection = () =>
    handleAdd?.(<Text>{`Unable to sign in with Facebook`}</Text>);

  return {
    verify,
    handleAdd,
    alreadyAuthenticated,
    facebookLoginRejection,
  };
};
