import * as React from 'react';
import {SingleInlinePlayer as SingleInlinePlayerProps} from '@mindfulness/cms';

import {H3, H4} from '../../typography';
import {
  Box,
  Card,
  Column,
  Container,
  Row,
  Section,
  Stack,
} from '../../layout';
import {APIImage, Link, Icon} from '../../global';
import {SectionIndex, SingleFragment} from '../../../types/types';

import {Padding, PlayButton} from './SingleInlinePlayer.styles';

export const SingleInlinePlayer: React.FC<
  SectionIndex<SingleInlinePlayerProps & SingleFragment>
> = ({background, paddingTop, paddingBottom, webSlug, mobile, ...single}) => {
  return webSlug && single ? (
    <Section
      background={background}
      padding={0}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Link href={mobile ? single.deepLink || '' : webSlug} passHref>
        <Box as="a" display="block" paddingB={30}>
          <Container maxWidth="prose">
            <Card padding={16} shadow="soft">
              <Box width="100%">
                <Row gap={20}>
                  <Column
                    span={{
                      xs: 24,
                      md: 5,
                    }}
                  >
                    {single?.cellImage?.url ? (
                      <APIImage
                        url={single.cellImage.url}
                        decoration="rounded"
                        ratio="1:1"
                      />
                    ) : null}
                  </Column>
                  <Column
                    span={{
                      xs: 18,
                      md: 16,
                    }}
                    textAlign="start"
                  >
                    <Padding>
                      <Stack direction="vertical" justify="space-between">
                        <div>
                          <H4 colour="grey7">
                            {single?.contributors?.[0]?.producer.fullName}
                          </H4>
                          <H3 weight="bold">{single?.title}</H3>
                        </div>
                        <H4 colour="primary">{single?.label}</H4>
                      </Stack>
                    </Padding>
                  </Column>
                  <Column span={{xs: 6, md: 3}} justify="end" items="end">
                    <PlayButton>
                      <Icon name="play-filled" size={24} />
                    </PlayButton>
                  </Column>
                </Row>
              </Box>
            </Card>
          </Container>
        </Box>
      </Link>
    </Section>
  ) : null;
};
