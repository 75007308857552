import styled from '@emotion/styled';

export const AbsoluteImage = styled.div<{
  top: number;
  left: number;
  width: number;
  height: number;
}>(
    ({theme, top, left, width, height}) => `
top: ${top}%;
left: ${left}%;
height: auto;
position: absolute;
width: ${width / 2.5}px;
height: ${height / 2.5}px;
@media (min-width: ${theme.breakpoints.md.min}) {
  width: ${width}px;
  height: ${height}px;
}
`,
);

export const Section = styled.section(
    ({theme}) => `
    overflow: hidden;
    position: relative;
    background-color: ${theme.colors.greyWarm};
    height: 1000px;
    @media (min-width: ${theme.breakpoints.md.min}) {
      height: 1100px;
    }
`,
);
