
import {CREATE_SHARE} from '../mutations/createShare';
import {
  CreateShareMutation,
  CreateShareMutationVariables,
} from '../../types/api';
import {getGraphClient} from '../../utils/graph';

export const createShare = async (
    variables: CreateShareMutationVariables,
) => {
  const {data} = await getGraphClient().mutate<CreateShareMutation>({
    mutation: CREATE_SHARE,
    variables,
  });
  return data?.createShare;
};
