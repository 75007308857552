import React, {useContext} from 'react';

import {Context, NewLink} from '../../global';
import {Stack} from '../../layout';
import {Text} from '../../typography';
import {switchSpace} from '../../../utils';
import {useModal} from '../../../hooks/useModal';

export const ProfileMenu: React.FC<{ fontSize?: 'lg' | 'base' }> = ({
  fontSize = 'lg',
}) => {
  const {handleLogout} = useContext(Context);
  const {close} = useModal('drawer');
  return (
    <Stack space={20} direction="vertical">
      <NewLink href={`/profile`}>
        <Text fontSize={fontSize}>Profile</Text>
      </NewLink>
      {switchSpace({
        'mindfulness.com': (
          <NewLink href={`/feed/your-library`}>
            <Text fontSize={fontSize}>Your Library</Text>
          </NewLink>
        ),
        'else': null,
      })}

      <NewLink href={`/checkout`}>
        <Text fontSize={fontSize}>Cart</Text>
      </NewLink>
      <Text
        as="button"
        onClick={() => {
          handleLogout?.();
          close();
        }}
        colour="sleepPrimary"
        fontSize={fontSize}
        align="left"
      >
        Sign out
      </Text>
    </Stack>
  );
};
