import styled from '@emotion/styled';

export const StyledTextArea = styled.textarea(({theme}) => `
  width: 100%;
  height: 100px;
  background-color: ${theme.colors.greyWarm};
  border-radius: ${theme.radius.sm};
  padding: ${theme.spacings[4]} ${theme.spacings[8]};
  resize: none;
  height: 250px;
  box-sizing: border-box;
  font-size: ${theme.fontSizes.lg};
  outline: none;
`);
