import React, {useCallback, useMemo, useRef} from 'react';
import type ReactPlayer from 'react-player';
import {Maybe} from '@mindfulness/utils/maybe';

import {Box, Stack} from '../../layout';
import {Text} from '../../typography';
import {useSecondsToMinutes} from '../../../hooks';


import style from './ProgressSlider.module.css';
import {
  SingleVariantFragment,
  PlayableTrack,
  PlayerStatus,
} from '../../../types/types';
import {assertNumber, getTrackDurations} from '../../../utils';
import {ChapterLabel} from './ProgressSlider.styles';

const getSliderWidth = (
    activeChapter: Maybe<PlayableTrack>,
    chapter: PlayableTrack,
    playedPercent: number,
): string => {
  if (!activeChapter) return '0%';
  if (activeChapter.index === chapter.index) {
    return `${playedPercent}%`;
  }
  if (activeChapter.index > chapter.index) {
    return '100%';
  }
  return '0%';
};

export const ProgressSlider: React.FC<Props> = (props) => {
  const {playedSeconds, tracks, chapter} = props;
  const track = useRef<HTMLDivElement>(null);

  const playedTracksDuration = useMemo(() => {
    if (!tracks || !chapter) return 0;
    const playedTracks = tracks.slice(0, chapter.index);
    return getTrackDurations(playedTracks);
  }, [tracks, chapter]);

  const totalDuration = React.useMemo(
      () => getTrackDurations(tracks),
      [tracks],
  );
  const durationPercent = React.useMemo(
      () => totalDuration / 100,
      [totalDuration],
  );

  const played = useSecondsToMinutes(playedSeconds + playedTracksDuration);
  const durationMins = useSecondsToMinutes(totalDuration);

  return (
    <Box width="100%" ref={track} marginT={20}>
      <Stack direction="horizontal" space={4} spacing={5} wrap="nowrap">
        {tracks ? (
          tracks.map((c) => (
            <Chapter
              key={c.id}
              currentChapter={c}
              durationPercent={durationPercent}
              totalDuration={totalDuration}
              track={track}
              {...props}
            />
          ))
        ) : (
          <div className={style.sliderContainer}
            style={{
              width: '100%',
            }}
          >
            <div className={style.sliderTrack} />
          </div>
        )}
      </Stack>
      <Stack justify="space-between">
        <Text fontSize="xs">{played}</Text>
        <Text fontSize="xs">{durationMins}</Text>
      </Stack>
    </Box>
  );
};

const Chapter: React.FC<
  Props & {
    currentChapter: PlayableTrack;
    totalDuration: number;
    durationPercent: number;
    track: React.RefObject<HTMLDivElement>;
  }
> = ({
  currentChapter,
  durationPercent,
  player,
  tracks,
  setTime,
  setChapter,
  chapter,
  totalDuration,
  playedPercent,
  track,
}) => {
  const {id, duration, chapterLabel} = currentChapter;

  const width = `${(duration || 0) / durationPercent}%`;
  const sliderRef = useRef<HTMLDivElement>(null);
  const handleScrub = useCallback(
      (
          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          clickedChapter: PlayableTrack,
          elem: React.RefObject<HTMLDivElement>,
      ) => {
        if (!player.current || !elem.current) return;
        const rect = elem.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const full = rect.right - rect.left;
        const dura = clickedChapter.duration || 0;
        // The distance of a single second on the bar
        const second = full / dura;
        const time = x / second;

        if (tracks && tracks.length > 1) {
          setChapter(clickedChapter);
          setTime(time, dura);
          return;
        }

        if (track.current) {
          setTime(time, totalDuration);
        }
      },
      [player.current, track.current, totalDuration],
  );

  const active = useMemo(
      () => chapter?.index === currentChapter.index,
      [chapter, currentChapter],
  );
  const visible = useMemo(() => {
    const lessThan3 = assertNumber(tracks?.length) <= 3;
    return lessThan3 || active;
  }, [tracks, active]);
  const sliderWidth = getSliderWidth(chapter, currentChapter, playedPercent);
  return (
    <div
      className={style.sliderContainer}
      ref={sliderRef}
      style={{
        width,
      }}
      key={id}
    >
      <div
        className={style.dragOverlay}
        onClick={(e) => {
          handleScrub(e, currentChapter, sliderRef);
        }}
        onMouseMove={(e) => {
          if (e.buttons > 0) {
            handleScrub(e, currentChapter, sliderRef);
          }
        }}
      />
      <ChapterLabel
        className="chapter-label"
        length={tracks?.length || 0}
        visible={visible}
        active={active}
        onClick={() => {
          setTime(0, assertNumber(currentChapter.duration));
          setChapter(currentChapter);
        }}
        last={currentChapter.index === assertNumber(tracks?.length) - 1}
      >
        {chapterLabel}
      </ChapterLabel>
      <div className={style.sliderTrack}>
        <div
          className={style.sliderProgress}
          style={{
            width: sliderWidth,
          }}
        />
      </div>
    </div>
  );
};

type Props = {
  duration: number;
  playedPercent: number;
  playedSeconds: number;
  setStatus: React.Dispatch<React.SetStateAction<PlayerStatus>>;
  setChapter: React.Dispatch<React.SetStateAction<Maybe<PlayableTrack>>>;
  setTime: (time: number, duration: number) => void;
  player: React.RefObject<ReactPlayer>;
  variant: Maybe<SingleVariantFragment>;
  chapter: Maybe<PlayableTrack>;
  tracks: Maybe<Array<PlayableTrack>>;
};
