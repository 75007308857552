import * as React from 'react';

import {
  Container,
  Row,
  Column,
  Card,
  Box,
  Stack,
  BasicReel,
} from '../../layout';
import {H1, Small} from '../../typography';
import {BlockContent, Icon} from '../../global';

import {IconBox, Review} from './TestimonialGrid.styles';
import {ReferencedTestimonialGrid, SectionIndex} from '../../../types/types';
import {Section} from '../../layouts/Section';
import {AspectRatio} from '../../layouts/AspectRatio';

export const TestimonialGrid: React.FC<Props> = ({
  title,
  paragraph,
  testimonials,
  background,
  paddingTop,
  paddingBottom,
  index,
}) => (
  <Section
    padding={{
      xs: 64,
      md: 80,
    }}
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
    background={background}
  >
    <Container gutter={0}>
      <Row justify="center">
        <Column
          span={{
            xs: 24,
            lg: 12,
          }}
          textAlign="center"
          maxWidth="prose"
        >
          <Box
            paddingX={{
              xs: 16,
              lg: 0,
            }}
          >
            {title ? (
              <H1
                as={index === 0 ? undefined : 'h2'}
                spacing={{
                  xs: 16,
                  md: 24,
                }}
                size="3xl"
              >
                {title}
              </H1>
            ) : null}
            {paragraph ? (
              <BlockContent
                content={paragraph}
                background="white"
                fontSize="md"
                colour="grey7"
              />
            ) : null}
          </Box>
        </Column>
      </Row>
    </Container>
    <BasicReel center Spacer={() => <Box minWidth="26px" />}>
      {testimonials?.map(({testimonial, _key}) => (
        <Box paddingL={26} paddingT={26} paddingB={26} key={_key} color="white">
          <Card backgroundGradient="soft" padding={0}>
            <Box
              backgroundColor="rgba(55, 55, 75, 0.2)"
              padding={24}
              width="390px"
              maxWidth="70vw"
            >
              <AspectRatio ratio="1:1">
                <Box>
                  <Stack direction="vertical" justify="space-between">
                    <IconBox width="100%" display="flex" justify="start">
                      <Icon name="quote" size={72} />
                    </IconBox>
                    <Box textAlign="left">
                      {testimonial ? (
                        <>
                          <Small spacing={6}>{`${testimonial.user}`}</Small>
                          <Review>{testimonial.review}</Review>
                        </>
                      ) : null}
                    </Box>
                  </Stack>
                </Box>
              </AspectRatio>
            </Box>
          </Card>
        </Box>
      ))}
    </BasicReel>
  </Section>
);

type Props = SectionIndex<ReferencedTestimonialGrid>;
