import {CSSProperties} from 'react';
import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {Alignment} from '@mindfulness/cms';
import {BreakpointRange, ResponsiveValue} from '../../types/types';
import {
  getAlign,
  getFontSize,
  getHide,
  getLeftMargin,
  getSpacing,
} from '../../utils';

export const H1 = styled.h1<Props>(
    ({
      theme,
      spacing = 0,
      colour,
      size = 'mega',
      prose,
      hide,
      gutter = [30, 16],
    }) => `
      display: block;
  white-space: pre-wrap;
  font-weight: ${theme.fontWeights.bold};
  line-height: 120%;
  ${getFontSize(
      theme,
    typeof size === 'object' ? size : {xs: 'xl', md: size},
  )}
  ${getHide(theme, hide)}
  ${getSpacing(theme, spacing)}
  ${colour ? `color: ${theme.colors[colour]};` : ''};
  ${
    prose ?
      `
    max-width: ${theme.sizes.prose};
    margin-left: auto;
    margin-right: auto;
    padding-left: ${theme.spacings[gutter[0]]};
    padding-right: ${theme.spacings[gutter[0]]};
    ` :
      ''
}
`,
);

// h1 with different responsive values
export const Title1 = styled(H1)(
    ({theme}) => `
  ${getFontSize(theme, {xs: 'xl', md: '2xl'})}
`,
);

export const H2 = styled.h2<Props>(
    ({
      theme,
      spacing = 0,
      colour,
      size = '2xl',
      prose,
      hide,
      gutter = [30, 16],
      whitespace = 'pre-wrap',
      loading,
    }) => `
      display: block;
  white-space: ${whitespace};
  font-weight: ${theme.fontWeights.bold};
  line-height: 120%;
  ${getFontSize(
      theme,
    typeof size === 'object' ? size : {xs: 'lg2', md: size},
  )}
  ${getHide(theme, hide)}
  ${getSpacing(theme, spacing)}
  ${colour ? `color: ${theme.colors[colour]};` : ''};
  ${
    prose ?
      `
    max-width: ${theme.sizes.prose};
    margin-left: auto;
    margin-right: auto;
    padding-left: ${theme.spacings[gutter[0]]};
    padding-right: ${theme.spacings[gutter[0]]};
    ` :
      ''
}
${
  loading ?
    `
  color: ${theme.colors.grey4};
  background-color: ${theme.colors.grey4};
  border-radius: ${theme.radius.sm};
  height: 32px;
` :
    ``
}
`,
);

// h2 with different responsive values
export const Title2 = styled(H2)(
    ({theme}) => `
    font-family: var(--font-heading);
${getFontSize(theme, {xs: 'xl', md: 'lg2'})}
`,
);


export const AppTitle2 = styled.p<Props>(
    ({theme, align, colour, spacing}) => `
line-height: 100%;
font-size: 1.375rem;
font-weight: ${theme.fontWeights.bold};
${getSpacing(theme, spacing)}
${getAlign(theme, align)}
${colour ? `color: ${theme.colors[colour]};` : ''};
`,
);

export const AppTitle3 = styled.p<Props>(
    ({theme, align, colour, loading, marginL}) => `
line-height: 120%;
font-size: 1.25rem;
${colour ? `color: ${theme.colors[colour]};` : ''};
${marginL ? getLeftMargin(theme, marginL) : ''}
font-weight: ${theme.fontWeights.bold};
font-family: var(--font-heading);
${getAlign(theme, align)}
${
  loading ?
    `
  color: ${theme.colors.grey4};
  background-color: ${theme.colors.grey4};
  border-radius: ${theme.radius.sm};
  height: 24px;
` :
    ``
}
`,
);

export const AppButtonText = styled.p(
    ({theme}) => `
line-height: 1rem;
font-size: 1rem;
font-weight: ${theme.fontWeights.bold};
`,
);

export const H3 = styled.h3<Props>(
    ({
      theme,
      spacing = 0,
      colour,
      size = 'lg',
      weight = 'regular',
      prose,
      hide,
      gutter = [30, 16],
      align,
      transform,
      ellipsis,
    }) => `
      ${getAlign(theme, align)}
      display: block;
  white-space: pre-wrap;
  font-weight: ${theme.fontWeights[weight]};
  line-height: 120%;
  ${transform ? `text-transform: ${transform};` : ''}
  ${
    ellipsis ?
      `
  -webkit-line-clamp: ${ellipsis};
  line-clamp: ${ellipsis};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  ` :
      ''
}
  ${getFontSize(
      theme,
    typeof size === 'object' ? size : {xs: 'lg', md: size},
  )}
  ${getHide(theme, hide)}
  ${getSpacing(theme, spacing)}
  ${colour ? `color: ${theme.colors[colour]};` : ''};
  ${
    prose ?
      `
    max-width: ${theme.sizes.prose};
    margin-left: auto;
    margin-right: auto;
    padding-left: ${theme.spacings[gutter[0]]};
    padding-right: ${theme.spacings[gutter[0]]};
    ` :
      ''
}
`,
);

export const Title3 = styled(H3)(
    ({theme}) => `
${getFontSize(theme, {xs: 'lg'})}

`,
);

export const Caption1 = styled.p<Props>(
    ({theme, transform}) => `
  font-size: ${theme.fontSizes.sm};
  font-weight: ${theme.fontWeights.medium};
  color: ${theme.colors.grey5};
  ${transform ? `text-transform: ${transform};` : ''}
`,
);

export * from './H4';
export * from './TitleLarge';

type Props = {
  align?: ResponsiveValue<Alignment>;
  size?: ResponsiveValue<keyof Theme['fontSizes']>;
  colour?: keyof Theme['colors'];
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  marginL?: ResponsiveValue<number>;
  weight?: keyof Theme['fontWeights'];
  transform?: CSSProperties['textTransform'];
  prose?: boolean;
  hide?: BreakpointRange;
  gutter?: [keyof Theme['spacings'], keyof Theme['spacings']];
  lineHeight?: ResponsiveValue<CSSProperties['lineHeight']>;
  whitespace?: CSSProperties['whiteSpace'];
  loading?: boolean;
  ellipsis?: number;
};

export type HeadingProps = Props & {
  as?: keyof JSX.IntrinsicElements;
};
