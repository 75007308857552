import styled from '@emotion/styled';

export const StyledInput = styled.input<{ error: boolean }>(
    ({theme, readOnly}) => `
    width: 100%;
    ${readOnly ? `color: ${theme.colors.grey7};` : ''}
    background: transparent;
`,
);

export const InputWrapper = styled.div<{ readOnly?: boolean; error: boolean }>(
    ({theme, readOnly, error}) => `
    width: 100%;
    border-bottom: 1px solid ${theme.colors.grey7};
    border-radius: 4px 4px 0 0;
    padding: 5px 11px;
  ${
    readOnly ?
      `
    background-color: ${theme.colors.grey1};
    border-color: ${theme.colors.grey4};
  ` :
      `
      ${
        error ?
          `
          &:focus-within {
            border-color: ${theme.colors.primary};
            border-color: ${theme.colors.accent};

          }
      ` :
          `
      `
      }
`
}`,
);
