import styled from '@emotion/styled';
import {Box} from '../../layout';

export const Review = styled.p(
    ({theme}) => `
  line-clamp: 6;
  -webkit-line-clamp: 6;
  display: -webkit-box;
  display: box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  worde-break: none;
  hyphens: auto;
  line-height: 120%;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes.md};

  @media (min-width: ${theme.breakpoints.md.min}) {
    font-size: ${theme.fontSizes.lg};
  }
`,
);

export const IconBox = styled(Box)(
    ({theme}) => `
  svg {
    width: 3rem!important;
    height: 3rem!important;
    @media (min-width: ${theme.breakpoints.md.min}) {
      width: 4.5rem!important;
      height: 4.5rem!important;
    }
  }
`,
);
