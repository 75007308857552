import {Card} from '../../layout';
import styled from '@emotion/styled';

export const HoverCard = styled(Card)(
    ({theme}) => `
  transition: ease 0.3s background-color, ease 0.3s color, ease 0.3 box-shadow;
  will-change: background-color, color, box-shadow;
  color: ${theme.colors.grey7};
  &:hover {
    color: ${theme.colors.sleepAccent};
    background: ${theme.colors.grey1};
    box-shadow: ${theme.shadows.big};
  }
`,
);
