import {ButtonHTMLAttributes, createElement, PropsWithChildren} from 'react';
import style from './Button.module.css';
import {ButtonStyle} from '@mindfulness/cms';
import {ResponsiveValue} from 'shared/types/types';
import {getResponsiveBoolClassNames} from 'shared/utils';

/**
 * Get the responsive class names for the a css property
 * @param {ResponsiveValue<T>} val - The value of the css property potentially an object of responsive values with breakpoints as keys
 * @param {string} prop - the CSS property name
 * @return {string[]} - An array of class names
 */
export function getButtonSize<T>(val: ResponsiveValue<T>, prop: string): string[] {
  if (!val) {
    return [
      style[`${prop}-lg`],
    ];
  }
  return typeof val === 'object' ? Object.entries(val).map(([key, value]) => style[`${key}-${prop}-${value}`]) : [
    style[`${prop}-${val}`],
  ];
}


export const StyledButton: React.FC<PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> & Props> = ({
  children, as = 'button',
  buttonStyle = 'primary',
  className,
  wide, loading, size, disabled,
  ...props
}) => {
  return createElement(as, {
    className: [
      style.button,
      style[`style-${buttonStyle}`],
      ...(wide ? getResponsiveBoolClassNames(wide, 'full-width') : []),
      ...getButtonSize(size, 'size'),
      ...(className ? [className] : []),
    ].join(' '),
    disabled: disabled || loading,
    ...props,
  }, children);
};

type Props = {
  as?: keyof JSX.IntrinsicElements;
  buttonStyle?: ButtonStyle | 'faded' | 'alt';
  wide?: ResponsiveValue<boolean>;
  size?: ResponsiveValue<'lg' | 'md' | 'sm' | 'xs'>;
  loading?: boolean;
}
