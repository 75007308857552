import * as React from 'react';

import {Alert} from '../forms';
import {Container, Row, Column, Box} from '../layout';
import {Section} from '../layouts/Section';
import {H4, Text} from '../typography';

import {
  Accordion,
  AccordionItem,
  AccordionItemState,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export const ErrorFallback: React.FC<{
  sectionType: string;
  error: unknown;
  componentStack: string | null;
  eventId: string | null;
  resetError(): void;
  index: number;
}> = ({error, sectionType, componentStack, index}) => (
  <Section>
    <Container>
      <Row>
        <Column>
          <H4 as="p" weight="bold">{`An error has occurred.`}</H4>
          <Box>
            <Accordion allowZeroExpanded={true}>
              <AccordionItem>
                <AccordionItemState>
                  {({expanded}) => (
                    <>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <Text>{`${expanded ? 'Hide' : 'Show'} details`}</Text>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Box paddingB={30}>
                          <Alert
                            error={
                              <pre
                                style={{
                                  whiteSpace: 'pre-line',
                                }}
                              >
                                {error instanceof Error ? error.toString() : 'Unknown error'}
                                {componentStack}
                                {`\nat ${sectionType}:${index}`}
                              </pre>
                            }
                          />
                        </Box>
                      </AccordionItemPanel>
                    </>
                  )}
                </AccordionItemState>
              </AccordionItem>
            </Accordion>
          </Box>
        </Column>
      </Row>
    </Container>
  </Section>
);
