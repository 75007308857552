import {useCallback} from 'react';
import {ApolloError} from '@apollo/client';
import {Maybe} from '@mindfulness/utils/maybe';
import {omitEmpty} from '@mindfulness/utils/object';

import {useUserPreferences} from './useUserPreferences';

import {signup} from '../../../api';
import {useModal} from '../../../hooks';
import {useAddCohort} from './useAddCohort';
import {useLoginFailure} from './useLoginFailure';
import {AuthProps} from '.';
import {trackNewOrExistingUsers} from '../../../utils/analytics';
import {useAnalytics} from '../SegmentProvider';
import {cleanVerifyUrlParams} from '../../../utils/url';

export const usePasswordlessSignup = ({
  handleLogin,
  pageData,
  handleAction,
}: AuthProps) => {
  const {close} = useModal('action');
  const handleAddCohort = useAddCohort();
  const handleLoginFailure = useLoginFailure();
  const preferences = useUserPreferences();
  const {track, identify} = useAnalytics();

  const handlePasswordlessSignup = useCallback(
      async ({
        email,
        name,
        cohort,
        requireVerify = true,
        onVerify,
        redirect,
        modalName = 'action',
        verifyLink,
        onToPage,
      }: SignupParams) => {
        try {
          const action = pageData?.action;
          const thankYou = action?.thankYouLink || action?.thankYouPage?.current;
          const loginWebLinkPath = cleanVerifyUrlParams(verifyLink);

          const {data} = await signup(
              {
                passwordless: {
                  email,
                  allowLogin: true,
                  loginWebLinkPath,
                },
                userDetails: {
                  fullName: name,
                },
                preferences,
              },
              omitEmpty({
                thankYou: onToPage ?? thankYou,
              }),
          );
          if (!data) {
            throw new Error('Couldn\'t sign up Passwordless');
          }
          await trackNewOrExistingUsers(data.signup, {
            track,
            identify,
          });
          close();

          await handleLogin(data.signup);
          await handleAddCohort({cohort});
          if (redirect) {
            redirect({email: data.signup?.user?.email || undefined});
            return;
          }
          await handleAction(data?.signup?.user?.email || undefined);
        } catch (e) {
          if (e instanceof ApolloError) {
            await handleLoginFailure(e, {
              email,
              requireVerify,
              onVerify,
              redirect,
              modalName,
            });
          }
          throw e;
        }
      },
      [handleLogin, handleAction, preferences, handleLoginFailure],
  );

  return handlePasswordlessSignup;
};

export type SignupParams = {
  email: string;
  name: string;
  cohort: Maybe<string>;
  requireVerify?: boolean;
  onVerify?: (params: { email?: string; name?: string }) => void;
  redirect?: (params: { email?: string; name?: string }) => void;
  modalName?: string;
  verifyLink?: string;
  onToPage?: string;
};
