import * as React from 'react';

import {ReferencedAnnouncement} from '../../../types/types';
import {Section} from '../../layouts/Section';
import {Text} from '../../typography';
import {Link} from '../../global';

export const AnnouncementBar: React.FC<ReferencedAnnouncement> = (props) => {
  const {announcement} = props;
  return (
    <LinkWrapper {...props}>
      <Section
        background="softLongGradient"
        sectionPadding={{
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        <Text
          fontWeight="bold"
          align="center"
          prose="xl"
          decoration="underline"
        >
          {announcement}
        </Text>
      </Section>
    </LinkWrapper>
  );
};

const LinkWrapper: React.FC<
  React.PropsWithChildren<ReferencedAnnouncement>
> = ({children, actionType, slug, url}) => {
  if (actionType === 'reference' && slug) {
    return (
      <Link href={slug}>
        <a>{children}</a>
      </Link>
    );
  } else if (actionType === 'internal' && url) {
    return (
      <Link href={url}>
        <a>{children}</a>
      </Link>
    );
  } else if (actionType === 'external') {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return <>{children}</>;
};
