import styled from '@emotion/styled';

export const Subtitle = styled.p(
    ({theme}) => `
  font-size: 1.5rem;
  font-weight: ${theme.fontWeights.bold};
`,
);

export const Title = styled.h3(
    ({theme}) => `
  font-size: 52px;
  line-height: 120%;
  display: inline-block;
  font-weight: ${theme.fontWeights.bold};
  background-image: ${theme.gradients.soft};
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  @media (min-width: ${theme.breakpoints.md.min}) {
    font-size: 62px;
  }
`,
);
