import {Cluster} from '../../layout';
import {
  Maybe,
  SingleDetailFragment,
  SingleVariantFragment,
} from '../../../types/api';
import {Text} from '../../typography/Text';
import style from '../SinglePlayerDetail/SinglePlayerDetail.module.css';

export const VariantSelector: React.FC<Props> = ({
  single,
  setVariant,
  variant,
}) => {
  return (
    <>
      <Text fontWeight="bold" fontSize="xs" spacing={8}>
        Duration
      </Text>
      {single.variants ? (
        <Cluster gap={8}>
          {single.variants.map((v) => (
            <button
              type="button"
              className={[
                style.variantButton,
                v.id === variant?.id ? style.selected : '',
              ].join(' ')}
              key={v.id}
              onClick={() => setVariant(v)}
            >
              {v.label || `${Math.round((v?.duration || 0) / 60)}mins`}
            </button>
          ))}
        </Cluster>
      ) : null}
    </>
  );
};

type Props = {
  single: SingleDetailFragment;
  variant: Maybe<SingleVariantFragment>;
  setVariant: React.Dispatch<
    React.SetStateAction<Maybe<SingleVariantFragment>>
  >;
};
