import styled from '@emotion/styled';

export const ItemContainer = styled.div(
    () => `

.cell-image {
  transition: transform 0.3s ease;
  will-change: transform;
}
&:hover {
  .cell-image {
    transform: scale(1.1);
  }
}
`,
);
