import {LottieDynamic} from './LottieDynamic';

export const Lottie: React.FC<LottieProps> = (props) => {
  return <LottieDynamic {...props} />;
};

export type LottieProps = {
  loop?: boolean;
  /** Path to lottie json file */
  path: string;
  state?: 'play' | 'pause' | 'stop';
  width?: number;
  height?: number;
};
