import styled from '@emotion/styled';
import {Stack} from '../../layout';

export const StyledContainer = styled.div(
    ({theme}) => `
  position: relative;
  overflow: hidden;
  padding: ${theme.spacings[40]} ${theme.spacings[30]};

  @media (min-width: ${theme.breakpoints.md.min}) {
    border-radius: ${theme.radius.lg};
    padding: ${theme.spacings[150]} ${theme.spacings[104]};
  }
`,
);

export const TextWrapper = styled(Stack)(
    ({theme}) => `
  color: ${theme.colors.grey9};
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(50px);
  border-radius: ${theme.radius.lg};
  padding: 40px;

  @media (min-width: ${theme.breakpoints.md.min}) {
    width: 80%;
    padding: 60px;
  }
  @media (min-width: ${theme.breakpoints.lg.min}) {
    width: 60%;
  }
`,
);
