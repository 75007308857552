import {useCallback} from 'react';
import {Maybe, when} from '@mindfulness/utils/maybe';

import {signup} from '../../../api';
import {useModal, useToast} from '../../../hooks';
import {isOnClient, trackNewOrExistingUsers} from '../../../utils';

import {useUserPreferences} from './useUserPreferences';
import {AuthProps} from '.';
import {useAnalytics} from '../SegmentProvider';

export const useFacebookLogin = ({pageData, handleLogin, handleAction}: AuthProps) => {
  const emailModal = useModal('email');
  const actionModal = useModal('action');
  const incognitoModal = useModal('incognito');
  const {facebookLoginRejection} = useToast();
  const preferences = useUserPreferences();
  const {track, identify} = useAnalytics();

  const handleFacebookLogin = useCallback(
      async (useAction: boolean) => {
        let data: {
        name: Maybe<string>;
        email: Maybe<string>;
      } = {
        name: undefined,
        email: undefined,
      };
        if (isOnClient()) {
          if (typeof FB === 'undefined') {
            incognitoModal.open({
              props: {
                title: 'Facebook Blocked by Browser',
                message:
                'Facebook was blocked by your browser, you may have an extension blocking it. Please check and try again.',
              },
            });
            throw new Error(
                'Couldn\'t sign up with Facebook while using Private Browsing',
            );
          }
          FB.login((response) => {
            if (!response.authResponse) {
              facebookLoginRejection();
              throw new Error('Couldn\'t sign up with Facebook');
            }
            FB.api('/me', async (userData: { name: string }) => {
              const action = pageData?.action;
              const thankYou = action?.thankYouLink || action?.thankYouPage?.current;
              const {data: res} = await signup(
                  {
                    facebook: {
                      facebookId: response.authResponse.userID,
                      accessToken: response.authResponse.accessToken,
                    },
                    userDetails: {
                      fullName: userData.name,
                      cohorts: [
                        ...(pageData?.action?.cohort ?
                      [pageData?.action?.cohort] :
                      []),
                      ],
                    },
                    preferences,
                  },
                  {
                    ...when(thankYou, (t) => ({
                      thankYou: t,
                    })),
                  },
              );
              data = {
                name: res?.signup.user.fullName,
                email: res?.signup.user.email || undefined,
              };

              if (!res) {
                throw new Error('Couldn\'t sign up with Facebook');
              }

              await trackNewOrExistingUsers(res.signup, {
                track, identify,
              });

              if (!res.signup.user.email) {
                actionModal.close();
                await handleLogin(res.signup);
                emailModal.open({
                  props: {
                    useAction,
                  },
                  onClose: async () => {
                    await handleAction(undefined);
                  },
                });
              } else {
                actionModal.close();
                await handleLogin(res.signup);
                if (useAction) {
                  await handleAction(res?.signup.user.email);
                }
              }
            });
          });
          return data;
        }
      },
      [handleLogin, handleAction, preferences],
  );

  return handleFacebookLogin;
};
