import * as React from 'react';
import {isMobile} from 'react-device-detect';
import {ThankYouCover as ThankYouCoverProps} from '@mindfulness/cms';

import {H1, H4} from '../../typography';
import {Column, Container, Row} from '../../layout';
import {ButtonLink, Context} from '../../global';
import {DownloadLinks, QRCodeSection, SplitCover} from '../../ui';
import {useNavigation, useQRCode} from '../../../hooks';
import {downloadLink} from '../../../utils';
import {SectionIndex} from '../../../types/types';

export const ThankYouCover: React.FC<SectionIndex<ThankYouCoverProps>> = ({
  title,
  paragraph,
  image,
  index,
}) => {
  const {qrCodeLink, sourceLink} = useQRCode();
  const data = React.useContext(Context);
  const {getQueryParam} = useNavigation();
  React.useEffect(() => {
    const addToCohort = getQueryParam('addToCohort');
    if (isMobile && addToCohort === 'true') {
      window.location.href = downloadLink();
    }
  }, [data, getQueryParam]);
  return (
    <SplitCover image={image} index={index}>
      <Container maxWidth="sm">
        <Row spacing={14}>
          <Column span={24}>
            {title ? (
              <H1 as={index === 0 ? undefined : 'h2'} size="3xl" spacing={16}>
                {title}
              </H1>
            ) : null}
            {paragraph ? (
              <H4
                colour="grey7"
                spacing={{
                  xs: 20,
                  md: 40,
                }}
              >
                {paragraph}
              </H4>
            ) : null}
          </Column>
        </Row>
        {isMobile ? (
          <Row>
            <Column>
              {sourceLink ? (
                <ButtonLink href={sourceLink} id={`ThankYouCover-${index}`}>
                  Download and Login
                </ButtonLink>
              ) : null}
            </Column>
          </Row>
        ) : (
          <>
            <QRCodeSection qrCodeLink={qrCodeLink} />
          </>
        )}
        <Row
          topSpacing={{
            xs: 16,
            md: 34,
          }}
        >
          <DownloadLinks />
        </Row>
      </Container>
    </SplitCover>
  );
};
