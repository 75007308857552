export const spacings = {
  0: '0rem',
  4: '0.25rem',
  5: '0.3125rem',
  6: '0.375rem',
  8: '0.5rem',
  10: '0.625rem',
  12: '0.75rem',
  14: '0.875rem',
  15: '0.9375rem',
  16: '1rem',
  20: '1.25rem',
  24: '1.5rem',
  26: '1.625rem',
  30: '1.875rem',
  34: '2.215rem',
  40: '2.5rem',
  50: '3.125rem',
  56: '3.5rem',
  60: '3.75rem',
  64: '4rem',
  72: '4.5rem',
  80: '5rem',
  104: '6.5rem',
  120: '7.5rem',
  150: '9.375rem',
  160: '10rem',
  174: '10.875rem',
  200: '12.5rem',
  242: '15.125rem',
};
