import * as React from 'react';
import {MediaOverlap as MediaOverlapProps} from '@mindfulness/cms';

import {Box, Container} from '../../layout';
import {H1} from '../../typography';
import {CallToActionButton, BlockContent, SanityImage} from '../../global';
import {VideoPlayer} from '../../ui/VideoPlayer';
import {SectionIndex} from '../../../types/types';

import {Wrapper, TextWrapper} from './MediaOverlap.styles';

export const MediaOverlap: React.FC<SectionIndex<MediaOverlapProps>> = ({
  videoUrl,
  title,
  paragraph,
  cta,
  image,
  autoplay,
  _type,
  index,
}) => (
  <Box>
    <Container maxWidth="md">
      <Wrapper>
        <Box marginB={16}>
          {videoUrl ? (
            <VideoPlayer videoUrl={videoUrl} autoplay={autoplay} />
          ) : image?.image ? (
            <SanityImage ratio="16:9" image={image} />
          ) : null}
        </Box>
        <TextWrapper>
          {title ? (
            <H1
              as={index === 0 ? undefined : 'h2'}
              spacing={10}
              size="2xl"
              colour="grey9"
            >
              {title}
            </H1>
          ) : null}
          {paragraph ? (
            <BlockContent
              content={paragraph}
              background="white"
              fontSize="md"
              colour="grey7"
              spacing={16}
            />
          ) : null}
          {cta?.text ? (
            <CallToActionButton id={`${_type}-${index}`} {...cta}>
              {cta?.text}
            </CallToActionButton>
          ) : null}
        </TextWrapper>
      </Wrapper>
    </Container>
  </Box>
);
