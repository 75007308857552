import {Maybe} from '@mindfulness/utils/maybe';
import {RefObject, useEffect} from 'react';

export const useOutsideAlerter = (
    ref: Maybe<RefObject<HTMLDivElement>>,
    callback: () => void,
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     * @param {Event} event
     */
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (ref?.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref?.current]);
};
