import * as React from 'react';
import {Stats as StatsProps} from '@mindfulness/cms';
import Image from 'next/legacy/image';

import {H1} from '../../typography';
import {Container, Row, Column, Stack, Section, Box} from '../../layout';
import {BlockContent} from '../../global';
import {SectionIndex} from '../../../types/types';

import {Subtitle, Title} from './Stats.styles';

const columnSpans = [20, 8, 7, 6];

export const Stats: React.FC<SectionIndex<StatsProps>> = ({
  blocks,
  title,
  paragraph,
  index,
  paddingTop = 120,
  paddingBottom = 150,
  displayStars,
}) => (
  <Section
    background="grey9Gradient"
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
  >
    <Container maxWidth="prose">
      <Row spacing={40}>
        <Column textAlign="center">
          {displayStars && (
            <Box marginB={24}>
              <Image
                src="/images/review-stars.png"
                width={165}
                height={25}
                alt="5 stars"
              />
            </Box>
          )}
          <H1 size="3xl" as={index === 0 ? undefined : 'h2'}>
            {title}
          </H1>
          {paragraph ? (
            <BlockContent content={paragraph} background="grey9" />
          ) : null}
        </Column>
      </Row>
    </Container>
    <Container maxWidth="xl">
      <Row justify="space-around">
        {blocks?.map(({title, subtitle, _key}) => (
          <Column
            span={{
              xs: [24, 24, 24, 12][blocks.length - 1],
              lg: columnSpans[blocks.length - 1],
            }}
            spacing={40}
            textAlign="center"
            key={_key}
            color="white"
          >
            <Stack space={16} direction="vertical">
              {title ? <Title>{title}</Title> : null}
              {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            </Stack>
          </Column>
        ))}
      </Row>
    </Container>
  </Section>
);
