import * as React from 'react';
import Image from 'next/legacy/image';
import {
  Figure,
  ResponsiveImage as ResponsiveImageProps,
} from '@mindfulness/cms';
import {Maybe, when} from '@mindfulness/utils/maybe';
import {SanityClientLike} from '@sanity/image-url/lib/types/types';
import {useNextSanityImage} from 'next-sanity-image';
import {Theme} from '@emotion/react';

import {
  BackgroundImageWrapper,
  DynamicContainer,
  ImageFrame,
} from './Image.styles';

import {FigureWithMeta, ImageProps, ResponsiveValue} from '../../../types/types';
import {Context} from '../../global';
import {useOnMobile, useElementSize} from '../../../hooks';
import {assertString, getSizedImage} from '../../../utils';
import {useBlurProps} from '../../../hooks/useBlurProps';

export const BackgroundImage: React.FC<
  Props & {
    overlay?: number;
    gradient?: keyof Theme['gradients'];
    radius?: keyof Theme['radius'];
    zIndex?: number;
    position?: React.CSSProperties['objectPosition'];
  }
> = ({overlay, radius, children, gradient, zIndex, position, ...props}) => (
  <>
    <BackgroundImageWrapper
      overlay={overlay}
      gradient={gradient}
      radius={radius}
      zIndex={zIndex}
    >
      {props?.image?.image ? (
        <DynamicImage
          {...props}
          objectFit="cover"
          objectPosition={position}
        />
      ) : null}
    </BackgroundImageWrapper>
    {children}
  </>
);

export const ResponsiveBackgroundImage: React.FC<
  BackgroundImageProps & {
    image: Maybe<ResponsiveImageProps>;
  }
> = ({image, ...props}) =>
  when(image, (i) => <ResponsiveBackgroundImageInner image={i} {...props} />) ||
  null;

export const ResponsiveBackgroundImageInner: React.FC<
  BackgroundImageProps & {
    image: ResponsiveImageProps;
  }
> = ({image, ...props}) => {
  const mobileImage = image?.mobileImage ?
    image.mobileImage :
    image.desktopImage;
  const img = useOnMobile() ? mobileImage : image?.desktopImage;
  return img ? <BackgroundImage image={img} {...props} /> : null;
};

type BackgroundImageProps = ImageProps & {
  overlay?: number;
  gradient?: keyof Theme['gradients'];
  radius?: keyof Theme['radius'];
  zIndex?: number;
  position?: React.CSSProperties['objectPosition'];
};

export const ResponsiveImageInner: React.FC<
  React.PropsWithChildren<
    ImageProps & ImageFrameProps & { image: ResponsiveImageProps }
  >
> = ({image, ...props}) => {
  const mobileImage = image?.mobileImage ?
    image.mobileImage :
    image.desktopImage;
  const desktopImage = image?.desktopImage ?
    image.desktopImage :
    image.mobileImage;
  return mobileImage && desktopImage ? (
    <DynamicImage {...props} image={useOnMobile() ? mobileImage : desktopImage} />
  ) : null;
};

export const ResponsiveImage: React.FC<
  ImageProps & ImageFrameProps & { image: Maybe<ResponsiveImageProps> }
> = ({image, ...props}) =>
  when(image, (i) => <ResponsiveImageInner image={i} {...props} />) || null;

export const DynamicImage: React.FC<Props> = ({
  size,
  objectFit = 'contain',
  layout = 'intrinsic',
  image,
  decoration,
  ratio: _ratio,
  ...props
}) => {
  const {client} = React.useContext(Context);
  const container = React.useRef<HTMLDivElement>(null);
  const realSize = useElementSize(container);
  const imageProps = useNextSanityImage(
    client as unknown as SanityClientLike,
    image?.image || null,
  );

  const ratio = React.useMemo(
      () =>
        when(size, (s) => {
          const [w, h] = s.split('x').map(Number);
          return when(w && h, () => h / w);
        }),
      [size],
  );

  const width = React.useMemo(
      () => props.width ?? realSize?.width,
      [props.width, realSize?.width],
  );
  const height = React.useMemo(
      () =>
      // Use height if passed in
        props.height ||
      // Else multiply the real width by the height ratio
      when(ratio || undefined, (r) => (realSize?.width || 0) * r) ||
      realSize?.height,
      [props.height, ratio, realSize?.height, realSize?.width],
  );
  const blurProps = useBlurProps(image, !!props.priority);
  return imageProps?.src ? (
    <DynamicContainer ratio={_ratio} decoration={decoration} ref={container}>
      {layout === 'fill' ? (
        <Image
          {...props}
          src={imageProps.src}
          objectFit={objectFit}
          layout={layout}
          {...blurProps}
        />
      ) : width && height ? (
        <Image
          {...props}
          {...imageProps}
          objectFit={objectFit}
          width={width}
          height={height}
          layout={layout}
          {...blurProps}
        />
      ) : null}
    </DynamicContainer>
  ) : null;
};

export const BasicImage: React.FC<Props & ImageFrameProps> = ({
  image,
  ...props
}) =>
  when(image?.image, () => <BasicImageInner image={image} {...props} />) ||
  null;

const BasicImageInner: React.FC<Props & ImageFrameProps> = ({
  image,
  decoration,
  background,
  ratio,
  radius,
  ...props
}) => {
  const {client} = React.useContext(Context);
  const imageProps = useNextSanityImage(
    client as unknown as SanityClientLike,
    image?.image || {},
  );
  const blurProps = useBlurProps(image, !!props.priority);

  return imageProps?.src ? (
    <ImageFrame
      decoration={decoration}
      background={background}
      ratio={ratio}
      radius={radius}
      width={props.width}
    >
      <Image {...imageProps} {...props} alt={assertString(props.alt)} {...blurProps} />
    </ImageFrame>
  ) : null;
};

export const APIImage: React.FC<
  React.PropsWithChildren<
    {
      url: string;
    } & ImageFrameProps &
      ImageProps
  >
> = ({
  decoration,
  background,
  url,
  ratio,
  children,
  className,
  portrait = false,
  ...props
}) => {
  const container = React.useRef<HTMLDivElement>(null);

  const realSize = useElementSize(container);
  const width = React.useMemo(
      () => props.width ?? realSize?.width,
      [props.width, realSize?.width],
  );
  const height = React.useMemo(
      () =>
      // Use height if passed in
        props.height ||
      // Else multiply the real width by the height ratio
      realSize?.height,
      [props.height, realSize?.height, realSize?.width],
  );
  const src = React.useMemo(() => {
    return getSizedImage({
      src: url,
      portrait,
      size: {
        width,
        height,
      },
    }) || url;
  }, [url, portrait, width, height]);

  return (
    <ImageFrame
      decoration={decoration}
      background={background}
      height={height}
      width={width}
      ratio={ratio}
      ref={container}
      className={className}
    >
      {url ? (
        <Image
          placeholder={props.priority ? 'empty' : 'blur'}
          blurDataURL={props.priority ?
          undefined :
          getSizedImage({
            src: url,
            portrait,
            size: {
              width: 1,
              height: 1,
            },
          }) || undefined}
          src={src}
          {...props}
          alt={assertString(props.alt)}
          layout="fill"
          objectFit="cover"
        />
      ) : null}
      {children}
    </ImageFrame>
  );
};

export const BackgroundAPIImage: React.FC<
  React.PropsWithChildren<
    Omit<ImageProps, 'alt'> & {
      overlay?: number;
      gradient?: keyof Theme['gradients'];
      radius?: keyof Theme['radius'];
      zIndex?: number;
      src: string;
      scale?: number;
      scaleFrom?: number;
      /** Set this to true if the image is a static next image, and therefore cannot be resized by the api */
      doNotResize?: true;
      triggerResize?: number;
      alt?: string;
    }
  >
> = ({
  overlay,
  radius,
  children,
  gradient,
  zIndex,
  scale,
  scaleFrom,
  className,
  objectFit = 'cover',
  doNotResize,
  triggerResize,
  src,
  portrait = false,
  ...props
}) => {
  const container = React.useRef<HTMLDivElement>(null);
  const realSize = useElementSize(container);
  const width = React.useMemo(
      () => props.width ?? realSize?.width,
      [props.width, realSize?.width],
  );
  const height = React.useMemo(
      () =>
      // Use height if passed in
        props.height ||
      // Else multiply the real width by the height ratio
      realSize?.height,
      [props.height, realSize?.height, realSize?.width],
  );

  return (
    <>
      <BackgroundImageWrapper
        overlay={overlay}
        gradient={gradient}
        radius={radius}
        zIndex={zIndex}
        scale={scale}
        scaleFrom={scaleFrom}
        className={className}
        ref={container}
      >
        {src ? (
          <Image
            {...props}
            placeholder={doNotResize || props.priority ? 'empty' : 'blur'}
            blurDataURL={doNotResize || props.priority ?
              undefined :
              getSizedImage({
                src,
                portrait,
                size: {
                  width: 1,
                  height: 1,
                },
              }) || undefined}
            src={
              doNotResize ?
                src :
                getSizedImage({
                  src,
                  portrait,
                  size: {
                    width,
                    height,
                  },
                }) || src
            }
            objectFit={objectFit}
            layout="fill"
            unoptimized
          />
        ) : null}
      </BackgroundImageWrapper>
      {children}
    </>
  );
};

type Props = React.PropsWithChildren<
  ImageProps &
    ImageFrameProps & {
      image: Maybe<Figure | FigureWithMeta>;
    }
>;

type ImageFrameProps = {
  decoration?: ImageDecoration;
  background?: keyof Theme['colors'];
  /** e.g 16:9 */
  ratio?: ResponsiveValue<string | number>;
  radius?: keyof Theme['radius'];
  loadingBackground?: boolean;
};

export type ImageDecoration =
  | 'none'
  | 'frame'
  | 'stack'
  | 'rounded'
  | 'circle'
  | 'overlay';
