import {Theme} from '@emotion/react';
import {toggle} from '@mindfulness/utils/boolean';

import {Icon} from '../../global/Icon';
import {
  CSSProperties,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Stack} from '../../layout';
import {favouriteSingle} from '../../../api/functions/favouriteSingle';
import {useShare} from '../../../hooks/useShare';
import {favouriteBundle} from '../../../api/functions/favouriteBundle';
import type {
  BundleFeedFragment,
  BundleDetailFragment,
  SingleFeedFragment,
} from '../../../types/api';
import {Context} from '../../global';
import {OnClient} from '../OnClient';
import {onClient} from '../../../utils/next';
import {LoadingSpinner} from '../LoadingSpinner';
import {useTrack} from '../../global/SegmentProvider';
import {TranscriptsButton} from '../TranscriptsButton';
import {ShareDropdown} from '../ShareDropdown';
import {ShareOption} from '../ShareDropdown/ShareOption';
import {NormalizedButton} from '../NormalizedButton';

export const SingleShare: React.FC<Props> = ({
  colour,
  justify,
  wide,
  single,
  bundle,
  size,
  shareUrl,
  hasTranscripts,
}) => {
  const {session} = useContext(Context);
  const track = useTrack();
  const {linkToShare, open, setOpen, handleShare, handleCopy, shareRef, loading: loadingShare} = useShare({
    shareUrl,
    single,
    bundle,
  });
  const [favourite, setFavourite] = useState(bundle ? !!bundle.userData?.bundleFavourite : !!single?.userData?.isFavourite);

  const handleFavourite = useCallback(async () => {
    if (!single?.id && !bundle?.id) {
      console.error('Unable to favourite, no ID found');
      return;
    }
    setFavourite(toggle);
    onClient(() => navigator.vibrate?.(200));
    if (single) {
      const res = await favouriteSingle({
        id: single.id,
        isFavourite: !favourite,
      });
      setFavourite(!!res?.isFavourite);
      await track('Single favorited', {
        single_id: single.id,
        single_title: single.title,
        is_favourite: res?.isFavourite,
        single_type: single.type,
      });
      return;
    }
    if (bundle) {
      const res = await favouriteBundle({
        id: bundle.id,
        isFavourite: !favourite,
      });
      setFavourite(!!res?.isFavourite);
      return;
    }
  }, [single?.id, bundle?.id, favourite]);

  useEffect(() => {
    if (single?.userData) {
      setFavourite(!!single?.userData?.isFavourite);
    }
  }, [single?.userData?.isFavourite, bundle?.userData?.bundleFavourite]);

  return (
    <Stack
      space={16}
      colour={colour}
      items="end"
      justify={justify}
      wide={wide}
      ref={shareRef}
    >

      <OnClient>
        <NormalizedButton
          position="relative"
          width="auto"
          type="button"
          onClick={(e) => {
            // Prevent events bubbling up to the player or other elements
            e.stopPropagation();
            e.preventDefault();
            handleShare();
            setOpen(toggle);
          }}
        >
          <Icon name="share" size={size} />
          <ShareDropdown open={open} position="left">
            {loadingShare ? (
              <LoadingSpinner />
            ) : (
              <>
                <ShareOption
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`whatsapp://send?text=${linkToShare}`}
                >
                  <Icon size={undefined} name="whatsapp">
                    Whatsapp
                  </Icon>
                </ShareOption>
                <ShareOption
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${linkToShare}`}
                >
                  <Icon size={undefined} name="facebook">
                    Facebook
                  </Icon>
                </ShareOption>
                <ShareOption
                  target="_blank"
                  rel="noreferrer noopener"
                  href={`http://twitter.com/share?url=${linkToShare}`}
                >
                  <Icon name="twitter" size={undefined}>
                    Twitter
                  </Icon>
                </ShareOption>
                <ShareOption as="button" type="button" onClick={handleCopy}>
                  <Icon size={undefined} name="copy">
                    Copy link
                  </Icon>
                </ShareOption>
              </>
            )}

          </ShareDropdown>
        </NormalizedButton>
      </OnClient>

      {hasTranscripts ? (
        <TranscriptsButton single={single} size={size} />
      ) : null}


      {(session && single?.id) || bundle?.id ? (
        <OnClient>
          <NormalizedButton
            width="auto"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleFavourite();
            }}
          >
            <div
              style={{
                marginTop: '3px',
              }}
            >
              {favourite ? (
                <Icon name="unfave" size={size} />
              ) : (
                <Icon name="fave" size={size} />
              )}
            </div>
          </NormalizedButton>
        </OnClient>
      ) : null}
    </Stack>
  );
};

type Props = {
  colour?: keyof Theme['colors'];
  justify?: CSSProperties['justifyContent'];
  size?: keyof Theme['spacings'];
  wide?: boolean;
  single?: SingleFeedFragment;
  bundle?: BundleFeedFragment | BundleDetailFragment;
  shareUrl?: string;
  hasTranscripts?: boolean;
};
