import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {AllColour} from '@mindfulness/cms';
import {Maybe} from '@mindfulness/utils/maybe';
import {textColourMap} from '../../../utils';

const getBackground = (theme: Theme, background: Maybe<AllColour>) => {
  if (!background) return '';

  if (background.includes('Gradient')) {
    const color = background.replace(
        'Gradient',
        '',
    ) as keyof Theme['gradients'];
    return `background-image: ${theme.gradients[color]};`;
  }
  if (!theme.colors.hasOwnProperty(background)) {
    return '';
  }
  return `background: ${theme.colors[background as keyof Theme['colors']]};`;
};

export const QuoteBackground = styled.div<{
  hasImage: boolean;
  hasBackgroundImage: boolean;
  background?: AllColour;
}>(
    ({theme, hasImage, hasBackgroundImage, background = 'primaryGradient'}) => `
  ${
    hasBackgroundImage ?
      `
    background-color: rgba(20, 20, 42, 0.6);
  ` :
      `
    ${getBackground(theme, background)}
  `
}
  color: ${theme.colors[textColourMap(background)]};
  position: relative;
  z-index: 0;
  padding-top: ${theme.spacings[40]};
  padding-bottom: ${hasImage ? theme.spacings[16] : theme.spacings[40]};
  @media (min-width: ${theme.breakpoints.md.min}) {
    padding-top: ${theme.spacings[174]};
    padding-bottom: ${hasImage ? theme.spacings[34] : theme.spacings[174]};
  }
`,
);

export const ImageBackground = styled.div(
    ({theme}) => `
  background: ${theme.colors.white};
  position: relative;
  z-index: 0;
`,
);

export const ImageWrapper = styled.div(
    ({theme}) => `
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);

  @media (min-width: ${theme.breakpoints.md.min}) {
    padding-left: 40px;
    padding-right: 40px;
  }
`,
);

export const OffsetWrapper = styled.div(
    ({theme}) => `
  overflow: visible;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  position: absolute;
`,
);

export const QuoteImage = styled.div(
    ({theme}) => `
  > svg {
    width: 30px;
    @media (min-width: ${theme.breakpoints.md.min}) {
      width: 72px;
    }
  }

`,
);
