import * as React from 'react';
import {QuoteCarousel as Props} from '@mindfulness/cms';
import {Box, Column, Container, Row, Section} from '../../layout';
import {SectionIndex} from '../../../types/types';
import {Blockquote, H1, H4} from '../../typography';
import {Slider} from '../../ui';

export const QuoteCarousel: React.FC<SectionIndex<Props>> = ({
  index,
  title,
  slides,
  background,
  paddingTop,
  paddingBottom,
}) => (
  <Section
    padding={{xs: 16, md: 30, lg: 80}}
    background={background}
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
  >
    <Container maxWidth="md">
      <Row>
        <Column textAlign={'center'} justify="center" spacing={34}>
          <H1 size="2xl" as={index === 0 ? undefined : 'h2'}>
            {title}
          </H1>
        </Column>
      </Row>
    </Container>

    <Slider speed={8000}>
      {slides?.map((slide) => (
        <Slide {...slide} key={slide._key} />
      ))}
    </Slider>
  </Section>
);

const Slide: React.FC<
  {
    _type: 'slide';
    quote?: string | undefined;
    author?: string | undefined;
  } & {
    _key: string;
  }
> = ({quote, author}) => (
  <Container maxWidth="lg">
    <Row>
      <Column textAlign="center">
        <Box
          paddingY={{
            xs: 60,
            md: 174,
          }}
        >
          <Blockquote spacing={60}>{quote}</Blockquote>
          {author ? <H4 weight="bold">{`— ${author}`}</H4> : null}
        </Box>
      </Column>
    </Row>
  </Container>
);
