
import {REDEEM_SHARE} from '../mutations/redeemShare';
import {
  RedeemShareMutation,
  RedeemShareMutationVariables,
} from '../../types/api';
import {getGraphClient} from '../../utils/graph';
import {UseTrack} from '../../components/global/SegmentProvider';

export const redeemShare = async (
    variables: RedeemShareMutationVariables,
    track: UseTrack,
) => {
  const {data} = await getGraphClient().mutate<RedeemShareMutation>({
    mutation: REDEEM_SHARE,
    variables,
  });
  if (data?.redeemShare?.id) {
    track('Share opened', {
      share_id: data.redeemShare.id,
      share_code: data.redeemShare.code,
      share_url: data.redeemShare.url,
      share_type: data.redeemShare.type,
      share_content_id: data.redeemShare.contentId,
    });
  }
  return data?.redeemShare;
};
