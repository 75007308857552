import * as React from 'react';
import {ifDo} from '@mindfulness/utils/logic';

import {Maybe} from '../../../types/types';
import {Label, Small} from '../../typography';

import {InputWrapper} from '../Input/Input.styles';
import {StyledTextarea} from './Textarea.styles';

export const Textarea: React.FC<Props> = ({
  label,
  validator,
  error,
  onChange,
  ...props
}) => {
  const [inValid, setInvalid] = React.useState<string>();
  return (
    <div>
      <InputWrapper readOnly={props.readOnly} error={Boolean(inValid || error)}>
        <Label>{label}</Label>
        <StyledTextarea
          {...props}
          error={Boolean(inValid || error)}
          onChange={(e) => {
            ifDo(Boolean(validator), () =>
              setInvalid(validator?.(e.target.value)),
            );
            onChange?.(e);
          }}
        />
      </InputWrapper>
      {error ? <Small colour="accent">{error}</Small> : null}
    </div>
  );
};

type Props = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label: string;
  validator?: (
    e: React.ChangeEvent<HTMLTextAreaElement>['target']['value']
  ) => Maybe<string>;
  error?: string;
};
