import React from 'react';

import {BackgroundAPIImage, Icon} from '../../global';
import {Box} from '../../layout';
import {ItemLocation, Maybe, WithWebSlug} from '../../../types/types';
import {BundleFeedFragment, CollectionLayout} from '../../../types/api';

import itemCardStyles from '../ItemCard/ItemCard.module.css';
import {Small} from '../../typography/Text';
import {H4} from '../../typography/Headings';
import {ItemContainer} from './BundleCard.styles';
import {assertString} from '../../../utils/string';
import {WithLinkProps, withLink} from '../../../hoc/withLink';
import {AspectRatio} from '../../layouts/AspectRatio';

export const CourseCard: React.FC<Props & WithLinkProps> = withLink(
    ({location, layout, ...item}) => {
      return (
        <ItemContainer location={location}>
          {location === 'reel' ||
        ['SHORT_GRID_1', 'GRID_1'].includes(assertString(layout)) ? (
          <ReelItem location={location} layout={layout} {...item} />
        ) : (
          <>
            <AspectRatio ratio="1:1">
              <Box
                color="white"
                position="relative"
                zIndex={1}
                radius="lg"
                overflow="hidden"
                paddingX={24}
              >
                <BackgroundAPIImage
                  className="cell-image"
                  doNotResize
                  src={item.cellImage?.url ?? item.cardImage?.url}
                  scale={1}
                  alt={`${item.title} ${item.subtitle} ${item.label}`}
                />
              </Box>
            </AspectRatio>
            <div className={itemCardStyles.textContainer}>
              <Small colour="grey7" fontSize="sm">
                {item.curator?.fullName}
              </Small>
              <H4 weight="bold">{item.title}</H4>
              <Small
                colour="grey7"
                transform="uppercase"
                fontSize="2xs"
                fontWeight="bold"
              >
                {item.label}
              </Small>
              <Small colour="grey7">
                {item.ratings?.averageFormatted ? (
                  <Icon name="star" size={12}>{item.ratings.averageFormatted}</Icon>
                ) : null}
              </Small>
            </div>
          </>
        )}
        </ItemContainer>
      );
    },
);

const ReelItem: React.FC<Props> = ({location, layout, ...item}) => {
  return (
    <AspectRatio ratio="598:340" justify="start">
      <Box
        color="white"
        position="relative"
        zIndex={1}
        radius="lg"
        overflow="hidden"
        paddingX={24}
      >
        <BackgroundAPIImage
          className="cell-image"
          doNotResize
          src={
            item.cardImage?.url || item.cellImage?.url || item.coverImage?.url
          }
          scale={1}
          alt={`${item.title} ${item.subtitle} ${item.label}`}
        />
      </Box>
    </AspectRatio>
  );
};

type Props = WithWebSlug<BundleFeedFragment> & {
  location?: ItemLocation;
  webSlug: NonNullable<BundleFeedFragment['webSlug']>;
  layout?: Maybe<CollectionLayout>;
};
