import * as React from 'react';
import {Theme} from '@emotion/react';
import styled from '@emotion/styled';

import {Small} from '../../typography';
import {Link} from '../../global';

export const PolicyLinks: React.FC<{ colour?: keyof Theme['colors'] }> = ({
  colour = 'grey7',
}) => (
  <Small colour={colour} fontSize="xs">
    By continuing, you agree to our{' '}
    <Link href="/terms-and-conditions" passHref>
      <StyledLink target="_blank">terms of service</StyledLink>
    </Link>{' '}
    and{' '}
    <Link href="/privacy-policy" passHref>
      <StyledLink target="_blank">privacy policy</StyledLink>
    </Link>
    .
  </Small>
);

const StyledLink = styled.a(() => `text-decoration: underline;`);
