import * as React from 'react';
import {switchEnum} from '@mindfulness/utils/logic';
import {justOne} from '@mindfulness/utils/array';
import {ErrorBoundary} from '@sentry/nextjs';

import {Maybe, MixedFragment, WithWebSlug} from '../../../types/types';
import {Container, Reel} from '../../layout';
import {assertString, unNull} from '../../../utils';

import {BundleFragment, CollectionFeedFragment} from '../../../types/api';
import {CollectionTitle} from '../CollectionTitle';
import {useHydratedCollection} from '../../../hooks/useHydratedCollection';
import {filterItems} from '../../../utils/version';
import {InProgressBundleCard} from '../InProgressBundleCard';
import {ErrorFallback} from '../../global/ErrorFallback';
import {useTrack} from '../../global/SegmentProvider';
import {Section} from '../../layouts/Section';

export const InProgressReel: React.FC<
  CollectionFeedFragment & {
    noLinks?: boolean;
    priority?: boolean;
    location?: 'collection';
    index: number;
    feedId: Maybe<string>;
  }
> = ({priority, noLinks, layout, location, index, feedId, ...staticCollection}) => {
  const {collection, ref, isIntersecting} = useHydratedCollection(staticCollection);
  const track = useTrack();
  const [type] = React.useMemo(() => {
    const item = justOne(collection?.results?.items);
    const bundleType = item?.__typename === 'Bundle' ? item.bundleType : null;
    return [item?.__typename, bundleType];
  }, [collection?.results.items]);

  const items = React.useMemo(() => {
    if (!collection?.results.items) {
      return [];
    }
    return collection.results.items;
  }, [collection?.results.items, type]);

  return !isIntersecting && index > 3 ? null : (
    <ErrorBoundary
      fallback={(props) => (
        <ErrorFallback
          {...props}
          index={index}
          sectionType={'InProgressReel'}
        />
      )}
    >
      {collection ? (
        <Section ref={ref} background="white" padding={40}>
          <Container>
            <CollectionTitle {...collection} location={location} />
          </Container>
          <Container gutter={{xs: 0, lg: 30}}>
            <Reel
              gap={{
                xs: 8,
                md: 16,
              }}
              withShadow={type === 'QuickLink'}
              show={{
                xs: 2.2,
                md: 3.2,
                lg: 4,
              }}
              scroll={{
                xs: 1,
                md: 2,
              }}
            >
              {filterItems(items as MixedFragment[]).map((item, index) => {
                return switchEnum(assertString(type, 'else'), {
                  Bundle: () => {
                    const bundle =
                      item as unknown as WithWebSlug<BundleFragment>;
                    const onClick = () => {
                      track('Collection item selected', {
                        item_id: bundle.id,
                        item_type: 'BUNDLE',
                        item_index: index,
                        collection_id: collection?.id,
                        collection_title: collection?.title,
                        feed_id: feedId,
                      });
                    };
                    return (
                      <InProgressBundleCard
                        {...bundle}
                        href={`/play?b=${bundle.id}`}
                        key={bundle.id}
                        priority={index < 4 && priority}
                        location="reel"
                        layout={unNull(layout)}
                        onClick={onClick}
                      />
                    );
                  },
                  else: null,
                });
              })}
            </Reel>
          </Container>
        </Section>
      ) : null}
    </ErrorBoundary>
  );
};
