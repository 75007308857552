import {ReferencedArticleFeed, ReferencedArticleReel} from '../types/types';

export const useEstimatedReadingTime = (article: ArticleArrayItem) => {
  const charCount = article.bodySections.reduce((r, a) => {
    const sectionChars =
      (a?.richTitleLength || 0) +
      (a?.titleLength || 0) +
      (a?.paragraphLength || 0) +
      (a?.richParagraphLength || 0) +
      (a?.blocks?.reduce(
          (pr, p) => pr + (p?.paragraphLength || 0) + (p?.titleLength || 0),
          0,
      ) || 0);
    return r + sectionChars;
  }, article?.bodyLength || 0);
  const estWordCount = charCount / 5;
  const estMinutes = Math.round(estWordCount / 180);
  return estMinutes;
};

type ArticleArrayItem =
  | ReferencedArticleFeed['articles'][0]
  | ReferencedArticleReel['articles'][0];
