import {ColoursGradients, ResponsiveValue} from '../../../types/types';
import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {AllColour} from '@mindfulness/cms';
import {getBottomPadding, getFontSize, textColourMap} from '../../../utils';

export const TextWrapper = styled.div<{
  background: AllColour;
}>(({theme, background}) => {
  const color = theme.colors[textColourMap(background)];
  return `
    ${color ? `color: ${color};` : ''}
    padding-bottom: ${theme.spacings[16]};
  `;
});

export const BlockContentContainer = styled.div<Props>(
    ({theme, fontSize, fontWeight, colour, spacing}) => `
    ${getFontSize(theme, fontSize)}
  ${fontWeight ? `font-weight: ${theme.fontWeights[fontWeight]};` : ''}
  ${colour ? `color: ${theme.colors[colour]};` : ''}
  ${getBottomPadding(theme, spacing)}

  p:last-child {
    margin-bottom: 0;
  }
  `,
);

export const StyledAnchor = styled.a<{
  background: ColoursGradients;
}>(({theme, background}) => {
  return `
    color: ${
      background === 'primary' ? theme.colors.white : theme.colors.primary
};
    text-decoration: underline;
  `;
});

type Props = {
  fontSize?: ResponsiveValue<keyof Theme['fontSizes']>;
  fontWeight?: keyof Theme['fontWeights'];
  colour?: keyof Theme['colors'];
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  gutter?: [keyof Theme['spacings'], keyof Theme['spacings']];
};
