import {switchSpace} from '../utils';

export const colors = switchSpace({
  'mindful.org': {
    greyWarm: '#F8F6F4',
    grey1: '#F2F4F4',
    grey3: '#EAEAEA',
    grey4: '#E2E2E2',
    grey5: '#949494',
    grey7: '#737373',
    grey8: '#4B4B4B',
    grey9: '#37374B',
    white: '#fff',
    primaryPale: '#CCDEFB',
    primary: '#01A7E1',
    secondary: '#6631F1',
    accent: '#FF4D6D',
    warning: '#DD5555',
    highlight: '#219653',
    highlight2: '#27AE60',
    primaryLight: '#5590F1',
    sleepPrimary: '#4C446C',
    sleepAccent: '#659DFC',
    sleep1: '#646485',
    sleep3: '#3C3C56',
    teal: '#264449',
    facebook: '#1877f2',
  },
  'else': {
    greyWarm: '#F8F6F4',
    grey1: '#F2F4F4',
    grey3: '#EAEAEA',
    grey4: '#E2E2E2',
    grey5: '#949494',
    grey7: '#737373',
    grey8: '#4B4B4B',
    grey9: '#37374B',
    white: '#fff',
    primaryPale: '#CCDEFB',
    primary: '#3D81F1',
    secondary: '#6631F1',
    accent: '#FF4D6D',
    warning: '#DD5555',
    highlight: '#219653',
    highlight2: '#27AE60',
    primaryLight: '#5590F1',
    sleepPrimary: '#4C446C',
    sleepAccent: '#659DFC',
    sleep1: '#646485',
    sleep3: '#3C3C56',
    teal: '#264449',
    facebook: '#1877f2',
  },
});
