import * as React from 'react';
import {uniqBy} from 'lodash';
import Marquee from 'react-fast-marquee';

import {Box, Column, Container, Row, Section} from '../../layout';
import {
  ReferencedTestimonialMarquee,
  SectionIndex,
} from '../../../types/types';
import {H1} from '../../typography';
import {TestimonialCard, TotalReviews} from '../../ui';

export const TestimonialMarqueeCurated: React.FC<Props> = ({
  index,
  title,
  testimonials,
  background,
  paddingTop,
  paddingBottom,
  image,
}) => {
  const flatTestimonials = React.useMemo(
      () => testimonials?.map(({testimonial}) => testimonial),
      [testimonials],
  );
  const uniqTestimonials = React.useMemo(
      () => uniqBy(flatTestimonials, 'user'),
      [flatTestimonials],
  );
  const firstHalf = React.useMemo(
      () => uniqTestimonials.slice(0, Math.ceil(uniqTestimonials.length / 2)),
      [uniqTestimonials],
  );
  const secondHalf = React.useMemo(
      () => uniqTestimonials.slice(Math.ceil(uniqTestimonials.length / 2)),
      [uniqTestimonials],
  );

  return (
    <Section
      padding={{
        xs: 30,
        lg: 80,
      }}
      background={background}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container maxWidth="md">
        <Row>
          <Column
            textAlign={'center'}
            justify="center"
            spacing={{
              xs: 16,
              md: 34,
            }}
          >
            <Box maxWidth={'370px'} width="100%">
              <H1 size="2xl" as={index === 0 ? undefined : 'h2'} spacing={16}>
                {title}
              </H1>
              <TotalReviews image={image} />
            </Box>
          </Column>
        </Row>
      </Container>
      <Marquee
        style={{
          marginBottom: '-36px',
        }}
        className="marquee"
      >
        {[...firstHalf, ...firstHalf, ...firstHalf].map(
            ({review, user, _key}) => (
              <TestimonialCard author={user} key={_key}>
                {review}
              </TestimonialCard>
            ),
        )}
      </Marquee>
      <Marquee className="marquee" direction="right">
        {[...secondHalf, ...secondHalf, ...secondHalf].map(
            ({review, user, _key}) => (
              <TestimonialCard author={user} key={_key}>
                {review}
              </TestimonialCard>
            ),
        )}
      </Marquee>
    </Section>
  );
};

type Props = SectionIndex<ReferencedTestimonialMarquee>;
