import * as React from 'react';

import {Box} from '../../../components/layout';
import {textColourMap} from '../../../utils';
import {ColoursGradients} from '../../../types/types';

export const TextWrapper: React.FC<
  React.PropsWithChildren<{
    background: ColoursGradients;
  }>
> = ({background, children}) => (
  <Box color={textColourMap(background)}>{children}</Box>
);
