import {OnClient} from '../components/ui/OnClient';

/**
 * Only returns the child component if rendering is occuring on client
 * @param {React.ComponentType<P>} Component - Child component
 * @param {string} displayName - The name to apply to the returned component
 * @return {React.FC} - The child component gets wrapped in a component that stops the child rendering unless it is on client side
 */
export function withOnClient<P extends {}>(
    Component: React.ComponentType<P>,
    displayName?: string,
): React.FC<P> {
  Component.displayName = 'WithOnClient';
  const WithOnClient = (props: P) => (
    <OnClient>
      <Component {...props} />
    </OnClient>
  );
  WithOnClient.displayName = displayName ?? 'WithOnClient';
  return WithOnClient;
}
