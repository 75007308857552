import * as React from 'react';
import {ImageSide as ImageSideProps} from '@mindfulness/cms';

import {H2, Small} from '../../typography';
import {
  BasicImage,
  BlockContent,
  CallToActionButton,
  TextWrapper,
} from '../../global';
import {Container, Row, Column, Section} from '../../layout';
import {SectionIndex} from '../../../types/types';

export const ImageSide: React.FC<SectionIndex<ImageSideProps>> = ({
  alignment = 'left',
  background = 'white',
  subtitle,
  content,
  image,
  imageDecoration,
  paddingTop = 60,
  paddingBottom = 60,
  _type,
  index,
}) => (
  <Section
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
    background={background}
  >
    <Container>
      <Row
        justify="space-evenly"
        items="center"
        reverse={alignment === 'right'}
      >
        <Column
          span={{
            xs: 24,
            md: 9,
          }}
          spacing={{
            xs: 30,
            sm: 60,
            md: 0,
          }}
        >
          <BasicImage
            image={image}
            layout="responsive"
            decoration={imageDecoration}
            background={background}
            priority={index < 2}
          />
        </Column>
        <Column
          span={{
            xs: 24,
            md: 9,
          }}
          textAlign={{
            xs: 'center',
            md: 'start',
          }}
        >
          <TextWrapper background={background}>
            {subtitle ? (
              <Small colour="primary" fontWeight="bold" transform="uppercase">
                {subtitle}
              </Small>
            ) : null}
            {content?.title ? <H2 spacing={10}>{content?.title}</H2> : null}
            <BlockContent
              content={content?.paragraph}
              background={background}
              spacing={{
                xs: 40,
                md: 50,
              }}
            />
          </TextWrapper>
          {content?.cta?.text ? (
            <CallToActionButton id={`${_type}-${index}`} {...content?.cta}>
              {content?.cta?.text}
            </CallToActionButton>
          ) : null}
        </Column>
      </Row>
    </Container>
  </Section>
);
