import {
  BundleFeedFragment,
  FeatureFeedFragment,
  PosterFeedFragment,
  ProducerFeedFragment,
  QuickLinkFeedFragment,
  SingleFeedFragment,
  TopicFeedFragment,
} from '../types/api';
import {CollectionFeedFragment, Maybe, TagFeedFragment} from '../types/types';
import {mapDeeplink} from './mapDeeplink';
import {assertString} from './string';

export const getVersion = () => {
  if (!process.env.WEB_VERSION) {
    throw new Error('WEB_VERSION is not defined');
  }
  const [major, minor, patch] = process.env.WEB_VERSION.split('.');
  return [Number(major), Number(minor), Number(patch)];
};

export const filterSingle = ({singleStatus}: SingleFeedFragment) => {
  return singleStatus === 'LIVE';
};

export const filterFeature = ({webLink, deepLink}: FeatureFeedFragment) => {
  return Boolean(assertString(mapDeeplink(deepLink) || webLink));
};

export const filterProducer = ({
  webSlug,
  cellImage,
}: ProducerFeedFragment) => {
  return Boolean(webSlug) && Boolean(cellImage?.url);
};

export const filterBundle = ({webSlug, status}: BundleFeedFragment) => {
  return Boolean(webSlug) && status === 'LIVE';
};

export const filterTopic = ({webSlug, deepLink}: TopicFeedFragment) => {
  return Boolean(webSlug) || Boolean(mapDeeplink(deepLink));
};

export const filterQuickLink = ({
  webLink,
  deepLink,
}: QuickLinkFeedFragment) => {
  return Boolean(assertString(mapDeeplink(deepLink) || webLink));
};

export const filterPoster = ({webLink, deepLink}: PosterFeedFragment) => {
  return Boolean(assertString(mapDeeplink(deepLink) || webLink));
};

type CollectionItem = CollectionFeedFragment['results']['items'][0];
const itemFilter = (item: CollectionItem) => {
  if (item.__typename === 'Single') {
    return filterSingle(item);
  }
  if (item.__typename === 'Feature') {
    return filterFeature(item);
  }
  if (item.__typename === 'Producer') {
    return filterProducer(item);
  }
  if (item.__typename === 'Bundle') {
    return filterBundle(item);
  }
  if (item.__typename === 'Topic') {
    return filterTopic(item);
  }
  if (item.__typename === 'QuickLink') {
    return filterQuickLink(item);
  }
  if (item.__typename === 'Poster') {
    return filterPoster(item);
  }
  return false;
};

export const filterItems = (
    items: CollectionFeedFragment['results']['items'],
): CollectionFeedFragment['results']['items'] => {
  return items?.filter(itemFilter);
};

export const filterCollections = (
    collections: Array<CollectionFeedFragment>,
) => {
  const filteredCollections = collections.filter(({results}) => {
    const foundItem = results.items.find(itemFilter);
    return Boolean(foundItem);
  });
  return filteredCollections;
};

export const filterEmptyFeeds = (feeds: Maybe<TagFeedFragment[]>) => {
  return feeds?.filter((feed) => {
    const collections = filterCollections(feed.collections);
    const hasCollections = Boolean(collections.length);

    return hasCollections;
  });
};
