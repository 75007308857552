import {useCallback, useEffect, useState} from 'react';
import {Maybe} from '@mindfulness/utils/maybe';
import {useSearchParams} from 'next/navigation';
import {useNewNavigation} from './useNewNavigation';

export const useQueryParam = (
    name: string,
    initialValue?: string,
    shallow = true,
) => {
  const {goTo, goToReplace, removeQueryParam} = useNewNavigation();
  const searchParams = useSearchParams();
  const [param, setParam] = useState<string | undefined>(initialValue);

  useEffect(() => {
    setParam(searchParams.get(name) ?? undefined);
  }, [searchParams]);

  const setter = useCallback(
      (val: SetParams) => {
        const setFn =
        typeof val === 'function' ?
          val :
          () => {
            return val;
          };
        const nextVal = setFn(searchParams.get(name) as Maybe<string>);

        setParam(nextVal);
        const query = {
          [name]: nextVal,
        };
        if (nextVal === undefined) {
          removeQueryParam(name);
          return;
        }
        if (shallow) {
          goToReplace({
            query,
          });
          return;
        }
        goTo({
          query,
        });
      },
      [name, param],
  );
  const getAndSet: [get: Maybe<string>, set: (setter: SetParams) => void] = [
    param,
    setter,
  ];

  return getAndSet;
};

type SetParams = Maybe<string> | ((prev: Maybe<string>) => Maybe<string>);
