import {gql} from '@apollo/client';
import {ShareFragment} from '../fragments';

export const REDEEM_SHARE = gql`mutation redeemShare($code: ID!) {
  redeemShare(code: $code) {
    ...ShareFragment
  }
}
${ShareFragment}
`;
