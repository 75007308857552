import styled from '@emotion/styled';

export const StyledCheckbox = styled.input(
    ({theme, checked}) => `
  margin: 0 ${theme.spacings[8]} 0 0;
  appearance: none;
  // Remove iOS < 15 background gradient
  background-color: transparent;
  width: ${theme.spacings[20]};
  height: ${theme.spacings[20]};
  border: 0.125rem solid ${theme.colors.primary};
  border-radius: 3px;
  display: grid;
  place-content: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem ${theme.colors.primary};
    ${checked ? 'transform: scale(1)' : 'transform: scale(0)'};
    border-radius: 2px;
  }
`,
);

export const InfoButton = styled.button(
    ({theme}) => `
  margin-left: ${theme.spacings[8]};
  position: relative;
  `,
);
