import * as React from 'react';
import {useContext, memo, useRef} from 'react';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import {ErrorBoundary} from '@sentry/nextjs';
import {useRecoilState, useSetRecoilState} from 'recoil';
import {when} from '@mindfulness/utils/maybe';
import {
  MeditationTemplate,
  MusicTemplate,
  SoundTemplate,
  TalkTemplate,
  ScenicTemplate,
  BreathworkTemplate,
  StoryTemplate,
  SpecialTemplate,
} from '@mindfulness/cms';
import QRCode from 'qrcode.react';
import {useRouter} from 'next/router';
import {isNull} from 'lodash';

import style from './SingleLayout.module.css';
import {Box, Card, Container, Flex, Stack} from '../../layout';
import {Text, H1, Title1, Small} from '../../typography';
import {videoSingleSchema} from '../../../schemas';

import {
  Maybe,
  SingleDetailFragment,
  SingleVariantFragment,
} from '../../../types/types';

import {useVariant} from '../../../hooks/useVariant';
import {useHydratedSingle} from '../../../hooks/useHydratedSingle';
import {HydratedPurchase, usePurchases} from '../../../hooks/usePurchases';

// import {Dropdown} from '../Dropdown';
import {SingleShare} from '../SingleShare';
import {Icon} from '../../global/Icon';
import {assertString, splitLabel} from '../../../utils/string';
import {TeacherPanel} from '../TeacherPanel';
import {Divider} from '../Divider';
import {useLockedSingle} from '../../../hooks/useLockedSingle';
import {Purchases} from '../Purchases';
import {Button} from '../../forms';
import {VariantSelector} from './VariantSelector';
import {playerPlayState, redirectState} from '../../../state/atoms';
import {Context} from '../../global';
import {APPSFLYER_BASE_URL, utmToDeeplink} from '../../../utils';

import {
  UnderlinedButton,
  UnderlinedLink,
} from '../../sections/HeroBundle/HeroBundle.styles';
import {OnClient} from '../OnClient';
import {AvailableEverywhere} from '../AvailableEverywhere';
import {ShareCard} from '../ShareCard';
import {useToast} from '../../../hooks/useToast';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {redeemShare} from '../../../api/functions/redeemShare';
import {useTrack} from '../../global/SegmentProvider';
import {useTrackPage} from '../../../hooks/useTrackPage';
import {MobileBanner} from '../MobileBanner';
import {LeftContainer, RightContainer, SplitContainer} from '../ContentSplit/ContentSplit.styles';
import {MediaType} from '../../../types/api';
import {audioSingleSchema} from '../../../schemas/AudioObject';
import {Section} from '../../layouts/Section';

/**
 * Gets the schema.org for the single depending on the media type
 * @param {SingleDetailFragment} single
 * @param {SingleVariantFragment} variant
 * @return {object}
 */
function getSchema(single: SingleDetailFragment & {
  transcripts: Array<string>;
}, variant: Maybe<SingleVariantFragment>) {
  if (!variant?.chapters) return;
  if (variant.chapters && variant.chapters.length > 0) {
    const lastChapter = variant.chapters[variant.chapters.length - 1];
    if (lastChapter.type === MediaType.Audio) {
      return audioSingleSchema({single});
    }
    return videoSingleSchema({single});
  }
  return variant.chapters[0].type === MediaType.Audio ? audioSingleSchema({single}) : videoSingleSchema({single});
}

const CollectionsList = dynamic(() =>
  import('../CollectionsList/index').then(
      ({CollectionsList}) => CollectionsList,
  ),
);

const Sections = dynamic(() =>
  import('../../global/Sections/index').then(({Sections}) => Sections),
);

const SinglePlayer = dynamic(() =>
  import('../../ui/SinglePlayer/index').then(({SinglePlayer}) => SinglePlayer),
);

export const SingleLayout: React.FC<Props> = ({
  template,
  single: {transcripts, ...staticSingle},
}) => {
  const isTriggered = useRef<boolean>(false);
  useTrackPage(staticSingle.title, staticSingle.id, {
    page_type: staticSingle.type,
  });
  const {handleAdd} = useToast();
  const track = useTrack();
  const [linkId, setLinkId] = useQueryParam('link_id');
  const [sharerId, setSharerId] = useQueryParam('sharer_id');
  const [sharerNameParam] = useQueryParam('sharer_name');
  const {loading: loadingPurchases, hydratedPurchases} = usePurchases(
      template?.purchases || [],
      template?.coupon,
  );
  const {single, loading: loadingSingle, refreshSingle} = useHydratedSingle(staticSingle);
  const {session} = useContext(Context);

  const {variant, setVariant} = useVariant({
    single,
  });
  // const transcript = useMemo(() => transcripts?.[0], []);
  const firstContributor = React.useMemo(
      () => single.contributors?.[0]?.producer,
      [single],
  );
  const {isLocked, loginAction} = useLockedSingle(single);
  const purchases = hydratedPurchases.filter((p) => {
    if (!single.userData) return true;
    if (
      ['BASICS_OR_PLUS', 'BASICS'].includes(
          assertString(single.userData.upgradeTo),
      )
    ) {
      return true;
    }
    if (
      single.userData.upgradeTo === 'PLUS' &&
      p.purchase.purchase?.title?.includes('Plus+')
    ) {
      return true;
    }
  });

  React.useEffect(() => {
    (async () => {
      if (linkId && sharerId && track && session) {
        if (isTriggered.current) return;
        isTriggered.current = true;
        const toast = <Text fontWeight="bold">{`✅ ${sharerNameParam || 'This was'} shared this with you`}</Text>;
        if (isLocked) {
          const res = await redeemShare({code: linkId}, track);
          if (res?.id) {
            setLinkId(undefined);
            setSharerId(undefined);
            handleAdd?.(toast); ;
            refreshSingle();
          }
        } else {
          handleAdd?.(toast);
        }
      }
    })();
  }, [linkId, sharerId, track, isLocked]);

  return (
    <ErrorBoundary
      fallback={
        <Section>
          <Container maxWidth="prose">
            <H1>Ooops, an error occured</H1>
            <Text>{`An error occured displaying ${single.title}. We are aware of this issue and working to resolve it as quickly as possible.`}</Text>
          </Container>
        </Section>
      }
    >
      <Section className={style.smNoPadding}>
        <SplitContainer splitPoint={'lg'}>
          <LeftContainer>
            <div className={style.showMobile}>
              <ShareCard onRedeem={refreshSingle} />
            </div>
            <SinglePlayer frame={true} single={single} variant={variant} />
            <div className={style.singleDesktopContent}>
              <Flex justify="space-between">
                {single.title ? (
                    <Title1 as="h1">{single.title}</Title1>
                  ) : null}
                <SingleShare colour="primary" justify="end" single={single} />
              </Flex>
              {single.description ? (
                  <Text fontSize="md" spacing={10} colour="grey9">
                    {single.description}
                  </Text>
                ) : null}

              {/* {transcript ? (
                  <Dropdown
                    moreText="Read Full Transcript"
                    lessText="Hide Transcript"
                    minHeight={200}
                  >
                    {transcript.split('\n').map((t) => (
                      <Text colour="grey8" key={t}>
                        {t}
                      </Text>
                    ))}
                  </Dropdown>
                ) : null} */}
            </div>
            <div className={style.singleMobileContent}>
              <Flex justify="space-between">
                <Stack space={10}>
                  {single.label ? (
                      <Small
                        spacing={10}
                        colour="primary"
                        fontWeight="bold"
                        transform="uppercase"
                        as="h3"
                      >
                        {splitLabel(single.label)}
                      </Small>
                    ) : null}
                  <Small colour="grey7">
                    {single.ratings?.average ? (
                      <Icon name="star" size={12} space={4}>
                        {single.ratings.average}
                      </Icon>
                    ) : null}
                  </Small>
                </Stack>
                <SingleShare colour="primary" justify="end" single={single} />
              </Flex>
              {single.title ? <Title1 as="p">{single.title}</Title1> : null}
              {single.description ? (
                  <Text fontSize="sm" spacing={10} colour="grey7">
                    {single.description}
                  </Text>
                ) : null}
              <VariantSelector
                single={single}
                variant={variant}
                setVariant={setVariant}
              />
              <Text fontSize="xs" colour="grey7" spacingTop={16} spacing={16}>
                  Your default time is based on your progress and is changed
                  automatically as you practice.
              </Text>
              {/* {transcript ? (
                  <Dropdown
                    moreText="Read Full Transcript"
                    lessText="Hide Transcript"
                    minHeight={0}
                  >
                    {transcript.split('\n').map((t) => (
                      <Text colour="grey8" key={t}>
                        {t}
                      </Text>
                    ))}
                  </Dropdown>
                ) : null} */}
              {firstContributor?.webSlug ? (
                  <TeacherPanel teacher={firstContributor} />
                ) : (
                  <Divider spacing={16} />
                )}
              <SinglePurchases
                single={single}
                template={template}
                isLocked={!!isLocked}
                hydratedPurchases={purchases}
                loadingPurchases={loadingPurchases}
              />
            </div>
          </LeftContainer>
          <RightContainer splitPoint={'lg'}>
            <div className={style.singleDesktopContent}>
              <ShareCard onRedeem={refreshSingle}/>
            </div>
            <Card
              show={'lg'}
              height="auto"
              justify="space-between"
              align="start"
              spacing={16}
              textAlign="left"
            >
              <div className={style.singleDesktopContent}>
                <DesktopContent
                  template={template}
                  single={single}
                  isLocked={!!isLocked}
                  hydratedPurchases={purchases}
                  loadingPurchases={loadingPurchases}
                  loadingSingle={loadingSingle}
                  variant={variant}
                  setVariant={setVariant}
                  firstContributor={firstContributor}
                />
              </div>
            </Card>
            {isLocked ? (
              <Text align="center">
                Already purchased?{' '}
                {session ? (
                  <UnderlinedLink href={'/contact'}>
                    {'Contact Support'}
                  </UnderlinedLink>
                ) : (
                  <UnderlinedButton
                    as="button"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      track('Button clicked', {
                        path: window.location.pathname,
                        button_text: 'Sign in',
                        id: 'BundleHeroSignIn',
                      });
                      loginAction();
                    }}
                  >
                    {'Sign in'}
                  </UnderlinedButton>
                )}
              </Text>
            ) : null}
          </RightContainer>
        </SplitContainer>
      </Section>

      {single.relatedCollections ? (
        <Box>
          <CollectionsList
            feedId={undefined}
            collections={single.relatedCollections}
            preventHydration={true}
          />
        </Box>
      ) : null}

      {template?.sections ? (
        <Sections sections={template.sections} indexOffset={1} />
      ) : null}
      <MobileBanner deeplink={`mcom://single/${single.id}`}/>
      <Head>
        <script {...getSchema({transcripts, ...single}, variant)} />
      </Head>
    </ErrorBoundary>
  );
};

const DesktopContent: React.FC<{
  template: SingleTemplates;
  single: SingleDetailFragment;
  isLocked: boolean;
  hydratedPurchases: HydratedPurchase[];
  loadingPurchases: boolean;
  loadingSingle: boolean;
  variant: Maybe<SingleVariantFragment>;
  setVariant: React.Dispatch<
    React.SetStateAction<Maybe<SingleVariantFragment>>
  >;
  firstContributor?: SingleDetailFragment['contributors'][0]['producer'];
}> = ({
  single,
  isLocked,
  firstContributor,
  hydratedPurchases,
  loadingPurchases,
  loadingSingle,
  variant,
  setVariant,
  template,
}) => {
  const {session} = useContext(Context);
  const router = useRouter();
  const link = React.useMemo(() => {
    const params = new URLSearchParams(utmToDeeplink(router.query)).toString();
    return when(
        single,
        () =>
          `${APPSFLYER_BASE_URL}?af_dp=${single.deepLink}${
          params.length ? `&${params}` : ''
          }`,
    );
  }, [single, router.isReady]);

  return (
    <>
      <Stack space={10}>
        {single.label ? (
          <Small
            spacing={10}
            colour="primary"
            fontWeight="bold"
            transform="uppercase"
            as="h3"
          >
            {splitLabel(single.label)}
          </Small>
        ) : null}
        <Small colour="grey7">
          {single.ratings?.average ? (
            <Icon name="star" size={12} space={4}>
              {single.ratings.average}
            </Icon>
          ) : null}
        </Small>
      </Stack>
      <VariantSelector
        single={single}
        variant={variant}
        setVariant={setVariant}
      />
      {firstContributor?.webSlug ? (
        <TeacherPanel teacher={firstContributor} />
      ) : (
        <Divider spacing={16} />
      )}


      {isNull(session) || loadingPurchases || loadingSingle ? null : (
          <>
            {isLocked && !loadingPurchases ? (
              <SinglePurchases
                single={single}
                template={template}
                isLocked={!!isLocked}
                hydratedPurchases={hydratedPurchases}
                loadingPurchases={loadingPurchases}
              />
            ) : (
              <PlayButton />
            )}
          </>
        )}


      <Divider spacing={30} />

      <Box background="greyWarm" radius="lg" padding={16}>
        <Flex gap={16}>
          <Box shrink={0} height="78px" width="78px">
            <OnClient>
              {link ? <QRCode value={link} size={78} /> : null}
            </OnClient>
          </Box>
          <Box>
            <Text
              colour="primary"
              fontSize="2xs"
              transform="uppercase"
              fontWeight="bold"
            >
              Play in-app
            </Text>
            <Text fontSize="xs">
              Scan the following QR code with your camera app to open it on our
              mobile app
            </Text>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

const PlayButton: React.FC = memo(function PlayButton() {
  const [playerStatus, setPlayerStatus] = useRecoilState(playerPlayState);

  return (
    <Button
      id="PlaySingle"
      wide
      onClick={() => {
        setPlayerStatus((prev) =>
                  prev === 'playing' ? 'paused' : 'playing',
        );
      }}
    >
      <Icon
        name={
                  playerStatus === 'playing' ? 'player-pause' : 'player-play'
        }
      >
        {playerStatus === 'playing' ? 'Pause' : 'Play'}
      </Icon>
    </Button>

  )
  ;
});

const SinglePurchases: React.FC<{
  template: SingleTemplates;
  single: SingleDetailFragment;
  isLocked: boolean;
  hydratedPurchases: HydratedPurchase[];
  loadingPurchases: boolean;
}> = ({template, isLocked, hydratedPurchases, loadingPurchases}) => {
  const [selected, setSelected] = React.useState<HydratedPurchase>();
  const setRedirect = useSetRecoilState(redirectState);
  const router = useRouter();

  return (
    <>
      {isLocked && !loadingPurchases ? (
        <>
          <Purchases
            purchases={template.purchases || []}
            hydratedPurchases={hydratedPurchases}
            loadingPurchases={loadingPurchases}
            selected={selected}
            setSelected={setSelected}
            index={0}
            coupon={template?.coupon}
            onAddToCart={() => setRedirect(router.asPath)}
          />
          <Divider spacing={30} />
          <AvailableEverywhere />
        </>
      ) : null}
    </>
  );
};

type Props = {
  single: SingleDetailFragment & {
    transcripts: Array<string>;
  };
  template: SingleTemplates;
};

type SingleTemplates =
  | MeditationTemplate
  | SpecialTemplate
  | MusicTemplate
  | SoundTemplate
  | TalkTemplate
  | ScenicTemplate
  | StoryTemplate
  | BreathworkTemplate;
