import * as React from 'react';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';

import * as AllSections from '../../sections';

import {ErrorFallback} from '../../global';
import {ReferencedPages} from '../../../types/types';
import {OrderFragment} from '../../../types/api';
import {useIntersection} from '../../../hooks/useIntersection';

export const Sections: React.FC<Props> = ({
  sections,
  indexOffset = 0,
  position,
  items,
}) => {
  if (process.env.MINDFULNESS_SPACE !== 'mindfulness.com') {
    return null;
  }
  return (
    <>
      {sections?.map((section, index) => {
        const i = index + indexOffset;
        return (
          <Sentry.ErrorBoundary
            key={section._key as never}
            fallback={(props) => (
              <ErrorFallback
                {...props}
                index={index}
                sectionType={section._type} />
            )}
          >
            {/*
              // @ts-ignore */}
            <SectionMapper
              {...section}
              index={i}
              position={position}
              items={items} />
          </Sentry.ErrorBoundary>
        );
      })}
    </>
  );
};

const SectionMapper: React.FC<
  NonNullable<ReferencedPages['sections']>[0] & {
    index: number;
    position?: 'checkout';
    items?: OrderFragment['purchasables'];
  }
> = ({index, position, items, ...section}) => {
  const {ref, isIntersecting} = useIntersection(true);
  if (index > 2) {
    const Section = dynamic(() =>
      import(`../../sections/${section._type}/index`).then(
          (s) => s[section._type],
      ),
    );
    if (!Section) {
      Sentry.captureException(
          `Dynamic section not found of type "${section._type}"`,
      );
      return <></>;
    }

    return (
      // @ts-ignore
      <Section {...section} index={index} position={position} items={items} />
    );
  }

  const Section = AllSections[section._type] || undefined;
  if (!Section) {
    Sentry.captureException(`Section not found of type "${section._type}"`);
    return <></>;
  }

  return (
    <div ref={ref}>
      {index < 2 ? (
        // @ts-ignore
        <Section {...section} index={index} position={position} items={items} />
      ) : (
        <>
          {isIntersecting && (
            // @ts-ignore
            <Section {...section} index={index} position={position} items={items} />
          )}
        </>
      )}


    </div>
  );
};

type Props = {
  sections: ReferencedPages['sections'];
  indexOffset?: number;
  position?: 'checkout';
  items?: OrderFragment['purchasables'];
};
