import styled from '@emotion/styled';

export const DirectionArrow = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateX(50%) translateY(-50%);
  z-index: 1;
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.md.min}) {
    display: block;
  }
`;
