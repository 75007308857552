import * as React from 'react';

import {ModalContext, OverlayModal} from '../../global';
import {H2, Text} from '../../typography';
import {Box, Column, Container, Row} from '../../layout';

export const LocalStorageError: React.FC = () => {
  const {getActiveProps} = React.useContext(ModalContext);
  const props = getActiveProps('incognito');
  return (
    <OverlayModal name="incognito" closeButton>
      <Box maxWidth="520px">
        <Container>
          <Row>
            <Column textAlign="center">
              <H2 as="p" spacing={16}>
                {props?.title || 'Private Browsing'}
              </H2>
              {props?.message ? <Text>{props?.message}</Text> : null}
            </Column>
          </Row>
        </Container>
      </Box>
    </OverlayModal>
  );
};
