import * as React from 'react';
import {BrandMission as BrandMissionProps} from '@mindfulness/cms';

import {BasicImage, CallToActionButton} from '../../global';
import {Box, Column, Container, Grid, Row, Section} from '../../layout';
import {SectionIndex} from '../../../types/types';
import {H2, H3, Text} from '../../typography';

import {DirectionArrow} from './BrandMission.styles';

export const BrandMission: React.FC<SectionIndex<BrandMissionProps>> = ({
  blocks,
  cta,
  _type,
  index,
}) => (
  <Section
    background="greyWarm"
    sectionPadding={{paddingTop: 0, paddingBottom: 171}}
  >
    <Container maxWidth="lg">
      <Row
        spacing={{
          xs: 20,
          md: 50,
        }}
      >
        <Column textAlign="center">
          <H2 colour="primary">We help you go from</H2>
        </Column>
      </Row>
      <Grid
        cols={{
          xs: 1,
          md: 3,
        }}
        rowGap={72}
        columnGap={0}
      >
        {blocks?.map(({image, title, subtitle, _key}, index) => (
          <Box textAlign="center" key={_key}>
            <Box position="relative">
              <BasicImage image={image} decoration="rounded" radius="sm" />
              {index < 2 ? (
                <DirectionArrow>
                  <svg
                    width="88"
                    height="33"
                    viewBox="0 0 88 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.38154 29.2442C9.1903 21.827 16.7788 16.9277 25.4037 13.9388C38.4291 9.42537 53.8203 9.26582 65.7089 11.4551C73.181 12.8302 77.7764 16.2584 84.7418 19.1993C85.0239 19.3183 85.3491 19.1863 85.4681 18.9041C85.5873 18.6233 85.4551 18.2968 85.1731 18.1792C78.1262 15.2038 73.4686 11.7566 65.9092 10.3649C53.8549 8.14524 38.249 8.31567 25.0407 12.8918C16.1779 15.9629 8.3937 21.0187 3.45162 28.641C3.28503 28.8978 3.35743 29.2404 3.61419 29.407C3.87095 29.5736 4.21495 29.501 4.38154 29.2442Z"
                      fill="#264449"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M86.8037 18.5349C86.7793 18.523 86.6152 18.4472 86.4848 18.3691C85.9421 18.0488 85.0438 17.4013 83.9588 16.5174C80.3226 13.548 74.604 7.92122 73.3442 2.76718C73.2715 2.46987 72.9715 2.28851 72.6742 2.36124C72.3769 2.43397 72.1939 2.7328 72.2667 3.03011C73.5774 8.39282 79.4746 14.287 83.2586 17.3756C83.7657 17.7889 84.2346 18.1542 84.6492 18.4598C83.0777 18.3781 81.5115 18.2561 79.9425 18.116C75.426 17.7113 71.4349 18.2967 67.1418 19.859C66.8548 19.9627 66.706 20.2818 66.8111 20.5686C66.9148 20.8556 67.2339 21.0044 67.5208 20.8993C71.6531 19.3964 75.4957 18.8304 79.8431 19.2194C81.8934 19.402 83.942 19.5537 85.9989 19.6293C86.2036 19.6369 86.7358 19.6506 86.8107 19.6408C87.2378 19.5854 87.2912 19.224 87.299 19.1437C87.3031 19.1049 87.3255 18.6209 86.8037 18.5349ZM86.5843 19.6209C86.5988 19.623 86.6134 19.6265 86.6293 19.6284C86.6164 19.6275 86.6018 19.624 86.5843 19.6209Z"
                      fill="#264449"
                    />
                  </svg>
                </DirectionArrow>
              ) : null}
            </Box>
            <Box paddingX={26}>
              <H3 weight="bold" spacing={10}>
                {title}
              </H3>
              <Text>{subtitle}</Text>
            </Box>
          </Box>
        ))}
      </Grid>
      {cta?.text ? (
        <Box display="flex" justify="center" paddingT={104}>
          <CallToActionButton id={`${_type}-${index}`} {...cta}>
            {cta?.text || 'Start for Free'}
          </CallToActionButton>
        </Box>
      ) : null}
    </Container>
  </Section>
);
