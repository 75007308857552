import * as React from 'react';

import {ReelTrack, ReelWrapper} from '../ui/Reel/Reel.styles';

export const BasicReel: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  center,
  Spacer,
  FrontSpacer,
}) => {
  const [dragging, setDragging] = React.useState<boolean>(false);
  const reelRef = React.useRef<HTMLDivElement>(null);

  return (
    <ReelWrapper center={!!center}>
      <ReelTrack
        onMouseMove={(e) => {
          if (e.buttons > 0) {
            setDragging(true);
            const current = reelRef.current;
            if (current) {
              current.scrollLeft -= e.movementX;
            }
            return;
          }
          setDragging(false);
        }}
        dragging={dragging}
        ref={reelRef}
      >
        {FrontSpacer ? <FrontSpacer /> : null}
        {children}
        {Spacer ? <Spacer /> : null}
      </ReelTrack>
    </ReelWrapper>
  );
};

type Props = {
  center?: boolean;
  Spacer?: React.FC;
  FrontSpacer?: React.FC;
};
