import {useState, useEffect, useCallback} from 'react';
import {Maybe, when} from '@mindfulness/utils/maybe';
import * as Sentry from '@sentry/nextjs';
import {
  clearSession,
  getGraphClient,
  getSession,
  StoredSession,
  storeSession,
  toStoredSession,
  unNull,
} from '../../../utils';
import {SessionFragment} from '../../../types/types';
import {GetSessionQuery} from '../../../types/api';
import {GET_SESSION} from '../../../api';
import {useIdentify} from '../SegmentProvider';
import {omitEmpty} from '@mindfulness/utils/object';

/**
 *
 * @return {object} - Returns the current session, a method to set the current session and methods to handle logging in and out.
 */
export const useSession = () => {
  const identify = useIdentify();
  const [session, setSession] = useState<Maybe<StoredSession> | null>(null);
  const [initialSession, setInitialSession] =
    useState<Maybe<StoredSession> | null>(null);

  useEffect(() => {
    const session = getSession();
    setSession(session);
    setInitialSession(session);
  }, []);

  /**
   * Stores the session in localStorage and context state, from any login provider
   */
  const handleLogin = useCallback((sess: Maybe<SessionFragment>) => {
    when(sess, (s) => setSession(() => storeSession(toStoredSession(s))));
  }, []);

  /**
   * Clears the session from localStorage and context state
   * and clears authentication from any SSO providers
   */
  const handleLogout = useCallback(() => {
    clearSession();
    setSession(undefined);
    setInitialSession(undefined);
  }, []);

  const refreshSession = async () => {
    const {
      data: {session},
    } = await getGraphClient().query<GetSessionQuery>({
      query: GET_SESSION,
      fetchPolicy: 'network-only',
    });
    if (session) {
      Sentry.setUser(omitEmpty({
        email: unNull(session?.user?.email),
        id: session?.user?.publicId,
      }));
    } else {
      Sentry.setUser(null);
    }
    await identify(
        {
          email: session?.user?.email,
          name: session?.user?.fullName,
        },
        session?.user?.publicId,
    );
    setSession(toStoredSession(session));
    return storeSession(toStoredSession(session));
  };


  return {
    session,
    initialSession,
    setSession,
    handleLogin,
    handleLogout,
    refreshSession,
  };
};

export type HandleLogin = (session: Maybe<SessionFragment>) => void;
