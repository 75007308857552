import * as React from 'react';

import {Stack, Box} from '../../layout';
import {Text} from '../../typography';
import {DiscountContext, Icon} from '../../global';
import {formatMoney} from '../../../utils';
import {CloseButton} from './DiscountBar.styles';

export const DiscountBar: React.FC<Props> = ({innerRef}) => {
  const {discount, invalidCoupon, handleInvalidCoupon} =
    React.useContext(DiscountContext);

  const discountString = React.useMemo(() => {
    if (!discount) {
      return '';
    }
    if ('percentOff' in discount && discount.percentOff) {
      return `${discount.percentOff}% discount applied`;
    }
    if ('amountOff' in discount && discount.amountOff) {
      return `${formatMoney(discount.amountOff)} discount applied`;
    }
    if ('trialDays' in discount && discount.trialDays) {
      return `${discount.trialDays} day trial applied`;
    }
  }, [discount]);

  return discountString || invalidCoupon ? (
    <Box
      background={invalidCoupon ? 'warning' : 'highlight'}
      color="white"
      display="flex"
      justify="center"
      items="center"
      paddingY={8}
      position="relative"
      ref={innerRef}
    >
      <Stack direction="vertical" justify="center">
        <Text align="center" fontWeight="bold">
          {invalidCoupon ? 'Coupon no longer valid.' : discountString}
        </Text>
        {invalidCoupon ? (
          <Text align="center" fontSize="xs">
            Enter another coupon.
          </Text>
        ) : null}
      </Stack>
      {invalidCoupon ? (
        <CloseButton onClick={() => handleInvalidCoupon?.(false)}>
          <Icon name="close-modal" size={20} />
        </CloseButton>
      ) : null}
    </Box>
  ) : null;
};

type Props = {
  innerRef?: React.RefObject<HTMLDivElement>;
};
