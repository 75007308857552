import {gql} from '@apollo/client';

const ProducerFeedFragment = gql`
  fragment ProducerFeedFragment on Producer {
    webSlug
    avatar {
      url
    }
    cellImage {
      url
    }
    fullName
    blurb
    id
  }
`;

const ProducerContributionFeedFragment = gql`
  fragment ProducerContributionFeedFragment on ProducerContribution {
    id
    producer {
      ...ProducerFeedFragment
    }
  }
  ${ProducerFeedFragment}
`;

/**
 * Used to render quicklinks inside a collection.
 */
const QuickLinkFeedFragment = gql`
  fragment QuickLinkFeedFragment on QuickLink {
    quickLinkTitle: title
    subtitle
    icon
    id
    webLink
    deepLink
  }
`;

const PosterFeedFragment = gql`
  fragment PosterFeedFragment on Poster {
    id
    title
    subtitle
    webLink
    deepLink
    showTitles
    posterImage {
      url
    }
  }
`;

const TopicFeedFragment = gql`
  fragment TopicFeedFragment on Topic {
    title
    subtitle
    inlineTitle
    webSlug
    deepLink
    id
    coverImage {
      url
    }
  }
`;

const FeatureFeedFragment = gql`
  fragment FeatureFeedFragment on Feature {
    id
    heightRatio
    webLink
    deepLink
    title
    cardImage {
      url
    }
    cellImage {
      url
    }
    coverImage {
      url
    }
  }
`;

const BundleFeedFragment = gql`
  fragment BundleFeedFragment on Bundle {
    id
    title
    description
    status
    webSlug
    subtitle
    label
    isNew
    bundleType: type
    ratings {
      average
      averageFormatted
      total
    }
    curator {
      ...ProducerFeedFragment
    }
    userData @include(if: $withUserData) {
      bundleLocked: locked
      completedSessions
      completedPercent
      bundleFavourite: isFavourite
    }
    cardImage {
      url
    }
    coverImage {
      url
    }
    cellImage {
      url
    }
    posterImage {
      url
    }
  }
  ${ProducerFeedFragment}
`;

export const SingleFeedFragment = gql`
  fragment SingleFeedFragment on Single {
    id
    type
    title
    label
    description
    webSlug
    free
    deepLink
    isNew
    ratings {
      average
      total
    }
    releaseDate
    singleStatus: status
    cellImage {
      url
    }
    coverImage {
      url
    }
    contributors {
      ...ProducerContributionFeedFragment
    }
    userData @include(if: $withUserData) {
      lastPlayedPercent
      defaultVariant
      isFavourite
      locked
    }
  }
  ${ProducerContributionFeedFragment}
`;

/**
 * Used to render a list of collections in the browse page.
 */
export const CollectionFeedFragment = gql`
  fragment CollectionFeedFragment on TagCollection {
    title
    subtitle
    description
    webSlug
    id
    layout
    playerLayout
    moreButton {
      title
      link
    }
    results(page: { take: $resultsTake, skip: 0 }) {
      items {
        ... on Single {
          ...SingleFeedFragment
        }
        ... on QuickLink {
          ...QuickLinkFeedFragment
        }
        ... on Bundle {
          ...BundleFeedFragment
        }
        ... on Producer {
          ...ProducerFeedFragment
        }
        ... on Feature {
          ...FeatureFeedFragment
        }
        ... on Topic {
          ...TopicFeedFragment
        }
        ... on Poster {
          ...PosterFeedFragment
        }
      }
    }
  }
  ${SingleFeedFragment}
  ${BundleFeedFragment}
  ${FeatureFeedFragment}
  ${TopicFeedFragment}
  ${ProducerFeedFragment}
  ${QuickLinkFeedFragment}
  ${PosterFeedFragment}
`;
