import * as React from 'react';
import {Gap} from '../../layout';
import {AppleLogin, FacebookLogin, GoogleLogin} from '../../ui';

export const InlineLoginButtons: React.FC<{
  color?: 'default' | 'white';
  useAction?: boolean;
}> = ({color = 'default'}) => {
  const setLoading = () => {};
  return (
    <Gap space={8}>
      <GoogleLogin size="sm" color={color} setLoading={setLoading} />
      <FacebookLogin size="sm" color={color} setLoading={setLoading} />
      <AppleLogin size="sm" color={color} setLoading={setLoading} />
    </Gap>
  );
};
