import * as React from 'react';
import {isEmpty} from 'lodash';

import {EnrichedHeroPlanPicker, SectionIndex} from '../../../types/types';
import {usePlanPicker} from '../../../hooks';
import {Alert, Button} from '../../forms';
import {
  Container,
  Row,
  Column,
  Section,
  Flex,
  Box,
  Stack,
} from '../../layout';
import {Small, Text, Title1} from '../../typography';
import {PlanCard, LoadingSpinner} from '../../ui';
import {Context, ResponsiveBackgroundImage} from '../../global';
import {has} from '../../../utils/array';
import {HydratedPurchase, usePurchases} from '../../../hooks/usePurchases';
import {useAddToCart} from '../../../hooks/useAddToCart';
import {PurchaseCard} from '../../ui/PurchaseCard';

export const HeroPlanPicker: React.FC<Props> = ({
  _type,
  title,
  paragraph,
  enrichedPlans,
  index,
  coupon,
  thankYouPage,
  thankYouLink,
  type,
  image,
  tagline,
  bundle,
  background = 'greyWarm',
  trialAndDiscount,
  paddingTop,
  paddingBottom,
  purchases,
  buttonText,
}) => {
  const {data} = React.useContext(Context);
  const [selectedPurchase, setSelectedPurchase] =
    React.useState<HydratedPurchase>();
  const {hydratedPurchases, loading: loadingPurchases} = usePurchases(
      purchases || [],
      coupon,
  );
  const {addToCart, loading: loadingAddToCart, error} = useAddToCart();

  const {
    loading,
    loadedPlans,
    sortedPlans,
    selectedPlan,
    setSelectedPlan,
    hasPlans,
    selectPlan,
  } = usePlanPicker({
    trialDays: 0,
    coupon,
    thankYouPage,
    thankYouLink,
    enrichedPlans,
    type,
    bundle,
    trialAndDiscount,
  });

  React.useEffect(() => {
    if (!hydratedPurchases.length) return;
    setSelectedPurchase(hydratedPurchases[0]);
  }, [hydratedPurchases.length]);

  return (
    <Section
      id={`${_type}-${index}`}
      sectionPadding={{
        paddingTop: paddingTop || 80,
        paddingBottom: paddingBottom || 160,
      }}
      position="relative"
      background={background}
    >
      <Container zIndex={1} position="relative">
        <Row>
          <Column
            span={{
              xs: 24,
              md: 20,
              lg: 12,
              xl: 9,
            }}
          >
            <Row>
              <Column spacing={64}>
                {tagline ? (
                  <Small
                    colour={background === 'grey9' ? 'white' : 'primary'}
                    fontWeight="bold"
                    transform="uppercase"
                    spacing={10}
                  >
                    {tagline}
                  </Small>
                ) : null}
                <Title1 as={index === 0 ? 'h1' : 'h2'} spacing={10}>
                  {title || 'Choose the plan that works best for you'}
                </Title1>
                <Text colour={background === 'grey9' ? 'white' : 'grey8'}>
                  {paragraph ||
                    'Your Plus+ subscription includes a 1-1 donation to someone in need of mindfulness.'}
                </Text>
              </Column>
              <Column spacing={16}>
                {loading ? (
                  <Flex justify="center">
                    <LoadingSpinner />
                  </Flex>
                ) : purchases ? (
                  <Column spacing={56}>
                    <Stack space={10}>
                      {loadingPurchases ? (
                        <Flex justify="center">
                          <LoadingSpinner />
                        </Flex>
                      ) : (
                        hydratedPurchases.map((purchase, index) => (
                          <Box key={purchase.id} width="120px">
                            <PurchaseCard
                              onChange={(p) => setSelectedPurchase(p)}
                              selected={purchase.id === selectedPurchase?.id}
                              {...purchase}
                            />
                          </Box>
                        ))
                      )}
                    </Stack>
                  </Column>
                ) : isEmpty(loadedPlans) ? (
                  <Alert error={'No matching plans found'} />
                ) : (
                  <Stack space={10}>
                    {sortedPlans.map((plan, index) => (
                      <Box key={plan.id} width="120px">
                        <PlanCard
                          {...plan}
                          trial={false}
                          selectedPlan={selectedPlan}
                          hasPayIn3={has(sortedPlans, (p) => p.payIn3)}
                          onChange={(newPlan) => {
                            setSelectedPlan(newPlan);
                          }}
                          index={index}
                          trialAndDiscount={trialAndDiscount}
                        />
                      </Box>
                    ))}
                  </Stack>
                )}
              </Column>

              {purchases ? (
                <Box width="100%">
                  <Alert error={error} />
                  <Button
                    id="AddToCartHeroPlanPicker"
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (!selectedPurchase) return;
                      const action = data?.action;
                      const onto = action?.thankYouLink || action?.thankYouPage?.current;
                      await addToCart(selectedPurchase, {
                        payInstalments: selectedPurchase.purchase.payIn3,
                        coupon,
                        metadata: {
                          ...(data?.action?.cohort ? {cohort: data.action.cohort} : {}),
                        },
                      }, undefined, undefined, undefined, onto);
                    }}
                    loading={loadingAddToCart}
                    wide
                  >
                    {`${buttonText ?? 'Add to Cart'}`}
                  </Button>
                </Box>
              ) : hasPlans ? (
                <Column span={8 * sortedPlans.length}>
                  <Button id="SelectHeroPlan" onClick={selectPlan} wide>
                    Add to Cart
                  </Button>
                </Column>
              ) : null}
            </Row>
          </Column>
        </Row>
      </Container>
      {image ? <ResponsiveBackgroundImage zIndex={0} image={image} /> : null}
    </Section>
  );
};

type Props = SectionIndex<EnrichedHeroPlanPicker>;
