import React from 'react';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {Box} from '../../layout';
import {OnClient} from '../../ui';
import {Title2, Text} from '../../typography';
import {Maybe} from '@mindfulness/utils/maybe';
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
const dayMap = ['days', 'hours', 'minutes', 'seconds'];

export const Countdown: React.FC<Props> = ({datetime}) => {
  return (
    <OnClient>
      <Timer datetime={datetime} />
    </OnClient>
  );
};

const Timer: React.FC<Props> = ({datetime}) => {
  const [countdownString, setCountdownString] = React.useState<string>();

  const countdown = React.useMemo(
      () => countdownString?.split(':'),
      [countdownString],
  );

  React.useEffect(() => {
    setTimeout(() => {
      const now = dayjs.utc().local();
      const then = dayjs.utc(datetime).local();
      const duration = dayjs.duration(then.diff(now));
      const text = duration.format('DD:HH:mm:ss');
      setCountdownString(text);
    }, 1000);
  }, [countdownString, datetime]);

  return (
    <>
      {countdown?.map((t, idx) => (
        <React.Fragment key={dayMap[idx]}>
          <Box>
            <Title2>{t}</Title2>
            <Text fontSize="xs">{dayMap[idx]}</Text>
          </Box>
          {idx < countdown.length - 1 ? <Title2>:</Title2> : null}
        </React.Fragment>
      ))}
    </>
  );
};

type Props = {
  datetime: Maybe<string>;
};
