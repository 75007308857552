import styled from '@emotion/styled';

export const InlineForm = styled.div(
    ({theme}) => `
  display: flex;
  margin-bottom: ${theme.spacings[10]};
  box-shadow: ${theme.shadows.medium};
  border-radius: 100px;
  button {
    transition: box-shadow 0.3s ease;
    box-shadow: none;
  }
  button:hover {
    opacity: 1;
    box-shadow: ${theme.shadows.medium};
  }
`,
);

export const Input = styled.input<{ wide?: boolean }>(
    ({theme, wide}) => `
  border: 1px solid ${theme.colors.grey4};
  outline: none;
  border-radius: 100px;
  padding-left: ${theme.spacings[30]};

  ${
    wide ?
      '' :
      `
       padding-right: ${theme.spacings[56]};
       margin-right: -${theme.spacings[56]};
     `
}

  width: 100%;
  max-width: 100%;
  color: ${theme.colors.grey9};
  height: 3.125rem;
`,
);
