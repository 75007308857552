import {useCallback, useContext} from 'react';
import {ApolloError} from '@apollo/client';
import {omitEmpty} from '@mindfulness/utils/object';

import {login} from '../../../api';
import {Maybe} from '../../../types/types';
import {cleanVerifyUrlParams, trackNewOrExistingUsers} from '../../../utils';

import {useAddCohort} from './useAddCohort';
import {useUpdatePreferences} from './useUpdatePreferences';
import {useLoginFailure} from './useLoginFailure';
import {AuthProps} from '.';
import {ModalContext} from '../ModalProvider';
import {useAnalytics} from '../SegmentProvider';

export const usePasswordlessLogin = ({
  handleLogin,
  pageData,
  handleAction,
}: AuthProps) => {
  const handleAddCohort = useAddCohort();
  const handleUpdatePreferences = useUpdatePreferences();
  const handleLoginFailure = useLoginFailure();
  const {closeModal} = useContext(ModalContext);
  const {track, identify} = useAnalytics();

  const handlePasswordlessLogin = useCallback(
      async ({
        email,
        onVerify,
        requireVerify = true,
        redirect,
        modalName = 'action',
        verifyLink,
        onToPage,
      }: LoginParams) => {
        try {
          const action = pageData?.action;
          const thankYou = action?.thankYouLink || action?.thankYouPage?.current;
          const loginWebLinkPath = cleanVerifyUrlParams(verifyLink);

          const {data} = await login(
              {
                passwordless: {
                  email,
                  loginWebLinkPath,
                },
              },
              omitEmpty({
                thankYou: onToPage ?? thankYou,
              }),
          );

          if (!data) {
            throw new Error('Couldn\'t login with Passwordless');
          }
          await trackNewOrExistingUsers(data.login, {
            track,
            identify,
          });
          await handleLogin(data?.login);
          await handleAddCohort({cohort: pageData?.action?.cohort});
          // They may be logging in via a challenge so we add any relevant cohorts once logged in
          await handleUpdatePreferences();
          if (redirect) {
            redirect({email: data?.login?.user?.email || undefined});
            return;
          }
          if (requireVerify) {
            await handleAction(data?.login?.user?.email || undefined);
            closeModal?.({
              name: modalName,
            });
            return;
          }
        } catch (e) {
          if (e instanceof ApolloError) {
            if (e.message === 'Token sent to email.') {
              await handleLoginFailure(e, {
                email,
                requireVerify,
                onVerify,
                redirect,
                modalName,
              });
              return;
            }
            throw e;
          }
        }
      },
      [
        handleLogin,
        handleAction,
        handleAddCohort,
        pageData,
        handleUpdatePreferences,
        handleLoginFailure,
        track, identify,
      ],
  );

  return handlePasswordlessLogin;
};

export type LoginParams = {
  requireVerify?: boolean;
  redirect?: Maybe<(params: { email?: string; name?: string }) => void>;
  onVerify?: (params: { email?: string; name?: string }) => void;
  email: string;
  modalName?: string;
  verifyLink?: string;
  onToPage?: string;
};
