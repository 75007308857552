import styled from '@emotion/styled';

export const LoadingBoxOverlay = styled.div<{open: boolean;}>(({open}) => {
  return `
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    transition: opacity 0.3s;
    ${open ? `
      opacity: 1;
    ` : `
      opacity: 0;
      pointer-events: none;
    `}

  `;
});
