import {gql} from '@apollo/client';

export const CREATE_SHARE = gql`
mutation createShare($type: ShareType!, $content: EntityReference) {
  createShare(type: $type, content: $content) {
    url
    sharerId
    sharerName
  }
}
`;
