import {FAVOURITE_SINGLE} from '../mutations/favouriteSingle';
import {FavouriteSingleMutation, FavouriteSingleMutationVariables} from '../../types/api';
import {getGraphClient} from '../../utils/graph';


export const favouriteSingle = async (variables: FavouriteSingleMutationVariables) => {
  const {data} = await getGraphClient().mutate<FavouriteSingleMutation>({
    mutation: FAVOURITE_SINGLE,
    variables,
  });
  return data?.favouriteSingle;
};
