import {ButtonHTMLAttributes, PropsWithChildren} from 'react';
import style from './CancelButton.module.css';

export const CancelButton: React.FC<PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>>> = ({
  children,
  ...props
}) => (
  <button className={[style.cancelButton].join(' ')} {...props}>
    {children}
  </button>
);
