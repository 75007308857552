import {
  useEffect,
  useMemo,
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
} from 'react';
import {FetchResult} from '@apollo/client';
import {Purchase, SanityKeyed} from '@mindfulness/cms';
import {SanityClient} from '@sanity/client';
import {omitEmpty} from '@mindfulness/utils/object';
import {Maybe, when} from '@mindfulness/utils/maybe';
import {Text} from '../../typography';

import {ModalContext} from '../ModalProvider';

import {
  CheckoutType,
  EnrichedPages,
  PageData,
  EnrichedArticles,
  ReferencedSiteSettings,
  SingleDetailFragment,
  SessionFragment,
  User,
} from '../../../types/types';
import {
  StoredSession,
  isOnClient,
  onClient,
  getPageCheckoutType,
  cleanEmail,
  AuthedSession,
} from '../../../utils';
import {useOpenModal, useToast, useUntil} from '../../../hooks';

import {defaultOnboardingSteps} from '../../templates/Onboarding/Onboarding.effects';
import {HandleAction, useActions} from './useActions';
import {HandleLogin, useSession} from './useSession';
import {LoginParams, usePasswordlessLogin} from './usePasswordlessLogin';
import {SignupParams, usePasswordlessSignup} from './usePasswordlessSignup';
import {useVerify} from './useVerify';
import {useAppleLogin} from './useAppleLogin';
import {
  HandleCheckout,
  HandleCheckoutGiftcard,
  useCheckout,
} from './useCheckout';
import {useGoogleLogin} from './useGoogleLogin';
import {useAddEmail} from './useAddEmail';
import {useFacebookLogin} from './useFacebookLogin';
import {BundleDetailFragment, Plan} from '../../../types/api';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {getBundle} from '../../../api';
import {useAddToCart} from '../../../hooks/useAddToCart';
import {HydratedPurchase, usePurchases} from '../../../hooks/usePurchases';
import {useSingleLocked} from '../../../hooks/useSingleLocked';
import {useNewNavigation} from '../../../hooks/useNewNavigation';

export const Context = createContext<ContextState>({
  site: undefined,
  client: undefined,
  data: undefined,
  handleAppleLogin: undefined,
  handleFacebookLogin: undefined,
  handleGoogleLogin: undefined,
  userEmail: undefined,
  handlePageAction: undefined,
  handleGoToCheckout: undefined,
  bundle: undefined,
  session: undefined,
  initialSession: undefined,
  setSession: undefined,
  handleLogin: undefined,
  handleLogout: undefined,
  handleAddEmail: undefined,
  handlePasswordlessSignup: undefined,
  handlePasswordlessLogin: undefined,
  handleVerify: undefined,
  handleAction: undefined,
  loadingPageAction: false,
  getCheckoutMethod: undefined,
  plan: undefined,
  hydratedPurchases: [],
  cancelLoadingPageAction: undefined,
  refreshSession: undefined,
});

export const Provider: FC<Props> = ({
  children,
  single,
  page,
  article,
  abortAction,
  disableAnnouncement,
  ...props
}) => {
  const {goTo, getUtmParams, goToHash} = useNewNavigation();
  const [bundle, setBundle] = useState<BundleDetailFragment>();
  const {openModal} = useContext(ModalContext);
  const [loadingPageAction, setLoadingPageAction] = useState(false);
  const {handleAdd} = useToast();
  useOpenModal(single ? 'single' : 'action', {
    single,
  });
  const {addToCart} = useAddToCart();
  const {hydratedPurchases} = usePurchases(
    [page?.action?.purchase].filter(Boolean) as Array<SanityKeyed<Purchase>>,
    page?.action?.coupon,
  );

  const [emailParam] = useQueryParam('email');
  const [sharerNameParam] = useQueryParam('sharer_name');
  const [sharerIdParam] = useQueryParam('sharer_id');
  const [linkId] = useQueryParam('link_id');
  const email_ = when(emailParam, (e) => cleanEmail(e));
  const [couponParam] = useQueryParam('coupon');
  const [cohortParam] = useQueryParam('cohort');
  const handleLockedSingle = useSingleLocked(single);
  const pageData = useUntil([page, article]);
  const {session, initialSession, setSession, handleLogin, handleLogout, refreshSession} =
    useSession();
  const plan = useMemo(() => {
    const sub = session?.user?.subscriptions?.find(({plan}) => plan.planCode);
    if (sub) {
      return sub.plan.planCode;
    }
  }, [session]);
  const handleAction = useActions(pageData, abortAction, refreshSession);
  const apiBundle = useMemo(() => {
    if (pageData && 'apiBundle' in pageData) {
      return pageData?.apiBundle;
    }
  }, [pageData]);

  useEffect(() => {
    setBundle(apiBundle);
    if (apiBundle && session) {
      (async () => {
        const userBundle = await getBundle(
            {
              id: apiBundle.id,
              withUserData: true,
              resultsTake: 10,
            },
            {
              fetchPolicy: 'network-only',
            },
        );
        setBundle(userBundle);
      })();
    }
  }, [apiBundle?.id, session]);

  useEffect(() => {
    (async () => {
      const toast = <Text fontWeight="bold">{`✅ ${sharerNameParam || 'This was'} shared this with you`}</Text>;
      if (linkId && sharerIdParam) {
        if (!single) {
          handleAdd?.(toast);
          return;
        }
      }
    })();
  }, [sharerNameParam, sharerIdParam, single, linkId]);


  const handlePasswordlessLogin = usePasswordlessLogin({
    pageData,
    handleLogin,
    handleAction,
  });

  const handlePasswordlessSignup = usePasswordlessSignup({
    pageData,
    handleLogin,
    handleAction,
  });

  const handleAppleLogin = useAppleLogin({
    pageData,
    handleLogin,
    handleAction,
  });

  const handleFacebookLogin = useFacebookLogin({
    pageData,
    handleLogin,
    handleAction,
  });

  const handleGoogleLogin = useGoogleLogin({
    pageData,
    handleLogin,
    handleAction,
  });

  const handleVerify = useVerify({
    pageData,
    handleLogin,
    handleAction,
  });

  const handleAddEmail = useAddEmail({
    session,
    handleAction,
  });

  const cancelLoadingPageAction = useCallback(() => {
    setLoadingPageAction(false);
  }, [setLoadingPageAction]);

  const handleGoToCheckout = useCallback(
      (pickerParams: PickerParams) => {
        const redirect = (emailAddress?: string) => {
        // Next router may not be ready
          const browserSearch = isOnClient() ?
          new URLSearchParams(window.location.search) :
          undefined;

          const email = emailAddress || email_ || browserSearch?.get('email');
          const action = pageData?.action;
          const onto = action?.thankYouLink || action?.thankYouPage?.current;
          goTo({
            pathname: '/subscribe',
            query: omitEmpty({
              ...(pageData?.action?.coupon ?
              {coupon: pageData.action.coupon} :
              {}),
              ...(onto ?
              {onto} :
              {}),
              ...pickerParams,
              ...(email ? {email} : {}),
              ...getUtmParams(),
            }),
          });
        };

        if (pickerParams.type === 'event' && !session && !email_) {
          openModal?.({
            name: 'action',
            props: {
              requireVerify: false,
              redirect,
              onVerify: ({email}: { email: string }) => {
                redirect(email);
              },
            },
          });
          return;
        }

        redirect();
      },
      [
        goTo,
        pageData?.action?.thankYouPage?.current,
        pageData?.action?.thankYouLink,
        pageData?.action?.coupon,
        session,
        email_,
      ],
  );

  const handlePageAction = useCallback(() => {
    if (abortAction) {
      return;
    }

    setLoadingPageAction(true);
    const action = pageData?.action || undefined;

    if (!!single) {
      handleLockedSingle();
      setLoadingPageAction(false);
    } else if (
      action?.action === undefined ||
      [
        'joinABundle',
        'joinACourse',
        'addToCohort',
        'downloadApp',
        'joinAChallenge',
        'redeemAccesspass',
      ].includes(action?.action)
    ) {
      const cohort = action?.cohort;
      openModal?.({
        name: 'action',
        props: {
          verifyLink: onClient(() => action?.action === 'addToCohort' && cohort ? `/addToCohort/${cohort}` : undefined),
        },
      });
      setLoadingPageAction(false);
    } else if (action?.action === 'onboarding') {
      goTo({
        pathname: `/get-started/${defaultOnboardingSteps[0]}`,
      });
    } else if (
      [
        'purchaseNewSubscription',
        'purchaseSubscription',
        'purchaseBogo',
        'purchaseGiftCard',
        'purchaseEvent',
        'purchase',
      ].includes(action?.action)
    ) {
      onClient(async () => {
        const firstPlanPicker = document.querySelector(
            `[id*='PlanPicker'], [id*='HeroPlanPicker'], [id*='PurchasePicker'], [id*='HeroBundle']`,
        );

        if (firstPlanPicker) {
          firstPlanPicker.classList.add('highlight');
          firstPlanPicker.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          });
          setTimeout(() => {
            firstPlanPicker?.classList.remove('highlight');
            setLoadingPageAction(false);
          }, 400);

          return;
        }
        if (action.action === 'purchase' && hydratedPurchases.length) {
          const onto = action?.thankYouLink || action?.thankYouPage?.current;
          await addToCart(
              hydratedPurchases[0],
              {
                coupon: action.coupon,
                metadata: {
                  ...(action?.cohort ? {cohort: action.cohort} : {}),
                },
              },
              session,
              undefined,
              cancelLoadingPageAction,
              onto,
          );
          return;
        }
        goTo({
          pathname: '/subscribe',
          query: omitEmpty({
            coupon: couponParam || action?.coupon,
            cohorts: action?.cohort ?? cohortParam,
            onto: action?.thankYouLink || action?.thankYouPage?.current || '/giftcard-purchased',
            type: getPageCheckoutType(action.action),
            email: email_,
            plan: action.plans?.[0]?.webAlias || undefined,
            trial: `${action.trialDays && action.trialDays > 0}`,
            // Passthrough utm values
            ...getUtmParams(),
          }),
        });
      });
    } else if (['redeemGuestpass'].includes(action?.action)) {
      if (session) {
        goTo({
          pathname: '/subscribe',
          query: omitEmpty({
            coupon: couponParam,
            cohorts: action?.cohort ?? cohortParam,
            onto: action?.thankYouLink || action?.thankYouPage?.current || '/thank-you/guestpass',
            trial: 'true',
            // Passthrough utm values
            ...getUtmParams(),
          }),
        });
        return;
      }
      openModal?.({
        name: 'action',
      });
      setLoadingPageAction(false);
    } else if (['locked'].includes(action?.action)) {
      if (
        action?.lockedToCohort &&
        !session?.user?.cohorts.includes(action?.lockedToCohort)
      ) {
        openModal?.({
          name: 'action',
          props: {
            unlockUrl: action?.unlockUrl,
          },
        });
      } else {
        onClient(() => {
          const firstVideo = document.querySelector(`[id*='Video'], `);
          if (firstVideo) {
            firstVideo.classList.add('highlight');
            goToHash(firstVideo.id);
            setTimeout(() => {
              firstVideo?.classList.remove('highlight');
              setLoadingPageAction(false);
            }, 400);
          }
        });
      }
      setLoadingPageAction(false);
    }
  }, [
    pageData,
    single,
    goTo,
    openModal,
    handleGoToCheckout,
    session,
    couponParam,
    hydratedPurchases,
    cancelLoadingPageAction,
    handleLockedSingle,
  ]);

  const getCheckoutMethod = useCheckout({session});

  return (
    <Context.Provider
      value={{
        handlePageAction,
        handleAppleLogin,
        handleFacebookLogin,
        handleGoogleLogin,
        handlePasswordlessSignup,
        handlePasswordlessLogin,
        handleLogin,
        handleAddEmail,
        handleLogout,
        handleVerify,
        handleAction,
        handleGoToCheckout,
        cancelLoadingPageAction,
        userEmail: session?.email ?? email_,
        bundle,
        session,
        initialSession,
        refreshSession,
        setSession,
        getCheckoutMethod,
        data: pageData,
        client: props.client,
        site: props.site,
        loadingPageAction,
        disableAnnouncement,
        plan: plan || undefined,
        hydratedPurchases,
      }}
    >
      {children}
    </Context.Provider>
  );
};

type Props = React.PropsWithChildren<{
  client?: SanityClient;
  article?: EnrichedArticles;
  page?: EnrichedPages;
  userEmail?: string;
  single?: SingleDetailFragment;
  site?: ReferencedSiteSettings;
  abortAction?: boolean;
  disableAnnouncement?: boolean;
}>;

export type ContextState = {
  site: Maybe<ReferencedSiteSettings>;
  plan: Plan['planCode'];
  client: Maybe<SanityClient>;
  data: PageData;
  userEmail?: string;
  bundle: Maybe<BundleDetailFragment>;
  getCheckoutMethod: Maybe<
    (type: CheckoutType) => HandleCheckoutGiftcard | HandleCheckout
  >;
  handlePageAction: Maybe<() => void>;
  handleAppleLogin: Maybe<
    (
      useAction: boolean
    ) => Promise<{ name: Maybe<string>; email: Maybe<string> }>
  >;
  handleFacebookLogin: Maybe<
    (useAction: boolean) => Promise<
      | {
          name: Maybe<string>;
          email: Maybe<string>;
        }
      | undefined
    >
  >;
  handleGoogleLogin: Maybe<
    (
      token: string,
      useAction: boolean
    ) => Promise<{
      email: string | null | undefined;
      name: string;
    }>
  >;
  handleVerify: Maybe<
    (
      params: { email: string; token: string },
      redirect?: (params: { email?: string; name?: string }) => void
    ) => void
  >;
  handleAction: Maybe<(email: Maybe<string>) => void>;
  handlePasswordlessLogin: Maybe<(params: LoginParams) => Promise<void>>;
  handlePasswordlessSignup: Maybe<
    ({
      email,
      name,
      cohort,
      requireVerify,
      redirect,
    }: SignupParams) => Promise<void>
  >;
  handleAddEmail: Maybe<
    (
      email: string,
      useAction: boolean
    ) => Promise<
      Maybe<
        FetchResult<
          {
            updateUser: User;
          },
          Record<string, any>,
          Record<string, any>
        >
      >
    >
  >;
  handleLogout: Maybe<() => void>;
  handleLogin: Maybe<(session: SessionFragment) => void>;
  refreshSession: Maybe<() => Promise<AuthedSession>>;
  handleGoToCheckout: Maybe<(type: PickerParams) => void>;
  session: Maybe<StoredSession> | null;
  initialSession: Maybe<StoredSession> | null;
  setSession: Maybe<
    React.Dispatch<React.SetStateAction<Maybe<StoredSession | null>>>
  >;
  loadingPageAction: boolean;
  abortAction?: boolean;
  disableAnnouncement?: boolean;
  hydratedPurchases: Maybe<HydratedPurchase[]>;
  cancelLoadingPageAction: Maybe<() => void>;
};

export type PickerParams = {
  type?: CheckoutType;
  coupon?: Maybe<string>;
  trial?: Maybe<string>;
  onto?: Maybe<string>;
  plan?: string | undefined;
};

export type AuthProps = {
  handleLogin: HandleLogin;
  handleAction: HandleAction;
  pageData: PageData;
};
