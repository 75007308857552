import styled from '@emotion/styled';
import {Stack} from '../../layout';

export const Glow = styled.div<{ selected: boolean }>(
    ({theme, selected}) => `
  height: 100%;
  width: 100%;
  box-shadow: ${theme.shadows[selected ? 'glow' : 'big']};
  @media (min-width: ${theme.breakpoints.md.min}) {
    box-shadow: ${theme.shadows[selected ? 'glow' : 'medium']};
  }
  transition: box-shadow 0.2s ease-in-out;
  border-radius: ${theme.radius.lg};
`,
);

export const Outer = styled.button<{ selected: boolean }>(
    ({theme, selected}) => `
padding: ${theme.spacings[4]};
border-radius: ${theme.radius.lg};
background-image: ${theme.gradients.primary};
position: relative;
width: 100%;
height: 100%;

&:after {
  content: '';
  position: absolute;
  ${
    selected ?
      `
      top: 4px;
      left: 4px;
      right: 4px;
      bottom: 4px;

  ` :
      `
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
  `
}
  transition-property: top, left, right, bottom;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  background-color: ${theme.colors.greyWarm};
  z-index: 0;
  border-radius: ${theme.radius.lg};
  @media (min-width: ${theme.breakpoints.md.min}) {
    background-color: ${theme.colors.white};
  }
}
`,
);

export const Inner = styled(Stack)(
    ({theme}) => `
background-color: ${theme.colors.greyWarm};
@media (min-width: ${theme.breakpoints.md.min}) {
  background-color: ${theme.colors.white};
}
border-radius: 12px;
padding: 15px;
position: relative;
z-index: 1;
user-select: none;
`,
);

export const BestValueBadge = styled.div(
    ({theme}) => `
background-color: ${theme.colors.highlight};
color: ${theme.colors.white};
text-transform: uppercase;
padding: 6px;
font-size: 9px;
font-weight: ${theme.fontWeights.bold};
position: absolute;
top: 0;
left: 50%;
transform: translateX(-50%) translateY(-50%);
border-radius: 3px;
white-space: nowrap;
z-index: 2;
`,
);
