import {forEach} from 'lodash';
import {usePathname} from 'next/navigation';
import * as React from 'react';
import {KeyOf, Maybe} from '../../../types/types';

const defaultProps = {
  modals: [],
  activeProps: {},
  isOpen: (_name: string) => false,
  getActiveProps: (_name: string) => ({}),
  openModal: undefined,
  closeModal: undefined,
  toggleModal: undefined,
};

export const ModalContext = React.createContext<ContextState>(defaultProps);

export const ModalProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const pathname = usePathname();
  const [onCloseFuncs, setOnCloseFuncs] = React.useState<
    Record<string, MaybeFunc>
  >({});
  const [modals, setModals] = React.useState<Array<string>>(
      defaultProps.modals,
  );
  const [activeProps, setActiveProps] = React.useState<Record<string, any>>(
      defaultProps.activeProps,
  );

  const isOpen = React.useCallback(
      (name: string) => modals.includes(name),
      [modals],
  );

  const getActiveProps = React.useCallback(
      (name: string) => activeProps[name],
      [activeProps],
  );

  const closeModal = ({name}: NamedParams) => {
    onCloseFuncs[name]?.();
    setModals((prev) => prev.filter((modal) => modal !== name));
    setActiveProps((prev) => ({...prev, [name]: undefined}));
    setOnCloseFuncs((prev) => {
      prev[name]?.();
      return {...prev, [name]: undefined};
    });
  };

  const openModal = ({name, props, onClose}: NamedParams) => {
    setModals((prev) => [...prev, name]);
    setActiveProps((prev) => ({...prev, [name]: props}));
    setOnCloseFuncs((prev) => ({...prev, [name]: onClose}));
  };

  const toggleModal = ({name, props}: NamedParams) => {
    if (isOpen(name)) {
      closeModal?.({name, props});
    } else {
      openModal({name, props});
    }
  };


  const closeAll = () => {
    forEach(modals, (name) => closeModal?.({name}));
  };


  React.useEffect(() => {
    closeAll();

    return () => {
      document.body.style.overflow = 'unset';
      closeAll();
    };
  }, [pathname]);

  // Lock scrolling when a modal is open
  React.useEffect(() => {
    if (modals.length) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modals]);

  return (
    <ModalContext.Provider
      value={{
        modals,
        activeProps,
        getActiveProps,
        openModal,
        closeModal,
        toggleModal,
        isOpen,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

type NamedParams = {
  name: string;
} & ModalParams;

export type ModalParams = {
  props?: Record<KeyOf, any>;
  onClose?: () => void;
};

type ContextState = {
  modals: Array<string>;
  activeProps: Record<string, any>;
  isOpen: (name: string) => boolean;
  getActiveProps: (name: string) => Record<string, any>;
  openModal: Maybe<(params: NamedParams) => void>;
  closeModal: Maybe<(params: NamedParams) => void>;
  toggleModal: Maybe<(params: NamedParams) => void>;
};

type MaybeFunc = Maybe<() => void>;
