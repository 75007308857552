import {useState, useEffect, useCallback} from 'react';
import {Maybe} from '@mindfulness/utils/maybe';
import {throttle} from 'lodash';
import {onClient} from '../utils';

export const useWindowWidth = () => {
  const [width, setWidth] = useState<Maybe<number>>(
      onClient(() => window.innerWidth),
  );

  const checkDevice = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  const throttledListener = throttle(checkDevice, 200);

  // called on first render
  useEffect(checkDevice, []);

  // listens to window resize
  useEffect(() => {
    window.addEventListener('resize', throttledListener);
    return () => window.removeEventListener('resize', throttledListener);
  }, []);

  return width;
};
