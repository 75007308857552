import * as React from 'react';
import {isMobile} from 'react-device-detect';
import {HeroThankYou as HeroThankYouProps} from '@mindfulness/cms';

import {H1, H4, Text} from '../../typography';
import {Column, Container, Row} from '../../layout';
import {BasicImage, ButtonLink, Context, TextWrapper} from '../../global';
import {DownloadLinks, QRCodeSection} from '../../ui';
import {useNavigation, useQRCode} from '../../../hooks';
import {downloadLink} from '../../../utils';
import {SectionIndex} from '../../../types/types';

import {ImageWrapper, Section} from './HeroThankYou.styles';

export const HeroThankYou: React.FC<SectionIndex<HeroThankYouProps>> = ({
  title,
  tagline,
  paragraph,
  image,
  index,
}) => {
  const {qrCodeLink, sourceLink} = useQRCode();
  const data = React.useContext(Context);
  const {getQueryParam} = useNavigation();
  React.useEffect(() => {
    const addToCohort = getQueryParam('addToCohort');
    if (isMobile && addToCohort === 'true') {
      window.location.href = downloadLink();
    }
  }, [data, getQueryParam]);
  return (
    <Section background="white" position="center">
      <Container>
        <Row justify="center" items="start">
          <Column
            span={{
              xs: 24,
              md: 10,
            }}
          >
            <TextWrapper background="white">
              <Row spacing={14}>
                <Column span={24}>
                  {tagline ? <Text colour="primary">{tagline}</Text> : null}
                  {title ? (
                    <H1
                      as={index === 0 ? undefined : 'h2'}
                      size="3xl"
                      spacing={16}
                    >
                      {title}
                    </H1>
                  ) : null}
                  {paragraph ? (
                    <H4
                      colour="grey7"
                      spacing={{
                        xs: 20,
                        md: 40,
                      }}
                    >
                      {paragraph}
                    </H4>
                  ) : null}
                </Column>
              </Row>
              {isMobile ? (
                <Row>
                  <Column>
                    {sourceLink ? (
                      <ButtonLink
                        href={sourceLink}
                        id={`HeroThankYou-${index}`}
                      >
                        Download and Login
                      </ButtonLink>
                    ) : null}
                  </Column>
                </Row>
              ) : (
                <>
                  <QRCodeSection qrCodeLink={qrCodeLink} />
                </>
              )}
              <Row
                topSpacing={{
                  xs: 16,
                  md: 34,
                }}
              >
                <DownloadLinks />
              </Row>
            </TextWrapper>
          </Column>
          <Column
            span={{
              xs: 0,
              md: 1,
            }}
          />
          <Column
            span={{
              xs: 24,
              md: 10,
            }}
          >
            {image ? (
              <ImageWrapper position="center">
                <BasicImage
                  image={image}
                  layout="responsive"
                  background="white"
                />
              </ImageWrapper>
            ) : null}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};
