import {useState, useEffect} from 'react';
import {throttle} from 'lodash';

/**
 * React hook that returns the current scroll position and is throttled to 200 by default.
 * @param {number} throttleInterval - The throttle interval in milliseconds. Defaults to 200
 * @return {Object} - The current scroll position.
 */
export function useScroll(throttleInterval: number = 200) {
  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const listener = () => {
    const offset = document.body.getBoundingClientRect();
    if (offset) {
      setScrollY(-offset.top);
      setScrollX(offset.left);
    }
  };

  const throttledListener = throttle(listener, throttleInterval);

  useEffect(() => {
    window.addEventListener('scroll', throttledListener);
    return () => {
      window.removeEventListener('scroll', throttledListener);
    };
  }, []);

  return {
    scrollY,
    scrollX,
  };
}
