import * as React from 'react';
import NextLink, {LinkProps} from 'next/link';
import styled from '@emotion/styled';
import {Maybe} from '@mindfulness/utils/maybe';

/**
 * Sometimes it's hard to track down where Links are provided more that one
 * child. This component is a wrapper around NextLink that provides a more clear
 * error, but also prevents the build from breaking if there is more than
 * one child
 * @param {LinkProps} props - props to pass to NextLink
 * @return {React.FC}
 */
export const Link: React.FC<
  React.PropsWithChildren<
    Omit<LinkProps, 'href'> & {
      href: Maybe<LinkProps['href']>;
    }
  >
> = (props) => {
  if (React.Children.count(props.children) !== 1) {
    console.error('Link component should have exactly one child');
    return null;
  }
  if (!props.href) {
    console.error('Link component href is null', props.children);
    return <>{props.children}</>;
  }
  return (
    <NextLink {...props} href={props.href} legacyBehavior prefetch={false}>
      {props.children}
    </NextLink>
  );
};

export const NewLink: React.FC<
  React.PropsWithChildren<
    Omit<LinkProps, 'href'> & {
      href: Maybe<LinkProps['href']>;
    }
  >
> = (props) => {
  if (React.Children.count(props.children) !== 1) {
    console.error('Link component should have exactly one child');
    return null;
  }
  if (!props.href) {
    // console.error('Link component href is null', props.children);
    return <>{props.children}</>;
  }

  return (
    <StyledNextLink {...props} href={props.href}>
      {props.children}
    </StyledNextLink>
  );
};

const StyledNextLink = styled(NextLink)(({theme}) => `
  transition: ease color 0.2s;
  &:hover {
    color: ${theme.colors.primary};
  }
`);

export const BlockLink = styled.a`
  display: block;
`;
