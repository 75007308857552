import * as React from 'react';
import {HeroPlain as HeroPlainProps} from '@mindfulness/cms';

import {H1, H4} from '../../typography';
import {Column, Container, Row, Section} from '../../layout';
import {CallToActionButton} from '../../global';
import {SectionIndex} from '../../../types/types';

export const HeroPlain: React.FC<SectionIndex<HeroPlainProps>> = ({
  tagline,
  title,
  cta,
  paragraph,
  _type,
  index,
}) => (
  <Section padding={72} background="accentGradient">
    <Container>
      <Row justify="center">
        <Column span={24} maxWidth="prose" textAlign="center">
          {tagline ? <H4 colour="primary">{tagline}</H4> : null}
          {title ? <H1 spacing={24}>{title}</H1> : null}
          {paragraph ? <H4 spacing={30}>{paragraph}</H4> : null}
          {cta?.text ? (
            <CallToActionButton id={`${_type}-${index}`} {...cta}>
              {cta?.text}
            </CallToActionButton>
          ) : null}
        </Column>
      </Row>
    </Container>
  </Section>
);
