import * as React from 'react';
import {Maybe} from '@mindfulness/utils/maybe';

import {useCreateModal} from '../../../hooks';
import {Icon} from '../../global';
import {Box} from '../../layout';
import {
  CloseButtonWrapper,
  ModalInner,
  ModalOuter,
  ModalWrapper,
} from './SmallModal.styles';
import {AspectRatio} from '../../layouts/AspectRatio';
import {NormalizedButton} from '../../ui/NormalizedButton';

export const SmallModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  closeButton,
  Bottom,
  Top,
  ...props
}) => {
  const {open, name, closeModal} = useCreateModal(props);
  return (
    <ModalWrapper open={open} id={`SmallModal-${name}`}>
      <ModalOuter onClick={() => closeModal?.()} />
      <ModalInner>
        {Top ? <AspectRatio ratio="52:25">{Top}</AspectRatio> : null}
        <Box paddingT={34}>
          <Bottom />
        </Box>
        {closeButton && (
          <CloseButtonWrapper>
            <NormalizedButton
              onClick={() => closeModal?.()}
              id={`SmallModal-${name}Close`}
              role="button"
              aria-label="Close modal"
            >
              <Icon name="close-modal" size={30} />
            </NormalizedButton>
          </CloseButtonWrapper>
        )}
      </ModalInner>
    </ModalWrapper>
  );
};

type Props = {
  name: string;
  open?: boolean;
  closeButton?: boolean;
  Top: Maybe<React.ReactElement>;
  Bottom: React.FC<any>;
};
