import * as React from 'react';
import {useContext, useEffect, useMemo, useState} from 'react';
import {AnalyticsBrowser, Context, EventProperties, ID, User} from '@segment/analytics-next';
import Cookies from 'js-cookie';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';
import {definetly} from '@mindfulness/utils/definetly';
import {when} from '@mindfulness/utils/maybe';
import {ifDo} from '@mindfulness/utils/logic';
import {curry, isSet} from 'lodash/fp';
import crypto from 'crypto';

import {useAsyncEffect} from '../../../hooks/useAsyncEffect';
import {Maybe} from '../../../types/types';
import {onClient} from '../../../utils/next';
import {getSession, setAuthToken} from '../../../utils/auth';
import {removeQueryParam, utmPropsFromUrl} from '../../../utils/url';
import {clearValue, setValue} from '../../../utils/storage';
import {Campaign, appsflyer, defaultEventProps, defaultIdentifyProps, getPublicId, segmentCampaign, silentlyUpdateUrl, storePublicId, unNull} from '../../../utils';
import {debug} from '../../../utils/logging';
import {captureException} from '@sentry/nextjs';

const setValue_ = curry(setValue);

// Segment aliases
const aliases: Record<string, string> = {
  'Subscription screen viewed': 'Checkout Started',
  'Payment info entered': 'Payment Info Entered',
  'Subscription purchase initiated': 'Subscription flow initiated',
  'Subscription purchase completed': 'Subscription flow completed',
  'Subscription purchase aborted': 'Subscription flow aborted',
  'Event purchase completed': 'Order Completed',
  'Checkout completed': 'Order Completed',
  'Checkout payment entered': 'Payment Info Entered',
};


export const SegmentContext = React.createContext<ContextState>(undefined!);

export const SegmentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [user, setUser] = useState<User>();

  const segment = useMemo(() => {
    return onClient(() => {
      try {
        return AnalyticsBrowser.load({
          writeKey: definetly(process.env.SEGMENT_KEY, 'Missing env var SEGMENT_KEY.'),
        });
      } catch (e) {
        captureException('Could not load segment');
      }
    });
  }, []);

  useAsyncEffect(async () => {
    const user = await segment?.user();
    setUser(user);
  }, [segment?.user]);

  const anonymousId = useMemo(() => {
    const id = user?.anonymousId();
    if (id) {
      Cookies.set('segment_anonymous_id', id);
    } else {
      Cookies.remove('segment_anonymous_id');
    }
    return id;
  }, [user]);

  const track = async (
      event: string,
      traits?: EventProperties,
  ): Promise<void> => {
    // Track events into appsflyer
    appsflyer()('pba', 'event', {
      eventType: 'EVENT',
      eventValue: traits,
      eventName: event,
    });
    if (!segment) {
      captureException('Could not capture event because segment was blocked.');
      return;
    };
    await segment.track(
        event,
        defaultEventProps(traits),
        {
          // Overwrite segment campaign if set (so that appsflyer fields)
          // get sent through as utm values
          context: {
            campaign: segmentCampaign(location.search) as Campaign,
          },
        },
    );
    // If there is an alias for this event, track same props under the alias
    when(aliases[event], (alias) => track(alias, traits));
  };

  const identify = async (props?: Record<string, any>, userId?: string) => {
    try {
      const id = userId || getPublicId();
      debug('identify', id);

      // Appsflyer pba
      when(id, (__) => appsflyer()('pba', 'setCustomerUserId', __ ?? ''));
      if (!segment) {
        captureException('Could not identify user because segment was blocked.');
        return;
      };
      return await segment.identify(id, defaultIdentifyProps(props), {
        // Overrite segment campaign if set (so that appsflyer fields)
        // get sent through
        context: {
          campaign: segmentCampaign(location.search) as Campaign,
        },
      });
    } catch (err) {
      console.error('failed to identify', err);
    }
  };

  // Track page event
  useEffect(() => {
    onClient(async () => {
      const session = getSession();

      const publicId = unNull(searchParams.get('publicId'));
      const atoken = unNull(searchParams.get('atoken'));
      const userEmail = when(unNull(searchParams.get('email')), decodeURIComponent);
      const resetLogin = searchParams.get('reset');
      const utmProps = utmPropsFromUrl(Object.fromEntries(searchParams.entries()));

      // Remove the local storage if there is a reset query param
      when(resetLogin, () => {
        clearValue('auth_session');
      });

      // When there is a public id in the query param, store it and remove it from the url
      when(publicId, (id) => {
        // Store publicId if present and remove from url
        storePublicId(id);
        // Silently update the url without doing a page change
        silentlyUpdateUrl(router, removeQueryParam(pathname, 'publicId'));
      });

      // If there is an atoken in the query param, store it and remove it from the url
      when(atoken, (token) => {
        // Store atoken is present and remove from url
        setAuthToken(token);
        // Silently update the url without doing a page change
        silentlyUpdateUrl(router, removeQueryParam(pathname, 'atoken'));
      });

      // Identify user on page load
      if (publicId || userEmail) {
        await identify({...when(userEmail, (email) => ({email}))}, publicId);
      }

      // Log an attribution event if utm params are present
      if (utmProps.utm_source || utmProps.utm_medium) {
        track('Attribution tracked', {
          page_id: `${pathname}?${searchParams}`,
          page_path: pathname,
          page_url: `${pathname}?${searchParams}`,

          campaign: {
            source: utmProps.utm_source,
            content: utmProps.utm_content,
            name: utmProps.utm_campaign,
            medium: utmProps.utm_medium,
            term: utmProps.utm_term,
          },
          referrer: {
            url: location.href,
            host: location.hostname,
          },
        });
      }

      // Store first utm vals if not already in storage
      ifDo(!!utmProps.utm_source && !isSet('first-utm-source'), () => {
        when(utmProps.utm_source, setValue_('first-utm-source'));
        when(utmProps.utm_campaign, setValue_('first-utm-campaign'));
        when(utmProps.utm_content, setValue_('first-utm-content'));
        when(utmProps.utm_medium, setValue_('first-utm-medium'));
        when(utmProps.utm_term, setValue_('first-utm-term'));
      });

      // Always override latest utm values into last-utm props
      ifDo(!!utmProps.utm_source, () => {
        when(utmProps.utm_source, setValue_('last-utm-source'));
        when(utmProps.utm_campaign, setValue_('last-utm-campaign'));
        when(utmProps.utm_content, setValue_('last-utm-content'));
        when(utmProps.utm_medium, setValue_('last-utm-medium'));
        when(utmProps.utm_term, setValue_('last-utm-term'));
      });

      // identify in impact
      const shasum = crypto.createHash('sha1');
      const email = session?.email ?
    shasum.update(session.email).digest('hex') :
    '';
      const impactData = {
        customerId: session?.publicId ?? '',
        customerEmail: email,
      };

      const ire = window.ire;
      ire('identify', impactData);
      debug('impact identify', impactData);
    });
  }, []);

  return (
    <SegmentContext.Provider
      value={{
        identify,
        segment,
        user,
        anonymousId,
        track,
      }}
    >
      {children}
    </SegmentContext.Provider>
  );
};

type ContextState = {
  segment: Maybe<AnalyticsBrowser>;
  user: Maybe<User>;
  anonymousId: Maybe<ID>;
  identify: UseIdentify;
  track: UseTrack;
};
export type UseIdentify = (props?: Record<string, any>, userId?: string) => Promise<Context | undefined>
export type UseTrack = (event: string, traits?: EventProperties) => Promise<void>

export const useAnalytics = () => {
  const analytics = useContext(SegmentContext);
  if (!analytics) {
    throw new Error('useAnalytics must be used within a SegmentProvider');
  }
  return analytics;
};
export const useSegment = (): Maybe<AnalyticsBrowser> => {
  const {segment} = useContext(SegmentContext);
  return segment;
};

export const useSegmentUser = (): Maybe<User> => {
  const {user} = useContext(SegmentContext);
  return user;
};

export const useAnonymousId = (): Maybe<ID> => {
  const {anonymousId} = useContext(SegmentContext);
  return anonymousId;
};

export const useIdentify = (): UseIdentify => {
  const {identify} = useContext(SegmentContext);
  return identify;
};

export const useTrack = (): UseTrack => {
  const {track} = useContext(SegmentContext);
  return track;
};

