import {useState, useEffect, useCallback, RefObject} from 'react';
import {Maybe} from '@mindfulness/utils/maybe';

export interface Size {
  width: number;
  height: number;
}

export const useElementSize = <T extends HTMLElement = HTMLDivElement>(
  ref: Maybe<RefObject<T>>,
  triggerResize?: number,
) => {
  const [size, setSize] = useState<Maybe<Size>>();

  const updateSize = useCallback(() => {
    const node = ref?.current;
    if (node) {
      setSize({
        width: node?.offsetWidth ?? 0,
        height: node?.offsetHeight ?? 0,
      });
    }
  }, [ref]);

  // Update on first run
  useEffect(updateSize, [ref, triggerResize]);

  // Listens to window resize
  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};
