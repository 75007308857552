import {PropsWithChildren} from 'react';

import {Small} from '../../typography/Text';
import styles from './NoticeBox.module.css';

export const NoticeBox: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <div className={styles.noticeBox}>
      <img src="/images/icon-info-circle.svg" alt="info circle icon" />
      <Small fontSize="xs">{children}</Small>
    </div>
  );
};
