// TODO: orgainze these functions into their respective /client /server files

import {isEmpty} from 'lodash';
import {PaymentMethod} from '@stripe/stripe-js';
import {Maybe} from '@mindfulness/utils/maybe';
import {definetly} from '@mindfulness/utils/definetly';
import {switchEnum} from '@mindfulness/utils/logic';
import {ExternalPlan, SanityKeyed} from '@mindfulness/cms';

import {
  AuthedSession,
  client,
  adminClient,
  uniqueClientId,
  getGraphClient,
  unNull,
} from '../../utils';

import {
  CollectionTagFragment,
  GetPlansQuery,
  GetRelatedFeedsQuery,
  GetTopicsQuery,
  GetTopicsQueryVariables,
  GiftcardMutation,
  GiftcardMutationVariables,
  GiftcardRegisterMutation,
  GiftcardRegisterMutationVariables,
  Mutation,
  MutationJoinCohortArgs,
  MutationJoinListsArgs,
  MutationLeaveListsArgs,
  MutationLoginArgs,
  MutationRedeemShareAnonymouslyArgs,
  MutationSignupArgs,
  MutationUpdateUserArgs,
  PlanFragment,
  SubscribeAndRegisterMutationVariables,
  SubscribeMutationVariables,
  PurchaseEventPassAnonymouslyMutation,
  PurchaseEventPassAnonymouslyMutationVariables,
  PurchaseEventPassMutationVariables,
  PurchaseEventPassMutation,
  PlanPurchaseInput,
  EventPassInput,
  GetAllTopicsQuery,
  GetSinglesSlugsQuery,
  ProducerFragment,
  TagCollectionFragment,
  SignupMutation,
  ReferencedPlanPicker,
  PlanWithInstallments,
  ReferencedHeroPlanPicker,
  RelatedFeeds,
} from '../../types/types';
import {
  SingleType,
  InstantAccess,
  GetTagFeedQuery,
  GetTagFeedQueryVariables,
  TagFeedFragment,
  GetAllFeedIdsQuery,
  GenerateInstantAccessMutation,
  GenerateInstantAccessMutationVariables,
  SubscribeMutation,
  SubscribeAndRegisterMutation,
} from '../../types/api';
import {
  MUTATE_REDEEM_SHARE_ANONYMOUSLY,
  MUTATE_SIGNUP,
  MUTATE_UPDATE_USER,
  MUTATE_LOGIN,
  MUTATE_PURCHASE_PLAN,
  MUTATE_PURCHASE_PLAN_REGISTER,
  MUTATE_JOIN_LIST,
  MUTATE_LEAVE_LIST,
  MUTATE_JOIN_COHORT,
  MUTATE_PURCHASE_GIFTCARD_ANONYMOUSLY,
  MUTATE_PURCHASE_GIFTCARD,
  MUTATE_PURCHASE_EVENT_PASS_ANONYMOUSLY,
  MUTATE_PURCHASE_EVENT_PASS,
  MUTATE_GENERATE_INSTANT_ACCESS,
} from '../mutations';
import {
  GET_ALL_COLLECTIONS_PATHS,
  GET_ALL_FEED_IDS,
  GET_ALL_TOPICS,
  GET_COUPON_PLANS,
  GET_HUB_COLLECTIONS,
  GET_PLANS,
  GET_PRODUCERS,
  GET_RELATED_FEED,
  GET_SINGLES_SLUGS,
  GET_TAG_FEED,
  GET_TOPICS,
} from '../queries';

export const getTagFeed = async (
    variables: GetTagFeedQueryVariables,
): Promise<Maybe<TagFeedFragment>> => {
  const {data} = await getGraphClient().query<GetTagFeedQuery>({
    query: GET_TAG_FEED,
    variables,
  });
  return unNull(data?.tagFeed);
};

export const getProducers = async (): Promise<Array<ProducerFragment>> => {
  const {data} = await adminClient.query({
    query: GET_PRODUCERS,
  });
  return data.producers.items;
};

export const getSinglesSlugs = async ({
  take = 2000,
  skip = 0,
  type = SingleType.Guided,
}): Promise<Array<{ webSlug?: string | null | undefined }>> => {
  const {data} = await adminClient.query<GetSinglesSlugsQuery>({
    query: GET_SINGLES_SLUGS,
    variables: {
      take,
      skip,
      type,
    },
  });
  return data.singles.items;
};

export const getRelatedFeeds = async (id: string): Promise<RelatedFeeds> => {
  const {data} = await getGraphClient().query<GetRelatedFeedsQuery>({
    query: GET_RELATED_FEED,
    variables: {
      id,
    },
  });

  return (data.tagFeed?.relatedFeeds || []).map(({id, title}) => ({
    slug: id.split('.')[1],
    id,
    title: title || '',
  }));
};

export const getAllFeedIds = async (): Promise<Array<string>> => {
  const {data} = await getGraphClient().query<GetAllFeedIdsQuery>({
    query: GET_ALL_FEED_IDS,
  });
  if (!data?.tags) throw new Error('No feeds returned');

  const allFeeds = Promise.all(
      [...data.tags, {id: 'meditate.featured', type: 'FEED'}].map(async ({id, type}) => {
        const {data} = await getGraphClient().query<GetRelatedFeedsQuery>({
          query: GET_RELATED_FEED,
          variables: {
            id: type === 'TOPIC' ? `t-${id}` : id,
          },
        });
        const related = data.tagFeed?.relatedFeeds?.map(({id}) => id) || [];
        return [id, ...related];
      }),
  );
  return (await allFeeds).flat();
};

export const getTopics = async ({
  slugs,
}: GetTopicsQueryVariables): Promise<GetTopicsQuery['tags']> => {
  const {data} = await getGraphClient().query<GetTopicsQuery>({
    query: GET_TOPICS,
    variables: {
      slugs,
    },
  });
  return data.tags;
};

export const getAllTopics = async () => {
  const {data} = await adminClient.query<GetAllTopicsQuery>({
    query: GET_ALL_TOPICS,
  });
  return data.topics;
};

export const getTopicCollections = async (
    topicId: string,
): Promise<Array<TagCollectionFragment>> => {
  const {data} = await adminClient.query({
    query: GET_HUB_COLLECTIONS,
    variables: {
      id: topicId,
    },
  });
  return data.tagCollections;
};

export const getCollectionsPaths = async (): Promise<
  Array<CollectionTagFragment>
> => {
  const {data} = await getGraphClient().query({
    query: GET_ALL_COLLECTIONS_PATHS,
  });
  return data.tags;
};

export const redeemShareAnonymously = async (
    variables: MutationRedeemShareAnonymouslyArgs,
) => {
  const {data} = await client.mutate<{
    redeemShareAnonymously: Mutation['redeemShareAnonymously'];
  }>({
    mutation: MUTATE_REDEEM_SHARE_ANONYMOUSLY,
    variables,
  });

  return data;
};

export const joinLists = async (variables: MutationJoinListsArgs) => {
  const {data} = await client.mutate<{
    joinLists: Mutation['joinLists'];
  }>({
    mutation: MUTATE_JOIN_LIST,
    variables,
  });

  return data;
};
export const leaveLists = async (variables: MutationLeaveListsArgs) => {
  const {data} = await client.mutate<{
    leaveLists: Mutation['leaveLists'];
  }>({
    mutation: MUTATE_LEAVE_LIST,
    variables,
  });

  return data;
};

export const generateInstantAccess = async ({
  type,
  ...variables
}: GenerateInstantAccessMutationVariables) => {
  const {data} = await client.mutate<GenerateInstantAccessMutation>({
    mutation: MUTATE_GENERATE_INSTANT_ACCESS,
    variables: {
      type: type || InstantAccess.Download,
      ...variables,
    },
  });
  return data;
};

export const signup = async (
    variables: MutationSignupArgs,
    headers?: Record<string, string>,
) => {
  const res = await client.mutate<SignupMutation>({
    mutation: MUTATE_SIGNUP,
    variables,
    context: {
      headers,
    },
  });
  return res;
};

export const login = async (
    variables: MutationLoginArgs,
    headers?: Record<string, string>,
) => {
  const res = await client.mutate<{
    login: Mutation['login'];
  }>({
    mutation: MUTATE_LOGIN,
    variables,
    context: {
      headers,
    },
  });
  return res;
};

export const updateUser = async ({
  details,
  session,
}: {
  details: MutationUpdateUserArgs['details'];
  session: Maybe<AuthedSession>;
}) => {
  if (!session?.userId || !session?.token) {
    console.warn('Can\'t update user without a session userId or token');
    return;
  }
  const res = await client.mutate<{
    updateUser: Mutation['updateUser'];
  }>({
    mutation: MUTATE_UPDATE_USER,
    variables: {
      id: session.userId,
      details,
    },
    context: {
      session,
      token: session.token,
    },
  });
  return res;
};

export const getPickerPlans = async (
    section: Maybe<
    (ReferencedPlanPicker | ReferencedHeroPlanPicker) & {
      // Deprecated from Sanity but data may still be present
      plans?: Array<SanityKeyed<ExternalPlan>>;
    }
  >,
): Promise<Maybe<{ enrichedPlans: Array<PlanWithInstallments> }>> => {
  if (!section?.plans && !section?.newPlans) {
    return;
  }
  const sectionPlans = [
    ...(section?.newPlans ? section.newPlans : []),
    ...(section?.plans ?
      section.plans.map((plan) => ({plan, payIn3: false})) :
      []),
  ];
  // Filter monthly plans on bogo or gift
  const filteredPlans = ['bogo', 'gift', 'event'].includes(section.type || '') ?
    sectionPlans.filter((p) => !p?.plan?.webAlias?.includes('monthly')) :
    sectionPlans;
  const mappedPlans = filteredPlans.map(
      (p) =>
        switchEnum(p?.plan?.webAlias?.split('_')[0] || 'else', {
          lifetime: 'lifetime_2',
          monthly: 'monthly_2',
          annual: 'annual_3',
          else: undefined,
        }) || p?.plan?.webAlias,
  ) as Array<string>;

  if (section.coupon) {
    const {plans} = await getCouponPlans({
      plans: mappedPlans,
      coupon: section.coupon,
    });
    const enrichedPlans = sectionPlans?.map((sp) => {
      return {
        payIn3: !!sp.payIn3,
        ...plans.find((p) => p.webAlias === sp?.plan?.webAlias),
      };
    }) as Array<PlanWithInstallments>;
    return {enrichedPlans};
  }
  const {plans} = await getPlans({
    plans: mappedPlans,
  });

  const enrichedPlans = sectionPlans?.map((sp) => {
    return {
      payIn3: !!sp.payIn3,
      ...plans.find((p) => p.webAlias === sp?.plan?.webAlias),
    };
  }) as Array<PlanWithInstallments>;

  return {enrichedPlans};
};

export const getPlans = async ({
  plans,
}: {
  plans: Array<string>;
}): Promise<GetPlansQuery & { error: Maybe<string> }> => {
  const {data, error} = await adminClient.query<GetPlansQuery>({
    query: GET_PLANS,
    variables: {
      plans,
    },
  });
  if (error) {
    return {
      ...data,
      error: error?.graphQLErrors?.[0].message || 'An unknown error occurred',
    };
  }
  if (!data?.plans || isEmpty(data?.plans)) {
    return {
      plans: [],
      error: 'No plans found',
    };
  }
  return {
    ...data,
    error: undefined,
  };
};

export const getCouponPlans = async ({
  plans,
  coupon = '',
}: {
  plans: Array<string>;
  coupon: Maybe<string>;
}): Promise<GetPlansQuery & { error: Maybe<string> }> => {
  const {data, error} = await getGraphClient().query<GetPlansQuery>({
    query: GET_COUPON_PLANS,
    variables: {
      plans,
      coupon,
    },
  });
  if (error) {
    return {
      ...data,
      error: error?.graphQLErrors?.[0].message || 'An unknown error occurred',
    };
  }
  if (!data?.plans || isEmpty(data?.plans)) {
    return {
      plans: [],
      error: 'No plans found',
    };
  }
  return {
    ...data,
    error: undefined,
  };
};

export const purchasePlanRegister = async ({
  coupon,
  ...variables
}: SubscribeAndRegisterMutationVariables) => {
  const res = await client.mutate<SubscribeAndRegisterMutation>({
    mutation: MUTATE_PURCHASE_PLAN_REGISTER,
    variables: {
      ...variables,
      coupon,
    },
  });
  return res;
};

export const purchaseGiftCardRegister = async (
    variables: GiftcardRegisterMutationVariables,
) => {
  const res = await client.mutate<GiftcardRegisterMutation>({
    mutation: MUTATE_PURCHASE_GIFTCARD_ANONYMOUSLY,
    variables,
  });
  return res;
};

export const purchaseGiftCard = async (
    variables: GiftcardMutationVariables,
) => {
  const res = await client.mutate<GiftcardMutation>({
    mutation: MUTATE_PURCHASE_GIFTCARD,
    variables,
  });
  return res;
};

export const purchasePlan = async (variables: SubscribeMutationVariables) => {
  const res = await client.mutate<SubscribeMutation>({
    mutation: MUTATE_PURCHASE_PLAN,
    variables,
  });
  return res;
};

export const purchaseEventPass = async (
    variables: PurchaseEventPassMutationVariables,
) => {
  const res = await client.mutate<PurchaseEventPassMutation>({
    mutation: MUTATE_PURCHASE_EVENT_PASS,
    variables,
  });
  return res;
};

export const purchaseEventPassAnonymously = async (
    variables: PurchaseEventPassAnonymouslyMutationVariables,
) => {
  const res = await client.mutate<PurchaseEventPassAnonymouslyMutation>({
    mutation: MUTATE_PURCHASE_EVENT_PASS_ANONYMOUSLY,
    variables,
  });
  return res;
};

export const handleGiftcardPurchase = async ({
  session,
  data,
  coupon,
  plan,
  paymentMethod,
}: {
  session: Maybe<AuthedSession> | null;
  data: {
    fullName: Maybe<string>;
    email: string;
  };
  coupon: Maybe<string>;
  plan: PlanFragment;
  paymentMethod: PaymentMethod;
}) => {
  if (!session) {
    if (!data.email) {
      throw new Error('No email provided');
    }
    const res = await purchaseGiftCardRegister({
      coupon,
      plan: plan.webAlias ?? plan.code,
      paymentMethod: paymentMethod.id,
      email: data.email,
      name: data.fullName,
      deviceId: definetly(
          uniqueClientId(),
          'No clientId, likely running on server.',
      ),
    });

    return res;
  } else {
    if (data.email) {
      await updateCredentials(session, {
        fullName: undefined,
        email: data.email,
      });
    }
    const res = await purchaseGiftCard({
      coupon,
      plan: plan.webAlias ?? plan.code,
      paymentMethod: paymentMethod.id,
      email: data.email || '',
    });
    return res;
  }
};

export const handleEventPurchase = async ({
  session,
  data,
  plan,
  paymentMethod,
  event,
}: {
  session: Maybe<AuthedSession> | null;
  data: {
    fullName: string;
    email: string;
  };
  plan: PlanPurchaseInput;
  event: EventPassInput;
  paymentMethod: PaymentMethod;
}) => {
  if (!session) {
    const res = await purchaseEventPassAnonymously({
      email: data.email,
      name: data.fullName,
      event,
      plan,
      stripe: {
        paymentMethodId: paymentMethod.id,
      },
    });
    return res;
  } else {
    await updateCredentials(session, data);
    // Purchase a plan without registering
    const res = await purchaseEventPass({
      plan,
      event,
      stripe: {
        paymentMethodId: paymentMethod.id,
      },
    });
    return res;
  }
};

/** Calls different methods depending on whether the user is logged in or not */
export const handlePurchase = async ({
  session,
  data,
  coupon,
  plan,
  paymentMethod,
  trial,
}: {
  session: Maybe<AuthedSession> | null;
  data: {
    fullName: string;
    email: string;
  };
  coupon: Maybe<string>;
  plan: PlanFragment;
  trial: number;
  paymentMethod: PaymentMethod;
}) => {
  if (!session) {
    const res = await purchasePlanRegister({
      email: data.email,
      name: data.fullName,
      deviceId: definetly(
          uniqueClientId(),
          'No clientId, likely running on server.',
      ),
      coupon,
      plan: plan.webAlias ?? plan.code,
      trial,
      paymentMethod: paymentMethod.id,
    });
    return res;
  } else {
    await updateCredentials(session, data);
    // Purchase a plan without registering
    const res = await purchasePlan({
      email: data.email,
      name: data.fullName,
      coupon,
      plan: plan.webAlias ?? plan.code,
      paymentMethod: paymentMethod.id,
      trial,
    });
    return res;
  }
};

/**
 * Add the users email or name if its not already saved
 * @param {AuthedSession} session - The current session
 * @param {FormData} data - Email and username returned from the form
 */
const updateCredentials = async (session: AuthedSession, data: FormData) => {
  // If they have a session but are missing user data add it.
  if (!session.email || !session.fullName) {
    if (session.userId) {
      const [firstName, lastName] = (data.fullName || '').split(' ');
      await updateUser({
        details: {
          email: data.email,
          firstName,
          lastName,
        },
        session,
      });
    }
  }
};

export const joinCohort = async (variables: MutationJoinCohortArgs) => {
  const res = await client.mutate<{
    joinCohort: Mutation['joinCohort'];
  }>({
    mutation: MUTATE_JOIN_COHORT,
    variables,
  });
  return res;
};

type FormData = {
  fullName: Maybe<string>;
  email: string;
};
