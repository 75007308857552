import {Theme} from '@emotion/react';
import styled from '@emotion/styled';

export const ModalWrapper = styled.div<{
  open: boolean;
}>(
    ({theme, open}) => `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  background-color: rgba(55, 55, 75, 0.6);
  transition: opacity ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacings[16]};
  ${
    open ?
      `
    opacity: 1;
    pointer-events: all;
  ` :
      `
    opacity: 0;
    pointer-events: none;
  `
}
`,
);

export const ModalOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100dvh;
  z-index: 0;
`;

export const ModalInner = styled.div<{
  padding: keyof Theme['spacings'];
}>(
    ({theme, padding}) => `
    background-color: ${theme.colors.white};
    border-radius: ${theme.radius.lg};
    z-index: 1;
    position: relative;
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.md.min}) {
      padding: ${theme.spacings[padding]};
    }
`,
);

export const CloseButtonWrapper = styled.div(
    ({theme}) => `
position: absolute;
right: 0;
top: 0;
@media (min-width: ${theme.breakpoints.md.min}) {
  right: -${theme.spacings[24]};
  top: -${theme.spacings[24]};
}
`,
);
