import {ContentCollection as ContentCollectionProps} from '@mindfulness/cms';
import {useContext, useMemo} from 'react';
import {isDefined} from '@mindfulness/utils/maybe';
import {inPast} from '@mindfulness/utils/time';

import {
  EnrichedPages,
  Maybe,
  MixedFragment,
  SectionIndex,
} from '../../../types/types';

import {
  Box,
  Column,
  Container,
  Flex,
  Row,
  Section,
  Stack,
} from '../../layout';
import {Title1, Text, Small, Tag} from '../../typography';
import {filterPlayerSingles} from '../../../utils/fn';
import {APIImage, BlockContent, Context, Icon} from '../../global';
import {Divider} from '../../ui/Divider';
import {SingleContainer} from './ContentCollection.styles';
import {WithLinkProps, withLink} from '../../../hoc/withLink';
import {Readmore} from '../../ui/Readmore';

import {useLockedBundle} from '../../../hooks/useLockedBundle';
import {useHydratedCollection} from '../../../hooks/useHydratedCollection';
import {CollectionFeedFragment, SingleFeedFragment} from '../../../types/api';
import {useContributorNames} from '../../../hooks/useContributorNames';
import {onClient} from 'utils/next';

export const ContentCollection: React.FC<
  SectionIndex<
    ContentCollectionProps & {
      apiCollection?: Maybe<CollectionFeedFragment>,
    }
  >
> = ({title, subtitle, description, apiCollection, ...sectionPadding}) => {
  const {collection: c, ref} = useHydratedCollection(apiCollection);
  const {data: pageData} = useContext(Context);
  const page: EnrichedPages | undefined = pageData?._type === 'pages' ? pageData : undefined;
  const productBundle = !!page?.productBundle;
  const collection = c as CollectionFeedFragment;
  return collection?.results?.items ? (
    <Section
      ref={ref}
      sectionPadding={sectionPadding}
      padding={{
        xs: 50,
        md: 60,
      }}
    >
      <Container>
        <Row gap={40}>
          <Column
            span={{
              xs: 24,
              md: 16,
            }}
          >
            {title || collection?.title ? (
              <Title1>{title || collection?.title}</Title1>
            ) : null}
            {subtitle || collection?.subtitle ? (
              <Text spacing={30} fontWeight="bold">
                {subtitle || collection?.subtitle}
              </Text>
            ) : null}
            <Readmore minHeight={160} spacing={30}>
              {description && typeof description === 'object' ? (
                <BlockContent
                  fontSize="md"
                  content={description}
                  background="white"
                />
              ) : collection?.description ? (
                <Text
                  fontSize="md"
                  spacing={{
                    xs: 40,
                    md: 0,
                  }}
                >
                  {collection.description}
                </Text>
              ) : null}
            </Readmore>
          </Column>
          <Column
            span={{
              xs: 24,
              md: 8,
            }}
          >
            {filterPlayerSingles(
              collection?.results.items as MixedFragment[],
            ).map((item) => {
              if (item.__typename === 'Single') {
                const single = item as SingleFeedFragment;
                return <CourseLink {...single} productBundle={productBundle} key={item.id} />;
              }
            })}
          </Column>
        </Row>
      </Container>
    </Section>
  ) : null;
};

const CourseLink: React.FC<SingleFeedFragment & {
  productBundle?: boolean;
}> = ({productBundle, ...props}) => {
  const {bundle, accessAction, isLockedBundle} = useLockedBundle();
  const isReleased = useMemo(
      () => !isDefined(props.releaseDate) || inPast(new Date(props.releaseDate)),
      [props.releaseDate],
  );
  const productID = useMemo(() => {
    return onClient(() => {
      const urlParams = new URLSearchParams(window.location.search);
      const productID = urlParams.get('product');
      return productID;
    });
  }, []);
  return (
    <CourseItem
      isReleased={isReleased}
      href={
        isLockedBundle ?
          undefined :
          isReleased ?
          `/play?${bundle?.id && !productBundle ? `b=${bundle.id}&` : ''}s=${props.id}${productBundle ? `&product=${productID ?? 'true'}` : ''}` :
          undefined
      }
      {...props}
      {...(isLockedBundle ?
        {
          onClick: () => {
            accessAction();
          },
        } :
        {})}
    />
  );
};

const CourseItem: React.FC<
  SingleFeedFragment & WithLinkProps & { isReleased: boolean }
> = withLink(({cellImage, label, title, contributors, isReleased, userData}) => {
  const contributorNames = useContributorNames(contributors);

  return (
    <>
      <SingleContainer disabled={!isReleased} gap={16} items="start">
        <Box
          width="64px"
          height="64px"
          display="flex"
          shrink={0}
          position="relative"
          overflow="hidden"
          radius="lg"
          className="cell-image"
        >
          <APIImage width={64} height={64} url={cellImage?.url} />
        </Box>
        <Stack direction="vertical" justify="space-between" paddingY={4} tall>
          <Flex
            gap={8}
            height={'16px'}
            items="center"
            justify="space-between"
            spacing={6}
          >
            <Small
              fontWeight="bold"
              transform="uppercase"
              colour="primary"
              fontSize="2xs"
            >
              {label}
            </Small>
            {!isReleased ? <Tag>Upcoming</Tag> : (
              <>
                {
                  userData?.lastPlayedPercent &&
                  userData.lastPlayedPercent >= 90 ? (
                    <Icon name="played" size={16} colour="primary" />
                  ) : null}
              </>
            )}
          </Flex>
          <Text fontWeight="bold" className="list-title">
            {title}
          </Text>

          <Small colour="grey7" fontSize="xs">
            {contributorNames}
          </Small>
        </Stack>
      </SingleContainer>
      <Divider colour={'grey3'} spacing={16} />
    </>
  );
});
