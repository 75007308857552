import {Announcements as AnnouncementsProps} from '@mindfulness/cms';

import {Box, Card, Column, Container, Reel, Row} from '../../layout';
import {Section} from '../../layouts/Section';
import {SectionIndex} from '../../../types/types';
import {Title3, Text} from '../../typography';
import {BlockContent} from '../../global';
import {useLockedBundle} from '../../../hooks/useLockedBundle';

export const Announcements: React.FC<SectionIndex<AnnouncementsProps>> = ({
  title,
  blocks,
  background,
  ...sectionPadding
}) => {
  const {isLockedBundle} = useLockedBundle();
  return isLockedBundle ? null : (
    <Section background={background} sectionPadding={sectionPadding}>
      <Container maxWidth="lg">
        <Row spacing={16}>
          <Column>
            <Title3 weight="bold">{title || 'Announcements'}</Title3>
          </Column>
        </Row>
      </Container>
      <Container maxWidth="lg" gutter={{xs: 0, md: 16}}>
        <Reel
          show={{
            xs: 1.2,
            md: 2.4,
            lg: 3,
          }}
          scroll={1}
          withShadow
        >
          {blocks?.map(({title, date, paragraph, _key}) => (
            <Box
              key={_key}
              width="100%"
              height="100%"
              paddingB={34}
              paddingR={16}
            >
              <Card align="start" justify="start" textAlign="left">
                <Text colour="primary" fontWeight="bold">
                  {date}
                </Text>
                <Title3 weight="bold" spacing={30}>
                  {title}
                </Title3>
                <BlockContent
                  colour="grey7"
                  content={paragraph}
                  background="white"
                />
              </Card>
            </Box>
          ))}
        </Reel>
      </Container>
    </Section>
  );
};
