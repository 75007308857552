import {PropsWithChildren, useMemo, useRef, useState} from 'react';
import {toggle} from '@mindfulness/utils/boolean';
import {Theme} from '@emotion/react';

import {assertNumber} from '../../../utils';
import {Box} from '../../layout/Box';

import {Gradient, ReadmoreContainer, ReadmoreLink} from './Readmore.styled';

export const Readmore: React.FC<PropsWithChildren<Props>> = ({
  children,
  minHeight,
  spacing,
}) => {
  const [open, setOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const height = useMemo(
      () => assertNumber(containerRef.current?.scrollHeight, minHeight),
      [containerRef.current, minHeight],
  );
  const isOpen = open || !(minHeight < height);

  return (
    <Box spacing={spacing}>
      <ReadmoreContainer
        minHeight={minHeight}
        height={height}
        ref={containerRef}
        open={isOpen}
      >
        {children}
        <Gradient open={isOpen} />
      </ReadmoreContainer>
      {/* Only show the readmore link when the container has more to show */}
      {minHeight < height ? (
        <ReadmoreLink
          as="button"
          colour="primary"
          onClick={() => setOpen(toggle)}
        >
          {open ? `Read less` : `Read more`}
        </ReadmoreLink>
      ) : null}
    </Box>
  );
};

type Props = {
  minHeight: number;
  spacing?: keyof Theme['spacings'];
};
