import {GetMobileConfigQuery} from '../../types/api';
import {GET_MOBILE_CONFIG} from '../../api/queries/getMobileConfig';
import {getGraphClient} from '../../utils/graph';

export const getMobileConfig = async () => {
  const {data} = await getGraphClient().query<GetMobileConfigQuery>({
    query: GET_MOBILE_CONFIG,
    fetchPolicy: 'network-only',
  });
  return data?.mobileConfig;
};
