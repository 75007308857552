import * as React from 'react';
import {SanityClient} from '@sanity/client';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/nextjs';

import {
  EnrichedPages,
  GeneratedPageData,
  EnrichedArticles,
  ReferencedFooterNavigation,
  ReferencedNavigation,
  ReferencedSideNavigation,
  ReferencedSiteSettings,
  SingleDetailFragment,
} from '../../../types/types';
import {CustomLayout, ErrorFallback, Header, Meta} from '../../global';
import {switchSpace} from '../../../utils';

const Footer = dynamic(() =>
  import('../Footer/index').then(({Footer}) => Footer),
);

export const Layout: React.FC<LayoutProps> = ({children, ...props}) => (
  <CustomLayout {...props}>
    <Meta generatedData={props.data} single={props.single} />
    <Header
      navigation={props.navigation}
      sideNavigation={props.sideNavigation}
      site={props.site}
    />
    <Sentry.ErrorBoundary
      fallback={(props) => (
        <ErrorFallback {...props} index={0} sectionType={'Feed'} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
    {switchSpace({
      'mindful.org': () => null,
      'else': () => <Footer navigation={props.footerNavigation} />,
    })}
  </CustomLayout>
);

export type LayoutProps = React.PropsWithChildren<{
  data?: GeneratedPageData;
  page?: EnrichedPages;
  article?: EnrichedArticles;
  navigation: ReferencedNavigation;
  sideNavigation: ReferencedSideNavigation;
  footerNavigation: ReferencedFooterNavigation;
  single?: SingleDetailFragment;
  userEmail?: string;
  site: ReferencedSiteSettings;
  abortAction?: boolean;
  disableAnnouncement?: boolean;
  draftMode?: boolean;
  client: SanityClient;
}>;
