import * as React from 'react';

import {ApolloError} from '@apollo/client';

import {useForm} from '../../../hooks';
import {cleanEmail, switchSpace} from '../../../utils';
import {Alert, Input, Button, LinkButton} from '../../forms';
import {Column, Container, Flex, Row} from '../../layout';

import {Text, Title1} from '../../typography';
import {
  Divider,
  PolicyLinks,
  FacebookLogin,
  GoogleLogin,
  AppleLogin,
} from '../../ui';
import {Context, ModalContext} from '../../global';
import {NoticeBox} from '../NoticeBox';

export const LoginForm: React.FC<Props> = ({
  email,
  name = 'action',
  requireVerify = true,
  onVerify,
  redirect,
  setView,
  notice,
  verifyLink,
  onToPage,
  setLoading,
}) => {
  const {handlePasswordlessLogin} = React.useContext(Context);
  const {openModal} = React.useContext(ModalContext);

  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    email: string;
  }>({
    initialValues: {
      email: email ?? '',
    },
    onSubmit: async (e, {data, setErrors}) => {
      try {
        await handlePasswordlessLogin?.({
          ...data,
          requireVerify,
          onVerify,
          redirect,
          modalName: name,
          verifyLink,
          onToPage,
        });
      } catch (err) {
        if (err instanceof ApolloError || err instanceof Error) {
          setErrors({
            form: err.message,
          });
        }
      }
    },
    validations: {
      email: {
        required: {
          value: true,
          message: 'Please enter your email address',
        },
      },
    },
  });

  return (
    <Container maxWidth="sm">
      <Row spacing={16}>
        <Column>
          <Title1 as="p" size="3xl" spacing={10}>
            {'Login to your account'}
          </Title1>

          <Text colour="grey7">
            {
              'Enter your email and we’ll send you an Instant App Access link. Open the email on your phone, click that link, and you’re in!'
            }
          </Text>
          {notice && <NoticeBox>{notice}</NoticeBox>}
        </Column>
      </Row>
      <Row as="form" spacing={16} onSubmit={handleSubmit}>
        <Alert error={errors?.form} />
        <Column spacing={24}>
          <Input
            label="Email"
            placeholder="Enter your email address"
            value={cleanEmail(data?.email)}
            type="email"
            onChange={handleChange('email')}
          />
        </Column>
        <Column spacing={10}>
          <Button
            wide
            id="actionSubmit"
            loading={loading || undefined}
            type="submit"
          >
            {'Sign in'}
          </Button>
        </Column>
        {switchSpace({
          'mindfulness.com': () => (
            <>
              <Column spacing={10}>
                <Divider text="or continue with" spacing={16} />
              </Column>
              <Column spacing={10}>
                <Flex wrap="nowrap" gap={10}>
                  <FacebookLogin redirect={redirect} setLoading={setLoading} />
                  <GoogleLogin redirect={redirect} setLoading={setLoading} />
                  <AppleLogin redirect={redirect} setLoading={setLoading} />
                </Flex>
              </Column>
            </>
          ),
          'else': () => null,
        })}
        <Column>
          <PolicyLinks />
        </Column>
        <Column>
          <Divider spacing={16} />
        </Column>
        <Column>
          <Text>
            {`Don't have an account? `}
            <LinkButton
              onClick={() => {
                if (setView) {
                  setView('signup');
                  return;
                }
                openModal?.({
                  name,
                  props: {
                    view: 'signup',
                    requireVerify,
                    onVerify,
                    redirect,
                    email: data?.email,
                  },
                });
              }}
            >
              Sign up
            </LinkButton>
          </Text>
        </Column>
      </Row>
    </Container>
  );
};

type Props = {
  email?: string;
  name?: string;
  notice?: string;
  requireVerify?: boolean;
  onVerify?: (params: { email?: string; name?: string }) => void;
  redirect?: (params: { email?: string; name?: string }) => void;
  setView?: React.Dispatch<React.SetStateAction<string>>;
  verifyLink?: string;
  onToPage?: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
