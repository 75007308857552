import * as React from 'react';
import {Benefits as Props} from '@mindfulness/cms';
import {Maybe, when} from '@mindfulness/utils/maybe';

import {Table} from './Benefits.styled';

import {SectionIndex} from '../../../types/types';
import {Box, Column, Container, Row, Section} from '../../layout';
import {H1, Small, Text, Title3} from '../../typography';
import {CallToActionButton, Icon} from '../../global';

const data = [
  {
    name: 'Podcasts',
    basic: 'true',
  },
  {
    name: 'Music',
    basic: 'true',
  },
  {
    name: 'Nature Sounds',
    basic: 'true',
  },
  {
    name: 'Mindful Radio',
    description: 'Daily Mixes, Talks, Interviews, and more',
    basic: 'true',
  },
  {
    name: 'Breathing Exerises',
    basic: 'true',
  },
  {
    name: 'Gratitude Practices',
    basic: 'true',
  },
  {
    name: 'Mini Mindfulness',
    description: 'Scenics, Mindul Tips, and more',
    basic: 'true',
  },
  {
    name: 'Sleep',
    basic: 'limited',
  },
  {
    name: 'Sleep Meditations',
    basic: 'false',
  },
  {
    name: 'Guided Meditations',
    description: 'Beginner/Advanced, Anxiety, Stress, and more',
    basic: 'false',
  },
  {
    name: 'Mindfulness Programs',
    description: 'Courses, Masterclasses and more',
  },
  {
    name: '365 Daily Programs',
    basic: 'false',
  },
];

export const Benefits: React.FC<SectionIndex<Props>> = ({
  index,
  title,
  subtext,
  _type,
  position,
  cta,
  items,
}) => {
  const hasSubscription = items?.some((item) => item.unlocks?.subscription);
  return (position === 'checkout' && items && hasSubscription) || !position ? (
    <Section
      padding={{
        xs: 16,
        md: 30,
        lg: 80,
      }}
    >
      <Container maxWidth="md">
        <Row>
          <Column textAlign={'center'} justify="center" spacing={34}>
            <Box maxWidth={'370px'} width="100%">
              <Title title={title} index={index} position={position} />
            </Box>
          </Column>
          <Column spacing={56}>
            <Box
              radius="lg"
              background="greyWarm"
              paddingY={20}
              paddingX={{
                xs: 16,
                md: 30,
              }}
            >
              <Table>
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <Icon name="basics" width={58} height={24} />
                    </th>
                    <th>
                      <Icon name="plus-gold" width={52} height={20} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item) => (
                    <tr key={item.name}>
                      <td>
                        <Text>{item.name}</Text>
                        {item.description ? (
                          <Small fontWeight="regular">{item.description}</Small>
                        ) : null}
                      </td>
                      <td>
                        {item.basic === 'true' ? (
                          <Checked />
                        ) : item.basic === 'limited' ? (
                          <Text colour="grey7">Limited</Text>
                        ) : null}
                      </td>
                      <td>
                        <Checked />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
          </Column>
          {cta?.text ? (
            <>
              <Column justify="center" spacing={when(subtext, () => 10)}>
                <CallToActionButton id={`${_type}-${index}`} {...cta}>
                  {cta?.text}
                </CallToActionButton>
              </Column>
              {subtext ? (
                <Column justify="center" textAlign="center">
                  <Text>{subtext}</Text>
                </Column>
              ) : null}
            </>
          ) : null}
        </Row>
      </Container>
    </Section>
  ) : null;
};

const Checked: React.FC = () => <Icon name="checked" />;

const Title: React.FC<{
  title: Maybe<string>;
  index: number;
  position: Maybe<'checkout'>;
}> = ({title, index, position}) =>
  position === 'checkout' ? (
    <Title3 as={index === 0 ? 'h1' : 'h2'} weight="bold">
      {title}
    </Title3>
  ) : (
    <H1 size="2xl" as={index === 0 ? undefined : 'h2'}>
      {title}
    </H1>
  );
