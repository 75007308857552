import * as React from 'react';
import {Teachers} from '@mindfulness/cms';

import {ArticleLink} from './ArticleFeed.styles';

import {
  SectionIndex,
  ReferencedArticleFeed,
  ArticleBodyData,
} from '../../../types/types';
import {useEstimatedReadingTime} from '../../../hooks';
import {Link} from '../../global';
import {Column, Container, Grid, Row} from '../../layout';
import {Section} from '../../layouts/Section';
import {H2, H4, Small} from '../../typography';

export const ArticleFeed: React.FC<SectionIndex<ReferencedArticleFeed>> = ({
  title,
  articles = [],
}) => (
  <Section
    padding={60}
    background="greyWarm"
  >
    <Container maxWidth="lg" gutter={26}>
      <Row spacing={60}>
        <Column
          textAlign={{
            xs: 'start',
            md: 'center',
          }}
        >
          <H2>{title}</H2>
        </Column>
      </Row>
      <Grid
        columnGap={26}
        rowGap={{
          xs: 30,
          md: 60,
        }}
        cols={{
          xs: 1,
          sm: 2,
          md: 3,
        }}
      >
        {articles.map((article) => (
          <ArticleFeedCard article={article} key={article.slug} />
        ))}
      </Grid>
    </Container>
  </Section>
);

const ArticleFeedCard: React.FC<{
  article: {
    title: string;
    producer: Teachers;
    slug: string;
  } & ArticleBodyData;
}> = ({article}) => {
  const estMinutes = useEstimatedReadingTime(article);
  return (
    <Link key={article.slug} href={article.slug} passHref>
      <ArticleLink>
        <Small colour="grey7" spacing={6}>
          {article.producer?.teacher?.fullName || 'Mindfulness.com'}
        </Small>
        <H4 weight="bold" spacing={16}>
          {article.title}
        </H4>
        <Small colour="grey7">{`${estMinutes} minutes reading`}</Small>
      </ArticleLink>
    </Link>
  );
};
