import * as React from 'react';
import {Theme} from '@emotion/react';

import {useCreateModal} from '../../../hooks';
import {Icon} from '../../global';

import {
  CloseButtonWrapper,
  ModalInner,
  ModalOuter,
  ModalWrapper,
} from './OverlayModal.styles';
import {CloseButton} from '../Modal/Modal.styles';

export const OverlayModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  closeButton,
  padding = 40,
  ...props
}) => {
  const {open, name, closeModal} = useCreateModal(props);
  return (
    <ModalWrapper open={open} id={`OverlayModal-${name}`}>
      {open ? (
        <>
          <ModalOuter onClick={() => closeModal?.()} />
          <ModalInner padding={padding}>
            {children}
            {closeButton && (
              <CloseButtonWrapper>
                <CloseButton
                  onClick={() => closeModal?.()}
                  id={`OverlayModal-${name}Close`}
                  role="button"
                  aria-label="Close modal"
                >
                  <Icon name="close-modal" size={30} />
                </CloseButton>
              </CloseButtonWrapper>
            )}
          </ModalInner>
        </>
      ) : null}
    </ModalWrapper>
  );
};

type Props = {
  name: string;
  open?: boolean;
  padding?: keyof Theme['spacings'];
  closeButton?: boolean;
  onClose?: () => void;
};
