import React, {useContext, useRef} from 'react';
import copy from 'copy-to-clipboard';
import {Maybe} from '@mindfulness/utils/maybe';
import {isOnClient, onClient} from '../utils/next';
import {useOutsideAlerter} from './useOutsideAlerter';
import {BundleFeedFragment, ShareType, SingleFeedFragment} from '../types/api';
import {createShare} from '../api/functions/createShare';
import {Context} from '../components/global/Provider';
import {useTrack} from '../components/global/SegmentProvider';

const getShareType = (single: Maybe<SingleFeedFragment>, bundle: Maybe<BundleFeedFragment>): Maybe<ShareType> => {
  if (single) {
    return single.type as unknown as ShareType;
  }
  if (bundle) {
    return ShareType.Bundle;
  }
};

export const useShare = ({
  shareUrl,
  single,
  bundle,
} : {
  shareUrl?: Maybe<string>;
  single?: Maybe<SingleFeedFragment>;
  bundle?: Maybe<BundleFeedFragment>;
}) => {
  const {session} = useContext(Context);
  const track = useTrack();
  const shareRef = useRef<HTMLDivElement>(null);
  const [linkToShare, setLinkToShare] = React.useState<string | undefined>();
  useOutsideAlerter(shareRef, () => setOpen(false));
  const [open, setOpen] = React.useState(false);
  const [shared, setShared] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const createShareLink = async () => {
    try {
      if (!isOnClient()) return 'https://mindfulness.com';
      if (session) {
        if (bundle) {
          const share = await createShare({
            type: ShareType.Bundle,
            content: {
              id: bundle.id,
            },
          });
          if (share?.url) {
            return share.url;
          }
        }
        if (single?.type) {
          const share = await createShare({
            type: single.type as unknown as ShareType,
            content: {
              id: single.id,
            },
          });
          if (share?.url) {
            return share.url;
          }
        }
      }
      if (shareUrl) {
        return shareUrl;
      }
      return window.location.href;
    } catch {
      return window.location.href;
    } finally {
      setLoading(false);
    }
  };


  const handleShare = React.useCallback(() => {
    onClient(async () => {
      const linkToShare = await createShareLink();
      setLinkToShare(linkToShare);
      const shareType = getShareType(single, bundle);
      track('Share initiated', {
        share_url: linkToShare,
        share_type: shareType,
      });
      if (navigator.share) {
        navigator
            .share({
              url: linkToShare,
              title: window.document.title,
            })
            .then(() => {
              setShared(true);
              track('Share completed', {
                share_url: linkToShare,
                share_type: shareType,
              });
            })
            .catch((e) => {
              track('Share aborted', {
                error: e,
                share_url: linkToShare,
                share_type: shareType,
              });
            });
      } else {
        setOpen(true);
      }
    });
  }, [track, single, bundle]);

  const handleCopy = React.useCallback(async () => {
    copy(linkToShare || 'https://mindfulness.com');
    setCopied(true);
  }, [linkToShare]);

  return {
    open,
    setOpen,
    copied,
    shared,
    createShareLink,
    linkToShare,
    handleShare,
    handleCopy,
    shareRef,
    loading,
  };
};
