import * as React from 'react';
import {Figure} from '@mindfulness/cms';
import {Maybe} from '@mindfulness/utils/maybe';
import {isMobile} from 'react-device-detect';

import {BackgroundAPIImage, BackgroundImage} from '../../global';

import style from './SplitCover.module.css';

export const SplitCover: React.FC<
  React.PropsWithChildren<{
    image: Maybe<Figure | string>;
    mobileImage?: Maybe<string>;
    index: number;
  }>
> = ({image, mobileImage, children, index}) => (
  <section className={style.section}>
    <div className={style.wrapper}>
      <div className={style.splitImage}>
        {!image ? null : typeof image === 'string' ? (
          <BackgroundAPIImage
            src={isMobile ? mobileImage || image : image}
            doNotResize
            priority={index === 0}
          />
        ) : (
          <BackgroundImage image={image} priority={index === 0} />
        )}
      </div>
      <div className={style.splitContent}>{children}</div>
    </div>
  </section>
);
