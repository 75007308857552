import {useRef} from 'react';

import {Paragraph} from '../../typography';
import {Box, Flex} from '../../layout';
import styles from './Scale.module.css';
import {Icon} from '../../global';

export const Scale: React.FC<Props> = ({
  high, low, onChange, value,
}) => {
  const sliderBox = useRef<HTMLDivElement>(null);
  const sliderBar = useRef<HTMLDivElement>(null);

  const handleSlider = (y: number) => {
    if (!sliderBox.current || !sliderBar.current) return;
    const box = sliderBox.current.getBoundingClientRect().top;
    const height = sliderBox.current.clientHeight;
    const newPercent = Math.min(Math.max(((y - box) / height) * 100, 0), 100);
    sliderBar.current.style.height = `${newPercent}%`;
    onChange(newPercent);
  };
  return (
    <Box>
      <Flex
        justify='center'
        column
        onClick={(e) => {
          handleSlider(e.clientY);
        }}
        onMouseMove={(e) => {
          if (e.buttons !== 1) return;
          handleSlider(e.clientY);
        }}
        onTouchMove={(e) => {
          handleSlider(e.changedTouches[0].clientY);
        }}
      >
        <Paragraph fontWeight="bold" noSelect>
          {high}
        </Paragraph>
        <div className={styles.scaleContainer} ref={sliderBox}>
          <div className={styles.scaleBar} ref={sliderBar} style={{
            height: value + '%',
          }}>
            <Icon size={34} name="angle-down" className="angle-up" />
            <Icon size={34} name="angle-down" className="angle-down" />
          </div>
        </div>
        <Paragraph fontWeight="bold" noSelect>
          {low}
        </Paragraph>
      </Flex>
    </Box>
  );
};

type Props = {
  high: string;
  low: string;
  onChange: (percent: number) => void;
  value: number;
}
