export const fontSizes = {
  '2xs': '0.625rem',
  'xs': '0.75rem',
  'sm': '0.875rem',
  'base': '1rem',
  'md': '1.125rem',
  'md2': '1.25rem',
  'lg': '1.375rem',
  'lg2': '1.5rem',
  'xl': '1.75rem',
  '2xl': '2.125rem',
  '3xl': '2.375rem',
  'quote': '2.625rem',
  'mega': '3.25rem',
  'xmega': '3.875rem',
  'xxmega': '4.312rem',
};
