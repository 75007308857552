import * as React from 'react';
import {uniqBy} from 'lodash';

import {Box, Section} from '../../layout';
import {
  ReferencedTestimonialMarquee,
  SectionIndex,
} from '../../../types/types';
import {TestimonialCard} from '../../ui';
import {Row} from './TestimonialRows.styled';

export const TestimonialRows: React.FC<Props> = ({
  testimonials,
  background,
  paddingTop,
  paddingBottom,
}) => {
  const flatTestimonials = React.useMemo(
      () => testimonials?.map(({testimonial}) => testimonial),
      [testimonials],
  );
  const uniqTestimonials = React.useMemo(
      () => uniqBy(flatTestimonials, 'user'),
      [flatTestimonials],
  );
  const firstHalf = React.useMemo(
      () => uniqTestimonials.slice(0, Math.ceil(uniqTestimonials.length / 2)),
      [uniqTestimonials],
  );
  const secondHalf = React.useMemo(
      () => uniqTestimonials.slice(Math.ceil(uniqTestimonials.length / 2)),
      [uniqTestimonials],
  );

  return (
    <Section
      padding={{
        xs: 30,
        lg: 80,
      }}
      background={background}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Box width="100%" overflow="hidden">
        <Row offset={150} spacing={-36}>
          {[...firstHalf, ...firstHalf, ...firstHalf].map(
              ({review, user}, index) => (
              // eslint-disable-next-line react/no-array-index-key
                <TestimonialCard author={user} key={`${review}-${index}`}>
                  {review}
                </TestimonialCard>
              ),
          )}
        </Row>
        <Row offset={50}>
          {[...secondHalf, ...secondHalf, ...secondHalf].map(
              ({review, user}, index) => (
              // eslint-disable-next-line react/no-array-index-key
                <TestimonialCard author={user} key={`${review}-${index}`}>
                  {review}
                </TestimonialCard>
              ),
          )}
        </Row>
      </Box>
    </Section>
  );
};

type Props = SectionIndex<ReferencedTestimonialMarquee>;
