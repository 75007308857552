import {ButtonHTMLAttributes, createElement, CSSProperties, PropsWithChildren} from 'react';
import style from './NormalizedButton.module.css';
import utilStyle from '../../../styles/utils.module.css';

export const NormalizedButton: React.FC<PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> & Props> = ({
  position,
  width,
  children,
  as = 'button',
  ...props
}) => {
  return createElement(as, {
    className: [
      style.normalizedButton,
      ...(width === 'auto' ? [] : [utilStyle['full-width']]),
      ...(position ? [utilStyle[position]] : []),
    ].join(' '),
    ...props,
  }, children);
};


type Props = {
  as?: keyof JSX.IntrinsicElements;
  position?: CSSProperties['position'];
  width?: 'auto';
}
