import * as React from 'react';
import {DeviceGalleryNew as DeviceGalleryNewProps} from '@mindfulness/cms';

import {Container, Row, Column, Grid, Section, Box} from '../../layout';
import {H2, H3, Text} from '../../typography';
import {BasicImage, BlockContent, CallToActionButton} from '../../global';
import {SectionIndex} from '../../../types/types';

export const DeviceGalleryNew: React.FC<
  SectionIndex<DeviceGalleryNewProps>
> = ({
  title,
  paragraph,
  background,
  blocks,
  paddingTop,
  paddingBottom,
  _type,
  index,
}) => (
  <Section
    background={background}
    padding={{
      xs: 80,
      md: 160,
    }}
    sectionPadding={{paddingTop, paddingBottom}}
  >
    <Container maxWidth="lg">
      {title || paragraph ? (
        <Row spacing={34}>
          <Column textAlign="center">
            {title ? (
              <H2 weight="bold" spacing={12}>
                {title}
              </H2>
            ) : null}
            {paragraph ? (
              <BlockContent content={paragraph} background={background} />
            ) : null}
          </Column>
        </Row>
      ) : null}

      <Grid
        cols={{
          xs: 1,
          md: 3,
        }}
        columnGap={{
          xs: 16,
          md: 64,
        }}
        rowGap={{
          xs: 16,
          md: 72,
        }}
      >
        {blocks?.map(({title, paragraph, image, cta, _key}, blockIndex) => (
          <Box textAlign="center" key={_key}>
            <BasicImage image={image} />
            <Box paddingY={14} paddingX={16}>
              <H3 weight="bold" spacing={14}>
                {title}
              </H3>
              <Text lineHeight="22px">{paragraph}</Text>
            </Box>
            {cta?.text ? (
              <CallToActionButton
                {...cta}
                style="cta"
                id={`${_type}-${index}-${blockIndex}`}
              >
                {cta.text}
              </CallToActionButton>
            ) : null}
          </Box>
        ))}
      </Grid>
    </Container>
  </Section>
);
