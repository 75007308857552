import * as React from 'react';
import {HeroCountdown as HeroCountdownProps} from '@mindfulness/cms';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
import {ContentContainer, TextWrapper} from './HeroCountdown.styles';

import {H1, H2, H3, Text} from '../../typography';
import {
  Box,
  Column,
  Container,
  Row,
  Stack,
} from '../../layout';
import {BackgroundImage, BasicImage, CallToActionButton} from '../../global';
import {useOnMobile} from '../../../hooks';
import {Maybe, SectionIndex} from '../../../types/types';
import {Countdown} from './Countdown';

import {OnClient} from '../../ui';
import {Section} from '../../layouts/Section';
import {AspectRatio} from '../../layouts/AspectRatio';

export const HeroCountdown: React.FC<SectionIndex<HeroCountdownProps>> = ({
  title,
  datetime,
  subtitle,
  cta,
  background,
  goingLiveText,
  image: responsiveImage,
  index,
  _type,
}) => {
  const image = useOnMobile() ?
    responsiveImage?.mobileImage || responsiveImage?.desktopImage :
    responsiveImage?.desktopImage;

  return (
    <Section
      background={background}
      padding={{
        xs: 0,
        md: 40,
      }}
    >
      <Container
        gutter={{
          xs: 0,
          md: 60,
        }}
      >
        {responsiveImage?.mobileImage?.image ||
        responsiveImage?.desktopImage?.image ? (
          <Row spacing={{xs: 0, md: 20}}>
            <Column textAlign="center">
              <Box display={{xs: 'block', md: 'none'}}>
                <BasicImage decoration="none" image={image} />
              </Box>
              <Box display={{xs: 'none', md: 'block'}}>
                <AspectRatio ratio="16:9">
                  <ContentContainer>
                    {image ? (
                      <BackgroundImage image={image} zIndex={0} />
                    ) : null}
                    <TextWrapper>
                      {title ? (
                        <H1 as={index === 0 ? undefined : 'h2'} spacing={24}>
                          {title}
                        </H1>
                      ) : null}
                      {subtitle ? <H3>{subtitle}</H3> : null}
                    </TextWrapper>
                  </ContentContainer>
                </AspectRatio>
              </Box>
            </Column>
          </Row>
        ) : null}

        <Box background="greyWarm" radius="lg" paddingX={40} paddingY={30}>
          <Row justify="space-between" items="center">
            <Column
              order={{
                xs: 2,
                lg: 1,
              }}
              textAlign={{
                xs: 'center',
                lg: 'start',
              }}
              span={{
                xs: 24,
                lg: 8,
              }}
              spacing={{
                xs: 30,
                lg: 0,
              }}
            >
              <OnClient>
                <Date datetime={datetime} />
              </OnClient>
            </Column>
            <Column
              order={{
                xs: 1,
                lg: 2,
              }}
              textAlign="center"
              span={{
                xs: 24,
                lg: 6,
              }}
              spacing={{
                xs: 16,
                lg: 0,
              }}
            >
              <Text>{goingLiveText || 'Going live in'}</Text>
              <Stack justify="space-between" space={6}>
                <Countdown datetime={datetime} />
              </Stack>
            </Column>
            <Column
              order={3}
              textAlign={{
                xs: 'center',
                lg: 'end',
              }}
              span={{
                xs: 24,
                lg: 8,
              }}
              spacing={{
                xs: 16,
                lg: 0,
              }}
            >
              <CallToActionButton {...cta} id={`${_type}-${index}`}>
                {cta?.text}
              </CallToActionButton>
            </Column>
          </Row>
        </Box>
      </Container>
    </Section>
  );
};

const Date: React.FC<{ datetime: Maybe<string> }> = ({datetime}) => {
  const date = React.useMemo(() => {
    return dayjs.utc(datetime).local().format('MMMM Do');
  }, [datetime]);
  return (
    <>
      <H2 spacing={4}>{date}</H2>
      <Text>Check your email for more details.</Text>
    </>
  );
};
