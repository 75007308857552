import React from 'react';

import {
  BundleFragment,
  ItemLocation,
  WithWebSlug,
} from '../../../types/types';
import {BackgroundAPIImage, Icon, NewLink} from '../../global';
import {Box} from '../../layout';

import itemCardStyles from '../ItemCard/ItemCard.module.css';
import {H4, Small} from '../../typography';
import {BundleFeedFragment} from '../../../types/api';
import {AspectRatio} from '../../layouts/AspectRatio';

export const PlaylistCard: React.FC<
  WithWebSlug<BundleFeedFragment> & {
    location?: ItemLocation;
    webSlug: NonNullable<BundleFragment['webSlug']>;
    priority?: boolean;
    onClick: () => void;
  }
> = ({
  location,
  title,
  subtitle,
  label,
  cellImage,
  coverImage,
  ratings,
  onClick,
  ...item
}) => {
  return (
    <NewLink
      onClick={onClick}
      href={item.webSlug.includes('/playlist') ? item.webSlug : `/play?b=${item.id}`}
    >
      <div className={[itemCardStyles.itemContainer, ...(location === 'reel' ? [itemCardStyles.reel] : [])].join(' ')}>
        <AspectRatio ratio="1:1" justify="start">
          <Box
            color="white"
            position="relative"
            zIndex={1}
            radius="lg"
            overflow="hidden"
            paddingX={24}
          >
            <BackgroundAPIImage
              className="playlist-image"
              src={cellImage?.url || coverImage?.url}
              alt={`${title} ${subtitle} ${label}`}
              overlay={0.01}
              priority={item.priority} />
          </Box>
        </AspectRatio>
        <div className={itemCardStyles.textContainer}>
          <H4 weight="bold">{title}</H4>
          <Small
            colour="grey7"
            transform="uppercase"
            fontSize="2xs"
            fontWeight="bold"
          >
            {label}
          </Small>
          <Small colour="grey7">
            {ratings?.averageFormatted ? (
                <Icon name="star" size={12}>{ratings.averageFormatted}</Icon>
              ) : null}
          </Small>
        </div>
      </div>
    </NewLink>
  );
};
