import {Maybe} from '@mindfulness/utils/maybe';
import React, {useState} from 'react';
import {KeyOf} from '../types/types';

/**
 * A hook for easily working with next/router functions
 * @return {Object} - Object of navigation functions
 */
export function useForm<T>({
  initialValues,
  onSubmit,
  validations,
  redirectAction,
}: Config<T>) {
  const [data, setData] = useState<T>(initialValues);
  const [errors, setErrors] = useState<Maybe<Errors>>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleToggle = React.useCallback(
      (key: keyof T) => () => {
        setData((prev) => {
          return {
            ...prev,
            [key]: !prev[key],
          };
        });
      },
      [],
  );

  const handleChange = React.useCallback(
      (key: KeyOf, sanitizeFn?: (val: string) => string) =>
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
          const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value;
          setData((prev) => ({
            ...prev,
            [key]: value,
          }));
        },
      [],
  );

  const submit = async (e?: React.FormEvent) => {
    setLoading(true);
    setErrors({});
    if (validations) {
      let valid = true;
      const newErrors: Errors = {};
      for (const key in validations) {
        if (validations.hasOwnProperty(key)) {
          const value = data[key];
          const validation = validations[key];
          if (validation?.required?.value && !value) {
            valid = false;
            newErrors[key] =
              validation?.required?.message || 'An error occured';
          }

          const pattern = validation?.pattern;
          if (
            typeof value === 'string' &&
            pattern?.value &&
            !RegExp(pattern.value).test(value)
          ) {
            valid = false;
            newErrors[key] = pattern.message;
          }

          const custom = validation?.custom;
          if (
            typeof value === 'string' &&
            custom?.isValid &&
            !custom.isValid(value)
          ) {
            valid = false;
            newErrors[key] = custom.message;
          }
        }
      }

      if (!valid) {
        setErrors(newErrors);
        setLoading(false);
        return;
      }
    }

    if (onSubmit) {
      try {
        await onSubmit(e, {errors, data, setErrors, resetData});

        if (redirectAction) {
          return;
        }
      } catch {
        setLoading(false);
      }
    }
    setLoading(false);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    return await submit(e);
  };

  const triggerSubmit = async () => {
    return await submit();
  };

  const resetData = React.useCallback(() => {
    setData(initialValues);
  }, [initialValues]);

  const setValue = React.useCallback((key: string, value: any) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  React.useEffect(() => {
    if (redirectAction) {
      setLoading(false);
    }
  }, [redirectAction]);

  return {
    data,
    errors,
    loading,
    handleChange,
    handleToggle,
    handleSubmit,
    triggerSubmit,
    setValue,
  };
}

type Config<T> = {
  initialValues: T;
  redirectAction?: true;
  onSubmit?: (
    e: Maybe<React.FormEvent>,
    data: {
      data: T;
      errors: Maybe<Errors>;
      setErrors: React.Dispatch<React.SetStateAction<Maybe<Errors>>>;
      resetData: () => void;
    }
  ) => void;
  validations: Partial<
    Record<
      keyof T,
      Partial<{
        pattern: {
          value: string;
          message: string;
        };
        custom: {
          isValid: (val: string) => boolean;
          message: string;
        };
        required: {
          value: boolean;
          message: string;
        };
      }>
    >
  >;
};

type Errors = Record<string, string>;
