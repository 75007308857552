import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemState,
} from 'react-accessible-accordion';
import {RichText} from '@mindfulness/cms';

import {SectionIndex, ReferencedFAQs, Maybe} from '../../../types/types';
import {H1, H4, Title3} from '../../typography';
import {Container, Row, Column, Section} from '../../layout';
import {BlockContent, Icon} from '../../global';

import {
  AccordionButton,
  AccordionPanel,
  FAQFooter,
  HelpCenterLink,
} from './FAQs.styles';

const getTitleAndAnswer = (
    faq: ReferencedFAQs['faqs'][0],
): {
  title: Maybe<string>;
  answer: Maybe<RichText>;
} => {
  if (faq.faqType === 'custom') {
    return {
      title: faq.title,
      answer: faq.answer,
    };
  }
  return {
    title: faq.reference?.title,
    answer: faq.reference?.answer,
  };
};

export const FAQs: React.FC<SectionIndex<ReferencedFAQs>> = ({
  background,
  title,
  faqs,
  moreLink,
  paragraph,
  paddingTop,
  paddingBottom,
  index,
  position,
}) => {
  const sectionRef = React.useRef<HTMLDivElement>(null);
  return (
    <Section
      background={background}
      ref={sectionRef}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container maxWidth="md">
        {title ? (
          <Row spacing={50}>
            <Column span={24} textAlign={'center'}>
              <Title title={title} index={index} position={position} />
              {paragraph ? (
                <H4
                  size="md"
                  spacing={{
                    xs: 16,
                    md: 30,
                  }}
                >
                  {paragraph}
                </H4>
              ) : null}
            </Column>
          </Row>
        ) : null}
        <Row>
          <Column span={24}>
            <Accordion
              allowZeroExpanded={true}
              preExpanded={[faqs?.[0]._key]}
              onChange={(e) => {
                if (sectionRef?.current && e.length > 0) {
                  const id = `#accordion__heading-${e[0]}`;
                  const elem = sectionRef.current.querySelector(id);
                  const top =
                    (elem?.getBoundingClientRect()?.top || 0) +
                    window.scrollY -
                    120;
                  window.scroll({
                    top,
                  });
                }
              }}
            >
              {faqs?.map((faq) => {
                if (!faq) {
                  return null;
                }
                const {title, answer} = getTitleAndAnswer(faq);
                return (
                  <AccordionItem key={faq._key} uuid={faq._key}>
                    <AccordionItemState>
                      {({expanded}) => (
                        <>
                          <AccordionItemHeading>
                            <AccordionButton>
                              <span>{title}</span>
                              {expanded ? (
                                <Icon name="minus" size={30} />
                              ) : (
                                <Icon name="plus" size={30} />
                              )}
                            </AccordionButton>
                          </AccordionItemHeading>
                          <AccordionPanel>
                            <BlockContent
                              content={answer}
                              background="white"
                              colour="grey7"
                            />
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItemState>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Column>
          {moreLink ? (
            <Column span={24}>
              <FAQFooter>
                <span>Find more answers</span>
                <HelpCenterLink href={moreLink} target="_blank">
                  <span>Help center</span>
                  <Icon name="external" size={12} />
                </HelpCenterLink>
              </FAQFooter>
            </Column>
          ) : null}
        </Row>
      </Container>
    </Section>
  );
};

const Title: React.FC<{
  title: Maybe<string>;
  index: number;
  position: Maybe<'checkout'>;
}> = ({title, index, position}) =>
  position === 'checkout' ? (
    <Title3 as={index === 0 ? 'h1' : 'h2'} weight="bold">
      {title}
    </Title3>
  ) : (
    <H1 size="2xl" as={index === 0 ? undefined : 'h2'}>
      {title}
    </H1>
  );
