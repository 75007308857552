import styled from '@emotion/styled';
import {ItemLocation} from '../../../types/types';

export const ItemContainer = styled.div<{ location?: ItemLocation }>(
    ({theme, location = 'reel'}) => `
${
  location === 'reel' ?
    `
    flex-shrink: 0;
  ` :
    `
  `
}
display: block;
cursor: pointer;

.cell-image {
  transition: transform 0.3s ease;
  will-change: transform;
}
&:hover {
  .cell-image {
    transform: scale(1.1);
  }
}
`,
);
