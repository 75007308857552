import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {isNumber} from 'lodash';
import {isDefined} from '@mindfulness/utils/maybe';

import {
  getSpacing,
  getLeftPadding,
  getRightPadding,
  getTopPadding,
  getBottomPadding,
  getPadding,
  getDisplay,
  getRadius,
  getLeftMargin,
  getRightMargin,
  getWidth,
  getHeight,
  getBackground,
  getShadow,
  getTopMargin,
  getBottomMargin,
  stopGlobalPropForwarding,
} from '../../utils';
import {ColoursGradients, ResponsiveValue} from '../../types/types';

export const Box = styled('div', {
  shouldForwardProp: stopGlobalPropForwarding,
})<{
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  padding?: ResponsiveValue<keyof Theme['spacings']>;
  paddingX?: ResponsiveValue<keyof Theme['spacings']>;
  paddingY?: ResponsiveValue<keyof Theme['spacings']>;
  paddingT?: ResponsiveValue<keyof Theme['spacings']>;
  paddingB?: ResponsiveValue<keyof Theme['spacings']>;
  paddingL?: ResponsiveValue<keyof Theme['spacings']>;
  paddingR?: ResponsiveValue<keyof Theme['spacings']>;
  marginB?: ResponsiveValue<number>;
  marginT?: ResponsiveValue<number>;
  marginL?: ResponsiveValue<number>;
  marginR?: ResponsiveValue<number>;
  marginY?: ResponsiveValue<number>;
  border?: React.CSSProperties['border'];
  borderR?: React.CSSProperties['border'];
  items?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
  display?: ResponsiveValue<React.CSSProperties['display']>;
  position?: React.CSSProperties['position'];
  zIndex?: React.CSSProperties['zIndex'];
  overflow?: React.CSSProperties['overflow'];
  textAlign?: React.CSSProperties['textAlign'];
  radius?: ResponsiveValue<keyof Theme['radius']>;
  shadow?: ResponsiveValue<keyof Theme['shadows']>;
  inset?: number;
  width?: ResponsiveValue<number | string>;
  height?: ResponsiveValue<string>;
  minHeight?: ResponsiveValue<string>;
  minWidth?: number | string;
  maxWidth?: number | string | keyof Theme['sizes']['container'];
  color?: keyof Theme['colors'];
  background?: ResponsiveValue<ColoursGradients | keyof Theme['colors']>;
  backgroundColor?: string;
  visibility?: React.CSSProperties['visibility'];
  grow?: boolean;
  shrink?: React.CSSProperties['flexShrink'];
  self?: React.CSSProperties['alignSelf'];
  top?: React.CSSProperties['top'];
  left?: React.CSSProperties['left'];
  bottom?: React.CSSProperties['bottom'];
  flexDirection?: React.CSSProperties['flexDirection'];
}>(
    ({
      theme,
      bottom,
      spacing,
      padding,
      paddingX,
      paddingY,
      paddingT,
      paddingB,
      paddingL,
      paddingR,
      marginL,
      marginR,
      marginB,
      marginT,
      marginY,
      display,
      position,
      zIndex,
      height,
      items,
      justify,
      inset,
      width,
      maxWidth,
      minWidth,
      minHeight,
      overflow,
      textAlign,
      color,
      background,
      backgroundColor,
      radius,
      shadow,
      visibility,
      grow,
      shrink,
      self,
      top,
      left,
      flexDirection,
    }) => `
  ${width ? getWidth(theme, width) : ''}
  ${height ? getHeight(theme, height) : ''}
  ${
    maxWidth ?
      Object.keys(theme.sizes.container).includes(
          maxWidth as keyof Theme['sizes']['container'],
      ) ?
        `max-width: ${
          theme.sizes.container[maxWidth as keyof Theme['sizes']['container']]
        };` :
        `max-width: ${maxWidth};` :
      ''
}
  ${grow ? `flex-grow: 1;` : ''}
  ${isDefined(shrink) ? `flex-shrink: ${shrink};` : ''}
  ${minWidth ? `min-width: ${minWidth};` : ''}
  ${minHeight ? `min-height: ${minHeight};` : 'min-height: 1px;'}
  ${isNumber(inset) ? `inset: ${inset}px;` : ''}
  ${display ? getDisplay(theme, display) : ''}
  ${position ? `position: ${position};` : ''}
  ${overflow ? `overflow: ${overflow};` : ''}
  ${textAlign ? `text-align: ${textAlign};` : ''}
  ${zIndex ? `z-index: ${zIndex};` : ''}
  ${items ? `align-items: ${items};` : ''}
  ${flexDirection ? `flex-direction: ${flexDirection};` : ''}
  ${justify ? `justify-content: ${justify};` : ''}
  ${spacing ? getSpacing(theme, spacing) : ''}
  ${padding ? getPadding(theme, padding) : ''}
  ${color ? `color: ${theme.colors[color]};` : ''}
  ${self ? `self: ${self};` : ''}
  ${
    background ?
      getBackground(theme, background) :
      backgroundColor ?
      `background: ${backgroundColor};` :
      ''
}
${top ? `top: ${top};` : ''}
${left ? `left: ${left};` : ''}
${bottom ? `bottom: ${bottom};` : ''}
${visibility ? `visibility: ${visibility};` : ''}

  ${radius ? getRadius(theme, radius) : ''}
  ${shadow ? getShadow(theme, shadow) : ''}
  ${
    paddingX ?
      `
    ${getLeftPadding(theme, paddingX)}
    ${getRightPadding(theme, paddingX)}
  ` :
      ''
}
  ${
    paddingY ?
      `
    ${getTopPadding(theme, paddingY)}
    ${getBottomPadding(theme, paddingY)}
  ` :
      ''
}
  ${
    paddingT ?
      `
    ${getTopPadding(theme, paddingT)}
  ` :
      ''
}
  ${
    paddingB ?
      `
    ${getBottomPadding(theme, paddingB)}
  ` :
      ''
}
  ${
    paddingL ?
      `
    ${getLeftPadding(theme, paddingL)}
  ` :
      ''
}
  ${
    paddingR ?
      `
    ${getRightPadding(theme, paddingR)}
  ` :
      ''
}
${
  marginY ?
    `
    ${getTopMargin(theme, marginY)}
    ${getBottomMargin(theme, marginY)}
  ` :
    `
    ${
      marginT ?
        `
      ${getTopMargin(theme, marginT)}
    ` :
        ''
    }
    ${
      marginB ?
        `
      ${getBottomMargin(theme, marginB)}
    ` :
        ''
    }
    `
}

  ${
    marginL ?
      `
    ${getLeftMargin(theme, marginL)}
  ` :
      ''
}
  ${
    marginR ?
      `
    ${getRightMargin(theme, marginR)}
  ` :
      ''
}

`,
);
