import * as React from 'react';
import Image from 'next/legacy/image';
import {Maybe} from '@mindfulness/utils/maybe';

import {Box, Flex} from '../../layout';
import {Text} from '../../typography';

export const TestimonialCard: React.FC<
  React.PropsWithChildren<{
    author: Maybe<string>;
    location?: 'stack' | 'reel';
  }>
> = ({author, children, location = 'reel'}) => {
  const isReel = React.useMemo(() => location === 'reel', [location]);
  return (
    <Box
      display="flex"
      height="100%"
      width="285px"
      paddingT={isReel ? 16 : 0}
      paddingB={isReel ? 34 : 0}
      paddingR={isReel ? 10 : 0}
    >
      <Box
        radius="lg"
        shadow="medium"
        paddingT={30}
        paddingB={20}
        paddingX={16}
        background="white"
        height="100%"
        width="100%"
        textAlign="left"
        display="flex"
        flexDirection="column"
        justify="space-between"
        color="grey9"
      >
        <Text spacing={20} fontSize="sm">
          {children}
        </Text>
        <Flex justify="space-between">
          <Text fontWeight="bold">{author ? `- ${author}` : ''}</Text>
          <Box>
            <Image
              alt="5 stars"
              src="/images/review-stars.png"
              width={86}
              height={15.5}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
