import * as React from 'react';
import {Maybe} from '@mindfulness/utils/maybe';
import {ApolloError} from '@apollo/client';

import {useForm, useModal} from '../../../hooks';
import {Alert, Input, Button} from '../../forms';
import {Column, Container, Row} from '../../layout';
import {Text, Title1} from '../../typography';
import {Divider} from '../../ui';
import {Context, Link} from '../../global';
import {LinkButton} from '../../forms';
import linkButton from '../../forms/LinkButton/LinkButton.module.css';

export const VerifyForm: React.FC<Props> = ({
  email = '',
  token = '',
  redirect,
  faqLink,
  setLoading,
}) => {
  const {handleVerify, handlePasswordlessLogin} =
    React.useContext(Context);
  const {close} = useModal('action');

  const {data, handleChange, handleSubmit, loading, errors} =
    useForm<{
      email: string;
      token: string;
    }>({
      initialValues: {
        email,
        token,
      },
      onSubmit: async (_e, {data, setErrors}) => {
        setLoading(true);
        try {
          await handleVerify?.(data, redirect);
          close();
        } catch (e) {
          if (e instanceof ApolloError || e instanceof Error) {
            setErrors({
              form: e.message,
            });
            return;
          }
          setErrors({
            form: 'Invalid verification token, please check and try again.',
          });
          setLoading(false);
        }
      },
      validations: {
        email: {
          required: {
            value: true,
            message: 'Please enter your email address',
          },
        },
        token: {
          required: {
            value: true,
            message: 'Please enter your verification code',
          },
        },
      },
    });

  return (
    <Container maxWidth="sm">
      <Row spacing={16}>
        <Column>
          <Title1 as="p" size="3xl" spacing={10}>
            {'Verify your email'}
          </Title1>
          <Text spacing={16} colour="grey7">
            {
              'Because you already have an account with Mindfulness.com we need to verify your identity.'
            }
          </Text>
          <Text fontWeight="bold" colour="grey7">
            {'Enter the verification code we sent to your email.'}
          </Text>
        </Column>
      </Row>

      <Row as="form" spacing={16} onSubmit={handleSubmit}>
        <Alert error={errors?.form} />
        <Column spacing={24}>
          <Input
            label="Email"
            placeholder="Enter your email address"
            value={data?.email}
            type="email"
            onChange={handleChange('email')}
          />
        </Column>
        <Column spacing={24}>
          <Input
            label="Verification code"
            placeholder="Enter the code we sent to you"
            value={data?.token}
            type="text"
            onChange={handleChange('token')}
          />
        </Column>

        <Column spacing={10}>
          <Button
            wide
            id="verifySubmit"
            loading={loading || undefined}
            type="submit"
            disabled={!data?.token || !data?.email}
          >
            {'Verify'}
          </Button>
        </Column>
        <Column>
          <Divider />
        </Column>
        <Column>
          <Text fontSize="sm" spacing={16}>
            {`You haven't received the code? `}
            <LinkButton
              type="button"
              onClick={async (e) => {
                e.preventDefault();
                await handlePasswordlessLogin?.({email, redirect});
              }}
            >
              {'Send Again'}
            </LinkButton>
          </Text>
          <Text fontSize="sm">
            If you need help, you can read our{' '}
            <Link href={faqLink || 'https://help.mindfulness.com'} passHref>
              <a className={linkButton.styledLink} target="_blank">{'FAQs'}</a>
            </Link>
            {' or '}
            <Link href={'/contact'} passHref>
              <a className={linkButton.styledLink} target="_blank">{'Contact Support'}</a>
            </Link>
          </Text>
        </Column>
      </Row>
    </Container>
  );
};

type Props = {
  email: Maybe<string>;
  token?: string;
  redirect?: (params: { email?: string; name?: string }) => void;
  faqLink?: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
