import * as React from 'react';
import {AllColour, TextOnImage as TextOnImageProps} from '@mindfulness/cms';

import {useOnMobile} from '../../../hooks';
import {SectionIndex} from '../../../types/types';
import {textColourMap} from '../../../utils';
import {
  BackgroundImage,
  CallToActionButton,
  BlockContent,
  TextWrapper,
} from '../../global';
import {H1} from '../../typography';
import {
  Container,
  Row,
  Column,
  Box,
} from '../../layout';
import {Section} from '../../layouts/Section';
import {AspectRatio} from '../../layouts/AspectRatio';

export const TextOnImage: React.FC<Props> = (props) => {
  const {image: responsiveImage, paddingTop, paddingBottom} = props;
  const image = responsiveImage ?
    useOnMobile() ?
      responsiveImage?.mobileImage || responsiveImage?.desktopImage :
      responsiveImage?.desktopImage :
    undefined;

  return (
    <Section
      position="relative"
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Box
        display={{
          xs: 'none',
          md: 'block',
        }}
        paddingY={174}
      >
        <BackgroundImage image={image} overlay={0.7} />
        <Content {...props} background="grey9" />
      </Box>
      <Box
        display={{
          xs: 'block',
          md: 'none',
        }}
      >
        <AspectRatio ratio="414:256">
          <BackgroundImage image={image} overlay={0.7} />
        </AspectRatio>
        <Box background="greyWarm" paddingT={30} paddingB={50}>
          <Content {...props} background="greyWarm" />
        </Box>
      </Box>
    </Section>
  );
};

const Content: React.FC<Props & { background: AllColour }> = ({
  title,
  index,
  paragraph,
  cta,
  _type,
  background,
}) => (
  <Container>
    <Row>
      <Column
        span={{
          xs: 24,
          md: 16,
        }}
        textAlign={{
          xs: 'center',
          md: 'start',
        }}
      >
        <Box>
          <TextWrapper background={background}>
            {title ? (
              <H1
                as={index === 0 ? undefined : 'h2'}
                spacing={paragraph ? 24 : undefined}
              >
                {title}
              </H1>
            ) : null}
            {paragraph ? (
              <Row spacing={16}>
                <Column
                  span={{
                    xs: 24,
                    md: 16,
                  }}
                >
                  <BlockContent
                    content={paragraph}
                    background={background}
                    fontSize="md"
                    colour={textColourMap(background)}
                  />
                </Column>
              </Row>
            ) : null}
          </TextWrapper>
          <Box marginT={30}>
            {cta?.text ? (
              <CallToActionButton id={`${_type}-${index}`} {...cta}>
                {cta.text}
              </CallToActionButton>
            ) : null}
          </Box>
        </Box>
      </Column>
    </Row>
  </Container>
);

type Props = SectionIndex<TextOnImageProps>;
