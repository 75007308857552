import * as React from 'react';
import {when} from '@mindfulness/utils/maybe';

import {Maybe} from '../../../types/types';
import {Label, Small} from '../../typography';

import {InputWrapper, StyledInput} from './Input.styles';

export const Input: React.FC<Props> = ({
  label,
  validator,
  error,
  onChange,
  ...props
}) => {
  const [inValid, setInvalid] = React.useState<string>();
  return (
    <div>
      <InputWrapper
        readOnly={Boolean(props.readOnly)}
        error={Boolean(inValid || error)}
      >
        <Label>{label}</Label>
        <StyledInput
          error={Boolean(inValid || error)}
          onChange={(e) => {
            when(validator, (v) => setInvalid(v(e.target.value)));
            onChange?.(e);
          }}
          {...props}
        />
      </InputWrapper>
      {error ? <Small colour="accent">{error}</Small> : null}
    </div>
  );
};

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  validator?: (
    e: React.ChangeEvent<HTMLInputElement>['target']['value']
  ) => Maybe<string>;
  error?: string;
};
