import * as React from 'react';

import {
  CloseButton,
  ModalContent,
  ModalContentInner,
  ModalImage,
  ModalInner,
  ModalWrapper,
} from './Modal.styles';

import {Icon} from '../../global';
import {useCreateModal} from '../../../hooks';

export const Modal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  Left,
  Right,
  hideClose,
  noGradient,
  ...props
}) => {
  const {open, name, closeModal, activeProps} = useCreateModal(props);
  return (
    <ModalWrapper open={open} id={`Modal-${name}`}>
      {open ? (
        <ModalInner>
          <ModalImage>
            <Left {...activeProps} />
          </ModalImage>
          <ModalContent noGradient={noGradient}>
            <ModalContentInner>
              <Right {...activeProps} />
            </ModalContentInner>
            {hideClose ? null : (
            <CloseButton
              id={`Modal-${name}Close`}
              role="button"
              aria-label="Close modal"
              onClick={() => closeModal?.()}
            >
              <Icon
                name="close-modal"
                size={{
                  xs: 30,
                  md: 40,
                }}
              />
            </CloseButton>
          )}
          </ModalContent>
        </ModalInner>
      ) : null}

    </ModalWrapper>
  );
};

type Props = {
  Left: React.FC<any>;
  Right: React.FC<any>;
  name: string;
  open?: boolean;
  hideClose?: boolean;
  noGradient?: boolean;
};
