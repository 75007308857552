import React, {ReactNode, Suspense, useEffect} from 'react';
import {onClient} from '../../../utils';
import {LoadingSpinner} from '../LoadingSpinner';

// Ensure that client side rendering is always inside a Suspense boundary
export const OnClient: React.FC<Props> = ({fallback, children}) => {
  const [isOnClient, setIsOnClient] = React.useState<boolean>(false);
  useEffect(() => {
    onClient(() => setIsOnClient(true));
  }, []);

  return (
    <Suspense fallback={fallback || <LoadingSpinner />}>
      {isOnClient ? children : null}
    </Suspense>
  );
};

type Props = React.PropsWithChildren<{
  fallback?: ReactNode;
}>;
