import {Maybe} from '@mindfulness/utils/maybe';
import {RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {getSizedImage} from '../utils';

export interface Size {
  width: number;
  height: number;
}

export const useImageSize = <T extends HTMLElement = HTMLDivElement>(
  src: string,
  ref: Maybe<RefObject<T>>,
  triggerResize?: number,
) => {
  const [size, setSize] = useState<Maybe<Size>>();

  const updateSize = useCallback(() => {
    const node = ref?.current;
    if (node) {
      setSize((prev) => {
        if (!prev) {
          return {
            width: node?.offsetWidth ?? 0,
            height: node?.offsetHeight ?? 0,
          };
        }
        if (
          node?.offsetWidth < prev.width ||
          node?.offsetHeight < prev.height
        ) {
          return prev;
        }
        return {
          width: node?.offsetWidth ?? 0,
          height: node?.offsetHeight ?? 0,
        };
      });
    }
  }, [ref?.current?.offsetHeight, ref?.current?.offsetWidth]);

  const url = useMemo(
      () =>
        getSizedImage({
          src,
          size,
        }),
      [src, size?.width, size?.height],
  );

  // Update on first run
  useEffect(updateSize, [ref, triggerResize]);

  // Listens to window resize
  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return url;
};
