import {GetQuestionSheetQuery, GetQuestionSheetQueryVariables} from '../../types/api';
import {GET_QUESTION_SHEETS} from '../queries/getQuestionSheets';
import {getGraphClient} from '../../utils/graph';

export const getQuestionSheets = async (variables: GetQuestionSheetQueryVariables) => {
  const {data} = await getGraphClient().query<GetQuestionSheetQuery>({
    query: GET_QUESTION_SHEETS,
    variables,
    fetchPolicy: 'no-cache',
  });

  return data?.questionSheets;
};
