import React, {useEffect} from 'react';
import ReactPlayer from 'react-player';
import {useRecoilState, useRecoilValue} from 'recoil';
import {first, round, throttle} from 'lodash';

import {OnClient} from '../OnClient';
import {playerAutoPlayState, playerPlayState, playerTranscripts} from '../../../state/atoms';
import {useModal, useAsMemo, useNavigation} from '../../../hooks';

import {hasCompletedOnboarding, trackEvents} from '../../../api';
import {
  Maybe,
  PlayableTrack,
  SingleFragment,
  SinglesQueue,
  SingleVariantFragment,
  VideoTracking,
} from '../../../types/types';
import {Context} from '../../global';
import {Box} from '../../layout';
import {useTrack} from '../../global/SegmentProvider';
import {getChapterPositionString, isOnClient, mapTranscriptUrl, uniqueSessionId, unNull} from '../../../utils';
import {omitEmpty} from '@mindfulness/utils/object';

export const Player: React.FC<Props> = (props) => {
  return (
    <OnClient
      fallback={
        <Box display="flex" width="100%" items="center" justify="center" />
      }
    >
      <PlayerInner {...props} />
    </OnClient>
  );
};

const PlayerInner: React.FC<Props> = ({
  duration,
  playPercent,
  playSeconds,
  single,
  variant,
  chapter,
  poster,
  setChapter,
  setTime,
  nextAndPreviousSingles,
  tracks,
  player,
  frame,
  bundleId,
  previousState,
  cueRef,
}) => {
  const [status, setStatus] = useRecoilState(playerPlayState);
  const transcripts = useRecoilValue(playerTranscripts);
  const {session} = React.useContext(Context);
  const track = useTrack();
  const {update} = useNavigation();
  const autoplay = useRecoilValue(playerAutoPlayState);
  const singleFinishedModal = useModal('singleFinished');
  const unlockModal = useModal('unlock');

  // Track the progress of playback every 5 percent of the chapter that is played in seconds
  const fivePercent = React.useMemo(() => {
    return duration * 0.05 * 1000;
  }, [duration]);

  const source = useAsMemo(chapter?.source);

  useEffect(() => {
    if (!player.current || !cueRef?.current) return;
    const textTracks = player.current.getInternalPlayer()?.textTracks;
    if (!tracks?.[0]) return;
    const textTrack: TextTrack | undefined = first(textTracks);
    if (!textTrack) return;
    textTrack.mode = 'showing';
    if (!transcripts) {
      const cueContainer = cueRef.current;
      cueContainer.innerHTML = '';
      cueContainer.style.opacity = '0';
    }
    const handleCueChange = (e: Event) => {
      const cueContainer = cueRef.current;
      if (!cueContainer || !transcripts) return;
      const track = e.target as TextTrack;
      const activeCue = track.activeCues?.[0] as VTTCue;
      cueContainer.innerHTML = '';
      if (!activeCue) {
        cueContainer.style.opacity = '0';
        return;
      };
      cueContainer.style.opacity = '1';
      cueContainer.appendChild(activeCue.getCueAsHTML());
      activeCue.line = -1;
    };

    textTrack.addEventListener('cuechange', handleCueChange);
    return () => {
      textTrack.removeEventListener('cuechange', handleCueChange);
    };
  }, [player, cueRef?.current, transcripts]);


  const defaultEventProps = (): VideoTracking => omitEmpty({
    // Session uuid
    player_session: uniqueSessionId(),
    // Chapter
    ...(chapter ? ({
      chapter: chapter.id,
      chaper_label: chapter.chapterLabel,
      chapter_duration: unNull(chapter.duration),
      chapter_order: chapter.index,
      chapter_position: getChapterPositionString(tracks, chapter.index),
      // Media
      media_id: chapter.id,
      media_type: chapter.type,
    }) : {}),
    /**
     * chapter_id
     * https://mindfulnesscom.slack.com/archives/C08AN476V/p1728874446698599?thread_ts=1726703985.306019&cid=C08AN476V
     * Which is:
     * The single id if there is only on chapter
     * The chapter.id if there is more than one chapter (i.e. the single id of the "chapter" single)
     * The single id if there is more than one chapter but it’s the first chapter
     */
    ...(tracks && chapter ? ({
      chapter_id: tracks.length === 1 ? single.id : chapter?.index === 0 ? single.id : chapter?.id,
    }) : ({})),
    // Chapter progress
    play_percent: playPercent,
    play_seconds: playSeconds,
    // Single
    single_id: single.id,
    single_type: single.type,
    single_title: single.title,
    single_source: source,
    // Variant
    ...(variant ? ({
      variant_id: variant.id,
      variant_label: unNull(variant.label),
    }) : ({})),

    session_duration: duration,
    video_url: source,
    bundle_id: bundleId,
    subtitles_shown: transcripts,
  });

  const trackMediaEvent = async (name: string, props = {}) => {
    const defaultEvents = defaultEventProps();
    track(name, {
      ...defaultEvents,
    });
    await trackEvents({
      events: [
        {
          name,
          properties: {
            ...defaultEvents,
            ...props,
          },
          timestamp: new Date(),
        },
      ],
    });
  };

  const delayedTrack = React.useMemo(
      () =>
        throttle(
            async (name: string, props: VideoTracking) => {
              await track(name, props);
              await trackEvents({
                events: [
                  {
                    name,
                    properties: {
                      ...props,
                    },
                    timestamp: new Date(),
                  },
                ],
              });
            },
            fivePercent,
            {trailing: false, leading: true},
        ),
      [fivePercent],
  );
  return (
    <ReactPlayer
      key={single.id}
      ref={player}
      url={source}
      width="100%"
      height="100%"
      controls={false}
      playsinline={true}
      loop={false}
      playing={status === 'playing'}
      progressInterval={1000}
      config={{
        file: {
          // forceHLS: chapter?.mimeType === 'video/hls',
          // captions disabled for now
          ...(chapter?.transcript?.url ?
            {
              tracks: [
                {
                  kind: 'subtitle',
                  src: mapTranscriptUrl(chapter.transcript.url),
                  srcLang: 'en',
                  default: false,
                  label: 'English',
                },
              ],
            } :
            {}),
          attributes: {
            poster,
            crossOrigin: 'anonymous',
          },
        },
      }}
      onReady={() => {
        if (autoplay) {
          // Once loaded, pause it unless we were already playing
          setStatus('playing');
        }
        setStatus(previousState.current || 'paused');
      }}
      onError={(e, data) => {
        if (data && !data?.fatal) {
          console.debug('non fatal error occured', e, data);
          return;
        }
        if (e.name === 'NotAllowedError') {
          console.debug('autoplay not allowed');
          setStatus('paused');
          return;
        }
        console.error(e, data);
        // setStatus('error');
      }}
      onProgress={async (state) => {
        const seconds = round(state.playedSeconds);
        const percent = state.played * 100;
        if (seconds) {
          setTime(seconds, duration, true);
        }
        if (state.played === duration) {
          await trackMediaEvent('Media playback progressed', {
            play_percent: round(percent),
            play_seconds: seconds,
          });
          return;
        }
        if (state.played > 0) {
          await delayedTrack('Media playback progressed', {
            ...defaultEventProps(),
            play_percent: round(percent),
            play_seconds: seconds,
          });
        }
      }}
      onStart={async () => {
        await trackMediaEvent('Media playback started');
      }}
      onPlay={async () => {
        if (playSeconds > 0) {
          await trackMediaEvent('Media playback resumed');
        }
      }}
      onPause={async () => {
        await trackMediaEvent('Media playback paused');
      }}
      onEnded={async () => {
        await delayedTrack.flush();
        await trackMediaEvent('Media playback finished');
        // Go to the next chapter if there is one
        if (chapter && tracks && tracks.length > chapter.index + 1) {
          setTime(0, duration, true);
          setChapter?.(tracks[chapter.index + 1]);
          return;
        }
        // If auto play is turned on and there is a next single to play automatically switch to that single
        if (autoplay && nextAndPreviousSingles?.collectionNext) {
          update({
            s: nextAndPreviousSingles.collectionNext,
          });
          return;
        }

        // Don't try to upsell to affirmation card users.
        if (isOnClient()) {
          const params = new URLSearchParams(window.location.search);
          const productCode = params.get('product');
          if (productCode) {
            setStatus('finished');
            return;
          }
        }
        // Show the user subscription screen if they do not have an active subscription
        if (!session?.user?.hasActiveSubscription) {
          setStatus('finished');

          if (!frame) {
            unlockModal.open();
          }
          return;
        }

        // Show onboarding modal if they have not on boarded
        const completedOnboarding = await hasCompletedOnboarding();
        if (!completedOnboarding) {
          setStatus('finished');
          singleFinishedModal.open();
          return;
        }

        setStatus('finished');
      }}
    />
  );
};

type Props = {
  duration: number;
  chapter: Maybe<PlayableTrack>;
  single: SingleFragment;
  variant: Maybe<SingleVariantFragment>;
  playPercent: number;
  playSeconds: number;
  player: React.RefObject<ReactPlayer>;
  poster: Maybe<string>;
  setChapter: Maybe<React.Dispatch<React.SetStateAction<Maybe<PlayableTrack>>>>;
  nextAndPreviousSingles: Maybe<SinglesQueue>;
  setTime: (time: number, duration: number, statOnly?: boolean) => void;
  tracks: Maybe<Array<PlayableTrack>>;
  frame: Maybe<boolean>;
  bundleId?: string;
  cueRef?: React.RefObject<HTMLDivElement>;
  previousState: React.MutableRefObject<Maybe<'playing' | 'paused'>>;
};
