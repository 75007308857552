import {Maybe} from '@mindfulness/utils/maybe';
import style from './SingleProgressBar.module.css';

export const SingleProgressBar: React.FC<{
  percent: Maybe<number>;
  location?: 'card';
}> = ({percent, location}) => {
  return (
    <>
      {percent && percent < 90 ? (
        <div className={[
          style.progressBarContainer,
          ...location === 'card' ? [
            style.card,
          ] : [],
        ].join(' ')}>
          <div className={style.progressBar} style={{
            width: `${percent}%`,
          }} />
        </div>
      ) : null}
    </>
  );
};
