import * as React from 'react';
import {CardElement} from '@stripe/react-stripe-js';

import {InputWrapper} from '../Input/Input.styles';

import {Maybe} from '@mindfulness/utils/maybe';
import {Label} from '../../typography';
import dynamic from 'next/dynamic';

const DynamicCardElement = dynamic(() =>
  import('@stripe/react-stripe-js').then(() => CardElement),
);

export const CardInput: React.FC<Props> = ({label, error, id, onChange}) => {
  return (
    <InputWrapper error={Boolean(error)}>
      <Label htmlFor={id}>{label}</Label>
      <DynamicCardElement
        id={id}
        onChange={onChange}
        options={{
          hidePostalCode: true,
          style: {
            base: {
              'fontFamily': 'Moderat',
              'fontSize': '16px',
              'fontSmoothing': 'antialiased',
              '::placeholder': {
                color: '#949494',
              },
            },
          },
        }}
      />
    </InputWrapper>
  );
};

type Props = {
  id: string;
  label: string;
  validator?: (
    e: React.ChangeEvent<HTMLInputElement>['target']['value']
  ) => Maybe<string>;
  error?: string;
  onChange?: () => void;
};
