
import {createElement, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import styles from './H4.module.css';
import styleUtils from 'shared/styles/utils.module.css';
import {getResponsiveClassNames} from 'shared/utils';
import {HeadingProps} from '../Headings';

export const H4 = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLHeadingElement> & HeadingProps>>(({
  children, className, style,
  as = 'div',
  spacing = 0,
  colour,
  size = 'md',
  weight = 'regular',
  prose,
  transform,
  hide,
  gutter = [30, 16],
  lineHeight = '120%',
  ...props
}, ref) => {
  return createElement(as, {
    className: [
      styles.titleLarge,
      ...(prose ? [
        styles.prose,
        styleUtils[`pl-${gutter[0]}`],
        styleUtils[`pr-${gutter[0]}`],
      ] : []),
      ...getResponsiveClassNames(spacing, 'mb'),
      styleUtils[`font-weight-${weight}`],
      ...getResponsiveClassNames(typeof size === 'object' ? size : {xs: 'md', md: size}, 'font-size'),
      ...(hide ? [styleUtils[`hidden-${hide[1]}-${hide[0]}`]] : []),
      ...(colour ? [styleUtils[`text-${colour}`]] : []),
      ...(className ? [className] : []),
    ].join(' '),
    style: {
      'line-height': lineHeight,
      'text-transform': transform,
      ...style,
    },
    ...props,
    ref,
  }, children);
});


H4.displayName = 'H4';
