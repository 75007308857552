import {Theme} from '@emotion/react';
import styled from '@emotion/styled';

export const ModalWrapper = styled.div<{
  open: boolean;
}>(
    ({open}) => `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(55, 55, 75, 0.6);
  transition: opacity ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  ${
    open ?
      `
    opacity: 1;
    pointer-events: all;
  ` :
      `
    opacity: 0;
    pointer-events: none;
  `
}
`,
);

export const ModalOuter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

export const ModalInner = styled.div<{
  padding: keyof Theme['spacings'];
}>(
    ({theme, padding}) => `
    background-color: ${theme.colors.white};
    z-index: 1;
    position: relative;
    padding: ${theme.spacings[16]};

    @media (max-width: ${theme.breakpoints.md.max}) {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: ${theme.breakpoints.md.min}) {
      padding: ${theme.spacings[padding]};
      overflow: hidden;
      border-radius: ${theme.radius.lg};

    }

`,
);

export const CloseButtonWrapper = styled.div<{
  show: boolean;
}>(
    ({theme, show}) => `
position: absolute;
right: -${theme.spacings[4]};
top: -${theme.spacings[4]};
${
  show ?
    '' :
    `

  @media (min-width: ${theme.breakpoints.md.min}) {
    display: none;
  }
`
}

`,
);
