import {gql} from '@apollo/client';

export const SUBMIT_QUESTIONSHEET = gql`
  mutation submitQuestionSheet($id: ID!, $answers: [QuestionAnswerInput!]) {
    submitQuestionSheet(id: $id, answers: $answers) {
        __typename
        deeplink
        question
    }
  }
`;
