import {useEffect} from 'react';
import {defaultEventProps} from '../utils';
import {useTrack} from '../components/global/SegmentProvider';

let time = 0;
export const usePageTimeTracking = () => {
  const track = useTrack();
  useEffect(() => {
    const interval = setInterval(() => {
      time += 30;
      track('Page time increased', {
        time,
        ...defaultEventProps(),
      });
    }, 30 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [track]);
};
