import {useCallback, useMemo} from 'react';
import {useWindowWidth} from './useWindowWidth';
import {breakpoints} from '../theme/breakpoints';
import {ResponsiveObj, ResponsiveValue} from '../types/types';

/**
 * Get the current breakpoint
 * @return {Object}
 */
export const useBreakpoint = () => {
  const width = useWindowWidth();
  const keys = useMemo(
      () =>
      Object.keys(breakpoints).reverse() as Array<keyof ResponsiveObj<number>>,
      [],
  );

  /**
   * Get the current breakpoint name from the screen width
   */
  const breakpoint = useMemo(
      () =>
        keys.find((key) => {
          const minInt = Number(breakpoints[key].min.replace('px', ''));
          return (width ?? 0) > minInt;
        }),
      [width, keys],
  );

  /**
   * Takes a ResponsiveValue object which may not have each breakpoint defined.
   * If the object does not have a breakpoint for the current screen width,
   * we find the next (smaller) until it does exist.
   */
  const nextBreakpoint = useCallback(
      (value: ResponsiveObj<number>) => {
        if (breakpoint) {
          keys.slice(keys.indexOf(breakpoint), keys.length);
          return keys.find((val) => value[val]);
        }
      },
      [breakpoint, keys],
  );

  const getResponsiveValue = useCallback(
      (value: ResponsiveValue<number>) => {
        if (!value) return 0;
        if (typeof value === 'object') {
          const val = value as ResponsiveObj<number>;
          const next = nextBreakpoint(val);
          if (next) {
            return val[next] as number;
          }
          return 0;
        }
        return value as number;
      },
      [nextBreakpoint],
  );

  return {breakpoint, nextBreakpoint, getResponsiveValue};
};
