import {switchEnum} from '@mindfulness/utils/logic';
import {Maybe} from '@mindfulness/utils/maybe';

export const purchaseIds = (
    planAlias: string,
    trial: boolean,
    type: Maybe<string>,
) => {
  if (type === 'bogo' || type === 'gift') {
    return;
  }
  // if (type === 'gift') {
  //   return switchEnum(planAlias, {
  //     lifetime_2: '7c01d824-bfb5-487a-8e4d-60f70be042e3',
  //     annual_3: '98d60784-3bad-453e-b2fa-d84905bd05e6',
  //     else: undefined,
  //   });
  // }
  if (trial) {
    return switchEnum(planAlias, {
      monthly_2: '226e81f3-d2e2-4e7c-a9d8-2bcc5ade70ae',
      lifetime_2: '17776e3f-bdb5-4972-bc33-08abeb184cbd',
      annual_3: '34754bbc-b0c5-4e76-b254-94d9abce8d9f',
      else: undefined,
    });
  }
  return switchEnum(planAlias, {
    price_1NQiZGL3D9fRmvsQFvi12nRu: '01c0650b-ee22-4e7f-b3c0-447fa10e3a81',
    monthly_2: '269ebfdd-377d-470f-8b08-df35da779fc7',
    lifetime_2: '9708edd5-4153-40fa-9e92-8ca36a309bc8',
    annual_3: 'b1583a0a-efb3-4dd8-af79-804573781333',
    else: undefined,
  });
};
