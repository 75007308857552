import * as React from 'react';
import {
  SectionText,
  Collection as CollectionProps,
  SingleHighlight as SingleHighlightProps,
  SingleInlinePlayer as SingleInlinePlayerProps,
  SinglePlayer as SinglePlayerProps,
} from '@mindfulness/cms';
import {PortableText} from '@portabletext/react';
import {Theme} from '@emotion/react';

import {Link, DynamicImage, NewLink} from '../../global';
import {
  ArticleQuote,
  H1,
  H2,
  H4,
  OrderedList,
  OrderedListItem,
  Strong,
  Text,
  Title3,
  UnorderedList,
  UnorderedListItem,
} from '../../typography';

import {Container} from '../../layout';
import {
  ColoursGradients,
  Maybe,
  ResponsiveValue,
  EnrichedHeroPlanPicker,
  EnrichedPlanPicker,
  ReferencedArticleFeed,
  ReferencedArticleReel,
  ReferencedContentFeature,
  ReferencedDeviceGallery,
  ReferencedDeviceTestimonials,
  ReferencedFAQs,
  ReferencedGallery,
  ReferencedHubGrid,
  ReferencedHubMarquee,
  ReferencedTeacherFeed,
  ReferencedTestimonialGrid,
  ReferencedTestimonialMarquee,
  SingleDetailFragment,
  SingleFragment,
} from '../../../types/types';

import {BlockContentContainer, StyledAnchor} from './BlockContent.styles';
import {
  ArticleFeed,
  ArticleReel,
  Banner,
  Benefits,
  BrandMission,
  Button,
  Collection,
  ContactForm,
  ContentFeature,
  DeviceGallery,
  DeviceGalleryNew,
  DeviceSideLayout,
  DeviceTestimonials,
  DevicesWithBullets,
  FAQs,
  Gallery,
  HeroBanner,
  HeroCountdown,
  HeroCurved,
  HeroEvent,
  HeroHome,
  HeroHub,
  HeroImageSide,
  HeroPlain,
  HeroPlanPicker,
  HeroRoundImage,
  HeroThankYou,
  HomeIllustration,
  HubGrid,
  HubMarquee,
  ImageSide,
  MediaOverlap,
  Paragraphs,
  PlanPicker,
  Quote,
  QuoteCarousel,
  Schedule,
  Share,
  SignupCover,
  SingleHighlight,
  SingleInlinePlayer,
  SinglePlayer,
  SocialBanner,
  Stats,
  Subscribe,
  TeacherFeed,
  TestimonialGrid,
  TestimonialMarquee,
  TestimonialMarqueeCurated,
  TextImageOverlay,
  TextOnImage,
  ThankYouCover,
  Typeform,
  Video,
} from '../../sections';
import {CollectionFeedFragment} from '../../../types/api';

export const BlockContent: React.FC<Props> = ({
  content,
  background,
  container = true,
  mobile,
  ...baseFontProps
}) => (
  <BlockContentContainer {...baseFontProps}>
    <PortableComponents
      content={content}
      background={background}
      container={container}
      mobile={mobile}
      {...baseFontProps}
    />
  </BlockContentContainer>
);

const PortableComponents: React.FC<Props> = ({
  content,
  background,
  container = true,
  mobile,
}) =>
  content ? (
    <PortableText
      value={content}
      components={{
        block: {
          h1: function BlockH1(props) {
            return (
              <H1 spacing={16} as="h2" prose={!container}>
                {props.children}
              </H1>
            );
          },
          h2: function BlockH2(props) {
            return (
              <H2 size="3xl" spacing={16} weight="bold" prose={!container}>
                {props.children}
              </H2>
            );
          },
          h3: function BlockH3(props) {
            return (
              <Title3 size="2xl" spacing={16} weight="bold" prose={!container}>
                {props.children}
              </Title3>
            );
          },
          h4: function BlockH4(props) {
            return (
              <H4 size="xl" spacing={16} weight="bold" prose={!container}>
                {props.children}
              </H4>
            );
          },
          blockquote: function BlockBlockQuote(props) {
            return (
              <ArticleQuote prose={!container}>{props.children}</ArticleQuote>
            );
          },
          normal: function BlockText(props) {
            return (
              <Text spacing={20} prose={!container}>
                {props.children}
              </Text>
            );
          },
        },
        types: {
          image: function Figure(props) {
            return props.value.image ? (
              <Container gutter={0} maxWidth="prose" spacing={40}>
                <DynamicImage image={props.value} />
              </Container>
            ) : null;
          },
          figure: function Figure(props) {
            return props.value.image ? (
              <Container gutter={0} maxWidth="prose" spacing={40}>
                <DynamicImage image={props.value} />
              </Container>
            ) : null;
          },
          ArticleFeed: (props) => {
            return (
              <ArticleFeed
                {...(props.value as unknown as ReferencedArticleFeed)}
                index={1}
              />
            );
          },
          ArticleReel: ({value}) => {
            return (
              <ArticleReel
                {...(value as unknown as ReferencedArticleReel)}
                index={1}
              />
            );
          },
          Banner: ({value}) => {
            return <Banner {...value} index={1} />;
          },
          Benefits: ({value}) => {
            return <Benefits {...value} index={1} />;
          },
          BrandMission: ({value}) => {
            return <BrandMission {...value} index={1} />;
          },
          Button: ({value}) => {
            return <Button {...value} index={1} />;
          },
          Collection: ({value}) => {
            return (
              <Collection
                {...(value as unknown as CollectionProps &
                  CollectionFeedFragment)}
                index={1}
              />
            );
          },
          ContactForm: ({value}) => {
            return <ContactForm {...value} index={1} />;
          },
          ContentFeature: ({value}) => {
            return (
              <ContentFeature
                {...(value as ReferencedContentFeature)}
                index={1}
              />
            );
          },
          DeviceGallery: ({value}) => {
            return (
              <DeviceGallery
                {...(value as unknown as ReferencedDeviceGallery)}
                index={1}
              />
            );
          },
          DeviceGalleryNew: ({value}) => {
            return <DeviceGalleryNew {...value} index={1} />;
          },
          DeviceSideLayout: ({value}) => {
            return <DeviceSideLayout {...value} index={1} />;
          },
          DevicesWithBullets: ({value}) => {
            return <DevicesWithBullets {...value} index={1} />;
          },
          DeviceTestimonials: ({value}) => {
            return (
              <DeviceTestimonials
                {...(value as ReferencedDeviceTestimonials)}
                index={1}
              />
            );
          },
          FAQs: ({value}) => {
            return <FAQs {...(value as ReferencedFAQs)} index={1} />;
          },
          Gallery: ({value}) => {
            return <Gallery {...(value as ReferencedGallery)} index={1} />;
          },
          HeroBanner: ({value}) => {
            return <HeroBanner {...value} index={1} />;
          },
          HeroCountdown: ({value}) => {
            return <HeroCountdown {...value} index={1} />;
          },
          HeroCurved: ({value}) => {
            return <HeroCurved {...value} index={1} />;
          },
          HeroEvent: ({value}) => {
            return <HeroEvent {...value} index={1} />;
          },
          HeroHome: ({value}) => {
            return <HeroHome {...value} index={1} />;
          },
          HeroHub: ({value}) => {
            return <HeroHub {...value} index={1} />;
          },
          HeroImageSide: ({value}) => {
            return <HeroImageSide {...value} index={1} />;
          },
          HeroPlain: ({value}) => {
            return <HeroPlain {...value} index={1} />;
          },
          HeroPlanPicker: ({value}) => {
            return (
              <HeroPlanPicker
                {...(value as unknown as EnrichedHeroPlanPicker)}
                index={1}
              />
            );
          },
          HeroRoundImage: ({value}) => {
            return <HeroRoundImage {...value} index={1} />;
          },
          HeroThankYou: ({value}) => {
            return <HeroThankYou {...value} index={1} />;
          },
          HomeIllustration: ({value}) => {
            return <HomeIllustration {...value} index={1} />;
          },
          HubGrid: ({value}) => {
            return (
              <HubGrid {...(value as unknown as ReferencedHubGrid)} index={1} />
            );
          },
          HubMarquee: ({value}) => {
            return (
              <HubMarquee
                {...(value as unknown as ReferencedHubMarquee)}
                index={1}
              />
            );
          },
          ImageSide: ({value}) => {
            return <ImageSide {...value} index={1} />;
          },
          MediaOverlap: ({value}) => {
            return <MediaOverlap {...value} index={1} />;
          },
          Paragraphs: ({value}) => {
            return <Paragraphs {...value} index={1} />;
          },
          PlanPicker: ({value}) => {
            return (
              <PlanPicker
                {...(value as unknown as EnrichedPlanPicker)}
                index={1}
              />
            );
          },
          Quote: ({value}) => {
            return <Quote {...value} index={1} />;
          },
          QuoteCarousel: ({value}) => {
            return <QuoteCarousel {...value} index={1} />;
          },
          Schedule: ({value}) => {
            return <Schedule {...value} index={1} />;
          },
          Share: ({value}) => {
            return <Share {...value} index={1} />;
          },
          SignupCover: ({value}) => {
            return <SignupCover {...value} index={1} />;
          },
          SingleHighlight: ({value}) => {
            return (
              <SingleHighlight
                {...(value as unknown as SingleHighlightProps & SingleFragment)}
                index={1}
              />
            );
          },
          SingleInlinePlayer: ({value}) => {
            return (
              <SingleInlinePlayer
                {...(value as unknown as SingleInlinePlayerProps &
                  SingleFragment)}
                mobile={mobile}
                index={1}
              />
            );
          },
          SinglePlayer: ({value}) => {
            return (
              <SinglePlayer
                {...(value as unknown as SinglePlayerProps & {
                  richSingle: SingleDetailFragment;
                })}
                index={1}
              />
            );
          },
          SocialBanner: ({value}) => {
            return <SocialBanner {...value} index={1} />;
          },
          Stats: ({value}) => {
            return <Stats {...value} index={1} />;
          },
          Subscribe: ({value}) => {
            return <Subscribe {...value} index={1} />;
          },
          TeacherFeed: ({value}) => {
            return (
              <TeacherFeed
                {...(value as unknown as ReferencedTeacherFeed)}
                index={1}
              />
            );
          },
          TestimonialGrid: ({value}) => {
            return (
              <TestimonialGrid
                {...(value as ReferencedTestimonialGrid)}
                index={1}
              />
            );
          },
          TestimonialMarquee: ({value}) => {
            return <TestimonialMarquee {...value} index={1} />;
          },
          TestimonialMarqueeCurated: ({value}) => {
            return (
              <TestimonialMarqueeCurated
                {...(value as ReferencedTestimonialMarquee)}
                index={1}
              />
            );
          },
          TextImageOverlay: ({value}) => {
            return <TextImageOverlay {...value} index={1} />;
          },
          TextOnImage: ({value}) => {
            return <TextOnImage {...value} index={1} />;
          },
          ThankYouCover: ({value}) => {
            return <ThankYouCover {...value} index={1} />;
          },
          Typeform: ({value}) => {
            return <Typeform {...value} index={1} />;
          },
          Video: ({value}) => {
            return <Video {...value} index={1} />;
          },
        },
        list: {
          bullet: function BulletList({children}) {
            return <UnorderedList prose={!container}>{children}</UnorderedList>;
          },
          tick: function TickList({children}) {
            return <UnorderedList prose={!container}>{children}</UnorderedList>;
          },
          number: function BulletList({children}) {
            return <OrderedList prose={!container}>{children}</OrderedList>;
          },
        },
        listItem: function BlockListItem(props) {
          return props.value.listItem === 'bullet' ? (
            <UnorderedListItem background={background}>
              {props.children}
            </UnorderedListItem>
          ) : props.value.listItem === 'tick' ? (
            <UnorderedListItem tick background={background}>
              {props.children}
            </UnorderedListItem>
          ) : (
            <OrderedListItem>{props.children}</OrderedListItem>
          );
        },
        marks: {
          strong: function MarksStrong({children}) {
            return <Strong>{children}</Strong>;
          },
          em: function MarksEm({children}) {
            return <em>{children}</em>;
          },
          code: function MarksCode({children}) {
            return <code>{children}</code>;
          },
          internalLink: function MarksInternalLink({value, children}) {
            const {slug = {}} = value;
            if (!slug) {
              return <span>{children}</span>;
            }
            const href = `/${slug?.current || ''}`;
            return (
              <Link href={href} passHref>
                <StyledAnchor background={background || 'white'}>
                  {children}
                </StyledAnchor>
              </Link>
            );
          },
          internalUrl: function MarksInternalUrl({value, children}) {
            const {href} = value;
            return (
              <StyledAnchor
                background={background || 'white'}
                as={(props) => <NewLink {...props} href={href} />}
              >
                {children}
              </StyledAnchor>
            );
          },
          link: function MarksExternalLink({value, children}) {
            const {href} = value;
            return (
              <StyledAnchor
                href={href}
                background={background || 'primary'}
                target="_blank"
                rel="noopener noreferrer"
              >
                {children}
              </StyledAnchor>
            );
          },
        },
      }}
    />
  ) : null;

type Props = {
  content: Maybe<SectionText>;
  background: Maybe<ColoursGradients>;
  fontSize?: ResponsiveValue<keyof Theme['fontSizes']>;
  colour?: Maybe<keyof Theme['colors']>;
  fontWeight?: keyof Theme['fontWeights'];
  container?: boolean;
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  mobile?: boolean;
};
