import {breakpoints} from './breakpoints';
import {colors} from './colors';
import {fontSizes} from './fontSizes';
import {fontWeights} from './fontWeights';
import {gradients} from './gradients';
import {radius} from './radius';
import {shadows} from './shadows';
import {sizes} from './sizes';
import {spacings} from './spacings';

export const theme = {
  breakpoints,
  colors,
  fontSizes,
  fontWeights,
  gradients,
  radius,
  shadows,
  sizes,
  spacings,
};
