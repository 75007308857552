import {
  ReferencedResource,
  ReferencedResources,
  SectionIndex,
} from '../../../types/types';
import {Column, Container, Flex, Row, Section} from '../../layout';
import {Text, Title3} from '../../typography';
import {BasicImage, Icon} from '../../global';
import {assertString} from '../../../utils';

import {useLockedBundle} from '../../../hooks/useLockedBundle';

import {
  SanityReferenceProps,
  withSanityReference,
} from '../../../hoc/withSanityReference';
import {HoverCard} from './Resources.styled';

export const Resources: React.FC<SectionIndex<ReferencedResources>> = ({
  resources,
  title,
}) => {
  const {isLockedBundle, accessAction} = useLockedBundle();

  return (
    <Section padding={0}>
      <Container>
        <Row spacing={16}>
          <Column>
            <Title3 weight="bold">{title || 'Resources'}</Title3>
          </Column>
        </Row>
        <Row gap={16}>
          {resources?.map((props) => {
            return (
              <Column
                span={{xs: 24, md: 8}}
                spacing={{
                  xs: 20,
                  md: 0,
                }}
                key={props._key}
              >
                <ResourceCard
                  {...props}
                  type={props.linkType}
                  target={props.target}
                  url={assertString(props.url)}
                  {...(isLockedBundle ?
                    {
                      onClick: () => accessAction(),
                    } :
                    {})}
                />
              </Column>
            );
          })}
        </Row>
      </Container>
    </Section>
  );
};

const ResourceCard = withSanityReference<
  ReferencedResource & SanityReferenceProps
>(({icon, title}) => (
  <HoverCard shadow="small" justify="space-between" direction="row">
    <Flex gap={8}>
      <BasicImage decoration="none" image={icon} width={30} height={30} />
      <Text fontWeight="bold">{title}</Text>
    </Flex>

    <Icon name="external-resource" size={24} />
  </HoverCard>
));
