import {onClient} from '../../../utils/next';
import {useTrack} from '../SegmentProvider';
import style from './AccountDropdown.module.css';
export const SignInButton: React.FC = () => {
  const track = useTrack();
  return (
    <button
      className={style.accountButton}
      type="button"
      onClick={() => {
        track('Button clicked', {
          button_id: 'SignIn',
          button_text: 'Sign In',
          path: window.location.pathname,
        });

        onClient(() => {
          window.location.href = `/login?onto=${window.location.pathname}&view=login`;
        });
      }}
    >
      Sign in
    </button>
  );
};
