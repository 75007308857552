import {atom} from 'recoil';
import {
  defaultOnboardingSteps,
  onboardingSteps,
} from '../components/templates/Onboarding/Onboarding.effects';
import {createKeyedObject} from '../utils';
import {
  cleanCartEffect,
  debugEffect,
  localStorageEffect,
  routeEffect,
  sessionStorageEffect,
} from './effects';
import {Maybe, PlayerStatus, Steps} from '../types/types';

/**
 * Onboarding atoms
 */
export const onboardingStepsState = atom<Array<Steps>>({
  key: 'steps',
  default: defaultOnboardingSteps,
  effects: [localStorageEffect('atomstate:steps', true), debugEffect('steps')],
});

export const onboardingStepState = atom<Steps>({
  key: 'step',
  default: defaultOnboardingSteps[0],
  effects: [
    localStorageEffect('atomstate:step', false),
    routeEffect((step) => `/get-started/${step}`),
    debugEffect('step'),
  ],
});

export const onboardingSelectionsState = atom<Record<Steps, Array<string>>>({
  key: 'selections',
  default: createKeyedObject<Steps, Array<string>>(onboardingSteps, []),
  effects: [
    localStorageEffect('atomstate:selections', true),
    debugEffect('selections'),
  ],
});

/**
 * Player atoms
 */
export const playerAutoPlayState = atom<boolean>({
  key: 'autoplay',
  default: false,
  effects: [
    localStorageEffect('atomstate:autoplay', true),
  ],
});

export const playerPlayState = atom<PlayerStatus>({
  key: 'playerState',
  default: 'loading',
});
export const playerTranscripts = atom<boolean>({
  key: 'playerTranscripts',
  default: false,
  effects: [
    localStorageEffect('atomstate:autoplay', true),
  ],
});
/**
 * Cart atoms
 */
export const orderIdState = atom<Maybe<string>>({
  key: 'orderId',
  default: undefined,
  effects: [localStorageEffect('atomstate:orderId', true)],
});

export const orderItemsState = atom<Array<string>>({
  key: 'orderItems',
  default: [],
  effects: [
    localStorageEffect('atomstate:orderItems', true),
    cleanCartEffect(),
  ],
});

/**
 * Redirect atom
 */
export const redirectState = atom<Maybe<string>>({
  key: 'redirect',
  default: undefined,
  effects: [localStorageEffect('atomstate:onto', true)],
});

/**
 * History state
 */
export const historyState = atom<string>({
  key: 'history',
  default: undefined,
  effects: [sessionStorageEffect('atomstate:history', true)],
});
