import * as React from 'react';
import {BackgroundAPIImage} from '../../global';
import {Box} from '../../layout';
import {Text, Title3} from '../../typography';
import {AspectRatio} from '../../layouts/AspectRatio';

import style from './TopicCard.module.css';

export const TopicCard: React.FC<{
  title?: string;
  subtitle?: string;
  url: string;
}> = ({title, subtitle, url}) => (
  <div className={style.itemContainer}>
    <AspectRatio ratio={'20:13'}>
      <Box position="relative">
        <BackgroundAPIImage src={url} className={style['cell-image']} zIndex={0} />
        <Box
          padding={16}
          display="flex"
          flexDirection="column"
          items="center"
          justify="center"
          zIndex={2}
          height="100%"
          position="relative"
          textAlign="center"
          color="white"
        >
          {subtitle && <Text>{subtitle}</Text>}
          {title && (
            <Title3 whitespace="nowrap" weight="bold">
              {title}
            </Title3>
          )}
        </Box>
      </Box>
    </AspectRatio>
  </div>
);
