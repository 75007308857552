import {jsonLdScriptProps} from 'react-schemaorg';
import {BreadcrumbList, ListItem} from 'schema-dts';
import {BreadcrumbItems} from '../types/types';

export const breadcrumbSchema = ({items}: { items: BreadcrumbItems }) =>
  jsonLdScriptProps<BreadcrumbList>({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': items.map(({title, link}, index) => ({
      '@type': 'ListItem',
      'position': index + 1,
      'item': {
        '@id': link,
        'name': title,
      } as ListItem,
    })),
  });
