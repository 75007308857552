import * as React from 'react';
import {isMultipleOf} from '@mindfulness/utils/math';

import {HubLink} from './HubGrid.styles';
import {H2, Title3, Text} from '../../typography';
import {BackgroundImage, BlockContent} from '../../global';
import {
  Container,
  Row,
  Column,
  Grid,
  Box,
} from '../../layout';
import {ReferencedHubGrid, SectionIndex} from '../../../types/types';
import {TopicCard} from '../../ui';
import {Section} from '../../layouts/Section';
import {AspectRatio} from '../../layouts/AspectRatio';

export const HubGrid: React.FC<SectionIndex<ReferencedHubGrid>> = ({
  title,
  paragraph,
  hubs = [],
  background,
  paddingBottom,
  paddingTop,
}) => (
  <Section
    background={background}
    padding={{
      xs: 80,
      md: 160,
    }}
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
  >
    <Container maxWidth="lg">
      <Row justify="center" spacing={40}>
        <Column
          textAlign="center"
          span={{
            xs: 24,
            md: 20,
          }}
        >
          <H2 spacing={10}>{title}</H2>
          {paragraph ? (
            <BlockContent
              fontSize="md"
              content={paragraph}
              colour="grey8"
              background="white"
            />
          ) : null}
        </Column>
      </Row>
      <Grid
        cols={{
          xs: 2,
          md: isMultipleOf(hubs.length, 3) ? 3 : 2,
        }}
        rowGap={{
          xs: 8,
          md: 24,
        }}
        columnGap={{
          xs: 8,
          md: 24,
        }}
      >
        {hubs.map(({title, topic, hubImage, noHub, slug}) =>
          slug?.current ? (
            <HubLink
              href={`${slug.current}${noHub ? '/browse' : ''}`}
              key={slug.current}
            >
              {hubImage ? (
                <AspectRatio ratio={'20:13'}>
                  <Box position="relative">
                    <BackgroundImage image={hubImage} className="image" />
                    <Box
                      padding={16}
                      display="flex"
                      items="center"
                      zIndex={1}
                      height={'100%'}
                      textAlign="center"
                    >
                      {topic?.subtitle && <Text>{topic?.subtitle}</Text>}
                      <Title3 whitespace="nowrap" colour="white" weight="bold">
                        {title}
                      </Title3>
                    </Box>
                  </Box>
                </AspectRatio>
              ) : (
                <TopicCard
                  title={title}
                  subtitle={topic?.subtitle}
                  url={topic?.coverImage?.url || `/images/solution-anxiety.png`}
                />
              )}
            </HubLink>
          ) : null,
        )}
      </Grid>
    </Container>
  </Section>
);
