import * as React from 'react';
import {isMobile} from 'react-device-detect';

import {Context, ButtonLink} from '../../global';
import {Button} from '../../forms';
import {ReferencedCtaButton, ResponsiveValue} from '../../../types/types';
import {InlineActionForm} from '../../ui';
import {assertString} from '../../../utils';
import {ButtonAnchor} from '../ButtonLink/ButtonAnchor';

export const CallToActionButton: React.FC<
  React.PropsWithChildren<
    Omit<ReferencedCtaButton, '_type'> & {
      id: string;
      className?: string;
      size?: 'sm' | 'xs';
      inlineColor?: 'white' | 'default';
      wide?: ResponsiveValue<boolean>;
    }
  >
> = ({
  actionType,
  url,
  target,
  style,
  slug,
  inlineForm,
  inlineColor,
  ...props
}) => {
  const {handlePageAction, data, loadingPageAction, hydratedPurchases} =
    React.useContext(Context);
  if (
    inlineForm &&
    ['joinABundle', 'joinACourse', 'addToCohort'].includes(
        data?.action?.action ?? '',
    )
  ) {
    return (
      <InlineActionForm
        actionType={actionType}
        color={inlineColor}
        {...props}
      />
    );
  }

  if (inlineForm && data?.action?.action === 'downloadApp' && !isMobile) {
    return (
      <InlineActionForm
        actionType={actionType}
        color={inlineColor}
        {...props}
      />
    );
  }

  if (actionType === 'reference' && slug?.current) {
    return <ButtonLink href={slug.current} variant={style} {...props} />;
  } else if (actionType === 'external') {
    return (
      <ButtonAnchor
        {...props}
        variant={style}
        loading={loadingPageAction}
        href={url} target="_blank" rel="noopener noreferrer"
      />
    );
  } else if (actionType === 'internal') {
    return (
      <ButtonLink
        href={assertString(url)}
        variant={style}
        loading={loadingPageAction}
        {...props}
      />
    );
  }

  const loadingPurchase =
    data?.action?.action === 'purchase' &&
    data.action.purchase &&
    !hydratedPurchases?.length;
  return (
    <Button
      {...props}
      shareUrl={url}
      variant={style}
      action={actionType}
      loading={loadingPageAction || loadingPurchase}
      onClick={() => {
        handlePageAction?.();
      }}
    >
      {<>{loadingPurchase ? 'Loading...' : props.children}</>}
    </Button>
  );
};
