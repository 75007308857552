import * as React from 'react';
import dynamic from 'next/dynamic';
import {usePathname} from 'next/navigation';
import {Maybe, isUndefined} from '@mindfulness/utils/maybe';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import {
  LogoAnchor,
  DrawerButton,
  Nav,
  NavItem,
  NavLabel,
  StyledHeader,
  OffsetWrapper,
} from './Header.styles';
import {AnnouncementBar} from './AnnouncementBar';
import {AccountDropdown} from './AccountDropdown';

import {Logo} from '../../brand';
import {Container, Row, Box, Stack, Column} from '../../layout';
import {loggedInItems} from '../../../utils';

import {
  Link,
  CallToActionButton,
  Context,
  ModalContext,
  Icon,
} from '../../global';
import {
  ReferencedNavigation,
  ReferencedNavigationMegaMenu,
  ReferencedSideNavigation,
  ReferencedSiteSettings,
} from '../../../types/types';
import {Button} from '../../forms';
import {useAsMemo, useModal, useScrolled} from '../../../hooks';
import {DiscountBar} from '../DiscountProvider/DiscountBar';
import {DiscountContext} from '../DiscountProvider';
import {Strong, Text} from '../../typography/Text';
import {withLink} from '../../../hoc/withLink';
import {useTrack} from '../SegmentProvider';
import {SignInButton} from './SignInButton';
import {useNewNavigation} from '../../../hooks/useNewNavigation';
import {VerticalDivider} from '../../ui/VerticalDivider';
import {ButtonAnchor} from '../ButtonLink/ButtonAnchor';

const Drawer = dynamic(() => import('./Drawer').then(({Drawer}) => Drawer));

export const Header: React.FC<React.PropsWithChildren<Props>> = ({
  navigation,
  sideNavigation,
  site,
}) => {
  const track = useTrack();
  const pathname = usePathname();
  const {discount} = React.useContext(DiscountContext);
  const {modals, toggleModal} = React.useContext(ModalContext);
  const {data, session, disableAnnouncement} = React.useContext(Context);
  const headerRef = React.useRef<HTMLDivElement>(null);

  const header = useAsMemo(data?.header);
  const cta = useAsMemo(header?.cta);
  const scrolled = useScrolled(header?.disableScroll ? -1 : 50);
  const announcement = React.useMemo(() => {
    return header?.announcement || site?.announcement;
  }, [site, header]);

  const open = React.useMemo(() => modals.includes('drawer'), [modals]);
  const hasAnnouncement = React.useMemo(
      () => {
        if (process.env.MINDFULNESS_SPACE !== 'mindfulness.com') {
          return false;
        }
        return announcement?.enabled &&
        !header?.hideAnnouncement &&
        !disableAnnouncement && isUndefined(session);
      },
      [announcement?.enabled, header?.hideAnnouncement, session],
  );

  const showTrialBar = React.useMemo(() => {
    const endsAt = session?.user?.accessPassEndsAt;
    if (endsAt) {
      const now = dayjs();
      const end = dayjs(endsAt);
      const hasActiveAccessPass = end.isBefore(now);
      return session && !session.user?.hasActiveSubscription && !hasActiveAccessPass;
    };
    return session && !session.user?.hasActiveSubscription;
  }, [session]);

  return (
    <>
      <OffsetWrapper
        header={!header?.hide}
        minimal={header?.minimal}
        announcement={hasAnnouncement}
      />
      <StyledHeader
        minimal={header?.minimal}
        scrolled={scrolled || open}
        ref={headerRef}
      >
        {discount ? (
          <DiscountBar />
        ) : (<>
          {process.env.MINDFULNESS_SPACE !== 'mindfulness.com' ? null : (
            <>
              {session && pathname.startsWith('/feed/') ? (
                <>
                  {showTrialBar ? (
                    <TrialBar href="/checkout?products=34754bbc-b0c5-4e76-b254-94d9abce8d9f" />
                  ) : null}
                </>
              ) : (
                hasAnnouncement &&
                announcement && <AnnouncementBar {...announcement} />
              )}
            </>
          )}
        </>)}
        {header?.hide ? null : (
          <Container>
            <Row>
              <Column span={{xs: 15, xl: 12}} items="center">
                <Box
                  display={{
                    xs: 'block',
                    xl: 'none',
                  }}
                  paddingR={10}
                >
                  <DrawerButton
                    onClick={() => {
                      track('Button clicked', {
                        button_id: 'MobileDrawer',
                        button_text: 'Open menu',
                        path: window.location.pathname,
                      });

                      toggleModal?.({name: 'drawer'});
                    }}
                    aria-label="Open menu"
                    id="OpenMenu"
                    color={
                      header?.minimal && !scrolled && !open ? 'white' : 'grey9'
                    }
                  >
                    {open ? <Icon name="close" size={30} /> : <Icon name="menu" size={30} />}
                  </DrawerButton>
                </Box>
                <Link href="/" passHref>
                  <LogoAnchor aria-label="Mindfulness.com homepage">
                    <Logo
                      view={
                        header?.minimal && !scrolled && !open ? 'light' : 'dark'
                      }
                    />
                  </LogoAnchor>
                </Link>
                <MainMenu navigation={navigation} />
              </Column>
              <Column
                span={{
                  xs: 0,
                  xl: 12,
                }}
                items="center"
                justify="end"
              >
                {data?._type === 'pages' && data?.type === 'thank-you' ? (
                  <>
                    <Stack direction="horizontal" space={12}>
                      <ButtonAnchor
                        variant="secondary"
                        id="headerHelp"
                        href="https://help.mindfulness.com/en/collections/2230903-getting-started"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Need Help?
                      </ButtonAnchor>
                      {session ? <AccountDropdown /> : null}
                    </Stack>
                  </>
                ) : (
                  <>
                    {header?.minimal ? (
                      <CallToActionWrapper />
                    ) : (
                      <>
                        <SideMenu sideNavigation={sideNavigation} />
                        <CallToActionWrapper />
                      </>
                    )}
                  </>
                )}
              </Column>
              <Column
                justify="end"
                items="center"
                span={{
                  xs: 9,
                  xl: 0,
                }}
              >
                {data?._type === 'pages' && data?.type === 'thank-you' ? (
                  <>
                    <Stack direction="horizontal" space={12}>
                      <ButtonAnchor
                        variant="secondary"
                        size="xs"
                        id="headerHelp"
                        href="https://help.mindfulness.com/en/collections/2230903-getting-started"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Need Help?
                      </ButtonAnchor>
                    </Stack>
                  </>
                ) : session ? null : (
                  <CallToActionButton
                    {...cta}
                    className="cta-button"
                    size="xs"
                    id="MobileHeader"
                  >
                    {cta?.text || 'Try for Free'}
                  </CallToActionButton>
                )}
              </Column>
            </Row>
          </Container>
        )}
      </StyledHeader>
      {header?.hide ? null : (
        <Drawer
          announcement={hasAnnouncement}
          navigation={navigation}
          sideNavigation={sideNavigation}
        />
      )}
    </>
  );
};

const CallToActionWrapper: React.FC = () => {
  const {session, data} = React.useContext(Context);
  const text = React.useMemo(() => {
    if (
      session &&
      ![
        'purchaseGiftCard',
        'purchaseSubscription',
        'purchaseBogo',
        'purchaseEvent',
        'addToCohort',
        'purchase',
        'locked',
      ].includes(data?.action?.action || 'downloadApp')
    ) {
      return 'Open the App';
    }
    return data?.header?.cta?.text || 'Try for Free';
  }, [session, data]);

  return (
    <Box paddingX={12} className="cta-wrapper" height="100%">
      <Stack direction="horizontal" space={26} tall items="center">
        {session ? (
          <>
            <VerticalDivider />
            <AccountDropdown />
          </>
        ) : (
          <>
            {process.env.MINDFULNESS_SPACE === 'mindfulness.com' ? (
              <>
                <SignInButton />
                <CallToActionButton
                  {...data?.header?.cta}
                  className="cta-button"
                  size="sm"
                  id="Header"
                >
                  {text}
                </CallToActionButton>
              </>
            ) : <SignInModalButton />}

          </>
        )}
      </Stack>
    </Box>
  );
};


const MainMenu: React.FC<{ navigation: ReferencedNavigation }> = ({
  navigation,
}) => {
  const {asPath} = useNewNavigation();
  const {data, session} = React.useContext(Context);
  const href = React.useCallback(
      (url: Maybe<string>, target: ReferencedNavigationMegaMenu['target']) => {
        if (url) {
          return url;
        }

        if (target?.slug?.current) {
          if (target.noHub) {
            return `${target.slug.current}/browse`;
          }
          return target.slug.current;
        }
        return undefined;
      },
      [],
  );
  // @ts-ignore
  const type = React.useMemo(() => data?.type, [data]);

  return (
    <Nav>
      {!data?.header?.minimal && type !== 'thank-you' ? (
        <>
          {session ? (
            <>
              {loggedInItems.map(({label, link}) => {
                const active = link ?
                  link === '/' ? asPath === '/' :
                 asPath.includes(link) :
                 false;
                return (
                  <NavItem active={active} key={link}>
                    <Link href={link} passHref>
                      <NavLabel as="a">{label}</NavLabel>
                    </Link>
                  </NavItem>
                );
              })}
            </>
          ) : (
            navigation?.linklist?.map(({_key, target, ...props}) => {
              const active = props.link?.url ?
                asPath.includes(props.link?.url) :
                false;
              return (
                <React.Fragment key={_key}>
                  {href(props.link?.url, target) ? (
                    <NavItem active={active}>
                      <Link href={href(props.link?.url, target)} passHref>
                        <NavLabel as="a">{props.link?.title}</NavLabel>
                      </Link>
                      {/* {props.gallery?.length && props.gallery.length > 0 ? (
                    <MegaMenu {...props} href={href(props.link?.url, target)} />
                  ) : null} */}
                    </NavItem>
                  ) : (
                    <NavItem active={active}>
                      <NavLabel>{props.link?.title}</NavLabel>
                      {/* {props.gallery?.length && props.gallery.length > 0 ? (
                    <MegaMenu {...props} />
                  ) : null} */}
                    </NavItem>
                  )}
                </React.Fragment>
              );
            })
          )}
        </>
      ) : null}
    </Nav>
  );
};

const SideMenu: React.FC<{
  sideNavigation: ReferencedSideNavigation;
}> = ({sideNavigation}) => (
  <>
    {sideNavigation?.linklist?.map(({_key, ...props}) => (
      <React.Fragment key={_key}>
        {props.url || props.slug?.current ? (
          <NavItem>
            <Link href={props.url || props.slug?.current} passHref>
              {props.type === 'external' ? (
                <NavLabel
                  as={(props) => (
                    <a target="_blank" rel="noopener noreferrer" {...props} />
                  )}
                >
                  {props.title}
                </NavLabel>
              ) : (
                <NavLabel as="a">{props.title}</NavLabel>
              )}
            </Link>
          </NavItem>
        ) : null}
      </React.Fragment>
    ))}
  </>
);

const TrialBar = withLink(() => {
  return (
    <Box
      background={'primary'}
      color="white"
      display="flex"
      justify="center"
      items="center"
      paddingY={8}
      position="relative"
      id="trialBar"
    >
      <DesktopText as="span">
        <Strong>{'Start a 7-Day Free Trial - '}</Strong>
        {'Risk-free, easy to cancel. Full access to all content.'}
      </DesktopText>
      <MobileText as="span">
        <Strong>{'Start a 7-Day Free Trial'}</Strong><br />
        <Text fontSize="xs">{'Risk-free, easy to cancel. Full access to all content.'}</Text>
      </MobileText>
    </Box>
  );
});

const DesktopText = styled(Text)(({theme}) => `
  display: none;
  @media (min-width: ${theme.breakpoints.md.max}) {
    display: inline-block;
  }
`);

const MobileText = styled(Text)(({theme}) => `
  display: inline-block;
  text-align: center;
  @media (min-width: ${theme.breakpoints.md.min}) {
    display: none;
  }
`);

const SignInModalButton: React.FC = () => {
  const {open} = useModal('login');
  return (
    <Button id={'HeaderSignIn'} size="sm" onClick={() => {
      open();
    }}>
      Sign In
    </Button>
  );
};

type Props = {
  navigation: ReferencedNavigation;
  sideNavigation: ReferencedSideNavigation;
  site: ReferencedSiteSettings;
};
