import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {getSpacing} from '../../../utils/styling';

export const ProfileAvatar = styled.div<{
  size?: 'lg';
  spacing?: keyof Theme['spacings'];
}>(
    ({theme, size, spacing}) => `

display: flex;
align-items: center;
justify-content: center;
border-radius: 100%;
background-color: ${theme.colors.grey4};
font-weight: 700;
text-transform: uppercase;
${getSpacing(theme, spacing)}

${size === 'lg' ? `
  width: 55px;
  height: 55px;
  font-size: 25px;
` : `
  width: 40px;
  height: 40px;
  font-size: 18px;
`}
`,
);
