import styled from '@emotion/styled';

export const SwitchButton = styled.div<{
  variant?: 'small';
}>(
    ({theme, variant}) => `
    background-color: ${theme.colors.grey3};
    border-radius: ${theme.radius.lg};
    position: relative;
    ${
      variant === 'small' ?
        `
        width: 33px;
        height: 20px;
    ` :
        `
        width: 51px;
        height: 31px;
    `
}

    transition: background-color 0.2s ease-in-out;

    &:after {
      content: '';
      border-radius: 100%;
      background-color: ${theme.colors.white};
      box-shadow: ${theme.shadows.small};
      ${
        variant === 'small' ?
          `
          width: 18px;
          height: 18px;
          top: 1px;
          left: 1px;
          ` :
          `
      width: 27px;
      height: 27px;
      top: 2px;
      left: 2px;
      `
}
      position: absolute;

      transition: transform 0.2s ease-in-out;
    }
    &[aria-checked="true"] {
      background-color: ${theme.colors.primary};
      &:after {
        ${
          variant === 'small' ?
            `
        transform: translateX(13px);
        ` :
            `
        transform: translateX(20px);
        `
}
      }
    }
`,
);
