import {percentOf} from '@mindfulness/utils/math';
import {useCallback, useEffect, useRef} from 'react';
import {defaultEventProps} from '../utils/analytics';
import {UseTrack, useTrack} from '../components/global/SegmentProvider';
import {throttle} from 'lodash';

const trackScroll = (percent: number, track: UseTrack) => {
  track('Page scrolled', {
    percent,
    ...defaultEventProps(),
  });
};

export const useScrollTracking = () => {
  const track = useTrack();
  const maxScroll = useRef(0);
  const tracked = useRef(0);

  const handleScrollTracking = useCallback(() => {
    const scrollPercent = percentOf(
        window.scrollY + window.innerHeight,
        document.body.scrollHeight,
    );
    maxScroll.current = Math.max(maxScroll.current, scrollPercent);

    if (maxScroll.current < 20) {
      return;
    }
    if (maxScroll.current >= 100 && tracked.current < 100) {
      tracked.current = 100;
      trackScroll(100, track);
      return;
    }
    if (maxScroll.current >= 80 && tracked.current < 80) {
      tracked.current = 80;
      trackScroll(80, track);
      return;
    }
    if (maxScroll.current >= 60 && tracked.current < 60) {
      tracked.current = 60;
      trackScroll(60, track);
      return;
    }
    if (maxScroll.current >= 40 && tracked.current < 40) {
      tracked.current = 40;
      trackScroll(40, track);
      return;
    }
    if (maxScroll.current >= 20 && tracked.current < 20) {
      tracked.current = 20;
      trackScroll(20, track);
      return;
    }
  }, [maxScroll.current, track]);

  useEffect(() => {
    const throttledListener = throttle(handleScrollTracking, 200);
    window.addEventListener('scroll', throttledListener);
    return function cleanup() {
      window.removeEventListener('scroll', throttledListener);
    };
  }, [handleScrollTracking]);
};
