import styled from '@emotion/styled';

export const GradientLink = styled.a(
    ({theme}) => `
  font-size: 42px;
  vertical-align: middle;
  display: inline-block;
  font-weight: ${theme.fontWeights.bold};
  background-image: linear-gradient(180deg, ${theme.colors.grey3} 0%, ${theme.colors.grey3} 100%);
  -webkit-background-clip: text;
  background-clip: text;
  text-fill-color: transparent;
  &:hover {
    background-image: ${theme.gradients.soft};
  }
  @media (min-width: ${theme.breakpoints.md.min}) {
    font-size: 92px;
  }
  h2 {
    white-space: nowrap;
    line-height: 100%;
  }
`,
);

export const Seperator = styled.span(
    ({theme}) => `
  font-size: 42px;
  display: inline-block;
  vertical-align: middle;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.grey3};
  padding-left: ${theme.spacings[16]};
  padding-right: ${theme.spacings[16]};
  line-height: 100%;
  @media (min-width: ${theme.breakpoints.md.min}) {
    font-size: 92px;
  }
`,
);
