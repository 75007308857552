import styled from '@emotion/styled';

export const PlayButton = styled.div(
    ({theme}) => `
  background: ${theme.gradients.primary};
  border-radius: 100%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${theme.shadows.small};
`,
);

export const Padding = styled.div(
    ({theme}) => `
  padding-top: ${theme.spacings[10]};
  padding-bottom: ${theme.spacings[10]};
  height: 100%;
`,
);
