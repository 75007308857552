import * as React from 'react';
import {Schedule as ScheduleProps} from '@mindfulness/cms';
import {switchEnum} from '@mindfulness/utils/logic';

import {Text, Title1, Title2} from '../../typography';
import {Container, Row, Column, Stack, Section, Box} from '../../layout';
import {CallToActionButton, BlockContent, TextWrapper} from '../../global';
import {ColoursGradients, SectionIndex} from '../../../types/types';
import {TextBanner} from './Schedule.styles';

export const Schedule: React.FC<SectionIndex<ScheduleProps>> = ({
  blocks,
  title,
  paddingTop = 60,
  paddingBottom = 60,
  _type,
  style,
  // background = 'darkDarkGradient' as AllColour,
}) => {
  // const style: string = 'light';
  const background = switchEnum<string, ColoursGradients>(style || 'else', {
    light: 'white',
    else: 'darkDarkGradient',
  });
  return (
    <Section padding={0} background={background}>
      {style === 'dark' ? (
        <TextBanner>
          {Array.from(Array(6)).map((key) => (
            <span key={key}>SCHEDULE&nbsp;·&nbsp;</span>
          ))}
        </TextBanner>
      ) : null}

      <Section
        sectionPadding={{
          paddingTop,
          paddingBottom,
        }}
      >
        {title ? (
          <Container maxWidth="prose">
            <Row>
              <Column
                textAlign="center"
                spacing={{
                  xs: 40,
                  md: 80,
                }}
              >
                <Title1>{title}</Title1>
              </Column>
            </Row>
          </Container>
        ) : null}

        <Container maxWidth="sm">
          {blocks?.map(({title, paragraph, cta, _key}, index) => (
            <Row justify="space-between" key={_key} items="start" spacing={50}>
              <Column span={4} items="start">
                <Text
                  fontWeight="bold"
                  colour={style === 'light' ? 'primary' : undefined}
                  fontSize={{
                    xs: 'mega',
                    md: 'xxmega',
                  }}
                >{`${index + 1}.`}</Text>
              </Column>
              <Column span={20} textAlign="start">
                <Stack space={16} direction="vertical">
                  <Box paddingT={16}>
                    {title || paragraph ? (
                      <TextWrapper background={background}>
                        {title ? <Title2 as="p">{title}</Title2> : null}
                        {paragraph ? (
                          <BlockContent
                            content={paragraph}
                            background={background}
                            spacing={16}
                          />
                        ) : null}
                      </TextWrapper>
                    ) : null}
                  </Box>

                  {cta?.text ? (
                    <div>
                      <CallToActionButton id={`${_type}-${index}`} {...cta}>
                        {cta?.text}
                      </CallToActionButton>
                    </div>
                  ) : null}
                </Stack>
              </Column>
            </Row>
          ))}
        </Container>
      </Section>
      {style === 'dark' ? (
        <TextBanner>
          {Array.from(Array(6)).map((key) => (
            <span key={key}>SCHEDULE&nbsp;·&nbsp;</span>
          ))}
        </TextBanner>
      ) : null}
    </Section>
  );
};
