import {SanityKeyed} from '@mindfulness/cms';
import {switchEnum} from '@mindfulness/utils/logic';
import {when} from '@mindfulness/utils/maybe';

import {ReferencedFooterNavigationLink} from '../../../types/types';
import {Text} from '../../typography';
import {Link} from '../../global';

export const MenuItems: React.FC<
  SanityKeyed<ReferencedFooterNavigationLink>
> = ({title, type, url, target}) => {
  return (
    when(type, (t) =>
      switchEnum(t, {
        internal: function LinkInternal() {
          return (
            <Text
              as={url ? (props) => <Link {...props} href={url} /> : undefined}
            >
              {title}
            </Text>
          );
        },
        external: function LinkExternal() {
          return (
            <Text
              as={(props) => (
                <a
                  {...props}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                />
              )}
            >
              {title}
            </Text>
          );
        },
        reference: function LinkReference() {
          return (
            <Text
              as={when(
                  target?.slug?.current,
                  (slug) =>
                    function AsLink(props) {
                      return <Link {...props} href={slug} />;
                    },
              )}
            >
              {title || target?.title}
            </Text>
          );
        },
        else: function NonLink() {
          return <Text>{title}</Text>;
        },
      }),
    ) || null
  );
};
