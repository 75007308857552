import * as React from 'react';
import {Box, Flex, Stack} from '../../layout';
import {H3, Small} from '../../typography';
import {SwitchButton} from './Switch.styles';
import {slugify} from '../../../utils';

export const Switch: React.FC<{
  checked: boolean;
  title: string;
  description: string;
  onChange: () => void;
  unchangeable?: boolean;
}> = ({checked, title, description, onChange, unchangeable}) => {
  const ariaId = React.useMemo(() => slugify(title), [title]);
  return (
    <Box
      background={{
        xs: 'greyWarm',
        md: 'white',
      }}
      radius="lg"
      paddingY={16}
      paddingX={20}
    >
      <Stack direction="horizontal" justify="space-between">
        <Stack direction="vertical">
          <H3 weight="bold" as="span" id={ariaId}>
            {title}
          </H3>
          <Small colour="grey7">{description}</Small>
        </Stack>
        <Flex items="center">
          {unchangeable ? (
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.39729 5.37448C11.7955 3.77205 14.615 2.91675 17.4994 2.91675C19.4145 2.91675 21.3108 3.29396 23.0802 4.02684C24.8495 4.75972 26.4571 5.83392 27.8113 7.18811C29.1655 8.5423 30.2397 10.1499 30.9726 11.9193C31.7055 13.6886 32.0827 15.585 32.0827 17.5001C32.0827 20.3844 31.2274 23.2039 29.625 25.6021C28.0225 28.0004 25.7449 29.8695 23.0802 30.9733C20.4154 32.0771 17.4832 32.3659 14.6543 31.8032C11.8254 31.2405 9.2269 29.8516 7.18738 27.8121C5.14787 25.7725 3.75894 23.174 3.19624 20.3451C2.63354 17.5163 2.92233 14.584 4.02611 11.9193C5.12989 9.25453 6.99907 6.97692 9.39729 5.37448ZM21.4654 12.8188L15.2091 19.0897L12.8029 16.6834C12.6721 16.5308 12.5113 16.4068 12.3303 16.3192C12.1494 16.2317 11.9523 16.1825 11.7515 16.1748C11.5507 16.167 11.3504 16.2008 11.1632 16.2742C10.9761 16.3475 10.8061 16.4587 10.664 16.6008C10.5219 16.7429 10.4107 16.9129 10.3374 17.1C10.264 17.2872 10.2302 17.4875 10.238 17.6883C10.2457 17.8891 10.2949 18.0862 10.3824 18.2671C10.47 18.4481 10.594 18.6089 10.7466 18.7397L14.1737 22.1813C14.31 22.3165 14.4716 22.4234 14.6493 22.496C14.8269 22.5686 15.0172 22.6054 15.2091 22.6043C15.5917 22.6026 15.9583 22.4508 16.23 22.1813L23.5216 14.8897C23.6583 14.7541 23.7668 14.5928 23.8408 14.4151C23.9149 14.2374 23.953 14.0468 23.953 13.8543C23.953 13.6617 23.9149 13.4711 23.8408 13.2934C23.7668 13.1157 23.6583 12.9544 23.5216 12.8188C23.2484 12.5472 22.8788 12.3948 22.4935 12.3948C22.1082 12.3948 21.7386 12.5472 21.4654 12.8188Z"
                fill="#219653"
              />
            </svg>
          ) : (
            <SwitchButton
              onClick={() => onChange()}
              role="switch"
              aria-checked={checked}
              aria-labelledby={ariaId}
            />
          )}
        </Flex>
      </Stack>
    </Box>
  );
};
