import styled from '@emotion/styled';
import {Text} from 'shared/components/typography/Text';

export const ChapterLabel = styled(Text)<{
  visible: boolean;
  active: boolean;
  last: boolean;
  length: number;
}>(
    ({theme, visible, active, last, length}) => `
  user-select: none;
  position: absolute;
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSizes['2xs']};
  ${
    length === 1 ?
      `
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
      ` :
      `
  ${last && length > 1 ? `right: 0;` : `left: 0;`}
  transform: translateY(-100%);

  `
}
  white-space: nowrap;
  font-size: ${theme.fontSizes.xs};
  transition: ease 0.2s opacity;
  opacity: ${visible ? (active ? '1' : '0.5') : '0'};

  @media (min-width: ${theme.breakpoints.md.min}) {
    font-size: ${theme.fontSizes.sm};
    ${last && length > 1 ? `right: 0;` : `left: 0;`}
    transform: translateY(-100%);

  }


`,
);
