import {gql} from '@apollo/client';
import {CollectionFeedFragment} from './feedFragments';

const RatingsFragment = gql`
  fragment RatingsFragment on Ratings {
    average
    averageFormatted
    total
  }
`;

export const SessionFragment = gql`
  fragment SessionFragment on Session {
    user {
      id
      publicId
      email
      cohorts
      hasActiveSubscription
      fullName
      firstName
      lastName
      accessPassEndsAt
      accessPassSource
      subscriptions {
        plan {
          name
          planCode
          active
        }
      }
    }
    strategy
    token
    createdAt
  }
`;

export const PlanDiscountFragment = gql`
  fragment PlanDiscountFragment on PlanDiscount {
    coupon
    valid
    amountOff
    percentOff
    finalPrice
    currency
    duration
    durationRepeats
    redeemBy
    trialDays
    freePass
  }
`;

export const PlanFragment = gql`
  fragment PlanFragment on Plan {
    id
    code
    planCode
    productCode
    name
    description
    priceUSD
    active
    webAlias
    webAliases
    renewalInterval
    price {
      price
      currencyCode
    }
    discount {
      ...PlanDiscountFragment
    }
  }
`;

export const PlanCouponFragment = gql`
  fragment PlanCouponFragment on Plan {
    id
    code
    planCode
    productCode
    name
    description
    priceUSD
    active
    webAlias
    webAliases
    renewalInterval
    price {
      price
      currencyCode
    }
    createdAt
    updatedAt
    deletedAt
    discount(coupon: $coupon) {
      ...PlanDiscountFragment
    }
  }
`;

export const UnauthenticatedSessionFragment = gql`
  fragment UnauthenticatedSessionFragment on UnauthenticatedSession {
    email
    exists
    paid
  }
`;

export const SubscriptionFragment = gql`
  fragment SubscriptionFragment on PlanSubscription {
    id
    active
    status
    upgraded
    store
    storePlanId
    startsAt
    expiresAt
    trialEndsAt
    autoRenewsAt
    autoRenews
    paymentDate
    paymentGraceEndsAt
    paymentCollected
    refundedAt
    plan {
      name
      code
      planCode
      productCode
    }
  }
`;

export const ProducerFragment = gql`
  fragment ProducerFragment on Producer {
    slug
    publicId
    feedId
    shortName
    fullName
    id
    blurb
    webSlug
    type
    avatar {
      url
    }
    coverImage {
      url
    }
    cellImage {
      url
    }
  }
`;

export const ProducerContributionFragment = gql`
  fragment ProducerContributionFragment on ProducerContribution {
    id
    type
    producer {
      ...ProducerFragment
    }
    createdAt
    updatedAt
    deletedAt
  }
  ${ProducerFragment}
`;

export const QuoteFragment = gql`
  fragment QuoteFragment on Quote {
    id
    text
    attribution
    singleId
    createdAt
  }
`;

export const TopicTagFragment = gql`
  fragment TopicTagFragment on Tag {
    title
    subtitle
    inlineTitle
    slug
    webSlug
    id
    type
    coverImage {
      url
    }
  }
`;

export const TagFragment = gql`
  fragment TagFragment on Tag {
    slug
    id
    type
    title
    subtitle
    description
    featured
    featuredOrder
    color
    coverImage {
      url
    }

    curator {
      ...ProducerFragment
    }

    updatedAt
    createdAt
    deletedAt
  }
  ${ProducerFragment}
`;

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    skip
    take
    more
    total
  }
`;

export const MediaFragment = gql`
  fragment MediaFragment on Media {
    id
    status
    originalName
    type
    mimeType
    chapterType
    path
    duration
    chapterLabel
    transcript {
      url
    }
    skipOnReplay
    source {
      url
    }
    initialFrame {
      url
    }
  }
`;

export const SingleVariantFragment = gql`
  fragment SingleVariantFragment on SingleVariant {
    id
    label
    description
    duration
    chapters {
      ...MediaFragment
    }
  }

  ${MediaFragment}
`;

export const SingleFragment = gql`
  fragment SingleFragment on Single {
    id
    type
    title
    label
    description
    webSlug
    free
    deepLink
    isNew
    ratings {
      ...RatingsFragment
    }
    tags {
      title
    }
    releaseDate
    singleStatus: status
    cellImage {
      url
    }
    coverImage {
      url
    }
    contributors {
      ...ProducerContributionFragment
    }
  }
  ${ProducerContributionFragment}
  ${RatingsFragment}
`;

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    publicId
    fullName
    firstName
    email
    role
    avatar {
      url
    }
  }
`;

export const CourseActivityFragment = gql`
  fragment CourseActivityFragment on CourseActivity {
    __typename
    ... on Activity {
      id
      layout
      required
      order
      createdAt
      updatedAt
      deletedAt
    }
    ... on SingleActivity {
      title
      description
      single {
        id
        type
        status
        title
      }
    }
    ... on TextActivity {
      text
    }
  }
`;

export const CourseSessionFragment = gql`
  fragment CourseSessionFragment on CourseSession {
    id
    title
    description
    order
    duration
    coverImage {
      url
    }
    activities {
      ...CourseActivityFragment
    }
    relatedMoment {
      id
      type
      status
      title
    }
    createdAt
    updatedAt
    deletedAt
  }
  ${CourseActivityFragment}
`;

export const CourseFragment = gql`
  fragment CourseFragment on Course {
    id
    title
    description
    label
    description
    webSlug
    free
    deepLink
    status
    ratings {
      ...RatingsFragment
    }
    coverImage {
      url
    }
    cellImage {
      url
    }
    previewText
    previewTrailer {
      ...MediaFragment
    }
    tags {
      slug
    }
    owner {
      ...ProducerFragment
    }
    contributors {
      ...ProducerContributionFragment
    }

    createdAt
    updatedAt
    deletedAt
  }
  ${ProducerContributionFragment}
  ${MediaFragment}
  ${ProducerFragment}
  ${RatingsFragment}
`;

export const BundleFragment = gql`
  fragment BundleFragment on Bundle {
    id
    title
    description
    status
    webSlug
    subtitle
    label
    isNew
    bundleType: type
    ratings {
      ...RatingsFragment
    }
    curator {
      ...ProducerFragment
    }
    collections {
      id
      title
      webSlug
      playerLayout
      results(page: { take: 30, skip: 0 }) {
        items {
          ... on Single {
            ...SingleFragment
          }
        }
      }
    }
    cardImage {
      url
    }
    coverImage {
      url
    }
    cellImage {
      url
    }
  }
  ${SingleFragment}
  ${ProducerFragment}
  ${RatingsFragment}
`;

export const SingleDetailFragment = gql`
  fragment SingleDetailFragment on Single {
    id
    type
    title
    label
    description
    webSlug
    free
    deepLink
    singleStatus: status
    releaseDate
    isNew
    tags {
      title
    }
    ratings {
      ...RatingsFragment
    }
    cellImage {
      url
    }
    coverImage {
      url
    }
    variants {
      ...SingleVariantFragment
    }
    contributors {
      ...ProducerContributionFragment
    }
    relatedCollections {
      ...CollectionFeedFragment
    }
    userData @include(if: $withUserData) {
      lastPlayedPercent
      defaultVariant
      isFavourite
      locked
      upgradeTo
    }
    updatedAt
    createdAt
    deletedAt
  }
  ${ProducerContributionFragment}
  ${SingleVariantFragment}
  ${CollectionFeedFragment}
  ${RatingsFragment}
`;

export const BundleDetailFragment = gql`
  fragment BundleDetailFragment on Bundle {
    id
    title
    description
    status
    webSlug
    subtitle
    label
    bundleType: type
    sessionsCount
    ratings {
      ...RatingsFragment
    }
    curator {
      ...ProducerFragment
    }
    userData @include(if: $withUserData) {
      bundleLocked: locked
      completedSessions
      bundleFavourite: isFavourite
      upgradeTo
    }
    collections {
      ...CollectionFeedFragment
    }
    cardImage {
      url
    }
    coverImage {
      url
    }
    cellImage {
      url
    }
    relatedCollectionIds
  }
  ${CollectionFeedFragment}
  ${ProducerFragment}
  ${RatingsFragment}
`;

export const CollectionTagFragment = gql`
  fragment CollectionTagFragment on Tag {
    webSlug
    id
    slug
    parentSlug
    title
    subtitle
    description
  }
`;

export const TagCollectionFragment = gql`
  fragment TagCollectionFragment on TagCollection {
    id
    title
    webSlug
  }
`;

export const TagFeedFragment = gql`
  fragment TagFeedFragment on TagFeed {
    title
    id
    description
    subtitle
    collections {
      ...CollectionFeedFragment
    }
    webMeta {
      title
      path
      tags
      noIndex
    }
    relatedFeeds {
      id
      title
    }
    coverImage {
      url
    }
  }
  ${CollectionFeedFragment}
`;

export const BundleCheckoutFragment = gql`
  fragment BundleCheckoutFragment on Bundle {
    webSlug
    slug
    id
    title
    cellImage(size: { width: 64, height: 64, fit: COVER }) {
      url
    }
  }
`;

export const UnlocksFragment = gql`
  fragment UnlocksFragment on PurchasableUnlock {
    cohort
    bundle
    collection
    single
    subscription
  }
`;

export const PurchaseFragment = gql`
  fragment PurchaseFragment on Purchasable {
    id
    title
    subtitle
    description
    type
    conditions
    cellImage {
      url
    }
    unlocks {
      ...UnlocksFragment
    }
    trialDays
    price {
      priceUSD
      comparePriceUSD
      renewalInterval
      discount {
        trialDays
        coupon
        amountOff
        percentOff
        finalPrice
      }
    }
  }
  ${UnlocksFragment}
`;

export const OrderPurchaseFragment = gql`
  fragment OrderPurchaseFragment on Purchasable {
    id
    title
    subtitle
    conditions
    description
    type
    cellImage {
      url
    }
    content {
      __typename
      ... on Bundle {
        webSlug
      }
      ... on Single {
        webSlug
      }
    }
    unlocks {
      ...UnlocksFragment
    }
    price {
      priceUSD
      comparePriceUSD
      renewalInterval
      discount {
        trialDays
        coupon
        amountOff
        percentOff
        finalPrice
      }
    }
  }
  ${UnlocksFragment}
`;

export const PurchaseWithCouponFragment = gql`
  fragment PurchaseWithCouponFragment on Purchasable {
    id
    title
    subtitle
    description
    type
    cellImage {
      url
    }
    price {
      priceUSD
      comparePriceUSD
      renewalInterval
      discount(coupon: $coupon) {
        trialDays
        coupon
        amountOff
        percentOff
        finalPrice
      }
    }
  }
`;

export const FinalizedOrderFragment = gql`
  fragment FinalizedOrderFragment on Order {
    id
    coupon
    payInstalments
    status
    finalizedAt
    available {
      instalments
      guarantee
    }
    summary {
      subtotal
      total
      dueToday
      amountOff
      percentOff
      conditions
    }
    purchasables {
      ...OrderPurchaseFragment
    }
  }
  ${OrderPurchaseFragment}
`;

export const OrderFragment = gql`
  fragment OrderFragment on Order {
    id
    coupon
    payInstalments
    status
    finalizedAt
    available {
      instalments
      guarantee
      upsellOffer {
        ...PurchaseFragment
      }
    }
    metadata
    summary {
      subtotal
      total
      dueToday
      amountOff
      percentOff
      conditions
    }
    purchasables {
      ...PurchaseFragment
    }
  }
  ${PurchaseFragment}
`;


export const ShareFragment = gql`
  fragment ShareFragment on Share {
    id
    code
    type
    url
    contentId
  }
`;


export const QuestionnaireFragment = gql`
fragment QuestionnaireFragment on Questionnaire {
  title
  id
  slug
  cohorts
  show
  sheets {
    initial
  }
}
`;

export const QuestionOptionFragment = gql`
fragment QuestionOptionFragment on QuestionOption {
  title
  recommended
  description
  label
  id
  nextAction
  nextValue
  value
  cellImage {
    url
  }
  details {
    description
    stats {
      label
    }
  }
}
`;


export const QuestionSheetFragment = gql`
fragment QuestionSheetFragment on QuestionSheet {
  title
  description
  slug
  id
  initial
  type
  submittingText
  submittingAnimation
  questionnaireId
  groupedOptions {
    ... on QuestionOption {
      ...QuestionOptionFragment
    }
    ... on QuestionOptionGroup {
      options {
        ...QuestionOptionFragment
      }
      description
      title
    }
  }
  image {
    url
    aspect
  }
}
${QuestionOptionFragment}
`;

