import * as React from 'react';
import Image from 'next/image';
import {Theme} from '@emotion/react';
import {Maybe} from '@mindfulness/utils/maybe';
import {Figure} from '@mindfulness/cms';

import {Box} from '../../layout';
import {Text} from '../../typography';
import {BasicImage} from '../../global';

export const TotalReviews: React.FC<{
  colour?: keyof Theme['colors'];
  image?: Maybe<Figure>;
}> = ({colour = 'grey7', image}) => (
  <>
    <Box display="inline-block">
      {image ? (
        <BasicImage width={94} height={40} image={image} />
      ) : (
        <Image
          src="/images/review-profiles.png"
          width={94}
          height={40}
          alt=""
        />
      )}
      <Image
        src="/images/review-stars.png"
        width={86}
        height={15.5}
        alt="5 stars"
      />
    </Box>
    <Text fontSize="xs" colour={colour}>
      4.9 stars with over 1M+ users
    </Text>
  </>
);
