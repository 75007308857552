import * as React from 'react';
import {Gallery as GalleryProps} from '@mindfulness/cms';
import {when} from '@mindfulness/utils/maybe';

import {TextUnderImage} from './Gallery.styles';

import {H2, H4, Text} from '../../typography';
import {BackgroundImage, BlockContent, Link} from '../../global';
import {
  Container,
  Row,
  Column,
  Grid,
} from '../../layout';
import {TextOnImageCard} from '../../ui';
import {
  GalleryBlock,
  ReferencedGallery,
  SectionIndex,
} from '../../../types/types';
import {Section} from '../../layouts/Section';
import {AspectRatio} from '../../layouts/AspectRatio';

export const Gallery: React.FC<Props> = ({
  _type,
  title,
  paragraph,
  blocks,
  background,
  paddingBottom,
  paddingTop,
  columns = '3',
  ...blockProps
}) => (
  <Section
    background={background}
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
  >
    <Container maxWidth="lg">
      <Row justify="center">
        <Column textAlign="center" span={20}>
          <H2 spacing={paragraph ? 10 : 40}>{title}</H2>
          {when(paragraph, () =>
            typeof paragraph === 'object' ? (
              <BlockContent
                spacing={{
                  xs: 16,
                  md: 40,
                }}
                background={background}
                content={paragraph}
              />
            ) : (
              <H4
                spacing={{
                  xs: 16,
                  md: 40,
                }}
              >
                {paragraph}
              </H4>
            ),
          )}
        </Column>
      </Row>
      <Grid
        cols={{
          xs: 1,
          md: parseInt(columns),
        }}
        rowGap={24}
        columnGap={24}
      >
        {blocks?.map((block) =>
          ['internal', 'reference', 'external'].includes(
              block.actionType || '',
          ) ? (
            <Link
              key={block._key}
              href={
                block.actionType === 'reference' ?
                  block.slug || '' :
                  block.url || ''
              }
            >
              <a
                {...(block.actionType === 'external' ?
                  {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                  } :
                  {})}
              >
                <Content
                  background={background}
                  key={block._key}
                  {...{...block, ...blockProps}}
                />
              </a>
            </Link>
          ) : (
            <Content
              background={background}
              key={block._key}
              {...{...block, ...blockProps}}
            />
          ),
        )}
      </Grid>
    </Container>
  </Section>
);

const Content: React.FC<
  GalleryBlock[0] & {
    textPosition?: GalleryProps['textPosition'];
    alignment?: GalleryProps['alignment'];
    overlay?: GalleryProps['overlay'];
    background?: GalleryProps['background'];
  }
> = ({
  textPosition,
  title,
  alignment,
  overlay,
  subtitle,
  background,
  image,
}) =>
  textPosition === 'onImage' ? (
    <TextOnImageCard image={image} title={title} alignment={alignment} />
  ) : (
    <TextUnderImage alignment={alignment}>
      <AspectRatio ratio="20:13" spacing={10}>
        {image ? (
          <BackgroundImage
            image={image}
            overlay={overlay === false ? undefined : 0.4}
            radius="lg"
            zIndex={1}
          />
        ) : null}
      </AspectRatio>
      <H4 weight="bold" size="lg">
        {title}
      </H4>
      {subtitle ? (
        <Text colour={background === 'grey9' ? undefined : 'grey7'}>
          {subtitle}
        </Text>
      ) : null}
    </TextUnderImage>
  );

type Props = SectionIndex<ReferencedGallery>;
