import {useContext, useEffect, useMemo} from 'react';
import {Maybe} from '@mindfulness/utils/maybe';
import type {SanityKeyed, Purchase as SanityPurchase} from '@mindfulness/cms';
import Image from 'next/image';
import router from 'next/router';

import {
  PurchaseButton,
  PurchaseLoader,
} from '../../sections/HeroBundle/HeroBundle.styles';
import {useAddToCart} from '../../../hooks/useAddToCart';
import {HydratedPurchase} from '../../../hooks/usePurchases';
import {Box, Flex, Stack} from '../../layout';
import {Small, Text} from '../../typography';
import {formatMoney} from '../../../utils/money';
import {assertNumber, payIn3Price} from '../../../utils/number';
import {Alert, Button} from '../../forms';
import {assertString} from '../../../utils/string';
import {Context, Icon} from '../../global';
import {useModal} from '../../../hooks/useModal';
import {useTrack} from '../../global/SegmentProvider';
import {ButtonSkeleton} from '../ButtonSkeleton';

export const Purchases: React.FC<{
  purchases: Array<SanityKeyed<SanityPurchase>>;
  hydratedPurchases: Array<HydratedPurchase>;
  setSelected: React.Dispatch<React.SetStateAction<Maybe<HydratedPurchase>>>;
  selected: Maybe<HydratedPurchase>;
  index: number;
  coupon: Maybe<string>;
  loadingPurchases: boolean;
  onAddToCart?: () => void;
}> = ({
  hydratedPurchases,
  purchases,
  coupon,
  loadingPurchases,
  setSelected,
  selected,
  index,
  onAddToCart,
}) => {
  const {data} = useContext(Context);
  const {addToCart, loading, error} = useAddToCart();
  const containsSelected = useMemo(() => {
    return purchases?.some((p) => p._key === selected?.purchase._key);
  }, [selected, purchases]);

  useEffect(() => {
    if (!selected && index === 0 && hydratedPurchases?.length) {
      const subscription = hydratedPurchases?.find((p) => {
        return p.unlocks?.subscription;
      });
      setSelected(subscription || hydratedPurchases?.[0]);
    }
  }, [hydratedPurchases, selected, index]);

  return (
    <>
      {loadingPurchases ? (
        <>
          <Stack space={10} spacing={30} direction="vertical">
            {purchases.map(({_key}) => (
              <PurchaseLoader key={_key} />
            ))}
          </Stack>
          <ButtonSkeleton />
        </>
      ) : purchases ? (
        <>
          <Stack space={10} spacing={30} direction="vertical">
            {hydratedPurchases.map((props) => (
              <Purchase
                {...props}
                selected={selected}
                setSelected={setSelected}
                key={props.purchase._key}
              />
            ))}
          </Stack>
          <Alert error={error} />

          <Button
            wide
            type="button"
            id="AddToCart"
            disabled={!(selected && containsSelected)}
            variant={selected && containsSelected ? 'primary' : 'secondary'}
            loading={loading}
            onClick={(e) => {
              e.stopPropagation();
              onAddToCart?.();
              if (!selected) return;
              const action = data?.action;
              const cohort = action?.cohort;
              const thankYou = action?.thankYouLink || action?.thankYouPage?.current;
              addToCart(selected, {
                payInstalments: selected.purchase.payIn3,
                coupon,
                metadata: {
                  ...(cohort ? {cohort} : {}),
                },
              }, undefined, undefined, undefined, thankYou);
            }}
          >
            {selected && containsSelected ?
              selected.trialDays ?
                'Start Free Trial' :
                'Add To Cart' :
              'Select an option'}
          </Button>
        </>
      ) : null}
    </>
  );
};

const Purchase: React.FC<
  HydratedPurchase & {
    selected: Maybe<HydratedPurchase>;
    setSelected: React.Dispatch<React.SetStateAction<Maybe<HydratedPurchase>>>;
  }
> = (props) => {
  const plusModal = useModal('plus');
  const track = useTrack();
  const {purchase, price, title, description, selected, setSelected, trialDays} = props;
  const _title = purchase?.title || title;
  const displayPrice = useMemo(() => {
    if (!price) return 0;
    if (price?.comparePriceUSD && price.comparePriceUSD > price.priceUSD) {
      if (purchase.payIn3) {
        return payIn3Price(assertNumber(price.comparePriceUSD));
      }
      return assertNumber(price.comparePriceUSD);
    }
    if (purchase.payIn3) {
      return payIn3Price(assertNumber(price?.priceUSD));
    }
    return assertNumber(price?.priceUSD);
  }, [price, purchase]);

  const displayDiscountPrice = useMemo(() => {
    if (price?.discount?.finalPrice) {
      if (purchase.payIn3) {
        return payIn3Price(assertNumber(price?.discount?.finalPrice));
      }
      return assertNumber(price?.discount?.finalPrice);
    }
    if (price?.comparePriceUSD && price.comparePriceUSD > price.priceUSD) {
      if (purchase.payIn3) {
        return payIn3Price(assertNumber(price.priceUSD));
      }
      return assertNumber(price.priceUSD);
    }
  }, [
    purchase.payIn3,
    price?.comparePriceUSD,
    price?.priceUSD,
    price?.discount?.finalPrice,
  ]);

  return (
    <PurchaseButton
      selected={selected?.purchase._key === purchase?._key}
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        track('Button clicked', {
          button_id: 'PurchaseItem',
          button_text: assertString(_title),
          path: router.asPath,
        });

        setSelected(props);
      }}
    >
      <Flex grow wrap="nowrap">
        <Box textAlign="left" width="100%">
          <span
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            {_title?.replace('Plus+', '')}{' '}
            {_title?.includes('Plus+') ? (
              <>
                <Image
                  alt="Plus+"
                  src="/icons/plus-gold.svg"
                  width={39}
                  height={13}
                />
                <Icon
                  name="info"
                  onClick={() => {
                    track('Button clicked', {
                      button_id: 'PlusInfo',
                      button_text: 'Info icon',
                      path: router.asPath,
                    });
                    plusModal.open();
                  }}
                />
              </>
            ) : null}
          </span>

          <Small fontWeight="regular" colour="grey5" ellipsis={1}>
            {purchase?.description || description}
          </Small>
        </Box>
      </Flex>
      <Stack wrap="nowrap" items="center" space={6}>
        {price?.discount?.trialDays || trialDays ? (
          <DiscountPrice
            price={displayPrice}
            discountPrice={0}
            payIn3={!!purchase.payIn3}
          />
        ) : displayDiscountPrice ? (
          <DiscountPrice
            price={displayPrice}
            discountPrice={displayDiscountPrice}
            payIn3={!!purchase.payIn3}
          />
        ) : (
          <Text fontSize="md">{formatMoney(displayPrice)}</Text>
        )}
      </Stack>
    </PurchaseButton>
  );
};

export const DiscountPrice: React.FC<{
  price: number;
  discountPrice: number;
  payIn3: boolean;
}> = ({price, discountPrice, payIn3}) => (
  <>
    {!payIn3 ? (
      <Small colour="grey5" fontWeight="regular" decoration="line-through">
        {formatMoney(price)}
      </Small>
    ) : null}
    <Text fontSize="md" colour="highlight">
      {formatMoney(discountPrice)}
    </Text>
  </>
);
