'use client';
import React from 'react';
import dynamic from 'next/dynamic';
import {RecoilRoot} from 'recoil';

import {ProviderStack} from './ProviderStack';
import {LayoutProps} from '../global';
import {RouteTracker} from './RouteTracker';
import {PlusUpgradeModal} from '../ui/PlusUpgradeModal';

const UnlockModal = dynamic(() =>
  import('../ui/UnlockModal').then(({UnlockModal}) => UnlockModal),
);
const SingleFinishedModal = dynamic(() =>
  import('../ui/SingleFinishedModal').then(
      ({SingleFinishedModal}) => SingleFinishedModal,
  ),
);
const LocalStorageError = dynamic(() =>
  import('../ui/LocalStorageError').then(
      ({LocalStorageError}) => LocalStorageError,
  ),
);
const LockedModal = dynamic(() =>
  import('../ui/LockedModal').then(({LockedModal}) => LockedModal),
);
const GuaranteeModal = dynamic(() =>
  import('../ui/GuaranteeModal').then(({GuaranteeModal}) => GuaranteeModal),
);
const ClaimAccessModal = dynamic(() =>
  import('../ui/ClaimAccessModal').then(
      ({ClaimAccessModal}) => ClaimAccessModal,
  ),
);
const EmailModal = dynamic(() =>
  import('../ui/EmailModal').then(({EmailModal}) => EmailModal),
);
const ActionModal = dynamic(() =>
  import('../ui/ActionModal').then(({ActionModal}) => ActionModal),
);
const LoginModal = dynamic(() =>
  import('../ui/LoginModal').then(({LoginModal}) => LoginModal),
);
const UpsellModal = dynamic(() =>
  import('../ui/UpsellModal').then(({UpsellModal}) => UpsellModal),
);
const AddToCartModal = dynamic(() =>
  import('../ui/AddToCartModal').then(({AddToCartModal}) => AddToCartModal),
);
const PlusModal = dynamic(() =>
  import('../ui/PlusModal').then(({PlusModal}) => PlusModal),
);
const ThankYouModal = dynamic(() =>
  import('../ui/ThankYouModal').then(({ThankYouModal}) => ThankYouModal),
);
const AttentionModal = dynamic(() =>
  import('../ui/AttentionModal').then(({AttentionModal}) => AttentionModal),
);
const PreviewBar = dynamic(() =>
  import('./PreviewBar').then(({PreviewBar}) => PreviewBar),
);

/**
 * Renders the providers stack and global modal components without the header and footer
 * @return {React.FC}
 */
export const CustomLayout: React.FC<
  Partial<React.PropsWithChildren<LayoutProps>>
> = ({children, ...props}) => {
  const hasLockedContent = props?.page?.action?.action === 'locked';
  const isSingle = Boolean(props.single);
  const isBundle = false;

  return (
    <RecoilRoot>
      <RouteTracker />
      <ProviderStack {...props}>
        {children}
        <EmailModal />
        <GuaranteeModal />
        <PlusModal />
        <LocalStorageError />
        <AddToCartModal />
        <UpsellModal />
        <AttentionModal />
        <ThankYouModal />
        {isSingle || isBundle ? (
            <>
              <ClaimAccessModal
                unclosable={isBundle}
                name={isSingle ? 'claimAccess' : 'action'}
              />
              <UnlockModal />
              <SingleFinishedModal />
            </>
          ) : null}
        {hasLockedContent ? <LockedModal /> : null}
        {isBundle ? null : <ActionModal />}
        <LoginModal />
        <PlusUpgradeModal />
        <PreviewBar enabled={!!props.draftMode} />
      </ProviderStack>
    </RecoilRoot>
  );
};
