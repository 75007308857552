import * as React from 'react';
import * as Sentry from '@sentry/nextjs';

import {ItemLocation, Maybe, WithWebSlug} from '../../../types/types';
import type {BundleFeedFragment, CollectionLayout} from '../../../types/api';

import {PlaylistCard} from './PlaylistCard';
import {CourseCard} from './CourseCard';
import {switchSpace} from '../../../utils/spaces';


export const BundleCard: React.FC<
  WithWebSlug<BundleFeedFragment> & {
    location?: ItemLocation;
    priority?: boolean;
    layout?: Maybe<CollectionLayout>;
    onClick: () => void;
  }
> = (props) => {
  const {bundleType} = props;
  return (
    <Sentry.ErrorBoundary fallback={<p>An error occured</p>}>
      {bundleType === 'PLAYLIST' ? (
        <PlaylistCard {...props} />
      ) : (
        <CourseCard href={switchSpace({'mindfulness.com': props.webSlug, 'else': `/play?b=${props.id}`})} {...props} />
      )}
    </Sentry.ErrorBoundary>
  );
};
