import styled from '@emotion/styled';

export const LeftContainer = styled.div(({theme}) => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.greyWarm};
`);

export const SingleCard = styled.div(({theme}) => `
  position: relative;
  height: 325px;
  width: 325px;
  box-shadow: 0px 0px 200px rgba(0, 0, 0, 0.35);
  border-radius: ${theme.radius.lg};
  overflow: hidden;
`);

export const SingleImage = styled.img`
  object-fit: cover;
  object-position: center;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const SingleCardInner = styled.div(({theme}) => `
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 74.48%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: ${theme.colors.white};
  padding: ${theme.spacings[60]} ${theme.spacings[30]};
`);

export const WaveWrapper = styled.div(({theme}) => `
  position: absolute;
  bottom: 40%;
  left: 0;
  right: 0;

  svg {
    width: 100%;
    position: absolute;
  }
`);

export const SingleCardFooter = styled.div(({theme}) => `
  background-color: ${theme.colors.white};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  z-index: 2;
`);
