import {useState, useRef} from 'react';

import {Icon} from '../../global';
import {ProfileDetails, ProfileMenu} from '../../ui';

import style from './AccountDropdown.module.css';
import {useOutsideAlerter} from '../../../hooks';

export const AccountDropdown = () => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, () => setOpen(false));

  return (
    <div className={style.accountToggleContainer}>
      <button className={style.accountButton} type="button" onClick={() => setOpen(true)}>
        My Account
        <Icon size={24} name="chevron" colour="primary" />
      </button>
      <div
        className={[
          style.accountTooltip,
          ...(open ? [style.open] : []),
        ].join(' ')}
        ref={wrapperRef}
      >
        <header className={style.tooltipHeader}>
          <ProfileDetails />
        </header>
        <div className={style.accountMenuWrapper}>
          <ProfileMenu fontSize="base" />
        </div>
      </div>
    </div>
  );
};
