import {DependencyList, useEffect} from 'react';

export const useAsyncEffect = (effect: () => Promise<void | Destructor>, deps: DependencyList) => {
  useEffect(() => {
    (async () => {
      effect();
    })();
  }, deps);
};

type Destructor = () => void;
