import * as React from 'react';
import {HeroHome as HeroHomeProps} from '@mindfulness/cms';
import {isMobile} from 'react-device-detect';
import {throttle} from 'lodash';
import Image from 'next/legacy/image';

import {Container, Row, Column, Box} from '../../layout';
import {H1, H2, Text} from '../../typography';
import {CallToActionButton} from '../../global';
import {SectionIndex} from '../../../types/types';

import {AbsoluteImage, Section} from './HeroHome.styles';

export const HeroHome: React.FC<SectionIndex<HeroHomeProps>> = ({
  cta,
  index,
  title,
  tagline,
  _type,
}) => {
  const top = React.useRef<HTMLDivElement>(null);
  const middle = React.useRef<HTMLDivElement>(null);
  const bottom = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    /**
     * Handles the moving of the images based on the mouse position.
     * Only used on devices that have a mouse.
     * @param {MouseEvent} e
     */
    function parallax(e: MouseEvent) {
      const x = e.clientX - window.innerWidth / 2;
      const y = e.clientY - window.innerHeight / 2;
      if (top.current) {
        top.current.style.transform = `translateX(${x * 0.08}px) translateY(${
          y * 0.04
        }px)`;
      }
      if (middle.current) {
        middle.current.style.transform = `translateX(${
          x * 0.06
        }px) translateY(${y * 0.03}px)`;
      }
      if (bottom.current) {
        bottom.current.style.transform = `translateX(${
          x * 0.04
        }px) translateY(${y * 0.02}px)`;
      }
    }

    /**
     * Handles the moving of the images based on the scroll position.
     * Only used on devices that don't have a mouse.
     */
    function scrollParallax() {
      const y = window.scrollY - window.innerHeight / 2;
      if (top.current) {
        top.current.style.transform = `translateY(-${y * 0.48 + 110}px)`;
      }
      if (middle.current) {
        middle.current.style.transform = `translateY(-${y * 0.34 + 100}px)`;
      }
      if (bottom.current) {
        bottom.current.style.transform = `translateY(-${y * 0.22 + 90}px)`;
      }
    }
    const throttledParallax = throttle(parallax, 100);
    if (isMobile) {
      scrollParallax();
      document.addEventListener('scroll', scrollParallax);
    } else {
      document.addEventListener('mousemove', throttledParallax);
    }
    return function cleanup() {
      document.removeEventListener('scroll', scrollParallax);
      document.removeEventListener('mousemove', throttledParallax);
    };
  }, []);

  const topImages = React.useMemo(
      () => [
        [70, 45, 175, 219, 2],
        [80, 5, 278, 421, 3],
        [48, 52, 187, 281, 4],
        [20, 48, 187, 281, 13],
      ],
      [],
  );

  const middleImages = React.useMemo(
      () => [
        [88, 43, 188, 281, 5],
        [35, 60, 188, 281, 6],
        [60, 2, 187, 227, 7],
        [-1, 4, 186, 281, 8],
        [55, 73, 370, 247, 1],
        [-1, 35, 223, 281, 9],
      ],
      [],
  );

  const bottomImages = React.useMemo(
      () => [
        [15, -2, 252, 219, 10],
        [5, 70, 281, 421, 11],
        [40, -20, 262, 219, 12],
      ],
      [],
  );

  return (
    <Section>
      <Box
        zIndex={4}
        paddingT={{
          xs: 174,
          md: 242,
        }}
        position="absolute"
        inset={0}
      >
        <Container maxWidth="prose">
          <Row>
            <Column textAlign="center">
              <H2 colour="primary" as={index === 0 ? 'h1' : 'h2'} spacing={6}>
                Mindfulness.com
              </H2>
              <H1 as="p">{title}</H1>
              <Text spacing={20}>{tagline}</Text>
              <CallToActionButton id={`${_type}-${index}`} {...cta}>
                {cta?.text || 'Join for Free'}
              </CallToActionButton>
            </Column>
          </Row>
        </Container>
      </Box>
      <Box
        zIndex={3}
        position="absolute"
        inset={0}
        ref={top}
        style={{
          transition: 'transform 1s ease-out',
          perspective: '50px',
        }}
      >
        {topImages.map((v) => (
          <ImageSkew v={v} key={v.toString()} />
        ))}
      </Box>
      <Box
        zIndex={2}
        position="absolute"
        inset={0}
        ref={middle}
        style={{
          transition: 'transform 1s ease-out',
          perspective: '150px',
        }}
      >
        {middleImages.map((v) => (
          <ImageSkew v={v} key={v.toString()} />
        ))}
      </Box>
      <Box
        zIndex={1}
        position="absolute"
        inset={0}
        ref={bottom}
        style={{
          transition: 'transform 1s ease-out',
          perspective: '200px',
        }}
      >
        {bottomImages.map((v) => (
          <ImageSkew v={v} key={v.toString()} />
        ))}
      </Box>
    </Section>
  );
};

const ImageSkew: React.FC<{ v: Array<number> }> = ({v}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    /**
     * Handles the skewing of the images based on the mouse position.
     * Only used on devices that have a mouse.
     * @param {MouseEvent} e - The mouse event.
     */
    function skewImage(e: MouseEvent) {
      if (ref.current) {
        // sets the origin position to the center of the image
        const originX = Math.floor(window.innerWidth / 2);
        const originY = Math.floor(window.innerHeight / 2);
        // position of mouse relative to 0,0
        const mousePositionX = e.clientX - originX;
        const mousePositionY = (e.clientY - originY) * -1;

        const x = (mousePositionY / window.innerHeight / 2).toFixed(2);
        const y = (mousePositionX / window.innerWidth / 2).toFixed(2);
        ref.current.style.transform = `rotateX(${x}deg) rotateY(${y}deg)`;
      }
    }
    if (!isMobile) {
      document.addEventListener('mousemove', skewImage);
    }
    return function cleanup() {
      document.removeEventListener('mousemove', skewImage);
    };
  }, [ref]);
  return (
    <AbsoluteImage
      ref={ref}
      left={v[0]}
      top={v[1]}
      width={v[2]}
      height={v[3]}
      style={{
        transition: 'transform 1s ease-out',
      }}
    >
      <Image
        src={`/images/home-hero-${v[4]}.jpg`}
        width={v[2]}
        height={v[3]}
        layout="intrinsic"
        alt=""
      />
    </AbsoluteImage>
  );
};
