import {FC} from 'react';
import {LottieProps} from '.';
import Lottie from '@lottielab/lottie-player/react';

export const LottieDynamic: FC<LottieProps> = ({
  loop = true,
  path,
  state = 'play',
  width,
  height,
}) => {
  return (
    <div
      style={{
        height: height ? `${height}px` : undefined,
        width: width ? `${width}px` : undefined,
      }}
    >
      <Lottie src={path} loop={loop} autoplay={state === 'play'} playing={state === 'play'} />
    </div>
  );
};
