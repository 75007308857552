import {useCallback} from 'react';
import {Maybe, when} from '@mindfulness/utils/maybe';
import {joinCohort} from '../../../api';

export const useAddCohort = () => {
  /**
   * Adds a logged in user to cohorts
   */
  const handleAddCohort = useCallback(
      async (variables: { cohort: Maybe<string> }) =>
        when(variables?.cohort, async (cohort) => {
          await joinCohort({cohort});
        }),
      [],
  );

  return handleAddCohort;
};
