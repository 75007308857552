// These functions should only be called from the client side.
// TODO: switch all functions to be called using `getGraphClient()`
import {
  MUTATE_JOIN_BUNDLE,
  MUTATE_REDEEM_GIFT_CARD_ANONYMOUSLY,
  MUTATE_REDEEM_SINGLE_ACCESS,
  MUTATE_UPDATE_PREFERENCES,
  GET_ONBOARDING_DATA,
  GET_SINGLE_LOCKED,
  MUTATE_TRACK_EVENTS,
  GET_BUNDLE,
  GET_SINGLE_BY_ID,
  MUTATE_ADD_TO_ORDER,
  GET_ORDER,
  MUTATE_REMOVE_FROM_ORDER,
  GET_FINALIZED_ORDER,
} from '../';
import {client, getGraphClient} from '../../utils';
import {UserInfo} from '../../types/api';
import {
  GetOnboardingDataQuery,
  GetSingleLockedQuery,
  GetSingleLockedQueryVariables,
  JoinBundleMutation,
  JoinBundleMutationVariables,
  QueryOptions,
  RedeemGiftCardAnonymouslyMutation,
  RedeemGiftCardAnonymouslyMutationVariables,
  RedeemSingleAccessMutationVariables,
  UpdatePreferencesMutation,
  UpdatePreferencesMutationVariables,
} from '../../types/types';
import {
  AddToOrderMutation,
  AddToOrderMutationVariables,
  GetBundleQuery,
  GetBundleQueryVariables,
  GetOrderQuery,
  GetOrderQueryVariables,
  RemoveFromOrderMutation,
  RemoveFromOrderMutationVariables,
  SingleByIdQuery,
  SingleByIdQueryVariables,
  SingleDetailFragment,
  TrackEventsMutationVariables,
} from '../../types/api';

// They may require user tokens to work correctly
export const redeemGiftCardAnonymously = async (
    variables: RedeemGiftCardAnonymouslyMutationVariables,
) => {
  const res = await client.mutate<RedeemGiftCardAnonymouslyMutation>({
    mutation: MUTATE_REDEEM_GIFT_CARD_ANONYMOUSLY,
    variables,
  });
  return res.data;
};

export const updatePreferences = async (
    variables: UpdatePreferencesMutationVariables,
) => {
  const res = await client.mutate<UpdatePreferencesMutation>({
    mutation: MUTATE_UPDATE_PREFERENCES,
    variables,
  });
  return res.data;
};

export const joinBundle = async (variables: JoinBundleMutationVariables) => {
  const res = await client.mutate<JoinBundleMutation>({
    mutation: MUTATE_JOIN_BUNDLE,
    variables,
  });
  return res.data;
};

export const redeemSingleAccess = async (
    variables: RedeemSingleAccessMutationVariables,
) => {
  const res = await client.mutate({
    mutation: MUTATE_REDEEM_SINGLE_ACCESS,
    variables,
  });
  return res.data;
};

export const singleIsLocked = async (
    variables: GetSingleLockedQueryVariables,
) => {
  const res = await client.query<GetSingleLockedQuery>({
    query: GET_SINGLE_LOCKED,
    variables,
  });
  return res.data.single.userData.locked;
};

export const hasCompletedOnboarding = async () => {
  const res = await client.query<GetOnboardingDataQuery>({
    query: GET_ONBOARDING_DATA,
  });
  return res.data.session.user.missingInfo?.includes(UserInfo.PrimaryTopic);
};

export const trackEvents = async (variables: TrackEventsMutationVariables) => {
  const {data} = await getGraphClient().mutate({
    mutation: MUTATE_TRACK_EVENTS,
    variables,
  });
  return data.events;
};

export const getBundle = async (
    variables: GetBundleQueryVariables,
    options?: QueryOptions,
) => {
  const res = await getGraphClient().query<GetBundleQuery>({
    query: GET_BUNDLE,
    ...options,
    variables,
  });
  return res.data.bundle;
};

export const getSingleById = async (
    variables: SingleByIdQueryVariables,
    options?: QueryOptions,
): Promise<SingleDetailFragment> => {
  const {data} = await getGraphClient().query<SingleByIdQuery>({
    query: GET_SINGLE_BY_ID,
    variables,
    fetchPolicy: 'network-only',
    ...options,
  });
  return data.single;
};

export const addToOrder = async (variables: AddToOrderMutationVariables) => {
  const {data} = await getGraphClient().mutate<AddToOrderMutation>({
    mutation: MUTATE_ADD_TO_ORDER,
    variables,
    fetchPolicy: 'network-only',
  });
  return data?.addToOrder;
};

export const removeFromOrder = async (
    variables: RemoveFromOrderMutationVariables,
) => {
  const {data} = await getGraphClient().mutate<RemoveFromOrderMutation>({
    mutation: MUTATE_REMOVE_FROM_ORDER,
    variables,
    fetchPolicy: 'network-only',
  });
  return data?.removeFromOrder;
};

export const getOrder = async (variables: GetOrderQueryVariables) => {
  const {data} = await getGraphClient().query<GetOrderQuery>({
    query: GET_ORDER,
    variables,
    fetchPolicy: 'network-only',
  });
  return data?.order;
};

export const getFinalizedOrder = async (variables: GetOrderQueryVariables) => {
  const {data} = await getGraphClient().query<GetOrderQuery>({
    query: GET_FINALIZED_ORDER,
    variables,
    fetchPolicy: 'network-only',
  });
  return data?.order;
};
