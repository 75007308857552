import styled from '@emotion/styled';

export const ModalWrapper = styled.div<{
  open: boolean;
}>(
    ({theme, open}) => `
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 30;
  background-color: rgba(55, 55, 75, 0.6);
  transition: opacity ease 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  ${
    open ?
      `
    opacity: 1;
    pointer-events: all;
  ` :
      `
    opacity: 0;
    pointer-events: none;
  `
}

`,
);

export const ModalOuter = styled.div(({}) => `
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`);

export const ModalInner = styled.div(
    ({theme}) => `

    background-color: ${theme.colors.white};
    z-index: 1;
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    max-height: 100%;
    border-radius: ${theme.radius.lg} ${theme.radius.lg} 0 0;

    @media (min-width: ${theme.breakpoints.md.min}) {
      top: 0!important;
      max-width: 520px;
      width: 100%;
      position: relative;
      border-radius: ${theme.radius.lg};
      overflow-y: auto;
    }
`,
);

export const CloseButtonWrapper = styled.div(({theme}) => `
  display: none;
  position: absolute;
  right: 8px;
  top: 8px;
  @media (min-width: ${theme.breakpoints.md.min}) {
    display: block;
  }
`);

export const BottomSheetWrapper = styled.div(() => `
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 1rem;
  position: relative;
`);

export const BottomSheetOverFlow = styled.div(() => `
  padding-top: 2rem;
  flex: 1;
  overflow-y: auto;
`);

export const BottomSheetBar = styled.div(
    ({theme}) => `

margin: 0 auto 0 auto;
min-height: 3px;
width: 80px;
border-radius: 2px;
background: ${theme.colors.grey4};


`,
);

export const DragBox = styled.div<{dragging: boolean}>(({
  dragging,
}) => `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  ${dragging ? 'display: block;' : 'display: none;'}
`);

export const BottomSheetBox = styled.div(({theme}) => `
position: absolute;
z-index: 20;
top: 0;
width: 100%;
height: 30px;
padding-top: 1rem;
@media (min-width: ${theme.breakpoints.md.min}) {
  display: none;
}
`);
