import {HTMLAttributes, PropsWithChildren} from 'react';
import {when} from '@mindfulness/utils/maybe';
import {Theme} from '../../../types/emotion';
import styleUtils from '../../../styles/utils.module.css';
import style from './AspectRatio.module.css';

export const AspectRatio: React.FC<PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>> = ({
  children,
  spacing,
  ratio,
  zIndex,
  items = 'center',
  justify = 'center',
  ...props
}) => {
  const _ratio = when(ratio, (s) => {
    const [w, h] = s.split(':').map(Number);
    return w && h ? (h / w) * 100 : undefined;
  });
  return (
    <div
      className={[
        ...(spacing ? [styleUtils[`mb-${spacing}`]] : []),
        ...(zIndex ? [styleUtils[`z-index`]] : []),
        ...(_ratio ? [style.ratio] : []),
      ].join(' ')}
      style={{
        ...props.style,
        ...(zIndex ? ({
          '--z-index': zIndex,
        }) : ({})),
        ...(_ratio ? ({
          '--ratio': `${_ratio}%`,
          '--items': items,
          '--justify': justify,
        }) : {}),
      }}
      {...props}
    >
      {children}
    </div>
  );
};


type Props = {
  spacing?: keyof Theme['spacings'];
  /** e.g 16:9 */
  ratio?: string;
  zIndex?: number;
  justify?: React.CSSProperties['justifyContent'];
  items?: React.CSSProperties['alignItems'];
};
