import * as React from 'react';
import {RichText} from '@mindfulness/cms';
import {ApolloError} from '@apollo/client';

import {Alert, Input, Button} from '../../forms';
import {Column, Container, Flex, Row} from '../../layout';
import {Strong, Text, Title1} from '../../typography';
import {
  Divider,
  PolicyLinks,
  FacebookLogin,
  GoogleLogin,
  AppleLogin,
} from '../../ui';
import {useForm, useLoggedInAs} from '../../../hooks';
import {BlockContent, Context, ModalContext} from '../../global';
import {LinkButton} from '../../forms';
import {cleanEmail, switchSpace, validateEmail} from '../../../utils';
import {NoticeBox} from '../NoticeBox';

export const SignUpForm: React.FC<Props> = ({
  title,
  accessTitle,
  paragraph,
  subtitle,
  notice,
  requireVerify = true,
  setView,
  onVerify,
  buttonText,
  suggestedEmail,
  redirect,
  name = 'action',
  verifyLink,
  onToPage,
  setLoading,
}) => {
  const {
    data: contextData,
    handlePasswordlessSignup,
    handleAction,
    handleLogout,
    session,
    initialSession,
  } = React.useContext(Context);

  const {openModal} = React.useContext(ModalContext);
  const loggedInAs = useLoggedInAs();
  const action = React.useMemo(() => contextData?.action, [contextData]);
  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    email: string;
    name: string;
  }>({
    initialValues: {
      email: suggestedEmail || '',
      name: '',
    },
    onSubmit: async (e, {data, setErrors}) => {
      try {
        await handlePasswordlessSignup?.({
          ...data,
          cohort: contextData?.action?.cohort,
          modalName: name,
          requireVerify,
          onVerify,
          redirect,
          verifyLink,
          onToPage,
        });
      } catch (err) {
        if (err instanceof ApolloError || err instanceof Error) {
          setErrors({
            form: err.message,
          });
        }
      }
    },
    validations: {
      email: {
        required: {
          value: true,
          message: 'Please enter your email address',
        },
        custom: {
          isValid: validateEmail,
          message: 'Please enter a valid email address',
        },
      },
      name: {
        required: {
          value: true,
          message: 'Please enter your name',
        },
      },
    },
  });

  return (
    <Container maxWidth="sm">
      <Row spacing={16}>
        <Column>
          <Title1 as="p" spacing={10}>
            {initialSession ?
              accessTitle || 'Continue with this account' :
              title ||
                action?.modal?.title ||
                'Sign up or login to your mindfulness account to proceed.'}
          </Title1>
          {subtitle || (initialSession && suggestedEmail) ? (
            <Text colour="grey7">
              {initialSession && suggestedEmail ? (
                <SuggestedText email={suggestedEmail} />
              ) : (
                subtitle
              )}
            </Text>
          ) : null}

          {paragraph ? (
            <BlockContent
              content={paragraph}
              background="white"
              colour="grey7"
              spacing={16}
            />
          ) : action?.modal?.paragraph ? (
            <>
              {/* TODO: deprecate non-richtext action paragraphs  */}
              {typeof action?.modal?.paragraph === 'object' ? (
                <BlockContent
                  content={action?.modal?.paragraph}
                  background="white"
                  colour="grey7"
                  spacing={16}
                />
              ) : (
                <Text colour="grey7">
                  {action?.modal?.paragraph ||
                    'Enter your email and we’ll send you an Instant App Access link. Open the email on your phone, click that link, and you’re in!'}
                </Text>
              )}
            </>
          ) : null}
          {notice && <NoticeBox>{notice}</NoticeBox>}
        </Column>
      </Row>
      {initialSession ? (
        <Row spacing={16}>
          {suggestedEmail ? (
            <Column spacing={24}>
              <LinkButton
                onClick={async () => {
                  await handleLogout?.();
                }}
              >
                {`Switch to ${suggestedEmail}`}
              </LinkButton>
            </Column>
          ) : null}
          <Column spacing={10}>
            <Input
              label={'Currently logged in as'}
              placeholder="Currently logged in as"
              value={loggedInAs}
              readOnly
              type="email"
            />
          </Column>
          <Column spacing={30}>
            {suggestedEmail ? null : (
              <Text>
                {'Not you? '}
                <LinkButton
                  onClick={async () => {
                    await handleLogout?.();
                  }}
                >
                  Switch Account
                </LinkButton>
              </Text>
            )}
          </Column>
          <Column>
            <Button
              id="openApp"
              wide
              type="button"
              onClick={async () => {
                if (redirect) {
                  redirect({
                    email: session?.email,
                    name: session?.user.fullName,
                  });
                  return;
                }
                handleAction?.(session?.email);
              }}
            >
              {buttonText || 'Use current account'}
            </Button>
          </Column>
        </Row>
      ) : (
        <>
          <Row as="form" spacing={16} onSubmit={handleSubmit}>
            <Alert error={errors?.form} />
            <Column spacing={24}>
              <Input
                label="Email"
                placeholder="Enter your email address"
                value={cleanEmail(data?.email)}
                error={errors?.email}
                type="email"
                onChange={handleChange('email', (s) => s.toLowerCase())}
              />
            </Column>
            {data?.email.length ? (
              <Column spacing={24}>
                <Input
                  label="Name"
                  placeholder="Enter your name"
                  value={data?.name}
                  error={errors?.name}
                  type="text"
                  onChange={handleChange('name')}
                />
              </Column>
            ) : null}

            <Column spacing={10}>
              <Button
                wide
                id="actionSubmit"
                loading={loading || undefined}
                type="submit"
              >
                {action?.modal?.buttonText ||
                  buttonText ||
                  'Get Instant Access'}
              </Button>
            </Column>

            {switchSpace({
              'mindfulness.com': () => (
                <>
                  <Column spacing={10}>
                    <Divider text="or continue with" spacing={16} />
                  </Column>
                  <Column spacing={10}>
                    <Flex wrap="nowrap" gap={10}>
                      <FacebookLogin redirect={redirect} setLoading={setLoading} />
                      <GoogleLogin redirect={redirect} setLoading={setLoading} />
                      <AppleLogin redirect={redirect} setLoading={setLoading} />
                    </Flex>
                  </Column>
                </>
              ),
              'else': () => null,
            })}

            <Column>
              <PolicyLinks />
            </Column>
            <Column>
              <Divider spacing={16} />
            </Column>
            <Column>
              <Text>
                {`Do you already have an account? `}
                <LinkButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    if (setView) {
                      setView('login');
                      return;
                    }
                    openModal?.({
                      name,
                      props: {
                        onVerify,
                        requireVerify,
                        view: 'login',
                        email: data.email,
                        redirect,
                        onToPage,
                      },
                    });
                  }}
                >
                  Sign in
                </LinkButton>
              </Text>
            </Column>
          </Row>
        </>
      )}
    </Container>
  );
};

const SuggestedText: React.FC<{ email: string }> = ({email}) => {
  return (
    <>
      {`It looks like you might be trying to access content from a different mindfulness account. (`}
      <Strong>{email}</Strong>
      {`).`}
    </>
  );
};

type Props = {
  small?: boolean;
  buttonText?: string;
  title?: string;
  suggestedEmail?: string;
  accessTitle?: string;
  subtitle?: string;
  notice?: string;
  name?: string;
  paragraph?: RichText;
  requireVerify?: boolean;
  setView?: React.Dispatch<React.SetStateAction<string>>;
  onVerify?: (params: { email?: string; name?: string }) => void;
  redirect?: (params: { email?: string; name?: string }) => void;
  verifyLink?: string;
  onToPage?: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
