import * as React from 'react';
import Image from 'next/image';
import {Text} from '../../typography';
import {Box, Cluster} from '../../layout';

import {BlockLinks} from './DownloadLinks.styles';

export const DownloadLinks: React.FC<{ hideTitle?: boolean }> = ({
  hideTitle,
}) => (
  <Box>
    {hideTitle ? null : (
      <Text colour="grey7" fontSize="sm" spacing={10}>
        App available on
      </Text>
    )}
    <Cluster gap={10}>
      <BlockLinks
        href="https://apps.apple.com/app/mindfulness-com-meditation-app/id1466046486"
        target="_blank"
        rel="noreferrer"
        aria-label="Download on the App Store"
      >
        <Image
          width={125}
          height={42}
          src="/images/download-appleStore.svg"
          alt="App Store"
        />
      </BlockLinks>
      <BlockLinks
        href="https://play.google.com/store/apps/details?id=com.mindfulness.android"
        target="_blank"
        rel="noreferrer"
      >
        <Image
          width={142}
          height={42}
          src="/images/download-googlePlay.svg"
          alt="Google Play"
          aria-label="Download on Google Play"
        />
      </BlockLinks>
    </Cluster>
  </Box>
);
