import {switchEnum} from '@mindfulness/utils/logic';
import {Maybe} from '../types/api';
import {isOnClient} from './next';

export const mapDeeplink = (deeplink: Maybe<string> | null): Maybe<string> => {
  if (!deeplink) {
    return;
  }
  const [path, search] = deeplink.replace('mcom://', '').split('?');
  const [root, a, b, c] = path.split('/');
  const queryParams = new URLSearchParams(search);

  return switchEnum(root, {
    single: () => {
      return `/play?s=${a}${b && b !== 'play' ? `&v=${b}` : ''}`;
    },
    bundle: () => {
      if (b === 'play') {
        return `/play?b=${a}`;
      }
      if (b === 'single') {
        const v = queryParams.get('variant');
        return `/play?b=${a}&s=${c}${v ? `&v=${v}` : ''}`;
      }
      if (a) {
        return `/play?b=${a}`;
      }
    },
    series: () => {
      return `/play?b=${a}`;
    },
    playlist: () => {
      return `/play?b=${a}`;
    },
    collection: () => {
      if (b === 'teachers') {
        return `/teachers`;
      }
      return `/collection/${[a, b].join('/')}`;
    },
    feed: () => `/feed/${a}`,
    teachers: () => '/teachers',
    home: () => '/',
    mini: () => '/feed/mini',
    sleep: () => '/feed/sleep',
    questionsheet: () => {
      if (!isOnClient()) return '';
      const query = new URLSearchParams();
      query.set('questionsheet', a);
      const queryString = query.toString();
      return `${window.location.pathname}${queryString ? `?${queryString}` : ''}`;
    },
    profile: () => '/profile',
    // collection: () => `/collection/${b}`,
    else: () => undefined,
  });
};
