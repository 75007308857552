import * as React from 'react';

import {Context, ModalContext, OverlayModal} from '../../global';
import {H2, Small} from '../../typography';
import {Box, Column, Container, Row} from '../../layout';
import {Divider} from '../../ui';
import {Alert, Button, Input} from '../../forms';
import {useForm} from '../../../hooks';
import {validateEmail} from '../../../utils';

export const EmailModal: React.FC = () => {
  const {closeModal, activeProps} = React.useContext(ModalContext);
  const props = React.useMemo(() => activeProps.email, [activeProps.email]);

  const {handleAddEmail} = React.useContext(Context);
  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    email: string;
  }>({
    initialValues: {
      email: '',
    },
    onSubmit: async (e, {data, setErrors}) => {
      try {
        await handleAddEmail?.(data.email, props.useAction);
      } catch {
        return setErrors({
          email: 'The email address you entered is already in use.',
        });
      }
    },
    validations: {
      email: {
        required: {
          value: true,
          message: 'Please enter your email address',
        },
        custom: {
          isValid: validateEmail,
          message: 'Please enter a valid email address',
        },
      },
    },
  });

  return (
    <OverlayModal name="email">
      <Box maxWidth="520px">
        <Container>
          <Row spacing={40}>
            <Column textAlign="center">
              <H2 as="p">Email Missing</H2>
              <Small>
                {
                  'We couldn’t detect your email with the SSO provider you have selected.'
                }
              </Small>
            </Column>
          </Row>
          <Row as="form" onSubmit={handleSubmit}>
            <Column>
              <Alert error={errors?.form} />
            </Column>
            <Column spacing={30}>
              <Input
                label="Email"
                placeholder="Enter your email address"
                value={data.email}
                error={errors?.email}
                type="email"
                onChange={handleChange('email', (s) => s.toLowerCase())}
              />
            </Column>
            <Column spacing={20}>
              <Button
                wide
                id="emailSubmit"
                loading={loading || undefined}
                type="submit"
              >
                {'Complete Your Account'}
              </Button>
            </Column>
            <Column spacing={20}>
              <Divider text="or" />
            </Column>
            <Column>
              <Button
                wide
                id="emailContinue"
                variant="secondary"
                onClick={() => closeModal?.({name: 'email'})}
                loading={loading || undefined}
                type="button"
              >
                Continue Without Email
              </Button>
            </Column>
          </Row>
        </Container>
      </Box>
    </OverlayModal>
  );
};
