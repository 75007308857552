import {gql} from '@apollo/client';
import {
  BundleCheckoutFragment,
  BundleDetailFragment,
  CollectionTagFragment,
  FinalizedOrderFragment,
  OrderFragment,
  PlanCouponFragment,
  PlanDiscountFragment,
  PlanFragment,
  ProducerFragment,
  PurchaseFragment,
  PurchaseWithCouponFragment,
  SessionFragment,
  SingleDetailFragment,
  SingleFragment,
  TagCollectionFragment,
  TagFeedFragment,
  TopicTagFragment,
} from './fragments';
import {CollectionFeedFragment} from './feedFragments';

export const GET_SESSION = gql`
  query getSession {
    session {
      ... on Session {
        ...SessionFragment
      }
    }
  }
  ${SessionFragment}
`;

export const GET_SINGLE_BY_WEB_SLUG = gql`
  query singleBySlug($webSlug: String!, $withUserData: Boolean!, $resultsTake: Int!) {
    single(webSlug: $webSlug) {
      ...SingleDetailFragment
    }
  }
  ${SingleDetailFragment}
`;

export const GET_SINGLE_BY_ID = gql`
  query singleById($id: ID!, $withUserData: Boolean!, $resultsTake: Int!) {
    single(id: $id) {
      ...SingleDetailFragment
    }
  }
  ${SingleDetailFragment}
`;

export const GET_PRODUCERS = gql`
  query getProducers {
    producers(
      page: { skip: 0, take: 2000 }
      filter: { hasWebSlug: true, type: TEACHER }
      sort: { method: POPULAR, direction: DESCENDING }
    ) {
      items {
        ...ProducerFragment
      }
    }
  }
  ${ProducerFragment}
`;

export const GET_SINGLES_SLUGS = gql`
  query getSinglesSlugs($take: Int!, $skip: Int!, $type: SingleType!) {
    singles(
      page: { skip: $skip, take: $take }
      filter: { status: LIVE, type: $type, hasWebSlug: true }
    ) {
      items {
        webSlug
      }
    }
  }
`;

export const GET_TAG_FEED = gql`
  query getTagFeed($id: ID!, $withUserData: Boolean!, $resultsTake: Int!) {
    tagFeed(id: $id) {
      ...TagFeedFragment
    }
  }
  ${TagFeedFragment}
`;

export const GET_RELATED_FEED = gql`
  query getRelatedFeeds($id: ID!) {
    tagFeed(id: $id) {
      relatedFeeds {
        id
        title
      }
    }
  }
`;

export const GET_ALL_FEED_IDS = gql`
  query getAllFeedIds {
    tags(types: [FEED, TOPIC]) {
      id
      type
    }
  }
`;

export const GET_HUB_COLLECTIONS = gql`
  query getHubCollections($id: ID!) {
    tagCollections(parent: { slug: $id }) {
      ...TagCollectionFragment
    }
  }
  ${TagCollectionFragment}
`;

export const GET_TOPICS = gql`
  query getTopics($slugs: [ID!]) {
    tags(slugs: $slugs) {
      ...TopicTagFragment
    }
  }
  ${TopicTagFragment}
`;

export const GET_ALL_TOPICS = gql`
  query getAllTopics {
    topics {
      ...TopicTagFragment
    }
  }
  ${TopicTagFragment}
`;

export const GET_TAG_COLLECTIONS = gql`
  query getTagCollections {
    tagCollections {
      ...TagCollectionFragment
    }
  }
  ${TagCollectionFragment}
`;

export const GET_ALL_COLLECTIONS_PATHS = gql`
  query getAllCollections {
    tags(type: COLLECTION) {
      ...CollectionTagFragment
    }
  }
  ${CollectionTagFragment}
`;

export const GET_TAG_COLLECTION = gql`
  query getTagCollection($id: ID!, $withUserData: Boolean!, $resultsTake: Int!) {
    tagCollection(id: $id) {
      ...CollectionFeedFragment
    }
  }
  ${CollectionFeedFragment}
`;

export const GET_TAG_COLLECTION_BY_SLUG = gql`
  query getTagCollectionBySlug($webSlug: String!, $withUserData: Boolean!, $resultsTake: Int!) {
    tagCollection(webSlug: $webSlug) {
      ...CollectionFeedFragment
    }
  }
  ${CollectionFeedFragment}
`;

export const GET_PRODUCER = gql`
  query getProducer($id: ID!) {
    producer(id: $id) {
      ...ProducerFragment
    }
  }
  ${ProducerFragment}
`;

export const GET_SINGLE = gql`
  query getSingle($id: ID!) {
    single(id: $id) {
      ...SingleFragment
    }
  }
  ${SingleFragment}
`;

export const GET_PLAYLIST_PATHS = gql`
  query getPlaylists($withUserData: Boolean!, $resultsTake: Int!) {
    bundles(filter: { type: PLAYLIST }) {
      items {
        ...BundleDetailFragment
      }
    }
  }
  ${BundleDetailFragment}
`;

export const GET_PLANS = gql`
  query getPlans($plans: [String!]!) {
    plans(aliases: $plans) {
      ...PlanFragment
    }
  }
  ${PlanFragment}
  ${PlanDiscountFragment}
`;
export const GET_COUPON_PLANS = gql`
  query getCouponPlans($plans: [String!]!, $coupon: String!) {
    plans(aliases: $plans) {
      ...PlanCouponFragment
    }
  }
  ${PlanCouponFragment}
  ${PlanDiscountFragment}
`;

export const GET_CHECKOUT_BUNDLE = gql`
  query getCheckoutBundle($id: String!) {
    bundle(id: $id) {
      ...BundleCheckoutFragment
    }
  }
  ${BundleCheckoutFragment}
`;

export const GET_BUNDLE = gql`
  query getBundle($id: String!, $withUserData: Boolean!, $resultsTake: Int!) {
    bundle(id: $id) {
      ...BundleDetailFragment
    }
  }
  ${BundleDetailFragment}
`;

export const GET_SINGLE_LOCKED = gql`
  query getSingleLocked($id: ID!) {
    single(id: $id) {
      userData {
        locked
      }
    }
  }
`;

export const GET_ONBOARDING_DATA = gql`
  query getOnboardingData {
    session {
      user {
        missingInfo
      }
    }
  }
`;

export const GET_ORDER = gql`
  query getOrder($order: ID!) {
    order(id: $order) {
      ...OrderFragment
    }
  }
  ${OrderFragment}
`;

export const GET_FINALIZED_ORDER = gql`
  query getFinalizedOrder($order: ID!) {
    order(id: $order) {
      ...FinalizedOrderFragment
    }
  }
  ${FinalizedOrderFragment}
`;

export const GET_PURCHASABLES = gql`
  query getPurchasables($ids: [ID!]) {
    purchasables(ids: $ids) {
      ...PurchaseFragment
    }
  }
  ${PurchaseFragment}
`;

export const GET_PURCHASABLE_WITH_COUPON = gql`
  query getPurchasablesWithCoupon($ids: [ID!], $coupon: String) {
    purchasables(ids: $ids) {
      ...PurchaseWithCouponFragment
    }
  }
  ${PurchaseWithCouponFragment}
`;
