import * as React from 'react';
import QRCode from 'qrcode.react';

import {Box, Tooltip} from '../../layout';
import {Maybe} from '../../../types/types';

import {OnClient} from '../OnClient';

export const QRCodeSection: React.FC<Props> = ({qrCodeLink}) => (
  <Box
    spacing={30}
    display={{
      xs: 'none',
      md: 'block',
    }}
  >
    <Box
      display="inline-block"
      position="relative"
      height="100px"
      width="100px"
      paddingR={16}
    >
      <OnClient>
        {qrCodeLink ? <QRCode value={qrCodeLink} size={100} /> : null}
      </OnClient>

      <Tooltip show={true}>
        Scan the following QR code with your camera app to open it on your phone
      </Tooltip>
    </Box>
  </Box>
);

type Props = {
  qrCodeLink: Maybe<string>;
};
