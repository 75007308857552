import {unNull} from '../../../utils/maybe';
import {withLink, WithLinkProps} from '../../../hoc/withLink';
import {FeatureFeedFragment} from '../../../types/api';
import {APIImage} from '../../global';
import {AspectRatio} from '../../layouts/AspectRatio';
import {ItemContainer} from './FeatureCard.styles';
import {Box} from '../../layout/Box';

export const FeatureCard: React.FC<WithLinkProps & FeatureFeedFragment & {
  priority?: boolean;
}> =
  withLink(({heightRatio, cardImage, title, priority}) => (
    <ItemContainer>
      <AspectRatio ratio={`1:${unNull(heightRatio)}`}>
        <Box
          color="white"
          position="relative"
          zIndex={1}
          radius="lg"
          overflow="hidden"
          backgroundColor='rgba(55, 55, 75, 0.2)'
        >
          <APIImage
            ratio={unNull(heightRatio)}
            url={cardImage?.url}
            alt={title}
            className="cell-image"
            priority={priority}
          />
        </Box>
      </AspectRatio>
    </ItemContainer>
  ));
