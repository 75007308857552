import {useCallback} from 'react';
import {switchEnum} from '@mindfulness/utils/logic';
import {omitEmpty} from '@mindfulness/utils/object';
import {when} from '@mindfulness/utils/maybe';
import {PaymentMethod} from '@stripe/stripe-js';

import {
  AuthedSession,
  assertString,
  defaultCheckoutThankyou,
} from '../../../utils';
import {
  handleEventPurchase,
  handleGiftcardPurchase,
  handlePurchase,
} from '../../../api';
import {Maybe, PlanFragment, CheckoutType} from '../../../types/types';

import {SubscriptionError} from '../../../types/api';
import {useAnalytics} from '../SegmentProvider';
import {useNewNavigation} from '../../../hooks/useNewNavigation';

export const useCheckout = ({session}: Props) => {
  const {goTo, getUtmParams, getQueryParam} = useNewNavigation();
  const {track, identify} = useAnalytics();

  const handleCheckout: HandleCheckout = useCallback(
      async ({name, email, paymentMethod, coupon, onto, plan, trial}) => {
        try {
          // Re-identify
          await identify(omitEmpty({email, name}));
          await track('Subscription purchase initiated', {});

          const res = await handlePurchase({
            session,
            coupon,
            data: {
              fullName: assertString(name),
              email,
            },
            plan,
            paymentMethod,
            trial,
          });

          if (!res.data?.purchasePlanAnonymously.id) {
            throw new Error('Could not purchase subscription');
          }

          await track('Subscription purchase completed', {
            affiliation: 'Stripe',
            order_id: res.data?.purchasePlanAnonymously.id,
            revenue: Number(
                plan?.discount?.finalPrice ?? plan?.price?.price ?? 0,
            ),
            currency: 'USD',
            products: [
              {
                product_id: plan.planCode,
                name: `Mindfulness Plus - ${plan.name}`,
              },
            ],
          });
          goTo({
            pathname:
            when(onto, (o) => decodeURIComponent(o).split('?')[0]) ||
            defaultCheckoutThankyou(),
            query: omitEmpty({
              link: res.data?.generateInstantAccess.link ?? undefined,

              // Passthrough utm values
              ...getUtmParams(),
            }),
          });
        } catch (e) {
          await track('Subscription purchase aborted', {});
          if (e instanceof Error) {
            if (e.message === SubscriptionError.BillingError) {
              return `We attempted to charge your card but recieved an error from your bank. \
          Try another card or contact your bank to allow the charge.`;
            }
            return e.message;
          }
        }
      },
      [identify, track],
  );

  /**
   * Purchases a giftcard with the provided payment details and redirects the user to the relevant payment page.
   * Returns a string if there was an error.
   * @param {Object} options - Payment details
   * @return {void | string} - Returns an error or string or nothing id the operation was successful
   */
  const handleCheckoutGiftcard: HandleCheckoutGiftcard = async ({
    email,
    name,
    paymentMethod,
    coupon,
    onto,
    plan,
  }) => {
    try {
      // Re-identify
      when(email, async (e) => await identify(omitEmpty({email: e, name})));
      await track('Gift card purchase initiated', {});
      const res = await handleGiftcardPurchase({
        session,
        plan,
        coupon,
        paymentMethod,
        data: {
          email,
          fullName: name,
        },
      });

      if (!res.data?.purchaseGiftCardAnonymously.id) {
        throw new Error('Could not purchase subscription');
      }
      const accessPass = res?.data?.purchaseGiftCardAnonymously;
      if (!accessPass) {
        throw new Error('No access pass returned from gift card purchase');
      }
      await track('Gift card purchase completed', {
        affiliation: 'Stripe',
        order_id: accessPass.id,
        revenue: Number(plan?.discount?.finalPrice ?? plan?.price?.price ?? 0),
        currency: 'USD',
        products: [
          {
            product_id: plan.planCode,
            name: `Mindfulness Plus - ${plan.name}`,
          },
        ],
      });

      goTo({
        pathname:
          when(onto, (o) => decodeURIComponent(o).split('?')[0]) ||
          '/giftcard-purchased',
        query: omitEmpty({
          link: getQueryParam('link'),
          ...(onto ?
            {} :
            {
              email: email,
              redeemUrl: accessPass.url,
              redeemCode: accessPass.code,
            }),
          // Passthrough utm values
          ...getUtmParams(),
        }),
      });
    } catch (e) {
      if (e instanceof Error) {
        await track('Gift card purchase aborted', {
          error: e.message,
        });
        if (e.message === SubscriptionError.BillingError) {
          return `We attempted to charge your card but recieved an error from your bank. \
         Try another card or contact your bank to allow the charge.`;
        }
        return e.message;
      }
    }
  };

  const handleCheckoutEvent: HandleCheckout = useCallback(
      async ({
        name,
        email,
        paymentMethod,
        coupon,
        onto,
        plan,
        trial,
        upgrade,
        bundleSlug,
        payIn3,
      }) => {
        try {
          if (!bundleSlug) {
            throw new Error('Bundle slug not provided');
          }
          // Re-identify
          await identify(omitEmpty({email, name}));
          await track('Event purchase initiated', {});

          await handleEventPurchase({
            session,
            data: {
              fullName: assertString(name),
              email,
            },
            event: {
              slug: bundleSlug,
              plusUpgrade: upgrade,
              ...(payIn3 ? {paymentInstalments: 3} : {}),
            },
            plan: {
              alias: plan.webAlias ?? plan.code,
              coupon,
              trial: trial || 0,
            },
            paymentMethod,
          });

          await track('Event purchase completed', {
            affiliation: 'Stripe',
            order_id: '',
            revenue: Number(
                plan?.discount?.finalPrice ?? plan?.price?.price ?? 0,
            ),
            currency: 'USD',
            products: [
              {
                product_id: plan.planCode,
                name: `${plan.name}`,
                payment_instalments: payIn3 ? 3 : 1,
              },
            ],
          });
          goTo({
            pathname:
            when(onto, (o) => decodeURIComponent(o).split('?')[0]) ||
            defaultCheckoutThankyou(),
            query: omitEmpty({
            // Passthrough utm values
              ...getUtmParams(),
            }),
          });
        } catch (e) {
          if (e instanceof Error) {
            await track('Event purchase aborted', {});
            if (e.message === SubscriptionError.BillingError) {
              return `We attempted to charge your card but recieved an error from your bank. \
        Try another card or contact your bank to allow the charge.`;
            }
            return e.message;
          }
        }
      },
      [],
  );

  const method = useCallback(
      (type: CheckoutType) => {
        const defaultOnto = defaultCheckoutThankyou();
        return switchEnum<
        'bogo' | 'gift' | 'else' | 'event',
        HandleCheckoutGiftcard | HandleCheckout
      >(type || 'else', {
        gift: () => (params: HandleCheckoutParams) =>
          handleCheckoutGiftcard(params),
        bogo:
          () =>
            ({onto = '/thank-you/bogo', ...params}: HandleCheckoutParams) =>
              handleCheckout({
                ...params,
                onto,
              }),
        event:
          () =>
            ({onto = defaultOnto, ...params}: HandleCheckoutParams) =>
              handleCheckoutEvent({
                ...params,
                onto,
              }),
        else:
          () =>
            ({onto = defaultOnto, ...params}: HandleCheckoutParams) =>
              handleCheckout({
                ...params,
                onto,
              }),
      });
      },
      [handleCheckout, handleCheckoutGiftcard],
  );

  return method;
};

type Props = {
  session: Maybe<AuthedSession> | null;
};

export type HandleCheckout = (
  params: HandleCheckoutParams
) => HandleCheckoutReturn;
export type HandleCheckoutGiftcard = (
  params: HandleCheckoutParams
) => HandleCheckoutReturn;

type HandleCheckoutReturn = Promise<Maybe<string>>;

type HandleCheckoutParams = {
  name?: string;
  email: string;
  paymentMethod: PaymentMethod;
  coupon?: string;
  onto: Maybe<string>;
  plan: PlanFragment;
  trial: number;
  upgrade: Maybe<boolean>;
  bundleSlug: Maybe<string>;
  payIn3?: boolean;
};
