import styled from '@emotion/styled';
import Link from 'next/link';

type ProgressBarProps = {
  percent: number;
};

export const ProgressBar = styled.div<ProgressBarProps>(
    ({theme, percent}) => `
  position: relative;
  width: 100%;
  height: 16px;
  border-radius: 8px;
  background-color: ${theme.colors.greyWarm};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${percent}%;
    height: 100%;
    border-radius: 8px;
    background-color: ${theme.colors.sleepAccent};
    transition: width 0.3s ease-in-out;
  }
`,
);


export const PurchaseButton = styled.button<{
  selected: boolean;
}>(({theme, selected}) => `
  display: flex;
  ${selected ? `
    border: 2px solid ${theme.colors.primary};
  ` : `
    border: 1px solid ${theme.colors.grey4};
  `}
  border-radius: ${theme.radius.lg};
  padding-left: 20px;
  padding-right: 20px;
  height: 65px;
  align-items: center;
  justify-content: space-between;
  font-weight: ${theme.fontWeights.bold};
`);

export const PurchaseLoader = styled.div(({theme}) => `
  display: flex;
  border: 1px solid ${theme.colors.grey4};
  border-radius: ${theme.radius.lg};
  padding-left: 20px;
  padding-right: 20px;
  height: 65px;
  align-items: center;
  justify-content: space-between;
  font-weight: ${theme.fontWeights.bold};
`);

export const UnderlinedLink = styled(Link)(() => `
  text-decoration: underline;
`);


export const UnderlinedButton = styled.button(() => `
  text-decoration: underline;
`);

