import {useContext, useMemo, useCallback, useEffect} from 'react';
import {ModalContext, ModalParams} from '../components/global';

export const useCreateModal = ({open, name, onClose}: Props) => {
  const modalContext = useContext(ModalContext);

  /** Create short hands for each of the functions and open state */
  const isOpen = useMemo(
      () => modalContext.modals.includes(name),
      [modalContext.modals, name],
  );

  const activeProps = useMemo<Record<string, unknown>>(
      () => modalContext.activeProps[name],
      [name, modalContext.activeProps],
  );

  const closeModal = useCallback(() => {
    modalContext.closeModal?.({name});
    onClose?.();
  }, [name, onClose]);

  const openModal = useCallback(
    <T extends ModalParams['props']>(props: T) => {
      modalContext.openModal?.({name, props});
    },
    [name],
  );

  const toggleModal = useCallback(
    <T extends ModalParams['props']>(props: T) => {
      modalContext.toggleModal?.({name, props});
    },
    [name],
  );

  useEffect(() => {
    /** open the modal upon creation */
    if (open) {
      openModal?.({name});
    }
  }, [open, name, openModal]);

  return {
    open: isOpen,
    name,
    activeProps,
    closeModal,
    openModal,
    toggleModal,
  };
};

type Props = {
  open?: boolean;
  name: string;
  activeProps?: Record<string, unknown>;
  onClose?: () => void;
};
