import styled from '@emotion/styled';

export const GoogleButton = styled.button<{
  size?: 'sm' | 'md';
}>(
    ({theme, size}) => `
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.grey7};
  cursor: pointer;
  outline: none;
  transition: ease 0.2s all;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  height: 2.8125rem;
  border-radius: 100px;
  ${size === 'md' ? `width: 100%;` : `width: 84px;`}
`,
);
