import styled from '@emotion/styled';

export const Curve = styled.svg`
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: -1px;
  width: calc(100% + 2px);
  z-index: -1;
`;
