import React from 'react';
import {useRouter} from 'next/router';
import {when} from '@mindfulness/utils/maybe';

import {SingleFragment} from '../types/types';
import {utmToDeeplink, APPSFLYER_BASE_URL} from '../utils';

export const useSingleLink = (single: SingleFragment) => {
  const router = useRouter();
  const link = React.useMemo(() => {
    const params = new URLSearchParams(utmToDeeplink(router.query)).toString();
    return when(
        single,
        () =>
          `${APPSFLYER_BASE_URL}?af_dp=${single.deepLink}${
          params.length ? `&${params}` : ''
          }`,
    );
  }, [single, router.isReady]);

  return link;
};
