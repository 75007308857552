import {
  GET_CHECKOUT_BUNDLE,
  GET_SINGLE_BY_WEB_SLUG,
  GET_TAG_COLLECTION,
  GET_TAG_COLLECTION_BY_SLUG,
} from '../';
import {getGraphClient, vttToPlainText} from '../../utils';
import {
  GetCheckoutBundleQuery,
  Maybe,
  QueryOptions,
  SingleDetailFragment,
} from '../../types/types';
import {
  GetTagCollectionBySlugQueryVariables,
  GetTagCollectionQuery,
  GetTagCollectionQueryVariables,
  SingleBySlugQueryVariables,
  CollectionFeedFragment,
} from '../../types/api';

// These functions should only be called serverside!
export const getCheckoutBundle = async (id: string) => {
  const res = await getGraphClient().query<GetCheckoutBundleQuery>({
    query: GET_CHECKOUT_BUNDLE,
    variables: {
      id,
    },
  });

  return res.data.bundle;
};

export const getSingleByWebSlug = async (
    variables: SingleBySlugQueryVariables,
): Promise<SingleDetailFragment> => {
  const {data} = await getGraphClient().query({
    query: GET_SINGLE_BY_WEB_SLUG,
    variables,
    fetchPolicy: 'no-cache',
  });

  return data.single;
};

export const getSingleByWebSlugs = async ({
  slug,
  prefix,
}: {
  slug: string;
  prefix: string;
}): Promise<SingleDetailFragment> => {
  const single = await getSingleByWebSlug({
    webSlug: `/${prefix}/${slug}`,
    withUserData: false,
    resultsTake: 5,
  });
  return single;
};

export const getSinglePageData = async ({
  slug,
  prefix,
}: {
  slug: string;
  prefix: string;
}): Promise<
  Maybe<
    SingleDetailFragment & {
      transcripts: Array<Maybe<string>>;
    }
  >
> => {
  try {
    const single = await getSingleByWebSlugs({
      slug,
      prefix,
    });

    if (single.singleStatus !== 'LIVE') return;

    const transcriptUrls =
      single?.variants?.flatMap(({chapters}) => {
        return chapters?.map(({transcript}) => transcript?.url);
      }) || [];
    const transcripts = await Promise.all(
        transcriptUrls.map(async (url) => {
          if (!url) return;
          const res = await fetch(url);
          const vtt = await res.text();
          const transcript = vttToPlainText(vtt) || '';
          return transcript;
        }),
    );

    return {transcripts, ...single};
  } catch (err) {
    console.error('Failed to get single', `/${prefix}/${slug}`);
  }
};

export const getTagCollection = async (
    variables: GetTagCollectionQueryVariables,
    options?: QueryOptions,
): Promise<Maybe<CollectionFeedFragment>> => {
  try {
    const {data} = await getGraphClient().query<GetTagCollectionQuery>({
      query: GET_TAG_COLLECTION,
      variables: {
        ...variables,
        withUserData: variables.withUserData || false,
        resultsTake: 100,
      },
      ...options,
    });
    return data.tagCollection;
  } catch (e) {
    console.error(`Failed to getTagCollection for ${variables.id}`);
  }
};

export const getTagCollectionByWebSlug = async (
    variables: GetTagCollectionBySlugQueryVariables,
): Promise<Maybe<CollectionFeedFragment>> => {
  try {
    const {data} = await getGraphClient().query({
      query: GET_TAG_COLLECTION_BY_SLUG,
      variables,
    });
    return data.tagCollection;
  } catch (e) {
    console.error(`Failed to getTagCollectionBySlug for ${variables.webSlug}`);
  }
};
