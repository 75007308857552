import * as React from 'react';
import {Maybe, when} from '@mindfulness/utils/maybe';
import {Alignment} from '@mindfulness/cms';

import {ReferencedDeviceGallery, SectionIndex} from '../../../types/types';

import {Container, Row, Column, Grid, Section} from '../../layout';
import {H3, Title3, Text} from '../../typography';
import {SanityImage, BlockContent, BlockLink, Link} from '../../global';

import {GridItem} from './DeviceGallery.styles';

export const DeviceGallery: React.FC<SectionIndex<ReferencedDeviceGallery>> = ({
  title,
  background,
  mobileCols = '1',
  columns = '3',
  blocks,
  paddingTop,
  paddingBottom,
  paragraph,
  textAlign,
  index,
}) => {
  const colNumber = Number(columns);
  return (
    <Section
      background={background}
      padding={{
        xs: 16,
        md: 40,
        lg: 80,
      }}
      sectionPadding={{paddingTop, paddingBottom}}
    >
      <Container maxWidth={colNumber <= 3 ? 'lg' : 'xl'}>
        <Row>
          <Column textAlign="center">
            <H3 size="3xl" weight="bold">
              {title}
            </H3>
            {paragraph && (
              <BlockContent
                spacing={16}
                content={paragraph}
                background={background}
              />
            )}
          </Column>
        </Row>
        <Grid
          cols={{
            xs: Number(mobileCols),
            md: Number(columns) / 2,
            lg: Number(columns),
          }}
          rowGap={{
            xs: 24,
            lg: colNumber <= 3 ? 72 : 24,
          }}
          columnGap={{
            xs: 24,
            lg: colNumber <= 3 ? 72 : 24,
          }}
        >
          {blocks.map((props, i) => (
            <Item
              {...props}
              align={textAlign}
              background={background}
              key={props._key}
              sectionIndex={index}
              index={i}
            />
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

const Item: React.FC<
  ReferencedDeviceGallery['blocks'][0] & {
    background: ReferencedDeviceGallery['background'];
    align: Maybe<Alignment>;
    sectionIndex: number;
    index: number;
  }
> = ({
  _key,
  image,
  title,
  background,
  paragraph,
  type,
  url,
  slug,
  align,
  index,
  sectionIndex,
}) => {
  const external = React.useMemo(() => {
    if (type === 'external' && url) {
      return true;
    }
  }, [type, url]);

  const link = React.useMemo(() => {
    if (!type) {
      return;
    }
    if ((type === 'internal' || type === 'external') && url) {
      return url;
    }
    if (type === 'reference' && slug) {
      return slug;
    }
  }, [type, url, slug]);

  return (
    <LinkWrapper url={link} external={Boolean(external)}>
      <GridItem key={_key} align={align}>
        {image ? (
          <SanityImage
            image={image}
            priority={(sectionIndex < 2 && index < 1) || sectionIndex < 1}
            spacing={10}
          />
        ) : null}
        <Title3 weight="bold">{title}</Title3>
        {when(paragraph, (p) =>
          typeof p === 'object' ? (
            <BlockContent content={p} background={background} />
          ) : (
            <Text colour={background === 'grey9' ? 'white' : 'grey7'}>{p}</Text>
          ),
        ) || null}
      </GridItem>
    </LinkWrapper>
  );
};

const LinkWrapper: React.FC<
  React.PropsWithChildren<{
    url: Maybe<string>;
    external: boolean;
  }>
> = ({children, url, external}) =>
  url ? (
    <Link
      href={url}
      {...(external ?
        {
          target: '_blank',
          rel: 'noopener noreferrer',
        } :
        {})}
      passHref
    >
      <BlockLink>{children}</BlockLink>
    </Link>
  ) : (
    <>{children}</>
  );
