import * as React from 'react';
import {Theme} from '@emotion/react';

import {Row} from '../layout';
import {convertToColumns} from '../../utils';

import {Column} from '../layouts/Column';

/* eslint-disable react/no-array-index-key */
export const Masonry: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  cols,
  gap = 26,
}) => {
  return (
    <Row gap={gap}>
      {convertToColumns(React.Children.toArray(children), cols)?.map(
          (column, columnIndex) => (
            <Column
              key={`column-${columnIndex}`}
              span={{
                xs: 24,
                md: 24 / cols,
              }}
            >
              <Row column>
                {column.map((cell, cellIndex) => (
                  <Column key={`cell-${cellIndex}`} spacing={gap}>
                    {cell}
                  </Column>
                ))}
              </Row>
            </Column>
          ),
      )}
    </Row>
  );
};
/* eslint-enable react/no-array-index-key */

type Props = {
  cols: number;
  gap?: keyof Theme['spacings'];
};
