import React from 'react';

import {
  Maybe,
  SingleDetailFragment,
  SingleVariantFragment,
} from '../../../types/types';
import {useSingleLink} from '../../../hooks';
import {Icon} from '../../global/Icon';
import {
  Box,
  Container,
  Row,
  Column,
  Flex,
  Stack,
  Cluster,
} from '../../layout';
import {Small, Text, Title1} from '../../typography';

import {Divider} from '../Divider';
import {QRCodeSection} from '../QRCodeSection';

import {SingleShare} from '../SingleShare';
import {TeacherPanel} from '../TeacherPanel';
import {splitLabel} from '../../../utils/string';

import style from './SinglePlayerDetail.module.css';

export const SinglePlayerDetail: React.FC<Props> = ({
  single,
  variant,
  setVariant,
}) => {
  const singleLink = useSingleLink(single);
  const firstContributor = React.useMemo(
      () => single.contributors?.[0]?.producer,
      [single],
  );
  return (
    <Box
      background="white"
      as="section"
      paddingB={{
        xs: 0,
        md: 40,
        lg: 60,
      }}
    >
      <Container maxWidth="mlg">
        <Row>
          <Column
            order={{
              xs: 3,
              md: 1,
            }}
            span={{
              xs: 24,
              md: 8,
            }}
          >
            <Flex justify="space-between">
              <Stack space={10}>
                {single.label ? (
                  <Small
                    spacing={10}
                    colour="primary"
                    fontWeight="bold"
                    transform="uppercase"
                    as="h3"
                  >
                    {splitLabel(single.label)}
                  </Small>
                ) : null}
                <Small colour="grey7">
                  {single.ratings?.average ? (
                    <Icon name="star" size={12} space={4}>
                      {single.ratings.average}
                    </Icon>
                  ) : null}
                </Small>
              </Stack>
              <SingleShare colour="primary" justify="end" single={single} />
            </Flex>
            <Text fontWeight="bold" fontSize="xs" spacing={8}>
              Duration
            </Text>
            {single.variants ? (
              <Cluster gap={8}>
                {single.variants.map((v) => (
                  <button
                    type="button"
                    className={[
                      style.variantButton,
                      v.id === variant?.id ? style.selected : '',
                    ].join(' ')}
                    key={v.id}
                    onClick={() => setVariant(v)}
                  >
                    {v.label || `${Math.round((v?.duration || 0) / 60)}mins`}
                  </button>
                ))}
              </Cluster>
            ) : null}
            {firstContributor?.webSlug ? (
              <TeacherPanel teacher={firstContributor} />
            ) : (
              <Divider spacing={16} />
            )}
            <QRCodeSection qrCodeLink={singleLink} />
          </Column>
          <Column
            order={{xs: 2}}
            span={{
              xs: 0,
              md: 1,
            }}
          />
          <Column
            order={{xs: 1, md: 3}}
            span={{
              xs: 24,
              md: 15,
            }}
            spacing={16}
          >
            {single.title ? (
              <Title1 as="h1" spacing={10}>
                {single.title}
              </Title1>
            ) : null}

            {single.description ? (
              <Text fontSize="md" spacing={30} colour="grey9">
                {single.description}
              </Text>
            ) : null}
            <Divider />

          </Column>
        </Row>
      </Container>
    </Box>
  );
};

type Props = {
  transcripts?: Array<string>;
  single: SingleDetailFragment;
  setVariant: React.Dispatch<
    React.SetStateAction<Maybe<SingleVariantFragment>>
  >;
  variant: Maybe<SingleVariantFragment>;
};
