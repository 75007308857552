import {SUBMIT_QUESTIONSHEET} from '../mutations/submitQuestionSheet';
import {
  SubmitQuestionSheetMutation,
  SubmitQuestionSheetMutationVariables,
} from '../../types/api';
import {getGraphClient} from '../../utils/graph';

export const submitQuestionSheet = async (
    variables: SubmitQuestionSheetMutationVariables,
) => {
  const {data} = await getGraphClient().mutate<SubmitQuestionSheetMutation>({
    mutation: SUBMIT_QUESTIONSHEET,
    variables,
  });
  return data?.submitQuestionSheet;
};
