import {createElement, CSSProperties, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import {Theme} from 'shared/types/emotion';
import {ResponsiveValue} from 'shared/types/types';
import styleUtils from 'shared/styles/utils.module.css';
import {getResponsiveClassNames, getResponsiveVariableClassNames, getResponsiveVariables} from 'shared/utils';

export const Flex = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>>(({
  children, className, style,
  as = 'div',
  gap = 0,
  justify = 'flex-start',
  items = 'center',
  wrap,
  spacing,
  reverse,
  column,
  height,
  grow,
  hide,
  overflow,
  paddingY,
  wide,
  ...props
}, ref) => {
  return createElement(as, {
    className: [
      styleUtils.flex,
      ...(hide ? [styleUtils[`hidden-${hide[1]}-${hide[0]}`]] : []),
      ...wrap ? [styleUtils[`flex-${wrap}`]] : [],
      ...getResponsiveClassNames(gap, 'gap'),
      styleUtils[`justify-${justify}`],
      styleUtils[`items-${items}`],
      ...getResponsiveClassNames(spacing, 'mb'),
      ...height ? getResponsiveVariableClassNames(height, 'height') : [],
      ...overflow ? [styleUtils[`overflow-${overflow}`]] : [],
      ...grow ? [styleUtils['flex-grow-1']] : [],
      ...paddingY ? [
        styleUtils[`pt-${paddingY}`],
        styleUtils[`pb-${paddingY}`],
      ] : [],
      ...wide ? [styleUtils.wide] : [],
      styleUtils[`flex-${column ? 'col' : 'row'}${reverse ? '-reverse' : ''}`],
      ...className ? [className] : [],
    ].join(' '),
    style: {
      ...height ? {
        ...getResponsiveVariables(height, 'height'),
      } : {},
      ...style,
    },
    ...props,
    ref,
  }, children);
});

Flex.displayName = 'Flex';


type Props = {
  as?: keyof JSX.IntrinsicElements;
  gap?: ResponsiveValue<keyof Theme['spacings']>;
  justify?: CSSProperties['justifyContent'];
  items?: CSSProperties['alignItems'];
  wrap?: CSSProperties['flexWrap'];
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  reverse?: boolean;
  column?: boolean;
  height?: ResponsiveValue<string>;
  grow?: boolean;
  hide?: [keyof Theme['breakpoints'], keyof Theme['breakpoints']];
  overflow?: CSSProperties['overflow'];
  paddingY?: keyof Theme['spacings'];
  wide?: boolean;
}
