import {useRouter} from 'next/router';
import {useEffect} from 'react';

export const usePreload = (url: string) => {
  const router = useRouter();
  // Get the thank you page ready
  useEffect(() => {
    if (!router.isReady) return;
    router.prefetch(url);
  }, [router.isReady, url]);
};
