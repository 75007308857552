import {useContext} from 'react';
import {Context} from '../components/global';
import {useStrategy} from './useStrategy';

export const useLoggedInAs = () => {
  const {session} = useContext(Context);
  const strat = useStrategy();

  if (!session) return;

  return session.strategy === 'PASSWORDLESS' ?
    session.email :
    `${session.fullName || session.email || session.firstName}${
        strat ? ` (${strat} Account)` : ''
    }`;
};
