import * as React from 'react';

import {BackgroundAPIImage, Context, Icon} from '../../global';
import {ItemLocation, SingleDetailFragment} from '../../../types/types';
import {H4, Small} from '../../typography';
import {Box} from '../../layout';

import styles from './ItemCard.module.css';
import {SingleProgressBar} from '../SingleProgressBar';
import {assertNumber} from '../../../utils/number';
import {splitLabel} from '../../../utils/string';
import {useContributorNames} from '../../../hooks/useContributorNames';
import {WithLinkProps, withLink} from '../../../hoc/withLink';
import {ErrorBoundary} from '@sentry/nextjs';

export const ItemCard: React.FC<Props & WithLinkProps> = withLink((props) => {
  const {
    cellImage,
    coverImage,
    contributors,
    label,
    ratings,
    title,
    location,
    priority,
    type,
  } = props;

  const contributorNames = useContributorNames(contributors);

  return (
    <ErrorBoundary fallback={<p>An error occured</p>}>
      {cellImage || coverImage ? (
        <div className={[
          styles.itemContainer,
          location === 'reel' ? styles.reel : '',
        ].join(' ')}>
          <div className={styles.cellContainer}>
            <div className={[styles.cellImage, 'cell-image', ...(type === 'TALK' ? [styles.blur] : [])].join(' ')}>
              <BackgroundAPIImage
                zIndex={0}
                priority={priority}
                src={cellImage?.url || coverImage?.url}
                portrait={type === 'MOMENT'}
                alt={`${title}${splitLabel(label)}${
                  contributorNames ? ` by ${contributorNames}` : ''
                }`}
              />
            </div>
            {type === 'TALK' ? <TalkData {...props} /> : null}
            <UserData {...props} />
          </div>
          <div className={styles.textContainer}>
            <Small colour="grey7" fontSize="sm">
              {contributorNames}
            </Small>
            <H4 weight="bold" spacing={5}>
              {title}
            </H4>
            <Small
              colour="grey7"
              transform="uppercase"
              fontSize="2xs"
              fontWeight="bold"
              spacing={5}
            >
              {label}
            </Small>
            <Small colour="grey7">
              {ratings?.average ? (
                <Icon name="star" size={12}>{ratings.average}</Icon>
              ) : null}
            </Small>
          </div>
        </div>
      ) : null}
    </ErrorBoundary>
  );
});

const TalkData: React.FC<Props> = ({contributors, priority}) => (
  <Box inset={0} position="absolute">
    {contributors?.length ? (
      <div className={['producer-image', styles.producerImages].join(' ')}>
        {contributors.map((p, idx) => (
          <div
            className={[
              styles.producerImage,
              ...(contributors.length > 1 ? [] : [styles.single]),
              styles[`index-${idx}`],
            ].join(' ')}
            key={p.id}
          >
            {p.producer?.cellImage?.url ? (
              <BackgroundAPIImage
                alt={p.producer.fullName}
                priority={priority}
                zIndex={0}
                src={p.producer.cellImage.url}
              />
            ) : null}
          </div>
        ))}
      </div>
    ) : null}

    <div className={styles.wave}>
      <svg
        width="286"
        height="61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          clipPath="url(#a)"
          stroke="#fff"
          strokeOpacity=".3"
          strokeWidth="3"
          strokeMiterlimit="10"
        >
          <path d="M-1.39 32.599v-4.2M5.122 34.7v-8.4M11.4 43.1V17.9M55.59 34.352v-7.7M62.102 38.317V22.684M68.38 29.217v2.567M17.68 53.603v-46.2M23.961 39.599v-18.2M30.24 34.7v-8.4M36.518 32.599v-4.2M74.894 38.317V22.684M81.174 53.833V7.166M87.453 58.5v-56M93.733 69.001V-8M100.012 58.5v-56M106.29 10.668v39.667M42.799 43.1V17.9M49.08 36.099v-11.2M113.733 16.266v28.467M120.014 21.166v18.667M126.292 32.599v-4.2M182.343 37.5v-14M132.571 31.902v-2.8M138.736 38.433V22.566M145.015 46.603v-32.2M151.295 39.599v-18.2M157.574 43.801v-26.6M163.854 53.603v-46.2M170.133 50.802v-40.6M176.414 46.603v-32.2M188.855 50.802v-40.6M195.136 58.5v-56M239.323 32.599v-4.2M245.836 34.7v-8.4M252.117 29.799v1.4M201.415 69.001V-8M207.695 50.802v-40.6M213.973 43.1V17.9M220.254 36.099v-11.2M258.629 34.7v-8.4M264.908 43.1V17.9M271.188 35.404v-9.8M277.467 39.599v-18.2M283.745 34.7v-8.4M226.534 43.1V17.9M232.813 36.099v-11.2" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h286v61H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </Box>
);

const UserData: React.FC<Props> = ({userData, isNew, loading}) => {
  const {plan} = React.useContext(Context);

  return (
    <Box inset={0} position="absolute" zIndex={2}>
      <div className={[
        styles.iconContainer,
        ...(loading ? [styles.loading] : []),
      ].join(' ')}>
        {!userData ? (
          <Icon name="lock" width={30} height={32} />
        ) : userData?.isFavourite ? (
          <Icon name="heart" width={30} height={32} />
        ) : userData?.locked ? (
          <>
            {plan === 'basics' ? (
              <Icon name="plus-badge" width={66} height={31} />
            ) : (
              <Icon name="lock" width={30} height={32} />
            )}
          </>
        ) : assertNumber(userData?.lastPlayedPercent) >= 90 ? (
          <Icon name="played" width={30} height={32} />
        ) : isNew ? (
          <div className={styles.badge}>NEW</div>
        ) : null}
      </div>
      <SingleProgressBar
        percent={userData?.lastPlayedPercent || undefined}
        location={'card'}
      />
    </Box>
  );
};

type SingleData = SingleDetailFragment;

type Props = React.PropsWithChildren<
  SingleData & {
    location?: ItemLocation;
    priority?: boolean;
    loading?: boolean;
  }
>;
