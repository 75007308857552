import * as React from 'react';
import {ThemeProvider} from '@emotion/react';
import {ApolloProvider} from '@apollo/client';
import {GoogleOAuthProvider} from '@react-oauth/google';

import {theme} from '../../theme';

import {client} from '../../utils';

import {
  DiscountProvider,
  LayoutProps,
  ModalProvider,
  Provider,
  ToastProvider,
} from '../global';
import {SegmentProvider} from './SegmentProvider';
import {QuestionnaireFragment} from './QuestionnaireFragment';

/**
 * Renders the providers stack and global modal components without the header and footer
 * @return {React.FC}
 */
export const ProviderStack: React.FC<Partial<LayoutProps>> = ({
  children,
  single,
  ...props
}) => (
  <SegmentProvider>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID as string}>
          <DiscountProvider>
            <ModalProvider>
              <ToastProvider>
                <Provider single={single} {...props}>
                  <QuestionnaireFragment />
                  {children}
                </Provider>
              </ToastProvider>
            </ModalProvider>
          </DiscountProvider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </ApolloProvider>
  </SegmentProvider>
);
