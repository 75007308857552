import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {Maybe} from '@mindfulness/utils/maybe';
import {switchEnum} from '@mindfulness/utils/logic';

import {ResponsiveValue} from '../../../types/types';
import {getRatio, getSpacing} from '../../../utils';
import {ImageDecoration} from '.';

const getFrameColour = (bg: keyof Theme['colors']): keyof Theme['colors'] => {
  return switchEnum<keyof Theme['colors'], keyof Theme['colors']>(bg, {
    grey9: 'sleepPrimary',
    white: 'greyWarm',
    else: 'greyWarm',
  });
};

export const ImageFrame = styled.div<ImageFrameProps>(
    ({
      theme,
      decoration = 'rounded',
      background = 'white',
      ratio,
      radius = 'lg',
      height,
      width,
      loadingBackground,
      spacing,
    }) => {
      return `
    position: relative;
    z-index: 0;
    flex-shrink: 0;
    width: ${width ? `${width}px` : '100%'};
    ${getSpacing(theme, spacing)}
    ${height ? `height: ${height}px;` : ''}
    ${
      loadingBackground ?
        `
      border-radius: ${decoration === 'circle' ? '100%' : theme.radius[radius]};
      background-color: ${theme.colors.sleep1};
    ` :
        ''
}
    ${
      decoration !== 'none' ?
        `
      img {
        border-radius: ${
          decoration === 'circle' ? '100%' : theme.radius[radius]
        };
      }
    ` :
        `
        `
}

    ${
      decoration === 'frame' ?
        `
      &:after {
        content: "";
        background-color: ${theme.colors[getFrameColour(background)]};
        transform: rotate(-10deg);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: ${theme.radius.lg};
        z-index: -1;
        display: none;
        @media (min-width: ${theme.breakpoints.lg}) {
          display: block;
        }
      }
    ` :
        ''
}

    ${
      decoration === 'overlay' ?
        `
      overflow: hidden;
      border-radius: ${theme.radius.lg};
      &:after {
        position: absolute;
        background: rgba(55, 55, 75, 0.4);
        content: "";
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    ` :
        ''
}


    ${
      decoration === 'circle' ?
        `
      overflow: hidden;
      border-radius: 100%;
      &:before {
        background-color: ${theme.colors[getFrameColour(background)]};
        content: "";
      }
    ` :
        ''
}

    ${
      decoration === 'stack' ?
        `
      &:before, &:after {
        @media (min-width: ${theme.breakpoints.xl}) {
          content: "";
        }
        background-color: ${theme.colors[getFrameColour(background)]};
        position: absolute;
        top: 0;
        left: 0;
        mix-blend-mode: multiply;
        width: 100%;
        height: 100%;
        border-radius: ${theme.radius.lg};
      }

      &:after {
        transform: rotate(-20deg);
        z-index: -1;
      }

      &:before {
        transform: rotate(-10deg);
      }
    ` :
        ratio ?
        `
      &:before {
        height: 0;
        content: "";
        display: block;
        ${getRatio(theme, ratio)}
      }

      > * {
        object-fit: cover;
        object-position: center;
        position: absolute !important;
        overflow: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        height: 100%;
        width: 100%;
      }
    ` :
        ''
}

  `;
    },
);

export const BackgroundImageWrapper = styled.div<{
  overlay?: number;
  radius?: keyof Theme['radius'];
  gradient?: keyof Theme['gradients'];
  zIndex?: number;
  scale?: number;
  scaleFrom?: number;
}>(
    ({theme, overlay, gradient, radius, scale, scaleFrom, zIndex = -1}) => `
  ${
    radius ?
      `
    overflow: hidden;
    border-radius: ${theme.radius[radius]};
  ` :
      ''
}
  ${
    scale ?
      `
    transform: scale(${scale});
    transition: transform 0.3s ease;
  ` :
      scaleFrom ?
      `
      transform: scale(${scaleFrom});
      transition: transform 0.3s ease;

    &:hover {
      transform: scale(1);

    }
      ` :
      ''
}
  position: absolute;
  z-index: ${zIndex};
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  span, img {
    height: 100%!important;
    width: 100%!important;
  }

  ${
    overlay || gradient ?
      `
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      ${
        overlay ?
          `
        background-color: rgba(55, 55, 75, ${overlay});
      ` :
          ''
      }
      ${
        gradient ?
          `
        background-image: ${theme.gradients[gradient]};
        background-size: cover;
        background-position: center;
      ` :
          ''
      }
    }
  ` :
      ''
}
`,
);

export const DynamicContainer = styled.div<{
  decoration: Maybe<ImageDecoration>;
  radius?: keyof Theme['radius'];
  ratio?: ResponsiveValue<string | number>;
}>(
    ({decoration, ratio, radius = 'lg', theme}) => `
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  ${
    ratio ?
      `
      &:before {
        height: 0;
        content: "";
        display: block;
        ${getRatio(theme, ratio)}
      }

      > * {
        object-fit: cover;
        object-position: center;
        position: absolute !important;
        overflow: hidden;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        height: 100%;
        width: 100%;
      }
    ` :
      ''
}
  ${
    decoration === 'rounded' ?
      `
    border-radius: ${theme.radius[radius]};
  ` :
      ''
}

  > * {
    min-width: 100%;
    min-height: 100%;
  }
`,
);

export type ImageFrameProps = {
  decoration?: ImageDecoration;
  background?: keyof Theme['colors'];
  radius?: keyof Theme['radius'];
  ratio?: ResponsiveValue<string | number>;
  height?: number;
  width?: number;
  loadingBackground?: boolean;
  spacing?: keyof Theme['spacings'];
};
