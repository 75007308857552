import * as React from 'react';

import {
  Maybe,
  SingleDetailFragment,
  SingleVariantFragment,
  SinglesQueue,
} from '../../../types/types';
import {BackgroundAPIImage} from '../../global';
import {Box} from '../../layout';

import {Player} from './Player';
import {Overlay} from './Overlay';

import style from './SinglePlayer.module.css';
import {useSinglePlayer} from '../../../hooks/useSinglePlayer';

export const SinglePlayer: React.FC<Props> = ({
  single,
  variant,
  requireSignIn = true,
  frame,
  bundleId,
  nextAndPreviousSingles,
}) => {
  const {
    status,
    wrapperRef,
    image,
    poster,
    scrubBackwards,
    scrubForwards,
    duration,
    playPercent,
    playSeconds,
    setTime,
    chapter,
    setChapter,
    player,
    tracks,
    previousState,
    cueRef,
    fullScreenRef,
    handleFullScreen,
  } = useSinglePlayer({single, variant});

  return (
    <div
      className={[
        style.videoWrapper,
        frame ? style.frame : '',
      ].join(' ')}
      ref={wrapperRef}
      id="VideoWrapper"
    >
      <div
        className={!frame ? style.videoRatioOff : style.videoRatio}>
        <Box position="absolute" height="100%" width="100%" ref={fullScreenRef}>
          {image && !poster ? (
            <BackgroundAPIImage
              src={image}
              zIndex={-2}
              objectPosition="center"
              objectFit="cover"
              priority={true}
              triggerResize={
                frame ? (status === 'playing' ? Math.random() : 0) : 0
              }
            />
          ) : null}
          <Overlay
            scrubBackwards={scrubBackwards}
            scrubForwards={scrubForwards}
            requireSignIn={requireSignIn}
            locked={!!single.userData?.locked}
            duration={duration}
            playPercent={playPercent}
            variant={variant}
            playSeconds={playSeconds}
            setTime={setTime}
            chapter={chapter}
            player={player}
            single={single}
            setChapter={setChapter}
            nextAndPreviousSingles={nextAndPreviousSingles}
            tracks={tracks}
            hideTitle={frame}
            cueRef={cueRef}
            handleFullScreen={handleFullScreen}
            frame={frame}
          />
          <Player
            duration={duration}
            playPercent={playPercent}
            playSeconds={playSeconds}
            chapter={chapter}
            poster={poster}
            player={player}
            single={single}
            variant={variant}
            setChapter={setChapter}
            setTime={setTime}
            nextAndPreviousSingles={nextAndPreviousSingles}
            frame={frame}
            bundleId={bundleId}
            tracks={tracks}
            previousState={previousState}
            cueRef={cueRef}
          />
        </Box>
      </div>
    </div>
  );
};

type Props = {
  single: SingleDetailFragment;
  variant: Maybe<SingleVariantFragment>;
  requireSignIn?: boolean;
  frame?: boolean;
  bundleId?: string;
  nextAndPreviousSingles?: SinglesQueue;
};
