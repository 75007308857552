import {GET_PURCHASABLES, GET_PURCHASABLE_WITH_COUPON} from '../../api/queries';
import {getGraphClient} from '../../utils/graph';
import {GetPurchasablesQuery, GetPurchasablesQueryVariables} from '../../types/api';

export const getPurchasables = async (variables: GetPurchasablesQueryVariables, coupon?: string) => {
  const query = coupon ? GET_PURCHASABLE_WITH_COUPON : GET_PURCHASABLES;
  const {data} = await getGraphClient().query<GetPurchasablesQuery>({
    query,
    variables: {
      ...variables,
      coupon,
    },
  });
  return data.purchasables;
};
