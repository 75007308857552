export const sizes = {
  container: {
    xs: '416px',
    s: '500px',
    sm: '600px',
    md: '720px',
    mlg: '910px',
    lgm: '980px',
    lg: '1100px',
    xl: '1300px',
    prose: '800px',
  },
  prose: '800px',
};
