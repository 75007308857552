import React, {useContext, useState} from 'react';
import {usePathname, useSearchParams} from 'next/navigation';

import {Context} from '../components/global/Provider';
import {useModal} from './useModal';
import {unNull} from '../utils/maybe';

export const useAuthView = (defaultView = 'signup') => {
  const actionModal = useModal('action');
  const {session} = useContext(Context);
  const [loading, setLoading] = useState<boolean>(false);
  const [view, setView] = useState<string>(defaultView);
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const {token, email, viewParam} = React.useMemo(() => {
    return {
      token: unNull(searchParams.get('token')),
      email: unNull(searchParams.get('email')),
      viewParam: unNull(searchParams.get('view')),
    };
  }, [searchParams]);

  React.useEffect(() => {
    if (session) {
      setView('signup');
      return;
    }
    if (token && email) {
      setView('verify');
      actionModal.open();
      return;
    }
    if (viewParam) {
      setView(viewParam);
      return;
    }
    if (email) {
      setView('login');
      return;
    }
    setView('signup');
  }, [token, email, session, viewParam, pathname]);

  return {
    token,
    email,
    view,
    setView,
    loading,
    setLoading,
  };
};
