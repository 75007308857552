import {useRef, useMemo} from 'react';
import Image, {ImageProps} from 'next/image';
import {useNextSanityImage} from 'next-sanity-image';
import {Maybe, when} from '@mindfulness/utils/maybe';
import {Figure} from '@mindfulness/cms';
import {SanityClientLike} from '@sanity/image-url/lib/types/types';

import {useSanityClient} from '../../../hooks/useSanityClient';
import {useElementSize} from '../../../hooks/useElementSize';
import {ImageFrame, ImageFrameProps} from './Image.styles';
import {assertString} from '../../../utils/string';
import {useBlurProps} from '../../../hooks/useBlurProps';

export const SanityImage: React.FC<Props> = ({
  image,
  decoration,
  background,
  ratio,
  spacing,
  ...props
}) => {
  const container = useRef<HTMLDivElement>(null);
  const realSize = useElementSize(container);
  const width = useMemo(
      () => props.width ?? realSize?.width,
      [props.width, realSize?.width],
  );
  const height = useMemo(
      () => props.height ?? realSize?.height,
      [props.height, realSize?.height, realSize?.width],
  );
  const blurProps = useBlurProps(image, !!props.priority);

  if (!image) return null;
  return (
    <ImageFrame
      ref={container}
      decoration={decoration}
      background={background}
      ratio={ratio}
      spacing={spacing}
    >
      {width ? (
        <SanityImageInner
          {...props}
          image={image}
          width={width}
          height={height}
          {...blurProps}
        />
      ) : null}
    </ImageFrame>
  );
};

export const SanityImageInner: React.FC<
  InnerProps & Omit<ImageProps, 'src' | 'alt'>
> = ({image, ...props}) => {
  const client = useSanityClient();

  const imageProps = useNextSanityImage(
    client as unknown as SanityClientLike,
    image?.image || null,
  );
  const blurProps = useBlurProps(image, !!props.priority);

  return (
    when(imageProps?.src, (src) => (
      <Image
        {...imageProps}
        alt={assertString(image?.alt)}
        src={src}
        {...props}
        {...blurProps}
      />
    )) || null
  );
};

type InnerProps = {
  image: Maybe<Figure>;
};

type Props = { image: Maybe<Figure> } & Omit<ImageProps, 'src' | 'alt'> &
  ImageFrameProps;
