import * as React from 'react';
import {Paragraphs as ParagraphsProps} from '@mindfulness/cms';

import {Title1} from '../../typography';
import {Container, Row, Column, Stack, Section} from '../../layout';
import {CallToActionButton, BlockContent, TextWrapper} from '../../global';
import {SectionIndex} from '../../../types/types';
import {textColourMap} from '../../../utils';

const columnSpans = [20, 8, 6];

export const Paragraphs: React.FC<SectionIndex<ParagraphsProps>> = ({
  blocks,
  background = 'white',
  alignment,
  paddingTop = 60,
  paddingBottom = 60,
  _type,
  index,
}) => (
  <Section
    background={background}
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
  >
    <Container maxWidth={blocks?.length === 1 ? 'prose' : 'xl'}>
      <Row justify="space-around">
        {blocks?.map(({title, paragraph, cta, _key}) => (
          <Column
            span={{
              xs: 24,
              md: columnSpans[blocks.length - 1],
            }}
            textAlign={alignment}
            key={_key}
          >
            <Stack space={16} direction="vertical">
              {title || paragraph ? (
                <TextWrapper background={background}>
                  {title ? (
                    <Title1
                      as={index === 0 ? 'h1' : 'h2'}
                      spacing={paragraph ? 10 : undefined}
                    >
                      {title}
                    </Title1>
                  ) : null}
                  {paragraph ? (
                    <BlockContent
                      colour={textColourMap(background)}
                      content={paragraph}
                      background={background}
                      spacing={16}
                    />
                  ) : null}
                </TextWrapper>
              ) : null}
              {cta?.text ? (
                <div>
                  <CallToActionButton id={`${_type}-${index}`} {...cta}>
                    {cta?.text}
                  </CallToActionButton>
                </div>
              ) : null}
            </Stack>
          </Column>
        ))}
      </Row>
    </Container>
  </Section>
);
