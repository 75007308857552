import * as React from 'react';

import {Icon, Context} from '../../global';

import {FacebookButton} from './FacebookLogin.styles';

export const FacebookLogin: React.FC<Props> = ({
  size = 'md',
  color = 'default',
  redirect,
  setLoading,
}) => {
  const {handleFacebookLogin} = React.useContext(Context);
  return (
    <FacebookButton
      size={size}
      color={color}
      type="button"
      id="FacebookLogin"
      role="button"
      aria-label="Login with Facebook"
      onClick={async () => {
        try {
          setLoading(true);
          const res = await handleFacebookLogin?.(typeof redirect !== 'function');
          // Redirect will be called when we want to verify but not call the page action
          // "Redirect" is a misnomer because it is really just an alternative call back to the page action
          if (typeof redirect === 'function') {
            redirect({email: res?.email});
            return;
          }
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
        }
      }}
    >
      <Icon
        name="facebook"
        colour={color === 'default' ? 'white' : 'facebook'}
        size={20}
      />
    </FacebookButton>
  );
};

type Props = {
  size?: 'md' | 'sm';
  color?: 'white' | 'default';
  redirect?: (params: { email?: string; name?: string }) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
