import * as React from 'react';
import {Theme} from '@emotion/react';
import {HeroCurved as HeroCurvedProps} from '@mindfulness/cms';
import {when} from '@mindfulness/utils/maybe';

import {
  BackgroundImage,
  BlockContent,
  CallToActionButton,
} from '../../global';
import {Box, Column, Container, Row} from '../../layout';
import {H1, H4} from '../../typography';
import {useOnMobile, useNavigation} from '../../../hooks';
import {SectionIndex} from '../../../types/types';

import {Curve} from './HeroCurved.styles';
import {Section} from '../../layouts/Section';

export const HeroCurved: React.FC<SectionIndex<HeroCurvedProps>> = ({
  image: responsiveImage,
  hideGradient,
  ...props
}) => {
  const image = useOnMobile() ?
    responsiveImage?.mobileImage || responsiveImage?.desktopImage :
    responsiveImage?.desktopImage;
  return (
    <Box>
      <Section overflow="hidden" position="relative" padding={160}>
        {image ? (
          <BackgroundImage
            gradient={hideGradient ? undefined : 'dark'}
            image={image}
            priority={props.index === 0}
          />
        ) : null}
        <Container spacing={60}>
          <Content {...props} as={props.index > 0 ? 'p' : 'h1'} color="white" />
        </Container>
        <Curve
          width="100%"
          viewBox="0 0 1440 133"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 2.4298V132.999H1440V0C1230.56 57.0368 982.036 90.0726 715.5 90.0726C452.772 90.0726 207.54 57.974 0 2.4298Z"
            fill="white"
          />
        </Curve>
      </Section>
    </Box>
  );
};

const Content: React.FC<
  SectionIndex<
    Omit<HeroCurvedProps, 'image'> & {
      color?: keyof Theme['colors'];
      as: React.ElementType<any>;
    }
  >
> = ({alignment, date, title, paragraph, cta, color, _type, index, as}) => {
  const {templateParams} = useNavigation();
  return (
    <Row justify={alignment}>
      <Column
        span={{
          xs: 24,
          md: 12,
        }}
        textAlign={alignment}
        color={color}
      >
        {date ? <H4 spacing={24}>{date}</H4> : null}
        {title ? (
          <H1
            as={as}
            spacing={{
              xs: 8,
              md: 24,
            }}
          >
            {templateParams(title)}
          </H1>
        ) : null}
        {when(paragraph, (p) =>
          typeof p === 'object' ? (
            <BlockContent
              background="grey9"
              content={p}
              fontSize={{
                xs: 'base',
                md: 'md',
              }}
              fontWeight="regular"
              spacing={{
                xs: 16,
                md: 30,
              }}
            />
          ) : null)}
        <Box maxWidth="600px" display="inline-block">
          {cta?.text ? (
            <CallToActionButton id={`${_type}-${index}`} {...cta}>
              {cta?.text}
            </CallToActionButton>
          ) : null}
        </Box>
      </Column>
    </Row>
  );
};
