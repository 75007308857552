import * as React from 'react';
import {ButtonConfig} from '../../forms';
import style from '../../forms/Button/Button.module.css';
import {getResponsiveBoolClassNames} from 'shared/utils';
import {getButtonSize} from 'shared/components/forms/Button/StyledButton';

export const ButtonAnchor: React.FC<
  React.PropsWithChildren<React.AnchorHTMLAttributes<HTMLAnchorElement>> & ButtonConfig
> = ({
  children,
  onClick,
  className,
  variant = 'primary',
  wide,
  size,
  ...props
}) => {
  const [loading, setLoading] = React.useState(props.loading);
  return (
    <a
      onClick={async (e) => {
        setLoading(true);
        await onClick?.(e);
        setLoading(false);
      }}
      loading={props.loading || loading}
      className={[
        style.button,
        style[`style-${variant}`],
        ...(wide ? getResponsiveBoolClassNames(wide, 'full-width') : []),
        ...getButtonSize(size, 'size'),
        ...(className ? [className] : []),
      ].join(' ')}
      {...props}
    >
      {children}
    </a>
  );
};
