import {colors} from './colors';

export const gradients = {
  dark: `linear-gradient(90deg, rgba(55, 55, 75, 0.84) 6.4%, rgba(55, 55, 75, 0) 61.05%);`,
  darkLg: `linear-gradient(90deg, rgba(55, 55, 75, 0.86) 7.19%, rgba(55, 55, 75, 0) 81.91%);`,
  darkDark: `linear-gradient(64.09deg, #4C446C 34.49%, #37374B 130.74%);`,
  darkCenter: `linear-gradient(90deg, #22242E 0%, #2B2B36 26.04%, #363644 50%, #2A2B36 83.33%, #22242E 100%);`,
  primary: `linear-gradient(64.09deg, ${colors.primary} 34.49%, ${colors.secondary} 130.74%);`,
  accent: `linear-gradient(200deg, rgba(255, 110, 120, 0.2) 100px, rgba(255, 255, 255, 0) 277px);`,
  soft: `linear-gradient(180deg, #D9B5C1 0%, #9A97B7 100%);`,
  softLong: `linear-gradient(90.06deg, #C6A3AF 0%, #9A97B7 100%);`,
  grey9: `linear-gradient(282.66deg, #2C2C42 8.88%, #4C4C79 75.68%);`,
};
