import {useContext, useEffect} from 'react';
import {when} from '@mindfulness/utils/maybe';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';

import {ModalContext} from '../components/global';

/**
 * Handles opening and prefilling the modal based on the current route params
 * @param {string} name - The name of the modal to open, either action or single depending on the page.
 * @param {objext} props - Any props that you want to pass into the modal
 */
export const useOpenModal = (name: string, props: Record<string, any>) => {
  const {openModal} = useContext(ModalContext);
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  useEffect(() => {
    if (pathname && name) {
      const modal = searchParams.get('modal');
      if (modal === 'true') {
        const email = searchParams.get('email');
        const token = searchParams.get('token');
        openModal?.({
          name,
          props: {
            ...props,
            ...when(email || token, () => ({email, token, view: 'verify'})),
          },
        });
        const params = new URLSearchParams(searchParams.toString());
        params.delete('modal');
        params.delete('email');
        params.delete('token');

        router.replace(
            `${pathname}?${params.toString()}`,
            undefined,
        );
      }
    }
  }, [searchParams, pathname, name]);
};
