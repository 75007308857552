import * as React from 'react';
import {Theme} from '@emotion/react';
import {Alignment, Figure} from '@mindfulness/cms';

import {BackgroundAPIImage, BackgroundImage} from '../../global';
import {Box, Column} from '../../layout';
import {H4} from '../../typography';

import style from './TextOnImageCard.module.css';
import {AspectRatio} from '../../layouts/AspectRatio';

export const TextOnImageCard: React.FC<Props> = ({
  image,
  title,
  fontSize = 'xl',
  alignment = 'center',
}) => (
  <div className={style.textOnImageContainer}>
    {typeof image === 'string' ? (
      <BackgroundAPIImage src={image} overlay={0.4}>
        <Content title={title} alignment={alignment} fontSize={fontSize} />
      </BackgroundAPIImage>
    ) : (
      <BackgroundImage image={image} overlay={0.4}>
        <Content title={title} alignment={alignment} fontSize={fontSize} />
      </BackgroundImage>
    )}
  </div>
);

const Content: React.FC<ContentProps> = ({title, alignment, fontSize}) => (
  <AspectRatio ratio="20:13">
    <Box padding={16}>
      <Column textAlign={alignment}>
        <H4 weight="bold" size={fontSize} colour="white">
          {title}
        </H4>
      </Column>
    </Box>
  </AspectRatio>
);

type Props = ContentProps & {
  image?: Figure | string;
};

type ContentProps = {
  title?: string;
  fontSize?: keyof Theme['fontSizes'];
  alignment?: Alignment;
};
