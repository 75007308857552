import * as React from 'react';

import {SectionIndex, ReferencedArticleReel} from '../../../types/types';
import {H2, Text} from '../../typography';
import {Column, Container, Reel, Row} from '../../layout';
import {ArticleCard} from '../../ui';
import {Section} from '../../layouts/Section';

export const ArticleReel: React.FC<SectionIndex<ReferencedArticleReel>> = ({
  title,
  paragraph,
  articles,
  background,
  paddingTop,
  paddingBottom,
}) =>
  articles?.length > 0 ? (
    <Section
      padding={{
        xs: 30,
        md: 80,
      }}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
      background={background}
    >
      {title || paragraph ? (
        <Container
          maxWidth="md"
          spacing={{
            xs: 30,
            md: 60,
          }}
        >
          <Row>
            <Column textAlign="center">
              {title ? <H2 spacing={10}>{title}</H2> : null}
              {paragraph ? <Text colour="grey7">{paragraph}</Text> : null}
            </Column>
          </Row>
        </Container>
      ) : null}
      <Container gutter={{xs: 0, lg: 30}}>
        <Row>
          <Column>
            <Reel
              show={{
                xs: 1.2,
                sm: 2.2,
                lg: 3.2,
              }}
              scroll={1}
              center
              gap={16}
              arrowRatio={3.2}
            >
              {articles?.map((article) => (
                <ArticleCard {...article} key={article._id} />
              ))}
            </Reel>
          </Column>
        </Row>
      </Container>
    </Section>
  ) : null;
