import {useRecoilValue} from 'recoil';
import {omitEmpty} from '@mindfulness/utils/object';

import {onboardingSelectionsState} from '../../../state/atoms';
import {ExperienceLevel, MixedResultType} from '../../../types/types';

export const useUserPreferences = () => {
  const onboardingSelections = useRecoilValue(onboardingSelectionsState);

  return omitEmpty({
    followingTags: onboardingSelections.topics.map((slug) => ({
      slug,
    })),
    preferredContent: [
      ...(onboardingSelections['sleep-improve'] as Array<MixedResultType>),
      ...(onboardingSelections['anxiety-improve'] as Array<MixedResultType>),
      ...(onboardingSelections['stress-improve'] as Array<MixedResultType>),
      ...(onboardingSelections['improve'] as Array<MixedResultType>),
    ],
    experience: onboardingSelections.experience[0] as ExperienceLevel,
    practiceGoal: Number(onboardingSelections.goal),
  });
};
