import {useCallback} from 'react';
import {updateUser} from '../../../api';
import {Maybe} from '../../../types/types';
import {AuthedSession, unNull} from '../../../utils';

import {HandleAction} from './useActions';

export const useAddEmail = ({session, handleAction}: Props) => {
  const handleAddEmail = useCallback(
      async (email: string, useAction?: boolean) => {
        const res = await updateUser({
          details: {
            email,
          },
          session: unNull(session),
        });
        if (useAction) {
          await handleAction(email);
        }
        return res;
      },
      [session, handleAction],
  );
  return handleAddEmail;
};

type Props = {
  session: Maybe<AuthedSession> | null;
  handleAction: HandleAction;
};
