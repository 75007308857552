import {useCallback} from 'react';
import {when} from '@mindfulness/utils/maybe';

import {signup} from '../../../api';
import {useModal} from '../../../hooks';
import {trackNewOrExistingUsers} from '../../../utils';
import {useUserPreferences} from './useUserPreferences';
import {AuthProps} from '.';
import {useAnalytics} from '../SegmentProvider';

export const useAppleLogin = ({
  handleLogin,
  pageData,
  handleAction,
}: AuthProps) => {
  const actionModal = useModal('action');
  const emailModal = useModal('email');
  const preferences = useUserPreferences();
  const {identify, track} = useAnalytics();

  const handleAppleLogin = useCallback(
      async (useAction: boolean) => {
        const response = await AppleID.auth.signIn();
        if (!response.authorization) {
          throw new Error('Apple sign in modal closed');
        }
        const action = pageData?.action;
        const thankYou = action?.thankYouLink || action?.thankYouPage?.current;
        const {data: res} = await signup(
            {
              apple: {
                token: response.authorization.id_token,
              },
              userDetails: {
                email: response.user?.email,
                ...response.user?.name,
                cohorts: [
                  ...(pageData?.action?.cohort ? [pageData?.action?.cohort] : []),
                ],
              },
              preferences,
            },
            {
              ...when(thankYou, (t) => ({
                thankYou: t,
              })),
            },
        );
        if (!res?.signup) {
          throw new Error('Couldn\'t sign up with Apple');
        }
        await trackNewOrExistingUsers(res.signup, {
          identify,
          track,
        });
        if (res.signup?.user?.email?.includes('@privaterelay.appleid.com')) {
          actionModal.close();
          await handleLogin(res.signup);
          emailModal.open({
            props: {
              useAction,
            },
            // If they close the modal without entering an email we handle the action without an email adress
            onClose: async () => {
              if (useAction) {
                await handleAction(undefined);
              }
            },
          });
        } else {
          actionModal.close();
          await handleLogin(res?.signup);
          if (useAction) {
            await handleAction(res?.signup.user.email || undefined);
          }
        }
        return {
          name: res.signup.user.fullName,
          email: res.signup.user.email || undefined,
        };
      },
      [handleLogin, handleAction, pageData, preferences, identify, track],
  );

  return handleAppleLogin;
};
