import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const useSecondsToMinutes = (seconds: number) => {
  return React.useMemo(() => {
    return dayjs.duration(seconds, 'seconds').format('[h]HH:mm:ss').replace('h00:', '').replace('h', '');
  }, [seconds]);
};
