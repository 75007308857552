import * as React from 'react';
import {RichText} from '@mindfulness/cms';

import {ButtonLink, Context, ModalContext, OverlayModal} from '../../global';
import {Box, Stack} from '../../layout';
import {AuthForm} from '../AuthForm';
import {Title1, Text} from '../../typography';
import {Button} from '../../forms';

const paragraph = [
  {
    _key: 'f0ed58af2846',
    _type: 'block',
    children: [
      {
        _key: '2ef972a353590',
        _type: 'span',
        marks: [],
        text: 'It looks like you need to sign in to unlock this content. It’s easy — just enter your email to get access. ',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
] as RichText;

export const LockedModal: React.FC = () => {
  const {session, handleLogout} = React.useContext(Context);
  const {activeProps} = React.useContext(ModalContext);
  const props = React.useMemo(() => activeProps.action, [activeProps.action]);
  const [loading, setLoading] = React.useState<boolean>(false);
  return (
    <OverlayModal name="action" padding={64}>
      <Box maxWidth="452px">
        {session ? (
          <Stack direction="vertical" space={16}>
            <Title1>Are you using the correct account?</Title1>
            <Text spacing={30}>
              It looks like this email address isn’t associated with this
              purchase. If you’ve already purchased the Masterclass, check your
              confirmation email to make sure you’ve got the correct account
              email.
            </Text>
            {props?.unlockUrl ? (
              <ButtonLink id={'lockedAction'} href={props.unlockUrl} wide>
                Purchase the masterclass
              </ButtonLink>
            ) : null}
            <Button
              variant="secondary"
              id={'lockedLogout'}
              onClick={() => handleLogout?.()}
              wide
            >
              Switch account
            </Button>
          </Stack>
        ) : (
          <AuthForm
            index={0}
            paragraph={paragraph}
            title={'Sign in to access'}
            loading={loading}
            setLoading={setLoading}
            {...props}
          />
        )}
      </Box>
    </OverlayModal>
  );
};
