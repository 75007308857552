import * as React from 'react';

import {ItemLocation, Maybe, WithWebSlug} from '../../../types/types';
import type {BundleFeedFragment, CollectionLayout} from '../../../types/api';
import {ItemContainer, PlayButtonCircle} from './InProgressBundleCard.styles';
import {Box} from '../../layout';
import {BackgroundAPIImage, Icon} from '../../global';
import style from '../SingleProgressBar/SingleProgressBar.module.css';
import {WithLinkProps, withLink} from '../../../hoc/withLink';
import {assertNumber} from '../../../utils/number';
import {AspectRatio} from '../../layouts/AspectRatio';

export const InProgressBundleCard: React.FC<Props & WithLinkProps> = withLink(
    ({cellImage, cardImage, posterImage, subtitle, title, label, userData}) => (
      <ItemContainer>
        <AspectRatio ratio="286:350">
          <Box
            color="white"
            position="relative"
            zIndex={1}
            radius="lg"
            overflow="hidden"
            paddingX={24}
          >
            <BackgroundAPIImage
              className="cell-image"
              src={posterImage?.url ?? cellImage?.url ?? cardImage?.url}
              scale={1}
              alt={`${title} ${subtitle} ${label}`}
            />
            <PlayButtonCircle>
              <Icon name="player-play" size={24} colour="white" />
            </PlayButtonCircle>
            <div className={[
              style.progressBarContainer,
              style.card,
            ].join(' ')}>
              <div className={style.progressBar} style={{
                width: assertNumber(userData?.completedPercent),
              }} />
            </div>
          </Box>
        </AspectRatio>
      </ItemContainer>
    ),
);

type Props = WithWebSlug<BundleFeedFragment> & {
  location?: ItemLocation;
  priority?: boolean;
  layout?: Maybe<CollectionLayout>;
};
