import * as React from 'react';
import {v4 as uuidv4} from 'uuid';
import {ToastContainer, ToastItem} from './ToastProvider.styles';
import {Maybe} from '../../../types/types';
import FadeIn from 'react-fade-in/lib/FadeIn';

const defaultProps = {
  items: [],
  handleAdd: undefined,
};

export const ToastContext = React.createContext<ContextState>({
  ...defaultProps,
});

export const ToastProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [items, setItems] = React.useState<ContextState['items']>(
      defaultProps.items,
  );

  const handleAdd = React.useCallback(
      (item: ToastItem['item']) => {
        setItems((prev) => [
          ...prev,
          {
            id: uuidv4(),
            item,
          },
        ]);
      },
      [],
  );

  const handleRemove = React.useCallback(
      (id: string) => {
        setItems((prev) => prev.filter((item) => item.id !== id));
      },
      [],
  );

  return (
    <ToastContext.Provider
      value={{
        items,
        handleAdd,
      }}
    >
      <ToastContainer>
        <FadeIn>
          {items.map(({item, id}) => (
            <Toast id={id} handleRemove={handleRemove} key={id}>
              {item}
            </Toast>
          ))}
        </FadeIn>
      </ToastContainer>
      {children}
    </ToastContext.Provider>
  );
};

type ContextState = {
  items: Array<ToastItem>;
  handleAdd: Maybe<(item: ToastItem['item']) => void>;
};

type ToastItem = {
  id: string;
  item: React.ReactElement;
};

const Toast: React.FC<ToastProps> = ({children, id, handleRemove}) => {
  React.useEffect(() => {
    setTimeout(() => {
      handleRemove(id);
    }, 6000);
  }, [id]);
  return <ToastItem>{children}</ToastItem>;
};

type ToastProps = React.PropsWithChildren<{
  id: string;
  handleRemove: (id: string) => void;
}>;
