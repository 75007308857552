import styled from '@emotion/styled';

export const Wrapper = styled.div(
    ({theme}) => `
  margin-top: -72px;
  position: relative;
  @media (min-width: ${theme.breakpoints.md.min}) {
    margin-top: -172px;
  }
`,
);

export const TextWrapper = styled.div(
    ({theme}) => `
  text-align: center;
  margin-bottom: ${theme.spacings[80]};
`,
);

export const VideoWrapper = styled.div(
    ({theme}) => `
  height: 345px;
  width: 100%;
  border-radius: ${theme.radius.lg};
  overflow: hidden;
  margin-bottom: ${theme.spacings[10]};
  position: relative;
`,
);
