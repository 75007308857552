import * as React from 'react';
import {Figure, SignupCover as SignupCoverProps} from '@mindfulness/cms';

import {SectionIndex} from '../../../types/types';
import {Context} from '../../global';
import {AuthForm, SplitCover} from '../../ui';
import {useAuthView} from '../../../hooks/useAuthView';
import {useEffect} from 'react';

export const SignupCover: React.FC<SectionIndex<SignupCoverProps>> = (
    props,
) => {
  const {data} = React.useContext(Context);
  const [_email, setEmail] = React.useState<string>();

  const {token, email, view, setView, loading, setLoading} = useAuthView();
  const defaultModalImage: Figure = React.useMemo(
      () => ({
        _type: 'figure',
        image: {
          _type: 'image',
          asset: {
            _ref: 'image-ed691c75c71a31d72cf1e0d34685cbd5eafebe5a-720x900-jpg',
            _type: 'reference',
          },
        },
      }),
      [],
  );

  const onVerify = ({
    email,
  }: {
    email?: string | undefined;
    name?: string | undefined;
  }) => {
    setView('verify');
    setEmail(email);
  };

  useEffect(() => {
    if (email && !_email) {
      setEmail(email);
    }
  }, [email]);

  return (
    <SplitCover
      index={props.index}
      image={props.image || data?.action?.modal?.image || defaultModalImage}
    >
      <AuthForm
        {...props}
        loading={loading}
        setLoading={setLoading}
        token={token}
        email={_email}
        view={view}
        setView={setView}
        onVerify={onVerify}
        // We are manually setting the view to 'verify' in the onVerify prop
        requireVerify={false}
      />
    </SplitCover>
  );
};
