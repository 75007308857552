import * as React from 'react';
import {Maybe} from '@mindfulness/utils/maybe';
import {BasicReel, Box, Flex} from '../../layout';
import {UncontainedSpacer} from '../Reel/Reel.styles';
import {NewLink} from '../../global';
import styles from './PillList.module.css';

export const PillList: React.FC<Props> = ({items}) => (
  <BasicReel FrontSpacer={() => <UncontainedSpacer />}>
    <Flex gap={10} wrap="nowrap">
      {items.map((item) => {
        if (!item) {
          return null;
        }
        const {url, active, label} = item;
        return (
          <NewLink href={url} replace scroll={false} key={url} passHref>
            <span className={[
              styles.pillLink,
              active ? styles.active : '',
            ].join(' ')}>{label}</span>
          </NewLink>
        );
      })}
      <Box width="1rem" />
    </Flex>
  </BasicReel>
);

type Props = {
  items: Array<
    Maybe<{
      url: string;
      active: boolean;
      label: string;
    }>
  >;
};
