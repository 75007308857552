import styled from '@emotion/styled';
export const SingleListItemContainer = styled.div(
    ({theme}) => `
border-bottom: 1px solid ${theme.colors.grey3};
padding-bottom: ${theme.spacings[15]};
margin-bottom: ${theme.spacings[15]};
.list-title {
  font-size: ${theme.fontSizes.md};
  transition: color 0.2s ease;
  will-change: color;
}
.cell-image {
  transition: transform 0.3s ease;
  will-change: transform;
}
&:hover {
  .list-title {
    color: ${theme.colors.primary};
  }
  .cell-image {
    transform: scale(1.1);
  }

  svg {
    animation: nudge 0.4s linear;
    color: ${theme.colors.primary};
  }

&:active {
  .list-title {
    color: ${theme.colors.primary};
  }
}
`,
);
