import {useCallback} from 'react';
import {when} from '@mindfulness/utils/maybe';

import {signup} from '../../../api';
import {useModal} from '../../../hooks';
import {trackNewOrExistingUsers} from '../../../utils';

import {useUserPreferences} from './useUserPreferences';
import {AuthProps} from '.';
import {useAnalytics} from '../SegmentProvider';

export const useGoogleLogin = ({
  handleLogin,
  pageData,
  handleAction,
}: AuthProps) => {
  const emailModal = useModal('email');
  const actionModal = useModal('action');
  const preferences = useUserPreferences();
  const {track, identify} = useAnalytics();
  const handleGoogleLogin = useCallback(
      async (code: string, useAction: boolean) => {
        const googleRes = await fetch('/api/auth/google', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code,
          }),
        });
        const googleJson = await googleRes.json();
        const action = pageData?.action;
        const thankYou = action?.thankYouLink || action?.thankYouPage?.current;
        const {data: res} = await signup(
            {
              google: {
                accessToken: googleJson.tokens.id_token,
              },
              userDetails: {
                fullName: googleJson.user.name,
                email: googleJson.user.email,
                cohorts: [
                  ...(pageData?.action?.cohort ? [pageData?.action?.cohort] : []),
                ],
              },
            },
            {
              ...when(thankYou, (t) => ({
                thankYou: t,
              })),
            },
        );

        if (!res) {
          throw new Error('Couldn\'t sign up with Google');
        }
        await trackNewOrExistingUsers(res.signup, {
          track,
          identify,
        });
        // There has not been an email address provided so we show the email modal, if the
        // email is not provided to the email modal we still handle the action but without
        // an email address
        if (!res.signup.user.email) {
          actionModal.close();
          await handleLogin(res.signup);
          emailModal.open({
            props: {
              useAction,
            },
            onClose: async () => {
              if (useAction) {
                await handleAction(undefined);
              }
            },
          });
        } else {
          actionModal.close();
          await handleLogin(res.signup);
          if (useAction) {
            await handleAction(res?.signup.user.email);
          }
        }
        return {
          email: res.signup.user.email,
          name: res.signup.user.fullName,
        };
      },
      [pageData, handleAction, handleLogin, preferences, actionModal],
  );

  return handleGoogleLogin;
};
