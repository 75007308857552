import {Theme} from '@emotion/react';
import styled from '@emotion/styled';

export const Gap = styled.div<{
  space: keyof Theme['spacings'];
}>(
    ({theme, space}) => `
  > * {
    margin-left: 0;
    margin-right: 0;
  }

  > * + * {
    margin-left: ${theme.spacings[space]};
  }
`,
);
