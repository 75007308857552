import styled from '@emotion/styled';

export const TextContainer = styled.div(
    ({theme}) => `
padding-top: ${theme.spacings[10]};
padding-left: ${theme.spacings[16]};
padding-right: ${theme.spacings[16]};
`,
);

export const ItemContainer = styled.div<{
  location: 'reel' | 'grid';
}>(
    ({theme, location}) => `
${location === 'reel' ? `padding-right: ${theme.spacings[24]}` : ``};
display: block;

img {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

&:hover {
  img {
    transform: scale(1);
  }
}
`,
);
