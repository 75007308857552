import {useEffect} from 'react';
import Cookie from 'js-cookie';
import {defaultEventProps, segmentCampaign} from '../utils/analytics';
import {useSegment} from '../components/global/SegmentProvider';

export const useTrackPage = (name: string, id: string, props?: Record<string, any>) => {
  const segment = useSegment();
  useEffect(() => {
    const testCookie = Cookie.get('test-cohorts');
    const cohortArray = testCookie ? testCookie.split(',') : [];
    if (!segment) return;
    segment.page(
        name,
        {
          page_id: id,
          page_name: name,
          web_cohorts: cohortArray,
          path: location.pathname,
          ...defaultEventProps(props),
        },
        {
          context: {
            campaign: segmentCampaign(location.search),
          },
        },
    );
  }, [segment]);
};

