import {useContext, useMemo} from 'react';
import {isUndefined} from 'lodash';

import {Context} from '../components/global';

import {SingleDetailFragment} from '../types/api';
import {useModal} from './useModal';
import {isDefined} from '@mindfulness/utils/maybe';

export const useLockedSingle = (s?: SingleDetailFragment) => {
  const {session} = useContext(Context);
  const actionModal = useModal('action');

  const isLocked = useMemo(
      () =>
        isUndefined(session) || (isDefined(s?.userData) && s?.userData?.locked),
      [s, session],
  );

  const loginAction = () => {
    actionModal?.open({
      props: {
        view: 'login',
        redirect: () => {
          actionModal?.close();
        },
      },
    });
  };

  return {
    isLocked,
    loginAction,
  };
};
