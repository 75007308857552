import {createElement, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import styles from './TitleLarge.module.css';
import {getResponsiveClassNames} from 'shared/utils';
import {HeadingProps} from '../Headings';

export const TitleLarge = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLHeadingElement> & HeadingProps>>(({
  children, className, style,
  as = 'div',
  spacing = 0,
  ...props
}, ref) => {
  return createElement(as, {
    className: [
      styles.titleLarge,
      ...getResponsiveClassNames(spacing, 'mb'),
      ...(className ? [className] : []),
    ].join(' '),
    style: {
      ...style,
    },
    ...props,
    ref,
  }, children);
});


TitleLarge.displayName = 'TitleLarge';


