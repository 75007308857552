import * as React from 'react';

import {ItemContainer, TextContainer} from './ArticleCard.styles';

import {useEstimatedReadingTime} from '../../../hooks';
import {ReferencedArticleReel} from '../../../types/types';

import {H3, Small} from '../../typography';
import {BasicImage, NewLink} from '../../global';

export const ArticleCard: React.FC<
  ReferencedArticleReel['articles'][0] & { location?: 'reel' | 'grid' }
> = ({location = 'grid', ...article}) => {
  const {title, slug, image} = article;
  const estMinutes = useEstimatedReadingTime(article);
  return slug?.current ? (
    <NewLink href={slug?.current} key={article._id}>
      <ItemContainer location={location}>
        {image ? (
          <BasicImage
            image={image}
            decoration="overlay"
            ratio="39:28"
            objectFit="cover"
          />
        ) : null}

        <TextContainer>
          <Small colour="grey7" spacing={6}>
            {article.producer?.teacher?.fullName || 'Mindfulness.com'}
          </Small>
          <H3 as="h4" weight="bold" spacing={16}>
            {title}
          </H3>
          <Small colour="grey7">{`${estMinutes} minutes reading`}</Small>
        </TextContainer>
      </ItemContainer>
    </NewLink>
  ) : null;
};
