import {useContext, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import FadeIn from 'react-fade-in';
import Cookies from 'js-cookie';

import styles from './MobileBanner.module.css';
import {Text} from '../../typography';
import {APIImage, Context, Icon} from '../../global';
import {Box} from '../../layout/Box';
import {useAsyncEffect} from '../../../hooks/useAsyncEffect';
import {isOnClient, onClient} from '../../../utils';
import {generateInstantAccess} from '../../../api';
import {InstantAccess, Maybe} from '../../../types/api';
import {Flex, Stack} from '../../layout';
import {useTrack} from '../../global/SegmentProvider';
import {Button} from '../../forms';
import {useNavigation} from '../../../hooks/useNavigation';
import {NormalizedButton} from '../NormalizedButton';

const isMobileOrTablet = isMobile || isTablet;

export const MobileBanner: React.FC<Props> = ({
  deeplink,
}) => {
  const track = useTrack();
  const {session} = useContext(Context);
  const {router, removeHash} = useNavigation();
  const [accessLink, setAccessLink] = useState<string>();
  const [open, setOpen] = useState(false);
  const {asPath} = router;

  // Debug mobile banner
  useAsyncEffect(async () => {
    if (!router.isReady) return;
    const {hash} = new URL(`https://mindfulness.com/${asPath}`);

    if (hash?.includes('webToMobile')) {
      Cookies.remove('webToMobile');
      Cookies.remove('webToMobileExpiry');
      const res = await generateInstantAccess({
        type: InstantAccess.Login,
        email: session?.email,
        onto: deeplink,
      });
      const link = res?.generateInstantAccess.link;

      if (link) {
        setAccessLink(link);
        setOpen(true);
        track('Mobile banner shown');
      }
      removeHash();
    }
  }, [router.isReady, asPath]);

  useAsyncEffect(async () => {
    if (!isOnClient() || !isMobileOrTablet || !session?.email) return;
    const expiry = Cookies.get('webToMobile');
    if (expiry) return;
    const res = await generateInstantAccess({
      type: InstantAccess.Login,
      email: session?.email,
      onto: deeplink,
    });
    const link = res?.generateInstantAccess.link;
    if (link) {
      setAccessLink(link);
      setOpen(true);
      track('Mobile banner shown');
    }
  }, [session, deeplink]);

  const handleInstantAccess = async () => {
    track('Mobile banner clicked', {
      deeplink,
    });
    Cookies.remove('webToMobile');
    Cookies.remove('webToMobileExpiry');
    onClient(() => {
      window.open(accessLink, '_blank')?.focus();
    });
  };
  const handleClose = async () => {
    setOpen(false);
    await track('Mobile banner closed');
    const expiryStr = Cookies.get('webToMobileExpiry');
    const expiryNum = expiryStr ? Number(expiryStr) : 1;
    const nextExpiry = expiryNum * 2;
    Cookies.set('webToMobile', `${nextExpiry}`, {expires: nextExpiry});
    Cookies.set('webToMobileExpiry', `${nextExpiry}`, {expires: 365});
  };

  return accessLink && open ? (
    <FadeIn>
      <div className={styles.wrapper}>
        <Stack space={10} items="center" justify="between">
          <Flex items="center" gap={10} grow>
            <NormalizedButton width="auto" onClick={() => handleClose()}>
              <Icon name="close" size={24} />
            </NormalizedButton>
            <APIImage url="/images/line-item.png" width={55} height={55} />
            <Box>
              <Text fontWeight="bold">Open in the mobile app</Text>
              <Text fontSize="xs">For an improved mobile experience.</Text>
            </Box>
          </Flex>

          <Button onClick={handleInstantAccess} id="OpenInMobile" size="xs">
            Open
          </Button>
        </Stack>
      </div>

    </FadeIn>
  ) : null;
};


type Props = {
  deeplink: Maybe<string>;
}
