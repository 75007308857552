import React from 'react';
import {
  Maybe,
  OrderFragment,
  OrderPurchaseFragment,
} from '../../../types/api';
import {Box, Flex} from '../../layout';
import {Divider} from '../Divider';
import {BackgroundAPIImage} from '../../global/Image';
import {Text} from '../../typography';
import {ButtonLink} from '../../global/ButtonLink';
import {unNull} from '../../../utils/maybe';
import {assertString} from '../../../utils/string';
import {ContentFlex, RowFlex} from './PurchaseList.styled';
import {useNavigation} from '../../../hooks';
import {Button} from '../../forms/Button';

export const PurchaseList: React.FC<{
  order: OrderFragment;
}> = ({order}) => (
  <>
    {order.purchasables.map((purchase) => {
      const {title, subtitle, id, cellImage} = purchase;
      return (
        <React.Fragment key={id}>
          <RowFlex key={id}>
            <Box
              overflow="hidden"
              radius="lg"
              position="relative"
              minWidth="64px"
              height="64px"
              zIndex={1}
            >
              <BackgroundAPIImage
                src={assertString(cellImage?.url, '/images/line-item.png')}
                doNotResize
              />
            </Box>
            <ContentFlex>
              <Box>
                <Text fontWeight="bold">{title}</Text>
                <Text colour="primary">{subtitle}</Text>
              </Box>
              <Flex justify="end">
                <UnlockButtons {...purchase} subscriptionLocation={order.metadata?.subscriptionLocation} />
              </Flex>
            </ContentFlex>
          </RowFlex>
          <Divider spacing={20} />
        </React.Fragment>
      );
    })}
  </>
);

const UnlockButtons: React.FC<OrderPurchaseFragment & {
  subscriptionLocation: Maybe<string>;
}> = ({
  unlocks,
  content,
  subscriptionLocation,
}) => {
  const {goTo} = useNavigation();
  if (unlocks?.subscription) {
    return (
      <Button
        onClick={() => {
          if (subscriptionLocation) {
            goTo(subscriptionLocation);
          }
          goTo({
            pathname: '/',
          });
        }}
        id={`UnlocksSubscription`}
        size="xs"
      >
        {subscriptionLocation ? 'Access' : 'Download app'}
      </Button>
    );
  }
  if (unlocks?.bundle) {
    return (
      <UnlockBundle
        id={unlocks.bundle}
        webSlug={
          content && 'webSlug' in content ? unNull(content?.webSlug) : undefined
        }
      />
    );
  }
  if (unlocks?.single) {
    return (
      <UnlockSingle
        id={unlocks.single}
        webSlug={
          content && 'webSlug' in content ? unNull(content?.webSlug) : undefined
        }
      />
    );
  }
  return null;
};

const UnlockBundle: React.FC<{
  id: string;
  webSlug: Maybe<string>;
}> = ({id, webSlug}) => {
  return (
    <ButtonLink
      href={assertString(webSlug, `/play?b=${id}`)}
      id={`UnlocksBundle`}
      size="xs"
    >
      Access
    </ButtonLink>
  );
};

const UnlockSingle: React.FC<{
  webSlug: Maybe<string>;
  id: string;
}> = ({id, webSlug}) => {
  return (
    <ButtonLink
      href={assertString(webSlug, `/play?s=${id}`)}
      id={`UnlocksSingle`}
      size="xs"
    >
      Access
    </ButtonLink>
  );
};
