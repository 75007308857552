import {CSSProperties} from 'react';
import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {
  getFontSize,
  getFontWeight,
  getSpacing,
  getTopSpacing,
  listColourMap,
} from '../../utils';
import {ColoursGradients, ResponsiveValue} from '../../types/types';

export const Text = styled.p<Props>(
    ({
      theme,
      colour,
      fontSize,
      fontWeight,
      spacing,
      spacingTop,
      prose,
      lineHeight,
      decoration,
      transform,
      align,
      loading,
      loadingHeight = 16,
      ellipsis,
      cursor,
      noSelect,
      fontFamily,
    }) => `
    ${
      loading ?
        `
      color: ${theme.colors.grey4};
      background-color: ${theme.colors.grey4};
      border-radius: ${theme.radius.sm};
      height: ${loadingHeight}px;
    ` :
        `
  ${colour ? `color: ${theme.colors[colour]};` : ''}

      `
}
  ${noSelect ? `user-select: none;` : ''}
  ${getFontSize(theme, fontSize)}
  ${getFontWeight(theme, fontWeight)}
  ${fontFamily === 'heading' ? `font-family: var(--font-heading);` : ''}
  ${getSpacing(theme, spacing)}
  ${getTopSpacing(theme, spacingTop)}
  ${lineHeight ? `line-height: ${lineHeight};` : ''}
  ${decoration ? `text-decoration: ${decoration};` : ''}
  ${transform ? `text-transform: ${transform};` : ''}
  ${cursor ? `cursor: ${cursor};` : ''}
  ${
    ellipsis ?
      `
  -webkit-line-clamp: ${ellipsis};
  line-clamp: ${ellipsis};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  ` :
      ''
}
  ${align ? `text-align: ${align};` : ''}
  ${
    prose ?
      `
    max-width: ${theme.sizes.prose};
    margin-left: auto;
    margin-right: auto;
    padding-left: ${theme.spacings[30]};
    padding-right: ${theme.spacings[30]};
  ` :
      ''
}
`,
);

export const Paragraph = Text;

export const Strong = styled.strong<Props>(
    ({theme, colour, fontWeight = 'bold', fontSize}) => `
  ${colour ? `color: ${theme.colors[colour]};` : ''}
  ${getFontSize(theme, fontSize)}
  ${getFontWeight(theme, fontWeight)}
`,
);

export const Small = styled.small<Props>(
    ({
      theme,
      colour,
      fontSize = 'sm',
      spacing,
      fontWeight,
      transform,
      decoration,
      lineHeight,
      wrap,
      ellipsis,
    }) => `
    display: block;
    ${getFontSize(theme, fontSize)}
    ${colour ? `color: ${theme.colors[colour]};` : ''}
    ${getSpacing(theme, spacing)}
    ${getFontWeight(theme, fontWeight)}
    ${transform ? `text-transform: ${transform};` : ''}
    ${lineHeight ? `line-height: ${lineHeight};` : ''}
    ${ellipsis ? `text-overflow: ellipsis;` : ''}
    ${wrap ? `white-space: ${wrap};` : ``}
    ${decoration ? `text-decoration: ${decoration};` : ''}

`,
);

export const Blockquote = styled.blockquote<Props>(
    ({theme, colour, fontWeight = 'regular', fontSize = 'quote', spacing}) => `
  ${colour ? `color: ${theme.colors[colour]};` : ''}
  ${getFontSize(theme, fontSize)}
  ${getFontWeight(theme, fontWeight)}
  ${getSpacing(theme, spacing)}

  font-family: var(--font-display);
  text-align: center;

  @media (min-width: ${theme.breakpoints.md.min}) {
    line-height: 50px;
  }
`,
);

export const ArticleQuote = styled.blockquote<Props>(
    ({
      theme,
      colour,
      fontWeight = 'regular',
      fontSize = 'lg',
      spacing = 16,
      prose,
    }) => `
  ${colour ? `color: ${theme.colors[colour]};` : ''}
  ${getFontSize(theme, fontSize)}
  ${getFontWeight(theme, fontWeight)}
  ${getSpacing(theme, spacing)}
  ${
    prose ?
      `
    max-width: ${theme.sizes.prose};
    margin-left: auto;
    margin-right: auto;
  ` :
      ''
}
  font-family: var(--font-display);
  border-left: 4px solid ${theme.colors.primary};
  padding-left: ${theme.spacings[16]};
`,
);

export const Label = styled.label(
    ({theme}) => `
  font-size: ${theme.fontSizes.base};
  color: inherit;
  display: flex;
  align-items: center;
  cursor: pointer;
`,
);

export const UnorderedList = styled.ul<Props>(
    ({theme, colour, fontSize, fontWeight, spacing = 16, prose}) => `
  padding-left: 1.5rem;
  text-align: left;
  ${colour ? `color: ${theme.colors[colour]};` : ''}
  ${getFontSize(theme, fontSize)}
  ${getFontWeight(theme, fontWeight)}
  ${getSpacing(theme, spacing)}
  ${
    prose ?
      `
    max-width: ${
      prose === true ? theme.sizes.prose : theme.sizes.container[prose]
    };
    margin-left: auto;
    margin-right: auto;
    padding-left: ${theme.spacings[30]};
    padding-right: ${theme.spacings[30]};
  ` :
      ''
}
`,
);

export const UnorderedListItem = styled.li<{
  background?: ColoursGradients;
  spacing?: keyof Theme['spacings'];
  tick?: boolean;
}>(({theme, background = 'white', tick, spacing = 8}) => {
  const svg = tick ?
    `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.3696 3.07117C6.74001 2.15549 8.35118 1.66675 9.99935 1.66675C11.0937 1.66675 12.1773 1.8823 13.1884 2.30109C14.1994 2.71987 15.1181 3.3337 15.8919 4.10752C16.6657 4.88135 17.2796 5.80001 17.6983 6.81105C18.1171 7.8221 18.3327 8.90573 18.3327 10.0001C18.3327 11.6483 17.8439 13.2594 16.9283 14.6298C16.0126 16.0002 14.7111 17.0683 13.1884 17.6991C11.6657 18.3298 9.99011 18.4948 8.3736 18.1733C6.75709 17.8517 5.27223 17.0581 4.1068 15.8926C2.94136 14.7272 2.14769 13.2423 1.82614 11.6258C1.5046 10.0093 1.66963 8.33377 2.30036 6.81105C2.93109 5.28834 3.99919 3.98685 5.3696 3.07117ZM12.2657 7.32509L8.69065 10.9084L7.31565 9.53342C7.24095 9.44619 7.14901 9.37534 7.04563 9.32532C6.94224 9.2753 6.82963 9.24719 6.71486 9.24276C6.6001 9.23832 6.48565 9.25766 6.37872 9.29956C6.27178 9.34145 6.17465 9.405 6.09344 9.48621C6.01223 9.56742 5.94868 9.66455 5.90679 9.77148C5.86489 9.87842 5.84555 9.99286 5.84999 10.1076C5.85442 10.2224 5.88253 10.335 5.93255 10.4384C5.98257 10.5418 6.05342 10.6337 6.14065 10.7084L8.09899 12.6751C8.17685 12.7523 8.2692 12.8134 8.37073 12.8549C8.47226 12.8964 8.58098 12.9174 8.69065 12.9168C8.90927 12.9158 9.11877 12.829 9.27399 12.6751L13.4407 8.50842C13.5188 8.43095 13.5808 8.33878 13.6231 8.23723C13.6654 8.13568 13.6872 8.02676 13.6872 7.91675C13.6872 7.80674 13.6654 7.69782 13.6231 7.59627C13.5808 7.49472 13.5188 7.40256 13.4407 7.32509C13.2845 7.16988 13.0733 7.08276 12.8532 7.08276C12.633 7.08276 12.4218 7.16988 12.2657 7.32509Z' fill='%2300C48C'/%3E%3C/svg%3E")` :
    `url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="${theme.colors[
        listColourMap(background)
    ]?.replace(
        '#',
        '%23',
    )}" d="M7.30291 5.30813C7.1089 5.5033 7 5.76731 7 6.0425C7 6.3177 7.1089 6.58171 7.30291 6.77688L10.9904 10.5165L7.30291 14.204C7.1089 14.3992 7 14.6632 7 14.9384C7 15.2136 7.1089 15.4776 7.30291 15.6727C7.39975 15.7704 7.51496 15.8479 7.64189 15.9007C7.76879 15.9536 7.90499 15.9809 8.04249 15.9809C8.17999 15.9809 8.31619 15.9536 8.44309 15.9007C8.56999 15.8479 8.68529 15.7704 8.78209 15.6727L13.1988 11.2561C13.2964 11.1592 13.3739 11.044 13.4268 10.9171C13.4796 10.7901 13.5069 10.654 13.5069 10.5165C13.5069 10.379 13.4796 10.2428 13.4268 10.1159C13.3739 9.98888 13.2964 9.87368 13.1988 9.77688L8.78209 5.30813C8.68529 5.21049 8.56999 5.133 8.44309 5.08012C8.31619 5.02723 8.17999 5 8.04249 5C7.90499 5 7.76879 5.02723 7.64189 5.08012C7.51496 5.133 7.39975 5.21049 7.30291 5.30813Z" /></svg>')`;

  return `
  margin-bottom: ${theme.spacings[spacing]};
  position: relative;

  &::before {
    position: absolute;
    left: -1.5rem;
    content: ${svg};
  }
`;
});

export const OrderedList = styled.ol<Props>(
    ({theme, colour, fontSize, fontWeight, spacing, prose}) => `
  padding-left: 1.5rem;
  list-style-type: decimal;
  list-style-position: inside;
  text-align: left;
  ${colour ? `color: ${theme.colors[colour]};` : ''}
  ${getFontSize(theme, fontSize)}
  ${getFontWeight(theme, fontWeight)}
  ${getSpacing(theme, spacing)}
  ${
    prose ?
      `
    max-width: ${theme.sizes.prose};
    margin-left: auto;
    margin-right: auto;
    padding-left: ${theme.spacings[30]};
    padding-right: ${theme.spacings[30]};
  ` :
      ''
}
  & > li > ol {
    list-style-type: lower-alpha;
  }
`,
);

export const OrderedListItem = styled.li(
    ({theme}) => `
  margin-bottom: ${theme.spacings[8]};
`,
);

export const Tag = styled.span(
    ({theme}) => `
  text-transform: uppercase;
  display: inline-block;
  padding: 2px 10px 0;
  border-radius: 50px;
  background-color: ${theme.colors.grey4};
  font-size: ${theme.fontSizes['2xs']};
  font-weight: ${theme.fontWeights.bold};
`,
);

type Props = {
  colour?: keyof Theme['colors'];
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  spacingTop?: ResponsiveValue<keyof Theme['spacings']>;
  fontSize?: ResponsiveValue<keyof Theme['fontSizes']>;
  fontWeight?: ResponsiveValue<keyof Theme['fontWeights']>;
  transform?: CSSProperties['textTransform'];
  decoration?: CSSProperties['textDecoration'];
  prose?: keyof Theme['sizes']['container'] | boolean;
  wrap?: CSSProperties['whiteSpace'];
  lineHeight?: CSSProperties['lineHeight'];
  align?: CSSProperties['textAlign'];
  loading?: boolean;
  loadingHeight?: number;
  ellipsis?: number;
  cursor?: CSSProperties['cursor'];
  noSelect?: boolean;
  fontFamily?: 'heading' | 'body';
};
