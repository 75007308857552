import {useContext} from 'react';
import {ApolloError} from '@apollo/client';
import {Maybe} from '@mindfulness/utils/maybe';

import {useToast} from '../../../hooks';
import {ModalContext} from '../ModalProvider';
import {useTrack} from '../SegmentProvider';

export const useLoginFailure = () => {
  const {verify, alreadyAuthenticated} = useToast();
  const {openModal} = useContext(ModalContext);
  const track = useTrack();

  const handleLoginFailure = async (
      e: ApolloError,
      {requireVerify, onVerify, redirect, email, modalName}: Params,
  ) => {
    if (e.graphQLErrors?.[0]) {
      const {message} = e.graphQLErrors[0];
      if (message === 'Token sent to email.') {
        await track('User already exists', {});
        if (!requireVerify && typeof onVerify === 'function') {
          await onVerify({
            email,
          });
          return;
        }
        if (!email?.includes('@mindfulness.com')) {
          await track('User verification required', {});
        }
        openModal?.({
          name: modalName,
          props: {
            view: 'verify',
            email,
            redirect,
          },
        });
        verify();

        return email;
      }
      if (message === 'Already authenticated.') {
        if (redirect) {
          redirect({email});
        }
        alreadyAuthenticated();
        return;
      }
    }
  };

  return handleLoginFailure;
};

type Params = {
  requireVerify: Maybe<boolean>;
  onVerify?: (params: { email?: string; name?: string }) => void;
  redirect: Maybe<(params: { email?: string; name?: string }) => void>;
  email: string;
  modalName: string;
};
