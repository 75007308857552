import {useState, useEffect, useCallback} from 'react';
import {useWindowWidth} from './useWindowWidth';

export const useOnMobile = () => {
  const width = useWindowWidth();
  const [onMobile, setOnMobile] = useState<boolean>();

  const checkDevice = useCallback(() => {
    if (width && width <= 576) {
      setOnMobile(true);
    } else {
      setOnMobile(false);
    }
  }, [width]);

  // Called on first render
  useEffect(checkDevice, []);

  // Listens to window resize
  useEffect(() => {
    window.addEventListener('resize', checkDevice);
    return () => window.removeEventListener('resize', checkDevice);
  }, []);

  return onMobile;
};
