import {Theme} from '@emotion/react';
import {Card} from '../../layout/Card';
import {
  LeftContainer,
  RightContainer,
  SplitContainer,
} from './ContentSplit.styles';
import React from 'react';

export const ContentSplit: React.FC<Props> = ({
  Left,
  Right,
  UnderCard,
  OverCard,
  splitPoint,
}) => {
  return (
    <SplitContainer splitPoint={splitPoint}>
      <LeftContainer>
        <Left />
      </LeftContainer>
      <RightContainer splitPoint={splitPoint}>
        {OverCard && <OverCard />}
        <Card
          show={splitPoint || 'md'}
          height="auto"
          justify="space-between"
          align="start"
          spacing={16}
          textAlign="left"
        >
          <Right />
        </Card>
        {UnderCard && <UnderCard />}
      </RightContainer>
    </SplitContainer>
  );
};

type Props = {
  Left: React.FC;
  Right: React.FC;
  UnderCard?: React.FC;
  OverCard?: React.FC;
  splitPoint?: keyof Theme['breakpoints'];
};
