import styled from '@emotion/styled';
import {Column, Row} from '../../layout';

export const Wrapper = styled.section(
    ({theme}) => `
  overflow: hidden;
  position: relative;
  padding-top: ${theme.spacings[20]};
  padding-bottom: ${theme.spacings[20]};

  @media (min-width: ${theme.breakpoints.md.min}) {
    padding-top: ${theme.spacings[72]};
    padding-bottom: ${theme.spacings[72]};
  }
`,
);

export const EggColumn = styled(Column)`
  position: relative;
`;

export const EggRow = styled(Row)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
`;

export const Egg = styled.div(
    ({theme}) => `
  mask-image: url("/images/egg.svg"), none;
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  transform: translateX(80px) translateY(-20px);

  @media (min-width: ${theme.breakpoints.sm.min}) {
    transform: translateX(60px) translateY(-20px);
  }
  @media (min-width: ${theme.breakpoints.md.min}) {
    transform: translateX(40px) translateY(-40px);
  }

  > * {
    transform: scale(1.1);
  }
`,
);

// export const DeviceImage = styled(BasicImage)(
//     ({theme}) => `
//   transform: translateX(-40px) translateY(-20px) translateZ(0);

//   @media (min-width: ${theme.breakpoints.sm.min}) {
//     transform: translateX(0) translateY(-20px) translateZ(0);
//   }
//   @media (min-width: ${theme.breakpoints.md.min}) {
//     transform: translateX(0) translateY(0) translateZ(0);
//   }
// `,
// );

export const TextWrapper = styled.div`
  max-width: 527px;
`;
