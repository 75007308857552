import {FC} from 'react';
import {CenteredContent as CenteredContentProps} from '@mindfulness/cms';

import {Title1} from '../../typography';
import {Container, Row, Column, Section, Flex} from '../../layout';
import {BlockContent, SanityImage} from '../../global';
import {SectionIndex} from '../../../types/types';

export const CenteredContent: FC<SectionIndex<CenteredContentProps>> = ({
  index,
  title,
  paragraph,
  image,
  ...sectionPadding
}) => (
  <Section sectionPadding={sectionPadding}>
    <Container maxWidth="md">
      <Row justify="space-around" spacing={30}>
        <Flex justify="center" spacing={12}>
          <SanityImage width={240} height={184} image={image} />
        </Flex>
        <Column textAlign="center">
          <Title1 spacing={16} as={index === 0 ? 'h1' : 'h2'}>
            {title}
          </Title1>
          <BlockContent colour="grey7" background="white" content={paragraph} />
        </Column>
      </Row>
    </Container>
  </Section>
);
