import * as React from 'react';
import {ButtonAction, ButtonStyle, CtaButton} from '@mindfulness/cms';
import dynamic from 'next/dynamic';

import {assertString} from '../../../utils';
import {Flex, Stack} from '../../layout';
import {LoadingSpinner, OnClient} from '../../ui';
import {ResponsiveValue} from '../../../types/types';

import {Icon} from '../../global/Icon';
import {Lottie} from '../../global/Lottie';
import {useTrack} from '../../global/SegmentProvider';
import {useShare} from '../../../hooks/useShare';

import style from './Button.module.css';
import utilStyle from '../../../styles/utils.module.css';
import {ShareDropdown} from '../../ui/ShareDropdown';
import {ShareOption} from '../../ui/ShareDropdown/ShareOption';
import {StyledButton} from './StyledButton';

const CalendarLinks = dynamic(
    () => import('./CalendarLinks').then(({CalendarLinks}) => CalendarLinks),
    {
      loading: () => <ShareOption>Loading...</ShareOption>,
    },
);

/**
 * Generic button component, handles all button types and basic button tracking
 * @return {React.ReactElement}
 */
export const Button: React.FC<ButtonProps> = ({
  variant,
  action,
  children,
  as,
  onClick,
  id,
  size,
  shareUrl,
  type,
  calendarEvent,
  loading,
  fullLoader,
  ...props
}) => {
  const track = useTrack();

  const {
    open,
    handleCopy,
    handleShare,
    setOpen,
    linkToShare,
    copied,
    shared,
    shareRef,
    loading: loadingShare,
  } = useShare({
    shareUrl,
  });
  return (
    <div className={[
      utilStyle['position-relative'],
      utilStyle['inline-block'],
      ...(!!props.wide ? [utilStyle['full-width']] : [utilStyle['max-content']]),
    ].join(' ')}>
      {['calendar', 'share'].includes(assertString(action)) ? (
        <OnClient>
          <ShareDropdown open={open} ref={shareRef}>
            <Stack direction="vertical">
              {action === 'calendar' && calendarEvent ? (
                <CalendarLinks
                  start={calendarEvent.start}
                  duration={calendarEvent.duration}
                  description={calendarEvent.description}
                  title={calendarEvent.title}
                />
              ) : null}
              {action === 'share' ? loadingShare ? (<LoadingSpinner />) : (
                <>
                  <ShareOption
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`whatsapp://send?text=${linkToShare}`}
                  >
                    <Icon size={undefined} name="whatsapp">
                      Whatsapp
                    </Icon>
                  </ShareOption>
                  <ShareOption
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${linkToShare}`}
                  >
                    <Icon size={undefined} name="facebook">
                      Facebook
                    </Icon>
                  </ShareOption>
                  <ShareOption
                    target="_blank"
                    rel="noreferrer noopener"
                    href={`http://twitter.com/share?url=${linkToShare}`}
                  >
                    <Icon name="twitter" size={undefined}>
                      Twitter
                    </Icon>
                  </ShareOption>
                  <ShareOption as="button" type="button" onClick={handleCopy}>
                    <Icon size={undefined} name="copy">
                      Copy link
                    </Icon>
                  </ShareOption>
                </>
              ) : null}
            </Stack>
          </ShareDropdown>
        </OnClient>
      ) : null}
      <StyledButton
        id={id}
        as={as}
        size={size}
        buttonStyle={variant}
        onBlur={() => setTimeout(() => setOpen(false), 500)}
        onClick={(e) => {
          track('Button clicked', {
            button_id: id,
            button_text: children?.toString(),
            path: window.location.pathname,
          });
          if (action === 'calendar') {
            setOpen((prev) => {
              if (prev) {
                track('Calendar event cancelled', {});
              } else {
                track('Calendar event initiated', {});
              }
              return !prev;
            });
            return;
          }
          if (action === 'share') {
            handleShare();
            return;
          }
          if (onClick) {
            onClick(e);
          }
        }}
        type={type}
        loading={loading}
        {...props}
      >
        <Flex items="center">
          {fullLoader && loading ? (
            <Lottie path="/lottie/loader.json" width={40} height={40} />
          ) : (
            <>
              {action === 'calendar' ? (
                <Icon name="calendar" size={undefined}>
                  <span>{children}</span>
                </Icon>
              ) : action === 'share' ? (
                <>
                  <Icon name="share" size={undefined}>
                    {copied ?
                      'Copied to clipboard!' :
                      shared ?
                      'Thanks for sharing!' :
                      children}
                  </Icon>
                </>
              ) : variant === 'cta' ? (
                <>
                  {children}
                  <Icon name="arrow" preText size={16} />
                </>
              ) : (
                children
              )}
            </>
          )}
        </Flex>
        {fullLoader ? null : loading ? (
          <div className={[
            style.spinnerContainer,
            ...(loading ? [style.loading] : []),
          ].join(' ')}>
            <LoadingSpinner />
          </div>
        ) : null}
      </StyledButton>
    </div>
  );
};

/* eslint-disable camelcase */
export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & ButtonConfig;

export type ButtonConfig = {
  variant?: ButtonStyle | 'faded' | 'alt';
  action?: ButtonAction;
  calendarEvent?: CtaButton['calendarEvent'];
  wide?: ResponsiveValue<boolean>;
  as?: keyof JSX.IntrinsicElements;
  loading?: boolean;
  disabled?: boolean;
  id: string;
  size?: ResponsiveValue<'lg' | 'md' | 'sm' | 'xs'>;
  shareUrl?: string;
  fullLoader?: boolean;
}
/* eslint-enable camelcase */
