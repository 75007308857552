import React from 'react';
import {Maybe} from '@mindfulness/utils/maybe';
import {justOne} from '@mindfulness/utils/array';

import {useNavigation} from './useNavigation';
import {SingleDetailFragment, SingleVariantFragment} from '../types/types';

export const useVariant = ({single}: Props) => {
  const {update, router} = useNavigation();
  const [variant, setVariant] = React.useState<Maybe<SingleVariantFragment>>();

  React.useEffect(() => {
    if (variant?.id) {
      update({
        variant: variant.id,
      });
    }
  }, [variant?.id, update]);

  React.useEffect(() => {
    if (router.isReady) {
      const variantQuery = justOne(router.query.variant);
      const foundVariant = single.variants?.find(
          ({id}) => `${id}` === variantQuery,
      );

      setVariant(foundVariant ? foundVariant : single.variants?.[0]);
    }
  }, [single.id, single.variants, router.isReady]);

  return {
    variant,
    setVariant,
  };
};

type Props = {
  single: SingleDetailFragment;
};
