import * as React from 'react';
import {RichText} from '@mindfulness/cms';

import {ModalContext, PanelModal} from '../../global';
import {Box} from '../../layout';
import {AuthForm} from '../AuthForm';
import {useQueryParam} from '../../../hooks/useQueryParam';

const paragraph = [
  {
    _key: 'f0ed58af2846',
    _type: 'block',
    children: [
      {
        _key: '2ef972a353590',
        _type: 'span',
        marks: [],
        text: 'Create a mindfulness account and we’ll unlock this premium session in your account forever.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
] as RichText;

export const ClaimAccessModal: React.FC<{
  name?: string;
  unclosable?: boolean;
}> = ({name = 'action', unclosable}) => {
  const {activeProps} = React.useContext(ModalContext);
  const props = activeProps[name];
  const [email] = useQueryParam('email');
  const [publicId] = useQueryParam('publicId');
  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <PanelModal name={name} padding={64} unclosable={unclosable}>
      <Box maxWidth="452px">
        <AuthForm
          index={0}
          loading={loading}
          setLoading={setLoading}
          paragraph={email || publicId ? undefined : paragraph}
          title={
            email || publicId ?
              'Login to access this content.' :
              'Claim your free access'
          }
          {...props}
        />
      </Box>
    </PanelModal>
  );
};
