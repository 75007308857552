import {useRecoilState} from 'recoil';
import router from 'next/router';
import {historyState} from '../../state/atoms';
import {useEffect, useRef} from 'react';

export const RouteTracker: React.FC<React.PropsWithChildren> = () => {
  const [history, setHistory] = useRecoilState(historyState);
  const hasSet = useRef<boolean>(false);

  useEffect(() => {
    const handleSetHistory = (_url: string, {shallow}: {shallow: boolean}) => {
      // Prevent reload from clearing the history
      if (shallow || history === router.asPath) return;
      setHistory(router.asPath);
    };
    router.events.on('beforeHistoryChange', handleSetHistory);

    return () => {
      router.events.off('beforeHistoryChange', handleSetHistory);
    };
  }, [router, hasSet.current]);


  return null;
};
