import {jsonLdScriptProps} from 'react-schemaorg';
import {Article, Person} from 'schema-dts';
import {EnrichedArticles, Maybe} from '../types/types';

import {getOrganisation, mindfulnessOrganization} from './Organization';

export const articleSchema = ({
  title,
  excerpt,
  imageUrl,
  producer,
  slug,
}: EnrichedArticles & { imageUrl: Maybe<string> }) =>
  jsonLdScriptProps<Article>({
    '@context': 'https://schema.org',
    '@type': 'Article',
    'headline': title,
    'alternativeHeadline': excerpt,
    'image': imageUrl,
    'genre': 'Mindfulness',
    'url': slug?.current ? `https://mindfulness.com${slug.current}` : undefined,
    ...(producer?.teacher ?
      {
        author: {
          '@context': 'https://schema.org',
          '@type': 'Person',
          ...(producer.teacher.fullName ?
              {
                familyName: producer.teacher.fullName.split(' ')[1],
                givenName: producer.teacher.fullName.split(' ')[0],
              } :
              {}),
          'knowsAbout': producer.knowsAbout,
          'url': `https://mindfulness.com${producer.teacher.webSlug}`,
          ...(producer.teacher.cellImage?.url ?
              {
                image: producer.teacher.cellImage.url,
              } :
              {}),
          'affiliation': mindfulnessOrganization,
          'description': producer.description || producer.teacher.blurb,
          'sameAs': producer.sameAs,
          'honorificPrefix': producer.honorificPrefix,
          'honorificSuffix': producer.honorificSuffix,
          ...(producer?.worksFor ?
              {worksFor: getOrganisation(producer?.worksFor)} :
              {}),
        } as Person,
      } :
      {}),
    'publisher': mindfulnessOrganization,
  });
