import styled from '@emotion/styled';

export const Table = styled.table(
    ({theme}) => `
  width: 100%;
  tbody {
    > * {
      border-top: 0px solid ${theme.colors.grey4};
    }

    > * + * {
      border-top-width: 1px;
    }

    tr {
      td {
        width: 25%;
        text-align: center;
        padding: ${theme.spacings[20]} 0;
        &:first-of-type {
          font-weight: ${theme.fontWeights.bold};
          width: 50%;
          text-align: left;
        }
      }
    }
  }
`,
);
