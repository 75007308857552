import {Alignment} from '@mindfulness/cms';
import {Column, Flex, Row} from '../../layout';
import {Small, Text, H4, Title2, Title1} from '../../typography';
import {Icon, NewLink} from '../../global';
import {CollectionFeedFragment} from '../../../types/api';
import {mapDeeplink} from '../../../utils/mapDeeplink';
import {useMemo} from 'react';

export const CollectionTitle: React.FC<
  CollectionFeedFragment & {
    noLinks?: boolean;
    align?: Alignment;
    location?: 'collection';
  }
> = ({
  subtitle,
  title,
  description,
  noLinks,
  webSlug,
  align,
  location,
  moreButton,
}) => {
  const moreLink = useMemo(
      () => mapDeeplink(moreButton?.link),
      [moreButton?.link],
  );
  return (
    <>
      {title || subtitle || description ? (
        <Row
          justify={align}
          spacing={{
            xs: 15,
            md: 40,
          }}
        >
          <Column justify={align} textAlign={align} vertical>
            {subtitle ? (
              <Small colour="primary" fontWeight="bold" transform="uppercase">
                {subtitle}
              </Small>
            ) : null}
            {title ? (
              !noLinks && moreLink && location !== 'collection' ? (
                <NewLink href={moreLink}>
                  <Flex wide justify="space-between" className="nudge">
                    <Title2>{title}</Title2>

                    <Text
                      colour="primary"
                      fontFamily="heading"
                      fontWeight={{
                        xs: 'regular',
                        md: 'bold',
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      See All
                      <Icon name="arrow" size={{xs: 0, lg: 16}} />
                    </Text>
                  </Flex>
                </NewLink>
              ) : (
                <>
                  {location === 'collection' ? (
                    <Title1 spacing={description ? 16 : 0} as="h1">
                      {title}
                    </Title1>
                  ) : (
                    <Title2 spacing={description ? 16 : 0}>{title}</Title2>
                  )}
                </>
              )
            ) : null}
            {description && description !== '' ? (
              <H4 colour="grey7">{description}</H4>
            ) : null}
          </Column>
        </Row>
      ) : null}
    </>
  );
};
