import {Maybe} from '@mindfulness/utils/maybe';
import {LinkProps} from 'next/link';
import {NewLink} from '../components/global/Link';
import {NormalizedButton} from '../components/ui/NormalizedButton';

/**
 * Wraps a component in a link if the href is defined, otherwise returns the component
 * without a link
 * @param {React.ComponentType<P>} Component - Child component
 * @param {string} displayName - The name to apply to the returned component
 * @return {React.FC} - Wrapped component
 */
export function withLink<P extends WithLinkProps>(
    Component: React.ComponentType<P>,
    displayName?: string,
): React.FC<P> {
  const WithLink = (props: P) => {
    if (props.onClick && !props.href) {
      return (
        <NormalizedButton onClick={props.onClick}>
          <Component {...props} />
        </NormalizedButton>
      );
    }
    if (props.href) {
      if (props.href.startsWith('http')) {
        return (
          <a
            href={props.href}
            target="_blank"
            rel="noopener noreferrer"
            style={{display: 'block'}}
            onClick={props.onClick}
          >
            <Component {...props} />
          </a>
        );
      }
      return (
        <NewLink href={props.href} onClick={props.onClick}>
          <Component {...props} />
        </NewLink>
      );
    }

    return <Component {...props} />;
  };
  WithLink.displayName = displayName ?? 'WithLink';
  return WithLink;
}

export type WithLinkProps = Omit<LinkProps, 'href'> & {
  href: Maybe<string>;
  onClick?: () => void;
};
