import styled from '@emotion/styled';

export const ToastContainer = styled.div(
    ({theme}) => `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  pointer-events: none;
  padding: 116px ${theme.spacings[30]};

  > * {
    margin-top: 0;
    margin-bottom: 0;
    pointer-events: auto;
  }

  > * + * {
    margin-top: ${theme.spacings[8]};
  }
`,
);

export const ToastItem = styled.div(
    ({theme}) => `
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.medium};
  border-radius: ${theme.radius.sm};
  padding: ${theme.spacings[16]};
`,
);
