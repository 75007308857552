import * as React from 'react';
import {Banner as BannerProps} from '@mindfulness/cms';

import {BackgroundImage} from '../../global';
import {useOnMobile} from '../../../hooks';
import {H2} from '../../typography';
import {Container, Row, Column, Section} from '../../layout';
import {SectionIndex} from '../../../types/types';

export const Banner: React.FC<SectionIndex<BannerProps>> = ({
  title,
  image: responsiveImage,
  index,
}) => {
  const image = responsiveImage ?
    useOnMobile() ?
      responsiveImage?.mobileImage :
      responsiveImage?.desktopImage :
    undefined;
  return (
    <Section
      position="relative"
      padding={{
        xs: 30,
        md: 104,
      }}
      sectionPadding={{
        paddingBottom: 500,
      }}
    >
      {image ? <BackgroundImage image={image} /> : null}
      <Container maxWidth="prose">
        <Row>
          <Column color="white" textAlign="center">
            {title ? (
              <H2 as={index === 0 ? undefined : 'h2'}>{title}</H2>
            ) : null}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};
