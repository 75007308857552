export const shadows = {
  small: `0px 5px 20px rgba(0, 0, 0, 0.1);`,
  medium: `0px 10px 30px rgba(0, 0, 0, 0.15);`,
  big: `0px 5px 30px 0px #00000040`,
  quickLink: `0px 9px 36px 0px rgba(0, 0, 0, 0.10)`,
  glow: `0px 5px 30px rgba(61, 129, 241, 0.5);`,
  soft: `0px 0px 50px rgba(0, 0, 0, 0.09);`,
};


