import styled from '@emotion/styled';

export const HubLink = styled.a(
    ({theme}) => `
  display: block;
  position: relative;
  z-index: 0;
  border-radius: ${theme.radius.lg};
  overflow: hidden;
  color: ${theme.colors.white};
  &:hover {
    .image {
      transform: scale(1.1);
    }
  }
  .image {
    transition: transform 0.3s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

`,
);
