import * as React from 'react';
import {Button as Props} from '@mindfulness/cms';

import {SectionIndex} from '../../../types/types';
import {Column, Container, Row, Section} from '../../layout';
import {CallToActionButton} from '../../global';

export const Button: React.FC<SectionIndex<Props>> = ({
  index,
  cta,
  _type,
  background,
  paddingTop,
  paddingBottom,
}) =>
  cta?.text ? (
    <Section
      background={background}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container maxWidth="md">
        <Row>
          <Column justify="center">
            <CallToActionButton id={`${_type}-${index}`} {...cta}>
              {cta.text}
            </CallToActionButton>
          </Column>
        </Row>
      </Container>
    </Section>
  ) : null;
