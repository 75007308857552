import {Theme} from '@emotion/react';
import styled from '@emotion/styled';

export const Section = styled.section<{
  background: keyof Theme['colors'];
  position: 'center' | 'bottom';
}>(({theme, background, position}) => `
  position: relative;
  padding-top: ${theme.spacings[80]};
  ${position !== 'bottom' ? `
    padding-bottom: ${theme.spacings[80]};
  ` : ''}
  ${background ? `background: ${theme.colors[background]};` : ''}
  overflow: hidden;
`);

export const ImageWrapper = styled.div<{
  position: 'center' | 'bottom'
}>(({theme, position}) => `
  ${position === 'bottom' ? `margin-bottom: -${theme.spacings[120]}` : ''}
`);
