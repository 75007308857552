import * as React from 'react';
import {Figure} from '@mindfulness/cms';

import {
  Modal,
  BackgroundImage,
  Context,
  ModalContext,
  SmallModal,
} from '../../global';
import {AuthForm} from '../../ui';
import {useAuthView} from '../../../hooks/useAuthView';
import {useUntil} from '../../../hooks';
import {isOnClient} from '../../../utils/next';

export const ActionModal: React.FC = () => {
  const {activeProps} = React.useContext(ModalContext);
  const {data} = React.useContext(Context);
  const authProps = useAuthView();
  const defaultModalImage: Figure = React.useMemo(
      () => ({
        _type: 'figure',
        image: {
          _type: 'image',
          asset: {
            _type: 'reference',
            _ref: 'image-d83b3e8e73830eb39ff8a4fe95ab2dfa5ff58bf4-1440x1800-jpg',
          },
        },
      }),
      [],
  );

  const props = activeProps.action;
  const smallImage = useUntil([
    data?.action?.modal?.image,
    props?.action?.modal?.image,
  ]);
  const image = useUntil([
    data?.action?.modal?.image,
    props?.action?.modal?.image,
    defaultModalImage,
  ]);

  return data?.action?.modal?.smallModal ? (
    <SmallModal
      name="action"
      Top={smallImage ? <BackgroundImage image={smallImage} /> : undefined}
      Bottom={() => <AuthForm {...authProps} {...props} verifyLink={isOnClient() ? window.location.pathname : undefined} />}
      closeButton
    />
  ) : (
    <Modal
      name="action"
      hideClose={activeProps.hideClose ?? false}
      Left={() => <BackgroundImage image={image} />}
      Right={() => <AuthForm {...authProps} {...props} verifyLink={isOnClient() ? window.location.pathname : undefined} />}
    />
  );
};
