import {useContext, useMemo} from 'react';
import {Context} from '../components/global';

export const useStrategy = () => {
  const {session} = useContext(Context);
  const titleCaseStrat = useMemo(() => {
    if (session?.strategy) {
      const lowercase = session.strategy.toLowerCase();
      return `${lowercase.charAt(0).toUpperCase()}${lowercase.substring(1)}`;
    }
  }, [session?.strategy]);

  return titleCaseStrat;
};
