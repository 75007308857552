import {StyledTextArea} from './FreeText.styles';

export const FreeText: React.FC<{
  value: string;
  onChange: (value: string) => void;
}> = ({
  value,
  onChange,
}) => (
  <StyledTextArea value={value} onChange={(e) => onChange(e.target.value)} />
);
