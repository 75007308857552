import * as React from 'react';
import {take} from 'lodash/fp';
import {justOne} from '@mindfulness/utils/array';
import * as Sentry from '@sentry/nextjs';

import {ResponsiveValue} from '../../../types/types';
import {Container, Grid} from '../../layout';

import {MixedResults} from '../MixedResults';
import {assertString, groupBy} from '../../../utils';
import {CollectionTitle} from '../CollectionTitle';
import {useHydratedCollection} from '../../../hooks/useHydratedCollection';
import {CollectionFeedFragment, Maybe} from '../../../types/api';
import {filterItems} from '../../../utils/version';
import {ErrorFallback} from '../../global/ErrorFallback';
import {Section} from '../../layouts/Section';

export const CollectionGrid: React.FC<
  CollectionFeedFragment & {
    priority?: boolean;
    noLinks?: boolean;
    count?: number;
    index: number;
    countCols?: ResponsiveValue<number>;
    location?: 'collection';
    feedId: Maybe<string>
  }
> = ({
  priority,
  count,
  countCols,
  layout,
  location,
  index,
  feedId,
  ...staticCollection
}) => {
  const {loading, collection, ref, isIntersecting} = useHydratedCollection(staticCollection);
  const [type, bundleType] = React.useMemo(() => {
    const item = justOne(collection?.results?.items);
    const bundleType = item?.__typename === 'Bundle' ? item.bundleType : null;
    return [item?.__typename, bundleType];
  }, [collection?.results.items]);

  const items = React.useMemo(() => {
    if (!collection?.results.items) {
      return [];
    }
    const _items = filterItems(collection?.results.items);
    if (location === 'collection') {
      return _items;
    }
    if (type === 'Topic') {
      return take(4, _items);
    }
    if (count) {
      return take(count, _items);
    }
    if (type === 'QuickLink') {
      return groupBy(_items, 2);
    }
    return _items;
  }, [collection?.results.items, location, count]);

  // if (!layout) return null;
  if (
    bundleType?.includes('CHALLENGE') &&
    ['GRID_2', 'SHORT_GRID_2'].includes(assertString(layout))
  ) {
    return null;
  }

  const defaultEventProps = {
    collection_id: collection?.id,
    collection_title: collection?.title,
    feed_id: feedId,
  };
  return !isIntersecting && index > 3 ? null : (
    <Sentry.ErrorBoundary
      fallback={(props) => (
        <ErrorFallback
          {...props}
          index={index}
          sectionType={'CollectionGrid'}
        />
      )}
    >
      {collection ? (
        <Section
          ref={ref}
          key={collection.id}
          sectionPadding={{
            paddingTop: 40,
            paddingBottom: 40,
          }}
          padding={0}
        >
          <Container
            gutter={{
              xs: 16,
              md: 30,
            }}
          >
            <CollectionTitle {...collection} location={location} />

            <Grid
              countCols={
                    location === 'collection' ? undefined :
                type === 'Topic' ?
                  {
                    xs: 2,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                  } :
                  countCols
              }
              cols={
                ['Topic'].includes(assertString(type)) ?
                  {xs: 2, sm: 2, md: 3, lg: 4, xl: 4} :
                  ['SHORT_GRID_1', 'GRID_1'].includes(assertString(layout)) ?
                  {
                    xs: 1,
                    md: items.length === 1 ? 1 : 2,
                  } :
                  {
                    xs: 2,
                    md: 3,
                    lg: 5,
                  }
              }
              columnGap={{
                xs: 8,
                md: 16,
              }}
            >
              <MixedResults
                items={items}
                priority={priority}
                loading={loading}
                location="grid"
                layout={layout || undefined}
                type={type}
                defaultEventProps={defaultEventProps}
              />
            </Grid>

          </Container>
        </Section>
      ) : null}
    </Sentry.ErrorBoundary>
  );
};
