import * as React from 'react';
import {HeroBanner as HeroBannerProps} from '@mindfulness/cms';

import {ContentContainer, TextWrapper} from './HeroBanner.styles';

import {H1} from '../../typography';
import {Container} from '../../layout';
import {BackgroundImage, BlockContent} from '../../global';
import {useOnMobile} from '../../../hooks';
import {SectionIndex} from '../../../types/types';
import {AspectRatio} from '../../layouts/AspectRatio';
import {Section} from '../../layouts/Section';

export const HeroBanner: React.FC<SectionIndex<HeroBannerProps>> = ({
  alignment,
  title,
  paragraph,
  image: responsiveImage,
  paddingTop = 60,
  paddingBottom = 60,
  background,
  index,
}) => {
  const image = useOnMobile() ?
    responsiveImage?.mobileImage || responsiveImage?.desktopImage :
    responsiveImage?.desktopImage;
  return (
    <Section
      background={background}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container
        gutter={{
          xs: 16,
          md: 60,
        }}
      >
        <AspectRatio ratio="16:9" justify={alignment}>
          <ContentContainer align={alignment}>
            {image ? <BackgroundImage image={image} zIndex={0} /> : null}
            <TextWrapper align={alignment}>
              {title ? (
                <H1
                  as={index === 0 ? undefined : 'h2'}
                  spacing={paragraph ? 24 : 0}
                >
                  {title}
                </H1>
              ) : null}
              {paragraph ? (
                <BlockContent content={paragraph} background="grey9" />
              ) : null}
            </TextWrapper>
          </ContentContainer>
        </AspectRatio>
      </Container>
    </Section>
  );
};
