import * as React from 'react';

import {Box} from '../../layout';
import {Small, Text} from '../../typography';
import {Context} from '../../global';
import {useWhen} from '../../../hooks';
import {Avatar} from '../Avatar';

export const ProfileDetails: React.FC = () => {
  const {session} = React.useContext(Context);
  const firstName = useWhen(session?.firstName);
  return session?.email ? (
    <>
      <Box>
        <Avatar session={session} />
      </Box>
      <Box width="100%">
        {firstName ? <Text fontWeight="bold">{firstName}</Text> : null}
        <Small>{session.email}</Small>
      </Box>
    </>
  ) : null;
};
