import styled from '@emotion/styled';
import {Flex} from '../../layout';

export const SingleContainer = styled(Flex)<{
  disabled: boolean;
}>(
    ({theme, disabled}) => `
  ${disabled ? 'opacity: 0.7;' : ''}

  .cell-image {
    transition: transform 0.2s ease;
    will-change: transform;
    transform: scale(1);
  }

  .list-title {
    transition: color 0.2s ease;
    will-change: color;
  }

  &:hover {
    .cell-image {
      transform: scale(1.1);
    }
    .list-title {
      color: ${theme.colors.primary};
    }
  }
`,
);
