import * as React from 'react';
import {HeroImageSide as HeroImageSideProps} from '@mindfulness/cms';
import {H2, Small} from '../../typography';
import {
  BlockContent,
  CallToActionButton,
  ResponsiveImage,
} from '../../global';
import {Container, Row, Column, Section, Box} from '../../layout';
import {SectionIndex} from '../../../types/types';

export const HeroImageSide: React.FC<SectionIndex<HeroImageSideProps>> = ({
  subtitle,
  content,
  image,
  paddingTop = 60,
  paddingBottom = 60,
  _type,
  index,
}) => (
  <Section
    overflow="hidden"
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
  >
    <Container>
      <Row justify="space-evenly">
        <Column
          span={{
            xs: 24,
            lg: 13,
          }}
        >
          <ResponsiveImage image={image} layout="responsive" />
          <Box display={{xs: 'block', lg: 'none'}} paddingY={16}>
            {content?.cta?.text ? (
              <CallToActionButton
                wide={{
                  xs: true,
                  lg: false,
                }}
                id={`${_type}-${index}`}
                {...content?.cta}
              >
                {content?.cta?.text}
              </CallToActionButton>
            ) : null}
          </Box>
        </Column>
        <Column
          span={{
            xs: 24,
            lg: 8,
          }}
        >
          <Box>
            {subtitle ? (
              <Small colour="primary" fontWeight="bold" transform="uppercase">
                {subtitle}
              </Small>
            ) : null}
            {content?.title ? <H2 spacing={10}>{content?.title}</H2> : null}
            <BlockContent
              content={content?.paragraph}
              background="white"
              spacing={{
                xs: 40,
                lg: 50,
              }}
            />
          </Box>
          {content?.cta?.text ? (
            <CallToActionButton
              wide={{
                xs: true,
                lg: false,
              }}
              id={`${_type}-${index}`}
              {...content?.cta}
            >
              {content?.cta?.text}
            </CallToActionButton>
          ) : null}
        </Column>
      </Row>
    </Container>
  </Section>
);
