import {gql} from '@apollo/client';

export const REDEEM_ACCESS_PASS = gql`
  mutation redeemAccessPass($code: ID!) {
    redeemAccessPass(code: $code) {
      id
      duration
    }
  }
`;
