import styled from '@emotion/styled';

export const ArticleLink = styled.a(
    ({theme}) => `
  display: block;
  @media (max-width: ${theme.breakpoints.sm.max}) {
    border-bottom: 1px solid ${theme.colors.grey4};
    padding-top: ${theme.spacings[30]};
    padding-bottom: ${theme.spacings[30]};
  }
`,
);
