import {FC, useState} from 'react';
import Link from 'next/link';
import {
  Box,
  Card,
  Column,
  Container,
  Row,
  Section,
  Stack,
} from '../../layout';
import {H1, H4, Text} from '../../typography';
import {
  EnrichedPurchasePicker,
  Maybe,
  SectionIndex,
} from '../../../types/types';
import {BlockContent} from '../../global';
import {Purchases} from '../../ui/Purchases';
import {HydratedPurchase, usePurchases} from '../../../hooks/usePurchases';
import {useTrack} from '../../global/SegmentProvider';

export const PurchasePicker: FC<PurchasePickerProps> = ({
  title,
  description,
  alignment = 'center',
  index,
  products,
  background,
  coupon,
  _type,
}) => {
  const [selected, setSelected] = useState<HydratedPurchase>();
  return (
    <Section id={`${_type}-${index}`} background={background} padding={64}>
      <Container>
        <Row>
          <Column spacing={26} textAlign={alignment}>
            <H1 size="3xl" as={index === 0 ? 'h1' : 'h2'} spacing={34} prose>
              {title}
            </H1>
            <Text colour="grey8" prose>
              {description}
            </Text>
          </Column>
        </Row>
        <Row justify="center" items="center" gap={30}>
          {products?.map((product, idx) => (
            <Column
              span={{
                xs: 24,
                md: 8,
              }}
              key={product._key}
              spacing={30}
            >
              <ProductCard
                {...product}
                selected={selected}
                setSelected={setSelected}
                index={idx}
                coupon={coupon}
              />
            </Column>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export type PurchasePickerProps = SectionIndex<EnrichedPurchasePicker>;

const ProductCard: React.FC<
  SectionIndex<NonNullable<EnrichedPurchasePicker['products']>[number]> & {
    selected: Maybe<HydratedPurchase>;
    setSelected: React.Dispatch<React.SetStateAction<Maybe<HydratedPurchase>>>;
    coupon: Maybe<string>;
  }
> = ({
  title,
  description,
  purchases = [],
  selected,
  setSelected,
  coupon,
  index,
  learnMorePage,
}) => {
  const track = useTrack();
  const {loading: loadingPurchases, hydratedPurchases} = usePurchases(
      purchases,
      coupon,
  );
  return (
    <Card
      textAlign="left"
      height="auto"
      onClick={() => {
        setSelected(() => hydratedPurchases?.[0]);
      }}
    >
      <Stack direction="vertical" wide>
        <Box marginB={14}>
          <H4 size="xl" weight="bold">
            {title}
          </H4>
          {learnMorePage?.current ? (
            <Text
              colour="primary"
              fontWeight="bold"
              onClick={(e) => {
                e.stopPropagation();
                track('Button clicked', {
                  button_id: 'LearnMore',
                  button_text: 'Learn more',
                  path: window.location.pathname,
                  url: learnMorePage?.current,
                });
              }}
              as={(props) => <Link {...props} href={learnMorePage?.current} />}
            >
              Learn more
            </Text>
          ) : null}
        </Box>
        <BlockContent background="white" content={description} spacing={16} />
        <Box>
          <Purchases
            loadingPurchases={loadingPurchases}
            purchases={purchases}
            hydratedPurchases={hydratedPurchases}
            selected={selected}
            setSelected={setSelected}
            index={index}
            coupon={coupon}
          />
        </Box>
      </Stack>
    </Card>
  );
};
