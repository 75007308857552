import styled from '@emotion/styled';

export const ModalWrapper = styled.div<{
  open: boolean;
}>(
    ({open}) => `
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.45);
  transition: opacity ease 0.3s;
  ${
    open ?
      `
    opacity: 1;
    pointer-events: all;
  ` :
      `
    opacity: 0;
    pointer-events: none;
  `
}
`,
);

export const ModalInner = styled.div(
    ({theme}) => `
  background: ${theme.colors.white};
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  overflow-y: auto;
`,
);

export const ModalImage = styled.div(
    ({theme}) => `
  width: 100%;
  height: 25%;
  position: relative;
  z-index: 0;

  @media (min-width: ${theme.breakpoints.md.min}) {
    width: 50%;
    height: 100%;
    display: flex;
  }
`,
);

export const ModalContent = styled.div<{
  noGradient?: boolean;
}>(
    ({theme, noGradient}) => `
  width: 100%;
  min-height: 75%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${
    noGradient ?
      '' :
      `
    background-image: ${theme.gradients.accent};
    background-repeat: no-repeat;
    background-size: 100% 300px;
  `
}
  @media (min-width: ${theme.breakpoints.md.min}) {
    width: 50%;
    min-height: 100%;
  }


`,
);

export const ModalContentInner = styled.div(
    ({theme}) => `
  padding-top: ${theme.spacings[50]};
  padding-bottom: ${theme.spacings[50]};
  width: 100%;

  @media (min-width: ${theme.breakpoints.md.min}) {
    padding-top: 0;
    padding-bottom: 0;
    overflow-y: auto;
    max-height: 100dvh;
  }
`,
);

export const CloseButton = styled.button(
    ({theme}) => `
  position: absolute;
  top: ${theme.spacings[20]};
  right: ${theme.spacings[20]};

  @media (min-width: ${theme.breakpoints.md.min}) {
    top: ${theme.spacings[40]};
    right: ${theme.spacings[40]};
  }

`,
);
