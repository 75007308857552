import React, {useContext} from 'react';
import {PopupButton} from '@typeform/embed-react';
import {when} from '@mindfulness/utils/maybe';
import {Typeform as TypeformProps} from '@mindfulness/cms';

import {SectionIndex} from '../../../types/types';
import {convertToObject, unNull} from '../../../utils';
import {OnClient} from '../../ui';
import {Context} from '../../global/Provider';
import {useTrack} from '../../global/SegmentProvider';

export const Typeform: React.FC<Props> = (props) => (
  <OnClient>
    <ButtonWrapper {...props} />
  </OnClient>
);

const ButtonWrapper: React.FC<Props> = ({id, hidden, autoClose}) => {
  const initialCall = React.useRef(true);
  const track = useTrack();
  const {session} = useContext(Context);
  const ref = React.useRef<{
    open:() => void;
    close: () => void;
    toggle: () => void;
  }>();

  React.useEffect(() => {
    if (initialCall && ref.current) {
      initialCall.current = false;
      setTimeout(() => {
        ref.current?.open();
      }, 1000);
    }
  }, [ref.current, initialCall.current]);

  return id ? (
    <PopupButton
      className="hide"
      id={id}
      onSubmit={() => {
        track('Typeform submitted', {});
        if (autoClose) {
          setTimeout(() => {
            ref.current?.close();
          }, autoClose * 1000);
        }
      }}
      hidden={{
        ...when(unNull(session), (s) => ({
          email: s.email,
          user_id: s.userId,
        })),
        ...when(hidden, (h) =>
          convertToObject(
              h
                  .filter(({name, value}) => !!name && !!value)
                  .map(
                      ({name, value}) =>
                        ({name, value} as { name: string; value: string }),
                  ),
          ),
        ),
      }}
      ref={ref}
    >
      click to open
    </PopupButton>
  ) : null;
};

type Props = SectionIndex<TypeformProps>;
