import styled from '@emotion/styled';
import {switchEnum} from '@mindfulness/utils/logic';
import {ResponsiveValue} from '../../types/types';
import {buildResponsiveCss} from '../../utils/styling';
type ToolTipPosition = 'left' | 'right' | 'bottom-left';

const positionStyles = (pos: ToolTipPosition) =>
  switchEnum(pos, {
    'left': () => `
      right: 100%;
      transform: translateY(-50%) translateX(-15px);
      top: 50%;
    `,
    'bottom-left': () => `
      right: 0;
      top: 100%;
    `,
    'else': () => `
      top: 50%;
      left: 100%;
      transform: translateY(-50%) translateX(15px);
    `,
  });

const arrowStyles = (pos: ToolTipPosition) =>
  switchEnum(pos, {
    'left': () => `
      &:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        top: 50%;
        background: #fff;
        right: 0px;
        transform: rotate(45deg) translateX(50%) translateY(-50%);
        transform: translate(4px, -50%) rotate(45deg);
      }
    `,
    'bottom-left': () => `
    `,
    'else': () => `
    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0px;
      top: 50%;
      background: #fff;
      transform: translate(-4px, -50%) rotate(45deg);
    }
    `,
  });

export const Tooltip = styled.div<{
  show: boolean;
  position?: ResponsiveValue<ToolTipPosition>;
}>(({theme, show, position = 'right'}) => {
  return `
    ${buildResponsiveCss({theme, val: position, format: positionStyles})}
    border-radius: ${theme.radius.sm};
    box-shadow: ${theme.shadows.medium};
    padding: ${theme.spacings[12]} ${theme.spacings[20]};
    text-align: left;
    color: ${theme.colors.grey7};
    position: absolute;


    width: 197px;
    background: ${theme.colors.white};
    font-size: ${theme.fontSizes.sm};
    z-index: 2;
    ${show ? `` : 'display: none;'}

    ${buildResponsiveCss({theme, val: position, format: arrowStyles})}

`;
});
