import styled from '@emotion/styled';
import {Maybe} from '@mindfulness/utils/maybe';
import {stopGlobalPropForwarding} from '../../../utils';
import {
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export const StyledFooter = styled.footer(
    ({theme}) => `
  background: ${theme.colors.white};
  border-top: ${theme.colors.grey4} solid 1px;

  @media (min-width: ${theme.breakpoints.md.min}) {
    padding-top: ${theme.spacings[40]};
    padding-bottom: ${theme.spacings[40]};
  }
`,
);

export const FooterPolicy = styled.div(
    ({theme}) => `
  background: ${theme.colors.grey9};
  color: ${theme.colors.grey1};
  padding: ${theme.spacings[16]};
`,
);

export const VerticalDivider = styled.div(
    ({theme}) => `
  height: 1.5rem;
  border-right: 1px ${theme.colors.grey4} solid;
  display: none;
  @media (min-width: ${theme.breakpoints.md.min}) {
    display: block;
  }
`,
);

export const AccordionButton = styled(AccordionItemButton, {
  shouldForwardProp: stopGlobalPropForwarding,
})<{
  open: Maybe<boolean>;
  lastItem?: boolean;
}>(
    ({theme, open, lastItem}) => `
  font-size: ${theme.fontSizes.md};
  line-height: 1.5;
  padding-top: ${theme.spacings[24]};
  padding-bottom: ${theme.spacings[24]};
  padding-left: ${theme.spacings[16]};
  padding-right: ${theme.spacings[16]};
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  ${lastItem ? `` : `border-bottom: 1px ${theme.colors.grey4} solid;`}
  svg {
    transition: transform 0.3s ease;
    transform: ${open ? `rotate(180deg)` : `rotate(0deg)`};
  }
`,
);

export const AccordionPanel = styled(AccordionItemPanel)(
    ({theme}) => `
  padding-top: ${theme.spacings[24]};
  padding-left: ${theme.spacings[16]};
  padding-right: ${theme.spacings[16]};
`,
);

export const BlockLinks = styled.a`
  display: block;
`;
