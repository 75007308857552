import * as React from 'react';
import {
  DeviceSideLayout as DeviceSideLayoutProps,
  RichText,
} from '@mindfulness/cms';

import style from './DeviceSideLayout.module.css';
import {
  BlockContent,
  ResponsiveImage,
  CallToActionButton,
} from '../../global';
import {AppStoreLinksSmall} from '../../brand';
import {H1, Text} from '../../typography';
import {Container, Row, Column, Box, Stack} from '../../layout';
import {textColourMap} from '../../../utils/styling';
import {SectionIndex} from '../../../types/types';
import {Section} from '../../layouts/Section';

const defaultParagraph = [
  {
    _key: 'f0ed58af2846',
    _type: 'block',
    children: [
      {
        _key: '2ef972a353590',
        _type: 'span',
        marks: [],
        text: 'Every day you will receive a short guided practice by Melli or Cory followed by a meditation to help you deal with anxiety and overwhelm.',
      },
    ],
    markDefs: [],
    style: 'normal',
  },
] as RichText;

export const DeviceSideLayout: React.FC<
  SectionIndex<DeviceSideLayoutProps>
> = ({
  background,
  title,
  tagline,
  paragraph,
  cta,
  image,
  hideIcons,
  imagePosition,
  _type,
  index,
  paddingTop = 60,
  paddingBottom = 60,
}) => (
  <Section
    className={style.mdNoPadding}
    background={background}
    position="relative"
    overflow="hidden"
    sectionPadding={{
      paddingTop,
      paddingBottom: imagePosition === 'bottom' ? 0 : paddingBottom,
    }}
  >
    <Container>
      <Row justify="center" items="center" style={{height: '100%'}}>
        <Column
          span={{
            xs: 24,
            md: 12,
            lg: 9,
          }}
        >
          <Box color={textColourMap(background)} paddingY={50}>
            <Row>
              <Column span={24}>
                <Stack direction="vertical" space={16}>
                  {tagline ? (
                    <Text colour={background === 'grey9' ? 'white' : 'primary'}>
                      {tagline}
                    </Text>
                  ) : null}

                  <H1
                    as={index === 0 ? undefined : 'h2'}
                    size="2xl"
                    spacing={16}
                  >
                    {title || 'Download the App'}
                  </H1>
                  <BlockContent
                    content={paragraph || defaultParagraph}
                    background={background}
                    fontSize="md"
                    colour={background === 'grey9' ? 'white' : 'grey7'}
                  />
                  <CallToActionButton id={`${_type}-${index}`} {...cta}>
                    {cta?.text || 'Download Now'}
                  </CallToActionButton>
                </Stack>
              </Column>
            </Row>
            {!hideIcons ? (
              <Row topSpacing={20}>
                <AppStoreLinksSmall background={background} />
              </Row>
            ) : null}
          </Box>
        </Column>
        <Column
          span={{
            xs: 0,
            lg: 1,
          }}
        />
        <Column
          span={{
            xs: 24,
            md: 10,
            lg: 10,
          }}
        >
          <div className={[
            style.imageWrapper,
            ...(imagePosition === 'bottom' ? [style.bottom] : []),
          ].join(' ')} style={{height: '720px'}}>
            <ResponsiveImage
              image={image}
              background={background}
              priority={index < 2}
            />
          </div>
        </Column>
      </Row>
    </Container>
  </Section>
);
