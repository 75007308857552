import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import {Maybe} from '@mindfulness/utils/maybe';
import {Box} from '../../layout';
import {Title2, Small} from '../../typography';
import {withOnClient} from '../../../hoc/withOnClient';
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
const dayMap = ['days', 'hours', 'min', 'sec'];

export const Countdown: React.FC<Props> = withOnClient(({datetime}) => {
  const [countdownString, setCountdownString] = React.useState<string>();

  const countdown = React.useMemo(
      () => countdownString?.split(':'),
      [countdownString],
  );

  React.useEffect(() => {
    setTimeout(() => {
      const now = dayjs.utc().local();
      const then = dayjs.utc(datetime).local();
      const duration = dayjs.duration(then.diff(now));
      const text = duration.format('HH:mm:ss');
      // Prevent months from absorbing the days
      const days = duration.days() + duration.months() * 30;
      setCountdownString(`${days}:${text}`);
    }, 1000);
  }, [countdownString, datetime]);

  return (
    <>
      {countdown?.map((t, idx) => (
        <React.Fragment key={dayMap[idx]}>
          <Box>
            <Title2>{t}</Title2>
            <Small fontSize="2xs">{dayMap[idx]}</Small>
          </Box>
          {idx < countdown.length - 1 ? <Title2>:</Title2> : null}
        </React.Fragment>
      ))}
    </>
  );
});

type Props = {
  datetime: Maybe<string>;
};
