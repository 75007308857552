import {useCallback} from 'react';
import {ApolloError} from '@apollo/client';

import {login} from '../../../api';
import {useModal} from '../../../hooks';
import {isOnClient} from '../../../utils';
import {useAddCohort} from './useAddCohort';
import {AuthProps} from '.';
import {useTrack} from '../SegmentProvider';

export const useVerify = ({
  handleLogin,
  pageData,
  handleAction,
}: AuthProps) => {
  const {close} = useModal('action');
  const track = useTrack();
  const handleAddCohort = useAddCohort();

  const handleVerify = useCallback(
      async (
          {email, token}: FuncProps,
          redirect?: (params: { email?: string; name?: string }) => void,
      ) => {
        try {
          const {data} = await login({
            passwordless: {
              email,
              token,
            },
          });
          if (!data) {
            throw new Error('Couldn\'t login with Passwordless');
          }
          await track('Existing user logged in', {});
          // Must login first because the handle action often relies on the user being logged in
          await handleLogin(data.login);
          if (isOnClient()) {
            const params = new URLSearchParams(window.location.search);
            const urlCohort = params.get('cohort');
            const cohort = pageData?.action?.cohort || urlCohort;
            if (cohort) {
              // They may be logging in via a challenge so we add any relevant cohorts once logged in
              await handleAddCohort({cohort: cohort});
            }
          }

          if (typeof redirect === 'function') {
            redirect({email});
            return;
          }

          await handleAction(data?.login?.user?.email || undefined);
          close();
          const urlParams = new URLSearchParams(window.location.search);
          const onto = urlParams.get('onto');
          if (onto) {
            window.location.href = onto;
            return;
          }
        } catch (e) {
          if (e instanceof ApolloError) {
            if (e.message === 'Already authenticated.') {
              if (typeof redirect === 'function') {
                redirect({email});
                return;
              }
              await handleAction(email || undefined);
            }
          }
          console.error(e);
          throw e;
        }
      },
      [handleAction, pageData, track],
  );
  return handleVerify;
};

type FuncProps = {
  email: string;
  token: string;
};
