import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemState,
} from 'react-accessible-accordion';
import {useRecoilValue} from 'recoil';

import {
  SectionIndex,
  Maybe,
  ReferencedCheckoutFAQ,
} from '../../../types/types';
import {H1, H4, Title3} from '../../typography';
import {Container, Row, Column, Section} from '../../layout';
import {BlockContent, Icon} from '../../global';

import {
  AccordionButton,
  AccordionPanel,
  FAQFooter,
  HelpCenterLink,
} from './CheckoutFAQ.styles';
import {orderItemsState} from '../../../state/atoms';

export const CheckoutFAQ: React.FC<SectionIndex<ReferencedCheckoutFAQ>> = ({
  background,
  title,
  moreLink,
  paragraph,
  paddingTop,
  paddingBottom,
  index,
  position,
  faqs,
}) => {
  const sectionRef = React.useRef<HTMLDivElement>(null);
  const purchasables = useRecoilValue(orderItemsState);

  const checkoutFaqs = React.useMemo(() => {
    return faqs.filter(({purchases}) => {
      const ids = purchases?.map(({id}) => id);
      return ids?.some((id) => purchasables?.includes(id ?? ''));
    });
  }, [purchasables]);

  return checkoutFaqs?.length > 0 ? (
    <Section
      background={background}
      ref={sectionRef}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container maxWidth="md">
        {title ? (
          <Row spacing={50}>
            <Column
              span={24}
              textAlign={{
                xs: 'start',
                md: 'center',
              }}
            >
              <Title title={title} index={index} position={position} />
              {paragraph ? (
                <H4
                  size="md"
                  spacing={{
                    xs: 16,
                    md: 30,
                  }}
                >
                  {paragraph}
                </H4>
              ) : null}
            </Column>
          </Row>
        ) : null}
        <Row>
          <Column span={24}>
            <Accordion
              allowZeroExpanded={true}
              preExpanded={[checkoutFaqs?.[0]?._id]}
            >
              {checkoutFaqs?.map((faq) => {
                if (!faq) {
                  return null;
                }

                return (
                  <AccordionItem key={faq._id} uuid={faq._id}>
                    <AccordionItemState>
                      {({expanded}) => (
                        <>
                          <AccordionItemHeading>
                            <AccordionButton>
                              <span>{faq.title}</span>
                              {expanded ? (
                                <Icon name="minus" size={30} />
                              ) : (
                                <Icon name="plus" size={30} />
                              )}
                            </AccordionButton>
                          </AccordionItemHeading>
                          <AccordionPanel>
                            <BlockContent
                              content={faq.answer}
                              background="white"
                              colour="grey7"
                            />
                          </AccordionPanel>
                        </>
                      )}
                    </AccordionItemState>
                  </AccordionItem>
                );
              })}
            </Accordion>
          </Column>
          {moreLink ? (
            <Column span={24}>
              <FAQFooter>
                <span>Find more answers</span>
                <HelpCenterLink href={moreLink} target="_blank">
                  <span>Help center</span>
                  <Icon name="external" size={12} />
                </HelpCenterLink>
              </FAQFooter>
            </Column>
          ) : null}
        </Row>
      </Container>
    </Section>
  ) : null;
};

const Title: React.FC<{
  title: Maybe<string>;
  index: number;
  position: Maybe<'checkout'>;
}> = ({title, index, position}) =>
  position === 'checkout' ? (
    <Title3 as={index === 0 ? 'h1' : 'h2'} weight="bold">
      {title}
    </Title3>
  ) : (
    <H1 size="2xl" as={index === 0 ? undefined : 'h2'}>
      {title}
    </H1>
  );
