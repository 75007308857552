import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {CSSProperties} from 'react';

export const Cover = styled.div<Props>(
    ({theme, justify = 'center', spacing = 40, gap = 0}) => `
display: flex;
flex-direction: column;
min-height: 100svh;
min-height: -webkit-fill-available;
justify-content: ${justify};
padding-top: ${theme.spacings[spacing]};
padding-bottom: ${theme.spacings[spacing]};
${
  gap ?
    `
> * {
  margin-top: ${theme.spacings[gap]};
  margin-bottom: ${theme.spacings[gap]};
}
` :
    ''
}

`,
);

type Props = {
  justify?: CSSProperties['justifyContent'];
  gap?: keyof Theme['spacings'];
  spacing?: keyof Theme['spacings'];
};
