import {useContext, useState} from 'react';
import FadeIn from 'react-fade-in';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {Box} from '../../layout';
import {Text} from '../../typography';
import {Button} from '../../forms';
import {redeemShare} from '../../../api/functions/redeemShare';
import {useModal} from '../../../hooks/useModal';
import {Context} from '../../global/Provider';
import {useTrack} from '../../global/SegmentProvider';


export const ShareCard: React.FC<Props> = ({
  onRedeem,
}) => {
  const {open, close} = useModal('login');
  const {session} = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [linkId, setLinkId] = useQueryParam('link_id');
  const [sharerName] = useQueryParam('sharer_name');
  const track = useTrack();
  const handleRedeem = async (code: string) => {
    setLoading(true);
    const res = await redeemShare({code}, track);
    if (res?.id) {
      setLinkId(undefined);
      await onRedeem?.();
    }
    setLoading(false);
  };
  const redeem = async () => {
    if (!linkId) {
      return;
    }
    if (!session) {
      open({
        props: {
          title: 'Create a free account',
          subtitle: 'Create a mindfulness account and we’ll unlock this premium session in your account forever.',
          redirect: async () => {
            await handleRedeem(linkId);
            close();
          },
          buttonText: 'Play for Free',
        },
      });
      return;
    }
    await handleRedeem(linkId);
  };
  return (
    <FadeIn>
      {(!!linkId) && (
        <Box background="primary" padding={30} radius={{
          xs: 'none',
          md: 'lg',
        }} spacing={{
          xs: 0,
          md: 14,
        }}>
          <Text colour="white" fontWeight="bold" fontSize="md">
            {`${sharerName ? sharerName : 'Someone'} shared this with you, so you can play it for free.`}
          </Text>
          <Text colour="white" spacing={26}>
            {`Create a free mindfulness.com account and we'll unlock this premium session in your account forever.`}
          </Text>
          <Button loading={loading} variant="secondary" id="RedeemContentShare" size="sm" onClick={redeem}>Keep It Forever</Button>
        </Box>
      )}
    </FadeIn>
  );
};

type Props = {
  onRedeem?: () => Promise<void>;
};
