import styled from '@emotion/styled';

export const TextBanner = styled.div(
    ({theme}) => `
  font-size: 82px;
  line-height: 70px;
  margin-bottom: -11px;
  width: 100%;
  color: #454557;
  font-weight: 700;
  letter-spacing: -0.1em;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  overflow: hidden;

  @media (min-width: ${theme.breakpoints.md.min}) {
    font-size: 156.796px;
    line-height: 132px;
    margin-bottom: -19px;
  }

  span {
    white-space: nowrap;
  }
`,
);
