import {useCallback} from 'react';
import {captureException} from '@sentry/nextjs';

import {updatePreferences} from '../../../api';
import {useUserPreferences} from './useUserPreferences';
import {useTrack} from '../SegmentProvider';

export const useUpdatePreferences = () => {
  const track = useTrack();
  const changes = useUserPreferences();
  const handleUpdatePreferences = useCallback(async () => {
    try {
      const data = await updatePreferences({changes});
      track('Updated preferences', {
        ...changes,
        followingTags: changes?.followingTags?.map(({slug}) => slug),
      });
      return data;
    } catch (e) {
      captureException(e);
    }
  }, [changes, track]);
  return handleUpdatePreferences;
};
