import styled from '@emotion/styled';

export const StyledTextarea = styled.textarea<{ error: boolean }>(
    ({theme, readOnly}) => `
  width: 100%;
  ${readOnly ? `color: ${theme.colors.grey7};` : ''}
  background: transparent;
  outline: none;

  &:focus {
    outline: none;
  }
`,
);
