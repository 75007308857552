import {PropsWithChildren} from 'react';
import {Theme} from '../../../types/emotion';
import styles from './VerticalDivider.module.css';

export const VerticalDivider: React.FC<PropsWithChildren<Props>> = ({children, background = 'white', height = '1.5rem'}) => {
  return <div
    style={{
      height,
    }}
    className={[
      styles.verticalDivider,
      ...(background === 'grey9' ? [styles.borderWhite] : []),
    ].join('')}
  >{children}</div>;
};


type Props = {
  background?: keyof Theme['colors'];
  height?: React.CSSProperties['height'];
};
