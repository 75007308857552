import * as React from 'react';
import {useSetRecoilState} from 'recoil';
import styled from '@emotion/styled';

import {BackgroundAPIImage, Context, Icon, OverlayModal} from '../global';
import {Row, Column, Stack, Box} from '../layout';
import {Text, UnorderedList, UnorderedListItem} from '../typography';
import {Button} from '../forms';
import {redirectState} from '../../state/atoms';
import {useQueryParam} from '../../hooks/useQueryParam';
import {useModal} from '../../hooks/useModal';
import {useNewNavigation} from '../../hooks/useNewNavigation';
import {CancelButton} from '../forms/Button/CancelButton';

export const PlusUpgradeModal: React.FC = () => {
  const {session} = React.useContext(Context);
  const setRedirect = useSetRecoilState(redirectState);
  const {goTo, pathname, asPath} = useNewNavigation();
  const {close, open} = useModal('plusUpgrade', () => {

  });
  const [pu, setPu] = useQueryParam('pu');
  const items = React.useMemo(() => {
    return [
      '2000+ Guided Meditations',
      'Courses from world-class teachers',
      'Resources for Stress + Anxiety',
      'Breathing exercises, gratitude practices, relaxation techniques',
      'Sleep meditations, playlists, stories',
      'Mindful talks, podcasts, music, nature sounds',
    ];
  }, []);

  React.useEffect(() => {
    if (pu && pathname === '/') {
      if (session && !session.user?.hasActiveSubscription) {
        open();
      }
      setPu(undefined);
    }
  }, [session, pu]);

  return (
    <OverlayModal name="plusUpgrade" padding={0} closeButton>
      <Box maxWidth="620px" style={{maxHeight: '90dvh', display: 'flex', flexDirection: 'column'}}>
        <Box height="237px" position="relative">
          <BackgroundAPIImage src="/images/plus-upgrade.png" doNotResize />
        </Box>
        <Box padding={40} style={{
          overflowY: 'auto',
        }}>
          <Row>
            <Column>
              <Title>
                {'Mindfulness '}
                <Icon name="plus-gold" width={86} height={30} spacing={5} />
              </Title>
              <Text spacing={16}>
                One membership to gain access to a world of premium mindfulness
                content created to help you live happier and stress less.
              </Text>
            </Column>
            <Column spacing={40}>
              <UnorderedList>
                {items.map((i) => (
                  <UnorderedListItem tick key={i}>
                    {i}
                  </UnorderedListItem>
                ))}
              </UnorderedList>
            </Column>
            <Stack direction="vertical" items="center" wide>
              <Button
                id="plusUpgradeButton"
                type="button"
                onClick={() => {
                  setRedirect(asPath);
                  goTo({pathname: '/checkout', query: {
                    products: '34754bbc-b0c5-4e76-b254-94d9abce8d9f',
                  }});
                }}
              >
                Start Free 7-Day Trial
              </Button>

              <CancelButton
                type="button"
                onClick={() => {
                  close();
                }}
              >
                Not now
              </CancelButton>
            </Stack>
          </Row>
        </Box>
      </Box>
    </OverlayModal>
  );
};

export const Title = styled.p(
    ({theme}) => `
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 32px;
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 5px;
`,
);
