import {getTopics} from '../../../api';
import {
  OnboardingConfig,
  OnboardingPersonalization,
  // OnboardingPersonalizedPlan,
  OnboardingPlan,
  OnboardingQuestion,
  OnboardingSignup,
  OnboardingStats,
  Steps,
  Maybe,
} from '../../../types/types';
import {omitEmpty} from '@mindfulness/utils/array';
import {switchEnum} from '@mindfulness/utils/logic';
import {when} from '@mindfulness/utils/maybe';

const contentOptions = [
  {
    label: 'Guided Meditations',
    value: 'GUIDED',
  },
  {
    label: 'Mindfulness Challenges',
    value: 'CHALLENGE',
  },
  {
    label: 'Helpful Tips',
    value: 'MOMENT',
  },
  {
    label: 'Calming Music',
    value: 'MUSIC',
  },
  {
    label: 'Breathing Exercises',
    value: 'BREATHWORK',
  },
  {
    label: 'Mindfulness Courses',
    value: 'COURSE',
  },
  {
    label: 'Talks & Podcasts',
    value: 'TALK',
  },
];

const extendExtraInfo = (extend: {
  id: Steps;
  title: string;
  description?: string;
  theme?: 'dark';
}): OnboardingQuestion => {
  const frequencyOptions = [
    {label: 'Never', value: 'never'},
    {label: 'Sometimes', value: 'sometimes'},
    {label: 'Often', value: 'often'},
    {label: 'Always', value: 'always'},
  ];

  return {
    description: 'Pick one answer',
    type: 'question',
    theme: 'light',
    minRequired: 1,
    maxAllowed: 1,
    options: frequencyOptions,
    ...extend,
  };
};

const extendStats = (
    extend: Omit<OnboardingStats, 'type' | 'description'>,
): OnboardingStats => {
  return {
    type: 'stats',
    description:
      'Our Mindfulness+ customers have seen significant improvements in their everyday life when doing mindfulness at least once a week.',
    ...extend,
  };
};
const topicSlugs = [
  'beginner',
  'sleep',
  'stress',
  'anxiety',
  'focus',
  'resilience',
  'self-compassion',
  'pain',
  'family',
  'performance',
] as const;

const topics: OnboardingQuestion = {
  id: 'topics',
  type: 'question',
  title: 'Hello, what brings you to Mindfulness.com',
  description: 'Pick 3 or more topics that interest you.',
  theme: 'light',
  minRequired: 1,
  options: async () => {
    const topics = await getTopics({
      slugs: topicSlugs as unknown as Array<string>,
    });
    return (topics || []).map(({slug, title, subtitle}) => ({
      value: slug,
      label: omitEmpty([subtitle, title]).join(' '),
    }));
  },
};

/* Anxiety */
const anxietyFrequency: OnboardingQuestion = extendExtraInfo({
  id: 'anxiety-frequency',
  title: 'How often do you feel anxious or worried?',
  description: 'Pick one answer',
});

const anxietyImpact: OnboardingQuestion = extendExtraInfo({
  id: 'anxiety-impact',
  title: 'Does your anxiety have an impact on your daily life?',
  description: 'Pick one answer',
});

const anxietyStats: OnboardingStats = extendStats({
  id: 'anxiety-stats',
  title:
    '[84%] of people similar to you, [feel less anxious] after trying mindfulness for 30 days.',
});

const anxietyImprove: OnboardingQuestion = {
  id: 'anxiety-improve',
  type: 'question',
  title: 'What would you like to try first, to improve your anxiety?',
  description: 'Pick up to three.',
  theme: 'light',
  minRequired: 1,
  maxAllowed: 3,
  options: contentOptions,
};

//  Sleep
const sleepFrequency: OnboardingQuestion = extendExtraInfo({
  id: 'sleep-frequency',
  title: 'Do you struggle with falling asleep or staying asleep?',
  theme: 'dark',
});

const sleepImpact: OnboardingQuestion = extendExtraInfo({
  id: 'sleep-impact',
  title: 'Is your sleeping pattern having an impact on your daily life?',
  theme: 'dark',
});

const sleepStats: OnboardingStats = extendStats({
  id: 'sleep-stats',
  title:
    '[75%] of people similar to you, [get better sleep] after trying mindfulness for 30 days.',
});

const sleepImprove: OnboardingQuestion = {
  id: 'sleep-improve',
  type: 'question',
  title: 'What would you like to try first, to get better sleep?',
  description: 'Pick up to three.',
  theme: 'dark',
  minRequired: 1,
  maxAllowed: 3,
  options: [
    {
      label: 'Sleep Meditations',
      value: 'GUIDED',
    },
    {
      label: 'Sleep Challenge',
      value: 'CHALLENGE',
    },
    {
      label: 'Sleep Music',
      value: 'MUSIC',
    },
    {
      label: 'Bedtime Breathing',
      value: 'BREATHWORK',
    },
    {
      label: 'Sleep Programs',
      value: 'COURSE',
    },
  ],
};

// Stress
const stressFrequency: OnboardingQuestion = extendExtraInfo({
  id: 'stress-frequency',
  title: 'Is stress having an impact on your daily life?',
});

const stressImpact: OnboardingQuestion = {
  id: 'stress-impact',
  title: 'What are your main sources of stress?',
  description: 'Pick up to three',
  type: 'question',
  theme: 'light',
  minRequired: 1,
  maxAllowed: 3,
  options: [
    {label: 'Work', value: 'Work'},
    {label: 'Family', value: 'Family'},
    {label: 'Relationships', value: 'Relationships'},
    {label: 'Money', value: 'Money'},
    {label: 'Pain & Illness', value: 'Pain & Illness'},
    {label: 'Loss or Grief', value: 'Loss or Grief'},
    {label: 'Work or Study', value: 'Work or Study'},
    {label: 'Trauma', value: 'Trauma'},
  ],
};

const stressStats: OnboardingStats = extendStats({
  id: 'stress-stats',
  title:
    '[91%] of people similar to you, [feel less stressed] after trying mindfulness for 30 days.',
});

const stressImprove: OnboardingQuestion = {
  id: 'stress-improve',
  type: 'question',
  theme: 'light',
  title: 'What would you like to try first, to feel more calm?',
  description: 'Pick up to three.',
  minRequired: 1,
  maxAllowed: 3,
  options: contentOptions,
};

const improve: OnboardingQuestion = {
  id: 'improve',
  type: 'question',
  theme: 'light',
  title: 'What would you like to try first?',
  description: 'Pick as many as you like',
  minRequired: 1,
  options: contentOptions,
};

const content: OnboardingStats = {
  id: 'content',
  type: 'stats',
  title: 'Great we\'ve got [2,340] pieces of premium content for you',
  description:
    'We collaborate with the world’s most trusted mindfulness teachers to produce premium content for you, the community.',
  image: '/images/onboarding-content2.png',
};

const experience: OnboardingQuestion = {
  id: 'experience',
  type: 'question',
  title: 'Have you ever meditated before?',
  description: 'Pick one answer.',
  minRequired: 1,
  maxAllowed: 1,
  theme: 'light',
  options: [
    {label: 'Never', value: 'NEVER'},
    {label: 'I\'ve tried it', value: 'TRIED'},
    {label: 'Sometimes', value: 'SOMETIMES'},
    {label: 'Regularly', value: 'REGULARLY'},
  ],
};

const goal: OnboardingQuestion = {
  id: 'goal',
  type: 'question',
  title: 'Let’s set a goal!',
  description:
    'How many times per week would you like to commit to practicing mindfulness?',
  minRequired: 1,
  maxAllowed: 1,
  theme: 'light',
  options: [
    {label: 'Once per week', value: '1'},
    {label: '2 or 3 times per week', value: '3'},
    {label: '5 or more times per week', value: '5'},
  ],
};

const personalization: OnboardingPersonalization = {
  id: 'personalization',
  type: 'personalization',
  title: 'Real changes seen by our community',
  buttonText: 'We\'re personalizing your experience',
  buttonTime: 8,
};

const signup: OnboardingSignup = {
  id: 'signup',
  type: 'signup',
  title: 'Get your personalized mindfulness plan',
  description: 'Enter your email to access your plan.',
  buttonText: 'Signup with email',
};

// const personalizedPlan: OnboardingPersonalizedPlan = {
//   id: 'personalized-plan',
//   type: 'personalized-plan',
// };

const plan: OnboardingPlan = {
  id: 'plan',
  type: 'plan',
};

export const allOnboardingSteps = {
  'topics': topics,
  'anxiety-frequency': anxietyFrequency,
  'anxiety-impact': anxietyImpact,
  'anxiety-stats': anxietyStats,
  'anxiety-improve': anxietyImprove,
  'sleep-frequency': sleepFrequency,
  'sleep-impact': sleepImpact,
  'sleep-stats': sleepStats,
  'sleep-improve': sleepImprove,
  'stress-frequency': stressFrequency,
  'stress-impact': stressImpact,
  'stress-stats': stressStats,
  'stress-improve': stressImprove,
  'improve': improve,
  'content': content,
  'experience': experience,
  'goal': goal,
  'personalization': personalization,
  'signup': signup,
  // 'personalized-plan': personalizedPlan,
  'plan': plan,
} as const;

export const onboardingSteps = Object.keys(allOnboardingSteps) as Array<Steps>;

export const defaultOnboardingSteps: Array<Steps> = [
  'topics',
  'improve',
  'content',
  'experience',
  'goal',
  'personalization',
  'signup',
  // 'personalized-plan',
  // 'plan',
];

export const getStepChangeSteps = (id: Steps) =>
  switchEnum(id, {
    topics: () => (values: Array<string>) => {
      const branches = ['anxiety', 'sleep', 'stress'];

      const val = values.find((v) => branches.includes(v));
      return when(val, (v) =>
        switchEnum<(typeof branches)[number], ChangeSteps>(v, {
          anxiety: {
            add: [
              'anxiety-frequency',
              'anxiety-impact',
              'anxiety-stats',
              'anxiety-improve',
            ],
            remove: ['improve'],
          },
          sleep: {
            add: [
              'sleep-frequency',
              'sleep-impact',
              'sleep-stats',
              'sleep-improve',
            ],
            remove: ['improve'],
          },
          stress: {
            add: [
              'stress-frequency',
              'stress-impact',
              'stress-stats',
              'stress-improve',
            ],
            remove: ['improve'],
          },
          else: {
            add: [],
            remove: [],
          },
        }),
      );
    },
    else: undefined,
  });

const resolveQuestionOptions = async ({options}: OnboardingQuestion) => {
  if (typeof options === 'function') {
    const resolvedOptions = await options();
    return resolvedOptions;
  }
  return options;
};

export const getStaticProps = async (
    id: Steps,
): Promise<Maybe<OnboardingConfig>> => {
  const data = switchEnum<Steps, Maybe<OnboardingConfig>>(id, {
    ...allOnboardingSteps,
    else: undefined,
  });

  if (data?.type === 'question') {
    const options = await resolveQuestionOptions(data);
    return {
      ...data,
      options,
    };
  }
  // if (data?.type === 'personalized-plan') {
  //   const topics =
  //     (await getTopics({
  //       slugs: topicSlugs as unknown as Array<string>,
  //     })) || undefined;
  //   return {
  //     ...data,
  //     topics,
  //   };
  // }

  return data;
};

export const getStaticPaths = () => {
  const paths = Object.keys(allOnboardingSteps).map((id) => ({
    params: {
      id,
    },
  }));
  return paths;
};

type ChangeSteps = {
  add: Array<Steps>;
  remove: Array<Steps>;
};

export const testimonials = [
  {
    _createdAt: '2021-10-15T02:53:54Z',
    _id: '1b50e3b3-83e1-4d64-bd59-3c9d01b83378',
    _rev: 'uKsIgnbY5DoFWsQn4yfSd2',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:23:06Z',
    image: {
      _type: 'figure',
      image: {
        _type: 'image',
        asset: {
          _ref: 'image-9b36ccb6f6a6a078132bcd1372edd9d98280dbc8-66x66-png',
          _type: 'reference',
        },
      },
    },
    rating: 5,
    review:
      'Great meditations that have a calming effect. Melli\'s voice is soothing.',
    user: 'Deborah UK',
  },
  {
    _createdAt: '2021-10-14T05:20:52Z',
    _id: '338085cd-74e0-42cb-8f19-38555b654c2d',
    _rev: 'uKsIgnbY5DoFWsQn4yfSd2',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:23:06Z',
    image: {
      _type: 'figure',
      image: {
        _type: 'image',
        asset: {
          _ref: 'image-87e23487a253993b07c9f9f5e561bfd6c71ad821-65x65-png',
          _type: 'reference',
        },
      },
    },
    rating: 5,
    review:
      'Melli has a beautiful way of guiding and reminding us of finding the stillness within.',
    user: 'Adrienne James',
  },
  {
    _createdAt: '2021-10-15T02:53:31Z',
    _id: '3bd19d23-01a0-4c43-9603-411f9614f10e',
    _rev: 'uKsIgnbY5DoFWsQn4yfSd2',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:23:06Z',
    image: {
      _type: 'figure',
      image: {
        _type: 'image',
        asset: {
          _ref: 'image-df140bb2c4404d4da4b6dcf7fc9261b2e89e3b7e-66x66-png',
          _type: 'reference',
        },
      },
    },
    rating: 5,
    review: 'Amazing, accessible, and warming experience. Thank you Melli.',
    user: 'Leah Jolliot',
  },
  {
    _createdAt: '2021-10-14T05:18:15Z',
    _id: '7c1decd6-3897-4fb8-8601-8d4967da4d3d',
    _rev: 'uKsIgnbY5DoFWsQn4yfSd2',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:23:06Z',
    image: {
      _type: 'figure',
      image: {
        _type: 'image',
        asset: {
          _ref: 'image-4773f99d7503a690368f9c72dd135e41ccf2ebd4-65x65-png',
          _type: 'reference',
        },
      },
    },
    rating: 5,
    review: 'Amazing, accessible, and warming experience. Thank you Melli.',
    user: 'Leah Jolliot',
  },
  {
    _createdAt: '2021-10-14T05:20:27Z',
    _id: '9d9b27c6-d747-4b8c-aa21-9b270c5604a8',
    _rev: 'uKsIgnbY5DoFWsQn4yfSd2',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:23:06Z',
    image: {
      _type: 'figure',
      image: {
        _type: 'image',
        asset: {
          _ref: 'image-51fe313bf83fa230343b8c573fc0d9c189715644-65x65-png',
          _type: 'reference',
        },
      },
    },
    rating: 5,
    review:
      'Great meditations that have a calming effect. Melli\'s voice is soothing.',
    user: 'Deborah UK',
  },
  {
    _createdAt: '2021-10-15T02:54:21Z',
    _id: 'a8dc49d8-6825-4d33-a695-01e1224bb843',
    _rev: 'uKsIgnbY5DoFWsQn4yfSd2',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:23:06Z',
    image: {
      _type: 'figure',
      image: {
        _type: 'image',
        asset: {
          _ref: 'image-29b97970b631f98145782b0a9346833c9711e94b-66x66-png',
          _type: 'reference',
        },
      },
    },
    rating: 5,
    review:
      'Melli has a beautiful way of guiding and reminding us of finding the stillness within.',
    user: 'Adrienne James',
  },
  {
    _createdAt: '2021-09-21T07:47:23Z',
    _id: 'b24da987-b7f6-4256-be3c-b4511646a0b9',
    _rev: 'uKsIgnbY5DoFWsQn4yfSd2',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:23:06Z',
    date: '2021-09-05T07:47:00.000Z',
    image: {
      _type: 'figure',
      image: {
        _type: 'image',
        asset: {
          _ref: 'image-fe76104c168aab47cf3c4802485c53f879028241-66x65-png',
          _type: 'reference',
        },
      },
    },
    rating: 5,
    review:
      'Mindfulness.com has finally made meditation stick for me as a daily practice.',
    title: 'Anne Walters',
    user: 'Anne Walters',
  },
  {
    _createdAt: '2022-02-20T23:59:37Z',
    _id: '007b1a2b-1ab9-4bda-b5b2-e47c7615dfdf',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'A quick, easy, and reassuring way to calm and refocus your anxious mind!\n\n',
    user: 'Bonnie',
  },
  {
    _createdAt: '2022-02-20T23:55:47Z',
    _id: '00ec72e9-0c6b-4273-9c46-7637a0951428',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'Thought provoking (the right kind 🙂), concise and structured.\n\n',
    user: 'Andy',
  },
  {
    _createdAt: '2022-05-19T02:46:22Z',
    _id: '01869f30-f747-44bf-8c42-152a9d0ee242',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'Free of judgment and informative. I highly recommend!',
    user: 'Amelia from GA',
  },
  {
    _createdAt: '2022-02-18T07:03:33Z',
    _id: '05743535-5966-4e99-893e-d3a4e09f9aa5',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'Step by step becoming more peaceful, in the moment, zen. \n\n',
    user: 'Leentje',
  },
  {
    _createdAt: '2022-06-08T05:33:25Z',
    _id: '08883779-de03-461e-abf6-346ade121a0d',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'It\'s nourishment for my old soul—easy listening, calming, relaxing.',
    user: 'Kristina',
  },
  {
    _createdAt: '2022-09-06T01:02:58Z',
    _id: '0db92d40-df5d-4812-9f32-02c380906baf',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'A necessary tool for those who feel lost.',
    user: 'Piper',
  },
  {
    _createdAt: '2022-09-06T01:02:19Z',
    _id: '0efc3e83-1860-44e8-aefa-78d978025c4e',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'The best and most effective meditation app thus far.',
    user: 'Manohar',
  },
  {
    _createdAt: '2021-12-15T02:38:40Z',
    _id: '10fc00b4-4407-45c8-a1dd-07ce673f0d5e',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'Thought provoking, concise and structured. In short, it’s brilliant and I hope millions more people can experience it.',
    user: 'Andy',
  },
  {
    _createdAt: '2022-02-20T23:52:23Z',
    _id: '13244e7c-7516-4505-a784-891c3306f282',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'During a painful personal experience, I can’t put into words the impact it had.\n\n',
    user: 'Grace',
  },
  {
    _createdAt: '2022-02-21T00:02:50Z',
    _id: '15c6da7f-b393-488a-b620-9b360280bf64',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'Relaxing and assuring beyond description ... thank you, Cory.\n\n',
    user: 'Babs312',
  },
  {
    _createdAt: '2022-02-21T00:05:04Z',
    _id: '16888e1e-1388-4772-a491-39b8ff508006',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'I have done Cory’s 10 day happiness course again and again. \n\n',
    user: 'GraceLoveTiger',
  },
  {
    _createdAt: '2022-06-28T00:02:44Z',
    _id: '187c2ce2-9853-40f1-bbe8-c8a3ffc33a3f',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'Wonderful daily companion for cultivating mindfulness',
    user: 'Erika M.',
  },
  {
    _createdAt: '2021-12-15T02:39:04Z',
    _id: '1b95e569-f635-4400-8018-13b299f5e47f',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'I really appreciate the blend of down to earth teachings and the high quality meditations on this app. It’s totally unique. It brings more calm, confidence and happiness into my life every day.',
    user: 'Mel Louise',
  },
  {
    _createdAt: '2022-02-20T23:55:19Z',
    _id: '1d7f439f-37f9-4efb-a31a-8510fe7fdcc0',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'I’ve used Insight Timer and Headspace for years, but now this is my go to app. \n\n',
    user: 'Andy',
  },
  {
    _createdAt: '2022-02-20T23:53:25Z',
    _id: '1dda3c46-06b5-4947-b4ce-7053a68ba280',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'Before this app it was hard for me to fall asleep, but now I sleep much better.\n\n',
    user: 'Izzy',
  },
  {
    _createdAt: '2022-02-23T01:44:31Z',
    _id: '1dda4cd2-d746-41a6-992b-541bf8ef7fd0',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'With meditations and sleep sounds, my little guy is falling asleep faster.',
    user: 'Lauren',
  },
  {
    _createdAt: '2022-02-21T00:04:07Z',
    _id: '1f068fb0-c843-4063-a72f-794940503f20',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'I LOVE the layout of the app and the daily meditations recommended for you! \n\n',
    user: 'GraceLoveTiger',
  },
  {
    _createdAt: '2022-02-21T00:04:51Z',
    _id: '228d2f07-c354-4357-9e0e-d242ea17e15b',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'Cory’s 10 day happiness course helped me see things from a new perspective.\n\n',
    user: 'GraceLoveTiger',
  },
  {
    _createdAt: '2022-02-20T23:55:03Z',
    _id: '23191f8e-4037-4874-9f80-12ab6c3f7055',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'I recommend this app 100%.\n\n',
    user: 'Vanessa',
  },
  {
    _createdAt: '2022-02-17T00:54:35Z',
    _id: '254777b7-d8ce-4107-a279-a4b2c82ff6df',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'I feel safe. And supported. ',
    user: 'Babi',
  },
  {
    _createdAt: '2022-02-20T23:51:58Z',
    _id: '269ed836-1611-4768-8efc-b931ce28acfc',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'I LOVE the layout and daily meditations recommended for you!\n\n',
    user: 'Grace',
  },
  {
    _createdAt: '2022-02-20T23:51:10Z',
    _id: '2b8deb2d-7b87-436e-813c-0022d338d4e5',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'In short, it’s brilliant and I hope millions more people can experience it.\n\n',
    user: 'Andy',
  },
  {
    _createdAt: '2022-06-28T00:04:01Z',
    _id: '2c7cdee9-53f0-466f-8f27-fdf959e5e2ff',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'This is the app I turn to when I want to feel calm and loved.',
    user: 'Abby',
  },
  {
    _createdAt: '2022-03-09T00:54:49Z',
    _id: '301e06b8-fe60-42b9-aff3-049f2fd4db87',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'I watch/listen every night before sleep—a great way to switch \'off\' and close the day, whether it was a good or bad day.',
    user: 'Pat',
  },
  {
    _createdAt: '2022-02-20T23:50:54Z',
    _id: '3415bf07-402b-4d9a-9130-6bf2bf59076b',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'Thought provoking, concise and structured. \n\n',
    user: 'Andy',
  },
  {
    _createdAt: '2022-02-21T00:03:24Z',
    _id: '358e6f46-82b3-4815-af48-858f4615e386',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'Each program is kept simple, focused, and thoughtfully and humanly communicated.\n\n',
    user: 'Pumpkin Hollow Vet',
  },
  {
    _createdAt: '2022-02-20T23:56:58Z',
    _id: '3662ddaf-8ee7-41d1-8cb2-d70601944b66',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review:
      'The meditations on anxiety and confidence have been huge for our daughter.\n\n',
    user: 'Matthew',
  },
  {
    _createdAt: '2022-02-21T00:02:22Z',
    _id: '3e378dfa-5ab2-456d-9b91-f204e0245c3b',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'I particularly enjoyed the course on happiness.\n\n',
    user: 'Lydia',
  },
  {
    _createdAt: '2022-06-08T05:30:39Z',
    _id: '41649a78-b0ad-4d9e-a288-514d5e8beb78',
    _rev: 'nsh0V1jFaQLVBtBJeMR2jW',
    _type: 'testimonials',
    _updatedAt: '2022-10-04T06:11:01Z',
    rating: 5,
    review: 'I depend on it each and every day. The meditations are fantastic.',
    user: 'Linda',
  },
];
