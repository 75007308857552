import {useSetRecoilState} from 'recoil';
import {useCallback, useContext} from 'react';
import {captureException} from '@sentry/nextjs';

import {Context} from '../components/global/Provider';
import {useModal} from './useModal';
import {playerPlayState} from '../state/atoms';
import {redeemSingleAccess} from '../api/functions/client';
import {Maybe, SingleDetailFragment} from '../types/api';

export const useSingleLocked = (single: Maybe<SingleDetailFragment>) => {
  const {session} = useContext(Context);
  const claimAccessModal = useModal('claimAccess');
  const plusUpgradeModal = useModal('plusUpgrade');
  const unlockModal = useModal('unlock');
  const setStatus = useSetRecoilState(playerPlayState);

  const handleSingleLocked = useCallback(() => {
    if (!single) return;
    const {userData, id, tags} = single;
    if (session) {
      if (!userData || userData?.locked) {
        unlockModal.open();
        return;
      }

      setStatus((prev) => {
        const next = prev === 'paused' ? 'playing' : 'paused';
        return next;
      });
    } else {
      const noClaim = tags?.find(({title}) => title === 'No Claim Access');

      if (noClaim) {
        plusUpgradeModal.open();
        return;
      }
      claimAccessModal.open({
        props: {
          redirect: async () => {
            try {
              await redeemSingleAccess({
                code: id,
              });
              claimAccessModal.close();
              setStatus('playing');
            } catch (e) {
              captureException(e);
            }
          },
        },
      });
    }
  }, [
    session,
    single?.userData,
    single?.id,
    claimAccessModal,
    unlockModal,
    setStatus,
  ]);

  return handleSingleLocked;
};
