import * as React from 'react';

import {H2} from '../../typography';
import {Container, Row, Column, Section, Stack} from '../../layout';
import {ReferencedContentFeature, SectionIndex} from '../../../types/types';
import {
  BackgroundImage,
  BlockContent,
  CallToActionButton,
} from '../../global';

export const ContentFeature: React.FC<
  SectionIndex<ReferencedContentFeature>
> = ({content, image, _type, index}) => (
  <Section
    position="relative"
    padding={{
      xs: 40,
      md: 174,
      lg: 242,
    }}
  >
    {image ? <BackgroundImage image={image} /> : null}
    <Container maxWidth="prose">
      <Row>
        <Column span={16} color="white">
          <Stack direction="vertical" space={10}>
            {content?.title ? <H2>{content.title}</H2> : null}
            <BlockContent content={content?.paragraph} background="grey9" />
            {content?.cta?.text ? (
              <CallToActionButton id={`${_type}-${index}`} {...content?.cta}>
                {content?.cta?.text}
              </CallToActionButton>
            ) : null}
          </Stack>
        </Column>
      </Row>
    </Container>
  </Section>
);
