export const breakpoints = {
  xs: {
    min: '480px',
    max: '479px',
  },
  sm: {
    min: '576px',
    max: '575px',
  },
  md: {
    min: '768px',
    max: '767px',
  },
  lg: {
    min: '992px',
    max: '991px',
  },
  xl: {
    min: '1200px',
    max: '1199px',
  },
  xxl: {
    min: '1600px',
    max: '1599px',
  },
};
