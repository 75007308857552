import {useCallback, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {composel} from '@mindfulness/utils/fn';

import {Maybe} from '../types/types';
import {useNavigation} from '../hooks';
import {
  getSegmentAnonymousID,
  toUrl,
  APPSFLYER_BASE_URL,
  GENERAL_SHORT_LINK,
} from '../utils';

const QR_DOMAIN =
  process.env.INFRA_ENV === 'prod' ?
    'https://qr.mindfulness.com' :
    'https://qr.dev.mindfulness.com';
const replaceDomain = (link: string) => `${QR_DOMAIN}${link.split('.com')[1]}`;
const appendSegmentAnonID = (link: string) =>
  `${link}${link.includes('?') ? '&' : '?'}said=${getSegmentAnonymousID()}`;
const makeQRLink = composel(replaceDomain, appendSegmentAnonID);

/**
 * A hook for creating QR codes
 * @return {Object}
 */
export function useQRCode() {
  const [sourceLink, setSourceLink] = useState<Maybe<string>>();
  const [qrCodeLink, setQRCode] = useState<Maybe<string>>();
  const router = useRouter();
  const {getQueryParam} = useNavigation();

  const buildLink = useCallback(() => {
    const urlValFromUrl = getQueryParam('url');
    const linkFromUrl = getQueryParam('link');
    const shortlinkIDFromUrl = getQueryParam('shortlink');
    const utmSourceFromUrl =
      getQueryParam('utm_source') ?? getQueryParam('pid');

    if (linkFromUrl) {
      return linkFromUrl;
    } else if (shortlinkIDFromUrl) {
      // There is a shortlink code in the url params
      // Which only comes from appsflyer short links, hence recreate short link
      return `${APPSFLYER_BASE_URL}/${shortlinkIDFromUrl}`;
    } else if (urlValFromUrl) {
      // There is a url query param (which comes only from branch.io converted links)
      // Hence just pass on the branch link
      return urlValFromUrl;
    } else if (utmSourceFromUrl) {
      // There is some attribution data in the url params
      // Hence recreate a long appsflyer url
      return toUrl({
        pathname: `${APPSFLYER_BASE_URL}`,
        query: router.query,
      });
    } else {
      // Return a default appsflyer attributed link
      return `${APPSFLYER_BASE_URL}/${GENERAL_SHORT_LINK}`;
    }
  }, [router]);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const link = buildLink();
    setSourceLink(link);
    const qrLink = makeQRLink(link);
    setQRCode(qrLink);

    // Regenerate the QR code incase the first time it was missing the
    // segment anonymous id (not always there on page load).
    setTimeout(() => {
      const link = buildLink();
      setSourceLink(link);
      const qrLink = makeQRLink(link);
      setQRCode(qrLink);
    }, 1000);
  }, [router.isReady, buildLink]);

  return {
    sourceLink,
    qrCodeLink,
    makeQRLink,
  };
}
