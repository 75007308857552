import {FC, useContext, useEffect, useState} from 'react';
import {isUndefined} from 'lodash';
import {OrderList as OrderListProps} from '@mindfulness/cms';

import {Container, Row, Column, Section} from '../../layout';
import {SectionIndex} from '../../../types/types';
import {Divider} from '../../ui/Divider';
import {OrderFragment} from '../../../types/api';
import {LoadingSpinner} from '../../ui/LoadingSpinner';
import {useQueryParam} from '../../../hooks/useQueryParam';
import {getFinalizedOrder} from '../../../api/functions/client';
import {PurchaseList} from '../../ui/PurchaseList';
import {Alert} from '../../forms/Alert';
import {Context} from '../../global/Provider';
import {useModal} from '../../../hooks/useModal';
import {SmallModal} from '../../global/SmallModal';
import {AuthForm} from '../../ui/AuthForm';
import {assertString, cleanEmail} from '../../../utils';

export const OrderList: FC<SectionIndex<OrderListProps>> = ({
  paddingTop,
  paddingBottom,
}) => {
  const {session} = useContext(Context);
  const [order, setOrder] = useState<OrderFragment>();
  const [error, setError] = useState<string>();
  const [orderId] = useQueryParam('order');
  const {open, close} = useModal('orderAccess');
  const [email] = useQueryParam('email');
  const _email = cleanEmail(assertString(email));
  useEffect(() => {
    setOrder(undefined);
    if (!orderId) return;
    (async () => {
      if (isUndefined(session)) {
        open({
          props: {
            redirect: () => {
              close();
            },
          },
        });
        setError('Please login to access this order.');

        return;
      }
      try {
        const res = await getFinalizedOrder({order: orderId});
        setOrder(res || undefined);
      } catch (e) {
        if (e instanceof Error) {
          setError(
            e.message === 'Purchase Order not found.' ?
              'Unable to access order, are you signed in with the correct account?' :
              e.message,
          );
          open({
            props: {
              suggestedEmail: _email,
              redirect: () => {
                close();
              },
            },
          });
          return;
        }
        setError('An unknown error occurred');
      }
    })();
  }, [orderId, session]);

  return (
    <Section
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container maxWidth="md">
        {order ? (
          <>
            <Divider spacing={20} />

            <PurchaseList order={order} />
          </>
        ) : (
          <Row>
            <Column justify="center">
              {error ? <Alert error={error} /> : <LoadingSpinner />}
            </Column>
          </Row>
        )}
      </Container>
      <SmallModal
        name="orderAccess"
        Top={undefined}
        Bottom={Bottom}
      />
    </Section>
  );
};

const Bottom: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {props} = useModal('orderAccess');
  const {session} = useContext(Context);
  const [email] = useQueryParam('email');
  const _email = cleanEmail(assertString(email));
  return (
    <AuthForm
      index={0}
      name="orderAccess"
      view={session ? 'signup' : _email ? 'login' : 'signup'}
      email={_email}
      loading={loading}
      setLoading={setLoading}
      accessTitle={'Did you want to switch accounts?'}
      subtitle={'You may need to login to be able to access this content.'}
      title={'Login to continue'}
      {...props} />
  );
};
