import {useCallback, useContext, useState} from 'react';
import {useRecoilState, useResetRecoilState} from 'recoil';

import {addToOrder, removeFromOrder} from '../api';
import {Maybe, OrderInput, PurchaseFragment} from '../types/api';
import {
  orderIdState,
  orderItemsState,
} from '../state/atoms';
import {useModal} from './useModal';
import {Context} from '../components/global/Provider';
import {useSearchParams} from 'next/navigation';
import {AuthedSession, onClient} from '../utils';
import {debug} from '../utils/logging';
import {useNewNavigation} from './useNewNavigation';
import {ApolloError} from '@apollo/client';


export const useAddToCart = () => {
  const {goTo} = useNewNavigation();
  const searchParams = useSearchParams();
  const context = useContext(Context);
  const {data, session, cancelLoadingPageAction} = context;
  const clearOrderId = useResetRecoilState(orderIdState);
  const clearOrderItems = useResetRecoilState(orderItemsState);
  const [orderId, setOrderId] = useRecoilState(orderIdState);
  const [orderItems, setOrderItems] = useRecoilState(orderItemsState);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  // const upsellModal = useModal('upsell');
  const actionModal = useModal('action');

  const add = async (purchase: PurchaseFragment, changes?: OrderInput) => {
    try {
      const res = await addToOrder({
        order: orderId,
        purchasable: purchase.id,
        changes,
      });
      setOrderId(res?.id);
      setOrderItems(res?.purchasables?.map(({id}) => id) || []);
      return res;
    } catch (err) {
      if (err instanceof ApolloError) {
        // Retry without order id
        if (err.message === 'Purchase Order not found.') {
          clearOrderId();
          clearOrderItems();
          return add(purchase, changes);
        }
        throw new Error(err.message);
      }
    }
  };

  const remove = async (purchase: PurchaseFragment) => {
    if (!orderId) return;
    const res = await removeFromOrder({
      order: orderId,
      purchasable: purchase.id,
    });

    setOrderId(res?.id);
    setOrderItems(res?.purchasables?.map(({id}) => id) || []);
  };

  const handleAdd = useCallback(
      async (purchase: PurchaseFragment, changes?: OrderInput, onto?: string) => {
        try {
          setLoading(true);
          if (!purchase?.id) return;
          await add(purchase, changes);

          // const containsUpsell = order?.purchasables?.find(
          //     (p) => p.id === order.available?.upsellOffer?.id,
          // );
          // if (order?.available?.upsellOffer && !containsUpsell) {
          //   upsellModal.open({
          //     props: {
          //       upsellOffer: order.available.upsellOffer,
          //     },
          //   });
          //   return;
          // }
          goTo({
            pathname: '/checkout',
            query: {onto},
          });
        } catch (err) {
          if (err instanceof Error) {
            if (err.message === 'Purchase Order not found.') {
            // Retry without order id
              clearOrderId();
              clearOrderItems();
              await add(purchase, changes);
              goTo({
                pathname: '/checkout',
                query: {onto},
              });
            }
            setError(err.message);
          }
        } finally {
          setLoading(false);
        }
      },
      [orderId, setOrderId, orderItems, setOrderItems, data?.action?.thankYouLink, data?.action?.thankYouPage?.current, searchParams],
  );

  const handleRemove = useCallback(
      async (purchase: PurchaseFragment) => {
        try {
          setLoading(true);
          if (!purchase?.id) return;
          await remove(purchase);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
        } finally {
          setLoading(false);
        }
      },
      [session],
  );

  const addToCart = useCallback(
      async (
          purchase: PurchaseFragment,
          _changes?: OrderInput,
          s?: Maybe<AuthedSession> | null,
          skipSignIn?: boolean,
          onClose?: () => void,
          onto?: string,
      ) => {
        debug('Adding to cart', purchase);

        const parent = window.location.pathname.split('/')[1];
        const singleArray = [
          'program',
          'guided-meditation',
          'music',
          'sound',
          'breathwork',
          'daily',
          'playlist',
          'sleep-story',
          'sound',
          'talk',
          'special-release',
        ];

        const changes = {
          ..._changes,
          metadata: {
            ..._changes?.metadata,
            // Redirect back to singles on subscriptions purchased from those pages
            subscriptionLocation: onClient(() => {
              return purchase.unlocks?.subscription && singleArray.includes(parent) ? window.location.pathname : undefined;
            }),
          },
        };

        if (!skipSignIn && !session && !s) {
          actionModal?.open({
            onClose: () => {
              if (onClose) {
                onClose();
                return;
              }
              cancelLoadingPageAction?.();
            },
            props: {
              onToPage: `/checkout?products=${purchase.id}${
              changes?.coupon ? `&coupon=${changes.coupon}` : ''
              }${changes?.metadata?.cohort ? `&cohort=${changes.metadata.cohort}` : ''}${onto ? `&onto=${onto}` : ''}`,
              redirect: async () => {
                await handleAdd(purchase, changes, onto);
              },
            },
          });
          return;
        }

        await handleAdd(purchase, changes, onto);
      },
      [session, cancelLoadingPageAction, data?.action?.cohort],
  );

  const removeFromCart = useCallback(
      async (purchase: PurchaseFragment) => {
        await handleRemove(purchase);
      },
      [session],
  );

  return {
    removeFromCart,
    addToCart,
    loading,
    error,
    add,
    remove,
  };
};
