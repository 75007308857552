import * as React from 'react';
import {Countdown as CountdownProps} from '@mindfulness/cms';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

import {Text, Title1} from '../../typography';
import {Box, Column, Container, Row, Section, Stack} from '../../layout';
import {CallToActionButton} from '../../global';
import {Maybe, SectionIndex} from '../../../types/types';
import {Countdown as CountdownTimer} from './Countdown';

import {withOnClient} from '../../../hoc/withOnClient';
import {useLockedBundle} from '../../../hooks/useLockedBundle';
import {Button} from '../../forms';

export const Countdown: React.FC<SectionIndex<CountdownProps>> = ({
  title,
  datetime,
  subtitle,
  cta,
  background,
  index,
  _type,
}) => {
  const {isLockedBundle, accessAction} = useLockedBundle();

  return (
    <Section
      background={background}
      padding={{
        xs: 0,
        md: 40,
      }}
    >
      <Container
        maxWidth="lg"
        gutter={{
          xs: 0,
          md: 16,
        }}
      >
        <Box
          background="greyWarm"
          radius={{
            xs: 'none',
            md: 'lg',
          }}
          paddingX={40}
          paddingY={30}
        >
          <Row justify="space-between" items="center">
            <Column
              order={{
                xs: 2,
                lg: 1,
              }}
              textAlign={{
                xs: 'center',
                lg: 'start',
              }}
              span={{
                xs: 24,
                lg: 8,
              }}
              spacing={{
                xs: 30,
                lg: 0,
              }}
            >
              <Title1>{title}</Title1>
              <Text>{subtitle}</Text>
            </Column>
            <Column
              order={{
                xs: 1,
                lg: 2,
              }}
              textAlign="center"
              span={{
                xs: 24,
                lg: 6,
              }}
              spacing={{
                xs: 16,
                lg: 0,
              }}
            >
              <Date datetime={datetime} />
              <Stack justify="center" space={6}>
                <CountdownTimer datetime={datetime} />
              </Stack>
            </Column>
            <Column
              order={3}
              textAlign={{
                xs: 'center',
                lg: 'end',
              }}
              span={{
                xs: 24,
                lg: 8,
              }}
              spacing={{
                xs: 16,
                lg: 0,
              }}
            >
              {isLockedBundle ? (
                <Button
                  id={`${_type}-${index}`}
                  variant={cta?.style}
                  onClick={() => accessAction()}
                >
                  {cta?.text}
                </Button>
              ) : (
                <CallToActionButton {...cta} id={`${_type}-${index}`}>
                  {cta?.text}
                </CallToActionButton>
              )}
            </Column>
          </Row>
        </Box>
      </Container>
    </Section>
  );
};

const Date: React.FC<{ datetime: Maybe<string> }> = withOnClient(
    ({datetime}) => {
      const date = React.useMemo(() => {
        return dayjs.utc(datetime).local().format('MMMM Do, YYYY');
      }, [datetime]);
      return <Text spacing={4}>{date}</Text>;
    },
);
