import {Figure, SanityImageMetadata} from '@mindfulness/cms';
import {FigureWithMeta, Maybe} from '../types/types';

export const useBlurProps = (image: Maybe<Figure | FigureWithMeta>, priority: boolean) => {
  const blurProps: Partial<{
    placeholder: 'blur';
    blurDataURL: string;
  }> = !priority && image?.image?.asset && 'metadata' in image.image.asset? {
    placeholder: 'blur',
    blurDataURL: (image.image.asset.metadata as SanityImageMetadata).lqip,
  } : {};
  return blurProps;
};
