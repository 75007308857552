import * as React from 'react';
import {useRouter} from 'next/router';
import {isMobile} from 'react-device-detect';
import {when} from '@mindfulness/utils/maybe';

import {Column, Container, Row, Stack} from '../../layout';
import {H2, Text} from '../../typography';
import {utmToDeeplink, APPSFLYER_BASE_URL} from '../../../utils';
import {Single} from '../../../types/types';
import {LogoApp, AppStoreLinksTiny} from '../../brand';
import {DownloadLinks, OnClient, QRCodeSection, SignUpForm} from '../../ui';
import {ButtonLink, Modal, Context} from '../../global';

import {
  LeftContainer,
  SingleCard,
  SingleCardFooter,
  SingleCardInner,
  SingleImage,
  WaveWrapper,
} from './SingleModal.styles';

export const SingleModal: React.FC = () => (
  <Modal name="single" Left={Left} Right={Right} />
);

const Right: React.FC<{ single: Single }> = ({single}) => {
  const router = useRouter();
  const {session} = React.useContext(Context);
  const link = React.useMemo(() => {
    const params = new URLSearchParams(utmToDeeplink(router.query)).toString();
    return when(
        single,
        () =>
          `${APPSFLYER_BASE_URL}?af_dp=${single.deepLink}${
          params.length ? `&${params}` : ''
          }`,
    );
  }, [single, router.isReady]);

  return single ? (
    session ? (
      <Container gutter={20} maxWidth="sm">
        <Row spacing={40}>
          <Column>
            <H2 as="p" size="3xl" spacing={8}>
              {isMobile ?
                'Download the app to play it instantly' :
                'Scan this on your phone to play it instantly in-app'}
            </H2>
            <Text colour="grey7">
              {
                'Open this link on your phone. The app is only available on mobile devices.'
              }
            </Text>
          </Column>
        </Row>
        <OnClient>
          {isMobile ? (
            <Row>
              <Column>
                {link ? (
                  <ButtonLink href={link} id={`SingleModal`}>
                    Download and Login
                  </ButtonLink>
                ) : null}
              </Column>
            </Row>
          ) : link ? (
            <>
              <QRCodeSection qrCodeLink={link} />
            </>
          ) : null}
        </OnClient>

        <Row
          topSpacing={{
            xs: 16,
            md: 34,
          }}
        >
          <Column>
            <DownloadLinks />
          </Column>
        </Row>
      </Container>
    ) : (
      <SignUpForm
        setLoading={() => {}}
        title={
          single.free ?
            'Access this for free in our mobile app' :
            'Access this in our mobile app.'
        }
      />
    )
  ) : null;
};

const Left: React.FC<{ single: Single }> = ({single}) => (
  <>
    {single ? (
      <LeftContainer>
        <SingleCard>
          <SingleCardInner>
            <Text fontSize="xs">
              {`${
                single.contributors[0]?.producer.fullName ?
                  `${single.contributors[0]?.producer.fullName} · ` :
                  ''
              } ${single.label}`}
            </Text>
            <Text lineHeight={1} fontSize="xl" fontWeight="bold">
              {single.title}
            </Text>
            <WaveWrapper>
              <WaveSVG />
            </WaveWrapper>
          </SingleCardInner>
          <SingleImage src={single.cellImage?.url} />
          <SingleCardFooter>
            <Stack justify="space-between" direction="horizontal">
              <LogoApp />
              <AppStoreLinksTiny />
            </Stack>
          </SingleCardFooter>
        </SingleCard>
      </LeftContainer>
    ) : null}
  </>
);

const WaveSVG = () => (
  <svg
    width="325"
    height="69"
    viewBox="0 0 325 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_9026:15949)">
      <path
        d="M25.8109 36.3818V32.6182"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3.51306 46.4184V22.582"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.16602 40.7725V28.2271"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.819 46.4184V22.582"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20.472 38.2636V30.7363"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M31.6732 38.2636V30.7363"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M37.3262 45.7908V23.209"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M77.1064 37.9498V31.0498"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M82.9688 41.5047V27.4956"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M88.6218 33.3501V35.6501"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M42.9792 55.1998V13.7998"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48.6322 42.6543V26.3452"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M54.2852 38.2636V30.7363"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M59.9381 36.3818V32.6182"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M94.4841 41.5047V27.4956"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M100.137 55.409V13.5908"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M105.79 59.591V9.40918"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M111.443 69V0"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M117.096 59.591V9.40918"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M122.749 16.7271V52.2725"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M65.5911 45.7908V23.209"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M71.2441 39.5183V29.4819"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M129.449 21.7456V47.2547"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M135.102 26.1362V42.8635"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M140.755 36.3818V32.6182"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M191.213 40.7725V28.2271"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M146.408 35.7547V33.2456"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M317.672 32.6182V36.3818"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M310.972 26.3452V42.6543"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M299.666 32.6182V36.3818"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M151.956 41.6093V27.3911"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M157.609 48.9273V20.0728"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M163.262 42.6543V26.3452"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M168.915 46.4184V22.582"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M174.568 55.1998V13.7998"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M180.221 52.6909V16.3091"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M185.874 48.9273V20.0728"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M197.075 52.6909V16.3091"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M202.728 59.591V9.40918"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M242.508 36.3818V32.6182"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M248.371 38.2636V30.7363"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M254.024 33.8726V35.1271"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M323.953 33.8726V35.1271"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M305.11 38.2636V30.7363"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M293.594 33.8726V35.1271"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M208.381 69V0"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M214.034 52.6909V16.3091"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M219.687 45.7908V23.209"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M225.34 39.5183V29.4819"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M259.886 38.2636V30.7363"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M265.539 45.7908V23.209"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M271.192 38.8907V30.1089"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M276.845 42.6543V26.3452"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M282.498 38.2636V30.7363"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M288.151 36.3818V32.6182"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M230.993 45.7908V23.209"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M236.646 39.5183V29.4819"
        stroke="white"
        strokeOpacity="0.19"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_9026:15949">
        <rect width="325" height="69" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
