import * as React from 'react';

import style from './Slider.module.css';

import {Box, Flex} from '../../layout';
import {throttle} from 'lodash';

export const Slider: React.FC<Props> = ({children, speed = 5000}) => {
  const [width, setWidth] = React.useState<number>();
  const [loaded, setLoaded] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [activeSlide, setActiveSlide] = React.useState(0);
  const outerRef = React.useRef<HTMLDivElement>(null);
  const innerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    /**
     * Update the state (witht he width of the outerRef) any time the window size changed
     */
    function handleResize() {
      setWidth(outerRef.current?.clientWidth);
    }
    const throttledListener = throttle(handleResize, 200);
    handleResize();
    window.addEventListener('resize', throttledListener);
    return function cleanup() {
      window.removeEventListener('resize', throttledListener);
    };
  }, [outerRef.current]);

  React.useEffect(() => {
    if (width && innerRef.current) {
      innerRef.current.style.transform = `translateX(-${
        activeSlide * width
      }px)`;
    }
  }, [width, innerRef.current, activeSlide]);

  React.useEffect(() => {
    let id: NodeJS.Timer;
    if (playing) {
      id = setInterval(() => {
        setActiveSlide((prev) => {
          const nextSlide = prev + 1;
          if (nextSlide >= React.Children.count(children)) {
            return 0;
          }
          return nextSlide;
        });
      }, speed);
    } else {
      // @ts-ignore
      clearInterval(id);
    }

    return function cleanup() {
      clearInterval(id);
    };
  }, [playing, children, speed]);

  React.useEffect(() => {
    setLoaded(true);
    setPlaying(true);
  }, []);

  return (
    <Box visibility={loaded ? 'visible' : 'hidden'}>
      <div className={style.sliderOuter} ref={outerRef}>
        <div className={style.sliderInner} ref={innerRef}>
          {React.Children.map(children, (child) => (
            <div
              key={child?.toString()}
              style={{
                width: `${width}px`,
              }}
            >
              {child}
            </div>
          ))}
        </div>
      </div>
      <SliderDots
        count={React.Children.count(children)}
        activeSlide={activeSlide}
        onChange={(index: number) => setActiveSlide(index)}
      />
    </Box>
  );
};

const SliderDots: React.FC<{
  count: number;
  activeSlide: number;
  onChange: (index: number) => void;
}> = ({count, activeSlide, onChange}) => (
  <Flex items="center" justify="center" gap={10} spacing={12}>
    {Array.from({length: count}, (_, index) => (
      <button
        type="button"
        className={[
          style.dot,
          activeSlide === index ? style.active : '',
        ].join(' ')}
        onClick={() => onChange(index)}
        key={index}
      />
    ))}
  </Flex>
);

type Props = React.PropsWithChildren<{
  /** time in ms to display each slide for before changing default: 5000 */
  speed: number;
}>;
