import * as React from 'react';
import {switchEnum} from '@mindfulness/utils/logic';

import {BundleCard} from '../BundleCard';
import {ItemCard} from '../ItemCard';
import {TeacherFeedBlock} from '../TeacherFeedBlock';

import {
  BundleFragment,
  ItemLocation,
  Maybe,
  ProducerFragment,
  SingleDetailFragment,
  WithWebSlug,
} from '../../../types/types';
import {assertString} from '../../../utils';
import {
  CollectionFeedFragment,
  CollectionLayout,
  FeatureFeedFragment,
  PosterFeedFragment,
  QuickLinkFeedFragment,
  TopicFeedFragment,
} from '../../../types/api';
import {QuickLink} from '../QuickLink';
import {FeatureCard} from '../FeatureCard';
import {NewLink} from '../../global/Link';
import {TopicCard} from '../TopicCard';
import {mapDeeplink} from '../../../utils/mapDeeplink';
import {PosterCard} from '../PosterCard';
import {useTrack} from '../../global/SegmentProvider';

export const MixedResults: React.FC<{
  items:
    | CollectionFeedFragment['results']['items']
    | [QuickLinkFeedFragment, QuickLinkFeedFragment];
  priority?: boolean;
  loading?: boolean;
  location?: ItemLocation;
  layout?: CollectionLayout;
  type: CollectionFeedFragment['results']['items'][0]['__typename'];
  defaultEventProps: {
    collection_id: Maybe<string>,
    collection_title: Maybe<string>,
    feed_id: Maybe<string>,
  }
}> = ({items, priority, loading, location, layout, type, defaultEventProps}) => {
  const track = useTrack();
  return (
    <>
      {items.map((item, index) => switchEnum(assertString(type, 'else'), {
        Bundle: () => {
          const bundle = item as unknown as WithWebSlug<BundleFragment>;
          const onClick = () => {
            track('Collection item selected', {
              item_id: bundle.id,
              item_type: 'BUNDLE',
              item_index: index,
              ...defaultEventProps,
            });
          };
          return (
            <BundleCard
              {...bundle}
              key={bundle.id}
              priority={index < 4 && priority}
              location={location}
              layout={layout}
              onClick={onClick}
            />
          );
        },
        Single: () => {
          const single = item as unknown as SingleDetailFragment;
          const onClick = () => {
            track('Collection item selected', {
              item_id: single.id,
              item_type: 'SINGLE',
              item_index: index,
              ...defaultEventProps,
            });
          };
          return (
            <ItemCard
              {...single}
              key={single.id}
              loading={loading}
              priority={index < 4 && priority}
              location={location}
              href={single.webSlug ? single.webSlug : `/play?s=${single.id}`}
              onClick={onClick}
            />
          );
        },
        Producer: () => {
          const producer = item as unknown as ProducerFragment;
          const onClick = () => {
            track('Collection item selected', {
              item_id: producer.id,
              item_type: 'PRODUCER',
              item_index: index,
              ...defaultEventProps,
            });
          };
          return (
            <TeacherFeedBlock
              {...producer}
              key={producer.id}
              priority={index < 4 && priority}
              location={location}
              onClick={onClick}
            />
          );
        },
        Feature: () => {
          const feature = item as unknown as FeatureFeedFragment;
          const href = assertString(
              mapDeeplink(feature.deepLink) || feature.webLink,
          );
          const onClick = () => {
            track('Collection item selected', {
              item_id: feature.id,
              item_type: 'FEATURE',
              item_index: index,
              ...defaultEventProps,
            });
          };
          return <FeatureCard {...feature} href={href} key={feature.id} onClick={onClick} priority={index < 4 && priority} />;
        },
        QuickLink: () => {
          const quickLinks = item as unknown as [
            QuickLinkFeedFragment,
            QuickLinkFeedFragment
          ];
          return <QuickLink quickLinks={quickLinks} key={quickLinks[0].id} defaultEventProps={{
            ...defaultEventProps,
            item_index: index,
          }} />;
        },
        Topic: () => {
          const topic = item as unknown as TopicFeedFragment;
          const onClick = () => {
            track('Collection item selected', {
              item_id: topic.id,
              item_type: 'TOPIC',
              item_index: index,
              ...defaultEventProps,
            });
          };
          return (
            <NewLink href={assertString(mapDeeplink(topic.deepLink) ?? topic.webSlug)} key={topic.id} onClick={onClick}>
              <TopicCard
                title={topic.title}
                subtitle={assertString(topic.subtitle)}
                url={topic.coverImage?.url} />
            </NewLink>
          );
        },
        Poster: () => {
          const poster = item as unknown as PosterFeedFragment;
          const onClick = () => {
            track('Collection item selected', {
              item_id: poster.id,
              item_type: 'POSTER',
              item_index: index,
              ...defaultEventProps,
            });
          };
          return (
            <PosterCard
              {...poster}
              href={assertString(
                  mapDeeplink(poster.deepLink) || poster.webLink,
              )}
              key={poster.id}
              priority={index < 4 && priority}
              location="reel"
              onClick={onClick}
            />
          );
        },
        else: null,
      }),
      )}
    </>
  );
};
