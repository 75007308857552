import styled from '@emotion/styled';

export const BackgroundContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 50%;
`;
export const IconBox = styled.div(({theme}) => `
  color: ${theme.colors.primary};
  padding-top: 5px;
`);
