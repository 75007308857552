import {Theme} from '@emotion/react';
import styled from '@emotion/styled';

export const SplitContainer = styled.div<{
  splitPoint?: keyof Theme['breakpoints'];
}>(
    ({theme, splitPoint = 'md'}) => `
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  max-width: ${theme.sizes.container.xl};
  @media (min-width: ${theme.breakpoints[splitPoint].min}) {
    gap: 40px;
    padding-left: ${theme.spacings[30]};
    padding-right: ${theme.spacings[30]};
  }
`,
);

export const RightContainer = styled.div<{
  splitPoint?: keyof Theme['breakpoints'];
}>(
    ({theme, splitPoint = 'md'}) => `
  flex-grow: 1;
  flex-basis: 100%;
  padding-left: ${theme.spacings[16]};
  padding-right: ${theme.spacings[16]};

  @media (min-width: ${theme.breakpoints[splitPoint].min}) {
    flex-basis: 380px;
    padding-left: 0;
    padding-right: 0;
  }
`,
);
export const LeftContainer = styled.div(
    () => `
  flex-basis: 0;
  flex-grow: 999;
`,
);
