import styled from '@emotion/styled';

export const ContentFlex = styled.div(({theme}) => `
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;

  @media (min-width: ${theme.breakpoints.sm.min}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

`);


export const RowFlex = styled.div(({theme}) => `
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
  align-items: start;

  @media (min-width: ${theme.breakpoints.sm.min}) {
    align-items: center;
  }
`);

