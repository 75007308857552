import {REDEEM_ACCESS_PASS} from '../mutations/redeemAccessPass';
import {
  RedeemAccessPassMutation,
  RedeemAccessPassMutationVariables,
} from '../../types/api';
import {getGraphClient} from '../../utils/graph';

export const redeemAccessPass = async (
    variables: RedeemAccessPassMutationVariables,
) => {
  const {data} = await getGraphClient().mutate<RedeemAccessPassMutation>({
    mutation: REDEEM_ACCESS_PASS,
    variables,
  });
  return data?.redeemAccessPass;
};
