import {Maybe} from '@mindfulness/utils/maybe';
import {NewLink} from '../components/global/Link';
import {NormalizedButton} from '../components/ui/NormalizedButton';

/**
 * Wraps a component in a link if the href is defined, otherwise returns the component
 * without a link
 * @param {React.ComponentType<P>} Component - Child component
 * @param {string} displayName - The name to apply to the returned component
 * @return {React.FC} - Wrapped component
 */
export function withSanityReference<P extends SanityReferenceProps>(
    Component: React.ComponentType<P>,
    displayName?: string,
): React.FC<P> {
  const WithSanityReference = (props: P) => {
    if (props.onClick) {
      return (
        <NormalizedButton type="button" onClick={props.onClick}>
          <Component {...props} />
        </NormalizedButton>
      );
    }
    if (props.type === 'external' && props.url) {
      return (
        <a href={props.url} target="_blank" rel="nofollow noreferrer">
          <Component {...props} />
        </a>
      );
    }
    if (props.type === 'internal' && props.url) {
      return (
        <NewLink href={props.url}>
          <Component {...props} />
        </NewLink>
      );
    }
    if (props.type === 'reference' && props.target?.slug?.current) {
      return (
        <NewLink href={props.target.slug.current}>
          <Component {...props} />
        </NewLink>
      );
    }

    return <Component {...props} />;
  };
  WithSanityReference.displayName = displayName ?? 'WithSanityReference';
  return WithSanityReference;
}

export type SanityReferenceProps = {
  type: Maybe<'reference' | 'external' | 'internal'>;
  url: Maybe<string>;
  target: Maybe<{
    slug: Maybe<{ current: Maybe<string> }>;
    title: Maybe<string>;
  }>;
  onClick?: () => void;
};
