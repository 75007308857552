import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {buildResponsiveCss, getGap} from '../../../utils/styling';
import type {ResponsiveValue} from '../../../types/types';


export const ReelTrack = styled.div<{
  dragging: boolean;
  withShadow?: boolean;
  gap?: ResponsiveValue<keyof Theme['spacings']>;
}>(
    ({theme, dragging, withShadow, gap}) => `
    ${
      gap ?
        `
      ${getGap(theme, gap)}
      ${
        withShadow ?
          `
        padding-top: 1rem;
        margin-top: -1rem;
        ` :
          ''
      }
    ` :
        `
    ${
      withShadow ?
        `
        padding-top: 1rem;
        margin-top: -1rem;
        margin-left: -1.2rem;
        padding-left: 1.2rem;
    ` :
        ''
    }`
}

  display: flex;
  flex-wrap: nowrap;
  height: auto;
  overflow-x: scroll;

  scrollbar-color: transparent;
  scrollbar-width: none;
  scroll-margin-right: 16px;


  ${
    dragging ?
      `
    user-select: none;
  ` :
      `
      // Adds inertia to the scroll
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;

      @media (min-width: ${theme.breakpoints.md.min}) {
        // scroll-snap-type: x mandatory;
        // scroll-behavior: smooth;
      }
  `
}

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  > * {
    scroll-snap-align: start;
  }
`,
);

export const ArrowButton = styled.button<{
  left?: boolean;
  visible: boolean;
  top?: number;
}>(
    ({theme, left, visible, top}) => `
    ${
      visible ?
        `
      @media (min-width: ${theme.breakpoints.lg.min}) {
        display: flex;
      }
      ` :
        ''
}
  border-radius: 100%;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  height: 60px;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 60px;
  position: absolute;
  top: ${top ? `${top}px` : '50%'};
  background: ${theme.colors.white};
  z-index: 4;
  display: none;

  ${
    left ?
      `
    left: 0;
    transform: translateX(-50%) translateY(-50%) rotate(180deg);
  ` :
      `
    right: 0;
    transform: translateX(50%) translateY(-50%);
  `
}

`,
);

export const ReelUncontainedSpacer = styled.div<{
  container?: keyof Theme['sizes']['container'];
}>(
    ({theme, container = 'lg'}) => `
  min-width: 1rem;
  padding-left: 1rem;
  @media (min-width: ${theme.breakpoints.md.min}) {
    min-width: calc((100vw - ${theme.sizes.container[container]}) / 2);
  }
`,
);

export const EndReelUncontainedSpacer = styled.div<{
  container?: keyof Theme['sizes']['container'];
}>(
    ({theme, container = 'lg'}) => `
  @media (min-width: ${theme.breakpoints.md.min}) {
    min-width: calc((100vw - ${theme.sizes.container[container]}) / 2);
  }
`,
);

export const ReelWrapper = styled.div<{ center: boolean }>(
    ({center}) => `
  display: flex;
  ${center ? `justify-content: center;` : ''}

`,
);

export const UncontainedSpacer = styled.div<{
  container?: keyof Theme['sizes']['container'];
  gutter?: ResponsiveValue<keyof Theme['spacings']>;
}>(({theme, container = 'xl', gutter = {xs: 16, md: 30}}) => {
  return `
      flex-shrink: 0;
  ${buildResponsiveCss({
    theme,
    val: gutter,
    format: (val) => `
    min-width: ${val}px;
    width: calc((100vw - ${theme.sizes.container[container]}) / 2 + ${val}px);
    `,
  })}
`;
});
