import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {CSSProperties} from 'react';
import {getSpacing} from '../../utils/styling';

export const Card = styled.div<{
  show?: keyof Theme['breakpoints'];
  radius?: keyof Theme['radius'];
  shadow?: keyof Theme['shadows'] | 'none';
  padding?: keyof Theme['spacings'];
  spacing?: keyof Theme['spacings'];
  background?: keyof Theme['colors'];
  backgroundGradient?: keyof Theme['gradients'];
  textAlign?: CSSProperties['textAlign'];
  justify?: CSSProperties['justifyContent'];
  align?: CSSProperties['alignItems'];
  height?: CSSProperties['height'];
  direction?: CSSProperties['flexDirection'];
}>(
    ({
      theme,
      padding = 24,
      radius = 'lg',
      shadow = 'medium',
      background = 'white',
      backgroundGradient,
      textAlign = 'center',
      justify = 'center',
      align = 'center',
      spacing,
      height = '100%',
      direction = 'column',
      show,
    }) => `
    ${
      show ?
        `
      @media (min-width: ${theme.breakpoints[show].min}) {
        border-radius: ${theme.radius[radius]};
        ${shadow === 'none' ? `` : `box-shadow: ${theme.shadows[shadow]};`}
        padding: ${theme.spacings[padding]};

      }
    ` :
        `
      border-radius: ${theme.radius[radius]};
      ${shadow === 'none' ? `` : `box-shadow: ${theme.shadows[shadow]};`}
      padding: ${theme.spacings[padding]};
    `
}
  overflow: hidden;
  width: 100%;
  height: ${height};
  display: flex;
  flex-direction: ${direction};
  justify-content: ${justify};
  align-items: ${align};
  text-align: ${textAlign};
  background-color: ${theme.colors[background]};
  ${spacing ? getSpacing(theme, spacing) : ''}
  ${
    backgroundGradient ?
      `background-image: ${theme.gradients[backgroundGradient]}` :
      ''
};
`,
);
