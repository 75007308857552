import {useEffect, useRef, useState} from 'react';

export const useIntersection = (setOnce?: boolean) => {
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting((prev) => {
        if (setOnce && prev) return prev;
        return entry.isIntersecting;
      });
    });
    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [ref.current, setOnce]);

  return {ref, isIntersecting};
};
