import {Teachers} from '@mindfulness/cms';
import {jsonLdScriptProps} from 'react-schemaorg';
import {Organization} from 'schema-dts';
import {Maybe} from '../types/types';

export const homeSchema = () => {
  return jsonLdScriptProps<Organization>({
    '@context': 'https://schema.org',
    ...mindfulnessOrganization,
  });
};

export const breathworksOrganization = {
  '@type': 'Organization',
  'name': 'Breathworks',
  'foundingDate': '2004',
  'url': 'http://breathworks-mindfulness.org.uk/',
  'logo': {
    '@type': 'ImageObject',
    'url': 'https://mindfulness.com/images/breathworks-logo.jpeg',
  },
  'sameAs': [
    'https://soundcloud.com/breathworks-mindfulness',
    'https://www.breathworks-mindfulness.com.au/',
    'https://mobile.twitter.com/breathworksmind',
    'https://www.facebook.com/BreathworksMindfulness/',
    'https://www.linkedin.com/company/breathworks-cic/',
  ],
  'founders': [
    {
      '@type': 'Person',
      'name': 'Vidyamala Burch',
    },
  ],
} as const;

export const mindfulnessOrganization = {
  '@type': 'Organization',
  'name': 'Mindfulness.com',
  'foundingDate': '2014',
  'url': 'https://mindfulness.com',
  'logo': {
    '@type': 'ImageObject',
    'url': 'https://mindfulness.com/images/mcom-logo-horizontal-dark.svg',
  },
  'sameAs': [
    'https://www.facebook.com/Mindfulnesscom/',
    'https://www.instagram.com/mindfulnesscom/',
    'https://twitter.com/mindfulness_com',
    'https://www.youtube.com/channel/UCeUFImBy1I9VbmX6EUjfGrA',
    'https://www.tiktok.com/@mindfulness.com',
  ],
  'founders': [
    {
      '@type': 'Person',
      'name': 'Melli O\'Brien',
    },
    {
      '@type': 'Person',
      'name': 'Matt Dickinson',
    },
  ],
  'contactPoint': {
    '@type': 'ContactPoint',
    'contactType': 'Customer support',
    'email': 'support@mindfulness.com',
  },
} as const;

/**
 * Takes a name of an organisation and returns that organisation's schema.
 * @param {string} name
 * @return {Organization} organisation schema
 */
export function getOrganisation(name: Names): Maybe<Organization> {
  const organisations = {
    'Mindfulness.com': mindfulnessOrganization,
    'Breathworks': breathworksOrganization,
  };
  return organisations[name || 'Mindfulness.com'] || undefined;
}

type Names = Teachers['worksFor'];
