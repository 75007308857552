import * as React from 'react';
import {useMemo} from 'react';
import {switchEnum} from '@mindfulness/utils/logic';
import {justOne} from '@mindfulness/utils/array';
import * as Sentry from '@sentry/nextjs';
import {Maybe, WithWebSlug} from '../../../types/types';
import {Container, Reel} from '../../layout';
import {assertString, groupBy, unNull} from '../../../utils';
import {ItemCard, BundleCard, TopicCard} from '../../ui';

import {TeacherFeedBlock} from '../TeacherFeedBlock';
import {
  BundleFragment,
  CollectionFeedFragment,
  FeatureFeedFragment,
  PosterFeedFragment,
  ProducerFragment,
  QuickLinkFeedFragment,
  SingleDetailFragment,
  TopicFeedFragment,
} from '../../../types/api';
import {CollectionTitle} from '../CollectionTitle';
import {useHydratedCollection} from '../../../hooks/useHydratedCollection';
import {QuickLink} from '../QuickLink';
import {FeatureCard} from '../FeatureCard';
import {NewLink} from '../../global/Link';
import {filterItems} from '../../../utils/version';
import {mapDeeplink} from '../../../utils/mapDeeplink';
import {PosterCard} from '../PosterCard';
import {ErrorFallback} from '../../global/ErrorFallback';
import {useTrack} from '../../global/SegmentProvider';
import {Section} from '../../layouts/Section';

export const CollectionReel: React.FC<
  CollectionFeedFragment & {
    noLinks?: boolean;
    priority?: boolean;
    location?: 'collection';
    index: number;
    preventHydration?: boolean;
    feedId: Maybe<string>;
  }
> = ({
  priority,
  noLinks,
  layout,
  location,
  index,
  preventHydration,
  feedId,
  ...staticCollection
}) => {
  const {collection, loading, ref, isIntersecting} = useHydratedCollection(
      staticCollection,
      preventHydration,
  );

  const [type, bundleType] = React.useMemo(() => {
    const item = justOne(collection?.results?.items);
    const bundleType = item?.__typename === 'Bundle' ? item.bundleType : null;
    return [item?.__typename, bundleType];
  }, [collection?.results.items]);

  const items = React.useMemo(() => {
    if (!collection?.results.items) {
      return [];
    }
    const _items = filterItems(collection?.results.items);
    if (type === 'QuickLink') {
      const pairs = groupBy(_items, 2);
      return pairs;
    }
    return _items;
  }, [collection?.results.items, type]);

  const track = useTrack();
  const defaultEventProps = useMemo(() => ({
    collection_id: collection?.id,
    collection_title: collection?.title,
    feed_id: feedId,
  }), [collection?.id, collection?.title, feedId]);

  return (
    <Sentry.ErrorBoundary
      fallback={(props) => (
        <ErrorFallback
          {...props}
          index={index}
          sectionType={'CollectionReel'}
        />
      )}
    >
      {collection ? (
        <Section ref={ref} background="white" padding={40}>
          <Container>
            <CollectionTitle {...collection} location={location} />
          </Container>
          <Container gutter={{xs: 0, lg: 30}}>
            {!isIntersecting && index > 3 ? <></> : (
            <Reel
              gap={{
                xs: type === 'QuickLink' ? 10 : 8,
                md: type === 'QuickLink' ? 26 : 16,
              }}
              withShadow={type === 'QuickLink'}
              arrowRatio={switchEnum(assertString(type, 'else'), {
                Single: () => 2,
                else: () => undefined,
              })}
              show={switchEnum(assertString(type, 'else'), {
                Topic: () => ({
                  xs: 2.1,
                  md: 3,
                  lg: 4,
                }),
                QuickLink: () => ({
                  xs: 2.4,
                  md: 3,
                  lg: 4,
                }),
                Producer: () => ({
                  xs: 2.1,
                  md: 3,
                  lg: 4,
                }),
                Course: () => ({
                  xs: 1.1,
                  md: 2.2,
                  lg: 2,
                }),
                Bundle: () => {
                  if (bundleType === 'PLAYLIST') {
                    return {
                      xs: 2.2,
                      md: 3.2,
                      lg: 4,
                    };
                  }
                  return {
                    xs: 1.1,
                    md: 2.2,
                    lg: 2,
                  };
                },
                Feature: () => ({
                  xs: 1.1,
                  md: 2.2,
                  lg: 2,
                }),
                Poster: () => ({
                  xs: 2.2,
                  lg: 4,
                }),
                else: () => ({
                  xs: 2.2,
                  md: 3.2,
                  lg: 5,
                }),
              })}
              scroll={switchEnum(assertString(type, 'else'), {
                Producer: () => ({
                  xs: 2,
                  md: 3,
                  lg: 4,
                }),
                Course: () => ({
                  xs: 1,
                  md: 2,
                }),
                Bundle: () => ({
                  xs: 1,
                  md: 2,
                }),
                Feature: () => ({
                  xs: 1,
                  md: 2,
                }),
                Poster: () => ({
                  xs: 1.1,
                  md: 2.2,
                  lg: 4,
                }),
                else: () => ({
                  xs: 2,
                  md: 3,
                  lg: 5,
                }),
              })}
            >
              {items.map((item, index) => {
                return switchEnum(assertString(type, 'else'), {
                  Bundle: () => {
                    const bundle =
                    item as unknown as WithWebSlug<BundleFragment>;
                    const onClick = () => track('Collection item selected', {
                      item_id: bundle.id,
                      item_type: 'BUNDLE',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <BundleCard
                        {...bundle}
                        key={bundle.id}
                        priority={index < 4 && priority}
                        location="reel"
                        layout={unNull(layout)}
                        onClick={onClick}
                      />
                    );
                  },
                  Single: () => {
                    const single = item as unknown as SingleDetailFragment;
                    const onClick = () => track('Collection item selected', {
                      item_id: single.id,
                      item_type: 'SINGLE',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <ItemCard
                        {...single}
                        key={single.id}
                        loading={loading}
                        priority={index < 4 && priority}
                        location="reel"
                        onClick={onClick}
                        href={
                        single.webSlug ?
                          single.webSlug :
                          `/play?s=${single.id}`
                        }
                      />
                    );
                  },
                  Producer: () => {
                    const producer = item as unknown as ProducerFragment;
                    const onClick = () => track('Collection item selected', {
                      item_id: producer.id,
                      item_type: 'BUNDLE',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <TeacherFeedBlock
                        {...producer}
                        key={producer.id}
                        priority={index < 4 && priority}
                        location="reel"
                        onClick={onClick}
                      />
                    );
                  },
                  QuickLink: () => {
                    const quickLinks = item as unknown as [
                      QuickLinkFeedFragment,
                      QuickLinkFeedFragment
                    ];
                    return (
                      <QuickLink
                        quickLinks={quickLinks}
                        key={quickLinks[0].id}
                        defaultEventProps={{
                          item_index: index,
                          ...defaultEventProps,
                        }}
                      />
                    );
                  },
                  Feature: () => {
                    const feature = item as unknown as FeatureFeedFragment;
                    const href = assertString(
                        mapDeeplink(feature.deepLink) || feature.webLink,
                    );
                    const onClick = () => track('Collection item selected', {
                      item_id: feature.id,
                      item_type: 'FEATURE',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <FeatureCard href={href} {...feature} key={feature.id} onClick={onClick} />
                    );
                  },
                  Topic: () => {
                    const topic = item as unknown as TopicFeedFragment;
                    const onClick = () => track('Collection item selected', {
                      item_id: topic.id,
                      item_type: 'TOPIC',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <NewLink href={assertString(mapDeeplink(topic.deepLink) ?? topic.webSlug)} onClick={onClick}>
                        <TopicCard
                          title={topic.title}
                          subtitle={assertString(topic.subtitle)}
                          url={topic.coverImage?.url}
                          key={topic.id}
                        />
                      </NewLink>
                    );
                  },
                  Poster: () => {
                    const poster = item as unknown as PosterFeedFragment;
                    const onClick = () => track('Collection item selected', {
                      item_id: poster.id,
                      item_type: 'FEATURE',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <PosterCard
                        {...poster}
                        href={assertString(
                            mapDeeplink(poster.deepLink) || poster.webLink,
                        )}
                        onClick={onClick}
                        key={poster.id}
                        priority={index < 4 && priority}
                        location="reel"
                      />
                    );
                  },
                  else: null,
                });
              })}
            </Reel>
            )}
          </Container>
        </Section>
      ) : null}
    </Sentry.ErrorBoundary>
  );
};
