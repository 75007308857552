import * as React from 'react';
import {HeroEvent as HeroEventProps} from '@mindfulness/cms';

import {Column, Container, Row, Section} from '../../layout';
import {ResponsiveBackgroundImage, ResponsiveImage} from '../../global';

import {SectionIndex} from '../../../types/types';

export const HeroEvent: React.FC<SectionIndex<HeroEventProps>> = ({
  backgroundImage,
  image,
  width = 'md',
  index,
}) => (
  <Section padding={0} position="relative">
    <ResponsiveBackgroundImage image={backgroundImage} priority={index < 1} />
    <Container maxWidth={width}>
      <Row>
        <Column>
          <ResponsiveImage image={image} priority={index < 1} />
        </Column>
      </Row>
    </Container>
  </Section>
);
