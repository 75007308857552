import {createElement, forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import {Alignment} from '@mindfulness/cms';
import {ResponsiveValue} from '../../../types/types';
import {Theme} from '../../../types/emotion';

import styleUtils from '../../../styles/utils.module.css';
import {getResponsiveClassNames, getResponsiveVariableClassNames, getResponsiveVariables} from '../../../utils/styling';


/**
 * Build the class names for the span property
 * @param {ResponsiveValue<number>} span - The value of the span property potentially an object of responsive values with breakpoints as keys
 * @param {boolean} flex - Whether the parent container is a flex container
 * @return {string[]} - An array of class names
 */
function getSpanClassNames(span: Props['span'], flex: boolean): string[] {
  if (!span) {
    return [
      styleUtils['span'],
      ...(span === 0 ? [] : flex ? [styleUtils['flex']] : [styleUtils['block']]),
    ];
  }
  if (typeof span === 'object') {
    return [
      ...Object.entries(span).map(([key, value]) => {
        return [
          styleUtils[`${key}-span-${value}`],
          ...(value === 0 ? [] : flex ? [styleUtils[`${key}-flex`]] : [styleUtils[`${key}-block`]]),
        ].join(' ');
      }),
    ];
  }

  return [
    styleUtils[`xs-span-${span}`],
    ...(span === 0 ? [] : flex ? [styleUtils['flex']] : [styleUtils['block']]),
  ];
}

export const Column = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>>(({
  children, className,
  as = 'div',
  items, justify, order, span, width, vertical, textAlign, maxWidth, spacing, position, color, minContent,
  ...props
}, ref) => {
  return createElement(as, {
    className: [
      ...getResponsiveClassNames(order, 'order'),
      ...(items ? [styleUtils[`items-${items}`]] : []),
      ...(justify ? [styleUtils[`justify-${justify}`]] : []),
      ...(vertical ? [styleUtils['flex-col']] : []),
      ...(getResponsiveClassNames(textAlign, 'text-align')),
      ...(!width ? getSpanClassNames(span, !!(items || justify)) : []),
      ...(getResponsiveVariableClassNames(width, 'width')),
      ...(maxWidth ? [styleUtils[`max-width-${maxWidth}`]] : []),
      ...(getResponsiveClassNames(spacing, 'mb')),
      ...(position ? [styleUtils[`position-${position}`]] : []),
      ...(color ? [styleUtils[`text-${color}`]] : []),
      ...(minContent ? [styleUtils['min-content']] : []),
      ...(className ? [className] : []),
    ].join(' '),
    style: {
      ...props.style,
      ...getResponsiveVariables(width, 'width'),
    },
    ...props,
    ref,
  }, children);
});

Column.displayName = 'Column';


type Props = {
  as?: keyof JSX.IntrinsicElements;
  items?: 'center' | 'end' | 'start';
  justify?: 'center' | 'end' | 'start';
  order?: ResponsiveValue<number>;
  span?: ResponsiveValue<number>;
  width?: ResponsiveValue<string>;
  maxWidth?: keyof Theme['sizes']['container'];
  textAlign?: ResponsiveValue<Alignment>;
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  position?: 'absolute' | 'relative';
  color?: keyof Theme['colors'];
  minContent?: boolean;
  vertical?: boolean;
}
