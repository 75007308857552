import {AnchorHTMLAttributes, createElement, PropsWithChildren} from 'react';
import style from './ShareDropdown.module.css';

export const ShareOption: React.FC<PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> & Props> = ({children, as = 'a', ...props}) => {
  return createElement(as, {
    className: style.shareOption,
    ...props,
  }, children);
};


type Props = {
  as?: keyof JSX.IntrinsicElements;
}
