import * as React from 'react';
import {captureException} from '@sentry/nextjs';
import {useGoogleLogin as useGoogle} from '@react-oauth/google';

import {Context, Icon} from '../../global';

import {GoogleButton} from './GoogleLogin.styles';

export const GoogleLogin: React.FC<Props> = ({
  size = 'md',
  color = 'default',
  redirect,
  setLoading,
}) => {
  const {handleGoogleLogin} = React.useContext(Context);
  const login = useGoogle({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await handleGoogleLogin?.(
            tokenResponse.code,
            typeof redirect !== 'function',
        );
        // Redirect will be called when we want to verify but not call the page action
        // "Redirect" is a misnomer because it is really just an alternative call back to the page action
        if (typeof redirect === 'function') {
          redirect({email: res?.email || undefined});
          return;
        }
      } catch (e) {
        captureException(e);
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      captureException(error);
      setLoading(false);
    },
    onNonOAuthError: (error) => {
      captureException(error);
      setLoading(false);
    },
    flow: 'auth-code',
  });
  return (
    <GoogleButton
      size={size}
      color={color}
      type="button"
      id="GoogleLogin"
      role="button"
      aria-label="Login with Google"
      onClick={() => {
        setLoading(true);
        login();
      }}
    >
      <Icon name="google" size={20} />
    </GoogleButton>
  );
};

type Props = {
  size?: 'md' | 'sm';
  color?: 'white' | 'default';
  redirect?: (params: { email?: string; name?: string }) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
