import * as React from 'react';
import Head from 'next/head';
import {Theme} from '@emotion/react';
import Link from 'next/link';

import {BreadcrumbItems} from '../../../types/types';
import {breadcrumbSchema} from '../../../schemas';
import {Small} from '../../typography';

import styles from './Breadcrumb.module.css';

export const Breadcrumb: React.FC<{
  items: BreadcrumbItems;
  colour?: keyof Theme['colors'];
}> = ({items, colour = 'primary'}) => (
  <>
    <Small transform="capitalize" colour={colour} spacing={30}>
      {items.map(({title, link}, index) => (
        <React.Fragment key={title}>
          {index ? <span>{' > '}</span> : null}
          {link ? (
            <Link href={link} className={styles.link}>
              {title}
            </Link>
          ) : (
            <span>{title}</span>
          )}
        </React.Fragment>
      ))}
    </Small>
    <Head>
      <script {...breadcrumbSchema({items})} />
    </Head>
  </>
);
