import styled from '@emotion/styled';
import {
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export const AccordionButton = styled(AccordionItemButton)(({theme}) => `
font-size: ${theme.fontSizes.md};
line-height: 1.5;
border-top: 1px ${theme.colors.grey4} solid;
padding-top: 25px;
padding-bottom: 25px;
font-weight: ${theme.fontWeights.bold};
display: flex;
justify-content: space-between;
cursor: pointer;
user-select: none;
`);

export const FAQFooter = styled.footer(({theme}) => `
font-size: ${theme.fontSizes.md};
line-height: 1.5;
border-top: 1px ${theme.colors.grey4} solid;
padding-top: 25px;
padding-bottom: 25px;
font-weight: ${theme.fontWeights.bold};
display: flex;
justify-content: space-between;
cursor: pointer;
user-select: none;
`);

export const AccordionPanel = styled(AccordionItemPanel)(({theme}) => `
  padding-bottom: 25px;
  margin-top: -10px;
`);

export const HelpCenterLink = styled.a(({theme}) => `
  color: ${theme.colors.primary};
  display: inline-flex;
  align-items: center;
  font-size: ${theme.fontSizes.base};
  white-space: nowrap;

  span {
    margin-right: 15px;
  }
`);
