import React, {useContext} from 'react';
import {PropsWithChildren} from 'react';
import {switchEnum} from '@mindfulness/utils/logic';
import {justOne} from '@mindfulness/utils/array';
import {take} from 'lodash/fp';
import * as Sentry from '@sentry/nextjs';

import {SingleListItemContainer} from './CollectionList.styles';

import {
  BundleFeedFragment,
  CollectionFeedFragment,
  Maybe,
  ProducerFeedFragment,
  QuickLinkFeedFragment,
  SingleFeedFragment,
  TopicFeedFragment,
} from '../../../types/api';
import {CollectionTitle} from '../CollectionTitle';
import {assertString, labelSplits} from '../../../utils/string';
import {APIImage, Context, ErrorFallback, Icon, NewLink} from '../../global';
import {Caption1, Small, Title3, Text} from '../../typography';
import {useContributorNames} from '../../../hooks/useContributorNames';
import {SingleShare} from '../SingleShare';
import {WithLinkProps, withLink} from '../../../hoc/withLink';
import {useHydratedCollection} from '../../../hooks/useHydratedCollection';
import {Box, Column, Section, Container, Stack, Row} from '../../layout';
import {filterItems} from '../../../utils/version';
import {mapDeeplink} from '../../../utils/mapDeeplink';
import {useTrack} from '../../global/SegmentProvider';

export const CollectionList: React.FC<
  CollectionFeedFragment & {
    bundleId?: string;
    count?: number;
    location?: 'collection';
    index: number;
    feedId: Maybe<string>;
  }
> = ({count, location, bundleId, index, feedId, ...staticCollection}) => {
  const {collection, loading, ref, isIntersecting} = useHydratedCollection(staticCollection);
  const [type, bundleType] = React.useMemo(() => {
    const item = justOne(collection?.results?.items);
    const bundleType = item?.__typename === 'Bundle' ? item.bundleType : null;
    return [item?.__typename, bundleType];
  }, [collection?.results.items]);
  const track = useTrack();
  const items = React.useMemo(() => {
    if (!collection?.results.items) {
      return [];
    }
    if (count) {
      return take(count, collection.results.items);
    }

    return collection.results.items;
  }, [collection?.results.items, count]);

  const defaultEventProps = {
    collection_id: collection?.id,
    collection_title: collection?.title,
    feed_id: feedId,
  };


  return (
    <Sentry.ErrorBoundary
      fallback={(props) => (
        <ErrorFallback
          {...props}
          index={index}
          sectionType={'CollectionList'}
        />
      )}
    >
      {collection ? (
        <Section ref={ref} background="white" padding={40}>
          <Container>
            <CollectionTitle {...collection} location={location} />
            {loading ? (
              <>
                {filterItems(items).map((item) => {
                  const i = item as BundleFeedFragment;
                  return (
                    <LoadingListItem key={i.id} />
                  );
                })}
              </>
            ) : !isIntersecting && index > 3 ? null : (
              <>
                {filterItems(items).map((item, index) => {
                  return switchEnum(assertString(type, 'else'), {
                    Bundle: () => {
                      const bundle = item as BundleFeedFragment;

                      if (bundleType === 'PLAYLIST') {
                        return <PlaylistListItem {...bundle} key={bundle.id} />;
                      }
                      if (
                        [
                          'TEACHER_BUNDLE',
                          'MASTERCLASS',
                          'IMMERSION',
                          'EVERGREEN_CHALLENGE',
                          'DAILY_CHALLENGE',
                        ].includes(assertString(bundleType))
                      ) {
                        const onClick = () => {
                          track('Collection item selected', {
                            item_id: bundle.id,
                            item_type: 'BUNDLE',
                            item_index: index,
                            ...defaultEventProps,
                          });
                        };
                        return <CourseListItem {...bundle} onClick={onClick} key={bundle.id} />;
                      }
                    },
                    Single: () => {
                      const single = item as SingleFeedFragment;
                      const onClick = () => {
                        track('Collection item selected', {
                          item_id: single.id,
                          item_type: 'SINGLE',
                          item_index: index,
                          ...defaultEventProps,
                        });
                      };
                      return (
                        <SingleListItem
                          bundleId={bundleId}
                          {...single}
                          onClick={onClick}
                          key={single.id}
                        />
                      );
                    },
                    Producer: () => {
                      const producer = item as ProducerFeedFragment;
                      const onClick = () => {
                        track('Collection item selected', {
                          item_id: producer.id,
                          item_type: 'PRODUCER',
                          item_index: index,
                          ...defaultEventProps,
                        });
                      };
                      return <ProducerListItem onClick={onClick} {...producer} />;
                    },
                    QuickLink: () => {
                      const quickLink = item as QuickLinkFeedFragment;
                      const onClick = () => {
                        track('Collection item selected', {
                          item_id: quickLink.id,
                          item_type: 'QUICK_LINK',
                          item_index: index,
                          ...defaultEventProps,
                        });
                      };
                      return (
                        <QuickLinkListItem
                          href={assertString(
                              mapDeeplink(quickLink.deepLink) || quickLink.webLink,
                          )}
                          onClick={onClick}
                          {...quickLink}
                          key={quickLink.id}
                        />
                      );
                    },
                    Topic: () => {
                      const topic = item as TopicFeedFragment;
                      const onClick = () => {
                        track('Collection item selected', {
                          item_id: topic.id,
                          item_type: 'TOPIC',
                          item_index: index,
                          ...defaultEventProps,
                        });
                      };
                      return (
                        <TopicListItem
                          href={assertString(mapDeeplink(topic.deepLink) || topic.webSlug)}
                          onClick={onClick}
                          {...topic}
                          key={topic.id}
                        />
                      );
                    },
                    else: null,
                  });
                })}
              </>
            )}

          </Container>
        </Section>
      ) : null}
    </Sentry.ErrorBoundary>
  );
};

const TopicListItem: React.FC<
  PropsWithChildren<WithLinkProps & TopicFeedFragment>
> = withLink(({inlineTitle}) => (
  <SingleListItemContainer>
    <Row gap={15}>
      <Column span={21} items="center">
        <Title3 weight="bold" transform="capitalize" className="list-title">
          {inlineTitle}
        </Title3>
      </Column>
      <Column span={3} items="center" justify="end">
        <Icon name="arrow" colour="grey5" />
      </Column>
    </Row>
  </SingleListItemContainer>
));

const QuickLinkListItem: React.FC<
  PropsWithChildren<WithLinkProps & QuickLinkFeedFragment>
> = withLink(({quickLinkTitle}) => {
  return (
    <SingleListItemContainer>
      <Row gap={15}>
        <Column span={21} items="center">
          <Title3 weight="bold" className="list-title">{quickLinkTitle}</Title3>
        </Column>
        <Column span={3} items="center" justify="end">
          <Icon name="arrow" colour="grey5" />
        </Column>
      </Row>
    </SingleListItemContainer>
  );
});

const ProducerListItem: React.FC<PropsWithChildren<ProducerFeedFragment & {
  onClick: () => void
}>> = ({
  webSlug,
  cellImage,
  fullName,
  onClick,
}) => (
  <NewLink href={assertString(webSlug)} onClick={onClick}>
    <SingleListItemContainer>
      <Row gap={15}>
        <Column span={{xs: 24, lg: 0}} items="center">
          <Stack space={15} items="center" wrap="nowrap">
            <Box>
              <APIImage
                width={64}
                height={64}
                url={cellImage?.url}
                className="cell-image"
              />
            </Box>
            <Stack direction="vertical">
              <Text fontWeight="bold" className="list-title">
                {fullName}
              </Text>
            </Stack>
          </Stack>
        </Column>
        <Column span={{xs: 0, lg: 24}} items="center">
          <Stack space={15} items="center" wrap="nowrap">
            <APIImage
              width={64}
              height={64}
              url={cellImage?.url}
              className="cell-image"
            />
            <Title3 weight="bold" className="list-title">
              {fullName}
            </Title3>
          </Stack>
        </Column>
      </Row>
    </SingleListItemContainer>
  </NewLink>
);

const CourseListItem: React.FC<PropsWithChildren<BundleFeedFragment & {
  onClick: () => void
}>> = ({
  onClick,
  ...bundle
}) => {
  const {
    cellImage,
    cardImage,
    coverImage,
    title,
    id,
    label,
    curator,
    bundleType,
    webSlug,
  } = bundle;
  const {session} = useContext(Context);
  const href = session ? `/play?b=${id}` : webSlug || `/play?b=${id}`;
  const [type, tracks] = labelSplits(label);
  return (
    <NewLink href={href} onClick={onClick}>
      <SingleListItemContainer>
        <Row gap={15}>
          <Column span={{xs: 24, lg: 0}} items="center">
            <Stack space={15} items="center" wrap="nowrap">
              <Box>
                <APIImage
                  width={64}
                  height={64}
                  className="cell-image"
                  url={
                    bundleType === 'PLAYLIST' ?
                      cardImage?.url || cellImage?.url || coverImage?.url :
                      bundleType.includes('CHALLENGE') ?
                      cardImage?.url || cellImage?.url || coverImage?.url :
                      cellImage?.url
                  }
                />
              </Box>
              <Stack direction="vertical">
                <Caption1>{curator?.fullName}</Caption1>
                <Text fontWeight="bold" className="list-title">
                  {title}
                </Text>
                <Small
                  fontSize="xs"
                  colour="grey5"
                  transform="uppercase"
                  fontWeight="bold"
                >
                  {label}
                </Small>
              </Stack>
            </Stack>
          </Column>
          <Column span={{xs: 0, lg: 12}} items="center">
            <Stack space={15} items="center" wrap="nowrap">
              <APIImage
                width={64}
                height={64}
                className="cell-image"
                url={
                  bundleType === 'PLAYLIST' ?
                    cardImage?.url || cellImage?.url || coverImage?.url :
                    bundleType.includes('CHALLENGE') ?
                    cardImage?.url || cellImage?.url || coverImage?.url :
                    cellImage?.url
                }
              />
              <Title3 weight="bold" className="list-title">
                {title}
              </Title3>
            </Stack>
          </Column>
          <Column span={{xs: 0, lg: 3}} items="center">
            <Caption1 transform="uppercase">{type}</Caption1>
          </Column>
          <Column span={{xs: 0, lg: 3}} items="center" justify="center">
            <Caption1>{curator?.fullName}</Caption1>
          </Column>
          <Column span={{xs: 0, lg: 3}} items="center" justify="center">
            <Small
              fontSize="xs"
              colour="grey5"
              transform="uppercase"
              fontWeight="bold"
            >
              {tracks}
            </Small>
          </Column>
          <Column span={{xs: 0, lg: 3}} items="center" justify="end">
            <SingleShare
              bundle={bundle}
              colour="grey5"
            />
          </Column>
        </Row>
      </SingleListItemContainer>
    </NewLink>
  );
};

const LoadingListItem: React.FC = () => {
  return (
    <SingleListItemContainer>
      <Row gap={15}>
        <Column span={{xs: 24, lg: 0}} items="center">
          <Stack space={15} items="center" wrap="nowrap">
            <Box width={'64px'} height={'64px'} background='grey9' radius={'lg'} />
            <Stack direction="vertical">
              <Caption1>&nbsp;</Caption1>
              <Text fontWeight="bold" className="list-title">
                Loading...
              </Text>
              <Small
                fontSize="2xs"
                colour="grey7"
                transform="uppercase"
                fontWeight="bold"
              >
                &nbsp;
              </Small>
            </Stack>
          </Stack>
        </Column>
        <Column span={{xs: 0, lg: 12}} items="center">
          <Stack space={15} items="center" wrap="nowrap">
            <Box width={'64px'} height={'64px'} background='grey9' radius={'lg'} />
            <Title3 weight="bold" className="list-title">
              Loading...
            </Title3>
          </Stack>
        </Column>
        <Column span={{xs: 0, lg: 3}} items="center">
          <Caption1 transform="uppercase">&nbsp;</Caption1>
        </Column>
        <Column span={{xs: 0, lg: 3}} items="center" justify="center">
          <Caption1>&nbsp;</Caption1>
        </Column>
        <Column span={{xs: 0, lg: 3}} items="center" justify="center">
          <Small
            fontSize="xs"
            colour="grey5"
            transform="uppercase"
            fontWeight="bold"
          >
            &nbsp;
          </Small>
        </Column>
        <Column span={{xs: 0, lg: 3}} items="center" justify="end">

        </Column>
      </Row>
    </SingleListItemContainer>
  );
};

const PlaylistListItem: React.FC<PropsWithChildren<BundleFeedFragment>> = (
    bundle,
) => {
  const {cellImage, title, id, label, webSlug} = bundle;
  const [type, tracks] = labelSplits(label);
  const {session} = useContext(Context);
  const href = session ? `/play?b=${id}` : webSlug || `/play?b=${id}`;
  return (
    <NewLink href={href}>
      <SingleListItemContainer>
        <Row gap={15}>
          <Column
            span={{
              xs: 24,
              lg: 0,
            }}
          >
            <Stack space={15} items="center" wrap="nowrap">
              <APIImage
                className="cell-image"
                width={64}
                height={64}
                url={cellImage?.url}
              />
              <Stack direction="vertical">
                <Text fontWeight="bold">{title}</Text>
                <Small
                  fontSize="2xs"
                  colour="grey7"
                  transform="uppercase"
                  fontWeight="bold"
                >
                  {bundle.label}
                </Small>
              </Stack>
            </Stack>
          </Column>
          <Column span={{xs: 0, lg: 15}} items="center">
            <Stack space={15} items="center" wrap="nowrap">
              <APIImage
                width={64}
                height={64}
                url={cellImage?.url}
                className="cell-image"
              />
              <Title3 className="list-title" weight="bold">
                {title}
              </Title3>
            </Stack>
          </Column>
          <Column
            span={{
              xs: 0,
              lg: 3,
            }}
            items="center"
            justify="center"
          >
            <Caption1 transform="uppercase">{type}</Caption1>
          </Column>
          <Column
            span={{
              xs: 0,
              lg: 3,
            }}
            items="center"
            justify="center"
          >
            <Small
              fontSize="xs"
              colour="grey5"
              transform="uppercase"
              fontWeight="bold"
            >
              {tracks}
            </Small>
          </Column>
          <Column
            span={{
              xs: 0,
              lg: 3,
            }}
            items="center"
            justify="end"
          >
            <SingleShare
              bundle={bundle}
              colour="grey5"
            />
          </Column>
        </Row>
      </SingleListItemContainer>
    </NewLink>
  );
};

const SingleListItem: React.FC<
  PropsWithChildren<SingleFeedFragment & { bundleId?: string, onClick: () => void }>
> = ({bundleId, onClick, ...single}) => {
  const {cellImage, title, webSlug, label, contributors, id} = single;
  const contributorNames = useContributorNames(contributors);
  const [type, time] = labelSplits(label);

  return (
    <NewLink
      onClick={onClick}
      href={
        webSlug ? webSlug : `/play?s=${id}${bundleId ? `&b=${bundleId}` : ''}`
      }
    >
      <SingleListItemContainer>
        <Row gap={15}>
          <Column span={{xs: 24, lg: 0}} items="center">
            <Stack space={15} items="center" wrap="nowrap">
              <APIImage
                width={64}
                height={64}
                url={cellImage?.url}
                className="cell-image"
                portrait={single.type === 'MOMENT'}
              />
              <Stack direction="vertical">
                <Caption1>{contributorNames}</Caption1>
                <Text fontWeight="bold" className="list-title">
                  {title}
                </Text>
                <Small
                  fontSize="2xs"
                  colour="grey7"
                  transform="uppercase"
                  fontWeight="bold"
                >
                  {single.label}
                </Small>
              </Stack>
            </Stack>
          </Column>
          <Column span={{xs: 0, lg: 12}} items="center">
            <Stack space={15} items="center" wrap="nowrap">
              <APIImage
                width={64}
                height={64}
                url={cellImage?.url}
                className="cell-image"
              />
              <Title3 weight="bold" className="list-title">
                {title}
              </Title3>
            </Stack>
          </Column>
          <Column span={{xs: 0, lg: 3}} items="center">
            <Caption1 transform="uppercase">{type}</Caption1>
          </Column>
          <Column span={{xs: 0, lg: 3}} items="center" justify="center">
            <Caption1>{contributorNames}</Caption1>
          </Column>
          <Column span={{xs: 0, lg: 3}} items="center" justify="center">
            <Small
              fontSize="xs"
              colour="grey5"
              transform="uppercase"
              fontWeight="bold"
            >
              {time}
            </Small>
          </Column>
          <Column span={{xs: 0, lg: 3}} items="center" justify="end">
            <SingleShare
              single={single}
              colour="grey5"
            />
          </Column>
        </Row>
      </SingleListItemContainer>
    </NewLink>
  );
};
