import * as React from 'react';
import {Divider as Props} from '@mindfulness/cms';

import {SectionIndex} from '../../../types/types';
import {Container, Section} from '../../layout';
import {Divider as DividerLine} from '../../ui';

export const Divider: React.FC<SectionIndex<Props>> = ({
  background,
  paddingTop,
  paddingBottom,
}) => (
  <Section
    background={background}
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
  >
    <Container maxWidth="lg">
      <DividerLine />
    </Container>
  </Section>
);
