import * as React from 'react';
import {ContactForm as ContactFormProps} from '@mindfulness/cms';

import {H2, Text} from '../../typography';
import {Container, Row, Column, Section} from '../../layout';
import {SectionIndex} from '../../../types/types';
import {Alert, Button, Input, Textarea, Checkbox} from '../../forms';
import {useForm} from '../../../hooks';
import {Context, ToastContext} from '../../global';

export const ContactForm: React.FC<SectionIndex<ContactFormProps>> = ({
  title,
  buttonText = 'Send',
  index,
}) => {
  const {data: pageData, session} = React.useContext(Context);
  const {handleAdd} = React.useContext(ToastContext);

  const {
    data,
    handleChange,
    handleToggle,
    handleSubmit,
    loading,
    errors,
    setValue,
  } = useForm<{
    email: string;
    name: string;
    message: string;
    subscribe: boolean;
  }>({
    initialValues: {
      email: '',
      name: '',
      message: '',
      subscribe: true,
    },
    onSubmit: async (e, {data, setErrors, resetData}) => {
      try {
        const res = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formName: pageData?.title,
            ...data,
          }),
        });

        await res.json();

        handleAdd?.(<Text>{`✅ Your message was successfully sent`}</Text>);
        resetData();
      } catch (e) {
        console.error(e);
        setErrors({
          form: 'We were unable to send your message at this time please contact us at support@mindfulness.com',
        });
      }
    },
    validations: {
      email: {
        required: {
          value: true,
          message: 'Please enter your email address',
        },
      },
      name: {
        required: {
          value: true,
          message: 'Please enter your name',
        },
      },
      message: {
        required: {
          value: true,
          message: 'Please enter a message',
        },
      },
    },
  });

  React.useEffect(() => {
    if (session?.email) {
      setValue('email', session.email);
    }
    if (session?.fullName) {
      setValue('name', session.fullName);
    } else if (session?.firstName) {
      setValue('name', session.firstName);
    }
  }, [session]);

  return (
    <Section>
      <Container maxWidth="sm">
        <Row as="form" onSubmit={handleSubmit}>
          {title ? (
            <Column spacing={34}>
              <H2 as={index === 0 ? undefined : 'h2'}>{title}</H2>
            </Column>
          ) : null}
          <Alert error={errors?.form} />
          <Column spacing={16}>
            <Input
              placeholder="Enter your name"
              label="Name"
              onChange={handleChange('name')}
              error={errors?.name}
              value={data?.name}
            />
          </Column>
          <Column spacing={16}>
            <Input
              placeholder="Enter your email address"
              label="Email"
              type="email"
              onChange={handleChange('email')}
              error={errors?.email}
              value={data?.email}
            />
          </Column>
          <Column spacing={30}>
            <Textarea
              placeholder="How can we help?"
              label="Message"
              onChange={handleChange('message')}
              error={errors?.message}
              value={data?.message}
            />
          </Column>
          <Column spacing={30} textAlign="center">
            <Checkbox
              checked={data?.subscribe}
              error={errors?.subscribe}
              onChange={handleToggle('subscribe')}
              label="Yes! I would like to receive news and special offers from Mindfulness.com"
            />
          </Column>
          <Column>
            <Button wide id="ContactForm" disabled={loading}>
              {buttonText}
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};
