import * as React from 'react';
import {throttle} from 'lodash';
import dynamic from 'next/dynamic';
import {Theme} from '@emotion/react';

import {ResponsiveValue} from '../../../types/types';
import {useBreakpoint} from '../../../hooks';
import {reduceObject} from '../../../utils';

import {ReelTrack} from './Reel.styles';
import styleUtil from '../../../styles/utils.module.css';
import style from './Reel.module.css';

const ReelButton = dynamic(
    () => import('./ReelButton').then(({ReelButton}) => ReelButton),
    {
      ssr: false,
    },
);

export const Reel: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  show,
  scroll,
  center,
  gap,
  hideArrows,
  Spacer,
  EndSpacer,
  withShadow,
  arrowRatio,
}) => {
  const [dragging, setDragging] = React.useState<boolean>(false);
  const [arrows, setArrows] = React.useState<'both' | 'left' | 'right'>(
      'right',
  );
  const reelRef = React.useRef<HTMLDivElement>(null);
  const {getResponsiveValue, breakpoint} = useBreakpoint();
  const responsiveShow = React.useMemo(
      () => getResponsiveValue(show),
      [show, breakpoint],
  );

  const responsiveScroll = React.useMemo(
      () => getResponsiveValue(scroll),
      [scroll, getResponsiveValue, breakpoint],
  );

  const throttledHandleScroll = React.useMemo(
      () =>
        throttle((e) => {
          setArrowVisibilty(e.target as HTMLDivElement);
        }, 300),
      [],
  );

  const setArrowVisibilty = React.useCallback((target: HTMLDivElement) => {
    if (target.scrollLeft === target.scrollWidth - target.clientWidth) {
      setArrows('left');
    } else if (target.scrollLeft < 10) {
      setArrows('right');
    } else {
      setArrows('both');
    }
  }, []);

  React.useEffect(() => {
    if (reelRef.current) {
      const {current} = reelRef;
      current.addEventListener('scroll', throttledHandleScroll);
      setArrowVisibilty(current);
    }
    return () => {
      reelRef.current?.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [reelRef?.current, throttledHandleScroll]);

  const handleScroll = React.useCallback(
      (dir: 'left' | 'right') => {
        if (reelRef.current && responsiveShow && responsiveScroll) {
          const left = dir === 'left';
          const {current} = reelRef;
          const oneTile = current.clientWidth / responsiveShow;
          const distance = oneTile * responsiveScroll;
          current.scrollBy({
            left: left ? distance * -1 : distance,
            behavior: 'smooth',
          });
        }
      },
      [reelRef, responsiveShow, responsiveScroll],
  );

  const span = React.useMemo(() => {
    if (typeof show === 'object') {
      return reduceObject(show, (val) => 24 / val);
    }
    return 24 / show;
  }, [show]);

  const arrowTop = (() => {
    if (!arrowRatio || !reelRef.current) return;

    const width = reelRef.current.clientWidth / responsiveShow;
    return width / arrowRatio;
  })();

  return (
    <div className={styleUtil['position-relative']}>
      <ReelButton
        top={arrowTop}
        visible={
          !hideArrows &&
          React.Children.count(children) > responsiveShow &&
          arrows !== 'right'
        }
        direction={'left'}
        onClick={() => handleScroll('left')}
      />

      <ReelTrack
        withShadow={!!withShadow}
        gap={gap}
        // center={center && React.Children.count(children) < responsiveShow}
        dragging={dragging}
        onMouseMove={(e) => {
          if (e.buttons > 0) {
            setDragging(true);
            const current = reelRef.current;
            if (current) {
              current.scrollLeft -= e.movementX;
            }
            return;
          }
          setDragging(false);
        }}
        ref={reelRef}
      >
        {Spacer ? <Spacer /> : <ReelSpacer />}
        {React.Children.map(children, (child) => (
          <div
            className={[
              style.reelItem,
              ...Object.keys(span).map((key) => style[`${key}-span`]),
            ].join(' ')}
            style={{
              ...Object.entries(span).reduce((acc, [key, value]) => ({
                ...acc,
                [`--${key}-span`]: `${(value / 24) * 100}%`,
              }), {}),
            }}
          >{child}</div>
        ))}
        {EndSpacer ? <EndSpacer /> : <ReelSpacer />}
      </ReelTrack>
      <ReelButton
        top={arrowTop}
        visible={
          !hideArrows &&
          React.Children.count(children) > responsiveShow &&
          arrows !== 'left'
        }
        onClick={() => handleScroll('right')}
        direction={'right'}
      />
    </div>
  );
};


const ReelSpacer: React.FC = () => <div className={style.reelSpacer} />;

type Props = {
  show: ResponsiveValue<number>;
  scroll: ResponsiveValue<number>;
  center?: boolean;
  Spacer?: React.FC;
  EndSpacer?: React.FC;
  hideArrows?: boolean;
  withShadow?: boolean;
  gap?: ResponsiveValue<keyof Theme['spacings']>;
  arrowRatio?: number;
};
