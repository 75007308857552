import styled from '@emotion/styled';
import {ItemLocation} from '../../../types/types';

export const ItemContainer = styled.div<{ location?: ItemLocation }>(
    ({theme, location = 'reel'}) => `
${
  location === 'reel' ?
    `
    flex-shrink: 0;
  ` :
    `
  `
}
display: block;
cursor: pointer;

.cell-image {
  transition: transform 0.3s ease;
  will-change: transform;
}
&:hover {
  .cell-image {
    transform: scale(1.1);
  }
}
`,
);

export const PlayButtonCircle = styled.div(
    ({theme}) => `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${theme.colors.grey9}b3;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`,
);
