import * as React from 'react';
import dynamic from 'next/dynamic';

import {FooterPolicy, StyledFooter, VerticalDivider} from './Footer.styles';

import {Context} from '../../global';
import {Column, Container, Stack, Row, Box} from '../../layout';
import {H2, H4, Small} from '../../typography';
import {
  EnrichedPages,
  ReferencedFooterNavigation,
} from '../../../types/types';
import {DownloadLinks, InlineActionForm} from '../../ui';
import {InlineLoginButtons} from '../../ui/InlineActionForm/InlineLoginButtons';
import {MenuItems} from './MenuItems';
import {SocialLinks} from './SocialLinks';
import {useIntersection} from '../../../hooks/useIntersection';

const FooterAccordion = dynamic(
    () =>
      import('./FooterAccordion').then(({FooterAccordion}) => FooterAccordion),
    {
      ssr: false,
    },
);

export const Footer: React.FC<Props> = ({navigation}) => {
  const {data, session} = React.useContext(Context);
  const page = data as EnrichedPages;
  const {ref, isIntersecting} = useIntersection(true);
  return (
    <>
      {!page?.footer?.hideFooterNav ? (
        <StyledFooter ref={ref}>
          {isIntersecting ? (
            <>
              {!session ? (
              <Box
                marginY={{
                  xs: 40,
                  lg: 0,
                }}
              >
                <Container>
                  <Row spacing={64} gap={26}>
                    <Column
                      spacing={{
                        xs: 26,
                        lg: 0,
                      }}
                      span={{
                        xs: 24,
                        lg: 8,
                      }}
                    >
                      <H2 as="p">Get started today</H2>
                      <Small>Start your 7-day free trial</Small>
                    </Column>
                    <Column
                      span={{
                        xs: 24,
                        lg: 8,
                      }}
                    >
                      <InlineActionForm
                        actionType="pageAction"
                        id="Footer"
                        hideButtons
                      >
                        Join Free
                      </InlineActionForm>
                    </Column>
                    <Column
                      span={{
                        xs: 24,
                        lg: 8,
                      }}
                    >
                      <Box
                        spacing={10}
                        display={{
                          xs: 'block',
                          lg: 'none',
                        }}
                      >
                        <Small fontSize="xs">Or sign up with</Small>
                      </Box>
                      <InlineLoginButtons color="white" />
                    </Column>
                  </Row>
                </Container>
              </Box>
            ) : null}
              <Container
                as="nav"
                role="navigation"
                aria-label="Footer navigation"
                gutter={{
                  xs: 0,
                  md: 16,
                }}
              >
                <Row gap={16} hide={['xs', 'md']}>
                  {navigation?.linklist?.map(({title, links, _key}) => (
                    <Column span={6} key={_key}>
                      <H4 weight="medium" spacing={20}>
                        {title}
                      </H4>
                      {links?.length ? (
                    <Stack direction="vertical" space={12} colour="grey7">
                      {links.map((props) => (
                        <MenuItems {...props} key={props._key} />
                      ))}
                    </Stack>
                  ) : null}
                    </Column>
                  ))}
                  <Column span={6}>
                    <Box spacing={30}>
                      <H4 weight="medium" spacing={10}>
                    Follow us
                      </H4>
                      <SocialLinks />
                    </Box>
                    <Box>
                      <H4 weight="medium" spacing={10}>
                    Get the App
                      </H4>
                      <DownloadLinks hideTitle />
                    </Box>
                  </Column>
                </Row>
                <Row hide={['md', 'xxl']}>
                  <Column>
                    <FooterAccordion navigation={navigation} />
                  </Column>
                </Row>
              </Container>
            </>
          ) : null}
        </StyledFooter>
      ) : null}
      {!page?.footer?.hideFooter ? (
        <FooterPolicy>
          <Container as="nav">
            <Stack space={16} justify="center">
              <Small wrap="nowrap">
                Mindfulness.com &nbsp; - &nbsp; All rights reserved
              </Small>
              <VerticalDivider />
              <Small wrap="nowrap">
                <a target="_blank" href="/terms-and-conditions">
                  Terms &amp; Conditions
                </a>
                <span> - </span>
                <a target="_blank" href="/privacy-policy">
                  Privacy Policy
                </a>
              </Small>
            </Stack>
          </Container>
        </FooterPolicy>
      ) : null}
    </>
  );
};

type Props = {
  navigation: ReferencedFooterNavigation;
};
