import React from 'react';
import {SingleFeedFragment} from '../types/api';

export const useContributorNames = (
    contributors: SingleFeedFragment['contributors'],
): string => {
  const names = React.useMemo(
      () =>
        (contributors || []).map(({producer}) => producer.fullName).join(' & '),
      [contributors],
  );
  return names;
};
