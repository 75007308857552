import {useContext, useCallback, useMemo} from 'react';
import {ModalContext, ModalParams} from '../components/global';

export const useModal = (name: string, onClose?: () => void) => {
  const {openModal, closeModal, activeProps} = useContext(ModalContext);
  const props = useMemo(() => activeProps[name], [activeProps, name]);

  const _openModal = useCallback(
      (params?: ModalParams) => {
        return openModal?.({
          name,
          ...params,
        });
      },
      [name],
  );

  const _closeModal = useCallback(
      (params?: ModalParams) => {
        closeModal?.({
          name,
          ...params,
        }),
        onClose?.();
      },
      [name, onClose],
  );

  return {
    open: _openModal,
    close: _closeModal,
    props,
  };
};
