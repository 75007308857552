import styled from '@emotion/styled';
import {H3} from '../../typography/Headings';
import {getFontSize} from '../../../utils';

export const QuickLinkCard = styled.div(
    ({theme}) => `
  user-select: none;
  background-color: ${theme.colors.greyWarm};
  border-radius: ${theme.radius.xl};
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  text-align: left;
  border: solid 2px ${theme.colors.greyWarm};
  transition: border 0.2s ease;
  padding: ${theme.spacings[12]};
  @media (min-width: ${theme.breakpoints.md.min}) {
    padding: ${theme.spacings[20]};
  }

  h3, p {
  transition: color 0.2s ease;

  }

  &:hover {
    border: solid 2px ${theme.colors.primary};

    h3, p {
      color: ${theme.colors.primary};
    }

  }
`,
);

export const QuickLinkTitle = styled(H3)(
    ({theme}) => `
${getFontSize(theme, {xs: 'base'})}
`,
);
