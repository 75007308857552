import styled from '@emotion/styled';

export const AppleButton = styled.button<{
  size: 'md' | 'sm';
  color: 'white' | 'default';
}>(
    ({theme, size, color}) => `
  cursor: pointer;
  outline: none;
  transition: ease 0.2s all;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  height: 2.8125rem;
  border-radius: 100px;

  ${
    color === 'default' ?
      `
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
  ` :
      `
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.grey7};
      color: #000;
      `
}
  ${
    size === 'md' ?
      `
    width: 100%;
  ` :
      `
      width: 84px;
      `
}
`,
);
