import {useRecoilState} from 'recoil';
import {Theme} from '@emotion/react';
import {when} from '@mindfulness/utils/maybe';

import {playerTranscripts} from '../../../state/atoms';
import {useTrack} from '../../global/SegmentProvider';
import {Box} from '../../layout';
import {Icon} from '../../global';
import {SingleFeedFragment} from '../../../types/api';
import {NormalizedButton} from '../NormalizedButton';

export const TranscriptsButton: React.FC<Props> = ({single, size}) => {
  const [transcripts, setTranscripts] = useRecoilState(playerTranscripts);
  const track = useTrack();

  return (
    <NormalizedButton
      width="auto"
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setTranscripts((prev) => !prev);
        track('Button clicked', {
          id: 'player.subtitles_toggled',
          ...(when(single, (s) => ({
            single_id: s.id,
            single_title: s.title,
            single_type: s.type,
          }))),

        });
      }}
    >
      <Box marginT={3}>
        <Icon name="subtitles" colour={transcripts ? 'primary' : 'white'} size={size} />
      </Box>
    </NormalizedButton>
  );
};

type Props = {
  single?: SingleFeedFragment;
  size?: keyof Theme['spacings'];
}
