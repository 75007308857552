import * as React from 'react';
import {HeroHub as HeroHubProps} from '@mindfulness/cms';

import {Container, Row, Column, Section} from '../../layout';
import {H1} from '../../typography';
import {
  BlockContent,
  ButtonLink,
  CallToActionButton,
  Context,
} from '../../global';
import {SectionIndex, EnrichedPages} from '../../../types/types';
import {assertString} from '../../../utils';

export const HeroHub: React.FC<SectionIndex<HeroHubProps>> = ({
  paragraph,
  paddingTop,
  paddingBottom,
  index,
  cta,
}) => {
  const {data} = React.useContext(Context);
  const page = data as EnrichedPages;

  return page ? (
    <Section
      padding={80}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container maxWidth="prose">
        <Row>
          <Column textAlign="center">
            <H1
              as={index === 0 ? undefined : 'h2'}
              spacing={paragraph ? 16 : undefined}
              size="xmega"
            >
              {assertString(page.title)}
            </H1>
            {paragraph ? (
              <Row spacing={30}>
                <Column>
                  <BlockContent content={paragraph} background="white" />
                </Column>
              </Row>
            ) : null}
            {cta?.actionType ? (
              <CallToActionButton {...cta} id={`HeroHub-${index}-cta`}>
                {cta?.text || 'Try for Free'}
              </CallToActionButton>
            ) : (page.topic?.id || page.feed) && page.slug?.current ? (
              <ButtonLink
                href={`${page.slug.current}/browse`}
                id={`HeroHub-${index}`}
                variant="cta"
              >
                {`Browse ${page.title} Library`}
              </ButtonLink>
            ) : null}
          </Column>
        </Row>
      </Container>
    </Section>
  ) : null;
};
