import * as React from 'react';
import {useRouter} from 'next/router';
import {Maybe} from '@mindfulness/utils/maybe';
import {toggle} from '@mindfulness/utils/boolean';

import {InfoButton, StyledCheckbox} from './Checkbox.styled';

import {Box, Stack, Tooltip} from '../../layout';
import {Label, Small} from '../../typography';
import {slugify} from '../../../utils';
import {useOutsideAlerter} from '../../../hooks';
import {Lottie} from '../../global/Lottie';
import {useTrack} from '../../global/SegmentProvider';

export const Checkbox: React.FC<Props> = ({
  label,
  error,
  checked,
  onChange,
  onClickInfo,
  description,
  tooltip,
  loading,
}) => {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  const track = useTrack();
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const router = useRouter();
  useOutsideAlerter(tooltipRef, () => setShowTooltip(false));

  const toggleTooltip = React.useCallback(() => setShowTooltip(toggle), []);
  return (
    <Box>
      <Box display="flex" items="start">
        <Stack direction="vertical">
          <Label htmlFor={slugify(label)}>
            <StyledCheckbox
              id={slugify(label)}
              type="checkbox"
              checked={checked}
              onChange={(e) => {
                track('Button clicked', {
                  button_id: slugify(label),
                  button_text: label,
                  path: router.asPath,
                });
                onChange?.(e);
              }}
            />
            {label}
            {(onClickInfo && typeof onClickInfo === 'function') || tooltip ? (
              <InfoButton onClick={onClickInfo || toggleTooltip}>
                {tooltip ? (
                  <Tooltip
                    ref={tooltipRef}
                    position={{
                      xs: 'left',
                      sm: 'right',
                    }}
                    show={showTooltip}
                  >
                    {tooltip}
                  </Tooltip>
                ) : null}
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.3696 2.07111C5.74001 1.15543 7.35118 0.666687 8.99935 0.666687C10.0937 0.666687 11.1773 0.882235 12.1884 1.30102C13.1994 1.71981 14.1181 2.33364 14.8919 3.10746C15.6657 3.88129 16.2796 4.79994 16.6983 5.81099C17.1171 6.82204 17.3327 7.90567 17.3327 9.00002C17.3327 10.6482 16.8439 12.2594 15.9283 13.6298C15.0126 15.0002 13.7111 16.0683 12.1884 16.699C10.6657 17.3297 8.99011 17.4948 7.3736 17.1732C5.75709 16.8517 4.27223 16.058 3.1068 14.8926C1.94136 13.7271 1.14769 12.2423 0.826142 10.6258C0.504599 9.00926 0.669626 7.33371 1.30036 5.81099C1.93109 4.28827 2.99919 2.98679 4.3696 2.07111ZM9.31625 4.90002C9.11336 4.81667 8.8858 4.81667 8.68291 4.90002C8.58062 4.93968 8.48717 4.99915 8.40791 5.07502C8.33431 5.15602 8.27512 5.24903 8.23291 5.35002C8.18626 5.44892 8.16343 5.55737 8.16625 5.66668C8.16561 5.77636 8.18664 5.88507 8.22811 5.98661C8.26958 6.08814 8.33068 6.18048 8.40791 6.25835C8.48892 6.33196 8.58193 6.39115 8.68291 6.43335C8.80916 6.48522 8.94622 6.50528 9.08204 6.49178C9.21786 6.47827 9.34828 6.43162 9.46184 6.35591C9.57541 6.2802 9.66864 6.17775 9.73334 6.05758C9.79804 5.9374 9.83224 5.80317 9.83292 5.66668C9.82985 5.44604 9.74353 5.23471 9.59125 5.07502C9.51199 4.99915 9.41854 4.93968 9.31625 4.90002ZM8.99958 8.16668C8.77857 8.16668 8.56661 8.25448 8.41033 8.41076C8.25405 8.56704 8.16625 8.779 8.16625 9.00002V12.3333C8.16625 12.5544 8.25405 12.7663 8.41033 12.9226C8.56661 13.0789 8.77857 13.1667 8.99958 13.1667C9.22059 13.1667 9.43256 13.0789 9.58884 12.9226C9.74512 12.7663 9.83292 12.5544 9.83292 12.3333V9.00002C9.83292 8.779 9.74512 8.56704 9.58884 8.41076C9.43256 8.25448 9.22059 8.16668 8.99958 8.16668Z"
                    fill="#3D81F1"
                  />
                </svg>
              </InfoButton>
            ) : null}

            <Box visibility={loading ? 'visible' : 'hidden'}>
              <Lottie
                path="/lottie/loading-spinner.json"
                width={30}
                height={30}
              />
            </Box>
          </Label>
          {description ? (
            <Box marginL={28}>
              <Small fontSize="xs" colour="grey7">
                {description}
              </Small>
            </Box>
          ) : null}
        </Stack>
      </Box>
      {error ? <Small colour="accent">{error}</Small> : null}
    </Box>
  );
};

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  validator?: (
    e: React.ChangeEvent<HTMLInputElement>['target']['value']
  ) => Maybe<string>;
  error?: string;
  checked?: boolean;
  onClickInfo?: () => void;
  tooltip?: string;
  description?: string;
  loading?: boolean;
};
