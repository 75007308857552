import {useMemo, useCallback} from 'react';
import {useRouter} from 'next/router';
import {isEqual} from 'lodash';
import {omitEmpty} from '@mindfulness/utils/object';
import {when} from '@mindfulness/utils/maybe';
import {justOne} from '@mindfulness/utils/array';

import {getTemplateValues, isOnClient, onClient, utmPropsFromUrl} from '../utils';

interface ParsedUrlQuery extends NodeJS.Dict<string | string[]> {}
declare type Url = UrlObject | string;

interface UrlObject {
  auth?: string | null | undefined;
  hash?: string | null | undefined;
  host?: string | null | undefined;
  hostname?: string | null | undefined;
  href?: string | null | undefined;
  pathname?: string | null | undefined;
  protocol?: string | null | undefined;
  search?: string | null | undefined;
  slashes?: boolean | null | undefined;
  port?: string | number | null | undefined;
  query?: string | null | ParsedUrlQuery | undefined;
}
/**
 * A hook for easily working with next/router functions
 * @return {Object} - Object of navigation functions
 */
export function useNavigation() {
  const router = useRouter();
  const currentUrl = useMemo(() => {
    if (!router.isReady) return;
    return new URL(
        `https://${
        process.env.INFRA_ENV === 'dev' ? `web.dev.` : ''
        }mindfulness.com${router.asPath}`,
    );
  }, [router.isReady, router.asPath]);

  const currentSearch = useMemo(() => {
    if (!currentUrl) return;
    return new URLSearchParams(currentUrl.search);
  }, [currentUrl]);

  const goTo = useCallback(
      (url: Url) => {
        const pathname = typeof url === 'string' ? url : url.pathname as string;
        if (pathname.startsWith('http')) {
          onClient(() => {
            window.location.href = pathname;
          });
          return;
        }
        router.push(url);
      },
      [router.isReady],
  );
  const goToHash = useCallback(
      (hash: string) => {
        router.replace(
            {
              hash,
            },
            undefined,
            {
              shallow: true,
            },
        );
      },
      [router.isReady],
  );

  const getQueryParam = useCallback((name: string) => {
    if (isOnClient()) {
      const params = new URLSearchParams(window.location.search);
      return justOne(params.get(name)) || undefined;
    }
  }, []);

  const removeParam = useCallback(
      (pathname: UrlObject['pathname'], param: string) => {
        if (!currentSearch) return;
        const oldQuery = {} as Record<string, string>;
        currentSearch.forEach((value, key) => {
          if (key === param) return;
          oldQuery[key] = value;
        });

        goTo({
          pathname,
          query: omitEmpty(oldQuery),
        });
      },
      [currentSearch],
  );

  const addParam = useCallback(
      (pathname: string, data: Record<string, string>) => {
        if (!currentSearch) return;
        const oldQuery: Record<string, string> = {};
        currentSearch.forEach((value, key) => {
          oldQuery[key] = value;
        });
        const newQuery = omitEmpty({
          ...oldQuery,
          ...data,
        });
        goTo({
          pathname,
          query: newQuery,
        });
      },
      [currentSearch],
  );

  const toggleParam = useCallback(
      (pathname: string, param: string, value?: string) => {
        if (!currentSearch) return;

        if (currentSearch.has(param)) {
          removeParam(pathname, param);
          return;
        }
        addParam(pathname, {
          [param]: value || '',
        });
        return;
      },
      [addParam, removeParam, currentSearch],
  );

  const changeParam = useCallback(
      (pathname: string, param: string, value: string) => {
        if (!currentSearch) return;

        const oldQuery: Record<string, string> = {};
        currentSearch.forEach((value, key) => {
          if (key === param) return;
          oldQuery[key] = value;
        });
        const newQuery = omitEmpty({
          ...oldQuery,
          [param]: value,
        }) as Record<string, string>;
        goTo({
          pathname,
          query: newQuery,
        });
      },
      [currentSearch],
  );

  const update = useCallback(
      (query_: ParsedUrlQuery, prefetch?: string) => {
        const query = {
          ...router.query,
          ...query_,
        };
        if (isEqual(router.query, query)) {
        // Don't update if the query unless it has changed to prevent unnecessary rerenders
          return;
        }
        router.replace(
            {
              query,
            },
            undefined,
            {
              shallow: true,
            },
        );
      // if (prefetch) {
      //   router.prefetch(`${prefetch}?${new URLSearchParams(query).toString()}`);
      // }
      },
      [router.isReady, router.query],
  );

  const removeHash = useCallback(() => {
    const query = {
      ...router.query,
    };
    router.replace(
        {
          query,
          hash: '',
        },
        undefined,
        {
          shallow: true,
        },
    );
  }, [
    router.isReady,
    router.query,
  ]);

  return {
    currentUrl,
    router,
    goTo,
    goToHash,
    removeHash,
    getQueryParam,
    removeParam,
    addParam,
    toggleParam,
    changeParam,
    getUtmParams: () => utmPropsFromUrl(router.query),
    templateParams: (template: string) => {
      const values = getTemplateValues(template).map((val) => ({
        ...val,
        param: when(val.value, (v) => getQueryParam(v)),
      }));

      const render = values.reduce((prev, curr) => {
        return prev.replace(
            '${' + curr.key + '}',
            curr.param || curr.alt || '',
        );
      }, template);
      return render;
    },
    update,
  };
}
