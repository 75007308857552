import {Theme} from '@emotion/react';
import {Maybe} from '@mindfulness/utils/maybe';
import {ProducerFragment} from '../../../types/api';
import style from './TeacherPanel.module.css';
import styleUtil from '../../../styles/utils.module.css';
import {Box, Flex} from '../../layout';
import {APIImage, NewLink} from '../../global';
import {Small, Text} from '../../typography';

export const TeacherPanel: React.FC<Props> = ({
  teacher,
  spacing = 30,
  loading,
}) => {
  const type = teacher?.type.replace('SYSTEM', 'PRODUCER');
  return teacher ? (
    <div
      className={[
        style.panel,
        styleUtil[`mb-${spacing}`],
      ].join(' ')}>
      <NewLink
        href={type === 'TEACHER' ? teacher.webSlug || undefined : undefined}
        passHref
      >
        <Flex gap={6}>
          <Box width="60px" height="60px">
            <APIImage
              background="white"
              url={teacher.cellImage?.url}
              decoration="circle"
              ratio="1:1"
            />
          </Box>

          <Box>
            <Small fontSize="2xs" fontWeight="bold" colour="primary">
              {type}
            </Small>
            <Text loading={loading}>{teacher.fullName}</Text>
          </Box>
        </Flex>
      </NewLink>
    </div>
  ) : (
    <div className={[style.panel, styleUtil[`mb-${spacing}`]].join(' ')}>
      <Flex gap={6}>
        <Box width="60px" height="60px">
          <APIImage
            background="white"
            url="/images/mcom-avatar.png"
            decoration="circle"
            ratio="1:1"
          />
        </Box>

        <Box>
          <Small fontSize="2xs" fontWeight="bold" colour="primary">
            PRODUCER
          </Small>
          <Text loading={loading}>{'Mindfulness.com'}</Text>
        </Box>
      </Flex>
    </div>
  );
};

type Props = {
  teacher: Maybe<ProducerFragment>;
  spacing?: keyof Theme['spacings'];
  loading?: boolean;
};
