import styled from '@emotion/styled';
import {Alignment} from '@mindfulness/cms';
import {sanityTextAlignmentMap} from '../../../utils';

export const ContentContainer = styled.div(
    ({theme}) => `
  position: relative;
  border-radius: ${theme.radius.lg};
  overflow: hidden;
  padding: ${theme.spacings[30]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`,
);

export const TextWrapper = styled.div<{
  align?: Alignment;
}>(
    ({theme, align}) => `
  ${align ? `text-align: ${sanityTextAlignmentMap(align)};` : ''}
  color: ${theme.colors.grey9};
  max-width: 564px;
  display: flex;
  flex-direction: column;
  align-items: ${align};
  z-index: 1;
  position: relative;
`,
);
