import {isUndefined} from '@mindfulness/utils/maybe';
import {useCallback, useContext, useEffect, useState} from 'react';
import {Maybe, SingleDetailFragment} from '../types/api';
import {Context} from '../components/global';
import {getSingleById} from '../api';
import {AuthedSession} from '../utils/auth';

const hydrate = async (
    id: Maybe<string>,
): Promise<Maybe<SingleDetailFragment>> => {
  try {
    if (!id) return;
    const single = await getSingleById(
        {
          id,
          withUserData: true,
          resultsTake: 5,
        },
        {
          fetchPolicy: 'no-cache',
        },
    );
    return single;
  } catch (e) {
    console.error(`Failed to useHydrateSingle`, e, id);
  }
};

export const useHydratedSingle = (staticSingle: SingleDetailFragment) => {
  const [loading, setLoading] = useState(true);
  const {session} = useContext(Context);
  const [single, setSingle] = useState<SingleDetailFragment>(staticSingle);

  const hydrateSingle = async (staticSingle: SingleDetailFragment, session: Maybe<AuthedSession> | null) => {
    if (!staticSingle?.id || session === null) return;
    if (isUndefined(session)) {
      setLoading(true);
      // If the user logs out, remove all the userData
      setSingle(staticSingle);
      setLoading(false);
      return;
    }
    setLoading(true);
    const hydratedSingle = await hydrate(staticSingle.id);

    if (hydratedSingle) {
      setSingle(hydratedSingle);
    }
    setLoading(false);
  };

  const refreshSingle = useCallback(async () => {
    await hydrateSingle(staticSingle, session);
  }, [staticSingle?.id, session]);

  useEffect(() => {
    hydrateSingle(staticSingle, session);
  }, [staticSingle?.id, session]);

  return {
    loading,
    single,
    refreshSingle,
  };
};
