import {useMemo, useCallback} from 'react';
import {Maybe, when} from '@mindfulness/utils/maybe';
import {omitEmpty} from '@mindfulness/utils/object';
import {switchEnum} from '@mindfulness/utils/logic';
import {captureException} from '@sentry/nextjs';

import {
  joinBundle,
  joinCohort,
  redeemShareAnonymously,
  generateInstantAccess,
} from '../../../api';

import {PageData} from '../../../types/types';
import {InstantAccess} from '../../../types/api';
import {AuthedSession, defaultCheckoutThankyou, defaultLanderThankyou} from '../../../utils';
import {useTrack} from '../SegmentProvider';
import {redeemAccessPass} from '../../../api/functions/redeemAccessPass';
import {useNewNavigation} from '../../../hooks/useNewNavigation';

export const useActions = (pageData: PageData, abortAction: Maybe<boolean>, refreshSession: () => Promise<AuthedSession>) => {
  const {goTo, getQueryParam, getUtmParams} = useNewNavigation();
  const track = useTrack();

  const actions = useMemo(
      () => ({
        downloadApp: async ({email}: ActionParam) =>
          await generateInstantAccess({
            type: InstantAccess.Download,
            email,
          }),
        addToCohort: async ({email}: ActionParam) => {
          const cohort = pageData?.action?.cohort;
          if (!cohort) return;
          await joinCohort({cohort});
        },
        joinABundle: async ({email}: ActionParam) => {
          try {
            if (!pageData?.action?.bundle?.id) {
              throw new Error('Bundle id not provided');
            }
            await joinBundle({
              id: pageData.action.bundle.id,
            });
          } catch (e) {
            captureException(e);
          }

          const res = await generateInstantAccess({
            email,
            type: InstantAccess.Bundle,
          });
          track('Bundle joined', {
            bundle_name: pageData?.title,
            bundle_cohort: pageData?.action?.cohort,
          });
          return res;
        },
        joinACourse: async ({email}: ActionParam) => {
          try {
            track('Course registered', {
              course_name: pageData?.title,
              course_offer: pageData?.action?.coupon,
              cohort: pageData?.action?.cohort,
            });
            if (!email) {
              return;
            }
            await redeemShareAnonymously({
              email,
              code: pageData?.action?.code as string,
            });
            const res = await generateInstantAccess({
              type: InstantAccess.Bundle,
              email,
            });

            return res;
          } catch (e) {
            captureException(e);
          }
        },
        joinAChallenge: async ({email}: ActionParam) => {
          const res = await generateInstantAccess({
            type: InstantAccess.Bundle,
            email,
          });
          const id = pageData?.action?.cohort;
          if (id) {
            await joinBundle({
              id,
            });
          }
          await track('Challenge joined', {
            challenge_name: pageData?.action?.challengeName,
            challenge_cohort: pageData?.action?.cohort,
          });
          return res;
        },
        redeemAccesspass: async () => {
          const code = pageData?.action?.accesspass;
          if (!code) return;
          const res = await redeemAccessPass({
            code,
          });
          await refreshSession?.();

          await track('Access pass redeemed', {
            access_pass: pageData?.action?.accesspass,
          });
          return res;
        },
        purchase: () => {},
        onboarding: () => {},
        purchaseGiftCard: () => {},
        purchaseSubscription: () => {},
        purchaseBogo: () => {},
        purchaseEvent: () => {},
        locked: () => {},
      }),
      [pageData],
  );

  /**
   * These methods are called after the user has logged in or signed up regardless of the method
   */
  const handleAction = useCallback(
      async (email: Maybe<string>) => {
        if (abortAction) {
          return;
        }
        const action = pageData?.action;
        const actionType = action?.action || 'downloadApp';

        if (actionType === 'redeemGuestpass') {
          goTo({
            pathname: '/subscribe',
            query: omitEmpty({
              coupon: getQueryParam('coupon'),
              cohorts: action?.cohort ?? getQueryParam('cohort'),
              onto: action?.thankYouLink || action?.thankYouPage?.current || '/thank-you/guestpass',
              trial: 'true',
              // Passthrough utm values
              ...getUtmParams(),
            }),
          });
          return;
        }
        if (!actions.hasOwnProperty(actionType)) {
          console.warn(actionType);
          return;
        }

        const res =
        (await actions[actionType]({
          email,
        })) || undefined;

        const onto = getQueryParam('onto');

        if (actionType === 'redeemAccesspass') {
          const pathname =
          when(onto, (o) => decodeURIComponent(o).split('?')[0]) ||
          action?.thankYouLink || action?.thankYouPage?.current ||
          defaultCheckoutThankyou();
          goTo({
            pathname,
            query: {
              [actionType]: 'true',
              ty: res?.__typename === 'AccessPass' ? switchEnum(res.duration ?? 'else', {
                DAY_10: '10',
                DAY_14: '14',
                DAY_30: '30',
                else: 'true',
              }) : 'true',
            },
          });
          return;
        }

        const pathname =
        when(onto, (o) => decodeURIComponent(o).split('?')[0]) ||
        action?.thankYouLink || action?.thankYouPage?.current ||
        defaultLanderThankyou();
        const search = when(onto, (o) => decodeURIComponent(o).split('?')[1]);
        const query = when(search, (sea) => {
          const params = new URLSearchParams(sea);
          const s = params.get('s');
          const v = params.get('v');
          const col = params.get('col');
          return omitEmpty({
            s,
            v,
            col,
          }) as Record<string, string>;
        });
        const accessLink = res && 'generateInstantAccess' in res ? res.generateInstantAccess.link ?? undefined : undefined;
        goTo({
          pathname,
          query: {
            [actionType]: true,
            pu: 'true',
            ...when(accessLink,
                (link) =>
                  ({
                    link,
                  }),
            ),
            ...when(query, (q) => q),
          },
        });
      },
      [pageData, actions, abortAction, getQueryParam],
  );

  return handleAction;
};

type ActionParam = {
  email: Maybe<string>;
};

export type HandleAction = (email: Maybe<string>) => Promise<void>;
