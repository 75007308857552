import * as React from 'react';

import {SectionIndex, ReferencedTeacherFeed} from '../../../types/types';
import {Column, Container, Grid, Row, Section} from '../../layout';
import {H1, Text} from '../../typography';
import {TeacherFeedBlock} from '../../ui';
import {ButtonLink} from '../../global';

export const TeacherFeed: React.FC<SectionIndex<ReferencedTeacherFeed>> = ({
  index,
  title,
  paragraph,
  background,
  producers,
}) => (
  <Section
    background={background}
    sectionPadding={{
      paddingBottom: 120,
      paddingTop: 120,
    }}
  >
    <Container>
      <Row spacing={40}>
        <Column textAlign="center">
          <H1 size="3xl" as={index === 0 ? undefined : 'h2'}>
            {title || `Learn From The World's Most\nTrusted Teachers`}
          </H1>
          <Text prose="md" colour="grey8">
            {paragraph ||
              `We only host the highest quality mindfulness teachers, these teachers will be adding courses, meditations and daily practices for you to enjoy.`}
          </Text>
        </Column>
      </Row>
      <Grid
        cols={{
          xs: 2,
          lg: 4,
        }}
        rowGap={24}
        columnGap={24}
        spacing={50}
      >
        {producers?.map((producer) => (
          <TeacherFeedBlock key={producer.webSlug} {...producer} />
        ))}
      </Grid>
      <Row>
        <Column justify="center">
          <ButtonLink
            id={`TeacherFeed-${index}-viewAll`}
            href={`/teachers`}
            variant="cta"
          >{`Browse Teachers & Experts`}</ButtonLink>
        </Column>
      </Row>
    </Container>
  </Section>
);
