import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import type ReactPlayer from 'react-player';
import {useRecoilState} from 'recoil';
import {isDesktop} from 'react-device-detect';
import dynamic from 'next/dynamic';

import {Icon} from '../../global';
import {Text, H4, Title2} from '../../typography';
import {Stack} from '../../layout';
import {useNavigation, useSecondsToMinutes} from '../../../hooks';
import {
  hasNextChapter,
  hasPreviousChapter,
  nextChapter,
  noNextChapter,
  noPreviousChapter,
  previousChapter,
} from '../../../utils';

import {ProgressSlider} from './ProgressSlider';

import {LoadingSpinner} from '../LoadingSpinner';

const SingleShare = dynamic(
    () => import('../SingleShare').then(({SingleShare}) => SingleShare),
    {
      ssr: false,
    },
);

import style from './Overlay.module.css';

import {
  Maybe,
  PlayableTrack,
  SingleDetailFragment,
  SingleVariantFragment,
  SinglesQueue,
} from '../../../types/types';
import {playerPlayState} from '../../../state/atoms';
import {useContributorNames} from '../../../hooks/useContributorNames';
import {useSingleLocked} from '../../../hooks/useSingleLocked';
import {OnClient} from '../OnClient';
import {getVersion} from '../../../utils/version';
import {TranscriptsButton} from '../TranscriptsButton';
import {NormalizedButton} from '../NormalizedButton';
import {VideoOverlay} from './SinglePlayer.styles';

const [major, minor] = getVersion();

export const Overlay: React.FC<Props> = ({
  requireSignIn,
  chapter,
  setChapter,
  duration,
  playSeconds,
  setTime,
  playPercent,
  single,
  tracks,
  nextAndPreviousSingles,
  player,
  variant,
  scrubBackwards,
  scrubForwards,
  noContent,
  hideTitle,
  cueRef,
  handleFullScreen,
  frame,
}) => {
  const backgroundRef = useRef<HTMLDivElement>(null);
  const [status, setStatus] = useRecoilState(playerPlayState);

  const [hover, setHover] = useState<boolean>(false);
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [controlsActive, setControlsActive] = useState<boolean>(false);
  const countdown = useSecondsToMinutes(duration - playSeconds);
  const {update} = useNavigation();

  const hovering = useMemo(() => {
    return status !== 'playing' || hover || mouseDown || controlsActive;
  }, [controlsActive, hover, mouseDown, status]);

  useEffect(() => {
    if (isDesktop) return;
    setHover(true);
  }, [isDesktop]);

  // Any time the hover state is set to true create a time out to turn it off 3 seconds later
  useEffect(() => {
    if (!hover) return;
    setTimeout(() => {
      setHover(false);
    }, 3000);
  }, [hover]);

  const stepBackward = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();

        setHover(true);

        const _hasPreviousChapter = hasPreviousChapter(chapter, tracks);
        // has previous chapter
        if (_hasPreviousChapter && tracks && chapter) {
          setChapter(previousChapter(chapter, tracks));
          return;
        }
        // Is part of a queue that has other singles
        if (nextAndPreviousSingles?.prev) {
          update({
            s: nextAndPreviousSingles.prev,
          });
        }
      },
      [chapter, tracks, nextAndPreviousSingles?.prev],
  );

  const stepForward = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation();

        setHover(true);
        const _hasNextChapter = hasNextChapter(chapter, tracks);
        // has next chapter
        if (_hasNextChapter && tracks && chapter) {
          setChapter(nextChapter(chapter, tracks));
          return;
        }
        if (nextAndPreviousSingles?.next) {
          update({
            s: nextAndPreviousSingles.next,
          });
        }
      },
      [chapter, tracks, nextAndPreviousSingles?.next],
  );

  const contributorNames = useContributorNames(single.contributors);

  return (
    <VideoOverlay
      status={status}
      hover={hovering}
      onClick={() => {
        if (hovering) return;
        setMouseDown(true);
      }}
      onMouseMove={() => {
        if (!isDesktop || hover) return;
        setHover(true);
      }}
    >
      {frame ? (
        <div className={style.additionalControls}>
          <TranscriptsButton single={single} size={24} />
          <NormalizedButton type="button" onClick={handleFullScreen}>
            <Icon name="full-screen" size={24} />
          </NormalizedButton>
        </div>
      ) : null}
      {status === 'error' ? (
        <Stack justify="center" direction="vertical">
          <H4 spacing={16} weight="bold">
            An error occurred
          </H4>
          <Text>Please refresh or try again later.</Text>
        </Stack>
      ) : (
        <>
          {chapter?.type === 'AUDIO' ? (
            <div className={`${style.timer} timer`}>
              <Text fontSize="xmega" fontWeight="bold">
                {countdown}
              </Text>
            </div>
          ) : null}

          <div className={style.controls}>
            <div className={style.backgroundClicker}
              ref={backgroundRef}
              onClick={() => {
                if (hovering) {
                  setMouseDown(false);
                  setControlsActive(false);
                  setHover(false);
                  return;
                }
              }}
            />
            {/* This is where subtitles will be rendered */}
            <div
              className={[
                style.cueContainer,
                ...(hovering ? [style.hover] : []),
              ].join(' ')}
              ref={cueRef}
              style={{opacity: 0}}
            />
            <div
              className={[
                style.titleContainer,
                ...(hovering ? [style.hover] : []),
              ].join(' ')}
            >
              {noContent || hideTitle ? null : (
                <>
                  <Title2 spacing={16}>{single.title}</Title2>
                  {contributorNames ? (
                    <H4 spacing={40}>{contributorNames}</H4>
                  ) : null}
                </>
              )}
              {frame ? null : (
                <OnClient>
                  <SingleShare wide justify="center" single={single} hasTranscripts={!!chapter?.transcript?.url && (major >= 3 && minor >= 6)} />
                </OnClient>
              )}
            </div>

            <div className={[style.controlsContainer, ...(hovering ? [style.hover] : [])].join(' ')}>
              {status === 'finished' ? (
                <button type="button" className={style.replayButton} onClick={() => setStatus('playing')}>
                  Replay
                </button>
              ) : (
                <>
                  <Stack
                    space={20}
                    items="center"
                    onMouseEnter={() => {
                      if (isDesktop) {
                        setControlsActive(true);
                      }
                    }}
                    onMouseLeave={() => {
                      setControlsActive(false);
                    }}
                  >
                    {tracks && chapter ? (
                      <button
                        type="button"
                        className={[
                          style.stepButton,
                          ...(noPreviousChapter(chapter, tracks) &&
                          !nextAndPreviousSingles?.prev ? [style.disabled] : []),
                        ].join(' ')}
                        onClick={stepBackward}
                      >
                        <Icon
                          name="player-stepback"
                          size={{xs: 16, md: 24}}
                        />
                      </button>
                    ) : null}

                    <button type="button" onClick={scrubBackwards}>
                      <Icon name="player-rewind" size={{xs: 24, md: 30}} />
                    </button>
                    <CenterButton
                      requireSignIn={requireSignIn}
                      single={single}
                    />
                    <button type="button" onClick={scrubForwards}>
                      <Icon
                        name="player-fastforward"
                        size={{xs: 24, md: 30}}
                      />
                    </button>
                    {tracks && chapter ? (
                      <button
                        type="button"
                        onClick={stepForward}
                        className={[
                          style.stepButton,
                          ...( noNextChapter(chapter, tracks) &&
                          !nextAndPreviousSingles?.next ? [style.disabled] : []),
                        ].join(' ')}
                      >
                        <Icon
                          name="player-stepforward"
                          size={{xs: 16, md: 24}}
                        />
                      </button>
                    ) : null}
                  </Stack>

                  <ProgressSlider
                    duration={duration}
                    playedPercent={playPercent}
                    playedSeconds={playSeconds}
                    setTime={setTime}
                    setStatus={setStatus}
                    setChapter={setChapter}
                    player={player}
                    variant={variant}
                    chapter={chapter}
                    tracks={tracks}
                  />
                </>
              )}
            </div>
          </div>
        </>
      )}
    </VideoOverlay>
  );
};

export const CenterButton: React.FC<{
  requireSignIn: boolean;
  single: SingleDetailFragment;
}> = ({requireSignIn, single}) => {
  const [status, setStatus] = useRecoilState(playerPlayState);
  const handleSingleLocked = useSingleLocked(single);
  return (
    <button
      type='button'
      className={style.playerButton}
      disabled={status === 'loading'}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (requireSignIn) {
          handleSingleLocked();
          return;
        }

        setStatus((prev) => {
          const next = prev === 'paused' ? 'playing' : 'paused';
          return next;
        });
      }}
    >
      {status === 'loading' ? (
        <LoadingSpinner />
      ) : status === 'playing' ? (
        <Icon name="player-pause" size={24} />
      ) : (
        <Icon name="player-play" size={24} />
      )}
    </button>
  );
};

type Props = {
  duration: number;
  requireSignIn: boolean;
  chapter: Maybe<PlayableTrack>;
  setChapter: React.Dispatch<React.SetStateAction<Maybe<PlayableTrack>>>;
  playPercent: number;
  variant: Maybe<SingleVariantFragment>;
  playSeconds: number;
  setTime: (time: number, duration: number) => void;
  single: SingleDetailFragment;
  player: React.RefObject<ReactPlayer>;
  nextAndPreviousSingles: Maybe<SinglesQueue>;
  tracks: Maybe<Array<PlayableTrack>>;
  locked: boolean;
  scrubBackwards: () => void;
  scrubForwards: () => void;
  noContent?: boolean;
  hideTitle?: boolean;
  cueRef: React.RefObject<HTMLDivElement>;
  handleFullScreen: () => void;
  frame?: boolean;
};
