import {Maybe, QuickLinkFeedFragment} from '../../../types/api';
import {WithLinkProps, withLink} from '../../../hoc/withLink';
import {Flex} from '../../layout';
import {Text} from '../../typography';
import {assertString} from '../../../utils/string';
import {mapDeeplink} from '../../../utils/mapDeeplink';
import {QuickLinkCard as Card, QuickLinkTitle} from './QuickLink.styles';
import {useTrack} from '../../global/SegmentProvider';

export const QuickLink: React.FC<{
  quickLinks: [QuickLinkFeedFragment, QuickLinkFeedFragment];
  defaultEventProps: {
    collection_id: Maybe<string>,
    collection_title: Maybe<string>,
    feed_id: Maybe<string>,
    item_index: number,
  }
}> = ({quickLinks, defaultEventProps}) => {
  const track = useTrack();
  return (
    <Flex
      column
      gap={{
        xs: 10,
        md: 26,
      }}
      spacing={30}
      items="stretch"
    >
      {quickLinks?.map((quickLink) => {
        const href = assertString(
            mapDeeplink(quickLink.deepLink) || quickLink.webLink,
        );
        return href ? (
          <QuickLinkCard
            onClick={() => {
              track('Collection item selected', {
                item_id: quickLink.id,
                item_type: 'QUICK_LINK',
                ...defaultEventProps,
              });
            }}
            href={href}
            {...quickLink}
            key={quickLink.quickLinkTitle}
          />
        ) : null;
      })}
    </Flex>
  );
};

export const QuickLinkCard: React.FC<WithLinkProps & QuickLinkFeedFragment> =
  withLink(({quickLinkTitle, subtitle}) => (
    <Card>
      <QuickLinkTitle
        weight="bold"
        ellipsis={1}
        spacing={{
          xs: 16,
          md: 40,
        }}
      >
        {quickLinkTitle}
      </QuickLinkTitle>
      <Text
        ellipsis={1}
        transform="uppercase"
        fontWeight="bold"
        colour="grey7"
        fontSize={{
          xs: '2xs',
          md: 'base',
        }}
      >
        {subtitle}
      </Text>
    </Card>
  ));
