import {OrderFragment} from '../../../types/api';
import {
  Maybe,
  PlanFragment,
  PlanWithInstallments,
} from '../../../types/types';
import * as React from 'react';

const defaultProps: ContextState = {
  discount: undefined,
  // expiry: undefined,
  handleDiscount: undefined,
  handlePurchaseDiscount: undefined,
  invalidCoupon: false,
  handleInvalidCoupon: undefined,
};

export const DiscountContext = React.createContext<ContextState>({
  ...defaultProps,
});

export const DiscountProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [discount, setDiscount] = React.useState(defaultProps.discount);
  const [invalidCoupon, setInvalidCoupon] = React.useState<boolean>(
      defaultProps.invalidCoupon,
  );
  // const [expiry, setExpiry] = React.useState<dayjs.Dayjs>(defaultProps.expiry);

  const handleDiscount = React.useCallback((plan: ContextState['discount']) => {
    setDiscount(plan);

    // if (plan?.discount) {
    //   const key = `coupon:${plan.discount.coupon}`;
    //   const expiryStorage = getValue(key);
    //   if (expiryStorage) {
    //     const expiryDate = dayjs(expiryStorage);
    //     setExpiry(expiryDate);
    //   } else {
    //     const halfAnHourFromNow = dayjs().add(30, 'm');
    //     setValue(
    //         `coupon:${plan.discount.coupon}`,
    //         halfAnHourFromNow.toISOString(),
    //     );
    //     setExpiry(halfAnHourFromNow);
    //   }
    // }
  }, []);

  const handlePurchaseDiscount = React.useCallback((order: OrderFragment) => {
    setDiscount(order.summary);
  }, []);

  const handleInvalidCoupon = React.useCallback((status: boolean) => {
    setInvalidCoupon(status);
  }, []);

  return (
    <DiscountContext.Provider
      value={{
        handleDiscount,
        handlePurchaseDiscount,
        handleInvalidCoupon,
        discount,
        invalidCoupon,
        // expiry,
      }}
    >
      {children}
    </DiscountContext.Provider>
  );
};

type ContextState = {
  discount: Maybe<
    PlanFragment['discount'] | OrderFragment['summary'] | PlanWithInstallments
  >;
  // TODO: depecrate handleDiscount when /subscribe is removed
  handleDiscount: Maybe<
    (
      plan: Maybe<
        | PlanFragment['discount']
        | OrderFragment['summary']
        | PlanWithInstallments['discount']
      >
    ) => void
  >;
  handlePurchaseDiscount: Maybe<(order: OrderFragment) => void>;
  handleInvalidCoupon: Maybe<(status: boolean) => void>;
  invalidCoupon: boolean;
  // expiry: Maybe<dayjs.Dayjs>;
};
