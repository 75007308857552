import * as React from 'react';
import {Theme} from '@emotion/react';

import {useCreateModal} from '../../../hooks';
import {Icon} from '../../global';

import {
  CloseButtonWrapper,
  ModalInner,
  ModalOuter,
  ModalWrapper,
} from './PanelModal.styles';
import {CloseButton} from '../Modal/Modal.styles';

export const PanelModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  closeButton,
  padding = 40,
  unclosable,
  ...props
}) => {
  const {open, name, closeModal} = useCreateModal(props);
  return (
    <ModalWrapper open={open} id={`PanelModal-${name}`}>
      <ModalOuter onClick={unclosable ? undefined : () => closeModal?.()} />
      <ModalInner padding={padding}>
        {children}
        {unclosable ? null : (
          <CloseButtonWrapper show={Boolean(closeButton)}>
            <CloseButton
              id={`PanelModal-${name}Close`}
              role="button"
              aria-label="Close modal"
              onClick={() => closeModal?.()}
            >
              <Icon name="close-modal" size={30} />
            </CloseButton>
          </CloseButtonWrapper>
        )}
      </ModalInner>
    </ModalWrapper>
  );
};

type Props = {
  name: string;
  open?: boolean;
  padding?: keyof Theme['spacings'];
  closeButton?: boolean;
  unclosable?: boolean;
};
