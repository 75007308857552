import * as React from 'react';
import Link, {LinkProps} from 'next/link';
import {ButtonConfig} from '../../forms';
import style from '../../forms/Button/Button.module.css';
import {getResponsiveBoolClassNames} from 'shared/utils';
import {getButtonSize} from 'shared/components/forms/Button/StyledButton';

export const ButtonLink: React.FC<React.PropsWithChildren<LinkProps & ButtonConfig & {
  className?: string;
}>> = ({
  children,
  onClick,
  className,
  variant = 'primary',
  wide,
  size,
  ...props
}) => {
  const [loading, setLoading] = React.useState(props.loading);
  const buttonSize = getButtonSize(size, 'size');

  return (
    <Link
      onClick={async (e) => {
        setLoading(true);
        await onClick?.(e);
        setLoading(false);
      }}
      loading={props.loading || loading}
      className={[
        style.button,
        style[`style-${variant}`],
        ...(wide ? getResponsiveBoolClassNames(wide, 'full-width') : []),
        ...buttonSize,
        ...(className ? [className] : []),
      ].join(' ')}
      {...props}
    >
      {children}
    </Link>
  );
};
