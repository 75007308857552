import {Fn} from '@mindfulness/utils/fn';
import {Maybe} from '@mindfulness/utils/maybe';

export const isOnServer = () => typeof window === 'undefined';
export const isOnClient = () => typeof window !== 'undefined';

export const onServer = <T>(fn: Fn<void, T>): Maybe<T> =>
  isOnServer() ? fn() : undefined;
export const onClient = <T>(fn: Fn<void, T>): Maybe<T> =>
  isOnClient() ? fn() : undefined;

export const throwOnServer = <T>(fn: Fn<void, T>): T => {
  if (!isOnClient()) {
    throw new Error('This function can only be called on client.');
  }
  return fn();
};
