import * as React from 'react';

import {ItemLocation, ProducerFragment} from '../../../types/types';
import {Box} from '../../layout';
import {H3, Small} from '../../typography';
import {BackgroundAPIImage, NewLink} from '../../global';

import style from './TeacherFeedBlock.module.css';
import {AspectRatio} from '../../layouts/AspectRatio';

export const TeacherFeedBlock: React.FC<
  ProducerFragment & {
    location?: ItemLocation;
    priority?: boolean;
    onClick?: () => void;
  }
> = ({fullName, cellImage, blurb, webSlug, priority, onClick}) => (
  <NewLink href={webSlug || ''} onClick={onClick}>
    <div className={style.teacherCell}>
      <AspectRatio ratio={'143:175'}>
        <Box position="relative">
          <BackgroundAPIImage
            src={cellImage?.url}
            alt={`${fullName} - ${blurb}`}
            zIndex={0}
            scaleFrom={1.1}
            priority={priority}
          />
          <div className={style.teacherInner}>
            <div>
              <Small fontSize="xs" fontWeight="bold">
                TEACHER
              </Small>
              <H3 weight="bold">{fullName}</H3>
            </div>
          </div>
        </Box>
      </AspectRatio>
    </div>
  </NewLink>
);
