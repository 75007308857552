import * as React from 'react';
import {Collection as CollectionProps} from '@mindfulness/cms';
import {justOne} from '@mindfulness/utils/array';
import {switchEnum} from '@mindfulness/utils/logic';

import {
  MixedFragment,
  ProducerFragment,
  SectionIndex,
  SingleDetailFragment,
  WithWebSlug,
} from '../../../types/types';
import {Container, Reel, Section} from '../../layout';
import {BundleCard, ItemCard, TeacherFeedBlock} from '../../ui';
import {assertString} from '../../../utils';
import {useHydratedCollection} from '../../../hooks/useHydratedCollection';
import {CollectionTitle} from '../../ui/CollectionTitle';
import type {
  BundleFeedFragment,
  CollectionFeedFragment,
} from '../../../types/api';
import {useTrack} from '../../global/SegmentProvider';

export const Collection: React.FC<
  SectionIndex<CollectionProps & CollectionFeedFragment>
> = (props) => {
  const {
    background,
    paddingTop,
    paddingBottom,
    hideCollectionContent,
    alignCollectionContent,
    index: sectionIndex,
    ...staticCollection
  } = props;
  const {collection, loading, ref} = useHydratedCollection(staticCollection);
  const track = useTrack();
  const defaultEventProps = {
    collection_id: collection?.id,
    collection_title: collection?.title,
  };
  const type = React.useMemo(
      () => justOne(collection?.results?.items)?.__typename,
      [collection?.results?.items],
  );

  return collection &&
    ['Producer', 'Single', 'Course', 'Bundle'].includes(assertString(type)) ? (
    <Section
      ref={ref}
      background={background}
      padding={{
        xs: 40,
        md: 80,
      }}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container gutter={{xs: 16, lg: 30}}>
        {hideCollectionContent ? null : (
          <CollectionTitle {...collection} align={alignCollectionContent} />
        )}
      </Container>
      <Container gutter={{xs: 0, lg: 30}}>
        <Reel
          gap={{
            xs: 8,
            md: 16,
          }}
          arrowRatio={2}
          show={switchEnum(assertString(type, 'else'), {
            Producer: () => ({
              xs: 2.2,
              md: 3.2,
              lg: 4,
            }),
            Course: () => ({
              xs: 1.1,
              md: 2.2,
            }),
            else: () => ({
              xs: 2.2,
              md: 3.2,
              lg: 5,
            }),
          })}
          scroll={switchEnum(assertString(type, 'else'), {
            Producer: () => ({
              xs: 2,
              md: 3,
              lg: 4,
            }),
            Course: () => ({
              xs: 11,
              md: 2,
            }),
            else: () => ({
              xs: 2,
              md: 3,
              lg: 5,
            }),
          })}
        >
          {(collection.results?.items as MixedFragment[])?.map(
              (item, index) => {
                return switchEnum(assertString(item.__typename, 'else'), {
                  Bundle: () => {
                    const bundle =
                    item as unknown as WithWebSlug<BundleFeedFragment>;
                    const onClick = () => track('Collection item selected', {
                      item_id: bundle.id,
                      item_type: 'BUNDLE',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <BundleCard
                        {...bundle}
                        key={bundle.id}
                        priority={index < 4 && sectionIndex < 2}
                        onClick={onClick}
                      />
                    );
                  },
                  Single: () => {
                    const single = item as unknown as SingleDetailFragment;
                    const onClick = () => track('Collection item selected', {
                      item_id: single.id,
                      item_type: 'SINGLE',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <ItemCard
                        {...single}
                        href={
                        single.webSlug ? single.webSlug : `/play?s=${single.id}`
                        }
                        key={single.id}
                        loading={loading}
                        priority={index < 4 && sectionIndex < 2}
                        onClick={onClick}
                      />
                    );
                  },
                  Producer: () => {
                    const producer = item as unknown as ProducerFragment;
                    const onClick = () => track('Collection item selected', {
                      item_id: producer.id,
                      item_type: 'BUNDLE',
                      item_index: index,
                      ...defaultEventProps,
                    });
                    return (
                      <TeacherFeedBlock
                        {...producer}
                        key={producer.id}
                        priority={index < 4 && sectionIndex < 2}
                        onClick={onClick}
                      />
                    );
                  },
                  else: null,
                });
              },
          )}
        </Reel>
      </Container>
    </Section>
  ) : null;
};
