import * as React from 'react';
import {SingleHighlight as SingleHighlightProps} from '@mindfulness/cms';

import {H2, H4, Small} from '../../typography';
import {Column, Container, Row, Section} from '../../layout';
import {APIImage, ButtonLink} from '../../global';
import {SectionIndex, SingleFragment} from '../../../types/types';

export const SingleHighlight: React.FC<
  SectionIndex<SingleHighlightProps & SingleFragment>
> = ({index, background, paddingTop, paddingBottom, ...single}) => {
  return single ? (
    <Section
      background={background}
      padding={{
        xs: 30,
        md: 104,
      }}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container gutter={16} maxWidth="prose">
        <Row
          gap={{
            xs: 0,
            md: 40,
            lg: 72,
          }}
          items="center"
        >
          <Column
            spacing={{
              xs: 16,
              md: 0,
            }}
            span={{
              xs: 24,
              md: 12,
            }}
          >
            {single?.cellImage?.url ? (
              <APIImage
                url={single.cellImage.url}
                decoration="rounded"
                ratio="1:1"
              />
            ) : null}
          </Column>
          <Column
            span={{
              xs: 24,
              md: 12,
            }}
          >
            <Section
              padding={{
                xs: 0,
                md: 30,
              }}
            >
              {single?.label ? (
                <Small colour="primary" fontWeight="bold" transform="uppercase">
                  {single.label}
                </Small>
              ) : null}
              {single?.title ? (
                <H2 spacing={single?.description ? 16 : 40}>{single.title}</H2>
              ) : null}
              {single?.description && single.description !== '' ? (
                <H4 as="p" spacing={40}>
                  {single.description}
                </H4>
              ) : null}
              {single?.webSlug ? (
                <ButtonLink
                  id={`SingleHighlight-${index}`}
                  href={single?.webSlug}
                >{`Play Now`}</ButtonLink>
              ) : null}
            </Section>
          </Column>
        </Row>
      </Container>
    </Section>
  ) : null;
};
