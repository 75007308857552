import * as React from 'react';
import {BlockContent, Context, OverlayModal} from '../../global';
import {Container, Row, Column} from '../../layout';

export const GuaranteeModal: React.FC = () => {
  const {site} = React.useContext(Context);

  return (
    <OverlayModal name="guarantee" closeButton>
      <Container maxWidth="xs">
        <Row>
          <Column>
            {site?.guaranteeModal ? (
              <BlockContent background="white" content={site.guaranteeModal} />
            ) : null}
          </Column>
        </Row>
      </Container>
    </OverlayModal>
  );
};
