import * as React from 'react';
import {isEmpty} from 'lodash';

import {Switch, Alert, Button, LinkButton} from '../../forms';
import {
  Container,
  Row,
  Column,
  Section,
  Flex,
  Box,
  Stack,
} from '../../layout';
import {H1, Small, Text} from '../../typography';
import {Divider, PlanCard, LoadingSpinner} from '../../ui';
import {EnrichedPlanPicker, SectionIndex} from '../../../types/types';
import {Context, ModalContext} from '../../global';
import {usePlanPicker} from '../../../hooks';
import {has} from '../../../utils/array';
import {PurchaseCard} from '../../ui/PurchaseCard';
import {HydratedPurchase, usePurchases} from '../../../hooks/usePurchases';
import {useAddToCart} from '../../../hooks/useAddToCart';

export const PlanPicker: React.FC<PlanPickerProps> = ({
  title,
  description,
  info,
  enrichedPlans,
  index,
  coupon,
  trialDays,
  thankYouPage,
  thankYouLink,
  bundle,
  alignment = 'center',
  buttonText,
  trialAndDiscount,
  purchases,
  type,
  _type,
}) => {
  const {openModal} = React.useContext(ModalContext);
  const {data} = React.useContext(Context);
  const [selectedPurchase, setSelectedPurchase] =
    React.useState<HydratedPurchase>();

  const {hydratedPurchases, loading: loadingPurchases} = usePurchases(
      purchases || [],
      coupon,
  );
  const {addToCart, loading: loadingAddToCart, error} = useAddToCart();
  const {
    loading,
    loadedPlans,
    sortedPlans,
    selectedPlan,
    setSelectedPlan,
    hasPlans,
    trialApplied,
    setTrial,
    selectPlan,
    hasEvent,
    showPlans,
    loadingCheckout,
  } = usePlanPicker({
    trialDays,
    coupon,
    thankYouPage,
    thankYouLink,
    enrichedPlans,
    type,
    bundle,
    trialAndDiscount,
  });

  React.useEffect(() => {
    if (!hydratedPurchases.length) return;
    setSelectedPurchase(hydratedPurchases[0]);
  }, [hydratedPurchases.length]);

  return (
    <Section
      id={`${_type}-${index}`}
      padding={64}
      background={{
        xs: 'white',
        md: 'greyWarm',
      }}
    >
      <Container maxWidth="xs">
        <Row>
          <Column spacing={26} textAlign={alignment}>
            <H1 size="3xl" as={index === 0 ? 'h1' : 'h2'} spacing={34}>
              {title || 'Choose the plan that works best for you'}
            </H1>
            <Text colour="grey8">
              {description ||
                'Your Plus+ subscription includes a 1-1 donation to someone in need of mindfulness.'}
            </Text>
          </Column>
        </Row>

        <Row>
          {trialDays && !['bogo', 'gift', 'event'].includes(type || '') ? (
            <>
              <Column spacing={10}>
                <Switch
                  title={`${trialDays} day free trial`}
                  description={
                    trialAndDiscount ?
                      'Free trial applied' :
                      `Redeem your free trial`
                  }
                  checked={trialApplied}
                  onChange={() => setTrial((prev) => !prev)}
                  unchangeable={trialAndDiscount}
                />
              </Column>
              {showPlans ? (
                <Column spacing={20}>
                  <Divider
                    text={trialApplied ? 'after trial' : undefined}
                    spacing={24}
                  />
                </Column>
              ) : (
                <Column spacing={10}>
                  <Divider spacing={24} />
                </Column>
              )}
            </>
          ) : null}
        </Row>
      </Container>
      <Container>
        <Row>
          <Column spacing={56}>
            {purchases ? (
              <Stack justify="center" space={10} wrap="nowrap">
                {loadingPurchases ? (
                  <Flex justify="center">
                    <LoadingSpinner />
                  </Flex>
                ) : (
                  hydratedPurchases.map((purchase, index) => (
                    <Box key={purchase.id} width="100%" maxWidth="120px">
                      <PurchaseCard
                        onChange={(p) => setSelectedPurchase(p)}
                        selected={purchase.id === selectedPurchase?.id}
                        {...purchase}
                      />
                    </Box>
                  ))
                )}
              </Stack>
            ) : (
              <>
                {showPlans ? (
                  <>
                    {loading ? (
                      <Flex justify="center">
                        <LoadingSpinner />
                      </Flex>
                    ) : isEmpty(loadedPlans) ? (
                      <Alert error={'No matching plans found'} />
                    ) : (
                      <Stack justify="center" space={10} wrap="nowrap">
                        {sortedPlans.map((plan, index) => (
                          <Box
                            key={`${plan.id}-${plan.payIn3}`}
                            width="100%"
                            maxWidth="120px"
                          >
                            <PlanCard
                              {...plan}
                              trial={trialApplied}
                              selectedPlan={selectedPlan}
                              hasPayIn3={has(sortedPlans, (p) => p.payIn3)}
                              onChange={(newPlan) => {
                                setSelectedPlan(newPlan);
                              }}
                              index={index}
                              trialAndDiscount={trialAndDiscount}
                            />
                          </Box>
                        ))}
                      </Stack>
                    )}
                  </>
                ) : null}
              </>
            )}
          </Column>
        </Row>
      </Container>
      <Container maxWidth="xs">
        <Row>
          <Column spacing={10} textAlign="center">
            {purchases ? (
              <Box paddingX={16}>
                <Alert error={error} />
                <Button
                  id="AddToCartPlanPicker"
                  onClick={async (e) => {
                    e.stopPropagation();
                    if (!selectedPurchase) return;
                    await addToCart(selectedPurchase, {
                      payInstalments: selectedPurchase.purchase.payIn3,
                      coupon,
                      metadata: {
                        ...(data?.action?.cohort ? {cohort: data.action.cohort} : {}),
                      },
                    });
                  }}
                  loading={loadingAddToCart}
                  wide
                >
                  {`${buttonText ?? 'Add to Cart'}`}
                </Button>
              </Box>
            ) : hasPlans ? (
              <Box paddingX={16}>
                <Button
                  id="SelectPlan"
                  onClick={selectPlan}
                  loading={loadingCheckout}
                  wide
                >
                  {`${buttonText ?? 'Add to Cart'}`}
                </Button>
              </Box>
            ) : null}
          </Column>
          {!hasEvent || info ? (
            <>
              <Column spacing={30} textAlign="center">
                <Small colour="grey7">
                  {info ? (
                    info
                  ) : (
                    <>
                      {'Cancel anytime. '}
                      <LinkButton
                        onClick={() =>
                          openModal?.({
                            name: 'guarantee',
                          })
                        }
                      >
                        30-Day mindfulness guarantee.
                      </LinkButton>
                      {` If you don't love us you'll get your money back.`}
                    </>
                  )}
                </Small>
              </Column>
              <Column spacing={0}>
                <Divider hide={['md', 'xxl']} />
              </Column>
            </>
          ) : null}
        </Row>
      </Container>
    </Section>
  );
};

export type PlanPickerProps = SectionIndex<
  EnrichedPlanPicker & {
    // Deprecated props previously passed from Sanity
    trialDays?: number;
    trialAndDiscount?: boolean;
  }
>;
