import * as React from 'react';
import {Theme} from '@emotion/react';

import {Small} from '../../typography';
import {Icon} from '../../global';
import {Stack} from '../../layout';

import {VerticalDivider} from '../../ui/VerticalDivider';
import styles from './AppStoreLinks.module.css';

export const AppStoreLinksSmall: React.FC<{
  background?: keyof Theme['colors'];
}> = ({background}) => (
  <Stack items="center" space={14}>
    <Small colour={background === 'grey9' ? 'white' : 'grey7'}>
      Available on
    </Small>
    <a
      href="https://apps.apple.com/app/mindfulness-com-meditation-app/id1466046486"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Download on the App Store"
    >
      <Icon name="apple" size={26} />
    </a>
    <VerticalDivider background={background} />
    <a
      href="https://play.google.com/store/apps/details?id=com.mindfulness.android"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Download on Google Play"
    >
      <Icon name="google-play" size={26} />
    </a>
  </Stack>
);

export const AppStoreLinksTiny: React.FC = () => (
  <Stack items="center" space={10}>
    <a
      className={styles.styledAnchor}
      href="https://apps.apple.com/app/mindfulness-com-meditation-app/id1466046486"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Download on the App Store"
    >
      <Icon name="apple" size={16} />
      <Small fontSize="2xs" spacing={0}>
        iOS
      </Small>
    </a>
    <VerticalDivider background="white" />
    <a
      href="https://play.google.com/store/apps/details?id=com.mindfulness.android"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Download on Google Play"
    >
      <Icon name="google-play" size={16} />
      <Small fontSize="2xs" spacing={0}>
        Android
      </Small>
    </a>
  </Stack>
);
