import * as React from 'react';

import {ItemLocation, Maybe} from '../../../types/types';
import type {CollectionLayout, PosterFeedFragment} from '../../../types/api';
import {ItemContainer} from './PosterCard.styles';
import {Box} from '../../layout';
import {BackgroundAPIImage} from '../../global';

import {WithLinkProps, withLink} from '../../../hoc/withLink';
import {Small, H4} from '../../typography';
import itemCardStyles from '../ItemCard/ItemCard.module.css';
import {AspectRatio} from '../../layouts/AspectRatio';

export const PosterCard: React.FC<Props & WithLinkProps> = withLink(
    ({posterImage, title, subtitle, showTitles}) => (
      <ItemContainer>
        <AspectRatio ratio="286:350">
          <Box
            color="white"
            position="relative"
            zIndex={1}
            radius="lg"
            overflow="hidden"
            paddingX={24}
          >
            <BackgroundAPIImage
              className="cell-image"
              src={posterImage?.url}
              scale={1}
              alt={`${title}`}
              doNotResize
            />
          </Box>
        </AspectRatio>
        {showTitles && (
          <div className={itemCardStyles.textContainer}>
            {title && subtitle && (
              <div>
                <H4 weight="bold" spacing={5}>
                  {title}
                </H4>
                <Small
                  colour="grey7"
                  transform="uppercase"
                  fontSize="2xs"
                  fontWeight="bold"
                  spacing={5}
                >
                  {subtitle}
                </Small>
              </div>
            )}
          </div>
        )}

      </ItemContainer>
    ),
);

type Props = PosterFeedFragment & {
  location?: ItemLocation;
  priority?: boolean;
  layout?: Maybe<CollectionLayout>;
};
