import {useContext, useMemo, useState, useCallback, useEffect} from 'react';
import {isEmpty, first} from 'lodash';
import {ExternalBundle} from '@mindfulness/cms';
import {switchEnum} from '@mindfulness/utils/logic';
import maybe, {when} from '@mindfulness/utils/maybe';
import {omitEmpty} from '@mindfulness/utils/object';
import {captureException} from '@sentry/nextjs';

import {useIsNotEmpty} from './useEmpty';
import {useUntil} from './useUntil';

import {Maybe, CheckoutType, PlanWithInstallments} from '../types/types';
import {Context, DiscountContext} from '../components/global';
import {billedWeekly, getPageCheckoutType} from '../utils';
import {useQueryParam} from './useQueryParam';
import {purchaseIds} from '../utils/checkout-migration';
import {useNavigation} from './useNavigation';

export const usePlanPicker = ({
  trialDays: sectionTrialDays,
  coupon: sectionCoupon,
  enrichedPlans,
  thankYouPage,
  thankYouLink,
  type,
  bundle: sectionBundle,
  trialAndDiscount,
}: Props) => {
  const {handleDiscount} = useContext(DiscountContext);
  const {handleGoToCheckout, data} = useContext(Context);
  const {goTo} = useNavigation();
  const [planParam, setPlanParam] = useState<string>();
  const [trialParam, setTrialParam] = useState<boolean>();

  const [payIn3Param, setPayIn3Param] = useState<boolean>();

  const [couponParam] = useQueryParam('coupon');

  const plans = useUntil([enrichedPlans]);
  const bundle = useUntil([sectionBundle, data?.action?.bundle]);
  const coupon = useUntil([couponParam, sectionCoupon, data?.action?.coupon]);
  const staticCoupon = useUntil([sectionCoupon, data?.action?.coupon]);
  const trialDays = useUntil([sectionTrialDays, data?.action?.trialDays]);
  const trial = useMemo(() => Boolean(trialDays), [trialDays]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingCheckout, setLoadingCheckout] = useState<boolean>(false);
  const [loadedPlans, setLoadedPlans] = useState<Array<PlanWithInstallments>>(
      [],
  );
  const [trialApplied, setTrial] = useState<boolean>(trial && !!trialParam);
  const [selectedPlan, setSelectedPlan] = useState<PlanWithInstallments>();
  const hasPlans = useIsNotEmpty(loadedPlans);
  const hasEvent = useMemo(
      () =>
        Boolean(
            loadedPlans.find(({productCode}) => productCode === 'mcom-event'),
        ),
      [loadedPlans],
  );
  const selectPlan = useCallback(() => {
    setLoadingCheckout(true);
    const pageAction = getPageCheckoutType(data?.action?.action);
    const checkoutType = switchEnum<
      Exclude<Props['type'], undefined>,
      CheckoutType
    >(type || 'pageAction', {
      bogo: 'bogo',
      gift: 'gift',
      event: 'event',
      pageAction,
      else: undefined,
    });

    const appliedCoupon = coupon || selectedPlan?.discount?.coupon || undefined;
    const purchaseAlias = switchEnum(
        (selectedPlan?.webAlias || 'annual_3').split('_')[0] || 'else',
        {
          lifetime: 'lifetime_2',
          monthly: 'monthly_2',
          annual: 'annual_3',
          else: selectedPlan?.webAlias || 'annual_3',
        },
    );

    const purchaseId = purchaseIds(purchaseAlias, trial, checkoutType);

    if (purchaseId) {
      goTo({
        pathname: '/checkout',
        query: omitEmpty({
          products: purchaseId,
          coupon: appliedCoupon,
        }),
      });
      return;
    }

    const onto = thankYouLink || thankYouPage?.current;
    const pickerParams = omitEmpty({
      type: checkoutType,
      b: bundle?.slug,
      plan: selectedPlan?.webAlias || undefined,
      ...(selectedPlan?.payIn3 ? {p3: 'true'} : {}),
      ...(onto ? {onto} : {}),
      td: trialAndDiscount ?? undefined,
      ...(trialApplied ?
        omitEmpty({
          trial: 'true',
          coupon: trialAndDiscount ? appliedCoupon : undefined,
        }) :
        omitEmpty({
          coupon: appliedCoupon,
          trial: 'false',
        })),
    });
    handleGoToCheckout?.(pickerParams);
    setLoadingCheckout(false);
  }, [type, selectedPlan, bundle, data?.action?.action, coupon]);

  const discounted = useMemo(() => {
    if (!selectedPlan) {
      return;
    }
    if (trialApplied && !trialAndDiscount) return;
    const {discount} = selectedPlan;
    if (!discount) return;
    const valid = discount.valid === true;
    const displayValid =
      when(maybe(discount.amountOff), (a) => a > 0) ||
      when(maybe(discount.percentOff), (b) => b > 0) ||
      when(maybe(discount.trialDays), (c) => c > 0);
    if (valid && displayValid) {
      return selectedPlan;
    }
  }, [trialApplied, selectedPlan]);

  const sortedPlans = useMemo(() => {
    if (!hasPlans) return [];

    // Calculate and map how much a plan costs per week
    const billedPlans = loadedPlans
        .map((p) => {
        // If theres a trial display the default price, otherwise show the discount price (if there is one)
          const displayPrice =
          trialApplied && p.renewalInterval !== 'NEVER' ?
            p.priceUSD :
            p.discount?.finalPrice || p.priceUSD;
          return {
            ...p,
            displayPrice,
            billed: billedWeekly({
              renewalInterval: p.renewalInterval,
              price: displayPrice,
            }),
            bestValue: false,
          };
        })
        .sort((a, b) => a.billed - b.billed);
    billedPlans[0].bestValue = true;
    return billedPlans.sort((a, b) => b.billed - a.billed);
  }, [loadedPlans, hasPlans, trialApplied]);

  useEffect(() => {
    if (!plans || isEmpty(plans)) {
      setLoading(false);
      return;
    }
    if (couponParam && couponParam !== staticCoupon) {
      (async () => {
        try {
          const res = await fetch('/api/plans', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              plans: plans.map(({webAlias}) => webAlias),
              coupon: couponParam,
            }),
          });
          const json: {
            message: string;
            plans: Array<PlanWithInstallments>;
          } = await res.json();

          const enrichedPlans = plans.map((plan) => {
            return {
              ...plan,
              ...json.plans.find((p) => p.webAlias === plan?.webAlias),
            };
          }) as Array<PlanWithInstallments>;
          if (isEmpty(enrichedPlans)) {
            setLoadedPlans(plans);
          } else {
            setLoadedPlans(enrichedPlans);
            setSelectedPlan(
                enrichedPlans.find(
                    ({webAlias, payIn3}) =>
                      webAlias === selectedPlan?.webAlias ||
                  ('annual' && payIn3 === selectedPlan?.payIn3),
                ) || first(enrichedPlans),
            );
          }
        } catch (e) {
          captureException(e);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setLoadedPlans(plans);
      setLoading(false);
    }
  }, [plans, staticCoupon, couponParam]);

  useEffect(() => {
    if (!plans) return;

    if (plans && !isEmpty(plans) && !selectedPlan) {
      if (planParam) {
        const p = plans.find(({webAlias, payIn3}) => {
          return webAlias === planParam && payIn3 === payIn3Param;
        }) as PlanWithInstallments;
        setSelectedPlan(p);
      } else {
        setSelectedPlan(
            plans.find(({webAlias, payIn3}) => {
              return webAlias?.includes('annual') && payIn3 === payIn3Param;
            }) || first(plans),
        );
      }
      if (trial) {
        setTrial(!!trialParam);
      } else if (planParam && !trial) {
        setTrial(false);
      }
    }
  }, [plans, trialParam, planParam, payIn3Param]);

  useEffect(() => {
    // if (handleDiscount) {
    //   handleDiscount(discounted);
    // }
  }, [discounted, handleDiscount]);

  useEffect(() => {
    if (selectedPlan) {
      setTrialParam(trialApplied && trial);
      setPayIn3Param(selectedPlan?.payIn3);
      setPlanParam(selectedPlan?.webAlias || undefined);
    }
  }, [selectedPlan, trialApplied, trial]);

  return {
    loading,
    loadedPlans,
    sortedPlans,
    selectedPlan,
    setSelectedPlan,
    hasPlans,
    trialApplied,
    setTrial,
    selectPlan,
    hasEvent,
    loadingCheckout,
    showPlans:
      !data?.action?.showPlansOnNoTrial ||
      (data?.action?.showPlansOnNoTrial && !trialApplied),
  };
};

type Props = {
  trialDays: Maybe<number>;
  coupon: Maybe<string>;
  enrichedPlans: Array<PlanWithInstallments>;
  thankYouPage: Maybe<{ _type: 'slug'; current: string }>;
  thankYouLink: Maybe<string>;
  type: CheckoutType | 'pageAction';
  bundle: Maybe<ExternalBundle>;
  trialAndDiscount: Maybe<boolean>;
};

/**
 * If a plan does not have a discount key on it, it is not discounted
 * If the plan is lifetime it IS ALWAYS DISCOUNTED - and will always have a discount key
 * If there is a trial applied the discount is not applied UNLESS ITS LIFETIME
 */
