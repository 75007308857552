import {forwardRef, HTMLAttributes, PropsWithChildren} from 'react';
import {Padding} from '@mindfulness/cms';
import {when} from '@mindfulness/utils/maybe';

import style from './Section.module.css';
import styleUtils from '../../../styles/utils.module.css';
import {ColoursGradients, ResponsiveValue} from '../../../types/types';
import {Theme} from '../../../types/emotion';
import {buildPaddingMap} from '../../../utils/styling';

const backgroundClass = (background?: Props['background']): Array<string> => {
  if (!background) return [];
  if (typeof background === 'object') {
    return [
      ...Object.entries(background).map(([key, value]) => styleUtils[`${key}:background-${value}`]),
    ];
  };
  return [
    styleUtils[`background-${background.includes('Gradient') ? `gradient-${background.replace('Gradient', '')}` : background}`],
  ];
};

const paddingClass = (padding?: Props['padding'], sectionPadding?: Props['sectionPadding']) => {
  const classArray = [
    ...sectionPadding?.paddingTop !== undefined ? [
      style['sectionPadding-pt'],
    ] : [
      ...(typeof padding === 'object' ? [
        ...Object.entries(padding).map(([key, value]) => styleUtils[`${key}-pt-${value}`]),
      ] : [styleUtils[`pt-${padding}`]]),
    ],
    ...sectionPadding?.paddingBottom !== undefined ? [
      style['sectionPadding-pb'],
    ] : [
      ...(typeof padding === 'object' ? [
        ...Object.entries(padding).map(([key, value]) => styleUtils[`${key}-pb-${value}`]),
      ] : [styleUtils[`pb-${padding}`]]),
    ],
  ];
  return classArray;
};

export const Section = forwardRef<HTMLDivElement, PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>>(({
  children, background, padding = 60, sectionPadding, position, overflow, overflowX, overflowY, className, ...props
}, ref) => {
  const padd = when(sectionPadding, (p) => buildPaddingMap(p));
  return (
    <section
      className={[
        style.section,
        ...(backgroundClass(background)),
        ...(paddingClass(padding, sectionPadding)),
        ...position ? [styleUtils[`position-${position}`]] : [],
        ...overflow ? [styleUtils[`overflow-${overflow}`]] : [],
        ...overflowX ? [styleUtils[`overflow-x-${overflowX}`]] : [],
        ...overflowY ? [styleUtils[`overflow-y-${overflowY}`]] : [],
        ...(className ? [className] : []),
      ].join(' ')}
      style={{
        ...props.style,
        ...(padd?.paddingTop !== undefined ? {
          '--section-pt-xs': `${padd.paddingTop.xs}px`,
          '--section-pt-sm': `${padd.paddingTop.sm}px`,
          '--section-pt-md': `${padd.paddingTop.md}px`,
          '--section-pt-lg': `${padd.paddingTop.lg}px`,
        } : {}),
        ...(padd?.paddingBottom !== undefined ? {
          '--section-pb-xs': `${padd.paddingBottom.xs}px`,
          '--section-pb-sm': `${padd.paddingBottom.sm}px`,
          '--section-pb-md': `${padd.paddingBottom.md}px`,
          '--section-pb-lg': `${padd.paddingBottom.lg}px`,
        } : {}),
      }}
      {...props}
      ref={ref}
    >
      {children}
    </section>
  );
});

Section.displayName = 'Section';


type Props = {
  background?: ResponsiveValue<ColoursGradients>;
  padding?: ResponsiveValue<keyof Theme['spacings']>;
  sectionPadding?: {
    paddingTop?: Padding;
    paddingBottom?: Padding;
  };
  position?: 'relative' | 'absolute';
  overflow?: 'hidden' | 'visible';
  overflowX?: 'hidden' | 'visible';
  overflowY?: 'hidden' | 'visible';
}
