import {gql} from '@apollo/client';
import {QuestionSheetFragment} from '../fragments';

export const GET_QUESTION_SHEETS = gql`
query getQuestionSheet($id: ID!) {
  questionSheets(id: $id) {
    ...QuestionSheetFragment
  }
}

${QuestionSheetFragment}
`;
