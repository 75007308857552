import * as React from 'react';
import {SinglePlayer as SinglePlayerProps} from '@mindfulness/cms';

import {
  Box,
  Column,
  Container,
  Flex,
  Row,
  Stack,
} from '../../layout';
import {
  Maybe,
  SectionIndex,
  SingleDetailFragment,
  SingleVariantFragment,
} from '../../../types/types';

import {useVariant} from '../../../hooks/useVariant';
import {Player} from '../../ui/SinglePlayer/Player';
import {useSinglePlayer} from '../../../hooks/useSinglePlayer';
import {BackgroundAPIImage, NewLink} from '../../global';
import {Overlay} from '../../ui/SinglePlayer/Overlay';
import {Footer, VideoWrapper} from './SinglePlayer.styles';
import {SingleShare} from '../../ui';
import {Text} from '../../typography';
import {useContributorNames} from '../../../hooks/useContributorNames';
import {useHydratedSingle} from '../../../hooks/useHydratedSingle';
import {assertString} from '../../../utils/string';
import {Section} from '../../layouts/Section';
import {AspectRatio} from '../../layouts/AspectRatio';

export const SinglePlayer: React.FC<
  SectionIndex<SinglePlayerProps & { richSingle: SingleDetailFragment }>
> = ({
  background,
  showDetails,
  paddingTop = 40,
  paddingBottom = 40,
  richSingle,
}) => {
  const {single, loading} = useHydratedSingle(richSingle);
  const {variant} = useVariant({single: richSingle});
  return single ? (
    <>
      <Section
        background={background}
        padding={0}
        position="relative"
        sectionPadding={{
          paddingTop,
          paddingBottom: showDetails ? 0 : paddingBottom,
        }}
      >
        <Container
          maxWidth="mlg"
          gutter={{xs: 0, md: 16}}
          zIndex={1}
          position="relative"
        >
          <Row>
            <Column spacing={40}>
              <SingleInlinePlayer
                single={single}
                variant={variant}
                loading={loading}
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </>
  ) : null;
};

const SingleInlinePlayer: React.FC<{
  single: SingleDetailFragment;
  variant: Maybe<SingleVariantFragment>;
  loading: boolean;
}> = ({single, variant, loading}) => {
  const {
    status,
    wrapperRef,
    image,
    poster,
    scrubBackwards,
    scrubForwards,
    duration,
    playPercent,
    playSeconds,
    setTime,
    chapter,
    setChapter,
    player,
    tracks,
    previousState,
    cueRef,
    handleFullScreen,
  } = useSinglePlayer({single, variant});
  const frame = true;
  const webSlug = React.useMemo(() => assertString(single.webSlug), [single]);
  const contributorNames = useContributorNames(single.contributors);
  return (
    <>
      <VideoWrapper ref={wrapperRef}>
        <AspectRatio ratio="16:9">
          <Box position="absolute" height="100%" width="100%">
            {image && !poster ? (
              <BackgroundAPIImage
                src={image}
                zIndex={-2}
                objectPosition="center"
                objectFit="cover"
                triggerResize={
                  frame ? (status === 'playing' ? Math.random() : 0) : 0
                }
              />
            ) : null}
            <Overlay
              scrubBackwards={scrubBackwards}
              scrubForwards={scrubForwards}
              handleFullScreen={handleFullScreen}
              requireSignIn={false}
              locked={false}
              duration={duration}
              playPercent={playPercent}
              variant={variant}
              playSeconds={playSeconds}
              setTime={setTime}
              chapter={chapter}
              player={player}
              single={single}
              setChapter={setChapter}
              nextAndPreviousSingles={undefined}
              tracks={tracks}
              noContent={true}
              cueRef={cueRef}
            />
            <Player
              duration={duration}
              playPercent={playPercent}
              playSeconds={playSeconds}
              chapter={chapter}
              poster={poster}
              player={player}
              single={single}
              variant={variant}
              setChapter={setChapter}
              setTime={setTime}
              tracks={tracks}
              nextAndPreviousSingles={undefined}
              previousState={previousState}
              frame={frame}
              cueRef={cueRef}
            />
          </Box>
        </AspectRatio>
      </VideoWrapper>
      <Footer>
        <Flex wrap="nowrap" justify="space-between" gap={16} items="flex-start">
          <Stack direction="vertical">
            <NewLink href={webSlug}>
              <>
                <Text fontWeight="bold" fontSize={{xs: 'base', md: 'lg'}}>
                  {single.title}
                </Text>
                <Text
                  colour="grey7"
                  fontSize={{
                    xs: 'sm',
                    md: 'base',
                  }}
                >
                  {contributorNames}
                </Text>
              </>
            </NewLink>
          </Stack>
          <Box shrink={0}>
            {loading ? null : (
              <SingleShare
                size={30}
                single={single}
                colour="primary"
              />
            )}
          </Box>
        </Flex>
      </Footer>
    </>
  );
};
