import React from 'react';
import {Stack, Box} from '../../layout';
import {Text} from '../../typography';
import {APIImage} from '../../global';

export const AvailableEverywhere: React.FC = () => {
  return (
    <Stack direction="horizontal" space={8}>
      <APIImage width={43} height={43} url="/images/all-devices.png" />
      <Box>
        <Text fontWeight="bold" fontSize="sm">
          Available everywhere.
        </Text>
        <Text fontSize="sm">Access on the web or in our mobile app.</Text>
      </Box>
    </Stack>
  );
};
