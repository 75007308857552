import * as React from 'react';
import {ErrorBoundary} from '@sentry/nextjs';
import {switchEnum} from '@mindfulness/utils/logic';

import {CollectionGrid, CollectionReel} from '..';
import {assertString} from '../../../utils/string';
import {CollectionList} from '../CollectionList';
import {InProgressReel} from '../InProgressReel';
import {CollectionFeedFragment, Maybe} from '../../../types/api';
import {filterCollections} from '../../../utils/version';
import {ErrorFallback} from '../../global/ErrorFallback';


export const CollectionsList: React.FC<Props> = ({collections, noLinks, priority, feedId, preventHydration, location}) => {
  const filteredCollections = filterCollections(collections);
  return (
    <ErrorBoundary
      fallback={(props) => (
        <ErrorFallback {...props} index={0} sectionType={'CollectionsList'} />
      )}
    >
      {filteredCollections
          .map(
              ({layout, ...collection}, index) => {
                if (location === 'collection') {
                  return (
                    <CollectionGrid
                      {...collection}
                      index={index}
                      key={`${collection.id}-${collection.title}`}
                      noLinks={noLinks}
                      priority={index === 0 && priority}
                      layout={layout}
                      location={location}
                      feedId={feedId}
                    />
                  );
                }
                return switchEnum(assertString(layout, 'else'), {
                  IN_PROGRESS: (
                    <InProgressReel
                      priority={index === 0 && priority}
                      index={index}
                      key={`${collection.id}-${collection.title}`}
                      feedId={feedId}
                      {...collection}
                    />
                  ),
                  SCROLL: (
                    <CollectionReel
                      noLinks={noLinks}
                      priority={index === 0 && priority}
                      index={index}
                      key={`${collection.id}-${collection.title}`}
                      location={location}
                      preventHydration={preventHydration}
                      feedId={feedId}
                      {...collection}
                    />
                  ),
                  SNAP_SCROLL: (
                    <CollectionReel
                      noLinks={noLinks}
                      priority={index === 0 && priority}
                      index={index}
                      key={`${collection.id}-${collection.title}`}
                      location={location}
                      preventHydration={preventHydration}
                      feedId={feedId}
                      {...collection}
                    />
                  ),
                  SHORT_GRID_2: (
                    <CollectionGrid
                      {...collection}
                      key={`${collection.id}-${collection.title}`}
                      noLinks={noLinks}
                      priority={index === 0 && priority}
                      index={index}
                      layout={layout}
                      count={5}
                      countCols={{
                        xs: 4,
                        sm: 4,
                        md: 3,
                        lg: 5,
                        xl: 5,
                      }}
                      location={location}
                      feedId={feedId}
                    />
                  ),
                  GRID_2: (
                    <CollectionGrid
                      {...collection}
                      index={index}
                      key={`${collection.id}-${collection.title}`}
                      noLinks={noLinks}
                      priority={index === 0 && priority}
                      layout={layout}
                      location={location}
                      feedId={feedId}
                    />
                  ),
                  GRID_1: (
                    <CollectionGrid
                      {...collection}
                      index={index}
                      key={`${collection.id}-${collection.title}`}
                      noLinks={noLinks}
                      priority={index === 0 && priority}
                      layout={layout}
                      location={location}
                      feedId={feedId}
                    />
                  ),
                  SHORT_LIST: (
                    <CollectionList
                      {...collection}
                      index={index}
                      count={4}
                      key={`${collection.id}-${collection.title}`}
                      location={location}
                      feedId={feedId}
                    />
                  ),
                  FULL_LIST: (
                    <CollectionList
                      {...collection}
                      index={index}
                      key={`${collection.id}-${collection.title}`}
                      location={location}
                      feedId={feedId}
                    />
                  ),
                  SHORT_GRID_1: (
                    <CollectionGrid
                      {...collection}
                      key={`${collection.id}-${collection.title}`}
                      noLinks={noLinks}
                      index={index}
                      priority={index === 0 && priority}
                      layout={layout}
                      location={location}
                      count={4}
                      feedId={feedId}
                    />
                  ),
                  else: (
                    <CollectionGrid
                      {...collection}
                      index={index}
                      key={`${collection.id}-${collection.title}`}
                      noLinks={noLinks}
                      priority={index === 0 && priority}
                      layout={layout}
                      location={location}
                      feedId={feedId}
                    />
                  ),
                });
              },
          )}
    </ErrorBoundary>
  );
};


type Props = {
  collections: Array<CollectionFeedFragment>;
  noLinks?: boolean;
  priority?: boolean;
  location?: 'collection';
  preventHydration?: boolean;
  feedId: Maybe<string>;
};
