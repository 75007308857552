import {Theme} from '@emotion/react';
import styled from '@emotion/styled';
import {CSSProperties} from 'react';

export const Cluster = styled.div<{
  gap?: keyof Theme['spacings'];
  justify?: CSSProperties['justifyContent'];
  items?: CSSProperties['alignItems'];
}>(
    ({theme, gap = 0, justify = 'flex-start', items = 'center'}) => `
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacings[gap]};
  justify-content: ${justify};
  align-items: ${items};
`,
);
