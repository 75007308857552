import {useContext, useState} from 'react';

import {Figure, HeroBundle as HeroBundleProps} from '@mindfulness/cms';
import {percentOf} from '@mindfulness/utils/math';
import FadeIn from 'react-fade-in';

import {Maybe, SectionIndex} from '../../../types/types';
import {Box, Column, Container, Row, Section} from '../../layout';
import {Title1, Title3, Small, H4, Text} from '../../typography';
import {
  APIImage,
  BlockContent,
  ButtonLink,
  Context,
  Icon,
  OverlayModal,
  SanityImage,
} from '../../global';
import {Divider} from '../../ui/Divider';
import {assertString} from '../../../utils';
import {
  ProgressBar,
  UnderlinedButton,
  UnderlinedLink,
} from './HeroBundle.styles';
import {useLockedBundle} from '../../../hooks/useLockedBundle';
import {VideoPlayer} from '../../ui/VideoPlayer';
import {Readmore} from '../../ui/Readmore';
import {useModal} from '../../../hooks';
import {Button} from '../../forms/Button';
import {Purchases} from '../../ui/Purchases';
import {HydratedPurchase, usePurchases} from '../../../hooks/usePurchases';
import {LoadingSpinner} from '../../ui/LoadingSpinner';
import {ContentSplit} from '../../ui/ContentSplit';
import {AvailableEverywhere} from '../../ui/AvailableEverywhere';
import {useTrack} from '../../global/SegmentProvider';

export const HeroBundle: React.FC<SectionIndex<HeroBundleProps>> = ({
  title,
  subtitle,
  description,
  details,
  image,
  videoUrl,
  purchases = [],
  coupon,
  index,
  ...sectionPadding
}) => {
  const track = useTrack();
  const {session} = useContext(Context);
  const [playing, setPlaying] = useState<boolean>();
  const {open} = useModal('bundleTrailer');
  const {loading: loadingPurchases, hydratedPurchases} = usePurchases(
      purchases,
      coupon,
  );
  const {
    isLockedBundle,
    isReleased,
    bundle,
    loginAction,
    loading: bundleLoading,
  } = useLockedBundle();

  return (
    <Section
      id={`HeroBundle-${index}`}
      sectionPadding={sectionPadding}
      padding={{
        xs: 0,
        md: 80,
      }}
    >
      <ContentSplit
        Left={() => (
          <>
            <Box
              display={{
                xs: 'none',
                md: 'block',
              }}
              marginB={30}
            >
              {image?.desktopImage?.image || image?.mobileImage?.image ? (
                <SanityImage
                  ratio={'339:191'}
                  image={(image.desktopImage || image.mobileImage) as Figure}
                  priority
                />
              ) : (
                <APIImage
                  ratio={'339:191'}
                  url={
                    bundle?.cardImage?.url ||
                    bundle?.coverImage?.url ||
                    bundle?.cellImage?.url
                  }
                  priority
                />
              )}
            </Box>
            <Box
              display={{
                xs: 'block',
                md: 'none',
              }}
              marginB={30}
            >
              {image?.mobileImage?.image || image?.desktopImage?.image ? (
                <SanityImage
                  decoration="none"
                  image={(image.mobileImage || image.desktopImage) as Figure}
                  priority
                />
              ) : (
                <APIImage
                  decoration="none"
                  url={
                    bundle?.cardImage?.url ||
                    bundle?.coverImage?.url ||
                    bundle?.cellImage?.url
                  }
                  priority
                />
              )}
            </Box>
            <Box
              paddingX={{
                xs: 16,
                md: 0,
              }}
            >
              <Title1>{title || bundle?.title}</Title1>
              <Title3 spacing={10}>{subtitle || bundle?.subtitle}</Title3>
              <Readmore minHeight={78}>
                {description && typeof description === 'object' ? (
                  <BlockContent
                    content={description}
                    fontSize="md"
                    colour="grey8"
                    background="white"
                  />
                ) : bundle?.description ? (
                  <H4 lineHeight={'150%'} colour="grey8">
                    {bundle.description}
                  </H4>
                ) : null}
              </Readmore>
              <Divider colour="grey4" spacing={30} />
            </Box>
          </>
        )}
        Right={() => (
          <>
            <Box width={'100%'}>
              <Row>
                {details?.map(({icon, text, _key}) => (
                  <Column
                    key={_key}
                    spacing={16}
                  >
                    <Icon
                      colour="sleepAccent"
                      name={assertString(icon)}
                      size={24}
                    >
                      <Text colour="grey7" fontWeight="bold">
                        {text}
                      </Text>
                    </Icon>
                  </Column>
                ))}
              </Row>
              <Divider colour="grey4" bottomSpacing={30} />
            </Box>

            <Box width={'100%'}>
              {bundleLoading ? (
                <Box
                  display="flex"
                  height="200px"
                  items="center"
                  justify="center"
                >
                  <LoadingSpinner />
                </Box>
              ) : (
                <>
                  {isLockedBundle ? (
                    <FadeIn>
                      <BundlePurchases
                        hydratedPurchases={hydratedPurchases}
                        loadingPurchases={loadingPurchases}
                        videoUrl={videoUrl}
                        purchases={purchases}
                        open={open}
                        coupon={coupon}
                      />
                    </FadeIn>
                  ) : (
                    <>
                      {bundle?.sessionsCount ? (
                        <>
                          <Small spacing={4}>{`${
                            bundle.userData?.completedSessions || 0
                          }/${bundle.sessionsCount} Sessions completed`}</Small>
                          <ProgressBar
                            percent={percentOf(
                                bundle.userData?.completedSessions || 0,
                                bundle.sessionsCount,
                            )}
                          />
                          <Divider colour="grey4" spacing={20} />
                        </>
                      ) : null}
                      <ButtonLink
                        disabled={!isReleased && !isLockedBundle}
                        id="StartBundle"
                        wide
                        href={`/play?b=${bundle?.id}`}
                      >
                        {isReleased ? (
                          bundle?.userData?.completedSessions ? (
                            <Icon name="player-play">Play Next</Icon>
                          ) : (
                            <Icon name="player-play">Start</Icon>
                          )
                        ) : (
                          'Scheduled'
                        )}
                      </ButtonLink>
                    </>
                  )}
                </>
              )}
            </Box>
          </>
        )}
        UnderCard={() => (
          <>
            {!bundleLoading && isLockedBundle ? (
              <Text align="center">
                Already purchased?{' '}
                {session ? (
                  <UnderlinedLink href={'/contact'}>
                    {'Contact Support'}
                  </UnderlinedLink>
                ) : (
                  <UnderlinedButton
                    as="button"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      track('Button clicked', {
                        path: window.location.pathname,
                        button_text: 'Sign in',
                        id: 'BundleHeroSignIn',
                      });
                      loginAction();
                    }}
                  >
                    {'Sign in'}
                  </UnderlinedButton>
                )}
              </Text>
            ) : null}
          </>
        )}
      />

      <OverlayModal
        name="bundleTrailer"
        closeButton
        onClose={() => {
          setPlaying(false);
        }}
      >
        <Container>
          <VideoPlayer videoUrl={videoUrl} playing={playing} />
        </Container>
      </OverlayModal>
    </Section>
  );
};


const BundlePurchases: React.FC<{
  coupon: Maybe<string>;
  hydratedPurchases: HydratedPurchase[];
  loadingPurchases: boolean;
  videoUrl: Maybe<string>;
  purchases: HeroBundleProps['purchases'];
  open: () => void;
}> = ({
  hydratedPurchases,
  loadingPurchases,
  coupon,
  videoUrl,
  purchases,
  open,
}) => {
  const [selected, setSelected] = useState<HydratedPurchase>();

  return (
    <>
      <Purchases
        purchases={purchases || []}
        hydratedPurchases={hydratedPurchases}
        loadingPurchases={loadingPurchases}
        selected={selected}
        setSelected={setSelected}
        index={0}
        coupon={coupon}
      />
      {videoUrl ? (
      <Box marginT={10}>
        <Button
          id="WatchTrailer"
          wide
          variant="outline"
          onClick={() => open?.()}
        >
          <Icon name="player-play">Watch Trailer</Icon>
        </Button>
      </Box>
    ) : null}
      <Divider spacing={30} />
      <AvailableEverywhere />
    </>
  );
};
