import * as React from 'react';

import {useNavigation} from '../../../hooks';
import {BackgroundAPIImage, ModalContext, OverlayModal} from '../../global';
import {Box, Stack} from '../../layout';
import {Title1, Text} from '../../typography';
import {Button} from '../../forms';
import {defaultOnboardingSteps} from '../../templates/Onboarding/Onboarding.effects';
import {CancelButton} from '../../forms/Button/CancelButton';

export const SingleFinishedModal: React.FC = () => {
  const {goTo} = useNavigation();
  const {closeModal} = React.useContext(ModalContext);

  return (
    <OverlayModal name="singleFinish" padding={0}>
      <Box maxWidth="640px">
        <Box height="273px" position="relative">
          <BackgroundAPIImage src="/images/unlock-bg.jpg" doNotResize />
        </Box>
        <Box padding={40} textAlign="center">
          <Stack justify="center" wide direction="vertical">
            <Box marginB={44}>
              <Title1>Finish personalizing your account</Title1>
              <Text colour="grey8">
                Complete a few quick questions to make your own personalized
                mindfulness plan.
              </Text>
            </Box>

            <Button
              id="unlockButton"
              type="button"
              onClick={() => {
                goTo({
                  pathname: `/get-started/${defaultOnboardingSteps[0]}`,
                });
              }}
            >
              Complete now
            </Button>
            <CancelButton
              type="button"
              onClick={() =>
                closeModal?.({
                  name: 'singleFinish',
                })
              }
            >
              Not now
            </CancelButton>
          </Stack>
        </Box>
      </Box>
    </OverlayModal>
  );
};
