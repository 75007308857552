import {createElement, forwardRef, PropsWithChildren} from 'react';
import style from './ShareDropdown.module.css';

export const ShareDropdown = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(({
  children,
  open,
  position = 'left',
  ...props
}, ref) => {
  return createElement('div', {
    className: [
      style.shareDropdown,
      ...(open ? [style.open] : []),
      ...(position === 'left' ? [style['position-left']] : [style['position-bottom']]),
    ].join(' '),
    ...props,
    ref,
  }, children);
});

ShareDropdown.displayName = 'ShareDropdown';

type Props = {
  open: boolean;
  position?: 'left';
}
