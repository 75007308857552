import {Maybe} from '../../../types/types';
import {AuthedSession} from '../../../utils/auth';
import {useWhen} from '../../../hooks/useWhen';

import {ProfileAvatar} from './Avatar.styles';
import {Theme} from '@emotion/react';

export const Avatar: React.FC<{
  session?: Maybe<AuthedSession> | null;
  size?: 'lg';
  spacing?: keyof Theme['spacings'];
}> = ({session, size, spacing}) => {
  const firstName = useWhen(session?.firstName);
  return (
    <ProfileAvatar size={size} spacing={spacing}>
      {(session?.email || firstName)?.charAt(0)}
    </ProfileAvatar>
  );
};
