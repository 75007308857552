import styled from '@emotion/styled';

export const Footer = styled.div(({theme}) => `
  background-color: ${theme.colors.greyWarm};
  padding: 20px 20px 26px;
  @media (min-width: ${theme.breakpoints.md.min}) {
    border-radius: 0 0 ${theme.radius.xl} ${theme.radius.xl};
  }
`);


export const VideoWrapper = styled.div(({theme}) => `
  overflow: hidden;
  @media (min-width: ${theme.breakpoints.md.min}) {
    border-radius:  ${theme.radius.xl} ${theme.radius.xl} 0 0;
  }
`);
