import React from 'react';
import Image from 'next/image';

import {Cluster} from '../../layout';
import {BlockLinks} from './Footer.styles';

export const SocialLinks: React.FC = () => {
  const socials = React.useMemo(
      () => [
        {
          href: 'https://www.facebook.com/Mindfulnesscom/',
          src: '/images/facebook.png',
          alt: 'Facebook',
        },
        {
          href: 'https://www.instagram.com/mindfulnesscom/',
          src: '/images/instagram.png',
          alt: 'Instagram',
        },
        {
          href: 'https://twitter.com/mindfulness_com',
          src: '/images/twitter.png',
          alt: 'Twitter',
        },
        {
          href: 'https://www.youtube.com/channel/UCeUFImBy1I9VbmX6EUjfGrA',
          src: '/images/youtube.png',
          alt: 'YouTube',
        },
        {
          href: 'https://www.tiktok.com/@mindfulness.com',
          src: '/images/tiktok.png',
          alt: 'TikTok',
        },
      ],
      [],
  );
  return (
    <Cluster gap={10}>
      {socials.map(({href, src, alt}) => (
        <BlockLinks
          target="_blank"
          rel="nofollow noopener"
          href={href}
          key={href}
        >
          <Image src={src} width={30} height={30} alt={alt} />
        </BlockLinks>
      ))}
    </Cluster>
  );
};
