import * as React from 'react';

import {Video as VideoProps} from '@mindfulness/cms';

import {Container, Section} from '../../layout';
import {H1} from '../../typography';
import {CallToActionButton, BlockContent} from '../../global';
import {SectionIndex} from '../../../types/types';

import {TextWrapper} from './Video.styles';
import {VideoPlayer} from '../../ui/VideoPlayer';
import {Theme} from '@emotion/react';

export const Video: React.FC<SectionIndex<VideoProps>> = ({
  autoplay,
  videoUrl,
  title,
  paragraph,
  cta,
  _type,
  index,
  background,
  width = 'xl',
  paddingTop,
  paddingBottom,
}) => (
  <Section
    id="Video"
    background={background}
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
  >
    <Container maxWidth={width as keyof Theme['sizes']['container']}>
      {videoUrl && <VideoPlayer videoUrl={videoUrl} autoplay={autoplay} />}

      {(title || paragraph || cta) && (
        <TextWrapper>
          {title ? (
            <H1
              as={index === 0 ? undefined : 'h2'}
              spacing={10}
              size="2xl"
              colour="grey9"
            >
              {title}
            </H1>
          ) : null}
          {paragraph ? (
            <BlockContent
              content={paragraph}
              background="white"
              fontSize="md"
              colour="grey7"
            />
          ) : null}
          {cta?.text ? (
            <CallToActionButton id={`${_type}-${index}`} {...cta}>
              {cta?.text}
            </CallToActionButton>
          ) : null}
        </TextWrapper>
      )}
    </Container>
  </Section>
);
