import * as React from 'react';
import {Share as ShareSection} from '@mindfulness/cms';

import {H3, H4} from '../../typography';
import {Container, Row, Column, Section, Box} from '../../layout';
import {Button} from '../../forms';
import {SectionIndex} from '../../../types/types';

import {BasicImage, APIImage} from '../../global';

export const Share: React.FC<SectionIndex<ShareSection>> = ({
  title,
  paragraph,
  buttonText,
  background,
  paddingTop,
  paddingBottom,
  shareImage,
  shareUrl,
  index,
}) => (
  <Section
    sectionPadding={{
      paddingTop,
      paddingBottom,
    }}
    padding={104}
    background={background}
  >
    <Container maxWidth="sm">
      <Row>
        <Column textAlign="center">
          <H3 weight="bold" size="2xl" spacing={8}>
            {title}
          </H3>
          {paragraph ? (
            <H4
              colour={background === 'grey9' ? undefined : 'grey7'}
              spacing={20}
            >
              {paragraph}
            </H4>
          ) : null}
          <Box marginB={50} width="215px" height="60px" display="inline-block">
            {shareImage ? (
              <BasicImage width={215} height={60} image={shareImage} />
            ) : (
              <APIImage
                decoration="none"
                width={215}
                height={60}
                url="/images/share-page-friends.png"
              />
            )}
          </Box>

          <Box
            paddingX={{
              xs: 0,
              md: 34,
            }}
          >
            <Button
              id={`Share-${index}`}
              action="share"
              shareUrl={shareUrl}
              wide
            >
              {buttonText || 'Share'}
            </Button>
          </Box>
        </Column>
      </Row>
    </Container>
  </Section>
);
