import * as React from 'react';
import styled from '@emotion/styled';
import {Theme} from '@emotion/react';
import {Flex} from '../../layout';
import {getBottomMargin, getHide, getTopMargin} from '../../../utils';
import {ResponsiveValue} from '../../../types/types';

export const Divider: React.FC<{
  text?: string;
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  topSpacing?: ResponsiveValue<keyof Theme['spacings']>;
  bottomSpacing?: ResponsiveValue<keyof Theme['spacings']>;
  hide?: [keyof Theme['breakpoints'], keyof Theme['breakpoints']];
  colour?: keyof Theme['colors'];
}> = ({text, spacing, hide, colour, topSpacing, bottomSpacing}) =>
  Boolean(text) ? (
    <Flex wrap="nowrap" gap={8} hide={hide}>
      <Line colour={colour} spacing={spacing} />
      <Text>{text}</Text>
      <Line colour={colour} spacing={spacing} />
    </Flex>
  ) : (
    <Line
      colour={colour}
      spacing={spacing}
      topSpacing={topSpacing}
      bottomSpacing={bottomSpacing}
      hide={hide}
    />
  );

const Line = styled.div<{
  spacing?: ResponsiveValue<keyof Theme['spacings']>;
  bottomSpacing?: ResponsiveValue<keyof Theme['spacings']>;
  topSpacing?: ResponsiveValue<keyof Theme['spacings']>;
  hide?: [keyof Theme['breakpoints'], keyof Theme['breakpoints']];
  colour?: keyof Theme['colors'];
}>(
    ({
      theme,
      spacing = 8,
      hide,
      topSpacing,
      bottomSpacing,
      colour = 'grey4',
    }) => `
  position: relative;
  text-align: center;
  height: 1px;
  width: 100%;
  background-color: ${theme.colors[colour]};
  ${getTopMargin(theme, topSpacing || spacing)}
  ${getBottomMargin(theme, bottomSpacing || spacing)}
  margin-left: auto;
  margin-right: auto;
  ${getHide(theme, hide)}
`,
);

const Text = styled.span`
  display: block;
  white-space: nowrap;
`;
