import * as React from 'react';
import {Subscribe as SubscribeProps} from '@mindfulness/cms';

import {H3, H4, Text} from '../../typography';
import {Input, Button, Alert} from '../../forms';
import {Container, Row, Column, Section} from '../../layout';
import {PolicyLinks} from '../../ui';
import {useForm} from '../../../hooks';
import {SectionIndex} from '../../../types/types';
import {joinLists, leaveLists} from '../../../api';
import {validateEmail} from '../../../utils';
import {useTrack} from '../../global/SegmentProvider';

/**
 * @return {React.ReactElement}
 */
export const Subscribe: React.FC<SectionIndex<SubscribeProps>> = ({
  title,
  paragraph,
  paddingTop,
  paddingBottom,
  type = 'subscribe',
  index,
}) => {
  const [success, setSuccess] = React.useState<boolean>(false);
  const track = useTrack();
  const {data, handleChange, handleSubmit, loading, errors} = useForm<{
    email: string;
  }>({
    initialValues: {
      email: '',
    },
    onSubmit: async (e, {data, resetData, setErrors}) => {
      const isSubscribe = type === 'subscribe';
      const mutation = isSubscribe ? joinLists : leaveLists;
      try {
        await mutation({email: data.email});
        track(
          isSubscribe ? 'Subscribed to list' : 'Unsubscribed from list',
          {mail_lists: ['ALL']},
        );
        setSuccess(true);
        resetData();
      } catch (err) {
        setErrors({
          form: `An error occured when trying to ${type} your email. Try again or contact support.`,
        });
      }
    },
    validations: {
      email: {
        required: {
          value: true,
          message: 'Please enter your email address',
        },
        custom: {
          isValid: validateEmail,
          message: 'Please enter a valid email address',
        },
      },
    },
  });

  return (
    <Section
      padding={80}
      sectionPadding={{
        paddingTop,
        paddingBottom,
      }}
    >
      <Container maxWidth="sm">
        <Row spacing={24}>
          <Column textAlign="center">
            <H3 weight="bold" size="2xl">
              {title}
            </H3>
            {paragraph ? (
              <H4 colour="grey7" spacing={20}>
                {paragraph}
              </H4>
            ) : null}
          </Column>
        </Row>
        <Row as="form" onSubmit={handleSubmit}>
          <Alert error={errors?.form} />
          {success ? (
            <Text colour="highlight">{`Your email has been ${type}d.`}</Text>
          ) : null}
          <Column spacing={24}>
            <Input
              label="Email"
              value={data.email}
              onChange={handleChange('email', (s) => s.toLowerCase())}
              error={errors?.email}
              placeholder="Enter your email address"
            />
          </Column>
          <Column spacing={24}>
            <Button
              type="submit"
              id={`Subscribe-${index}`}
              wide
              loading={loading}
            >
              Get Notified
            </Button>
          </Column>
          <Column textAlign="center">
            <PolicyLinks />
          </Column>
        </Row>
      </Container>
    </Section>
  );
};
