import * as React from 'react';
import Image from 'next/image';
import {HomeIllustration as HomeIllustrationProps} from '@mindfulness/cms';

import {H2, Text} from '../../typography';
import {Container, Row, Column, Section, Box} from '../../layout';
import {SectionIndex} from '../../../types/types';

export const HomeIllustration: React.FC<
  SectionIndex<HomeIllustrationProps>
> = ({title, bottomText, paragraph}) => (
  <Section background="greyWarm" padding={0}>
    <Container maxWidth="sm">
      <Row justify="center">
        <Column textAlign="center">
          <H2
            as="p"
            spacing={{
              xs: 26,
              md: 60,
            }}
          >
            {title}
          </H2>
        </Column>
        <Column textAlign="center">
          <Box display="inline-block">
            <Image src="/images/messy.png" width={202} height={652} alt="" />
          </Box>
        </Column>
        <Column textAlign="center">
          <H2
            as="p"
            spacing={{
              xs: 10,
              md: 16,
            }}
          >
            {bottomText}
          </H2>
          <Text>{paragraph}</Text>
        </Column>
        <Column>
          <Box paddingY={30} display="flex" justify="center">
            <Image
              src="/images/straight-sketch.png"
              width={100}
              height={100}
              alt=""
            />
          </Box>
        </Column>
      </Row>
    </Container>
  </Section>
);
